import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { TrayElement } from "./TrayElement";

export function BackButtonTrayElement(props: {
    className?: string;
}) {
    const settings = useContext(SettingsContext);
    const navigate = useNavigate();

    return <Fragment>
        <TrayElement>
            {settings.history.length > 0 && <button style={{ order: -1000 }} className={"shortcutButton" + (props.className ? " " + props.className : "")} onClick={() => { back(); }}>{i18n.t("common.back")}</button>}
        </TrayElement>
    </Fragment>;

    function back() {
        if (settings.history.length === 0)
            return;

        const element = settings.history[settings.history.length - 1];
        if (!element)
            return;

        settings.set({
            ...settings,
            ...element,
            history: settings.history.slice(0, settings.history.length - 1),
        });

        navigate(element.url, { replace: true });
    }
}