import React, { useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";

export enum ItemTypes {
    Card = "Card"
}

export type CardInfo = {
    label: string;
    className?: string;

    /**
     * In case you add an element multiple times, each of that needs a distinct ID.
     * leave blank to auto-generate
     */
    id: string;

    /**
     * Optional, if you want to add custom data
     */
    data?: any;
}

export type CardData = CardInfo & {
    index: number | undefined
}

type DragItem = CardData & {
    type: string
}

export type CardPropsType = CardData & {
    moveCard?: (dragIndex: number, hoverIndex: number) => void
    onDelete?: (item: CardData) => void;
};

export function Card(props: CardPropsType): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop<DragItem>({
        accept: ItemTypes.Card,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current || item.index === undefined)
                return;

            const dragIndex = item.index;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const hoverIndex = props.index!;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex)
                return;

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;

            // Time to actually perform the action
            if (props.moveCard)
                props.moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });


    const [
        collected, 
        drag, 
    ] = useDrag({
        type: ItemTypes.Card,
        item: {
            type: ItemTypes.Card,
            id: props.id,
            label: props.label,
            index: props.index,
            data: props.data,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div ref={ref} className="element" data-testid="card" style={{ opacity: collected.isDragging ? 0.5 : 1 }}>
            {props.label}

            {props.onDelete !== undefined && <svg onClick={() => {
                props.onDelete!(props);
            }} className="svg-icon xsmall"><use xlinkHref="#trashcan" /></svg>}
        </div>
    );
}