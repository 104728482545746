import React, { useContext } from "react";
import { TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { RcaType } from "../../contexts/ContextTypes";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import RootCauseAnalysis from "./RootCauseAnalysis";
import { isRcaVisible } from "./RootCauseView";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function LossesView() {
    const session = useContext(SessionContext);
    const isOniqUser = isOniqEmployee(session);

    const rcaPages: TabbedViewType[] = [{
        isVisible: isRcaVisible(RcaType.Bottleneck, session, isOniqUser),
        tabTitle: "common.organizational",
        tabSlug: "organizational",
        stats: undefined,
        controller: undefined,
        content: <>
            <RootCauseAnalysis
                type={RcaType.OrgLosses}
                title=""
            />
        </>,
    }];

    return <TabbedView
        breadcrumbs={[{
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        }, {
            label: "common.losses"
        }]}
        subtitle={<ViewSubtitle />}
        pages={rcaPages}
    />;
}