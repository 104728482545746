import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SimpleEventDispatcher } from "strongly-typed-events";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { Portal } from "../portal/Portal";

const tooltipWidth = 300;

const tailSize = 15;
const tailPadding = 10;

// Padding in pixels on top and bottom of the bubble
const bubbleYPadding = 10;

export type SpotlightPropsType = {
    id?: string | string[];
    contentHtml?: string;
    className?: string;
    position?: Position;
    img?: string;
};

export type Position = {
    placement: Placements;
    alignment: Alignments;
}

export enum Alignments {
    Left = "left",
    Right = "right",
    Center = "center",
}

export enum Placements {
    Top = "top",
    Bottom = "bottom",
}

type Rect = {
    top: number,
    bottom: number,
    left: number,
    right: number,
};

type State = {
    position: Position;
    rect: Rect;
    tail: Rect;
    content: string;
    needsScrolling: boolean;
}


const hideTooltipsNotification = new SimpleEventDispatcher();
export function hideAllSpotlights() {
    hideTooltipsNotification.dispatch(undefined);
}

export function Spotlight(props: SpotlightPropsType) {
    const session = useContext(SessionContext);
    const anchorRef = useRef<any>(null);
    const [state, setState] = useState<State | undefined>();
    const { trackEvent } = useMatomo();

    useEffect(() => {
        const handler = () => {
            setState(undefined);
        };

        hideTooltipsNotification.asEvent().subscribe(handler);

        return () => {
            hideTooltipsNotification.asEvent().unsubscribe(handler);
        };
    }, []);

    useEffect(() => {
        function handleResize() {
            setState(undefined);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (state !== undefined && props.id)
            trackEvent({
                category: "Spotlight",
                action: "clicked",
                name: getCombinedId(),
            });
    }, [state]);

    if (!hasContent()) {
        if (isOniqEmployee(session) && props.id) {
            if (Array.isArray(props.id) && props.id.length > 1) {
                const missingIds = props.id.filter(id => !i18n.exists("spotlights." + id.toLowerCase()));
                console.warn(`No text provided for the spotlights:\n${missingIds.map(id => `   ${id}.${session.locale}.md\n`)}\n`);
            }
            else
                console.warn(`No text provided ${props.id}.${session.locale}.md`);

        }

        return <></>;
    }

    return <div className={"spotlight brandHover" + (props.className ? " " + props.className : " bottomRight")}>
        {props.img !== undefined && <img
            src={props.img ?? "/assets/radix-info-circled.svg"}
            role="button"
            ref={anchorRef}
            onClick={(e) => {
                show();
                e.preventDefault();
                e.stopPropagation();
            }} />}

        {props.img === undefined && <svg
            className="svg-icon xsmall"
            role="button"
            ref={anchorRef} onClick={(e) => {
                show();
                e.preventDefault();
                e.stopPropagation();
            }}><use xlinkHref="#radix-info-circled" /></svg>}

        {state?.rect !== undefined && <Portal>
            <Tooltip
                id={getCombinedId()}
                rect={state.rect}
                tail={state.tail}
                content={state.content}
                needsScrolling={state.needsScrolling}
                onBlur={() => {
                    setState(undefined);
                }} />
        </Portal>}
    </div>;

    function getCombinedId() {
        if (!props.id)
            return undefined;

        if (Array.isArray(props.id))
            return props.id.join("_");

        return props.id;
    }

    function hasContent() {
        if (Array.isArray(props.id)) {
            return props.id.every(id => i18n.exists("spotlights." + id.toLowerCase()));
        }
        if (props.id) {
            const key = "spotlights." + props.id.toLowerCase();
            return i18n.exists(key);
        }

        return props.contentHtml !== undefined;
    }

    function show() {
        if (!anchorRef.current)
            return;

        // Get content and measure size
        const content = Array.isArray(props.id) ?
            props.id.map(id => i18n.t("spotlights." + id.toLowerCase())).join("\n") :
            props.id ? i18n.t("spotlights." + props.id.toLowerCase()) : props.contentHtml!;
        const metrics = measureMarkup("spotlightContent", content, tooltipWidth);

        const anchor = anchorRef.current.getBoundingClientRect();
        const position = props.position ?? findBestPosition(anchor, metrics.width, metrics.height);

        const rect = clipToScreen(getRect(tooltipWidth, Math.ceil(metrics.height) + 2 * bubbleYPadding, position.alignment, position.placement, anchor));

        const needsScrolling = (rect.bottom - rect.top) < metrics.height;

        const tail = getTail(position, anchor, rect);

        if (!rect || !tail)
            return;

        setState({
            position,
            rect,
            tail,
            content,
            needsScrolling,
        });
    }

    function getTail(position: Position, anchor: Rect, content: Rect): Rect {
        const y = position.placement === Placements.Top ? content.bottom : content.top;
        const x = position.alignment === Alignments.Left ? content.right - tailSize - tailPadding :
            position.alignment === Alignments.Right ? content.left + tailSize + tailPadding :
                (content.left + content.right) / 2;

        return {
            top: y,
            left: x,
            bottom: y + tailSize,
            right: x + tailSize,
        };
    }

    function findBestPosition(anchor: Rect, width: number, height: number): Position {
        const best = {
            pixels: undefined as number | undefined,
            placement: Placements.Top,
            alignment: Alignments.Center,

        };
        for (const placement of [Placements.Top, Placements.Bottom])
            for (const alignment of [Alignments.Center, Alignments.Left, Alignments.Right]) {
                const intersectingPixels = getRectPixelsOnScreen(getRect(width, height, alignment, placement, anchor));

                if (best.pixels === undefined || intersectingPixels > best.pixels) {
                    best.pixels = intersectingPixels;
                    best.alignment = alignment;
                    best.placement = placement;
                }
            }
        return {
            alignment: best.alignment,
            placement: best.placement,
        } as Position;
    }

    function getRectPixelsOnScreen(rect: Rect) {
        const w = window.innerWidth;
        const h = window.innerHeight;

        const clippedRect = {
            left: Math.max(0, rect.left),
            right: Math.min(w, rect.right),
            top: Math.max(0, rect.top),
            bottom: Math.min(h, rect.bottom),
        };

        return (clippedRect.right - clippedRect.left) * (clippedRect.bottom - clippedRect.top);
    }

    /**
     * Clips the given rect to the screen.
     * @param rect The rect to clip.
     * @returns Clipped rect.
     */
    function clipToScreen(rect: Rect) {
        const w = window.innerWidth;
        const h = window.innerHeight;

        const rectW = rect.right - rect.left;

        const clippedLeft = Math.max(0, rect.left);
        const clippedRight = Math.min(w, rect.right);

        return {
            top: Math.max(0, rect.top),
            bottom: Math.min(h, rect.bottom),
            left: clippedRight !== rect.right ? Math.max(0, clippedRight - rectW) : clippedLeft,
            right: clippedLeft !== rect.left ? Math.min(w, clippedLeft + rectW) : clippedRight,
        };
    }

    /**
     * Determines the placement of the tooltip based on the position of the anchor and the alignment and placement parameters.
     */
    function getRect(width: number, height: number, alignment: Alignments, placement: Placements, anchor: Rect, yPadding = 20) {
        const xTailOffset = tailPadding + tailSize / 2;

        const x: Partial<Rect> = {};
        switch (alignment) {
            case Alignments.Left:
                x.left = anchor.right - width + xTailOffset;
                x.right = anchor.right + xTailOffset;
                break;

            case Alignments.Center: {
                const center = (anchor.left + anchor.right) / 2;
                x.left = center - width / 2;
                x.right = center + width / 2;
                break;
            }

            case Alignments.Right:
                x.left = anchor.left - xTailOffset;
                x.right = anchor.left + width - xTailOffset;
                break;
        }

        const y: Partial<Rect> = {};
        switch (placement) {
            case Placements.Top:
                y.bottom = anchor.top - yPadding;
                y.top = anchor.top - height - yPadding;
                break;

            case Placements.Bottom:
                y.top = anchor.bottom + yPadding;
                y.bottom = anchor.bottom + height + yPadding;
                break;
        }

        return {
            ...x,
            ...y,
        } as Rect;
    }
}

function measureMarkup(cssClass: string, markup: string, maxWidth: number) {
    const element = document.createElement("div");
    element.style.visibility = "hidden";
    element.className = cssClass;
    element.innerHTML = markup;
    element.style.maxWidth = maxWidth + "px";
    document.body.appendChild(element);
    const result = {
        width: element.offsetWidth,
        height: element.offsetHeight
    };
    document.body.removeChild(element);
    return result;
}

type TooltipProps = {
    id?: string;
    content: string;
    rect: Rect;
    tail: Rect;
    onBlur: () => void;
    needsScrolling: boolean;
}

function Tooltip(props: TooltipProps) {
    const style = {
        top: props.rect.top,
        left: props.rect.left,
        width: props.rect.right - props.rect.left,
        height: props.rect.bottom - props.rect.top,
    };

    // Display spotlight ID in log if the user is an oniq
    // employee
    const session = useContext(SessionContext);
    if (isOniqEmployee(session) && props.id)
        // eslint-disable-next-line no-console
        console.log(`Displaying spotlight ${props.id}.${session.locale}.md`);

    const tailStyle = {
        top: props.tail.top,
        left: props.tail.left,
        width: props.tail.right - props.tail.left,
        height: props.tail.bottom - props.tail.top,
    };

    return <div className="spotlightOverlay" onClick={(e) => {
        props.onBlur();
        e.stopPropagation();
        e.preventDefault();
    }}>
        <div
            className="bubble"
            style={{ ...style }}>
        </div>
        <div className="tail" style={tailStyle} />
        <div className="tailCover" style={tailStyle} />

        <div className="spotlightContent" style={{ ...style }}>
            <div
                className={props.needsScrolling ? "scrollable" : ""}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onBlur();
                }}
                dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
    </div>;
}