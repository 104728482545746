import React, { useState } from "react";
import i18n from "../../i18n";

export type FormDeleteButtonProps = {
    label: string;
    confirmationLabel: string;

    disabled?: boolean;

    /**
     * Shows a spinner if true. Also disables the button.
     */
    spinning?: boolean;

    /**
     * Called when the button is clicked twice
     * @returns 
     */
    onClick?: () => void;
};

export function FormDeleteButton(props: FormDeleteButtonProps) {
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const label = isConfirming ? props.confirmationLabel : props.label;

    return <button
        type="button"
        disabled={props.disabled || props.spinning}
        className="shortcutButton formButtonDanger noWrap alignCenter"
        onMouseOut={() => setIsConfirming(false)}
        onBlur={() => setIsConfirming(false)}
        onClick={clickHandler}>
        {props.spinning && <div className="formButtonSpinner">
            <div className="dot-pulse" />
        </div>}
        {i18n.t(label)}
    </button>;

    function clickHandler() {
        if (isConfirming) {
            props.onClick?.();
        } else {
            setIsConfirming(true);
        }
    }
}