import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { Formatter } from "../../utils/Formatter";

export type ProgressProps = {
    percent: number;
    label?: string;
    numDigits?: number;
};

/**
 * This component displays a progress bar
 */
export default function Progress(props: ProgressProps) {
    const session = useContext(SessionContext);

    return <div
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={props.percent}
        className="progress">
        <div
            style={{
                width: Math.max(0, Math.min(100, props.percent)) + "%"
            }}
            className="bar" />
        <div className="value">{props.label ?? Formatter.formatPercent(props.percent, 100, props.numDigits ?? 1, session.numberFormatLocale)}</div>
        <div className="frame" />
    </div>;
}