import React from "react";
import i18n from "../../i18n";

export type NoDataAvailableProps = {
    title?: string;
    message?: string;
    visible: boolean;
}

export function NoDataAvailable(props: NoDataAvailableProps) {
    if (!props.visible)
        return null;
        
    return <div className="noDataAvailable">
        {props.title !== undefined ? i18n.t(props.title) : ""}
        {props.message !== undefined && <div>
            {i18n.t(props.message)}
        </div>}
    </div>;
}