/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";

export type GroupButtonPropsType = React.PropsWithChildren<{
    title: string;
    className?: string;
}>;
type GroupButtonStateType = {
    isExpanded: boolean;
};

export const GroupButton: React.FunctionComponent<GroupButtonPropsType> = (props) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [state, setState] = useState<GroupButtonStateType>({
        isExpanded: false
    });

    const rect = buttonRef.current?.getBoundingClientRect();

    return <div className="groupButton">
        <button className={"headerButton groupButton" + (props.className ? " " + props.className : "")}
            onClick={() => {
                setState({
                    ...state,
                    isExpanded: !state.isExpanded,
                });
            }}
            ref={buttonRef}>
            {props.title}
            <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-caret-down" /></svg>
        </button>
    
        <div 
            className={"overlay" + (!state.isExpanded ? " overlayInactive" : "")}
            onClick={() => {
                setState({
                    ...state,
                    isExpanded: false
                });
            }}
        >
            <div 
                style={{
                    top: ((rect?.top ?? 0) + (rect?.height ?? 0) + 1) + "px",
                    left: (rect?.left ?? 0) + "px",
                }}
                className="contentContainer">
                {props.children}
            </div>
        </div>
    </div>;
};