import { range } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { VictoryAxis, VictoryBar, VictoryBrushContainer, VictoryChart, VictoryLabel, VictoryStack } from "victory";
import { DomainTuple } from "victory-core";
import colors from "../../colors.json";
import VictoryThemeOniq from "../../styles/VictoryThemeOniq";

type DomainType = { x: DomainTuple; y: DomainTuple };
type DataType = { x?: string | number | Date, y?: number }[];

export type BarChartSelectorPropsType = {

    /**
     * Data provided in an array with objects for x and y content
     */
    data: DataType,

    /**
     * Data provided on unfiltered data in an array with objects for x and y content
     */
    dataNoFilter?: DataType,

    /**
     * Domain that is marked on the x-axis
     */
    brushDomain: DomainTuple,

    /**
     * Domain of the x-axis
     */
    xDomain?: [number, number],

    /**
     * Values for x-ticks
     */
     xTickValues?: number[],

    /**
     * Formatter of y-ticks. Can either be an array of numbers or
     * a function that takes the following possible arguments
     * tick: value, index: index of the tick, ticks: all ticks
     */
     xTickFormatter?: number[] | ((tick: number, index: number, ticks: number[]) => string | number)

    /**
     * Domain of the y-axis
     */
    yDomain?: [number, number],

    /**
     * Label of the y axis
     */
    yLabel?: string;

    /**
     * Values for y-ticks
     */
    yTickValues?: number[],

    /**
     * Formatter of y-ticks. Can either be an array of numbers or
     * a function that takes the following possible arguments
     * tick: value, index: index of the tick, ticks: all ticks
     */
    yTickFormatter?: number[] | ((tick: number, index: number, ticks: number[]) => string | number),

    /**
     * Labels for the the bars
     */
     barLabels?: string[] | number[],

    /**
     * Handler that should be called when the domain has changed.
     * It will be called with the new x-domain values.
     */
    onBrushDomainChange: (domain: [number, number]) => void,
};

export default function BarChartSelector(props: BarChartSelectorPropsType) {
    // The BarChartSelector needs to be placed in a flex container.

    const [histogramSize, setHistogramSize] = useState<{width?: number, height?: number}>();
    const xDomain = (props.xDomain === undefined || isNaN(props.xDomain[0]) || isNaN(props.xDomain[1]) || props.xDomain[0] === props.xDomain[1]) ? undefined : { x: props.xDomain, y: undefined};
    const yDomain = (props.yDomain === undefined || isNaN(props.yDomain[0]) || isNaN(props.yDomain[1]) || props.yDomain[0] === props.yDomain[1]) ? undefined : { y: props.yDomain, x: undefined};

    const yMax = (() => {
        const data = (props.data ?? [0]).concat(props.dataNoFilter ?? []).map(d => d.y ?? 0);
        return Math.max(...data);
    })();

    function onBrushDomainChange(domain: DomainType) {
        props.onBrushDomainChange(domain.x as [number, number]);
    }

    const containerRef = useRef<HTMLDivElement>(null);

    // Register observer in case the user has not specified width & height
    const observerRef = useRef(new ResizeObserver(e => {
        const { width, height } = e[0].contentRect;
        setHistogramSize({ width, height });
    }));

    useEffect(() => {
        if (containerRef.current)
            observerRef.current.observe(containerRef.current);

        return () => {
            if (observerRef.current)
                observerRef.current.disconnect();
        };
    }, [
        containerRef,
        observerRef,
    ]);

    return <div ref={containerRef} className="chartContainer">
        {histogramSize !== undefined && <VictoryChart
            theme={VictoryThemeOniq}
            width={histogramSize.width}
            height={histogramSize.height}
            padding={{
                left: 60,
                right: 10,
                top: 25,
                bottom: 20,
            }}
            containerComponent={
                <VictoryBrushContainer
                    responsive={false} // we always pass width and hight so no need to this
                    brushDimension="x"
                    brushDomain={{ x: props.brushDomain }}
                    brushStyle={{ fill: "black", fillOpacity: 0.1 }}
                    // below is the desired design which is still buggy (see https://github.com/FormidableLabs/victory/issues/1806)
                    handleComponent={<svg pointerEvents="none">
                        <line x1="50%" y1="0%" x2="50%" y2="100%" style={{ stroke: colors.$black }} />
                        <rect y={"40%"} height={"20%"} width={"100%"} style={{ stroke: colors.$black, fill: colors.$black }} />
                    </svg>}
                    // below is another possible implementation which unfortunately still has some glitches
                    // because the handleWidth can currently not be set in typescript
                    // this will be possible after https://github.com/FormidableLabs/victory/pull/1807
                    // handleComponent={<rect
                    //     style={{
                    //         fill: colors.$cTextSecondary,
                    //         strokeWidth: "6",
                    //         stroke: colors.$cTextSecondary,
                    //         height: 2,
                    //         strokeDasharray: "0 150 50 1000000"
                    //     }}
                    // />}
                    defaultBrushArea={"disable"}
                    onBrushDomainChangeEnd={onBrushDomainChange}
                />
            }>
            {!props.dataNoFilter && <VictoryBar
                data={props.data}
                x={"x"}
                y={"y"}
                labels={props.barLabels}
            />}
            {props.dataNoFilter && <VictoryStack >
                <VictoryBar
                    data={props.data}
                    x={"x"}
                    y={"y"}
                    labels={props.barLabels}
                />
                <VictoryBar
                    data={props.dataNoFilter}
                    x={"x"}
                    y={"y"}
                />
            </VictoryStack>}

            <VictoryAxis crossAxis
                fixLabelOverlap={true}
                domain={ xDomain }
                tickFormat={props.xTickFormatter}
                tickValues={props.xTickValues}
            />
            <VictoryAxis
                dependentAxis
                crossAxis={false}
                domain={ yDomain }
                offsetX={60}
                axisLabelComponent={<VictoryLabel dy={-20}/>}
                label={props.yLabel}
                tickFormat={props.yTickFormatter}
                tickValues={props.yTickValues ?? buildDefaultYTicks(histogramSize.height, yMax)}
            />
        </VictoryChart>}
    </div>;
}

/**
 * Builds a default tick list that does not provide more than one tick every minTickDistance pixels
 * @param height Height of the component
 * @param minTickDistance Minimum tick distance
 * @param padding to subtract from chart height
 * @param yMax maximum y value
 */
function buildDefaultYTicks(height: number | undefined, yMax: number, minTickDistance = 20, padding = 70) {
    let multiplyer = 0.01;
    while (multiplyer < Math.abs(yMax))
        multiplyer *= 10;

    const goodIncrements = [0.1, 0.2, 0.25, 0.5, 1];
    const unpaddedHeight = (height ?? 100) - padding;

    for (let i=0; i<goodIncrements.length; i++) {
        const ticks = Math.ceil((yMax / multiplyer) / goodIncrements[i]) + 1;
        if ((unpaddedHeight / ticks) > minTickDistance) {
            const result = range(0, ticks).map(v => Math.round(100 * v * goodIncrements[i] * multiplyer) / 100);
            return result;
        }
    }

    return [0, 1];
}
