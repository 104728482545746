import { SimpleCache } from "../utils/SimpleCache";
import sum from "hash-sum";

export type GeneratorFunc<T> = () => T;
let sharedRef: SimpleCache<string, any> | null = null;

/**
Caching hook. You can use this instead of useMemo for a better caching experience
 */
export function useCache<T>(gen: GeneratorFunc<T>, dependencies: any[], cacheSize = 100) {
    if (!sharedRef)
        sharedRef = new SimpleCache<string, any>(cacheSize);
    const cache = sharedRef as SimpleCache<string, T>;
    const key = sum(dependencies);
    if (cache.has(key))
        return cache.get(key)!;
    const value = gen();
    cache.set(key, value as any);
    return value;
}
