import { assign } from "lodash";
import colorJson from "../colors.json";

// *
// * Colors
// *
const labelColor = colorJson.$black;

const colors = [colorJson["$grayish-400"], colorJson["$gray-2"], colorJson["$green-2"], colorJson.$purple];

const primaryColor = colorJson["$grayish-400"];
const secondColor = colorJson["$green-2"];
const axisColor = colorJson["$gray-1"];
// *
// * Typography
// *
const sansSerif = "'OpenSans', sans-serif";
const letterSpacing = "normal";
export const fontSize = 12;
// *
// * Layout
// *
const baseProps = {
    padding: 35,
    colorScale: colors
};
// *
// * Labels
// *
export const baseLabelStyles = {
    fontFamily: sansSerif,
    fontSize,
    letterSpacing,
    padding: 5,
    fill: colorJson["$black"],
    stroke: "transparent"
};

const centeredLabelStyles = assign({ textAnchor: "middle" }, baseLabelStyles);
// *
// * Strokes
// *
const strokeLinecap = "round";
const strokeLinejoin = "round";

export default {
    area: assign(
        {
            style: {
                data: {
                    fill: primaryColor
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    axis: assign(
        {
            style: {
                axis: {
                    fill: "transparent",
                    stroke: axisColor,
                    strokeWidth: 1,
                    strokeLinecap,
                    strokeLinejoin,
                },
                axisLabel: assign({}, centeredLabelStyles, {
                    padding: 25,
                }),
                grid: {
                    fill: "none",
                    stroke: "none",
                    pointerEvents: "painted"
                },
                ticks: {
                    size: 0,
                    stroke: "white"
                },
                tickLabels: {
                    ...baseLabelStyles,
                    fill: labelColor,
                }
            }
        },
        baseProps
    ),
    bar: assign(
        {
            style: {
                data: {
                    fill: primaryColor,
                    padding: 8,
                    strokeWidth: 0
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    boxplot: assign(
        {
            style: {
                max: { padding: 8, stroke: primaryColor, strokeWidth: 1 },
                maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
                median: { padding: 8, stroke: primaryColor, strokeWidth: 1 },
                medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
                min: { padding: 8, stroke: primaryColor, strokeWidth: 1 },
                minLabels: assign({}, baseLabelStyles, { padding: 3 }),
                q1: { padding: 8, fill: secondColor },
                q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
                q3: { padding: 8, fill: secondColor },
                q3Labels: assign({}, baseLabelStyles, { padding: 3 })
            },
            boxWidth: 20
        },
        baseProps
    ),
    candlestick: assign(
        {
            style: {
                data: {
                    stroke: primaryColor,
                    strokeWidth: 1
                },
                labels: assign({}, baseLabelStyles, { padding: 5 })
            },
            candleColors: {
                positive: "#ffffff",
                negative: primaryColor
            }
        },
        baseProps
    ),
    chart: baseProps,
    errorbar: assign(
        {
            borderWidth: 8,
            style: {
                data: {
                    fill: "transparent",
                    stroke: primaryColor,
                    strokeWidth: 2
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    group: assign(
        {
            colorScale: colors
        },
        baseProps
    ),
    histogram: assign(
        {
            style: {
                data: {
                    fill: secondColor,
                    stroke: primaryColor,
                    strokeWidth: 2
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    legend: {
        colorScale: colors,
        gutter: 10,
        orientation: "vertical",
        titleOrientation: "top",
        style: {
            data: {
                type: "circle"
            },
            labels: baseLabelStyles,
            title: assign({}, baseLabelStyles, { padding: 5 })
        }
    },
    line: assign(
        {
            style: {
                data: {
                    fill: "transparent",
                    stroke: primaryColor,
                    strokeWidth: 2
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    pie: {
        style: {
            data: {
                padding: 10,
                stroke: "transparent",
                strokeWidth: 1
            },
            labels: assign({}, baseLabelStyles, { padding: 20 })
        },
        colorScale: colors,
        width: 400,
        height: 400,
        padding: 50
    },
    scatter: assign(
        {
            style: {
                data: {
                    fill: primaryColor,
                    stroke: "transparent",
                    strokeWidth: 0
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    stack: assign(
        {
            colorScale: colors
        },
        baseProps
    ),
    tooltip: {
        style: assign({}, baseLabelStyles, { padding: 0, pointerEvents: "none" }),
        flyoutStyle: {
            stroke: primaryColor,
            strokeWidth: 1,
            fill: "#f0f0f0",
            pointerEvents: "none"
        },
        flyoutPadding: 5,
        cornerRadius: 5,
        pointerLength: 10
    },
    voronoi: assign(
        {
            style: {
                data: {
                    fill: "transparent",
                    stroke: "transparent",
                    strokeWidth: 0
                },
                labels: assign({}, baseLabelStyles, { padding: 5, pointerEvents: "none" }),
                flyout: {
                    stroke: primaryColor,
                    strokeWidth: 1,
                    fill: "#f0f0f0",
                    pointerEvents: "none"
                }
            }
        },
        baseProps
    ),
    domain: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 1,
    }
};
