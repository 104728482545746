import React, { useContext } from "react";
import { RcaType } from "../../contexts/ContextTypes";
import { SettingsContext, getRecentRcaByType } from "../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../contexts/ContextTypes";
import CreateRootCauseAnalysis from "./CreateRootCauseAnalysis";
import RootCauseAnalysisResults from "./RootCauseAnalysisResults";
import { BaseQuantityType } from "../../models/ApiTypes";

export default function RootCauseAnalysis(props: {
    title: string;
    type: RcaType,
    baseQuantities?: BaseQuantityType[],
    onTabChange?: (tab: number) => void,
    analyzedValue?: LegacyAnalyzedValues;
}) {
    const settings = useContext(SettingsContext);
    const rca = getRecentRcaByType(props.type, settings);

    if (!rca)
        return <></>;

    const isShowValidResults = rca.id !== undefined && rca.showResults === true && rca.status !== "failed";

    return <>
        {(!isShowValidResults) && <CreateRootCauseAnalysis title={props.title} baseQuantities={props.baseQuantities} type={props.type} analyzedValues={props.analyzedValue} drilldown={rca.drilldown} />}
        {(isShowValidResults) && <RootCauseAnalysisResults type={props.type} />}
    </>;
}
