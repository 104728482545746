
const uuidRegex = /\/[a-f0-9-]{36}/g;
const digitRegex = /\/\d+/g;

/**
 * Replace uuids and numbers with placeholders
 */
export function lowerCardinality(s: string) {
    return s.replace(uuidRegex, "/<id>").replace(digitRegex, "/<d>");
}

/**
 * Get pure path (no url host, no query strings in case of urls) with uuids and numbers replaced with placeholders
 */
export function lowerCardinalityPath(s: string) {
    let path = s;
    try {
        path = new URL(s).pathname;
    } catch {
        // if it's not a url that's ok
    }
    return lowerCardinality(path);
}
