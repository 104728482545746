import React, { useContext } from "react";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { KpiComparisons } from "../../../contexts/ContextTypes";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { defaultCaseLimit } from "../../../Global";
import { useCases } from "../../../hooks/UseCases";
import i18n from "../../../i18n";
import { buildKpiSpotlightId, busyTimeSubtypes, delayTimeSubtypes, getKpiDefinition, getUnit } from "../../../models/Kpi";
import { KpiTypes, SortOrder, StatisticTypes } from "../../../models/KpiTypes";
import { getCaseStats, getPropName } from "../CaseProcessKpiChart";
import SubTimeStats from "./SubTimeStats";

export function CaseStats() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning;

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    const disable = settings.selection.case === undefined;

    const orderProp = settings.kpi.sortOrder === SortOrder.Ascending ? "" : "-";
    const sortProp = getPropName(session, settings);

    const [caseStats, isLoading] = useCases({
        sort: [orderProp + sortProp],
        limit: defaultCaseLimit,
        calculatePlanned: isPlanningComparison,
        calculateDeviations: isPlanningComparison,
        ...kpiDefinition?.apiParameters,
        customKpis: kpiDefinition?.caseCustomKpis,
    }, {
        disable,
    });

    const selectedCase = (caseStats?.cases ?? []).find(c => c.id === settings.selection.case);
    if (!selectedCase && !isLoading)
        return <></>;
    
    const statsTranslations: Map<StatisticTypes, string> = new Map([
        [StatisticTypes.Mean, "common.statistics.mean"],
        [StatisticTypes.Sum, "common.statistics.sum"],
    ]);

    const value = getCaseStats(selectedCase?.actual, kpiDefinition);
    const plannedValue = getCaseStats(selectedCase?.planned, kpiDefinition);

    return <StatsSection title={i18n.t("common.case") + " " + settings.selection.case}>
        <StatsSubSection title={kpiDefinition?.label} spotlightId={buildKpiSpotlightId(settings.kpi.selectedKpi)}>
            <StatsRowValue label={i18n.t(statsTranslations.get(settings.kpi.statistic) ?? "common.statistics.sum")} isLoading={isLoading} value={value} unit={unit} isHighlight={true}/>
        </StatsSubSection>

        {settings.kpi.selectedKpi === KpiTypes.BusyTime && <SubTimeStats timeComponent={KpiTypes.BusyTime} subComponents={busyTimeSubtypes} isLoading={isLoading} selection={selectedCase?.actual} />}
        {settings.kpi.selectedKpi === KpiTypes.FailureTime && <SubTimeStats timeComponent={KpiTypes.FailureTime} title="common.losses" subComponents={delayTimeSubtypes} isLoading={isLoading} selection={selectedCase?.actual} />}

        {isPlanningComparison && kpiDefinition?.allowedComparisons.includes(KpiComparisons.Planning) && <StatsSubSection title={kpiDefinition?.labelPlan} spotlightId={buildKpiSpotlightId(settings.kpi.selectedKpi)} >
            <StatsRowValue label={i18n.t(statsTranslations.get(settings.kpi.statistic) ?? "common.statistics.sum")} isLoading={isLoading} value={plannedValue} unit={unit} isHighlight={true} na={"common.notAvailableAbbreviated"}/>
        </StatsSubSection>}

    </StatsSection>
    
    ;
}
