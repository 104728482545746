import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Padding } from "../graph/GraphCommon";

export type ScrollbarState = {
    from: number;
    to: number;
    max: number;
}

export type ScrollbarProps = {
    initialState: ScrollbarState;
    onChange?: (state: ScrollbarState) => void;
    padding?: Padding;
    orientation?: "horizontal" | "vertical";
}

export interface IScrollbar {
    setOffset(offset: number): void;
}

export const Scrollbar = React.forwardRef((props: ScrollbarProps, ref: React.Ref<IScrollbar>) => {
    const [state] = useState<ScrollbarState>(props.initialState);
    const containerRef = useRef<HTMLDivElement>(null);

    const isHorizontal = props.orientation !== "vertical";

    useImperativeHandle(ref, () => ({
        setOffset(offset) {
            if (containerRef.current)
                if (isHorizontal)
                    containerRef.current.scrollLeft = offset;
                else
                    containerRef.current.scrollTop = offset;
        },
    }));

    useEffect(() => {
        if (containerRef.current)
            if (isHorizontal)
                containerRef.current.scrollLeft = state.from;
            else
                containerRef.current.scrollTop = state.from;
    }, [
        state,
        containerRef.current,
    ]);

    const style = isHorizontal ? {
        bottom: 0,
        left: props.padding?.left ?? 0,
        right: props.padding?.right ?? 0,
    } : {
        right: 0,
        top: props.padding?.top ?? 0,
        bottom: props.padding?.bottom ?? 0,
    };

    return <div
        className={`scrollbar ${props.orientation ?? "horizontal"}`}
        ref={containerRef}
        style={style}
        onScroll={(e) => {
            const div = e.target as HTMLDivElement;

            if (props.onChange) {
                if (isHorizontal)
                    props.onChange({
                        ...state,
                        from: div.scrollLeft,
                        to: div.scrollLeft + div.clientWidth,
                    });
                else
                    props.onChange({
                        ...state,
                        from: div.scrollTop,
                        to: div.scrollTop + div.clientHeight,
                    });
            }
        }}>
        <div
            className="demander"
            style={{
                width: isHorizontal ? `${state.max}px` : undefined,
                height: isHorizontal ? undefined : `${state.max}px`,
            }}
        />
    </div>;
});