import React, { useImperativeHandle, useState } from "react";
import i18n from "../../i18n";
import { isPromise } from "../../utils/Utils";

export interface IModal {
    show: () => void;
    hide: () => void;
}

export enum ModalTypes {
    Info,
    Error,
}

export type ModalProps = {
    title?: string;
    darkMode?: boolean;
    icon?: string;
    defaultButtonLabel?: string;
    children: JSX.Element | JSX.Element[];
    canBlur?: boolean;
    isOkDisabled?: boolean;
    type?: ModalTypes;
    isClosable?: boolean;
    showCancelButton?: boolean;

    /**
     * Called when the window is about to disappear
     */
    onDone?: () => Promise<void>;

    /**
     * Invoked when the default button is clicked. Return true if you want the window
     * to stay open, everything else if you want it to close.
     * @returns true if you want to prevent this window from being closed
     */
    onDefaultButtonClicked?: () => (boolean | undefined | void) | Promise<boolean | undefined | void>;
    onCancel?: () => void;
    width?: number;

    bottom?: JSX.Element | JSX.Element[];
    bottomClassName?: string;
    /**
     * If true, the modal is visible. If false, it's hidden. If undefined, you have to programmatically 
     * show or hide it, using the interface exposed.
     */
    isVisible?: boolean;
}

/**
 * This message modal supports two workflows: You can either control it's visibility via the `isVisible` property,
 * or you may refer to the exposed IModal interface and use it's `show()` and `hide()` methods.
 * 
 * However, mixing these approaches is a certain path towards doom and misery. Don't do it.
 */
export const Modal = React.forwardRef((props: ModalProps, ref: React.Ref<IModal>) => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [showOkButtonSpinner, setShowOkButtonSpinner] = useState(false);

    useImperativeHandle(ref, () => ({
        show() {
            setIsShowing(true);
        },
        hide() {
            setIsShowing(false);
        }
    }));

    if ((props.isVisible !== undefined && props.isVisible === false) || (props.isVisible === undefined && !isShowing))
        return <></>;

    const typeClass = props.darkMode ? "modalDark" : "modalLight";

    const icon = props.icon ?? (props.type === ModalTypes.Error ? "warning" : undefined);

    const showCancelButton = props.showCancelButton && props.onCancel !== undefined;

    return <div className="modal">
        <div className={"modalContainer " + typeClass} style={{
            width: props.width
        }}>
            {props.isClosable === true && <div className="closer brandHover" onClick={() => {
                setIsShowing(false);
                if (props.onCancel)
                    props.onCancel();
            }}><svg className="svg-icon xsmall hoverOpacity"><use xlinkHref={"#radix-cross-1"} /></svg></div>}

            {props.title && <h1 className="title">{i18n.t(props.title)}</h1>}
            <div className={"modalContent" + (icon ? " modalContentIcon" : "")}>
                {icon !== undefined && <div style={{ display: "flex" }}><svg className="svg-icon large"><use xlinkHref={"#" + icon} /></svg></div>}
                {props.children}
            </div>

            <div className={"bottom" + (props.bottomClassName ? " " + props.bottomClassName : "")}>
                {props.bottom !== undefined && props.bottom}
                <button
                    data-testid="modal-ok-button"
                    disabled={props.isOkDisabled || showOkButtonSpinner}
                    className="shortcutButton"
                    onClick={async () => {
                        if (props.onDefaultButtonClicked) {
                            const result = props.onDefaultButtonClicked();
                            // check if result is a promise
                            const preventClosing = (isPromise(result) ? await result : result) === true;
                            if (preventClosing)
                                return;
                        }

                        if (props.onDone) {
                            const result = props.onDone();
                            if (result) {
                                setShowOkButtonSpinner(true);
                                await result;
                                setShowOkButtonSpinner(false);
                            }
                        }
                        setIsShowing(false);

                    }}>
                    {showOkButtonSpinner && <div className="buttonSpinner">
                        <div className="dot-pulse" />
                    </div>}
                    {i18n.t(props.defaultButtonLabel ?? "common.OK")}
                </button>

                {showCancelButton && <button
                    data-testid="modal-cancel-button"
                    className="shortcutButton"
                    onClick={() => {
                        setIsShowing(false);
                        props.onCancel!();
                    }}>{i18n.t("common.cancel")}</button>}
            </div>
        </div>
    </div>;
});

export default Modal;