import { useContext } from "react";
import { disableAllCalcOptions, PerProductCaseStatisticsClusteringParams, PerProductCaseStatisticsClusteringResponseSchema } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { ApiHookOptions, useApi } from "./UseApi";

export function useParetoClustering(request: Partial<PerProductCaseStatisticsClusteringParams> = {}, options?: ApiHookOptions<PerProductCaseStatisticsClusteringResponseSchema>):
    [PerProductCaseStatisticsClusteringResponseSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: PerProductCaseStatisticsClusteringParams = {
        ...disableAllCalcOptions,
        ...request,
        statistic: request.statistic ?? "count",
        percentileCuts: request.percentileCuts ?? [0, 80, 95],
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request.eventKeys ?? session.project?.eventKeys ?? { } as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        consolidatePasses: request.consolidatePasses ?? groupSupportsConsolidatePasses(request.eventKeys?.activityKeysGroup),
        sort: request.sort ?? ["-count"],
        limit: 10000,
    };

    const setDisabled = options?.disable || requestOptions.eventKeys === undefined || requestOptions.uploadId === undefined;

    return useApi(Datastores.paretoClustering, requestOptions, [ 
        JSON.stringify(requestOptions)
    ], {
        ...options,
        disable: setDisabled,
    });
}