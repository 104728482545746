import { FeatureImportance, FeatureType } from "../../models/ApiTypes";
import { SessionContextType } from "../../contexts/SessionContext";
import { ProductIdentifier, SelectionType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { Graph, GroupingKeys } from "../../models/Dfg";
import { EventFilter } from "../../models/EventFilter";
import { buildEdgeFilter, buildNodeFilter, buildProductCategoryFilter, buildProductFilter } from "../../utils/FilterBuilder";


export enum RcaSelectionType {
   Node = "node",
   Category = "category",
   Edge = "edge",
   Product = "product",
   Reason = "reason",
   Time = "time"
}

/**
 * Data needed to synchronize rca selection with other general selections
 */
export type RcaSelectionSyncData = {
    graph: Graph
    productColumn?: string
    products: ProductIdentifier[]
};

/**
 * Sync both the current selection and some given rca features.
 * 
 * Within the application, we need to make sure that: 
 * - a selection of a node will cause the selection of a matching RCA feature
 * - a selection with an RCA feature that references a node will cause the selection of that node as well
 */
export function getRcaSelectionSyncUpdate(rcaFeatures: FeatureImportance[], selection: SelectionType, data: RcaSelectionSyncData) {
    // First check if any feature that is available matches an already selected
    // entity (node, edge and so on), in which case we want to update the
    // selection to that.
    const selectedFeature = (rcaFeatures || []).find((rcaFeature) => isFeatureInSelection(rcaFeature, selection));
    // If so, make sure that that feature is also added to the selection and any
    // corresponding other selection (node/edge and so on) are also selected.
    if (selectedFeature !== undefined)
        return rcaFeatureToSelectionUpdate(selectedFeature, data);
    
    // Otherwise deselect any rca features.
    return {...selection, feature: undefined};
}

/**
 * Given an rca feature, construct an update for the selection.
 *
 * The selection will contain both the feature and any other attribute that is
 * selected based on the direct association with the feature (for example a
 * machine profile feature will also select a machine node and so on).
 */
export function rcaFeatureToSelectionUpdate(rcaFeature: FeatureImportance, data: RcaSelectionSyncData): Partial<SelectionType> {
    if (!rcaFeature || !rcaFeature.feature) {
        return {};
    }
    const edgeFeature = findEdgeFeature(rcaFeature.feature);
    if (edgeFeature) {
        const edge = data.graph?.multiEdges.find(e => e.from === edgeFeature?.edge?.fromNode?.activity && e.to === edgeFeature?.edge.toNode?.activity);
        return {
            edge,
            feature: rcaFeature
        };
    }

    if (rcaFeature.feature.nodeOccurrence) {
        const nodeId = rcaFeature.feature.nodeOccurrence.nodeValues?.activity;
        const node = data.graph?.nodes.find(node => node.id === nodeId);
        return {
            node,
            feature: rcaFeature
        };
    }

    if (rcaFeature.feature.caseAttribute) {
        // We currently only support product and product categories as case attribute features
        // so we can make some assumptions here
        const category = rcaFeature.feature.caseAttribute?.columnValue!.column.toString();
        const categoryValue = rcaFeature.feature.caseAttribute?.columnValue!.value.toString();

        if (!category || !categoryValue)
            return {};

        if (category === data.productColumn) {
            // Product selected
            const product = (data.products ?? []).find(p => p.name === categoryValue);
            return {
                product,
                feature: rcaFeature
            };
        } else {
            // Product category selected
            return {
                category,
                categoryValue,
                feature: rcaFeature
            };
        }
    }

    return {
        feature: rcaFeature
    };
}


export function isFeatureInSelection(rcaFeature: FeatureImportance, selection: SelectionType) {
    if (selection.category && selection.categoryValue) {
        const value = rcaFeature.feature?.caseAttribute?.columnValue!.value;
        if (value === selection.categoryValue)
            return true;
    }

    if (selection.product) {
        const value = rcaFeature.feature?.caseAttribute?.columnValue!.value.toString();
        if (value === selection.product.name)
            return true;
    }

    if (selection.node) {
        const nodeId = rcaFeature.feature?.nodeOccurrence?.nodeValues?.activity;
        if (selection.node.id === nodeId) {
            return true;
        }
    }

    if (selection.edge) {
        const edgeFeature = findEdgeFeature(rcaFeature.feature);
        if (
            edgeFeature?.edge.fromNode.activity === selection.edge.from &&
            edgeFeature?.edge.toNode.activity === selection.edge.to
        )
            return true;
    }

    if (selection.feature?.feature !== undefined) {
        if (isFeatureTargetEqual(rcaFeature.feature, selection.feature.feature))
            return true;
    }

    return false;
}


function isFeatureTargetEqual(a: FeatureType, b: FeatureType) {
    // we need to check both objects keys in case they are not symmetrical
    const keys = new Set([...Object.keys(a), ...Object.keys(b)]);
    for (const key of keys) {
        const value = a[key];
        const compareValue = b[key];
        if (
            value?.columnValue?.column !== compareValue?.columnValue?.column ||
            value?.columnValue?.value !== compareValue?.columnValue?.value ||
            value?.name !== compareValue?.name
        )
            return false;
    }
    return true;
}

export function getRcaSelectionType(selection: SelectionType, allowedSelectionTypes?: RcaSelectionType[]): RcaSelectionType | undefined {
    if (allowedSelectionTypes !== undefined) {
        const rcaSelectionType = getRcaSelectionType(selection);
        return (rcaSelectionType !== undefined && allowedSelectionTypes.includes(rcaSelectionType)) ? rcaSelectionType : undefined;
    }
    if (!selection.feature)
        return undefined;

    if (selection.node !== undefined) {
        return RcaSelectionType.Node;
    }
    if (selection.categoryValue !== undefined && selection.category !== undefined) {
        return RcaSelectionType.Category;
    }
    if (selection.product !== undefined) {
        return RcaSelectionType.Product;
    }
    if (selection.edge !== undefined) {
        return RcaSelectionType.Edge;
    }
    if (getReasonFromFeature(selection.feature.feature)?.columnValue) {
        return RcaSelectionType.Reason;
    }
    if (selection.feature?.feature?.timeUsage ?? selection.feature?.feature?.absoluteTimeUsage) {
        return RcaSelectionType.Time;
    }
    if (selection.feature.feature.nodeOccurrence || selection.feature?.feature?.nodeSetupTime || selection.feature?.feature?.nodeFailureTime || selection.feature?.feature?.nodeInterruptionTime || selection.feature?.feature.nodePassChangeTime || selection.feature?.feature.nodeProductionTime) {
        return RcaSelectionType.Node;
    }
}


export function getRcaSelectionTitle(rcaSelection: RcaSelectionType, titles: { [key in RcaSelectionType]?: string; }) {
    if (!rcaSelection)
        return "";
    const title = titles[rcaSelection];
    return title ? i18n.t(title) : "";
}

/**
 * Builds a filter that let's pass only cases that have the given machine, category or reason
 * @param graph The machine-grouped graph. Needed for building edge filters.
 * @returns Filter instance or undefined
 */
export function buildRcaSelectionFilter(selection: SelectionType | undefined, session: SessionContextType, graph: Graph | undefined): EventFilter | undefined {
    if (!selection?.feature)
        return;

    if (selection.node)
        return buildNodeFilter(selection.node, false, GroupingKeys.Machine);

    if (selection?.category && selection?.categoryValue)
        return buildProductCategoryFilter(selection.category ?? "", [selection.categoryValue ?? ""], false, session);

    if (selection?.product)
        return buildProductFilter(selection.product, false, session);

    if (selection.edge) {
        const fromNode = graph?.nodes.find(n => n.id === selection.edge?.from);
        const toNode = graph?.nodes.find(n => n.id === selection.edge?.to);

        if (!fromNode || !toNode)
            return undefined;

        return buildEdgeFilter(fromNode, toNode, false, GroupingKeys.Machine);
    }

    if (selection.feature) {
        const reason = getReasonFromFeature(selection.feature.feature);
        if (reason?.columnValue)
            return buildProductCategoryFilter(reason.columnValue.column, [reason.columnValue.value], false, session);
    }
}

export enum DefinedPropsTypes {
    Frequency,
    Duration,
}

export function getDefinedProps(feature: FeatureType | undefined, type: DefinedPropsTypes) {
    if (!feature)
        return;

    const suffix = type === DefinedPropsTypes.Duration ? "ReasonsDuration" : "ReasonsFrequency";

    for (const prop of Object.getOwnPropertyNames(feature)) {
        if (prop.indexOf(suffix) >= 0 && !!feature[prop])
            return prop.replace(suffix, "");
    }
}

/**
 * Check if a feature type of the feature has the "smallValues" flag set.
 *
 * From API docs: If 'true', smaller values of this feature are driving the predicted value.
 */
export function hasFeatureSmallValue(feature?: FeatureType) {
    if (feature === undefined)
        return false;

    for (const val of Object.values(feature))
        if (val && ("smallValues" in val) && val.smallValues)
            return true;

    return false;
}

/**
 * Obtain a reason feature type from the feature if it exists.
 *
 * Reason features are defined by name structure i.e. setupReasonsFrequency,
 * failureReasonstDuration.
 */
export function getReasonFromFeature(feature?: FeatureType) {
    if (feature === undefined)
        return undefined;

    for (const [key, val] of Object.entries(feature)) {
        if (key.toLowerCase().includes("reason") && val !== undefined && val !== null) {
            return val;
        }
    }
}



/**
 * Get all feature components that apply to edges
 */
export function getEdgeFeatures(feature: FeatureType | undefined) {
    return feature !== undefined ? [feature.edgeDuration, feature.edgeOccurrence, feature.edgeDurationDeviation] : [];
}

/**
 * Find first defined edge feature available if possible
 */
export function findEdgeFeature(feature: FeatureType | undefined) {
    return getEdgeFeatures(feature).find(f => f !== undefined && f !== null);
}

/**
 * Influence is the relevance of a feature or the negative relevance if minimized.
 */
export function calculateInfluence(relevance: number | undefined, maximize = true) {
    if (relevance === undefined) return undefined;
    return maximize ? relevance : -relevance;
}
