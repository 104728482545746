import React, { useContext } from "react";
import { StatsRowValue, StatsRowValueSigned } from "../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Formatter } from "../../utils/Formatter";
import { useCases } from "../../hooks/UseCases";

export function SideStatisticsProjectDeviation() {
    const settings = useContext(SettingsContext);

    const [cases, isLoading] = useCases({
        eventFilters: settings.previewFilters ?? settings.filters,
        calculateTimeAndFreqStats: true,
        calculateDeviations: true,

        limit: 0,
    });

    return <div data-testid="sidePanel">
        <StatsSection title="common.totalProcess" >

            <StatsSubSection title="workflows.planningDeviation.averageDeviationDuration">
                <StatsRowValueSigned label="common.allCases" isLoading={isLoading} unit={Formatter.units.durationShort}
                    isHighlight={true}
                    value={cases?.log?.deviation?.durationStatistics?.mean}
                />
            </StatsSubSection>

            <StatsSubSection title="common.caseCount">
                <StatsRowValue label="common.allCases" isLoading={isLoading} unit={Formatter.units.number}
                    value={cases?.log?.actual?.count}
                />
            </StatsSubSection>

        </StatsSection>
    </div>;
}
