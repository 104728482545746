import React, { PropsWithChildren } from "react";

export type FormButtonProps = PropsWithChildren<{
    className?: string;
    disabled?: boolean;
    
    /**
     * Shows a spinner if true. Also disables the button.
     */
    spinning?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    onClick?: () => void;
}>;

export function FormButton(props: FormButtonProps) {
    const classes = [
        "shortcutButton"
    ];

    if (props.className)
        classes.push(props.className);

    return <button
        type={props.type}
        disabled={props.disabled || props.spinning}
        className={classes.join(" ")}
        onClick={props.onClick}>
        {props.spinning && <div className="formButtonSpinner">
            <div className="dot-pulse" />
        </div>}
        {props.children}
    </button>;
}