import axios, { AxiosError } from "axios";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { disableAllCalcOptions, GanttChartRequestType, GanttEntry, TimeMode } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../contexts/SettingsContext";
import { GroupingKeys } from "../models/Dfg";
import { Datastores } from "../utils/Datastores";
import { getActivityLabelFromActivityValues } from "../utils/GroupingUtils";
import { getIsGroupingValid } from "../utils/Initializers";
import { useMountedState } from "./UseMounted";

export function useGantt(options?: Partial<GanttChartRequestType>, disable = false, onError?: (error: AxiosError) => void): [GanttEntry[] | undefined, boolean] {
    const sessionContext = useContext(SessionContext);
    const settingsContext = useContext(SettingsContext);

    const isMounted = useMountedState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gantt, setGantt] = useState<GanttEntry[] | undefined>();

    const filters = options?.eventFilters ?? settingsContext.previewFilters ?? settingsContext.filters;

    const [subscriptionId] = useState(() => { return Datastores.ganttChart.getSubscriptionId(); });
    useEffect(() => {
        return () => {
            Datastores.ganttChart.cancelSubscription(subscriptionId);
        };
    }, []);

    useEffect(() => {
        if (!sessionContext.project?.eventKeys ||
            !sessionContext.project?.uploadId ||
            !getIsGroupingValid(sessionContext.project.eventKeys, settingsContext.groupingKey) ||
            disable)
            return;

        setIsLoading(true);

        const request = {
            ...disableAllCalcOptions,
            ...options,
            eventFilters: filters,
            eventKeys: {
                ...sessionContext.project?.eventKeys,
                activityKeysGroup: settingsContext.groupingKey,
                ...options?.eventKeys,
            },
            uploadId: options?.uploadId ?? sessionContext.project?.uploadId,
            uploads: sessionContext.project?.uploads,
            timeMode: options?.timeMode ?? settingsContext.gantt.timeMode,
            limit: options?.limit ?? 500,
        };

        Datastores.ganttChart.get(request, subscriptionId).then((data) => {
            // Parse timestamps as needed
            if (settingsContext.gantt.timeMode === TimeMode.Absolute) {
                const newState = data.map((entry) => {
                    return {
                        ...entry,
                        events: entry.events.map((e) => [
                            DateTime.fromISO(String(e[0])).toUTC().toSeconds(),
                            DateTime.fromISO(String(e[1])).toUTC().toSeconds(),
                            e[2]
                        ]),
                    } as GanttEntry;
                });

                setGantt(sortData(newState, settingsContext));
            }
            else
                setGantt(sortData(data as GanttEntry[], settingsContext));
        }).catch((error) => {
            // Supress cancellations
            if (axios.isCancel(error))
                return;

            if (onError)
                onError(error as AxiosError);
            else    
                throw error;
        }).finally(() => {
            if (isMounted())
                setIsLoading(false);
        });
    }, [
        sessionContext.project?.eventKeys,
        sessionContext.project?.uploadId,
        JSON.stringify(sessionContext.project),
        filters,
        settingsContext.groupingKey,
        settingsContext.gantt.timeMode,
        settingsContext.apiRetry,
    ]);

    return [gantt, isLoading];
}

function sortData(newState: GanttEntry[], settingsContext: SettingsType): GanttEntry[] {
    return newState.sort((a, b) => {
        const aLabel = getActivityLabelFromActivityValues(a.activityValues, settingsContext.groupingKey ?? GroupingKeys.None);
        const bLabel = getActivityLabelFromActivityValues(b.activityValues, settingsContext.groupingKey ?? GroupingKeys.None);

        return aLabel.localeCompare(bLabel);
    });
}
