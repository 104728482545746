import React, { useContext, useState } from "react";
import { BomGraph } from "../../components/bom-graph/BomGraph";
import { ProductDropdown } from "../../components/product-dropdown/ProductDropdown";
import { SelectionPage } from "../../components/selection-page/SelectionPage";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, ProductIdentifier, getPushedHistory } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { isProductFilter, buildProductFilter, isUpstreamProductFilter, getCaseFilter } from "../../utils/FilterBuilder";
import { useNavigate } from "react-router-dom";
import { navigateWith } from "../../components/tabbed-view/TabbedView";
import { getHash } from "../../utils/Utils";
import { NodeRoles } from "../../models/Dfg";
import Shortcuts, { ActionItem, ActionType, ShortcutContexts } from "../../components/shortcuts/Shortcuts";

export function BillOfMaterialsPage() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const navigate = useNavigate();

    if (!settings.supplyChain.selectedProduct)
        return <BomProductSelection />;

    const isNodeSelected = settings.selection.node !== undefined && settings.selection.node.role !== NodeRoles.Inventory;

    const upstreamFilters = settings.filters.filter(f => isUpstreamProductFilter(f, session.project?.eventKeys));
    const isNodeIncludedInProductFilter = isNodeSelected && upstreamFilters.some(f => getCaseFilter(f.filters?.[0])?.eq?.map(e => e?.toString())?.includes(settings.selection.node?.name ?? ""));
    const isSelectionMainSelectedProduct = settings.selection.node?.name === settings.supplyChain.selectedProduct;

    const isOnlyBomProduct = settings.selection.node?.count === undefined;
    const isUpstreamProductAvailable = !!settings.selection.node?.hasIncomingEdges;

    const showAddInputProductsButton = isNodeSelected && !isNodeIncludedInProductFilter && !isSelectionMainSelectedProduct && (!isOnlyBomProduct || isUpstreamProductAvailable);

    const actions: ActionItem[] = [];

    const addInputProductsButton = showAddInputProductsButton ?
        <button
            className="shortcutButton"
            onClick={() => {
                // We are filtering by the product name here, so we need to
                // use the name instead of the node id.
                if (!settings.selection.node?.name)
                    return;


                function add<T>(arr: T[], element: T) {
                    if (!arr?.includes(element))
                        arr.push(element);
                }

                // Add product to existing filter
                const t = settings.filters.find(f => isUpstreamProductFilter(f, session.project?.eventKeys));
                const existingProductFilter = t ? JSON.parse(JSON.stringify(t)) : undefined;
                if (existingProductFilter?.filters?.[0]?.caseAttributeText?.eq)
                    add(existingProductFilter.filters[0].caseAttributeText.eq, settings.selection.node.name);

                if (existingProductFilter?.filters?.[0]?.caseAttributeInt?.eq)
                    add(existingProductFilter.filters[0].caseAttributeInt.eq, +settings.selection.node.name);

                if (existingProductFilter?.filters?.[0]?.caseAttributeFloat?.eq)
                    add(existingProductFilter.filters[0].caseAttributeFloat.eq, +settings.selection.node.name);

                if (existingProductFilter?.filters?.[0]?.caseAttributeBool?.eq)
                    add(existingProductFilter.filters[0].caseAttributeBool.eq, settings.selection.node.name === "true");

                // Replace filter instance
                const newFilters = settings.filters.map(f => {
                    if (isUpstreamProductFilter(f, session.project?.eventKeys))
                        return existingProductFilter ?? f;
                    return f;
                });

                if (getHash(newFilters) === getHash(settings.filters))
                    return;

                navigateWith(settings, navigate, {
                    filters: newFilters,
                    history: getPushedHistory(location.pathname, settings),
                    selection: {}
                }, `/projects/${session.projectId}/supply-chain/bom`);
            }
            }>
            {i18n.t("shortcuts.showInputProducts")}
        </button>
        : undefined;

    return <>
        <BomGraph />
        <Shortcuts additionalButtons={addInputProductsButton} disabledActionTypes={[ActionType.Filter]} actions={actions} handledSelections={[ShortcutContexts.ProductNodes]} />
    </>;
}

function BomProductSelection() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [product, setProduct] = useState<string>(() => {
        const productFilter = settings.filters.find(f => !!isProductFilter(f, false, session.project?.eventKeys)?.length);
        const products = isProductFilter(productFilter, false, session.project?.eventKeys);

        // Currently we support only selections of single products
        if (products?.length === 1)
            return products[0];

        return "";
    });

    const filtersWithoutProductFilters = settings.filters.filter(f => {
        const r = isProductFilter(f, false, session.project?.eventKeys);
        return r === undefined || r.length === 0;
    });

    // Display product selection
    return <SelectionPage
        title={i18n.t("supplyChain.landingPageTitle").toString()}
        description={i18n.t("supplyChain.description").toString()}
        startButton={<button
            className="shortcutButton"
            disabled={!product}
            onClick={() => {
                startWithProduct(product);
            }}>
            {i18n.t("supplyChain.startAnalysis").toString()}
        </button>}
        selectorComponent={<ProductDropdown
            filters={filtersWithoutProductFilters}
            className="dropdownLight mbl sizeConstrainedSelect"
            onChange={(p) => {
                startWithProduct(p);
            }}
            value={product} />}
    />;

    function startWithProduct(p: string) {
        setProduct(p);
        const productFilter = buildProductFilter({
            name: p,
        } as ProductIdentifier, false, session, true);
        if (!productFilter)
            return;

        const newFilters = settings.filters.filter((f) => { return !isProductFilter(f, undefined, session.project?.eventKeys); }).concat([productFilter]);
        settings.mergeSet({
            filters: newFilters,
            supplyChain: {
                selectedProduct: p,
            }
        });
    }
}
