import React, { useContext, useEffect, useRef } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { useSupplyChainGraphs } from "../../hooks/UseSupplyChainGraphs";
import i18n from "../../i18n";
import { ApiGraph, MultiEdge, Node } from "../../models/Dfg";
import { getCustomKpiParameters, getMainProductValue } from "../../models/Kpi";
import { KpiPresets, KpiTypes } from "../../models/KpiTypes";
import { DfgUtils } from "../../utils/DfgUtils";
import { DfGraphLayout, GraphChangeBehavior, IDfGraph, nodeHighlightColorMapDefault, nodeLegendDurationProps } from "../dfg/DfGraph";
import { getNodeMarkupBillOfMaterials } from "../dfg/nodes/NodeMarkupFactory";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import { TrayElement } from "../tray/TrayElement";
import { ProductCaseAggregationStatistics } from "../../models/ApiTypes";
import { capitalize, flatten, get, uniq } from "lodash";
import { getQuantityUnit } from "../../utils/Formatter";
import { isUpstreamProductFilter } from "../../utils/FilterBuilder";
import colors from "../../colors.json";
import { useStatistics } from "../../hooks/UseStatistics";
import { NoDataAvailable } from "../no-data-available/NoDataAvailable";
import { DownloadDfgTrayElement } from "../tray/DownloadDfgTrayElement";

export function BomGraph() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiTypes = [...KpiPresets.supplyChainViewKpis, KpiTypes.GoodQuantity];

    const graphOptions = getCustomKpiParameters(kpiTypes, settings, session);

    const [graph, isGraphLoading] = useSupplyChainGraphs(graphOptions, {
        disable: !settings.supplyChain.selectedProduct,
    });

    const [stats, isStatsLoading] = useStatistics(undefined);

    // Build graph
    const dfg = React.useMemo(() => {
        if (!graph)
            return undefined;

        const filters = (settings.previewFilters ?? settings.filters ?? []).filter(f => isUpstreamProductFilter(f, session.project?.eventKeys));
        const filterProductIds = new Set(uniq(flatten(filters.map(f => f.filters?.[0]?.caseAttributeText?.eq ??
            f.filters?.[0]?.caseAttributeInt?.eq?.map(e => e.toString()) ??
            f.filters?.[0]?.caseAttributeFloat?.eq?.map(e => e.toString()) ??
            f.filters?.[0]?.caseAttributeBool?.eq?.map(e => e.toString()) ??
            f.filters?.[0]?.caseAttributeDatetime?.eq?.map(e => e.toString()) ?? []))));

        // a single product can be represented by multiple nodes (machine and inventory nodes for example), so we need to obtain all node ids that match the filtered products
        const filterProductNodeIds = new Set(graph.nodes?.filter(node => filterProductIds.has(node.name)).map(n => n.id));
        // filter components are all products that feed into a filtered product
        const filterComponentIds = new Set(graph.nodes?.filter(node => graph.edges.some(e => e.from === node.id && filterProductNodeIds.has(e.to))).map(n => n.name));
        // finally get all nodes that belong to all filtered components
        const filterComponentNodeIds = new Set(graph.nodes?.filter(node => filterComponentIds.has(node.name)).map(n => n.id));
        // Gather all nodes that have incoming edges which are used to determine if a node can be expanded
        const nodesWithIncomingEdges = new Set(graph.edges.map(e => e.to));

        const nodes: Node[] = graph.nodes.map(node => {
            // Only add node if the corresponding product is either in productIds,
            // or if an edge exists that ends in such a node
            const addNode = filterProductNodeIds.has(node.id) || filterComponentNodeIds.has(node.id);
            return !addNode ? undefined : {
                ...node,
                id: node.id,
                hasIncomingEdges: nodesWithIncomingEdges.has(node.id),
            } as Node;
        }).filter(p => p !== undefined) as Node[];

        const result: ApiGraph = {
            nodes,
            // only show edges that feed into a filtered node or connect nodes that belong to a component product
            edges: graph.edges.filter(e => filterProductNodeIds.has(e.to) || (filterComponentNodeIds.has(e.from) && filterComponentNodeIds.has(e.to))),
            hash: "",
        };

        return DfgUtils.preprocessGraph(result);
    }, [
        JSON.stringify(settings.previewFilters ?? settings.filters),
        settings.quantity,
        graph,
    ]);

    // If the user decides to delete the upstream product filter, navigate back to the
    // product selection screen
    useEffect(() => {
        if (!settings.filters.some(f => isUpstreamProductFilter(f, session.project?.eventKeys)))
            settings.set({ supplyChain: { selectedProduct: undefined } });
    }, [
        JSON.stringify(settings.filters),
    ]);

    const bomGraphRef = useRef<IDfGraph>(null);

    return <div className="fillParent">
        {!isStatsLoading && stats?.numFilteredTraces === 0 && <NoDataAvailable visible={true} title="filters.noCasesSelected" message="" />}
        {isStatsLoading || stats?.numFilteredTraces !== 0 && <DfGraphLayout
            edgeLabelFunc={getEdgeValue}
            isLoading={isGraphLoading}
            graphChangeBehavior={GraphChangeBehavior.FitOnNodeCountChange}
            legend={nodeLegendDurationProps}
            ref={bomGraphRef}
            nodeHighlightStatFunc={(node) => {
                return getMainProductValue(node as unknown as ProductCaseAggregationStatistics, settings, session);
            }}
            nodeHighlightColorFunc={(node, stat, minStatistic, maxStatistic, scale) => {
                // Leaf nodes with no stats (dead end BOM nodes)
                return node.count === undefined && !node.hasIncomingEdges ? colors["$gray-4"] :
                    // Nodes with no stats that can be expanded further (for example "Baugruppen")
                    node.count === undefined && !!node.hasIncomingEdges ? colors["$white"] :
                        // Nodes that actually have statistics for the rest
                        scale !== undefined ? nodeHighlightColorMapDefault(scale ?? 0) :
                            colors.$graphNodeBackground;
            }}
            markupFunc={getNodeMarkupBillOfMaterials}
            graph={dfg}
        />}
        <Shortcuts handledSelections={[ShortcutContexts.Product]} />
        <DownloadDfgTrayElement graph={bomGraphRef.current} filename={i18n.t("supplyChain.title").toString()} />
        <TrayElement>
            <button
                style={{ order: -1 }}
                className="shortcutButton"
                onClick={() => {
                    settings.mergeSet({
                        supplyChain: {
                            selectedProduct: "",
                        },
                    });
                }
                }>
                {i18n.t("supplyChain.startNewAnalysis").toString()}
            </button>
        </TrayElement>
    </div>;
}

function getEdgeValue(edge: MultiEdge | undefined, settings: SettingsType, session: SessionType) {
    if (edge === undefined || edge.edges.length === 0)
        return undefined;
    const value = get(edge.edges[0], `component${capitalize(settings.quantity)}Statistics.mean`);
    const unit = getQuantityUnit(settings.quantity);
    return unit.formatter(value, { locale: session.locale });
}
