export class SimpleCache<K, T> {
    constructor(private maxCacheSize: number) { }

    private generation = 1;

    private age = new Map<K, number>();

    private content = new Map<K, T>();

    has(key: K) {
        return this.content.has(key);
    }

    get(key: K) {
        if (this.age.has(key)) {
            this.age.set(key, this.generation);
            this.generation++;
            return this.content.get(key);
        }

        return undefined;
    }

    set(key: K, value: T) {
        if (this.content.size >= this.maxCacheSize)
            this.preempt();

        const result = this.content.set(key, value);

        this.age.set(key, this.generation);
        this.generation++;

        return result;
    }

    remove(key: K) {
        this.content.delete(key);
        this.age.delete(key);
    }

    getOrSet(key: K, func: ((key: K) => T) | (() => T)) {
        const cached = this.get(key);
        if (cached !== undefined)
            return cached;
        const result = func(key);
        this.set(key, result);
        return result;
    }

    flush() {
        this.content.clear();
        this.age.clear();
    }

    size() {
        return this.content.size;
    }

    /**
     * When the high water mark is reached, this function removes half of the
     * elements from the cache
     */
    private preempt() {
        const numElementsToRemove = this.maxCacheSize / 2;

        // eslint-disable-next-line no-empty
        for (let i=0; i<numElementsToRemove && this.preemptElement(); i++) { }
    }

    /**
     * Removes the least used element from the cache
     */
    private preemptElement() {
        console.assert(this.content.size, "preemptElement called, but the cache is empty");

        const itr = this.content.keys();

        let minAge = Number.MAX_SAFE_INTEGER;
        let minKey: K | undefined = undefined;

        let itrElement = itr.next();

        while (!itrElement.done) {
            const currentAge = this.age.get(itrElement.value)!;

            if (minAge > currentAge) {
                minAge = currentAge;
                minKey = itrElement.value;
            }

            itrElement = itr.next();
        }

        if (minKey !== undefined) {
            this.age.delete(minKey);
            this.content.delete(minKey);
            return true;
        }

        return false;
    }
}
