import React, { useContext } from "react";
import BenchmarkingControls, { getValidBenchmarkingControlSettings } from "../../components/controls/BenchmarkingControls";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { caseKpiControlsGetAllowedStatistics, getAllowedKpis } from "../../models/Kpi";
import { getProductDefaultSettings, viewSettingsInitialization } from "../../utils/Initializers";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { ProcessKpiChart } from "../process-kpi-chart/ProcessKpiChart";
import ProductSideStatistics from "../process-kpi-chart/stats/ProductSideStatistics";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { KpiPresets } from "../../models/KpiTypes";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { LogStats } from "../process-kpi-chart/stats/LogStats";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function TimingsKpisView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.products"
        }]}
        pages={[
            {
                tabTitle: "common.processKpis",
                tabSlug: "process",
                spotlightId: "Timings-KPI-Process",
                controller: <BenchmarkingControls kpis={KpiPresets.productTimeKpis} />,
                activator: (preferences) => {
                    let temp = viewSettingsInitialization(session,
                        settings,
                        preferences,
                        getProductDefaultSettings(session),
                        {
                            aggregationTypes: [AggregationTypes.Product, AggregationTypes.Case, AggregationTypes.Time,],
                            kpis: getAllowedKpis(session, settings, KpiPresets.productTimeKpis, true),
                            sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.Median, SortByType.Percentiles],
                            quantities
                        });

                    if (!temp)
                        return;

                    temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingControlSettings(session, temp, { kpis: KpiPresets.productTimeKpis }));

                    // The allowed comparisons are depending on settings that might have been corrected above,
                    // so we need to run this as a two-step process
                    settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                        statistics: caseKpiControlsGetAllowedStatistics(session, settings, temp.kpi.selectedKpi, temp.kpi.aggregation),
                    }));
                },
                dimensions: getDimensionParameters(session.projectId, "kpis/process"),
                content: <>
                    <ProcessKpiChart
                        noProductDataPlaceholder="kpi.noDataProductsRequired"
                        noCaseDataPlaceholder="kpi.noDataAllCasesEqual"
                        noTimeDataPlaceholder="kpi.noDataInTimeRange"
                        pageSlug="timings" />
                    <BackButtonTrayElement />
                </>,
                selectionTrigger: (settings) => {
                    return settings.selection.product !== undefined ||
                        settings.selection.case !== undefined ||
                        settings.selection.timeperiod !== undefined;
                },
                stats: <ProductSideStatistics unmarkedAside={<LogStats />} />,
            }
        ]}
    />;
}
