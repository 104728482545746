import axios, { CancelToken, CancelTokenSource } from "axios";
import { reject } from "lodash";
import { SimpleCache } from "./SimpleCache";

export class ApiCache<RESPONSE, REQUEST_PARAMS> {
    private queryRefs: {
        key: string,
        promise: Promise<RESPONSE>;
        subscriptions: number[],
        cancelTokenSource: CancelTokenSource;
    }[] = [];

    private cache: SimpleCache<string, RESPONSE>;

    private nextSubscription = 1;

    constructor(private apiFunc: (request: REQUEST_PARAMS, cancelToken: CancelToken | undefined) => Promise<RESPONSE>, size = 32) {
        this.cache = new SimpleCache(size);
    }

    private numRequests = 0;
    private numRequestsBySubscription: { [subscriptionId: number]: number } = {};

    getSubscriptionId() {
        const id = this.nextSubscription++;
        return id;
    }

    cancelSubscription(subscriptionId: number) {
        this.queryRefs.forEach(q => {
            q.subscriptions = q.subscriptions.filter(s => s != subscriptionId);
        });

        const cancelRefs = this.queryRefs.filter(q => !q.subscriptions.length);

        // Cancel queries noone cares about anymore :'-(
        for (const cRef of cancelRefs)
            cRef.cancelTokenSource.cancel();

        this.queryRefs = this.queryRefs.filter(q => q.subscriptions.length > 0);

        this.numRequests = 0;
    }

    /**
     * Returns the number of currently ongoing requests.
     * @param subscriptionId If provided, the number of requests for this subscription is returned. Otherwise, the total 
     * number of requests is returned.
     */
    getRequestCount(subscriptionId?: number) {
        if (subscriptionId === undefined)
            return this.numRequests;

        return this.numRequestsBySubscription[subscriptionId] ?? 0;
    }

    flush() {
        this.cache.flush();
    }

    isCached(options: REQUEST_PARAMS) {
        const key = JSON.stringify(options);
        return this.cache.has(key);
    }

    get(options: REQUEST_PARAMS, subscriptionId: number) {
        const key = JSON.stringify(options);

        const existing = this.cache.get(key);
        if (existing !== undefined)
            return new Promise<RESPONSE>((resolve) => {
                resolve(existing);
            });

        // Check if a request is currently ongoing
        const query = this.queryRefs.find(q => q.key === key);
        if (query !== undefined) {
            // If so, add a reference
            query.subscriptions.push(subscriptionId);
            return query.promise;
        }

        // Cache miss, issue request
        const cancelTokenSource = axios.CancelToken.source();
        const q = {
            key,
            cancelTokenSource,
            promise: this.apiFunc(options, cancelTokenSource.token),
            subscriptions: [subscriptionId]
        };
        this.queryRefs.push(q);

        this.incRef(subscriptionId);

        q.promise.then((data) => {
            // Add result to cache
            this.decRef(subscriptionId);
            this.cache.set(key, data);
        }).catch((err) => {
            this.decRef(subscriptionId);
            if (axios.isCancel(err))
                return;

            return reject(err);
        }).finally(() => {
            // Remove promise from list of ongoing queries
            this.queryRefs = this.queryRefs.filter(q => q.key !== key);
        });

        return q.promise;
    }

    private incRef(subscriptionId: number) {
        this.numRequests++;
        if (this.numRequestsBySubscription[subscriptionId] === undefined)
            this.numRequestsBySubscription[subscriptionId] = 1;
        else
            this.numRequestsBySubscription[subscriptionId]++;
    }

    private decRef(subscriptionId: number) {
        this.numRequests--;
        if (this.numRequestsBySubscription[subscriptionId] !== undefined)
            this.numRequestsBySubscription[subscriptionId]--;
    }
}
