import { isString } from "lodash";
import React from "react";
import i18n from "../../i18n";

export type FormHeaderProps = React.PropsWithChildren<{
    title: string | JSX.Element;
    description: string | JSX.Element;
}>;


export function FormHeader(props: FormHeaderProps) {
    return <div className="formHeader next">
        <div className="left">
            <h2>{isString(props.title) ? i18n.t(props.title) : props.title}</h2>

            <div className="headerDescription">
                {isString(props.description) ? i18n.t(props.description) : props.description}
            </div>
        </div>

        <div className="right">
            {props.children}
        </div>
    </div>;
}