import { capitalize } from "lodash";
import React, { useContext } from "react";
import ReactSwitch from "react-switch";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { getAssignedQuantities, QuantityType, Quantity } from "../../utils/Quantities";
import Dropdown from "../dropdown/Dropdown";
import { BaseQuantityType } from "../../models/ApiTypes";

export type VariantsSortInputProps = {
    sort: string;
    ascending: boolean;
    quantity: BaseQuantityType | undefined;
    onChanged: (sort: string, ascending: boolean, quantity: BaseQuantityType | undefined) => void;
    className?: string;
};

export function VariantsSortInput(props: VariantsSortInputProps) {
    const session = useContext(SessionContext);

    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);
    const quantityOptions = caseYieldQuantities.map(o => {
        return {
            label: i18n.t(o.name),
            value: o.id,
        };
    });

    const sortOptions = [{
        value: "count",
        label: i18n.t("common.count")
    }, {
        value: "duration",
        label: i18n.t("filters.variants.sortDuration"),
    }];

    if (quantityOptions.length > 0) {
        sortOptions.push({
            value: "yield",
            label: i18n.t("filters.variants.cumulatedYield"),
        });
        sortOptions.push({
            value: "rate",
            label: i18n.t("filters.variants.meanRate"),
        });
    }

    return <div className={"variantsSortInput" + (props.className ? " " + props.className : "")}>
        <div className="block">
            <label>
                {i18n.t("explorer.sortOrderBy")}
            </label>
            <Dropdown
                className="dropdownLight"
                options={sortOptions}
                isSearchable={false}
                value={sortOptions.find(o => o.value === props.sort)!}
                onChange={(e) => {
                    if (!e)
                        return;

                    props.onChanged(e.value as string, props.ascending, props.quantity);
                }}
            />
        </div>

        {quantityOptions.length > 0 && <div className="block">
            <label>
                {i18n.t("common.quantityUnit")}
            </label>
            <Dropdown
                className="dropdownLight"
                options={quantityOptions}
                isSearchable={false}
                isDisabled={quantityOptions.length <= 1}
                value={quantityOptions.find(o => o.value === props.quantity)!}
                onChange={(e) => {
                    props.onChanged(props.sort, props.ascending, e?.value as BaseQuantityType);
                }}
            />
        </div>}

        <div className="block sortSwitch">
            <div className="twoOptions rowOptions">
                <label htmlFor="switch-sort-order">{i18n.t("explorer.descending")}</label>
                <ReactSwitch
                    id="switch-sort-order"
                    height={16}
                    handleDiameter={13}
                    width={32}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offColor={colors["$gray-2"]}
                    onColor={colors["$gray-2"]}
                    checked={props.ascending}
                    onChange={() => {
                        props.onChanged(props.sort, !props.ascending, props.quantity);
                    }}
                />
                <label htmlFor="switch-sort-order">{i18n.t("explorer.ascending")}</label>
            </div>
        </div>
    </div>;
}

export function getSortOrder(sort: string, ascending: boolean, quantity: Quantity | undefined) {
    const prefix = ascending ? "" : "-";

    if (sort === "count" || sort === "duration")
        return prefix + sort;

    if (sort === "yield")
        return prefix + "caseYield" + capitalize(quantity?.id) + "Statistics.sum";

    if (sort === "rate")
        return prefix + "caseYieldRate" + capitalize(quantity?.id) + "Statistics.mean";

    throw Error("unknown sort order: " + sort);
}