import React, { useContext } from "react";
import { StatsRowValue, StatsRowValueSigned } from "../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { buildCaseFilter } from "../../utils/FilterBuilder";
import { Formatter } from "../../utils/Formatter";
import { useCases } from "../../hooks/UseCases";

export function SideStatisticsCaseDeviation() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const filters = settings.previewFilters ?? settings.filters;

    const deviationOptions = {
        eventFilters: filters,
        calculateTimeAndFreqStats: true,
    };

    const filter = buildCaseFilter(settings.selection.case ? [settings.selection.case] : undefined, false, session);

    const [allCases, allCasesLoading] = useCases({
        ...deviationOptions,
        calculatePlanned: true,
        calculateDeviations: true,
        eventFilters:  (settings.previewFilters ?? settings.filters).concat(filter ? [filter] : []),
        limit: 1,
    });

    const selectedCase = allCases?.cases.find(c => c.id === settings.selection.case);


    if (!settings.selection.case)
        return null;

    const actual = selectedCase?.actual?.duration;
    const planned = selectedCase?.planned?.duration;

    const relative = actual !== undefined && !!planned ?
        (actual - planned) / planned :
        undefined;

    return <div data-testid="sidePanel">
        <StatsSection title={`${i18n.t("common.case")} ${selectedCase?.id}`} isTitleLoading={allCasesLoading || selectedCase === undefined}>

            <StatsSubSection title="workflows.planningDeviation.title" spotlightId="Timings-PD-Comparison-Statistics-Case" >
                <StatsRowValue label="workflows.planningDeviation.plannedDuration" isLoading={allCasesLoading} unit={Formatter.units.durationShort} value={selectedCase?.planned?.duration} />
                <StatsRowValue label="workflows.planningDeviation.actualDuration" isLoading={allCasesLoading} unit={Formatter.units.durationShort} value={selectedCase?.actual?.duration} />
                <StatsRowValueSigned label="workflows.planningDeviation.deviationTime" isLoading={allCasesLoading} unit={Formatter.units.durationShort} value={selectedCase?.deviation?.duration} isHighlight={true} />
                <StatsRowValueSigned label="workflows.planningDeviation.relativeDeviation" isLoading={allCasesLoading} unit={Formatter.units.percent} value={relative} />
            </StatsSubSection>
        </StatsSection>
    </div>;
}
