import { flattenDeep, range } from "lodash";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import colors from "../../colors.json";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Formatter } from "../../utils/Formatter";
import { IScrollbar, Scrollbar } from "../scrollbar/Scrollbar";
import { BaseGraphProps, ContainerModes, Padding, Tick, generateColumnLabels, generateGraphTicks, getHorizonalLines, useDragging } from "./GraphCommon";

export type Bar<T> = Tick & {
    valueLabel?: string;
    tooltip?: string;
    data?: T;
    value?: number;
    barColor?: string;
}

export type GroupGraphProps<T> = BaseGraphProps & {
    minGroupPadding?: number;

    barPadding?: number;

    barWidth?: number;

    barColors?: string[];

    /**
     * Index of the currently selected group
     */
    selectedGroupIdx?: number;

    /**
     * Index of the bar within the selected group
     */
    selectedGroupBarIdx?: number;

    selected?: T | undefined;

    onSelected?: (groupIdx: number | undefined, barIdx: number | undefined, data: T | undefined) => void;

    onLabelSelected?: (groupIdx: number) => void;

    data: Bar<T>[][];

    /**
     * If set, a legend will be displayed with the colors of the respective bars
     */
    legend?: JSX.Element;
}

export function GroupGraph<T>(props: GroupGraphProps<T>) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const scrollbarRef = useRef<IScrollbar>(null);

    const [selectedGroupIdx, selectedGroupBarIdx] = useMemo(() => {
        if (props.selected !== undefined &&
            props.selectedGroupBarIdx === undefined &&
            props.selectedGroupIdx === undefined) {
            for (let groupIdx = 0; groupIdx < props.data.length; groupIdx++)
                for (let barIdx = 0; barIdx < props.data[groupIdx].length; barIdx++)
                    if (props.data[groupIdx][barIdx].data === props.selected)
                        return [groupIdx, barIdx];
        }
        return [props.selectedGroupIdx, props.selectedGroupBarIdx];
    }, [
        props.data,
        props.selected,
        props.selectedGroupBarIdx,
        props.selectedGroupIdx,
    ]);

    const barColors = props.barColors ?? [
        colors.$actual,
        settings.kpi.comparisons === KpiComparisons.Planning ? colors.$plan : colors.$bestCase,
        colors["$blue-light"], colors["$blue-dark"], colors.$red, colors.$purple, colors.$yellow];

    const padding: Padding = {
        top: props.padding?.top ?? 50,
        bottom: props.padding?.bottom ?? 50,
        left: props.padding?.left ?? 100,
        right: props.padding?.right ?? 10,
    };

    const { min, max, maxBarsPerGroup } = useMemo(() => {
        // Get a flat list of all values in any bar
        const allValues = flattenDeep(props.data ?? []).filter(d => d !== undefined).map(d => d.value).filter(d => d !== undefined);

        // Get a list of all values for horizontal lines. 
        // If the line has a label, we'll add 5% to them such that the axis 
        // is 5% longer in case they are setting the max value.
        const allLineValues = (props.horizonalLines ?? []).map(v => v.label ? v.value * 1.05 : v.value);
        const min = props.min ?? Math.min(0, ...allValues, ...allLineValues);
        const max = props.max ?? Math.max(0, ...allValues, ...allLineValues);

        return {
            min: props.min ?? (min !== undefined && isFinite(min) ? min : 0),
            max: props.max ?? (max !== undefined && isFinite(max) ? max : 0),
            maxBarsPerGroup: Math.max(0, ...(props.data ?? []).map(g => g.length)),
        };
    }, [
        props.min,
        props.max,
        props.data,
        props.horizonalLines
    ]);

    // How much of barSpace will be assigned for drawing the bar
    const barWidth = props.barWidth ?? 20;
    const barPadding = props.barPadding ?? 1;
    let groupPadding = props.minGroupPadding ?? 100;

    // Space allocated for a group, excluding padding
    const groupWidth = maxBarsPerGroup * barWidth + (maxBarsPerGroup - 1) * barPadding;

    // Enforce minimum width
    const minWidth = padding.left + padding.right + groupWidth;
    const width = Math.max(props.width, minWidth);

    // Enforce a minimum height of 30 pixels for the chart, no matter what the properties say
    const height = Math.max(padding.bottom + padding.top + 30, props.height);
    const paneWidth = width - padding.left - padding.right;

    const maxGroupsInView = Math.floor(paneWidth / (groupWidth + groupPadding));
    const canDrag = maxGroupsInView <= (props.data?.length ?? 0);

    if (!canDrag) {
        const paddingSpace = paneWidth - groupWidth * props.data.length;
        groupPadding = paddingSpace / Math.max(1, props.data.length);
    }

    const contentWidth = (groupWidth + groupPadding) * props.data.length;
    const { dragState, mouseHandlers, offset, resetDragPosition, setOffset } = useDragging(canDrag, contentWidth - paneWidth, props.initialOffset, (offset) => {
        if (scrollbarRef.current)
            scrollbarRef.current.setOffset(offset);
    });

    useEffect(() => {
        resetDragPosition();
    }, [
        props.data
    ]);

    if (props.data === undefined)
        return null;

    const yZero = valueToY(0);

    // Axes
    const axisColor = colors["$gray-7"];
    const axes: JSX.Element[] = [];
    axes.push(<line key="y-axis" x1={padding.left + 0.5} x2={padding.left + 0.5} y1={padding.top} y2={height - padding.bottom} stroke={axisColor} />);

    if (yZero <= (height - padding.bottom))
        axes.push(<line key="x-axis" x1={padding.left} x2={width - padding.right} y1={yZero} y2={yZero} stroke={axisColor} />);

    // y axis ticks
    const tickLabels: JSX.Element[] = [];

    const tickLength = 5;
    const yAxisTicks = (props.yAxisTicks?.length) ? props.yAxisTicks : generateGraphTicks(
        height - padding.top - padding.bottom,
        min,
        max,
        true,
        props.yAxisUnit ?? Formatter.defaultUnit,
        {
            locale: session.locale,
            ...props.formatterParams
        },
        props.yAxisUnit?.formatter ?? Formatter.defaultUnit.formatter);

    if (props.showYAxisTicks || props.showYAxisLines)
        for (const tick of yAxisTicks) {
            const y = Math.round(valueToY(tick.value));
            if (props.showYAxisLines)
                axes.push(<line key={`y-axis-tick-${tick.value}`} x1={padding.left} x2={width - padding.right} y1={y} y2={y} stroke="rgba(0,0,0,0.075)" />);

            if (props.showYAxisTicks) {
                tickLabels.push(<div key={`y-axis-label-${tick.value}`} className="yAxisTick" style={{
                    left: 0,
                    width: padding.left,
                    top: y - 20 + 0.5,
                    height: 40,
                    paddingRight: tickLength + 5,
                }}>
                    {tick.label ?? Formatter.formatNumber(tick.value, 2, session.numberFormatLocale)}
                </div>);
                axes.push(<line key={`y-axis-tick-${tick.value}`} x1={padding.left - tickLength} x2={padding.left} y1={y} y2={y} stroke={axisColor} />);
            }
        }

    // Line drawing
    const [lines, lineLabels] = getHorizonalLines(props.horizonalLines, width, padding, valueToY);

    const barLabels: JSX.Element[] = [];
    const barValues: JSX.Element[] = [];

    const valueFormatter = props.valueFormatter ?? props.yAxisUnit?.formatter ?? Formatter.defaultUnit.formatter;

    // Render visible groups
    const visibleGroupIndices = getVisibleIndices();
    const groups: JSX.Element[] = [];

    const maxGroupLength = Math.max(...props.data.map(g => g.length), 0);
    for (const idx of visibleGroupIndices) {
        const group = props.data[idx];
        if (!group)
            continue;

        const isGroupSelected = selectedGroupIdx === idx;

        for (let i = 0; i < maxGroupLength; i++) {
            const bar = group[i];

            const x = padding.left + idx * (groupWidth + groupPadding) + i * (barWidth + barPadding) + groupPadding / 2 - offset;

            const y1 = Math.round(valueToY(0));
            const y2 = Math.round(valueToY(bar?.value ?? 0));

            const isSelected = isGroupSelected && selectedGroupBarIdx === i;
            const color = barColors[i % barColors.length];
            groups.push(createRect(`bar-${idx}-${i}`, x, barWidth, Math.min(y1, y2), Math.max(y1, y2), isSelected ? colors.$graphSelectionBarColor : bar?.barColor ?? color, () => {
                if (!props.onSelected)
                    return;

                // If a drag event ended recently, this is not a selection click but caused by
                // the user releasing the mouse button.
                if (!dragState.lastDragGestureEnded ||
                    (new Date().getTime() - dragState.lastDragGestureEnded) > 100) {
                    if (selectedGroupIdx === idx && selectedGroupBarIdx === i)
                        props.onSelected(undefined, undefined, undefined);
                    else
                        props.onSelected(idx, i, bar.data);

                }
            }));

            if (props.showBarValues)
                barValues.push(<div
                    key={`value-${idx}-${i}`}
                    className={"barValue" + (isGroupSelected ? " selected" : "")}
                    {...mouseHandlers}
                    style={{
                        left: x - padding.left + barWidth / 2,
                        height: 40,
                        marginLeft: -(barWidth + barPadding) / 2,
                        width: barWidth + barPadding,
                        // Position label above bar, unless the value is negative.
                        // In that case, position it above 0.
                        bottom: (bar?.value >= 0) ? height - y2 : height - y1,
                    }}>
                    {bar === undefined && "N/A"}
                    {bar !== undefined && (bar.valueLabel ?? valueFormatter(bar.value, {
                        locale: session.numberFormatLocale,
                        numDigits: 1,
                        ...props.formatterParams,
                    }))}
                </div>);
        }

        // Find first element that has a valid label and render that for the entire group
        const element = group.filter(g => !!g.label?.length)[0];
        if (element) {
            const xCenter = padding.left + idx * (groupWidth + groupPadding) + (groupWidth + groupPadding) / 2 - offset;
            const lines = generateColumnLabels(element.label!, element.tooltip, xCenter, groupWidth, width, height, padding, () => {
                if (props.onLabelSelected)
                    props.onLabelSelected(idx);
            }, mouseHandlers, isGroupSelected);

            for (const line of lines)
                barLabels.push(line);
        }
    }

    return <div
        style={{
            width: width,
            height: height,
            cursor: canDrag ? "grab" : "default",
            overflow: (props.containerMode ?? ContainerModes.Overflowing) === ContainerModes.Overflowing ? "visible" : "hidden",
        }}
        onClick={() => {
            if (props.onSelected)
                props.onSelected(undefined, undefined, undefined);
        }}
        className="graph">
        {dragState.isDragGesture && <div className="cover" {...mouseHandlers} onMouseOut={mouseHandlers.onMouseUp} />}

        {props.yAxisLabel !== undefined && <div className="yAxisLabel" style={{
            width: height - padding.top - padding.bottom,
            top: padding.top,
            left: -(height - padding.top - padding.bottom) + padding.left - (props.yAxisLabelPadding ?? 0),
        }}>
            {props.yAxisLabel}
        </div>}

        <div
            style={{
                position: "absolute",
                top: 0,
                left: padding.left,
                height: height,
                width: width - padding.left - padding.right,
                overflow: "hidden",
            }}
        >
            {barValues}
        </div>

        <svg
            {...mouseHandlers}
            style={{
                width,
                height,
                position: "relative",
            }}>
            <defs>
                <clipPath id="constrain-pane">
                    <rect x={padding.left} y={padding.top} width={width - padding.left - padding.right} height={height - padding.top - padding.bottom} />
                </clipPath>
            </defs>

            {axes}
            {lines}
            {groups}
        </svg>

        {tickLabels}
        {barLabels}
        {lineLabels}

        {(props.containerMode ?? ContainerModes.Overflowing) === ContainerModes.Constrained && <div
            className="fader"
            style={{
                height: padding.bottom,
                width: Math.min(15, padding.left / 2),
            }}
        />}

        {props.title !== undefined && <div className="topContainer title">
            {props.title}
        </div>}

        {/* Legend  */}
        {props.legend !== undefined && props.legend}

        <Scrollbar
            ref={scrollbarRef}
            padding={padding}
            onChange={(state) => {
                setOffset(state.from);
            }}
            initialState={{
                from: offset,
                to: offset + paneWidth,
                max: contentWidth,
            }} />
    </div>;

    function valueToY(value: number) {
        const delta = (max - min) || 1;
        const ratio = (value - min) / delta;
        const panelHeight = Math.max(0, height - padding.top - padding.bottom);

        return Math.max(0, height - padding.bottom) - ratio * panelHeight;
    }

    /**
     * Calculates the index of the corresponding bar for a given x coordinate. This coordinate
     * is relative to the left of the chart pane.
     * @param offset
     */
    function panelXToIndex(x: number) {
        return Math.floor((x + offset) / (groupWidth + groupPadding));
    }

    function getVisibleIndices() {
        const fromIdx = Math.max(0, panelXToIndex(0));
        const toIdx = Math.min(panelXToIndex(paneWidth) + 1, props.data?.length ?? 0);

        if (toIdx < fromIdx)
            return [];

        return range(fromIdx, toIdx);
    }
}

function createRect(key: string, x: number, width: number, minY: number, maxY: number, color: string, onClick: () => void) {
    return <rect
        style={{ cursor: "pointer" }}
        key={key}
        x={x}
        width={width}
        y={minY}
        height={maxY - minY}
        fill={color}
        clipPath="url(#constrain-pane)"
        onClick={(e) => {
            onClick();

            e.preventDefault();
            e.stopPropagation();
        }}
    />;
}