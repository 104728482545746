import { useContext, useEffect, useState } from "react";
import { disableAllCalcOptions, GetProjectVariantsRequest, Variant } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { GroupingKeys } from "../models/Dfg";
import { Datastores } from "../utils/Datastores";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { getIsGroupingValid } from "../utils/Initializers";
import { useMountedState } from "./UseMounted";
import { noop } from "lodash";

export function useVariants(options: Partial<GetProjectVariantsRequest>, grouping?: GroupingKeys): [Variant[] | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const isMounted = useMountedState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [state, setState] = useState<Variant[] | undefined>();
    const filters = options.eventFilters ?? settings.previewFilters ?? settings.filters;

    const sortOrder = options.sort ?? "-count";

    useEffect(() => {
        if (!session.project ||
            !session.project.eventKeys ||
            !session.project.uploadId ||
            !getIsGroupingValid(session.project.eventKeys, settings.groupingKey))
            return;

        const subscriptionId = Datastores.getVariants.getSubscriptionId();

        const groupingKey = grouping ?? settings.groupingKey ?? GroupingKeys.None;

        const request: GetProjectVariantsRequest = {
            ...disableAllCalcOptions,
            ...options,
            eventFilters: filters,
            eventKeys: {
                ...options?.eventKeys,
                ...session.project.eventKeys,
                activityKeysGroup: groupingKey,
            },
            uploadId: session.project?.uploadId,
            uploads: session.project?.uploads,
            offset: options.offset ?? 0,
            sort: sortOrder,
            consolidatePasses: options.consolidatePasses ?? groupSupportsConsolidatePasses(groupingKey),
        };

        if (!Datastores.getVariants.isCached(request))
            setIsLoading(true);

        Datastores.getVariants.get(request, subscriptionId).then((result) => {
            if (isMounted())
                setState(result);
        }).catch(noop).finally(() => {
            if (isMounted())
                setIsLoading(false);
        });

        return () => {
            Datastores.getVariants.cancelSubscription(subscriptionId);
        };
    }, [
        sortOrder,
        session.project,
        settings.groupingKey,
        JSON.stringify(filters),
        settings.apiRetry,
    ]);

    return [state, isLoading];
}
