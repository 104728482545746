import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import i18n from "../../i18n";

export enum Dimensions {
    Timing = "timings",
    Output = "output",
    Quality = "quality",
    Carbon = "carbon",
    Stock = "stock",
}

export type DimensionProps = {
    timing?: string;
    output?: string;
    quality?: string;
    carbon?: string;
    stock?: string;
};

export function Dimension(props: DimensionProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const session = useContext(SessionContext);
    const isOniq = isOniqEmployee(session);

    const showCarbon = isOniq || session.project?.settings?.isDemoProject || session.project?.eventKeys?.carbonMass !== undefined || session.project?.eventKeys?.electricityEnergy !== undefined;

    return <div className="dimensions">
        <button
            id="dimension-time"
            disabled={props.timing === undefined}
            title={i18n.t("time").toString()}
            onClick={() => navigate(props.timing!)}
            className={props.timing === location.pathname ? "active" : undefined}>
            {i18n.t("time").toString()}
        </button>

        <button
            id="dimension-output"
            disabled={props.output === undefined}
            title={i18n.t("common.output").toString()}
            onClick={() => navigate(props.output!)}
            className={props.output?.endsWith(location.pathname) ? "active" : undefined}>
            {i18n.t("common.output").toString()}
        </button>

        <button
            id="dimension-stock"
            disabled={props.stock === undefined}
            title={i18n.t("output.goodsInProcess").toString()}
            onClick={() => navigate(props.stock!)}
            className={props.stock?.endsWith(location.pathname) ? "active" : undefined}>
            {i18n.t("common.inventory").toString()}
        </button>

        <button
            id="dimension-quality"
            disabled={props.quality === undefined}
            title={i18n.t("common.quality").toString()}
            onClick={() => navigate(props.quality!)}
            className={props.quality?.endsWith(location.pathname) ? "active" : undefined}>
            {i18n.t("common.quality").toString()}
        </button>

        {showCarbon && <button
            id="dimension-carbon"
            disabled={props.carbon === undefined}
            title={i18n.t("common.carbonFootprint").toString()}
            onClick={() => navigate(props.carbon!)}
            className={props.carbon?.endsWith(location.pathname) ? "active" : undefined}>
            {i18n.t("common.co2").toString()}
        </button>}
    </div>;
}

export function getDimensionParameters(projectId: string | undefined, suffix: string, validFor?: Dimensions[], forbiddenFor?: Dimensions[]): DimensionProps {
    if (projectId === undefined)
        return {};

    return {
        timing: (validFor === undefined || validFor.includes(Dimensions.Timing)) && (forbiddenFor === undefined || !forbiddenFor.includes(Dimensions.Timing)) ? `/projects/${projectId}/timings/${suffix}` : undefined,
        output: (validFor === undefined || validFor.includes(Dimensions.Output)) && (forbiddenFor === undefined || !forbiddenFor.includes(Dimensions.Output)) ? `/projects/${projectId}/output/${suffix}` : undefined,
        quality: (validFor === undefined || validFor.includes(Dimensions.Quality)) && (forbiddenFor === undefined || !forbiddenFor.includes(Dimensions.Quality)) ? `/projects/${projectId}/quality/${suffix}` : undefined,
        carbon: (validFor === undefined || validFor.includes(Dimensions.Carbon)) && (forbiddenFor === undefined || !forbiddenFor.includes(Dimensions.Carbon)) ? `/projects/${projectId}/carbon/${suffix}` : undefined,
        stock: (validFor === undefined || validFor.includes(Dimensions.Stock)) && (forbiddenFor === undefined || !forbiddenFor.includes(Dimensions.Stock)) ? `/projects/${projectId}/stock/${suffix}` : undefined,
    };
}

export function getDimensionFromPathname(pathname: string) {
    const rx = new RegExp("/projects/[^/]+/([^/]+)");
    const matches = pathname.match(rx);
    if (matches === null || (matches?.length ?? 0) < 2)
        return undefined;

    // Map URL parts to Dimensions
    return {
        timings: Dimensions.Timing,
        output: Dimensions.Output,
        quality: Dimensions.Quality,
        carbon: Dimensions.Carbon,
        stock: Dimensions.Stock,
    }[matches[1]];
}