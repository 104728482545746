/**
 * Helper class that's exclusively used for node size calculations.
 * Also caches font measurements for a while.
 * Append your data, and retrieve the corresponding node size at the end with `getSize()`
 */
import i18n from "../../../i18n";
import { SimpleCache } from "../../../utils/SimpleCache";
import { Layouter } from "../Layouter";
import { NodeMarkupRow, RowTypes, RowValueTypes } from "./NodeRenderer";

const MAX_CACHE_SIZE = 10000;

export class NodeSize {
    private numRows = 0;

    private static measurementCache = new SimpleCache<string, { width: number, height: number }>(MAX_CACHE_SIZE);

    constructor(private width = 0, private height = 0, private rowSpacing = 2, private hMargin = 10, private sectionPadding = 6) { }

    addSection() {
        this.height += 2 * this.sectionPadding;
    }

    addRowSize(markup: NodeMarkupRow) {
        if (markup.rowType === RowTypes.Hidden)
            return;

        if (markup.rowType === RowTypes.Columns) {
            const labelSize = this.measureText(i18n.t(markup.label ?? ""), "nodeMeasureContainerHalf");
            // For bars, we just use a fixed placeholder as their value may be formatted differentyly.
            const isBar = (markup.valueType === RowValueTypes.LabelBar || markup.valueType === RowValueTypes.PercentBar);
            const valueSize = this.measureText(isBar ? "____100 %____" : (markup.value?.toString() ?? ""), "nodeMeasureContainerHalf");
            const columnGapWidth = 10; // equivilant to the css gap size
            // Make sure that both columns are equally large so that our grid layout works.
            // If we just took the sum of label and value width, we would end up
            // with asymmetrical rows which we don't want.
            const columnsWidth = Math.max(labelSize.width, valueSize.width) * 2 + columnGapWidth;

            this.width = Math.max(this.width, columnsWidth);
            this.height += Math.max(labelSize.height, valueSize.height) + this.rowSpacing;
            return;
        }

        if (markup.rowType === RowTypes.Headline) {
            const size = this.measureText(markup.value?.toString() ?? "", "nodeMeasureContainerBold");
            this.width = Math.max(this.width, size.width);
            this.height += size.height + this.rowSpacing;
            return;
        }

        // Regular text
        const size = this.measureText(markup.value?.toString() ?? "", "nodeMeasureContainer");
        this.width = Math.max(this.width, size.width);
        this.height += size.height + this.rowSpacing;
    }

    /**
     * Returns the node size in pixels
     * @returns the node size in pixels
     */
    getSize() {
        return {
            // We need to add the horizontal margin here
            width: this.width + this.hMargin * 2,

            // However, the section margins have already been added by previous
            // addSection calls
            height: this.height,
        };
    }

    private measureText(text: string, container = "nodeMeasureContainer") {
        const key = container + "/" + text;
        const c = NodeSize.measurementCache;
        return c.get(key) ?? c.set(key, Layouter.measureFontSize(container, text)).get(key)!;
    }
}
