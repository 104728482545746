import React, { ReactNode } from "react";
import { Auth0ContextInterface, User as Auth0User } from "@auth0/auth0-react";
import Modal, { ModalTypes } from "../../components/modal/Modal";
import { User } from "../../contexts/SessionContext";
import i18n from "../../i18n";

/**
 * Renders a modal with children and redirects to login page.
 */
export default function UnauthorizedModal(props: { auth0: Partial<Auth0ContextInterface<Auth0User>>, children?: ReactNode }) {
    return <Modal
        type={ModalTypes.Info}
        isClosable={true}
        isVisible={true}
        onDone={async () => {
            if (props.auth0?.isAuthenticated) {
                if (props.auth0?.logout)
                    props.auth0?.logout({ returnTo: window.location.origin });
            } else {
                if (props.auth0.loginWithRedirect)
                    await props.auth0?.loginWithRedirect();
            }
        }}
        title="errorModal.unauthorized.title">
        <div>
            {props.children}
        </div>
    </Modal>;
}

export function getUnauthorizedModalMessage(auth0: Partial<Auth0ContextInterface<Auth0User>>, user?: User) {
    if (auth0?.isLoading)
        return null;

    // Auth0 redirects authorization failures to url with additional url paramters
    const loginError = new URLSearchParams(window.location.search).get("error_description");
    const isPermissionMissing = !user?.permissions?.includes("iqa");

    if (loginError == "user is blocked")
        return getUserBlockedMessage();

    if (user?.email && isPermissionMissing)
        return getUserUnauthorizedMessage();

    return null;
}

export function getUserBlockedMessage() {
    return toDangerousHtmlMessage("errorModal.unauthorized.userBlockedMsg");
}

export function getUserUnauthorizedMessage() {
    return toDangerousHtmlMessage("errorModal.unauthorized.userUnauthorizedMsg");
}

function toDangerousHtmlMessage(message: string) {
    return <div dangerouslySetInnerHTML={{
        __html: i18n.t(message)
    }}></div>;
}
