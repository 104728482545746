import { uniq } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spotlight } from "../../components/spotlight/Spotlight";
import Tile, { TileSelectionModes } from "../../components/tile/Tile";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import { SettingsContext, defaultValues } from "../../contexts/SettingsContext";
import Global from "../../Global";
import i18n from "../../i18n";
import { Project } from "../../models/Project";
import { Upload } from "../../models/Upload";
import { Formatter } from "../../utils/Formatter";
import { useLocalStorage } from "../../utils/LocalStorage";
import updateUserpilotUrl from "../../utils/Userpilot";
import { getDashboardSettings } from "../dashboard/Dashboard";
import { Api } from "../../api/Api";

type TableModel = {
    id: string;
    project: Project;
    upload: Upload | undefined;
    active: boolean;
    created: Date;
};

export default function ProjectBrowser() {
    const session = useContext(SessionContext);
    const settingsContext = useContext(SettingsContext);

    const navigate = useNavigate();

    updateUserpilotUrl();

    const [listData, setListData] = useState<TableModel[] | undefined>(undefined);

    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const [starMap, setStar] = useLocalStorage<boolean>("projectbrowser");


    useEffect(() => {
        updateProjectList();
    }, [session.user, settingsContext.apiRetry]);

    return <div className="projectContainer">
        <div className="greetingContainer">
            <div className="greeting">{i18n.t("browser.welcome")}</div>
        </div>
        <div className="tileContainer" data-testid="container">
            {(listData ?? []).map(e => <Tile
                key={e.id}
                className={e.project.id === session.project?.id ? "selected" : ""}
                selectionMode={TileSelectionModes.Pinnable}
                isSelected={starMap[e.id] === true}
                title={e.project.name}
                description={e.project.description}
                onSelectionChanged={(state) => {
                    updateStar(e.id, state);
                }}
                extra={
                    <table className="customTable">
                        <tbody>
                            <tr>
                                <td>
                                    {i18n.t("browser.states.updated")}
                                </td>
                                <td>
                                    {Formatter.formatDateTime(e.project?.updated, undefined, session.numberFormatLocale, session.timezone)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                onClick={() => {
                    session.setProject(e.project.id);
                    Global.projectLoadingSpinnerHasBeenShown = false;
                    navigate("/projects/" + e.project.id + "/dashboard");
                    settingsContext.set({
                        ...JSON.parse(JSON.stringify(defaultValues)),
                        dashboard: getDashboardSettings(session, e.project.id),
                    });
                }}
            />)}

            {isOniqEmployee(session) && <div>
                <div className="actionButtons">
                    <button
                        onClick={() => {
                            session.set({
                                project: undefined,
                                projectId: undefined,
                            });
                            navigate("/projects/add-project-upload");
                        }}>
                        <span className="addProject">
                            <svg className="svg-icon xsmall mr"><use xlinkHref="#radix-plus" /></svg>
                            <span>{i18n.t("browser.addProject")}</span>
                        </span>
                    </button>
                </div>
            </div>}
        </div>

        <Spotlight id="Projects" className="bottomRight mb mrl fixed" />
    </div>;

    function updateStar(id: string, state: boolean) {
        setStar(id, state);
        setListData(sortData(listData ?? []));
    }

    function sortData(data: TableModel[]) {
        const result = [...data];
        result.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });

        result.sort((a, b) => {
            const aStar = starMap[a.id] || false;
            const bStar = starMap[b.id] || false;

            if (aStar === bStar)
                return 0;

            if (aStar)
                return -1;

            return 1;
        });

        return result;
    }

    async function updateProjectList() {
        if (isInitialized)
            return;

        const projectResponse = await Api.getProjects({});

        const uploadIds = uniq(projectResponse.map(project => project.uploadId).filter(uploadId => uploadId !== undefined) as string[]);
        const uploadResponse = await Api.getUploads({ idEq: uploadIds });

        const newTable = projectResponse.map(project => {
            return {
                id: project.id,
                project: project,
                upload: uploadResponse.find((item: Upload) => (item.id === project.uploadId && item)),
                created: project.created,
            } as TableModel;
        });

        setListData(sortData(newTable));

        setIsInitialized(true);
    }
}
