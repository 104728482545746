/**
 * Displays the configuration of a single sequence filter element.
 * Sequence filters check event sequences against a mandatory sequence of activities
 * to decide whether or not to include a specific case.
 */

import parse from "html-react-parser";
import React, { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";
import { ActivityItem } from "../../../models/EventKeys";
import { attributesToGroupingKey, getActivityLabelFromActivityItem } from "../../../utils/GroupingUtils";

type SequenceFilterStatusPropsType = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function SequenceFilterStatus(props: SequenceFilterStatusPropsType): JSX.Element {
    const session = useContext(SessionContext);
    if (!session.project?.eventKeys)
        return <div></div>;
    const isExcluded = !!props.filter?.caseSequence?.ne?.length;
    const groupingMode = getFilterGroupingMode();
    let activities = [] as ActivityItem[];
    if (props.filter?.caseSequence?.ne?.length)
        activities = props.filter?.caseSequence?.ne[0];
    else if (props.filter?.caseSequence?.eq?.length)
        activities = props.filter?.caseSequence?.eq[0];
    const numActivities = activities.length;
    const translationSuffix = isExcluded ? "Excluded" : "";
    const translationString = numActivities > 1 ? "filters.sequencePlural" + translationSuffix : "filters.sequenceSingular" + translationSuffix;

    return <div className={"filter sequenceFilter" + (props.isEdited ? " edit" : "")} onClick={() => { if (props.onClick) props.onClick(); }}>
        <svg className="svg-icon small"><use xlinkHref="#radix-mixer-vertical" /></svg>
        <div title={activities.map(a => a.values.join(", ")).join(" ▶ ")}>
            {parse(i18n.t(translationString, {
                firstActivity: numActivities ? getActivityLabelFromActivityItem(activities[0], groupingMode) : "",
                numActivities
            }))}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;

    function getFilterGroupingMode() {
        if (props.filter?.caseSequence?.eq?.length)
            return attributesToGroupingKey(props.filter!.caseSequence!.eq![0][0].keys, session.project!.eventKeys!);
    }
}