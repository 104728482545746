import React from "react";

export type ValueSpinnerProps = {
    isLoading: boolean;
    className?: string;
    classNameSpinning?: string;
    children?: JSX.Element | JSX.Element[] | string | string[] | number | number[] | null | undefined;
};

export function ValueSpinner(props: ValueSpinnerProps) {
    if (props.isLoading)
        return <span data-testid="valuespinner-spinning" className="valueSpinnerPlaceholder">
            <div className={"dot-pulse" + (props.classNameSpinning ? " " + props.classNameSpinning : "")} data-testid={"spinner"} />
        </span>;

    return <div className={props.className}>{props.children}</div>;
}
