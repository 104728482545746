import React, { useContext } from "react";
import { CaseAggregationDeviationsSubStatisticsSchema } from "../../../models/ApiTypes";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { RcaType } from "../../../contexts/ContextTypes";
import { getRecentRcaByType, SettingsContext } from "../../../contexts/SettingsContext";
import i18n from "../../../i18n";
import { Formatter } from "../../../utils/Formatter";
import { calculateInfluence, getRcaSelectionType } from "../RcaUtils";
import { InfluenceStatsSubsection } from "./InfluenceStatsSubsection";
import { useCases } from "../../../hooks/UseCases";
import { CaseAggregationStatistics } from "../../../models/Case";

/**
 * Used in SideStatisticsRcaStats. Don't use directly!
 */
export function PlanningDeviationRcaStats() {
    const settings = useContext(SettingsContext);

    const rca = getRecentRcaByType(RcaType.Time, settings);
    const rcaFilters = rca?.rcaFilters ?? [];

    const calcOptions = {
        calculateTimeAndFreqStats: true,
        limit: 0
    };

    const [allCaseStats, isAllCaseStatsLoading] = useCases({
        ...calcOptions,
        calculateDeviations: true,
        eventFilters: rcaFilters  // use RCA filters instead of main filters otherwise node/edge names might disappear
    });

    const showSelectionStats = getRcaSelectionType(settings.selection);

    const allCasesTitle = i18n.t("workflows.planningDeviation.rcaDeviationDuration");
    const influence = calculateInfluence(settings.selection.feature?.relevance, rca?.maximize ?? true);

    return <>
        {showSelectionStats && <StatsSection title={"rca.comparingStats"}>
            <InfluenceStatsSubsection label={"common.scheduleDeviation"} influence={influence} unitMeta={Formatter.units.durationShort}/>
            <DeviationStatsSubsection title={allCasesTitle} stats={allCaseStats?.log.deviation} count={allCaseStats?.log.deviation?.count} isLoading={isAllCaseStatsLoading}/>
        </StatsSection>}
    </>;
}



function DeviationStatsSubsection(props: {isLoading: boolean, title: string, stats: CaseAggregationStatistics | CaseAggregationDeviationsSubStatisticsSchema | undefined, count?: number}) {
    return <StatsSubSection title={props.title}>
        <StatsRowValue label="common.mean" value={props.stats?.durationStatistics?.mean} unit={Formatter.units.durationShort} isHighlight={true} isLoading={props.isLoading}/>
        {/* TODO: WE DON'T HAVE RELATIVETOPLANNED ANYMORE. WHAT TO DO? */}
        {/* <StatsRowValue label="common.relativeMean" value={props.stats?.relativeToPlanned.durationStatistics?.mean} unit={Formatter.units.percent} isLoading={props.isLoading}/> */}
        <StatsRowValue label="common.statistics.absCount" value={props.count} isLoading={props.isLoading} unit={Formatter.units.numberShort}/>
    </StatsSubSection>;
}

