export enum AggregationTypes {
    Product = "product",
    Case = "case",
    Time = "time",
}

export enum RcaType {
    Time = 1,
    Quality = 2,
    Throughput = 3,
    ThroughputTime = 4,
    Bottleneck = 5,
    OrgLosses = 6,  // TODO: delete this after deciding whether to send the RCA type in the request
}

export enum KpiComparisons {
    Planning = "planning",
    BestProcesses = "bestProcesses",
    None = "none",
}

export enum LegacyAnalyzedValues {
    TimeValuesMean = "timeValuesMean",
    TimeValuesSum = "timeValuesSum",
    Frequency = "frequency",
    OutputValuesMean = "outputValuesMean",
    OutputValuesSum = "outputValuesSum",
    OutputRate = "outputRate",
    OutputAverageStock = "outputAverageStock",
    QualityValuesMean = "qualityValuesMean",
    QualityValuesSum = "qualityValuesSum",
    QualityRate = "qualityRate",
    QualityQuota = "qualityQuota",
    CarbonEmissions = "carbonEmissions",
    CarbonPerYield = "carbonPerYield",
    EnergyConsumption = "energyConsumption",
    EnergyPerYield = "energyPerYield",
    ProcessRatio = "processRatio",
    Throughput = "throughput",
    CycleTime = "cycleTime"
}
