import React, { useContext } from "react";
import { timeAndThroughputCalcOptions } from "../../../models/ApiTypes";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { RcaType } from "../../../contexts/ContextTypes";
import { SettingsContext, getRecentRcaByType } from "../../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../../contexts/ContextTypes";
import { Stats } from "../../../models/Stats";
import { getCaseAggregatedYieldRateStatistics } from "../../../utils/CaseStats";
import { Formatter } from "../../../utils/Formatter";
import { quantities } from "../../../utils/Quantities";
import { calculateInfluence, getRcaSelectionType } from "../RcaUtils";
import { InfluenceStatsSubsection } from "./InfluenceStatsSubsection";
import { useCases } from "../../../hooks/UseCases";

/**
 * Used in SideStatisticsRcaStats. Don't use directly!
 */
export function ProcessPathRcaStats(props: {
    rcaType: RcaType;
}) {
    const settings = useContext(SettingsContext);

    const calcOptions = {
        ...getCaseStatisticsCalcOptions(props.rcaType),
        limit: 0
    };

    const rca = getRecentRcaByType(props.rcaType, settings);
    const rcaFilters = rca?.rcaFilters ?? [];

    const [allCaseStatistics, areAllCasesLoading] = useCases({
        ...calcOptions,
        eventFilters: rcaFilters,  // use RCA filters instead of main filters otherwise node/edge names might disappear
    });

    const showSelectionStats = !!getRcaSelectionType(settings.selection);

    if (!showSelectionStats)
        return <></>;

    const influence = calculateInfluence(settings.selection.feature?.relevance, rca?.maximize ?? true);

    if (rca.analyzedValue === LegacyAnalyzedValues.OutputRate) {
        // FIXME: settings.quantity might have changed since the RCA was made... this should be embedded in the rca settings
        const unitMeta = quantities.find(q => q?.baseQuantity === settings.quantity && q.isFrequency === (rca.analyzedValue === LegacyAnalyzedValues.OutputRate))?.unit;

        return <StatsSection title="rca.comparingStats">
            <InfluenceStatsSubsection label={"common.throughput"} influence={influence} unitMeta={unitMeta}></InfluenceStatsSubsection>
            <StatsSubSection title="workflows.processPath.rcaMeanOutputRate">
                <StatsRowValue label="common.allCases" value={allCaseStatistics?.log?.actual !== undefined ? getCaseAggregatedYieldRateStatistics(allCaseStatistics.log.actual, settings.quantity)?.mean : undefined} unit={unitMeta} isLoading={areAllCasesLoading}/>
            </StatsSubSection>
        </StatsSection>;
    }

    if (rca.analyzedValue === LegacyAnalyzedValues.TimeValuesMean) {
        return <StatsSection title="rca.comparingStats">
            <InfluenceStatsSubsection label="common.throughputTime" influence={influence} unitMeta={Formatter.units.durationShort}></InfluenceStatsSubsection>
            <TimeValuesMeanStatsSubsection isLoading={areAllCasesLoading} title="workflows.processPath.rcaTimeValuesMean" stats={allCaseStatistics?.log?.actual?.durationStatistics} count={allCaseStatistics?.log?.actual?.count}/>
        </StatsSection>;
    }

    return <></>;
}

function TimeValuesMeanStatsSubsection(props: {isLoading: boolean, title: string, stats: Stats | undefined, count?: number}) {
    return <StatsSubSection title={props.title}>
        <StatsRowValue label="common.mean" value={props.stats?.mean} unit={Formatter.units.durationShort} isLoading={props.isLoading} isHighlight={true}/>
        <StatsRowValue label="common.statistics.max" value={props.stats?.max} unit={Formatter.units.durationShort} isLoading={props.isLoading} />
        <StatsRowValue label="common.statistics.min" value={props.stats?.min} unit={Formatter.units.durationShort} isLoading={props.isLoading} />
        {props.count !== undefined && <StatsRowValue label="common.statistics.absCount" value={props.count} unit={Formatter.units.numberShort} isLoading={props.isLoading} />}
    </StatsSubSection>;
}

function getCaseStatisticsCalcOptions(rcaType: RcaType) {
    if ([RcaType.Quality, RcaType.Throughput].includes(rcaType))
        return timeAndThroughputCalcOptions;
    return {
        calculateTimeAndFreqStats: true,
    };
}
