/**
 * Display the configuration of a single duration filter element.
 * Duration filters check the duration of an event trace, and only
 * let it pass if the duration falls into the interval range specified.
 */
import React from "react";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";

type CaseIdFilterStatusPropsType = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function CaseIdFilterStatus(props: CaseIdFilterStatusPropsType): JSX.Element | null {
    const count = props.filter.caseAttributeInt?.eq?.length ?? 
        props.filter.caseAttributeText?.eq?.length ??
        props.filter.caseAttributeInt?.ne?.length ?? 
        props.filter.caseAttributeText?.ne?.length ?? 0;

    if (!count)
        return null;
    
    const caseName = count === 1 ? String(props.filter.caseAttributeInt?.eq ?? props.filter.caseAttributeText?.eq ?? props.filter.caseAttributeInt?.ne ?? props.filter.caseAttributeText?.ne) : undefined;

    const isExcluded = !!props.filter.caseAttributeInt?.ne || !!props.filter.caseAttributeText?.ne;

    const state = i18n.t(isExcluded ? "filters.caseId.statusTitleExcluded" : "filters.caseId.statusTitle", {
        count
    });

    return <div className={"filter caseIdFilter" + (props.isEdited ? " edit" : "")} onClick={onClick}>
        <svg className="svg-icon small"><use xlinkHref="#radix-file" /></svg>
        <div>
            {state} <br />
            <b>{caseName !== undefined ? caseName : i18n.t("filters.caseId.numCases", { count })}</b>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;

    function onClick() {
        if (props.onClick)
            props.onClick();
    }
}