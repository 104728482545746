import { isNumber } from "lodash";
import React, { useContext } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "../../contexts/SessionContext";
import { useMatchedPath } from "../../Routing";
import updateUserpilotUrl from "../../utils/Userpilot";
import { TabsNext, TabsNextPageProps } from "../tabs/TabsNext";

export type TabbedViewNextType = TabsNextPageProps & {
    tabSlug?: string;
    isVisible?: boolean;
}

export type TabbedViewNextProps = {
    pages: TabbedViewNextType[];
    headline?: string;
    onTabChanged?: (idx: number, slug: string) => void;
};

export function TabbedViewNext(props: TabbedViewNextProps) {
    // Initialize parameters from URL
    const { projectId, tabSlug } = useParams<{
        projectId: string,
        tabSlug: string,
    }>();

    const session = useContext(SessionContext);
    if (session.projectId !== projectId)
        session.setProject(projectId);

    updateUserpilotUrl(true);

    // Initialized from params, updated at tab changes. Always has index
    // of currently displayed tab page
    let currentTabIdx = props.pages.findIndex(p => p.tabSlug === tabSlug);
    if (tabSlug !== undefined && currentTabIdx < 0 && isNumber(+tabSlug))
        currentTabIdx = Math.max(0, Math.min(props.pages.length - 1, (+tabSlug) - 1));
    if (currentTabIdx < 0)
        currentTabIdx = 0;

    const matchedPath = useMatchedPath();
    const navigate = useNavigate();

    if (props.pages[currentTabIdx] === undefined)
        return <></>;

    return <div className="next">
        {session.project !== undefined && <>
            <TabsNext
                title={props.headline}
                onTabChanged={(idx) => {
                    const tabSlug = props.pages[idx].tabSlug ?? idx.toString();
                    const path = generatePath(matchedPath.route!, { projectId: projectId!, tabSlug: tabSlug ?? idx.toString() });
                    navigate(path, { replace: true });

                    if (props.onTabChanged)
                        props.onTabChanged(idx, tabSlug);
                }}

                pages={props.pages}
                selectedIndex={currentTabIdx}
            />
        </>}
    </div>;
}