import React from "react";
import i18n from "../../i18n";
import { Legend } from "./Legend";
import colors from "../../colors.json";

export function VarianceLegend() {
    return <Legend
        items={[{
            color: colors.$graphPositiveValueColor,
            label: i18n.t("common.statistics.varianceLegend").toString(),
        }, {
            color: colors.$selected,
            label: i18n.t("common.statistics.median").toString(),
            isLine: true,
        }]}
    />;
}