import React, { useContext } from "react";
import { AutoCenteringModes, getLegendProps, IDfGraph, ProcessGraph, ZoomControlLocations } from "../../components/dfg/DfGraph";
import { getNodeMarkupQuality } from "../../components/dfg/nodes/NodeMarkupFactory";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { NodeKpiChart } from "../../components/kpi-chart/NodeKpiChart";
import SideStatisticsQuality from "../../components/side-statistics/SideStatisticsQuality";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import { Project } from "../../models/Project";
import { getEdgeLabelText, getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import { allowedGroupingReduced, allowedGroupingReducedWithoutMachineObject } from "../../utils/GroupingUtils";
import { getValueStreamDefaultSettings, viewSettingsInitialization } from "../../utils/Initializers";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { getQualityCaseQuantities, KpiErrorMessage, validateQualityCaseParameters } from "./QualityKpisView";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import DfgControls, { getValidDfgControlSettings } from "../../components/controls/DfgControls";
import { getAllowedKpis, graphKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { getValidNodeKpiControlSettings, NodeKpiControls } from "../../components/controls/NodeKpiControls";
import i18n from "../../i18n";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { KpiTypes, KpiPresets, StatisticTypes } from "../../models/KpiTypes";
import { NodeVarianceChart } from "../../components/kpi-chart/NodeVarianceChart";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function QualityProcessView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const scrapQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Scrap, false);
    const baseQuantities = scrapQuantities.map(q => q.baseQuantity);
    const dfgRef = React.useRef<IDfGraph>(null);

    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);

    const isInitialized = session.project !== undefined;

    const errorMessage = isInitialized ? (() => {
        const q = getQualityNodeQuantities(settings, session.project);
        if (!q.isQuotaAvailable && settings.kpi.selectedKpi === KpiTypes.ScrapRatio)
            return "kpi.noDataScrapYieldRequired";

        if (!q.isScrapAvailable)
            return "kpi.noDataScrapRequired";
    })() : undefined;

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.valueStream"
        }]}
        pages={[{
            tabTitle: "explorer.dfg",
            tabSlug: "dfg",
            spotlightId: "Quality-DFG",
            selectionTrigger: (settings) => {
                return settings.selection.node !== undefined;
            },
            activator: (preferences) => {
                const q = getQualityCaseQuantities(settings, session.project);
                let temp = validateQualityCaseParameters(session, settings, preferences, getValueStreamDefaultSettings(session), {
                    aggregationTypes: [AggregationTypes.Product],
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamQualityKpis),
                    quantities: q.quantities.map(a => a.baseQuantity),
                    groupingKeys: allowedGroupingReduced,
                });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamQualityKpis }));

                settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                    statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                }));
            },
            dimensions: getDimensionParameters(session.projectId, "process/dfg", undefined, []),
            content: <>
                <KpiErrorMessage message={errorMessage} />
                {!errorMessage && isInitialized && <div className="processExplorerGraph" >
                    <ProcessGraph
                        legend={getLegendProps(settings.kpi.selectedKpi)}
                        ref={dfgRef}
                        isObjectCentric={hasObjects}
                        zoomControlLocation={ZoomControlLocations.FarRight}
                        analysis={AnalysisType.Output}
                        edgeLabelFunc={getEdgeLabelText}
                        nodeHighlightStatFunc={(node) => {
                            return getMainNodeStat(node, settings, session);
                        }}
                        markupFunc={getNodeMarkupQuality}
                        centerMode={AutoCenteringModes.CenterIfUninitialized} />
                </div>}
                <BackButtonTrayElement />
                <DownloadDfgTrayElement graph={dfgRef.current} filename={i18n.t("common.processGraph").toString() + "-" + i18n.t("common.quality").toString()} />
            </>,
            controller: <DfgControls kpis={KpiPresets.valueStreamQualityKpis} />,
            stats: <SideStatisticsQuality />,
        }, {
            tabTitle: "common.processKpis",
            tabSlug: "benchmarking",
            selectionTrigger: (settings: SettingsType) => {
                return settings.selection.node !== undefined;
            },
            controller: <NodeKpiControls kpis={KpiPresets.valueStreamQualityKpis} />,
            stats: <SideStatisticsQuality />,
            activator: (preferences) => {
                let temp = viewSettingsInitialization(session, settings, preferences, getValueStreamDefaultSettings(session), {
                    aggregationTypes: [AggregationTypes.Product],
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamQualityKpis),
                    quantities: baseQuantities,
                    groupingKeys: allowedGroupingReducedWithoutMachineObject,

                });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidNodeKpiControlSettings(session, temp, { kpis: KpiPresets.valueStreamQualityKpis }));

                const allowedComparisons = getEnabledComparisonsValueStream(session, settings);
                const validSettings = {
                    statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                    sortBy: allowedComparisons?.includes(KpiComparisons.Planning) ? [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.OrderSequences]
                        : [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.OrderSequences]
                };

                // The allowed comparisons are depending on settings that might have been corrected above,
                // so we need to run this as a two-step process
                settings.set(viewSettingsInitialization(session, temp, undefined, undefined, validSettings));
            },
            spotlightId: "Quality-KPI",
            dimensions: getDimensionParameters(session.projectId, "process/benchmarking", undefined, []),
            content: <>
                <KpiErrorMessage message={errorMessage} />
                {!errorMessage && <div key="nodeKpiChart">
                    {settings.kpi.statistic === StatisticTypes.Variance ? <NodeVarianceChart analysisType={AnalysisType.Output} noDataPlaceholder="" pageSlug="quality" />
                        : <NodeKpiChart
                            analysisType={AnalysisType.Output}
                            noDataPlaceholder={"kpi.noDataYieldRequired"}
                            pageSlug="quality"
                        />}
                </div>}
                <BackButtonTrayElement />
            </>,
        }]}
    />;
}

/**
 * This method encapsulates quality-specific validation logic.
 */
export function getQualityNodeQuantities(settings: SettingsType, project: Project | undefined) {
    const scrapQuantities = getAssignedQuantities(project?.eventKeys, QuantityType.Scrap, false);
    const yieldQuantities = getAssignedQuantities(project?.eventKeys, QuantityType.Yield, false);

    return {
        quantities: scrapQuantities,
        quotaQuantities: yieldQuantities,
        isScrapAvailable: scrapQuantities.some(s => s.baseQuantity === settings.quantity),
        isQuotaAvailable: scrapQuantities.some(s => s.baseQuantity === settings.quantity) &&
            yieldQuantities.some(s => s.baseQuantity === settings.quantity),
    };
}