import React from "react";

export type NoMatrixDataAvailableProps = {
    title?: string;
    description?: string;
    selectorComponent?: JSX.Element | JSX.Element[] | undefined;
    startButton?: JSX.Element | JSX.Element[] | undefined;
}

export function SelectionPage(props: NoMatrixDataAvailableProps) {

    return <div className="fillParentAnalyses">
        <div className="matrix">
            <div className="topContainer">
                <h1 className="title">{props.title}</h1>
                <p className="infoText">{props.description}</p>
                <div>{props.selectorComponent}</div>
                {props.startButton}
            </div>
        </div>
    </div>;

}