import React from "react";
import { useContext } from "react";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { getProductDefaultSettings, viewSettingsInitialization } from "../../utils/Initializers";
import { getAssignedQuantities } from "../../utils/Quantities";
import { QuantityType } from "../../utils/Quantities";
import { getDefaultEnabledComparisons } from "../../utils/SettingsUtils";
import { StockKpiChart } from "../stock-kpi-chart/StockKpiChart";
import { KpiErrorMessage } from "./QualityKpisView";
import BenchmarkingControls, { getValidBenchmarkingControlSettings } from "../../components/controls/BenchmarkingControls";
import { KpiPresets, StatisticTypes } from "../../models/KpiTypes";
import ProductSideStatistics from "../process-kpi-chart/stats/ProductSideStatistics";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { BaseQuantityType } from "../../models/ApiTypes";
import { caseKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { LogStats } from "../process-kpi-chart/stats/LogStats";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function StockKpisView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);

    const errorMessage = session.project !== undefined && (!quantities.map(q => q.baseQuantity).includes(settings.quantity as BaseQuantityType)) ?
        "kpi.noDataCaseYieldRequired" : undefined;

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.products"
        }]}
        pages={[{
            tabTitle: "common.processKpis",
            tabSlug: "process",
            spotlightId: "Stock-KPI-Process",
            controller: <BenchmarkingControls kpis={KpiPresets.productInventoryKpis} />,
            activator: (preferences) => {
                let temp = viewSettingsInitialization(session, settings, preferences,
                    getProductDefaultSettings(session),
                    {
                        statistics: [StatisticTypes.Mean],
                        kpis: KpiPresets.productInventoryKpis,
                        sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical],
                        quantities: quantities.map(q => q.baseQuantity),
                        aggregationTypes: [AggregationTypes.Product, AggregationTypes.Case, AggregationTypes.Time],
                    });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingControlSettings(session, temp, { kpis: KpiPresets.productInventoryKpis }));

                settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                    statistics: caseKpiControlsGetAllowedStatistics(session, settings, temp.kpi.selectedKpi, temp.kpi.aggregation),
                }));
            },
            dimensions: getDimensionParameters(session.projectId, "kpis/process"),
            content: <>
                <KpiErrorMessage message={errorMessage} />
                {!errorMessage &&
                    <StockKpiChart
                        noProductDataPlaceholder="kpi.noDataProductsAndCaseYieldRequired"
                        noCaseDataPlaceholder="kpi.noDataYieldRequired"
                        noTimeDataPlaceholder="kpi.noDataInTimeRange"
                        pageSlug="stock"
                        validComparisons={getDefaultEnabledComparisons(session, settings)} />}
                <BackButtonTrayElement />
            </>,
            selectionTrigger: (settings) => {
                return settings.selection.product !== undefined ||
                    settings.selection.case !== undefined ||
                    settings.selection.timeperiod !== undefined;
            },
            stats: <ProductSideStatistics unmarkedAside={<LogStats />} />,
        }]}>
        <BackButtonTrayElement />
    </TabbedView>;
}
