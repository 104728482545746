import React, { useContext, useRef } from "react";
import { useGridResize } from "../../components/kpi-chart/UseGridResize";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SettingsContext } from "../../contexts/SettingsContext";
import { CaseProcessKpiChart } from "./CaseProcessKpiChart";
import { ProductProcessKpiChart } from "./ProductProcessKpiChart";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import { TimeperiodChart } from "../../components/timeperiods-chart/TimeperiodsChart";
import { ProductVariations } from "../process-path/ProductVariations";
import { getAnalysisTitle } from "../../components/product-chart/ProductChart";
import { StatisticTypes } from "../../models/KpiTypes";
import { SessionContext } from "../../contexts/SessionContext";

export type KpiChartParams = {
    width: number;
    height: number;
    pageSlug: string;
    titleKey: string;
}
export const getMessage = (noDataPlaceholder: string, numOrders?: number, isDeviationApi?: boolean) => {
    // If we have orders selected in the filter but we have no data, so if it's in planning comparison then 
    // it means that we have no planning data (so display no plannig data message) otherwise display message of noDataPlaceholder  
    if (numOrders)
        return <NoDataAvailable title="common.noKpisAvailable" message={isDeviationApi ? "kpi.noDataPlanningCases" : noDataPlaceholder} visible={true} />;
    return <NoDataAvailable title="common.noOrderFilter" visible={true} />;
};

export function ProcessKpiChart(props: {
    pageSlug: string;
    noProductDataPlaceholder: string;
    noCaseDataPlaceholder: string;
    noTimeDataPlaceholder: string;
    addEnergyStats?: boolean;
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const container = useRef<HTMLDivElement>(null);
    const [width, height] = useGridResize(container, 1, undefined, undefined, 0);

    const titleKey = getAnalysisTitle(session, settings);

    return <>
        <div className="fillParentAnalyses" ref={container}>
            {settings.kpi.aggregation === AggregationTypes.Case && !!width && !!height && <CaseProcessKpiChart singleObjectStatsValid={false} noDataPlaceholder={props.noCaseDataPlaceholder} pageSlug={props.pageSlug} width={width} height={height} addEnergyStats={props.addEnergyStats} titleKey={titleKey} />}
            {settings.kpi.aggregation === AggregationTypes.Product && settings.kpi.statistic !== StatisticTypes.Variance && !!width && !!height && <ProductProcessKpiChart singleObjectStatsValid={false} noDataPlaceholder={props.noProductDataPlaceholder} pageSlug={props.pageSlug} width={width} height={height} addEnergyStats={props.addEnergyStats} titleKey={titleKey} />}
            {settings.kpi.aggregation === AggregationTypes.Product && settings.kpi.statistic === StatisticTypes.Variance && !!width && !!height && <ProductVariations noDataPlaceholder="kpi.noDataProductsRequired" />}
            {settings.kpi.aggregation === AggregationTypes.Time && !!width && !!height &&
                <TimeperiodChart width={width} height={height}
                    noDataPlaceholder={props.noTimeDataPlaceholder}
                    addEnergyStats={props.addEnergyStats}
                    title={titleKey}
                    requestOptions={{
                        calculateTimeAndFreqStats: true,
                        calculateBusyStats: true,
                        calculateProductionStats: true,
                        calculateUnknownStats: true,
                        calculateFailureStats: true,
                        calculateSetupStats: true,
                        calculateInterruptionStats: true,
                        calculatePassChangeStats: true,
                        calculatePassStats: true,
                        calculateOutputStats: true,
                    }} />}
        </div>
        <Shortcuts handledSelections={[ShortcutContexts.Product, ShortcutContexts.Case, ShortcutContexts.Timeperiod]} />
    </>;
}
