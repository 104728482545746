import React, { useContext } from "react";
import { KpiTypes, StatisticTypes } from "../../../models/KpiTypes";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { getKpiDefinition, getKpiSpotlightId, getStatisticFromObject, KpiDefinition } from "../../../models/Kpi";
import { CaseAggregationStatistics, CaseStatistics } from "../../../models/Case";
import { TimeperiodCaseAggregationStatisticsSchema } from "../../../models/ApiTypes";
import { StatsSubSection } from "../../../components/stats-section/StatsSection";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { Formatter } from "../../../utils/Formatter";
import { StatsRowPercentBar } from "../../../components/stats-section/StatsRowPercentBar";
import { AggregationTypes } from "../../../contexts/ContextTypes";
import { getCaseStats } from "../CaseProcessKpiChart";
import { TimeperiodCaseStatisticsSchema } from "../../../models/generated";
import { ProductCaseAggregationStatisticsSchema } from "../../../models/generated";

type SubTimeStatsProps = {
    title?: string;
    isLoading: boolean;
    timeComponent: KpiTypes;
    subComponents: subKpiDefinition[];
    selection?: ProductCaseAggregationStatisticsSchema | TimeperiodCaseStatisticsSchema | CaseStatistics | CaseAggregationStatistics | TimeperiodCaseAggregationStatisticsSchema;
    useLogPath?: boolean;
}


type subKpiDefinition = {
    type: KpiTypes;
    label?: string;
}

export default function SubTimeStats(props: SubTimeStatsProps) {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinition = getKpiDefinition(props.timeComponent, { session, settings });
    const value = getValue(kpiDefinition);

    if ((value === undefined || value === 0)&& !props.isLoading)
        return <></>;

    const subElements = props.subComponents.map(kpi => {
        const subKpiDefinition = getKpiDefinition(kpi.type, { session, settings });
        const subValue = getValue(subKpiDefinition);
        return <SubTimeBar
            key={kpi.type}
            label={kpi.label ?? subKpiDefinition?.label}
            time={subValue}
            busyTime={value}
            isLoading={props.isLoading}
            locale={session.numberFormatLocale}
        />;
    });

    return <StatsSubSection title={props.title ?? kpiDefinition?.label} spotlightId={getKpiSpotlightId(session, props.timeComponent, settings.kpi.aggregation)}>
        <StatsRowValue isLoading={props.isLoading} label="common.statistics.total" unit={Formatter.units.durationShort} value={value} />

        {subElements}
    </StatsSubSection>;

    function getValue(kpiDefinition?: KpiDefinition) {
        if (settings.kpi.aggregation === AggregationTypes.Case && !props.useLogPath)
            return getCaseStats(props.selection as CaseStatistics, kpiDefinition);
        return getStatisticFromObject(props.selection as CaseAggregationStatistics, kpiDefinition, StatisticTypes.Sum, settings.kpi.aggregation, props.useLogPath) as number;
    }
}

function SubTimeBar(props: { label: string | undefined, time: number | undefined, busyTime: number | undefined, isLoading: boolean, locale: string }) {

    if ((props.time === undefined || props.busyTime === undefined) && !props.isLoading)
        return <></>;

    const value = (!props.isLoading && props.time !== undefined && props.busyTime !== undefined) ? props.time / props.busyTime : undefined;

    const barLabel = Formatter.formatPercent(value, 1, 0, props.locale);

    return <StatsRowPercentBar isLoading={props.isLoading} value={value} of={1} label={props.label ?? ""} barLabel={barLabel} />;

}




