import { get, isObject } from "lodash";
import { SessionType } from "../contexts/SessionContext";
import { SettingsType } from "../contexts/SettingsContext";
import { Node, groupingKeysNoneGroups, groupingKeysPassCompatibleGroups } from "../models/Dfg";
import { DfgUtils } from "./DfgUtils";
import { getKpiDefinition, getUnit } from "../models/Kpi";
import { KpiTypes, StatisticTypes } from "../models/KpiTypes";
import { isObjectCentricAvailable } from "./SettingsUtils";
import { EquipmentNodeStatisticsSchema } from "../models/ApiTypes";

type KpiType = {
    value?: number,
    formattedValue?: string | undefined,
    label: string,
}

/**
 * Get main node kpi helps collecting the important kpi from a node. Depending on the settings different 
 * values and labels are returned. This can for example be different time types (Throughput, Busy, Setup, etc.)
 * or also relative kpis such as busy time per piece.
 * @param node 
 * @param settings 
 * @param session 
 * @returns an object with information on the numerical value, the formatted value and the associated label
 */
export function getMainNodeKpi(node: Node | undefined, settings: SettingsType, session: SessionType, overrides?: { kpiType?: KpiTypes, statistic?: StatisticTypes }): KpiType {
    if (node === undefined)
        return {
            label: "no node found"
        };
    const value = getMainNodeStat(node, settings, session, overrides);
    const kpiDefinition = getKpiDefinition(overrides?.kpiType ?? settings.kpi.selectedKpi, { session, settings });
    const unit = getUnit(kpiDefinition?.unit, overrides?.statistic ?? settings.kpi.statistic);
    if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey)) {
        return {
            label: kpiDefinition?.label ?? "Not defined yet",
            value,
            formattedValue: value !== undefined ? unit?.formatter(value, { locale: session.numberFormatLocale, baseQuantity: settings.quantity }) : undefined
        };
    }

    if (groupingKeysNoneGroups.includes(settings.groupingKey)) {
        return {
            value,
            label: "common.confirmation",
            formattedValue: value ? unit?.formatter(value, { locale: session.numberFormatLocale, baseQuantity: settings.quantity }) : undefined
        };
    }

    return {
        label: "Not defined yet"
    };
}

/**
 * This function returns the equipment statistics of a specific node and kpi
 * @param node 
 * @param equipmentStats equipment statistics of all nodes
 * @param settings 
 * @param session 
 * @returns an object with information on the numerical value, the formatted value and the associated label
 */
export function getEquipmentNodeKpi(node: Node | undefined, equipmentStats: EquipmentNodeStatisticsSchema[] | undefined, settings: SettingsType, session: SessionType, kpi?: { kpiType?: KpiTypes, statistic?: StatisticTypes}): KpiType {
    if (node === undefined || equipmentStats === undefined)
        return {
            label: "no equipment statistics found"
        };    

    const value = getEquipmentNodeKpiValue(node, equipmentStats, session, settings, kpi);
    const kpiDefinition = getKpiDefinition(kpi?.kpiType ?? settings.kpi.selectedKpi, { session, settings });
    const unit = getUnit(kpiDefinition?.unit, kpi?.statistic ?? settings.kpi.statistic);

    return {
        label: kpiDefinition?.label ?? "Not defined yet",
        value,
        formattedValue: value !== undefined ? unit?.formatter(value, { locale: session.numberFormatLocale, baseQuantity: settings.quantity }) : undefined
    };
}

/**
 * This function returns a numeric value of a kpi that is used as a main kpi. Main kpis are for example displayed as values on nodes.
 * Also these values can be used for highlighting.
 * @param node 
 * @param settings 
 * @param overrides 
 * @returns kpi as number
 */
export function getMainNodeStat(node: Node | undefined, settings: SettingsType, session: SessionType, overrides?: { kpiType?: KpiTypes, statistic?: StatisticTypes, isObjectCentric?: boolean }): number | undefined {
    const isObjectCentric = overrides?.isObjectCentric ?? isObjectCentricAvailable(session.project?.eventKeys);
    const kpiType = overrides?.kpiType ?? settings.kpi.selectedKpi;
    const statistic = overrides?.statistic ?? settings.kpi.statistic;
    if (node === undefined)
        return undefined;
    const objectNode = DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType) as Node;
    if (objectNode === undefined)
        return undefined;
    return getStatFromObjectNode(objectNode, session, settings, { kpiType, statistic });
}

/**
 * @param node 
 * @param settings 
 * @param overrides 
 * @returns kpi as number
 */
export function getEquipmentNodeKpiValue(node: Node | undefined, equipmentStats: EquipmentNodeStatisticsSchema[] | undefined, session: SessionType, settings: SettingsType, kpi?: { kpiType?: KpiTypes, statistic?: StatisticTypes, isObjectCentric?: boolean }): number | undefined {

    const kpiType = kpi?.kpiType ?? settings.kpi.selectedKpi;
    const statistic = kpi?.statistic ?? settings.kpi.statistic;

    if (node === undefined)
        return undefined;

    const equipmentNode = equipmentStats?.find(n => n.activityValues?.machine?.value === node?.activityValues?.machine?.value);
    
    const propName = getNodeEquipmentStatisticName(session, settings, { kpiType, statistic });

    if (propName === undefined || equipmentNode === undefined)
        return;
    
    const value = get(equipmentNode, propName) as number | undefined;
    
    return value;
}

/**
 * This function returns the numeric value for a node where the object node was already identified.
 * @param objectNode 
 * @param settings 
 * @param overrides 
 * @returns 
 */
export function getStatFromObjectNode(objectNode: Node | undefined, session: SessionType, settings: SettingsType, overrides?: { kpiType?: KpiTypes, statistic?: StatisticTypes }): number | undefined {
    const kpiType = overrides?.kpiType ?? settings.kpi.selectedKpi;
    const statistic = overrides?.statistic ?? settings.kpi.statistic;
    const propName = getNodeStatisticName(session, settings, { kpiType, statistic });

    if (propName === undefined || objectNode === undefined)
        return;
    const value = get(objectNode, propName) as number | undefined;
    return value;
}

/**
 * This function returns the path to a node statistic. The path is a string to the kpi.
 * @param settings 
 * @param overrides 
 * @returns string as a path to the kpi
 */
export function getNodeEquipmentStatisticName(session: SessionType, settings: SettingsType, kpi?: { kpiType?: KpiTypes, statistic?: StatisticTypes }): string | undefined {
    const kpiType = kpi?.kpiType ?? settings.kpi.selectedKpi;
    const statistic = kpi?.statistic ?? settings.kpi.statistic;
    const kpiDefinition = getKpiDefinition(kpiType, { session, settings });
    switch (statistic) {
        case StatisticTypes.Mean:
            return isObject(kpiDefinition?.equipmentNodeStatsPath) ? kpiDefinition?.equipmentNodeStatsPath.mean : kpiDefinition?.equipmentNodeStatsPath + ".mean";
        case StatisticTypes.Sum:
            return isObject(kpiDefinition?.equipmentNodeStatsPath) ? kpiDefinition?.equipmentNodeStatsPath.sum : kpiDefinition?.equipmentNodeStatsPath + ".sum";
        case StatisticTypes.Variance:
            return isObject(kpiDefinition?.equipmentNodeStatsPath) ? kpiDefinition?.equipmentNodeStatsPath.variance : kpiDefinition?.equipmentNodeStatsPath;
        case StatisticTypes.Median:
            return isObject(kpiDefinition?.equipmentNodeStatsPath) ? kpiDefinition?.equipmentNodeStatsPath.variance + ".median" : kpiDefinition?.equipmentNodeStatsPath + ".median";
        default:
            break;
    }
}

/**
 * This function returns the path to a node statistic. The path is a string to the kpi.
 * The string is build up depending on the grouping and the kpi.
 * @param settings 
 * @param overrides 
 * @returns string as a path to the kpi
 */
export function getNodeStatisticName(session: SessionType, settings: SettingsType, overrides?: { kpiType?: KpiTypes, statistic?: StatisticTypes }): string | undefined {
    const kpiType = overrides?.kpiType ?? settings.kpi.selectedKpi;
    const statistic = overrides?.statistic ?? settings.kpi.statistic;
    const kpiDefinition = getKpiDefinition(kpiType, { session, settings });
    switch (statistic) {
        case StatisticTypes.Mean:
            return isObject(kpiDefinition?.nodeStatisticsPath) ? kpiDefinition?.nodeStatisticsPath.mean : kpiDefinition?.nodeStatisticsPath + ".mean";
        case StatisticTypes.Sum:
            return isObject(kpiDefinition?.nodeStatisticsPath) ? kpiDefinition?.nodeStatisticsPath.sum : kpiDefinition?.nodeStatisticsPath + ".sum";
        case StatisticTypes.Variance:
            return isObject(kpiDefinition?.nodeStatisticsPath) ? kpiDefinition?.nodeStatisticsPath.variance : kpiDefinition?.nodeStatisticsPath;
        case StatisticTypes.Median:
            return isObject(kpiDefinition?.nodeStatisticsPath) ? kpiDefinition?.nodeStatisticsPath.variance + ".median" : kpiDefinition?.nodeStatisticsPath + ".median";
        default:
            break;
    }
}