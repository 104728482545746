import { sum } from "lodash";
import { CASE_TYPE_ID, Edge, BaseGraph } from "../models/Dfg";

export function addAverageStockToGraphNodes(dfg: BaseGraph) {
    dfg.nodes = dfg.nodes.map((n) => {
        const caseAverageStock = getAggregatedStock(dfg.edges, n.id, CASE_TYPE_ID);
        const caseWaitingOrders = getWaitingOrders(dfg.edges, n.id, CASE_TYPE_ID);
        const objects = n.objects?.map((o) => {
            if (o.type === undefined)
                return o;
            const objectAverageStock = getAggregatedStock(dfg.edges, n.id, o.type);
            const objectWaitingOrders = getWaitingOrders(dfg.edges, n.id, o.type);
            return {
                ...o,
                customKpis: {
                    ...o.customKpis,
                    ...objectWaitingOrders
                },
                kpis: {
                    ...o.kpis,
                    ...objectAverageStock
                }
            };
        });
        return {
            ...n,
            objects,
            customKpis: {
                ...n.customKpis,
                ...caseWaitingOrders
            },
            kpis: {
                ...n.kpis,
                ...caseAverageStock
            }
        };
    });
    return dfg;
}

export function getAggregatedStock(edges: Edge[], nodeId: string, objectType: string) {
    // we only sum up over stock that is going new into the node and do not sum up over loop edges
    // in addition we need to make sure that we are checking the right object types
    const relevantEdges = edges.filter((e) => e.to === nodeId && e.to !== e.from 
    && (e.objectType === objectType || (objectType === CASE_TYPE_ID && e.objectType === undefined)));
    const caseAverageStockSumCount = relevantEdges.length > 0 ? sum(relevantEdges.map((e) => e.kpis?.averageYieldStockCount)) : undefined;
    const caseAverageStockSumMass = relevantEdges.length > 0 ? sum(relevantEdges.map((e) => e.kpis?.averageYieldStockMass)) : undefined;
    const caseAverageStockSumLength = relevantEdges.length > 0 ? sum(relevantEdges.map((e) => e.kpis?.averageYieldStockLength)) : undefined;
    return {
        averageYieldStockCount: caseAverageStockSumCount,
        averageYieldStockMass: caseAverageStockSumMass,
        averageYieldStockLength: caseAverageStockSumLength
    };

}

export function getWaitingOrders(edges: Edge[], nodeId: string, objectType: string) {
    // we only sum up over orders that is going new into the node and do not sum up over loop edges
    // in addition we need to make sure that we are checking the right object types
    const relevantEdges = edges.filter((e) => e.to === nodeId && e.to !== e.from 
    && (e.objectType === objectType || (objectType === CASE_TYPE_ID && e.objectType === undefined)));
    const activeTransitions = relevantEdges.length > 0 ? sum(relevantEdges.map((e) => e.customKpis?.activeTransitions?.value)) : undefined;
    return {
        activeTransitions: {
            value: activeTransitions
        }
    };
}