import parse from "html-react-parser";
import { isArray } from "lodash";
import React from "react";
import i18n from "../../../i18n";
import { GroupingKeys, NodeDetailLevel, NodeRoles } from "../../../models/Dfg";
import Progress, { ProgressProps } from "../../progress/Progress";
import { Spotlight } from "../../spotlight/Spotlight";
import { NodeDomProps, NodeHandlers } from "../DfGraph";
import { NodeSize } from "./NodeSize";
import { KpiTypes } from "../../../models/KpiTypes";
import { classNames } from "../../../utils/Utils";

export enum RowTypes {
    Headline,

    /**
     * Text rows must have a label and may have a value. If the latter is present,
     * a two-column layout will be used to display both.
     */
    Text,

    /**
     * May contain cool HTML markup that will be injected into the nodes
     */
    Html,

    /**
     * TwoColumns must come with both, label and value. ValueType determines how that is
     * rendered. If value is omitted, the entire row will be supressed.
     * Also, the label will be translated if there's a translation string present.
     */
    Columns,

    /** Prevents rendering of this value */
    Hidden,
}

export enum SeparatorTypes {
    None,
    Line,
    Spacer,
    Section,
}

export type NodeMarkupGroup = {
    elements: (NodeMarkupRow | NodeMarkupGroup)[];
    separator?: SeparatorTypes;
    detailLevels?: NodeDetailLevel[];
    groupingKeys?: GroupingKeys[];
    kpis?: KpiTypes[];
    baseQuantities?: string[];
};

export enum RowValueTypes {
    LabelBar,
    PercentBar,
    Percent,
    Number,
    Text,
}

export type NodeMarkupRow = {
    // This definition is here so we can efficiently check if we have a group or not
    elements?: undefined;
    rowType: RowTypes;
    label?: string;
    valueType?: RowValueTypes;
    value?: string | number | ProgressProps | JSX.Element | undefined;
    detailLevels?: NodeDetailLevel[];
    groupingKeys?: GroupingKeys[];
    kpis?: KpiTypes[];
    baseQuantities?: string[];
}

export type NodeRendererProps = {
    markup: (NodeMarkupGroup | NodeMarkupRow)[];
    isSelected: boolean;
    isGroup: boolean;
    dom?: NodeDomProps;
    handlers?: NodeHandlers;
    role?: NodeRoles;
    warnings?: string[] | undefined;
    className?: string;
};

export default function NodeRenderer(props: NodeRendererProps) {
    return <div
        className={classNames([
            "nodeDetails",
            props.className,
            props.isSelected && "nodeSelected",
            props.role === NodeRoles.CarbonScope3 && "nodeMaterial",
            props.role === NodeRoles.Inventory && "nodeInventory",
        ])}
        data-testid={props.isSelected ? "selected" : "node"}
        {...props.dom}
        style={{
            ...props.dom?.style,
        }}
    >
        {props.isGroup && <div className="nodeStack" style={{
            backgroundColor: props.dom?.style.backgroundColor,
        }} />}

        {props.warnings !== undefined && props.warnings.length > 0 && <Spotlight img="/assets/exclamation.svg" className="nodeValidationWarning" contentHtml={renderWarnings(props.warnings)} />}

        <div className="nodeContentContainer" {...props.handlers}>
            {props.role === NodeRoles.Inventory && <div className="nodeInventoryIcon">
                <img src="/assets/inventory.svg" height="40" />
            </div>}
            {props.markup.filter(m => m !== undefined).map((m, i) => {
                return renderMarkupGroup(m, (props.dom?.key ?? "") + "-" + i.toString());
            })}
        </div>
    </div>;

    function renderWarnings(warnings: string[]) {

        if (warnings.length <= 1)
            return `<b>${i18n.t("dataQualityWarnings.title").toString()}</b><div>${warnings[0]}</div>`;

        return `<b>${i18n.t("dataQualityWarnings.title").toString()}</b>${warnings.map(w => "<div>" + w + "</div>").join("")}`;
    }

    function renderMarkupGroup(e: NodeMarkupGroup | NodeMarkupRow, keyPrefix: string) {
        const result: JSX.Element[] = [];

        if (e.elements === undefined) {
            // This is a row, not a group
            return renderRow(e, keyPrefix);
        }

        // Render row data
        for (const element of e.elements ?? []) {
            if (element.elements !== undefined) {
                // this is a group by itself, render accordingly!
                const groupJsx = renderMarkupGroup(element, keyPrefix + "-" + result.length.toString());
                if (groupJsx !== undefined)
                    if (isArray(groupJsx)) {
                        for (const e of groupJsx)
                            result.push(e);
                    }
                    else
                        result.push(groupJsx as JSX.Element);

                continue;
            }

            const jsx = renderRow(element, keyPrefix + result.length.toString());
            if (jsx === undefined)
                continue;

            result.push(jsx as JSX.Element);
        }

        // Add decorators and shit
        if (result.length === 0)
            return undefined;

        if (e.separator === SeparatorTypes.Spacer)
            return <div key={keyPrefix + "-group"} className="mb">{result}</div>;

        if (e.separator === SeparatorTypes.Line)
            return <div key={keyPrefix + "-group"} className="section sectionBorder">{result}</div>;

        if (e.separator === SeparatorTypes.Section)
            return <div key={keyPrefix + "-group"} className="section dfgNode">{result}</div>;

        return result;
    }

    function renderRow(element: NodeMarkupRow, keyPrefix: string) {
        const key = keyPrefix + (element.label ?? element.value);
        switch (element.rowType) {
            case RowTypes.Headline:
                if (element.value === undefined)
                    break;

                return <div key={key} className="oneCol"><b><>{element.value}</></b></div>;

            case RowTypes.Html:
            // Set React key to the JSX element
                return React.cloneElement(element.value as JSX.Element, { key });

            case undefined:
            case RowTypes.Text:
                if (element.value === undefined)
                    break;

                return <div key={key} className="oneCol"><>{element.value}</></div>;

            case RowTypes.Columns:
                if (element.value === undefined)
                    break;

                if (element.label === undefined)
                // This should never happen - I'm primarily pleasing the compiler here
                    throw Error("value without label");

                return <div key={key} className="twoCols">
                    <label>{parse(i18n.t(element.label))}</label>
                    <div>{renderValue(element.valueType, element.value as string | number | ProgressProps)}</div>
                </div>;
        }

        return undefined;
    }

    function renderValue(valueType: RowValueTypes | undefined, value: string | number | ProgressProps): JSX.Element {
        switch (valueType) {
            case RowValueTypes.Percent:
                return <div>{(+value).toFixed(1)} %</div>;

            case undefined:
            case RowValueTypes.Text:
            case RowValueTypes.Number:
                return <div><>{value}</></div>;

            case RowValueTypes.LabelBar:
                return <Progress {...(value as ProgressProps)} />;

            case RowValueTypes.PercentBar:
                return <Progress percent={+value} />;
        }
    }
}

export function getMetrics(markup: (NodeMarkupGroup | NodeMarkupRow)[]) {
    const size = new NodeSize();

    getMetricsRecursive(markup, size);

    return size.getSize();
}

function getMetricsRecursive(markup: (NodeMarkupGroup | NodeMarkupRow)[], size: NodeSize) {
    for (const element of markup) {
        if (element.elements === undefined) {
            size.addRowSize(element);
            continue;
        } else {
            const group = element as NodeMarkupGroup;
            if (group.separator === SeparatorTypes.Line)
                size.addSection();
        }

        // element is a markup group itself, so it's time for recursion!
        getMetricsRecursive(element.elements, size);
    }
}
