import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { AnalysisType, calculateOutputStatsArguments, useGraph } from "../../hooks/UseGraph";
import { GroupingKeys, NodeRoles } from "../../models/Dfg";
import { KpiTypes } from "../../models/KpiTypes";
import { DfgUtils, getCustomKpisDfg } from "../../utils/DfgUtils";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { LogStats } from "../../views/process-kpi-chart/stats/LogStats";
import { StatsSection } from "../stats-section/StatsSection";
import { FrequencyAndProductStats, NodeStats } from "../stats-section/Utility";
import { LocalizationInfoStats } from "./LocalizationInfoStats";
import { getKpiDefinition } from "../../models/Kpi";
import NodeStatsLineGraph from "../graph/NodeStatsLineGraph";

export default function SideStatisticsQuality(props: {
    groupingKey?: GroupingKeys,
}) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const graphOptions = {
        ...calculateOutputStatsArguments,
        calculateNodes: true,
        calculateActivityValues: true,
        ...getCustomKpisDfg(settings, session, false, [KpiTypes.ScrapQuantity, KpiTypes.ScrapRatio]),
    };

    const isTerminalNode = settings.selection.node?.role === NodeRoles.Start || settings.selection.node?.role === NodeRoles.End;
    const hasSelection = !isTerminalNode && (settings.selection.node !== undefined || settings.selection.edge !== undefined);
    const disable = !hasSelection;

    const graph = useGraph(graphOptions, AnalysisType.Output, false, disable);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const isLoading = graph === undefined;

    const node = graph?.nodes.find(n => n.id === settings.selection.node?.id);
    const selection = node ? DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType) : undefined;

    const label = selection ? getShortActivityLabelFromActivityValues(selection?.activityValues, props.groupingKey ?? settings.groupingKey)
        : undefined;

    if (!hasSelection)
        return <LogStats />;

    // Don't show stats for edges
    if (settings.selection.edge)
        return null;

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { settings, session });

    return <>
        <div data-testid="sidePanel">
            {!isTerminalNode && <StatsSection title={label} isTitleLoading={isLoading}>
                <LocalizationInfoStats />

                <NodeStats kpiType={settings.kpi.selectedKpi} node={selection} disableSum={settings.kpi.selectedKpi === KpiTypes.ScrapRatio} isLoading={isLoading} />

                {/* Line chart */}
                {!!kpiDefinition?.nodeOverTimeStatisticsPath && <NodeStatsLineGraph />}

                <FrequencyAndProductStats node={selection} isLoading={isLoading}/>
            </StatsSection>}
        </div>
    </>;
}
