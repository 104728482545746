import { isArray } from "lodash";
import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { useGroupEvents } from "../../../../hooks/UseGroupEvents";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { ActivityItem } from "../../../../models/EventKeys";
import { fixActivityItem } from "../../../../utils/FilterBuilder";
import { getActivityLabelFromActivityItem, getActivityLabelFromColumnInfo, groupSupportsConsolidatePasses } from "../../../../utils/GroupingUtils";
import Dropdown from "../../../dropdown/Dropdown";
import { CardInfo } from "../../../dropzone/Card";
import { CollectionTypes, Dropzone, Orientations, SearchResults } from "../../../dropzone/Dropzone";
import Spinner from "../../../spinner/Spinner";

export type SequenceFilterEditorPropsType = {
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};

export default function SequenceFilterEditor(props: SequenceFilterEditorPropsType) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const groupingKey = props.initialValue?.caseSequence?.groupingKey ?? settings.groupingKey;

    const filtersWithoutMyself = settings.filters.filter((s, idx) => {
        return settings.filterEditor.editFilterIndex === undefined || idx !== settings.filterEditor.editFilterIndex;
    });

    const [activities, attributes, isLoading] = useGroupEvents(groupingKey, filtersWithoutMyself);

    const [enforceImmediateSequence, setEnforceImmediateSequence] = useState<boolean>(props.initialValue?.caseSequence?.enforceImmediateSequence ?? false);
    const [isExcluded, setIsExcluded] = useState<boolean>(props.initialValue?.caseSequence?.ne !== undefined ?? false);

    const initSequence = props.initialValue?.caseSequence?.eq ?? props.initialValue?.caseSequence?.ne;
    const initActivities = initSequence && initSequence[0] ? initSequence[0] : [];

    const [sequence, setSequence] = useState<CardInfo[]>(initActivities.map(a => {
        return {
            id: a.id + Math.random(),
            label: getActivityLabelFromActivityItem(a, groupingKey),
            data: a.id,
        } as CardInfo;
    }));

    const sortedActivities = (activities ?? []).sort((a, b) => {
        return (isArray(a.value) ? a.value : [a.value]).join(", ").localeCompare((isArray(b.value) ? b.value : [b.value]).join(", "));
    });

    // Emit state once everything is initialized
    useEffect(() => {
        if (attributes)
            emitState(sequence, isExcluded, enforceImmediateSequence);
    }, [
        attributes,
    ]);

    // Clear elements when grouping key changes
    useEffect(() => {
        if (groupingKey === props.initialValue?.caseSequence?.groupingKey)
            return;

        setSequence([]);
    }, [
        groupingKey,
    ]);

    const dropdownOptions = useMemo(() => {
        return [{
            label: i18n.t("filters.sequence.regularSequence"),
            value: false,
        }, {
            label: i18n.t("filters.sequence.immediateSequence"),
            value: true,
        }];
    }, [
        session.locale,
    ]);

    const isReady = !isLoading && sortedActivities !== undefined && attributes?.length;

    const searchCb = useCallback(search, [
        attributes, activities
    ]);

    const dropzone = useMemo(() => {
        return <Dropzone
            key={Math.random().toString()}
            sequenceHeader={
                <Dropdown
                    isSearchable={false}
                    className="dropdownLight mbs"
                    onChange={(e) => {
                        if (!e)
                            return;

                        setEnforceImmediateSequence(e.value as boolean);
                        emitState(sequence, isExcluded, e.value as boolean);
                    }}
                    options={dropdownOptions}
                    value={dropdownOptions.find(o => o.value === enforceImmediateSequence)!}
                />
            }
            orientation={Orientations.Horizontal}
            searchTitle={i18n.t("filters.sequence.selection").toString()}
            dropzoneLabel={i18n.t("filters.sequence.sequence").toString()}
            searchPlaceholderLabel={i18n.t("filters.sequence.selectionPlaceholder")}
            totalCount={sortedActivities.length}
            onChange={(s) => {
                setSequence(s);
                emitState(s, isExcluded, enforceImmediateSequence);
            }}
            initialValue={sequence}
            collectionType={CollectionTypes.Sequence}
            onSearch={search}
        />;
    }, [
        groupingKey,
        sequence,
        attributes,
        activities,
        isExcluded,
        dropdownOptions,
        enforceImmediateSequence,
        searchCb,
    ]);

    return <>
        <Spinner isLoading={!isReady} className="filterSpinner" />
        {isReady && <div className="sequenceFilterEditor">
            {dropzone}
            <div className="checkboxes">
                <label>
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={isExcluded}
                        id="checkbox-exclude"
                        data-testid="checkbox-exclude"
                        onChange={(e) => {
                            setIsExcluded(e.target.checked);
                            emitState(sequence, e.target.checked, enforceImmediateSequence);
                        }} />
                    <label htmlFor="checkbox-exclude" />
                    {i18n.t("filters.sequence.exclude")}
                </label>
            </div>
        </div>}
    </>;

    function search(parts: string[]): SearchResults {
        const attributeKeys = attributes ?? [];
        const matches = (sortedActivities || []).filter(a => parts.length === 0 || parts.every(p => getActivityLabelFromColumnInfo(a, attributeKeys, groupingKey)!.toLocaleLowerCase().indexOf(p) >= 0));

        return {
            matches: matches.map(m => {
                return {
                    id: m.id,
                    label: getActivityLabelFromColumnInfo(m, attributeKeys, groupingKey),
                    data: m.id,
                } as CardInfo;
            }),
            hasMoreData: false
        };
    }

    function emitState(s: CardInfo[], isExcluded: boolean, enforceImmediateSequence: boolean) {
        const sequence = [s.map(e => {
            const info = activities?.find(a => a.id === e.data);
            if (!info || info.value === null || info.value === undefined)
                return;

            return fixActivityItem({
                id: info.id,
                keys: attributes ?? [],
                values: info?.value,
            } as ActivityItem);
        }).filter(s => s !== undefined) as ActivityItem[]];

        const filter: EventFilter | undefined = s?.length ? {
            caseSequence: {
                groupingKey,
                eq: !isExcluded ? sequence : undefined,
                ne: isExcluded ? sequence : undefined,
                consolidatePasses: groupSupportsConsolidatePasses(groupingKey),
                enforceImmediateSequence
            }
        } : undefined;

        if (!sequence?.length || !sequence[0].length) {
            props.onUpdate?.(undefined);
            return;
        }

        props.onUpdate?.(filter);
    }
}
