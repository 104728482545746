import React, { useContext, useMemo } from "react";
import { generatePath, useNavigate, useResolvedPath } from "react-router-dom";
import { SessionContext, SessionType} from "../../contexts/SessionContext";
import { getPushedHistory, SettingsContext, SettingsContextType } from "../../contexts/SettingsContext";
import { useColumnValues } from "../../hooks/UseColumnValues";
import { AnalysisType, useGraph, UseGraphArguments } from "../../hooks/UseGraph";
import { useGraphLayout } from "../../hooks/UseGraphLayout";
import { useNodesByCases } from "../../hooks/UseNodesByCases";
import i18n from "../../i18n";
import { BaseGraph, Graph, GroupingKeys, Node } from "../../models/Dfg";
import { EventKeys } from "../../models/EventKeys";
import { DfgUtils, updateHash } from "../../utils/DfgUtils";
import { buildCaseFilter } from "../../utils/FilterBuilder";
import { DfgLayoutPropsType, DfGraphRender, IDfGraph } from "../dfg/DfGraph";
import { useCases } from "../../hooks/UseCases";

export const OrderTrackingGraph = React.forwardRef((props: DfgLayoutPropsType & {globalSettings?: SettingsContextType}, ref: React.Ref<IDfGraph>) => {
    const graph = useOrderTrackingGraph();

    const graphLayout = useGraphLayout({
        graph,
        preLayoutGraphFilterFunc: orderTrackingGraphFilterFunc,
        markupFunc: props.markupFunc,
        edgeLabelFunc: props.edgeLabelFunc,
        isObjectCentric: true,
        hasEventId: true
    });

    return <div className="fillParent"><DfGraphRender
        {...props}
        ref={ref}
        graph={graph}
        graphLayout={graphLayout}
    />
    {props.globalSettings !== undefined && <OrderTrackingShortcuts className="shortcutsAbsolute" globalSettings={props.globalSettings} />}
    </div>;
});

function orderTrackingGraphFilterFunc(graph: BaseGraph | undefined) {
    if (graph === undefined)
        return graph;
    const onlyObjectsGraph = DfgUtils.filterOnlyObjects(graph);
    const groupedStartEndNodeGraph = DfgUtils.groupStartEndNodes(onlyObjectsGraph);
    return groupedStartEndNodeGraph;
}

export function useOrderTrackingGraph(options?: Partial<UseGraphArguments>): Graph | undefined {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    // Prevent invalid intermediate states
    const orderTrackingArguments = useOrderTrackingArguments();
    const args = orderTrackingArguments ?
        {
            ...orderTrackingArguments,
            ...options
        } : undefined;

    const graph = useGraph(args, AnalysisType.Times, false, !args);

    // MachineType is cases!!!!!
    const caseIds = graph?.nodes.map(n => n.activityValues?.machineType?.value).filter(c => c !== undefined) as string[];
    const caseFilter = buildCaseFilter(caseIds, false, session);
    const requestOptions = {eventFilters: caseFilter ? [caseFilter] : [], calculateOutputStats: true};
    // get cases for grouping machineType (Orders)
    const disableCaseStatsRequest = !caseFilter || settings.groupingKey !== GroupingKeys.MachineType;
    const [cases] = useCases(requestOptions, {
        disable: disableCaseStatsRequest,
    });

    // get nodes by cases stats
    const getNodesByCases = caseFilter && settings.groupingKey === GroupingKeys.Machine;
    const [nodesByCases] = useNodesByCases({
        ...requestOptions,
        eventKeys: {...session.project?.eventKeys, activityKeysGroup: GroupingKeys.Machine} as EventKeys
    }, {
        disable: !getNodesByCases,
    });

    return useMemo(() => {
        if (graph === undefined)
            return;
        const newGraph = {...graph};
        // Machine type is the case/order view for order tracking.
        // Therefore we are using the case stats from the default log and insert them as throughput time.
        if (settings.groupingKey === GroupingKeys.MachineType && cases !== undefined) {
            const nodes: Node[] = newGraph?.nodes.map(n => {
                const caseStats = cases?.cases.find(c => c.id === n.activityValues?.machineType?.value);
                // TODO: add other relative scrap statistics than only count (mass, length)
                return {...n, throughputTime: caseStats ? caseStats.actual?.duration : undefined, kpis: {...n.kpis, relativeScrapCount: caseStats?.actual?.kpis?.relativeCaseScrapCount}};
            }) as Node[];
            const result = {...newGraph, nodes}!;
            updateHash(result);
            return result;
        }
        // We are using the statistics from the default log here in the nodes.
        if (settings.groupingKey === GroupingKeys.Machine) {
            const nodes: Node[] = newGraph?.nodes.map(n => {
                let nodeStats = undefined;
                if (nodesByCases !== undefined) {
                    nodeStats = nodesByCases.records.find(nodeByCase => (
                    // the caseId of the node by case response needs to be equal to the machine type in our graph (machine type is the case/order)
                        nodeByCase.caseId === n.activityValues?.machineType?.value &&
                    // the machine names need to match (this works only if the machine names are unique within the case/order)
                    nodeByCase.activityValues?.machine?.value === n.activityValues?.machine?.value));
                }
                return {...nodeStats, id: n.id, activityValues: n.activityValues, role: n.role};
            }) as Node[];
            const result = {...newGraph, nodes}!;
            updateHash(result);
            return result;
        }
        
        updateHash(newGraph);
        return newGraph;
    }, [
        settings.groupingKey,
        graph,
        cases,
        nodesByCases
    ]);
}

export function useOrderTrackingArguments() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    // This useMemo is here for stable references, rather than speeding things up.
    // This way, the result of this hook can be used as a trigger for useEffects
    // or useMemos.
    const orderTrackingArguments = useMemo(() => {
        if (!settings.orderTracking.orderFilter)
            return;

        const orderFilterEntry = settings.orderTracking.orderFilter ? [settings.orderTracking.orderFilter] : undefined;
        const caseFilter = orderFilterEntry && session.project?.eventKeysOrderTracking?.caseId ? [{
            caseAttributeText: {
                name: session.project?.eventKeysOrderTracking?.caseId,
                eq: orderFilterEntry,
            }
        }] : [];

        return {
            uploadId: session.project?.uploadIdOrderTracking,
            eventKeys: session.project?.eventKeysOrderTracking,
            groupingKey: settings.groupingKey,
            eventFilters: caseFilter
        } as UseGraphArguments;
    }, [
        settings.orderTracking.orderFilter,
        session.project?.eventKeysOrderTracking?.caseId,
        settings.groupingKey
    ]);

    return orderTrackingArguments;
}


export function OrderTrackingShortcuts(props: {
    globalSettings: SettingsContextType
    className: string;
}) {
    const navigate = useNavigate();
    const url = useResolvedPath("").pathname;
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const selectedCase = settings.selection?.node?.activityValues?.machineType?.value?.toString();

    // we gather the caseIds from the default log to check whether it makes sense to add a filter
    const caseIdLabel = session.project?.eventKeys?.caseId;
    const caseIdColumnValues = (useColumnValues(caseIdLabel !== undefined ? [caseIdLabel] : []) ?? {})[caseIdLabel || ""] || [];
    const possibleCaseIds = caseIdColumnValues.map((c) => c.value.toString()) as string[];

    if (!selectedCase)
        return <></>;

    return <div className={props.className}>
        <button
            title={i18n.t("orderTracking.analyzeInProcessGraphHelper").toString()}
            className="shortcutButton"
            disabled={!possibleCaseIds.includes(selectedCase)}
            onClick={() => {
                jumpToSelectedCaseDfg(selectedCase);
            }}>
            {i18n.t("orderTracking.analyzeInProcessGraph")}
        </button>
    </div>;

    function jumpToSelectedCaseDfg(selectedCase: string | undefined) {
        const filter = buildSelectedCaseFilter(selectedCase, session);
        if (!filter)
            return;
        props.globalSettings.set({
            filters: [filter],
            history: getPushedHistory(url, props.globalSettings)
        });
        
        const path = generatePath("/projects/:projectId/timings/process/:tabSlug", { projectId: session.projectId!, tabSlug: "dfg" });
        navigate(path);
    }
}


function buildSelectedCaseFilter(selectedCase: string | undefined, session: SessionType) {
    if (selectedCase === undefined)
        return;
    const filter = buildCaseFilter([selectedCase], false, session);
    if (filter)
        return filter;
}
