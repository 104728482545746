import { useContext, useMemo } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { disableAllCalcOptions, PerTimeperiodDeviationStatisticsParams } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { PerTimeperiodDeviationStatisticsSchema } from "../models/generated";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { AggregatedTimeperiodSchema } from "./UseTimeAggregatedCaseStatistics";

/**
 * Fetches time-aggregated case statistics. Returns a format that is shared between multiple hooks.
 */ 
export function useDeviationTimeperiodStatistics(request: Partial<PerTimeperiodDeviationStatisticsParams>, options?: ApiHookOptions<PerTimeperiodDeviationStatisticsSchema> & {
    addEnergyStats?: boolean;
}):
    [AggregatedTimeperiodSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: PerTimeperiodDeviationStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        actual: {
            ...request.actual,
            uploadId: request?.actual?.uploadId ?? session.project?.uploadId ?? "",
            eventKeys: request?.actual?.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys,
            eventFilters: request?.actual?.eventFilters ?? settings.previewFilters ?? settings.filters ?? [],
            uploads: session.project?.uploads,
        },
        planned: {
            uploadId: request?.planned?.uploadId ?? session.project?.uploadIdPlan ?? request?.actual?.uploadId ?? session.project?.uploadId ?? "",
            eventKeys: (request?.planned?.eventKeys ?? session.project?.eventKeysPlan ?? {}) as EventKeys,
        },
        tz: request?.tz ?? session.timezone ?? "UTC",
    };

    const hasPlanningData = requestOptions.planned.uploadId.length > 0;

    const [data, isLoading, hash] = useApi(Datastores.getDeviationTimeperiodStatistics, requestOptions, [JSON.stringify(requestOptions)], {
        ...options,
        disable: !hasPlanningData || options?.disable,
    });

    const response = useMemo(() => {
        return data ? toAggregatedTimeperiodSchema(data) : undefined;
    }, [ data ]);

    return [response, isLoading && hasPlanningData, hash];
}

function toAggregatedTimeperiodSchema(data: PerTimeperiodDeviationStatisticsSchema): AggregatedTimeperiodSchema {
    return {
        frequency: data.frequency,
        timeperiods: data.timeperiods,
        log: {
            actual: data.log,
        },
    };
}