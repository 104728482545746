import { get, uniq } from "lodash";
import React, { useContext, useState } from "react";
import { QuantityDropdown } from "../../components/controls/KpiControlPrimitives";
import Dropdown from "../../components/dropdown/Dropdown";
import Modal, { ModalProps } from "../../components/modal/Modal";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext, SessionType, isOniqDev, isOniqEmployee } from "../../contexts/SessionContext";
import { DashboadTileSettings, SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { caseKpiControlsGetAllowedStatistics, getKpiDefinition } from "../../models/Kpi";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { Quantity, quantities } from "../../utils/Quantities";
import { BaseQuantityType } from "../../models/ApiTypes";
/**
 * This is strongly inspired by <KpiControls>, but that works on the settings context,
 * while we need to generate a tile settings config here. Also it's light-themed…
 */

type KpiOptionsType = ({
    label: string;
    value: KpiTypes | undefined;
} | {
    label: string;
    options: {
        label: string;
        value: KpiTypes;
    }[];
})[]

export function DashboardTileSettingsModal(props: Omit<ModalProps, "children"> & {
    onChange: (kpi: KpiTypes | undefined, statistic: StatisticTypes, quantity: BaseQuantityType | undefined) => void,
    initialValue?: DashboadTileSettings;
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const isOniqUser = isOniqEmployee(session);
    const isOniqDeveloper = isOniqDev(session);

    const allowedKpis = getAllAllowedKpisAnyStatistic(session, settings);

    // Carbon/Energy KPIs contain faked data so we don't want to show them to non-oniq users
    const kpis = !isOniqUser ? allowedKpis.filter(kpi => !["carbon", "carbonPerOutput", "energyPerOutput", "energy"].includes(kpi)) : allowedKpis;

    const kpiOptions: KpiOptionsType = kpis.map(v => {
        const kpiDefinition = getKpiDefinition(v, { session, settings });
        const kpiOption = { label: i18n.t(kpiDefinition?.label ?? ""), value: v };
        if (kpiDefinition?.indentationLevel)
            return { label: "", options: [kpiOption] };
        return kpiOption;
    });

    // This is a hack only for special purposes where we would like to show less tiles on the dashboard.
    if (isOniqDeveloper)
        kpiOptions.push({ label: i18n.t("common.noKpi"), value: undefined });

    const [kpiType, setKpiType] = useState(props.initialValue?.kpiType ?? settings.kpi.selectedKpi);

    const allowedStatistics = caseKpiControlsGetAllowedStatistics(session, settings, kpiType, AggregationTypes.Time) ?? [];
    const [statistic, setStatistic] = useState(() => {
        const candidate = props.initialValue?.statistic ?? settings.kpi.statistic;
        if (!allowedStatistics.includes(candidate))
            return allowedStatistics[0];
        return candidate;
    });

    const kpiDefinition = getKpiDefinition(kpiType, getContextFromTile({ kpiType, statistic }, session, settings));
    // If planning data exists, offer both quantities allowed for actual and plan. Otherwise,
    // only those from actual.
    const allowedQuantities = getAllowedQuantities(kpiType, statistic);

    const [quantity, setQuantity] = useState(() => {
        const candidate = props.initialValue?.quantity ?? settings.quantity ?? allowedQuantities[0]?.baseQuantity;
        if (allowedQuantities && !allowedQuantities.some(q => q.baseQuantity === candidate))
            return allowedQuantities[0]?.baseQuantity;
        return candidate;
    });

    return <Modal
        {...props}
        isClosable={true}
        isVisible={true}
        canBlur={true}
        title={i18n.t("dashboard.tileSelectionModal.title").toString()}
        showCancelButton={true}
        onDone={() => {
            const hasQuantity = allowedQuantities !== undefined && allowedQuantities.length > 0;
            props.onChange(kpiType, statistic, hasQuantity ? quantity : undefined);
            return Promise.resolve();
        }}
        width={500}>
        <Dropdown
            className="dropdownLight mb"
            options={kpiOptions}
            testId="dropdown-kpi"
            value={{ value: kpiType, label: i18n.t(getKpiDefinition(kpiType, { session, settings })?.label ?? "") }}
            onChange={(e) => {
                const kpi = e!.value as KpiTypes;
                setKpiType(kpi);
                const stat = getValidStatistic(statistic, kpi, AggregationTypes.Time);
                setStatistic(stat);
                const allowedQuantities = getAllowedQuantities(kpi, stat);
                if (allowedQuantities?.length > 0 &&
                    (quantity === undefined || !allowedQuantities.some(q => q.baseQuantity === quantity)))
                    setQuantity(allowedQuantities[0].baseQuantity);
            }}
            isSearchable={true}
        />

        <div className="buttons-3col mtLarge mb">
            <StatisticButton disabled={!allowedStatistics.includes(StatisticTypes.Mean)} statistic={StatisticTypes.Mean} />
            <StatisticButton disabled={!allowedStatistics.includes(StatisticTypes.Sum)} statistic={StatisticTypes.Sum} />
            <StatisticButton disabled={!allowedStatistics.includes(StatisticTypes.Median)} statistic={StatisticTypes.Median} />
        </div>

        <>
            {allowedQuantities?.length > 0 && <QuantityDropdown
                quantities={allowedQuantities}
                className="dropdownLight"
                value={quantity}
                onChange={q => setQuantity(q)} />}
        </>
    </Modal>;

    function getValidStatistic(statistic: StatisticTypes, kpiType: KpiTypes, aggregation: AggregationTypes) {
        const allowedStatistics = caseKpiControlsGetAllowedStatistics(session, settings, kpiType, aggregation);
        if (!allowedStatistics)
            return StatisticTypes.Mean;

        if (allowedStatistics.includes(statistic))
            return statistic;

        return allowedStatistics[0];
    }

    function changeStatistic(stat: StatisticTypes) {
        if (statistic !== stat)
            setStatistic(stat);
        const allowedQuantities = getAllowedQuantities(kpiType, stat);
        if (kpiDefinition?.isQuantityDependent && allowedQuantities?.length > 0 && quantity === undefined)
            setQuantity(allowedQuantities[0].baseQuantity);
    }

    function StatisticButton(props: { statistic: StatisticTypes, disabled?: boolean }) {
        const id = `button-statistic-${props.statistic.toString().toLowerCase()}`;
        return <button
            disabled={props.disabled}
            className={statistic === props.statistic ? "active" : ""}
            id={id}
            data-testid={id}
            onClick={() => {
                changeStatistic(props.statistic);
            }}>
            {i18n.t(`common.statistics.${props.statistic}`)}
        </button>;
    }

    function getAllowedQuantities(kpiType: KpiTypes | undefined, statistic: StatisticTypes) {
        if (!kpiType)
            return [];

        const kpiDefinition = getKpiDefinition(kpiType, getContextFromTile({ kpiType, statistic }, session, settings));
        const allowedBaseQuantities: BaseQuantityType[] = (kpiDefinition?.allowedQuantities.actual.timeperiod ?? kpiDefinition?.allowedQuantities.actual.case ?? []).concat(session.project?.eventKeysPlan !== undefined ? kpiDefinition?.allowedQuantities.plan.case ?? [] : []);
        const allowedQuantities = uniq(allowedBaseQuantities).map(baseQuantity => quantities.find(q => q.baseQuantity === baseQuantity && !q.isFrequency)).
            filter(q => q !== undefined) as Quantity[];

        return allowedQuantities;
    }
}

export function getContextOverride(session: SessionType, settings: SettingsType, overrides: Partial<{
    statistic: StatisticTypes,
    kpi: KpiTypes,
    aggregation: AggregationTypes,
    quantity: BaseQuantityType,
}>) {
    return {
        session,
        settings: {
            ...settings,
            quantity: overrides.quantity ?? settings.quantity,
            kpi: {
                ...settings.kpi,
                selectedKpi: overrides.kpi ?? settings.kpi.selectedKpi,
                statistic: overrides.statistic ?? settings.kpi.statistic,
                aggregation: overrides.aggregation ?? settings.kpi.aggregation,
            }
        },
    };
}

export function getContextFromTile(tile: DashboadTileSettings, session: SessionType, settings: SettingsType) {
    return getContextOverride(session, settings, {
        statistic: tile.statistic,
        kpi: tile.kpiType,
        aggregation: AggregationTypes.Time,
        quantity: tile.quantity,
    });
}


function getAllAllowedKpisAnyStatistic(session: SessionType, settings: SettingsType) {
    const result: Set<KpiTypes> = new Set();
    for (const kpiType of uniq([
        ...KpiPresets.productTimeKpis,
        ...KpiPresets.productOutputKpis,
        ...KpiPresets.productInventoryKpis,
        ...KpiPresets.productQualityKpis,
        ...KpiPresets.productSustainabilityKpis,
    ])) {
        const allowedStatistics = caseKpiControlsGetAllowedStatistics(session, settings, kpiType, AggregationTypes.Time);
        if (!allowedStatistics)
            continue;

        for (const statistic of allowedStatistics) {
            const override = {
                aggregation: AggregationTypes.Time,
                statistic,
                kpi: kpiType,
            };
            const def = getKpiDefinition(kpiType, getContextOverride(session, settings, override));

            if (!def ||
                (def.requiresPlanningData && !session.project?.uploadIdPlan) ||
                (def.isQuantityDependent && def.allowedQuantities.actual.case.length === 0 && def.allowedQuantities.plan.case.length === 0) ||
                (def.requiredEventKeys !== undefined && def.requiredEventKeys.some(eventKey => get(session.project, eventKey) === undefined)) ||
                (def.requiredEventKeys !== undefined && def.allowedQuantities.actual.case.some(q => {
                    const kpiDef = getKpiDefinition(kpiType, getContextOverride(session, settings, {
                        ...override,
                        quantity: q,
                    }));
                    return kpiDef?.requiredEventKeys?.some(eventKey => get(session.project, eventKey) === undefined);
                })))
                continue;

            result.add(kpiType);
        }
    }

    return Array.from(result);
}
