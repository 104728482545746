import { uniqBy } from "lodash";
import { useContext, useState } from "react";
import { defaultProductLimit } from "../Global";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { CaseDeviationStatisticsParams, PerProductStatisticsParams, disableAllCalcOptions } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { PerProductCaseStatisticsSchema, PerProductDeviationStatisticsSchema, ProductCaseAggregationStatisticsSchema } from "../models/generated";
import { fixSortOrder } from "./UseCases";
import { getPlanningState } from "../utils/SettingsUtils";

type LogType = {
    productCount: number;
};

export type ProductStatType = {
    name: string;
    id: string;
    actual?: ProductCaseAggregationStatisticsSchema;
    planned?: ProductCaseAggregationStatisticsSchema;
    deviation?: ProductCaseAggregationStatisticsSchema;
};

export type ProductStatsType = {
    // Can be undefined when a plan wasn't executed
    products: ProductStatType[],
    log: {
        actual?: LogType;
        planned?: LogType;
        deviation?: LogType;
    }
}

export function useProducts(request: Partial<PerProductStatisticsParams>, options?: ApiHookOptions<ProductStatsType>): [ProductStatsType | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [result, setResult] = useState<ProductStatsType | undefined>(undefined);

    const {hasRoutings, hasPlanningLog} = getPlanningState(session);

    // Decide which endpoint to use.
    const isDeviationRequested = !!request.calculateDeviations || !!request.calculatePlanned;

    // Only use deviation endpoint when we don't have routings!
    const useDeviationApi = isDeviationRequested && !hasRoutings && hasPlanningLog;
    const useRegularApi = !isDeviationRequested || hasRoutings;

    // Build requests
    const uploadId = request.uploadId ?? session.project?.uploadId ?? "";
    const eventKeys = request.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys;
    const eventFilters = request.eventFilters ?? settings.previewFilters ?? settings.filters;
    const limit = request.limit ?? defaultProductLimit;

    // Deviation endpoing
    const deviationOptions: CaseDeviationStatisticsParams = {
        actual: {
            uploadId,
            uploads: session.project?.uploads,
            eventKeys,
            eventFilters,
        },
        planned: {
            uploadId: session.project?.uploadIdPlan ?? "",
            eventKeys: session.project?.eventKeysPlan ?? {} as EventKeys,
        },
        ...disableAllCalcOptions,
        ...request, // calculate...
        limit,
        sort: fixSortOrder(session, request.sort, isDeviationRequested),
        offset: request.offset,
    };

    // State is set using the onData callback, so we don't need to return the result here.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, isDeviationResponseLoading] = useApi(Datastores.getPerProductCaseDeviationStatistics, deviationOptions, [ JSON.stringify(deviationOptions) ], {
        disable: !useDeviationApi || options?.disable || session.project?.id === undefined,
        onData: (data) => {
            const mapped = mapFromDeviationResponse(data);
            setResult(mapped);
            options?.onData?.(mapped);
        },
    });

    // Regular endpoint
    const regularRequest: PerProductStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        eventFilters,
        eventKeys,
        uploadId,
        uploads: session.project?.uploads,
        limit,
        calculateDeviations: request?.calculateDeviations && hasRoutings,
        calculatePlanned: request?.calculatePlanned && hasRoutings,
        // ugly but works for now
        sort: fixSortOrder(session, request.sort, isDeviationRequested),
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [__, isRegularResponseLoading] = useApi(Datastores.getProductCaseAggregationStatistics, regularRequest, [ JSON.stringify(regularRequest) ], {
        disable: !useRegularApi || options?.disable || session.project?.id === undefined,
        onData: (data) => {
            const mapped = mapFromRegularResponse(data);
            setResult(mapped);
            options?.onData?.(mapped);
        },
    });

    return [result, isDeviationResponseLoading || isRegularResponseLoading];
}

/**
 * Converts the response from the regular API to the unified format
 */
function mapFromRegularResponse(data: PerProductCaseStatisticsSchema) {
    const products = uniqBy((data?.products.map(p => { return { id: p.id, name: p.name }; }) ?? []).concat(data?.planned?.products.map(p => { return { id: p.id, name: p.name }; }) ?? []), "id");

    const actualMap = new Map(data?.products.map(p => [p.id, p]));
    const plannedMap = new Map(data?.planned?.products.map(p => [p.id, p]));
    const deviationMap = new Map(data?.deviation?.products.map(p => [p.id, p]));

    const result: ProductStatsType = {
        products: products.map(p => {
            return {
                id: p.id,
                name: p.name,
                actual: actualMap.get(p.id),
                planned: plannedMap.get(p.id),
                deviation: deviationMap.get(p.id),
            };
        }),
        log: {
            actual: {
                productCount: data?.log.productCount,
            },
            planned: data.planned?.log === undefined ? undefined : {
                productCount: data.planned?.log.productCount,
            },
            deviation: data.deviation?.log === undefined ? undefined : {
                productCount: data.deviation?.log.productCount,
            },
        },
    };

    return result;
}

function mapFromDeviationResponse(data: PerProductDeviationStatisticsSchema) {
    const response: ProductStatsType = {
        products: (data.products ?? []).map(p => {
            return {
                id: p.id,
                name: p.name,
                actual: { ...p.actual, id: p.id, name: p.name, count: p.caseCount },
                deviation: { ...p.deviation, id: p.id, name: p.name },
                planned: { ...p.planned, id: p.id, name: p.name },
            };
        }),
        log: {
            actual: {
                productCount: data.log.productCount,
            },
        },
    };

    return response;
}
