import React, { useContext, useMemo, useRef } from "react";
import { ContainerModes, GraphLine, commonSelectionLineProps } from "../graph/GraphCommon";
import { Bar, Graph } from "../graph/Graph";
import Spinner from "../spinner/Spinner";
import { SessionContext, SessionType, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { analysisGraphMapping, AnalysisType, useGraph } from "../../hooks/UseGraph";
import { getShortActivityLabelFromActivityValues, groupKeyToActivityKeys, translateActivityKey } from "../../utils/GroupingUtils";
import { Graph as graphType, GroupingKeys, Node, NodeRoles } from "../../models/Dfg";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import { getKpiDefinition, getUnit } from "../../models/Kpi";
import { useGridResize } from "./UseGridResize";
import colors from "../../colors.json";
import { getCustomKpisDfg, getNodeKpiStatistics } from "../../utils/DfgUtils";
import DownloadFile, { TemplateType } from "../download-file/DownloadFile";
import { valueStreamGroupingKeys } from "../controls/GroupingKeyControls";
import { KpiTypes } from "../../models/KpiTypes";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { Stats } from "../../models/Stats";
import { ensureProperCountFormatting, getRelevantNodes } from "./NodeKpiChart";
import { useCustomerTakt } from "../../hooks/UseProductionTakt";
import { sortByVariance } from "../../views/process-path/ProductVariations";
import { getAnalysisTitle } from "../product-chart/ProductChart";
import { VarianceLegend } from "../graph/VarianceLegend";

export type NodeKpiChartProps = {
    analysisType: AnalysisType;
    noDataPlaceholder: string;
    pageSlug: string;
    title?: string;
    className?: string;
    disableShortcuts?: boolean;
}

type BarData = {
    node: Node | undefined,
    stats: Stats,
};

export function NodeVarianceChart(props: NodeKpiChartProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const horizonalLines: GraphLine[] = [];

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === props.analysisType)?.arguments;

    const graphOptions = {
        ...analysisArguments,
        ...getCustomKpisDfg(settings, session, false),
        calculateNodes: true,
        calculateEdges: true,
        calculateRoles: true,
        calculateActivityValues: true,
    };
    const graph = useGraph(graphOptions, props.analysisType);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    // For the cycle time chart we calculate the production takt as a referende value and we draw a red horizontal line.
    // We only want to show it for the PassValueStream grouping because it may be wrong for other groupings.
    const productionTakt = useCustomerTakt(settings.kpi.selectedKpi !== KpiTypes.CycleTime);
    if (productionTakt !== undefined && settings.kpi.showCustomerTakt && settings.groupingKey === GroupingKeys.PassValueStream)
        horizonalLines.push({ ...productionTakt, color: colors.$coral, width: 2 });

    const container = useRef<HTMLDivElement>(null);
    const [width, height] = useGridResize(container, 1, undefined, undefined, 0);

    const className = props.className ?? "fillParentMargin";

    const graphData: Bar<BarData>[] = useMemo(() => {
        if (graph === undefined)
            return [];

        const result: Bar<BarData>[] = [];
        const relevantNodes = graph.nodes !== undefined ? getRelevantNodes(graph.nodes, isObjectCentric, settings.graph.objectType) : [];
        for (const node of relevantNodes) {
            if (node === undefined || node.role === NodeRoles.Start || node.role === NodeRoles.End || node.role === NodeRoles.Link || node.role === NodeRoles.Inventory)
                continue;

            const stats = getNodeKpiStatistics(session, settings, node, settings.kpi.selectedKpi);

            if (stats?.median === undefined ||
                stats?.p75 === undefined ||
                stats?.p25 === undefined)
                continue;

            result.push({
                label: getShortActivityLabelFromActivityValues(node.activityValues, settings.groupingKey, props.analysisType === AnalysisType.ValueStream),
                data: {
                    node,
                    stats
                },
                value: stats.p75,
                baseValue: stats.p25,
                median: stats.median,
            });

        }

        // Sort!
        const sorted = sortByVariance(result, settings) as Bar<BarData>[];

        return sorted;
    }, [
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.graph.objectType,
        settings.quantity,
        graph?.hash,
    ]);
    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);
    const hasData = graphData !== undefined && graphData.length > 0;
    const isLoading = graph === undefined;
    const headlineKey = getAnalysisTitle(session, settings);
    const downloadAllowed = hasDownloadPermission(session);
    const noDataPlaceholder = (isObjectCentric && settings.kpi.selectedKpi !== KpiTypes.BusyTime) ? "kpi.noDataObjectCentric" : props.noDataPlaceholder;
    return <>
        <div className={className} ref={container}>
            <Spinner isLoading={isLoading} showProjectLoadingSpinner={true} />
            {graph !== undefined && !hasData && !isLoading && <div className="noKpisAvailable">
                {i18n.t("common.noKpisAvailable")}
                {!!noDataPlaceholder && <div>
                    {i18n.t(noDataPlaceholder)}
                </div>}
            </div>}

            {hasData && !isLoading && !!height && !!width && <>
                <Graph
                    horizonalLines={drawLine(horizonalLines, graph, session, settings)}
                    width={width}
                    height={height}
                    min={0}
                    title={i18n.t(headlineKey).toString()}
                    padding={{
                        top: 40,
                        left: 80,
                        bottom: 100,
                    }}
                    selectedIndex={graphData.findIndex(d => d.data?.node?.id === settings.selection.node?.id)}
                    onSelected={(e) => {
                        if (settings.selection.node?.id === e?.data?.node?.id)
                            settings.setSelection({});
                        else
                            settings.setSelection({
                                node: e?.data?.node
                            });
                    }}
                    yAxisUnit={unit}
                    showYAxisLines={true}
                    showYAxisTicks={true}
                    legend={<VarianceLegend />}
                    formatterParams={{
                        numDigits: 1,
                    }}
                    valueFormatter={(value) => {
                        return ensureProperCountFormatting(value, unit, session, settings.quantity);
                    }}
                    data={graphData ?? []}
                    containerMode={ContainerModes.Constrained}
                />
                <DownloadFile
                    data={graphData}
                    isValueStream={valueStreamGroupingKeys.includes(settings.groupingKey)}
                    template={TemplateType.NodeVariance}
                    meta={unit}
                    allowed={downloadAllowed}
                    title={i18n.t(headlineKey).toString()} />
            </>}
        </div>
        {props.disableShortcuts ? "" : <Shortcuts handledSelections={[ShortcutContexts.Node]} />}
    </>;
}

export function exportNodeVarianceData(data: Bar<BarData>[], settings: SettingsType, session: SessionType) {

    const result = [];

    const activityKeys = groupKeyToActivityKeys(settings.groupingKey, session.project!.eventKeys!);
    type Item = {
        [key: string]: string | number | undefined;
    }

    for (const bar of data) {
        let item: Item = {};

        for (const activityKey of activityKeys) {
            const temporaryItem = {
                [translateActivityKey(activityKey) ?? ""]: bar.data?.node?.activityValues?.[activityKey]?.value,
            };
            item = Object.assign(item, temporaryItem);
        }

        item[i18n.t("common.statistics.median")] = bar?.median,
        item[i18n.t("common.percentile25")] = bar?.baseValue,
        item[i18n.t("common.percentile75")] = bar?.value,
        item[i18n.t("common.statistics.shortMin")] = bar.data?.stats?.min,
        item[i18n.t("common.statistics.shortMax")] = bar.data?.stats?.max,

        result.push(item);
    }

    return result;
}

// To draw the green horizontal line we use the value of the selected element in the graph as a reference value. 
function drawLine(horizonalLines: GraphLine[], graph: graphType, session: SessionType, settings: SettingsType): GraphLine[] {

    const selectedNode = settings.selection.node ? graph?.nodes?.find(n => n.id === settings.selection.node?.id) : undefined;
    const value = selectedNode ? getNodeKpiStatistics(session, settings, selectedNode, settings.kpi.selectedKpi) : undefined;

    if (value?.median !== undefined)
        horizonalLines.push({ ...{ value: value?.median, ...commonSelectionLineProps } });

    return horizonalLines;
}
