import { debounce } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { GraphLayoutState } from "../../hooks/UseGraphLayout";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import { Portal } from "../portal/Portal";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Global from "../../Global";
import { classNames, getTouchCenter } from "../../utils/Utils";
import { Alignments, Placements, Spotlight } from "../spotlight/Spotlight";

export type DfGraphComplexitySliderProps = {
    graphLayout: GraphLayoutState,
    hidden?: boolean,
    disabled?: boolean,
};

export function DfGraphComplexity(props: DfGraphComplexitySliderProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const trackRef = useRef<HTMLDivElement>(null);
    const { trackEvent } = useMatomo();

    // Internal model of the currently selected reduction step. This is used to make the
    // whole component more reactive. Doing the entire roundtrip (update state - reduce graph -
    // layout - render) takes quite some time. A useEffect updates the internal model in case
    // props.score changes. Also, when undefined, the track head disappears all handlers
    // are disabled.
    const [internalStep, setInternalStep] = useState<number | undefined>(undefined);

    // States for dragging
    const [dragOrigin, setDragOrigin] = useState<number | undefined>(undefined);
    const [dragDelta, setDragDelta] = useState<number | undefined>(undefined);

    const complexitySliderSteps = props.graphLayout.complexitySteps ?? [];

    // the complexitySliderSteps change first, and internalStep immediately after. So, to prevent
    // the slider jumping around, I detect the changes here already in the rendering function.
    // This is quite hacky, but I don't know any better. If you do, please teach me.
    // As this leads to some flaky behaviors we do not want it happening in tests or stories.
    const complexityRef = useRef(complexitySliderSteps);
    const tempSliderDisable = complexityRef.current !== complexitySliderSteps && !(Global.isRunningJestTest || Global.isRunningStorybook);

    // When we're not dragging the handle, sync back whatever the global state tells us about the cutoff
    useEffect(() => {
        if (dragOrigin !== undefined)
            return;

        if (settings.graph.complexityCutoffScore !== undefined) {
            const newStep = getComplexityStepFromScore(settings.graph.complexityCutoffScore, complexitySliderSteps);
            setInternalStep(newStep);
        } else
            setInternalStep(undefined);

        // Part 2 of the above hack
        complexityRef.current = complexitySliderSteps;
    }, [
        settings.graph.complexityCutoffScore,
        complexitySliderSteps,
    ]);

    // Debounce settings state updates by 300ms
    const debouncedSetSettings = React.useRef(
        debounce((cutoffScore) => {
            settings.setGraph({
                complexityCutoffScore: cutoffScore,
            });

            if (cutoffScore !== undefined)
                trackEvent({
                    category: "Interactions",
                    action: "Complexity changed",
                    name: cutoffScore.toString(),
                });
        }, 250)
    ).current;

    // Clear pending debounces when destroying this component
    React.useEffect(() => {
        return () => {
            debouncedSetSettings.cancel();
        };
    }, []);

    if (props.hidden)
        return null;

    const isDisabled = props.disabled === true || complexitySliderSteps.length <= 1;

    // Track head parameters
    const showTrackHead = complexitySliderSteps.length > 1 && internalStep !== undefined && !tempSliderDisable;
    const yPosition = Math.max(0, Math.min((trackRef.current?.clientHeight ?? 0), idxToY(internalStep) + (dragDelta ?? 0)));
    const label = Formatter.formatPercent((props.graphLayout.complexityHistogram ?? [])[getDraggingIdx(dragDelta)], 100, 0, session.numberFormatLocale);

    return <div className={classNames(["dfGraphComplexitySlider", isDisabled && "disabled"])}>
        <div
            className="trackIcon tooltip tooltipLeft tooltipOneLiner"
            onClick={() => {
                if (showTrackHead && !isDisabled)
                    stepButtonClickHandler(internalStep - 1);
            }}>
            <svg className="svg-icon small"><use xlinkHref="#radix-layers" /></svg>
            {!isDisabled && <span className="tooltipText">{i18n.t("common.moreDetails")}</span>}
        </div>
        <div className="trackbar">
            <div className="track" ref={trackRef} onClick={trackClickHandler} />
            {showTrackHead && <div
                className={classNames(["head", dragOrigin !== undefined && "dragging"])}
                style={{
                    top: yPosition,
                }}
                onMouseDown={startDragging}
                onTouchStart={touchStartDragging}>
                {label}
            </div>}
        </div>
        <div
            className="trackIcon tooltip tooltipLeft tooltipOneLiner"
            onClick={() => {
                if (showTrackHead && !isDisabled)
                    stepButtonClickHandler(internalStep + 1);
            }}>
            <svg className="svg-icon small"><use xlinkHref="#ui-layer-single" /></svg>
            {!isDisabled && <span className="tooltipText">{i18n.t("common.lessDetails")}</span>}
        </div>
        <Spotlight id="Complexity-Slider" className="trackIcon mbs" position={{alignment: Alignments.Left, placement: Placements.Bottom}} />


        <Portal>
            <>{dragOrigin !== undefined && <div
                className="dragReceiverOverlay"
                onTouchMove={touchMoveDragging}
                onMouseUp={stopDragging}
                onMouseOut={stopDragging}
                onTouchEnd={touchStopDragging}
                onMouseMove={moveDragging} />}</>
        </Portal>
    </div>;

    // #region Drag and click handlers

    function touchStartDragging(e: React.TouchEvent<HTMLDivElement>) {
        if (isDisabled)
            return;

        const center = getTouchCenter(e.touches);
        if (!center)
            return;

        setDragOrigin(center.clientY);
        setDragDelta(0);
    }

    function startDragging(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (isDisabled)
            return;

        setDragOrigin(e.screenY);
        setDragDelta(0);
    }

    function getDraggingIdx(delta: number | undefined) {
        if (!trackRef.current)
            return 0;

        return clientYToIdx(idxToY(internalStep) + (delta ?? 0));
    }

    function touchStopDragging(e: React.TouchEvent<HTMLDivElement>) {
        const center = getTouchCenter(e.touches);
        if (!center)
            return;

        stopDraggingByDelta(center.clientY - (dragOrigin ?? 0));
    }

    function stopDragging(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        stopDraggingByDelta(e.screenY - (dragOrigin ?? 0));
    }

    function stopDraggingByDelta(delta: number) {
        const idx = getDraggingIdx(delta);
        setInternalStep(idx);
        setDragOrigin(undefined);
        setDragDelta(undefined);

        // we're treating the first cutoff point as 0
        const cutoffScore = idx === 0 ? 0 : complexitySliderSteps[idx];
        debouncedSetSettings(cutoffScore);
    }

    function touchMoveDragging(e: React.TouchEvent<HTMLDivElement>) {
        const center = getTouchCenter(e.touches);
        if (!center) {
            touchStopDragging(e);
            return;
        }

        moveDraggingByDelta(center.clientY - (dragOrigin ?? 0));
    }

    function moveDragging(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        // Sometimes, the mouseUp event gets lost, so we need to validate here
        // if we are still dragging
        if (e.buttons === 0) {
            stopDragging(e);
            return;
        }

        moveDraggingByDelta(e.screenY - (dragOrigin ?? 0));
    }

    function moveDraggingByDelta(delta: number) {
        const idx = getDraggingIdx(delta);
        setDragDelta(delta);

        const cutoffScore = idx === 0 ? 0 : complexitySliderSteps[idx];
        debouncedSetSettings(cutoffScore);
    }

    function stepButtonClickHandler(step: number) {
        const newStep = Math.min(Math.max(step, 0), complexitySliderSteps.length - 1);
        const cutoffScore = newStep === 0 ? 0 : complexitySliderSteps[newStep];

        setInternalStep(newStep);
        debouncedSetSettings(cutoffScore);
    }

    function trackClickHandler(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (!trackRef.current || internalStep === undefined || isDisabled)
            return;

        const newStep = clientYToIdx(e.pageY - trackRef.current.getBoundingClientRect().top);
        setInternalStep(newStep);

        const cutoffScore = complexitySliderSteps[newStep];
        debouncedSetSettings(cutoffScore);
    }

    function clientYToIdx(y: number) {
        const idxUnclipped = Math.round(y / (trackRef.current!.clientHeight / (complexitySliderSteps.length - 1)));
        return Math.max(0, Math.min(complexitySliderSteps.length - 1, idxUnclipped));
    }

    function idxToY(idx: number | undefined) {
        const i = idx ?? internalStep ?? 0;
        if (!complexitySliderSteps.length || !trackRef.current?.clientHeight)
            return 0;

        return (i * trackRef.current.clientHeight) / (complexitySliderSteps.length - 1);
    }

    // #endregion
}

function getComplexityStepFromScore(score: number | undefined, scoreSteps: number[]) {
    return Math.max(scoreSteps.findIndex(s => (score || 0) <= s), 0);
}