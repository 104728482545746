import React, { useRef, useEffect } from "react";
import { NotificationService } from "../components/notification/NotificationService";
import i18n from "../i18n";
import { SettingsContextType } from "../contexts/SettingsContext";
import copy from "copy-text-to-clipboard";
import Modal, { ModalProps } from "../components/modal/Modal";
import { SessionContextType } from "../contexts/SessionContext";
import { getViewId } from "../views/favorites/EditFavoritesModal";
import { Api } from "../api/Api";

/**
 * Show this modal if sharing failed!
 */
export function ShareModal(props: Omit<ModalProps, "children">) {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    // Select url
    useEffect(() => {
        if (textAreaRef.current)
            textAreaRef.current.select();
    }, [
        textAreaRef.current,
    ]);

    return <Modal
        title="common.shareView"
        isVisible={true}
        {...props}
    >
        <div>
            {i18n.t("common.shareLinkCopyFailedMessage")}
            <textarea
                style={{
                    width: "100%",
                    height: "120px",
                }}
                readOnly={true}
                className="input light"
                value={window.location.href}
                ref={textAreaRef} />
        </div>
    </Modal>;
}

export async function shareAsync(session: SessionContextType, settings: SettingsContextType) {
    let url: string = window.location.href;
    try {
        const viewConfiguration = await Api.createViewConfigurations({
            userId: session.user?.hash ?? "",
            name: "shared",
            isHidden: true,
            isSharedWithOrganization: true,
            projectId: session.projectId!,
            viewId: getViewId(window.location.href),
            settings,
        });
        url = `${window.location.origin}/goto/${viewConfiguration.id}`;
    } catch {
        // fall back to using the unshortened link
    }

    // 1. Use navigator.share if we are on a mobile device.
    // On desktop the user experience is not good enough, so we don't use it there.
    if (navigator.share && isAndroidOrIOS()) {
        await navigator.share({
            url,
            title: i18n.t("common.shareView")!,
        });
        return true;
    }

    // 2. Use navigator.clipboard if available
    let success = false;

    if (navigator?.clipboard?.writeText) {
        try {
            await navigator.clipboard.writeText(url);
            success = true;
        } catch {
            // ignore
        }
    }

    // 3. use copy-text-to-clipboard, which is a polyfill for navigator.clipboard
    if (!success)
        try {
            success = copy(url);
        } catch {
            // ignore
        }

    if (success) {
        NotificationService.add({
            className: "light success-icon success-accent",
            summary: "common.shareLinkCopied",
            id: "shareLinkCopied",
            icon: "radix-bell",
            message: "common.shareLinkCopiedMessage",
            autoCloseDelay: 3000,
        });

        // Workaround for showing the notification immediately
        settings.set({});
    }

    return success;
}

function isAndroidOrIOS(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /android/.test(userAgent) || /iphone|ipad|ipod/.test(userAgent);
}