import { useContext, useEffect, useState } from "react";
import { disableAllCalcOptions, TraceOptions } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { GroupingKeys } from "../models/Dfg";
import { EventFilter } from "../models/EventFilter";
import { Datastores } from "../utils/Datastores";
import { getIsGroupingValid } from "../utils/Initializers";
import { useMountedState } from "./UseMounted";
import { noop } from "lodash";
import { ignoreCancelledRequest } from "../api/Api";

export type ApiHookOptions<RESPONSE> = {
    disable?: boolean;
    onData?: (data: RESPONSE) => void;
    cancelPreviousRequests?: boolean;
};

export type StatsType = {
    minDate?: Date;
    maxDate?: Date;
    minCaseDuration?: number;
    maxCaseDuration?: number;
    numFilteredTraces?: number;
    numTotalTraces?: number;
    numFilteredEvents?: number;
    numTotalEvents?: number;
}

export function useStatistics(customFilters?: EventFilter[], options?: ApiHookOptions<StatsType>): [StatsType, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const isMounted = useMountedState();

    const [state, setState] = useState<StatsType>({});
    const [isLoading, setIsLoading] = useState(false);
    const filters = customFilters ?? settings.previewFilters ?? settings.filters;

    const [subscriptionIdAllStats] = useState<number>(() => { return Datastores.statistics.getSubscriptionId(); });
    const [subscriptionIdFilteredStats] = useState<number>(() => { return Datastores.statistics.getSubscriptionId(); });
    useEffect(() => {
        return () => {
            Datastores.statistics.cancelSubscription(subscriptionIdAllStats);
            Datastores.statistics.cancelSubscription(subscriptionIdFilteredStats);
        };
    }, []);

    useEffect(() => {
        if (!session.project?.uploadId ||
            !session.project?.eventKeys ||
            !getIsGroupingValid(session.project.eventKeys, settings.groupingKey) ||
            options?.disable)
            return;

        const request = {
            ...disableAllCalcOptions,
            eventFilters: [],
            eventKeys: {
                ...session.project.eventKeys,
                activityKeysGroup: settings.groupingKey ?? GroupingKeys.None,
            },
            uploadId: session.project.uploadId,
            uploads: session.project?.uploads,
        };

        if (!Datastores.statistics.isCached(request) && isMounted())
            setIsLoading(Datastores.statistics.getRequestCount(subscriptionIdFilteredStats) > 0);

        if (options?.cancelPreviousRequests)
            Datastores.statistics.cancelSubscription(subscriptionIdAllStats);

        Datastores.statistics.get(request, subscriptionIdAllStats).then((result) => {
            if (isMounted())
                setState((s) => {
                    return {
                        ...s,
                        numTotalEvents: result.nEvents,
                        numTotalTraces: result.nCases,
                    };
                });

            if (options?.onData)
                options.onData(result);
        }).catch(noop).finally(() => {
            if (isMounted())
                setIsLoading(Datastores.statistics.getRequestCount(subscriptionIdFilteredStats) > 0);
        });
    }, [
        session.project?.eventKeys,
        settings.apiRetry,
        settings.groupingKey
    ]);

    useEffect(() => {
        if (!session.project ||
            !session.project.eventKeys ||
            !session.project.uploadId)
            return;

        const request: TraceOptions = {
            ...disableAllCalcOptions,
            eventFilters: filters,
            eventKeys: {
                ...session.project.eventKeys,
                activityKeysGroup: settings.groupingKey ?? GroupingKeys.None,
            },
            uploads: session.project.uploads,
            uploadId: session.project?.uploadId
        };

        if (!Datastores.statistics.isCached(request)) {
            if (isMounted())
                setIsLoading(true);
        }

        Datastores.statistics.cancelSubscription(subscriptionIdFilteredStats);

        Datastores.statistics.get(request, subscriptionIdFilteredStats).then((result) => {
            if (isMounted()) {
                setState((s) => {
                    const data = {
                        ...s,
                        minDate: result.startTime,
                        maxDate: result.endTime,
                        minCaseDuration: result.minCaseDuration,
                        maxCaseDuration: result.maxCaseDuration,
                        numFilteredTraces: result.nCases,
                        numFilteredEvents: result.nEvents,
                    };

                    if (options?.onData)
                        options.onData(data);

                    return data;
                });
                setIsLoading(Datastores.statistics.getRequestCount(subscriptionIdFilteredStats) > 0);
            }
        }).catch(ignoreCancelledRequest).finally(() => {
            if (isMounted())
                setIsLoading(Datastores.statistics.getRequestCount(subscriptionIdFilteredStats) > 0);
        });
    }, [
        session.project,
        settings.groupingKey,
        JSON.stringify(filters),
        settings.apiRetry,
    ]);

    return [state, isLoading];
}