import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { Datastores } from "../../../../utils/Datastores";
import { buildCaseFilter } from "../../../../utils/FilterBuilder";
import { CardInfo } from "../../../dropzone/Card";
import { CollectionTypes, Dropzone, IDropzone, Orientations } from "../../../dropzone/Dropzone";
import Spinner from "../../../spinner/Spinner";
import { noop } from "lodash";

const maxResults = 100000;

type CaseIdFilterEditorPropsType = {
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};

export default function CaseIdFilterEditor(props: CaseIdFilterEditorPropsType) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const dropzoneRef = useRef<IDropzone>(null);
    const [subscriptionId, setSubscriptionId] = useState<number>(0);

    const [selectedIds, setSelectedIds] = useState<string[]>(() => {
        const exclude = !!props.initialValue?.caseAttributeInt?.ne || !!props.initialValue?.caseAttributeText?.ne;

        if (props.initialValue?.caseAttributeInt)
            return exclude ? (props.initialValue.caseAttributeInt.ne ?? []).map(id => id.toString()) :
                (props.initialValue.caseAttributeInt.eq ?? []).map(id => id.toString());

        if (props.initialValue?.caseAttributeText)
            return exclude ? props.initialValue.caseAttributeText.ne ?? [] : props.initialValue.caseAttributeText.eq ?? [];

        return [];
    });

    const [allCaseIds, setAllCaseIds] = useState<string[] | undefined>();
    const [excludeSelection, setExcludeSelection] = useState<boolean | undefined>(() => {
        return props.initialValue?.caseAttributeInt?.ne !== undefined || props.initialValue?.caseAttributeText?.ne !== undefined;
    });

    useEffect(() => {
        const id = Datastores.distinctAttributeValues.getSubscriptionId();
        setSubscriptionId(id);

        return () => {
            Datastores.distinctAttributeValues.cancelSubscription(id);
        };
    }, []);

    useEffect(() => {
        if (!session.project?.uploadId ||
            !session.project.eventKeys)
            return;

        const filters = settings.filterEditor.editFilterIndex === undefined ? settings.filters : settings.filters.filter((_, idx) => idx !== settings.filterEditor.editFilterIndex!);

        // This request does not have a limit. Once the API supports searching case IDs, we
        // can offload this to the backend. See issue #1004.
        Datastores.distinctAttributeValues.get({
            eventFilters: filters,
            eventKeys: session.project.eventKeys,
            uploadId: session.project.uploadId,
            uploads: session.project.uploads,
            limit: maxResults,
            attributes: [session.project.eventKeys.caseId],
        }, subscriptionId).then((result) => {
            if (!result?.length) {
                setAllCaseIds([]);
                return;
            }

            const ids = result[0].values.map(v => v.value.toString());
            setAllCaseIds(ids);

            // Wait until case IDs have been updated
            setTimeout(() => { dropzoneRef.current?.dataUpdated(); }, 0);
        }).catch(noop);
    }, [
        settings.filters,
        session.project,

    ]);

    return <>
        <Spinner isLoading={allCaseIds === undefined} className="filterSpinner" />

        <div className="caseIdFilterEditor">
            <div className="switch">
                <label className="alignCheckboxes">
                    <div>
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={excludeSelection}
                            id="checkbox-exclude"
                            data-testid="checkbox-exclude"
                            onChange={(e) => {
                                modeChanged(selectedIds, e.target.checked);
                            }} />
                        <label htmlFor="checkbox-exclude" />
                    </div>
                    <div>
                        {i18n.t("filters.excludeProducts")}
                    </div>
                </label>
            </div>

            {allCaseIds !== undefined && <Dropzone
                className="dropzoneContainer"
                ref={dropzoneRef}
                orientation={Orientations.Horizontal}
                searchTitle={i18n.t("filters.caseId.searchCases").toString()}
                dropzoneLabel={i18n.t("filters.caseId.selectedCases").toString()}
                searchPlaceholderLabel={i18n.t("filters.caseId.caseIdSelection")}
                onChange={(s) => {
                    const selection = s.map(s => s.id);
                    modeChanged(selection, !!excludeSelection);
                }}
                totalCount={allCaseIds.length < maxResults ? allCaseIds.length : undefined}
                initialValue={(selectedIds ?? []).map(id => caseIdToCardInfo(id))}
                collectionType={CollectionTypes.Set}
                onSearch={search}
                dropFilteredResults={true}
            />}
        </div>
    </>;

    function modeChanged(ids: string[], excludeSelection: boolean) {
        setExcludeSelection(excludeSelection);
        setSelectedIds(ids);

        const filter = buildCaseFilter(ids, excludeSelection, session);

        if (props.onUpdate)
            props.onUpdate(filter);
    }

    function search(parts: string[]) {
        const matches = (parts.length === 0 ? allCaseIds : (allCaseIds ?? []).filter(c => {
            const lc = c.toLowerCase();
            return parts.every(p => lc.indexOf(p) >= 0);
        }))?.sort((a, b) => {
            return a.localeCompare(b);
        });

        return {
            matches: (matches ?? []).map(m => caseIdToCardInfo(m)).filter((_, idx) => idx < maxResults),
            hasMoreData: (matches?.length ?? 0) > maxResults,
        };
    }

    function caseIdToCardInfo(id: string) {
        return {
            id,
            label: id
        } as CardInfo;
    }
}
