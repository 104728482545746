import { isString } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import i18n from "../../i18n";
import { classNames } from "../../utils/Utils";

export type NotificationActionProps = {
    label: string;
    onClick: () => boolean | undefined | void;
    isPrimary?: boolean;
}

export type NotificationProps = {
    /**
     * ID to uniquely identify this notidication instance. Usually assigned by NotificationService.add.
     */
    id?: string;

    /**
     * Style it using the following CSS classes:
     *  - light/dark for corresponding themes
     *  - [success|warning]-[accent|icon]
     */
    className?: string;

    /**
     * Headline
     */
    summary: string;

    /**
     * Message body
     */
    message: string | JSX.Element;

    /**
     * Actions (optional)
     */
    actions?: NotificationActionProps[];

    onClick?: () => void;

    /**
     * Invoked when the user clicks the "close" icon on the top right. If the callback returns `true`,
     * the cancel will be cancelled and thus, nothing will happen.
     */
    onCancel?: () => void;

    icon?: string;

    /**
     * If provided, the notification closes after this many milliseconds automatically
     */
    autoCloseDelay?: number;
};

/**
 * Notification popup. Will be rendered in a portal.
 * Style it using the following CSS classes:
 *  - light/dark for corresponding themes
 *  - [success|warning]-[accent|icon]
 */
export function Notification(props: NotificationProps) {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const timerRef = useRef<NodeJS.Timer | undefined>();

    useEffect(() => {
        if (timerRef.current)
            clearTimeout(timerRef.current);

        if (props.autoCloseDelay)
            timerRef.current = setTimeout(() => {
                hide();
            }, props.autoCloseDelay);

        return () => {
            if (timerRef.current)
                clearTimeout(timerRef.current);
        };
    }, [
        props.autoCloseDelay
    ]);

    useEffect(() => {
        setIsInitialized(true);
    }, []);

    return <div className={classNames(["notification", props.className, !isInitialized && "initial", !!props.autoCloseDelay && "autoclose"])}>
        <div className="inner" style={props.autoCloseDelay === undefined ? undefined : { borderBottom: "none" }}>
            <div className="icon">
                <svg className="svg-icon xsmall"><use xlinkHref={`#${props.icon ?? "bell"}`} /></svg>
            </div>
            <div className="content">
                <div className="summary">{i18n.t(props.summary)}</div>
                {isString(props.message) && <div className="message">{i18n.t(props.message)}</div>}
                {!isString(props.message) && <div className="message">{props.message}</div>}
                <div className="actions">
                    {(props.actions ?? []).map(a => <div
                        key={`action-${a.label}`}
                        className={a.isPrimary ? "primary" : "secondary"}
                        onClick={() => {
                            if (!a.onClick())
                                hide();
                        }}>{i18n.t(a.label)}</div>)}
                </div>
            </div>

            <div className="closer" onClick={() => {
                if (!isInitialized)
                    return;

                hide();
            }}>
                <svg className="svg-icon tiny brandHover"><use xlinkHref="#radix-cross-1" /></svg>
            </div>
            {props.autoCloseDelay !== undefined && <div className="autocloser" style={{
                transform: `scaleX(${isInitialized ? 1 : 0})`,
                transitionDuration: props.autoCloseDelay ? `${props.autoCloseDelay}ms` : "1s",
            }}></div>}
        </div>
    </div>;

    function hide() {
        setIsInitialized(false);
        setTimeout(() => {
            if (props.onCancel)
                props.onCancel();
        }, 250);
    }
}
