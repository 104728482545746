import { isArray } from "lodash";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { Formatter } from "../../../../utils/Formatter";

export type OperatorType = "eq" | "ne" | "lt" | "le" | "gt" | "ge";
export type attributeTypeId = "datetime" | "string" | "integer" | "float" | "boolean";

export type AttributeOperatorType = {
    name: OperatorType,
    label: string,
    isArray: boolean;
};

export const attributeOperators: AttributeOperatorType[] = [
    {
        name: "eq",
        label: "=",
        isArray: true,
    }, {
        name: "ne",
        label: "≠",
        isArray: true,
    }, {
        name: "le",
        label: "≤",
        isArray: false,
    }, {
        name: "lt",
        label: "<",
        isArray: false,
    }, {
        name: "ge",
        label: "≥",
        isArray: false,
    }, {
        name: "gt",
        label: ">",
        isArray: false,
    },
];

export const attributeTypes: {
    availableOperators: AttributeOperatorType[];
    formatter: (e: number | string | undefined) => (string | undefined);
    type: {
        id: (attributeTypeId | "")[],  // allow empty string a fallthrough (searching for it will result in no results but that' sometimes what we want)
        label: string,
    };
}[] = [{
    availableOperators: attributeOperators.filter(o => { return ["eq", "ne"].indexOf(o.name) >= 0; }),
    formatter: (e: number | string | undefined) => { return e ? e.toString() : undefined; },
    type: {
        id: ["string"],
        label: i18n.t("filters.attribute.string")
    },
}, {
    availableOperators: attributeOperators.filter(o => { return ["lt", "le", "gt", "ge"].indexOf(o.name) >= 0; }),
    formatter: (e: number | string | undefined) => { return Formatter.formatDateTime(e); },
    type: {
        id: ["datetime"],
        label: i18n.t("filters.attribute.datetime")
    }
}, {
    availableOperators: attributeOperators.filter(o => { return ["eq", "ne", "lt", "le", "gt", "ge"].indexOf(o.name) >= 0; }),
    formatter: (e: number | string | undefined) => { return e ? e.toString() : undefined; },
    type: {
        id: ["integer"],
        label: i18n.t("filters.attribute.integer")
    }
},
{
    availableOperators: attributeOperators.filter(o => { return ["lt", "le", "gt", "ge"].indexOf(o.name) >= 0; }),
    formatter: (e: number | string | undefined) => { return e ? e.toString() : undefined; },
    type: {
        id: ["float"],
        label: i18n.t("filters.attribute.float")
    }
}
];

function getAttributeFilterType(filter: EventFilter) {
    if (filter.caseAttributeDatetime)
        return attributeTypes.find(a => a.type.id.indexOf("datetime") >= 0);

    if (filter.caseAttributeInt)
        return attributeTypes.find(a => a.type.id.indexOf("integer") >= 0);

    if (filter.caseAttributeFloat)
        return attributeTypes.find(a => a.type.id.indexOf("float") >= 0);

    if (filter.caseAttributeText)
        return attributeTypes.find(a => a.type.id.indexOf("string") >= 0);
}

/**
 * Returns the filter value. Could be anything really, string, number, undefined...
 * @param filter EventFilter
 * @returns Filter value
 */
export function getAttributeFilterValue(filter: EventFilter) {
    const e = filter?.caseAttributeDatetime ?? filter.caseAttributeInt ?? filter.caseAttributeFloat ?? filter.caseAttributeText;
    return e?.lt ?? e?.le ?? e?.gt ?? e?.ge ?? (e?.eq?.length ? e.eq : (e?.ne?.length ? e.ne : undefined));
}


/**
 * Returns the operator used in a given EventFilter instance
 * @param filter EventFilter
 * @returns Operator used in that filter instance
 */
export function getAttributeFilterOperator(filter: EventFilter) {
    const e = filter?.caseAttributeDatetime ?? filter.caseAttributeInt ?? filter.caseAttributeFloat ?? filter.caseAttributeText;
    if (!e)
        return undefined;

    const keys = Object.keys(e).filter(k => k !== "name" && e[k] !== undefined);
    if (!keys.length)
        return undefined;

    const key = keys[0];

    return attributeOperators.find(a => a.name === key);
}

/**
 * Convenience method that prepares a whole bunch of metadata for your pleasure
 * @param filter EventFilter instance
 * @returns metadata
 */
export function getAttributeFilterMetadata(filter: EventFilter) {
    const type = getAttributeFilterType(filter);
    const value = getAttributeFilterValue(filter);
    const operator = getAttributeFilterOperator(filter);

    const e = filter?.caseAttributeDatetime ?? filter.caseAttributeInt ?? filter.caseAttributeFloat ?? filter.caseAttributeText;

    let valueFormatted: string | string[] | undefined = undefined;
    if (type?.formatter && value !== undefined) {
        if (!isArray(value))
            valueFormatted = type.formatter(value);
        else
        if (value.length === 1)
            valueFormatted = type.formatter(value[0]);
    }

    return {
        type,
        value,
        valueFormatted,
        operator,
        attributeName: e?.name,
    };
}
