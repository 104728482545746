import { useContext, useEffect, useState } from "react";
import { StatsCalculationRequest, disableAllCalcOptions } from "../models/ApiTypes";
import { SessionContext, hasNetTransitionTimes } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { DeviationGraphResponse, GroupingKeys } from "../models/Dfg";
import { addAverageStockToGraphNodes } from "../utils/AverageStock";
import { Datastores } from "../utils/Datastores";
import { DfgUtils, updateHash } from "../utils/DfgUtils";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { getIsGroupingValid } from "../utils/Initializers";
import { AnalysisType } from "./UseGraph";
import { useMountedState } from "./UseMounted";
import { ignoreCancelledRequest } from "../api/Api";

/**
 * Hook for accessing deviation graph statistics
 * @param disable if true, the hook will not do anything. It's used in scenarios where the data is
 * needed conditionally, and the hook count should be kept constant.
 */
export function useDeviationGraphs(analysisArguments: Partial<StatsCalculationRequest> & { groupingKey?: GroupingKeys }, analysis: AnalysisType = AnalysisType.Times, disable = false): [DeviationGraphResponse | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [subscriptionId] = useState<number>(() => { return Datastores.getDeviationGraphs.getSubscriptionId(); });
    useEffect(() => { return () => { Datastores.getDeviationGraphs.cancelSubscription(subscriptionId); }; }, []);

    const [state, setState] = useState<DeviationGraphResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isMounted = useMountedState();

    const currentFilters = settings.previewFilters ?? settings.filters;

    useEffect(() => {
        if (disable)
            return;

        if (!session.project?.eventKeys ||
            !session.project?.uploadId ||
            !session.project?.uploadIdPlan ||
            !session.project?.eventKeysPlan ||
            !getIsGroupingValid(session.project.eventKeys, settings.groupingKey)) {
            setState({
                actual: undefined,
                planned: undefined,
            });
            return;
        }

        const requestOptions = {
            ...disableAllCalcOptions,
            actual: {
                uploadId: session.project!.uploadId!,
                uploads: session.project?.uploads,
                eventKeys: {
                    ...session.project?.eventKeys,
                    activityKeysGroup: analysisArguments.groupingKey ?? settings.groupingKey,
                },
                eventFilters: currentFilters,
            },
            planned: {
                uploadId: session.project!.uploadIdPlan!,
                eventKeys: {
                    ...session.project?.eventKeysPlan,
                    activityKeysGroup: analysisArguments.groupingKey ?? settings.groupingKey,
                },
            },
            consolidatePasses: groupSupportsConsolidatePasses(settings.groupingKey),
            filterCaseDurationDeviations: undefined,
            ...analysisArguments,
        };

        // The API returns an error if this is set to true but there's no shift plan
        requestOptions.calculateNetEdgeTimes = (requestOptions?.calculateNetEdgeTimes ?? false) && !!hasNetTransitionTimes(session);

        setState({
            actual: undefined,
            planned: undefined,
            deviation: undefined,
        });

        if (!Datastores.getDeviationGraphs.isCached(requestOptions)) {
            // Set isLoading to true if we have a cache miss
            setIsLoading(true);
        }

        // Cancel already issued requests
        Datastores.getDeviationGraphs.cancelSubscription(subscriptionId);

        Datastores.getDeviationGraphs.get(requestOptions, subscriptionId).then((response) => {
            if (!isMounted())
                return;

            let actual = response.actual !== undefined ? DfgUtils.preprocessGraph(response.actual) : undefined;
            let planned = response.planned !== undefined ? DfgUtils.preprocessGraph(response.planned) : undefined;
            const deviation = response.deviation !== undefined ? DfgUtils.preprocessGraph(response.deviation) : undefined;

            if (analysis === AnalysisType.Output || 
                analysis === AnalysisType.Stock) {
                if (actual !== undefined)
                    actual = addAverageStockToGraphNodes(actual);
                if (planned !== undefined)
                    planned = addAverageStockToGraphNodes(planned);
            }

            updateHash(actual);
            updateHash(planned);
            updateHash(deviation);

            setState({
                ...response,
                deviation,
                actual,
                planned,
            });
        }).catch(ignoreCancelledRequest).finally(() => {
            if (isMounted())
                setIsLoading(false);
        });
    }, [
        disable,
        currentFilters,
        session.project,
        settings.groupingKey,
        settings.kpi.analyzedValue,
        settings.quantity,
        JSON.stringify(analysisArguments)
    ]);

    return [state, isLoading];
}
