export class Matrix {
    constructor(public m11: number, public m12: number, public m21: number, public m22: number, public tx: number, public ty: number) { }

    clone() {
        return new Matrix(this.m11, this.m12, this.m21, this.m22, this.tx, this.ty);
    }

    multiply(b: Matrix) {
        return Matrix.multiply(this, b);
    }

    getTranslationMatrix() {
        return new Matrix(1, 0, 0, 1, this.tx, this.ty);
    }

    getTransformMatrix() {
        return new Matrix(this.m11, this.m12, this.m21, this.m22, 0, 0);
    }

    static multiply(a: Matrix, b: Matrix) {
        return new Matrix(
            a.m11 * b.m11 + a.m12 * b.m21, a.m11 * b.m12 + a.m12 * b.m22,
            a.m21 * b.m11 + a.m22 * b.m21, a.m21 * b.m12 + a.m22 * b.m22,
            a.m11 * b.tx + a.m12 * b.ty + a.tx,
            a.m21 * b.tx + a.m22 * b.ty + a.ty
        );
    }

    static toArray(matrix: Matrix) {
        return [[matrix.m11, matrix.m12, matrix.tx],
            [matrix.m21, matrix.m22, matrix.ty],
            [0, 0, 1]];
    }

    static invert(matrix: Matrix) {
        const bData = Matrix.toArray(matrix);
        const data = Matrix.toArray(Matrix.createIdentityMatrix());

        for (let i = 0; i < 3; i++) {
            // Find Pivot element
            let mag = 0;
            let pivot: number | undefined = undefined;

            for (let j = i; j < 3; j++) {
                const mag2 = Math.abs(bData[j][i]);
                if (mag2 > mag) {
                    mag = mag2;
                    pivot = j;
                }
            }

            if (pivot === undefined)
                return;

            // Move pivot row into place
            if (pivot !== i) {
                let temp: number;

                for (let j = i; j < 3; j++) {
                    temp = bData[i][j];
                    bData[i][j] = bData[pivot][j];
                    bData[pivot][j] = temp;
                }

                for (let j = 0; j < 3; j++) {
                    temp = data[i][j];
                    data[i][j] = data[pivot][j];
                    data[pivot][j] = temp;
                }
            }

            // Normalize pivot
            mag = bData[i][i];
            for (let j = i; j < 3; j++)
                bData[i][j] /= mag;

            for (let j = 0; j < 3; j++)
                data[i][j] /= mag;

            // Eliminate pivot element from other rows
            for (let k = 0; k < 3; k++) {
                if (k === i)
                    continue;

                const mag2 = bData[k][i];
                for (let j = i; j < 3; j++)
                    bData[k][j] = bData[k][j] - mag2 * bData[i][j];

                for (let j = 0; j < 3; j++)
                    data[k][j] = data[k][j] - mag2 * data[i][j];
            }
        }

        return new Matrix(data[0][0], data[0][1], data[1][0], data[1][1], data[0][2], data[1][2]);
    }

    toString() {
        return "matrix(" + this.m11 + ", " + this.m12 + ", " + this.m21 + ", " + this.m22 + ", " + this.tx + ", " + this.ty + ")";
    }

    static toString(matrix: Matrix) {
        return "matrix(" + matrix.m11 + ", " + matrix.m12 + ", " + matrix.m21 + ", " + matrix.m22 + ", " + matrix.tx + ", " + matrix.ty + ")";
    }

    /**
     * Checks if the given matrix repesents a cartesian coordinate
     * system. Meaning, the axes are perpendicular and the scaling
     * of the x- and y axis is identical.
     * @returns true if matrix is cartesian, otherwise false.
     */
    isCartesian() {
        return this.m12 === 0 && this.m21 === 0 && (this.m11 - this.m22) < 0.000001;
    }

    /**
     * Deserializes a matrix that has previously been serialized with
     * toString().
     * @param matrixString Serialized matrix
     * @returns Matrix instance
     */
    static fromString(matrixString: string) {
        const transformMatrixStr = matrixString.substr(7, matrixString.length - 8);
        const m = transformMatrixStr.split(",").map(e => +e);
        const matrix = new Matrix(m[0], m[1], m[2], m[3], m[4], m[5]);
        return matrix;
    }

    static fromStyle(style: string) {
        const regex = /matrix\(([^)]+)\)/;
        const match = regex.exec(style);
        if (!match) 
            return undefined;

        return Matrix.fromString(match[0]);
    }

    static createIdentityMatrix() {
        return Matrix.createTranslationMatrix(0, 0);
    }

    static createTranslationMatrix(tx: number, ty: number) {
        return new Matrix(1, 0, 0, 1, tx, ty);
    }

    static createRotationMatrix(deg: number) {
        const rad = deg * Math.PI / 180.0;

        return new Matrix(
            Math.cos(rad), -Math.sin(rad),
            Math.sin(rad), Math.cos(rad),
            0, 0
        );
    }

    static createScaleMatrix(factor: number) {
        return new Matrix(factor, 0, 0, factor, 0, 0);
    }

    static isIdentity(matrix: Matrix | undefined, margin = 0.0000001) {
        if (matrix === undefined)
            return false;

        return matrix.equals(Matrix.createIdentityMatrix(), margin);
    }

    equals(matrix: Matrix, margin = 0.0000001) {
        const diff = Math.abs(matrix.m11 - this.m11) +
            Math.abs(matrix.m12 - this.m12) +
            Math.abs(matrix.m21 - this.m21) +
            Math.abs(matrix.m22 - this.m22) +
            Math.abs(matrix.tx - this.tx) +
            Math.abs(matrix.ty - this.ty);

        return diff <= margin;
    }
}
