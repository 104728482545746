import React from "react";
import i18n from "../../../i18n";
import { AbcProductStateType, EventFilter, abcFilterRenderType } from "../../../models/EventFilter";

type ProductFilterStatusPropsType = {
    filter: {filters?: EventFilter[]},
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

const maxFilterLength = 50;

export default function ProductFilterStatus(props: ProductFilterStatusPropsType) {
    const isExcluded = props.filter?.filters && (props.filter.filters[0].caseAttributeText?.ne || props.filter.filters[0].caseAttributeInt?.ne);
    
    const areUpstreamProductsIncluded = props.filter.filters?.some(f => 
        !!f.caseAttributeText?.billOfMaterials?.upstreamLevels ||
        !!f.caseAttributeInt?.billOfMaterials?.upstreamLevels ||
        !!f.caseAttributeFloat?.billOfMaterials?.upstreamLevels);

    const translationKey = `filters.productFilterState${areUpstreamProductsIncluded ? "Upstream" : ""}${isExcluded ? "Exclude" : ""}`;

    const state = i18n.t(translationKey, {
        count: props.filter.filters?.length 
    });

    const filterValues: string[] = [];
    const abcState = (props.filter?.filters?.find(f => f.renderType === abcFilterRenderType) as any | undefined)?.abcState;
    const categoryAttributes = (props.filter.filters ?? []).filter(f => (f.caseAttributeInt ?? f.caseAttributeText) !== undefined);
    if (!!abcState && categoryAttributes.length === 0) {
        const kpiLookup: {[id: string]: string} = {
            count: "common.caseCount",
            caseYield: "common.yield",
            caseOutput: "common.outputWithoutUnit",
            caseScrap: "common.scrap"
        };

        const selection = abcState.selection as AbcProductStateType;
        const str = ["A", "B", "C"].filter((_, idx) => selection[idx]).join("");
        if (str.length > 0) {
            const kpi = Object.keys(kpiLookup).find(k => (abcState.statistic as string).startsWith(k));
            filterValues.push(`${str}-${i18n.t("common.products")} (${i18n.t(kpiLookup[kpi ?? ""] ?? "")})`);
        }
    }

    if (categoryAttributes.length > 1 || (categoryAttributes.length === 1 && !!abcState))
        filterValues.push(i18n.t("common.numCategories", { count: categoryAttributes.length + (abcState ? 1 : 0)}));   
    else if (categoryAttributes.length === 1) {
        const v = categoryAttributes[0];
        const attributes = v.caseAttributeInt ? v.caseAttributeInt.eq ?? v.caseAttributeInt.ne : v.caseAttributeText?.eq ?? v.caseAttributeText?.ne;
        
        if (attributes)
            filterValues.push(attributes.join("|").toString());
    }

    const filterValueStr = filterValues.join(", ");
    
    return <div className={"filter productFilter" + (props.isEdited ? " edit" : "")} onClick={onClick}>
        <svg className="svg-icon xsmall"><use xlinkHref="#radix-mix" /></svg>
        <div>
            {state} <br />
            <b>{(filterValueStr.length > 0 && filterValueStr.length > maxFilterLength) ? filterValueStr.substring(0, maxFilterLength) + "..." : filterValueStr}</b>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;

    function onClick() {
        if (props.onClick)
            props.onClick();
    }
}