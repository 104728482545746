import { Auth0Provider } from "@auth0/auth0-react";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { SessionContextProvider } from "./contexts/SessionContext";
import { SettingsContextProvider } from "./contexts/SettingsContext";
import Global from "./Global";
import "./index.scss";
import { CustomMatomoProvider } from "./utils/Matomo";
import { initSentry } from "./utils/Sentry";

// Start the mocking conditionally.
if (process.env.NODE_ENV === "development" && !process.env.REACT_APP_API_ENDPOINT) {
    // We need to require this - by no means import it! During the production build
    // the environment variables are inlined, and then the above if-statement will
    // be translated to if (false) { ... }, so this code block gets removed.
    // This results in all test code being removed from the build.
    // If you imported the browser mocks, they would always be around, no matter what.
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("./mocks/ApiMocks");
}

if (!["development", "test"].some(e => e === process.env.NODE_ENV)) {
    // Only initialize sentry when running in a production-like environment
    // We don't want to be spammed by all of the errors happening in development
    // and unit testing.
    initSentry();
}

const container = document.getElementById("root");
const root = createRoot(container!);

const redirectUrl = window.location.origin + "/welcome?to=" + encodeURIComponent(window.location.href);
root.render(<React.StrictMode>
    <Auth0Provider
        domain={Global.auth0.domain}
        clientId={Global.auth0.clientId}
        audience={Global.auth0.audience}
        redirectUri={redirectUrl}
        cacheLocation="localstorage"
    >
        <Suspense fallback={<div>Loading...</div>}>
            <SessionContextProvider>
                <SettingsContextProvider>
                    <BrowserRouter>
                        <CustomMatomoProvider>
                            <App />
                        </CustomMatomoProvider>
                    </BrowserRouter>
                </SettingsContextProvider>
            </SessionContextProvider>
        </Suspense>
    </Auth0Provider>
</React.StrictMode>);