/**
 * This component displays the current filter configuration
 */

import React, { useContext } from "react";
import colors from "../../colors.json";
import { SettingsContext } from "../../contexts/SettingsContext";
import { EventFilter, activityFilterRenderType, caseIdFilterRenderType, caseYieldFilterRenderType, productFilterRenderType, timeFilterRenderType } from "../../models/EventFilter";
import { Gauge } from "../gauge/Gauge";
import ActivityFilterStatus from "./status/ActivityFilterStatus";
import AttributeFilterStatus from "./status/AttributeFilterStatus";
import CaseIdFilterStatus from "./status/CaseIdFilterStatus";
import DurationFilterStatus from "./status/DurationFilterStatus";
import ProductFilterStatus from "./status/ProductFilterStatus";
import SequenceFilterStatus from "./status/SequenceFilterStatus";
import TimeFilterStatus from "./status/TimeFilterStatus";
import VariantFilterStatus from "./status/VariantFilterStatus";
import CaseYieldFilterStatus from "./status/CaseYieldFilterStatus";

export type FiltersPropsType = {
    numPassingCases: number;
    numTotalCases: number;
    filters?: EventFilter[];
    onFilterClicked?: (filter: EventFilter, idx: number) => void;
    onFilterRemoved?: (filter: EventFilter, idx: number) => void;
    onAddButtonClicked?: () => void;
};

export default function FilterStatus(props: FiltersPropsType) {
    const settings = useContext(SettingsContext);
    const filters = (props.filters ?? []).map((f, idx) => {
        const key = JSON.stringify(f, undefined, 0);

        const args = {
            key,
            filter: f,
            onRemove: () => { onRemoveHandler(f, idx); },
            onClick: () => { onClickHandler(f, idx); },
            isEdited: settings.filterEditor.editFilterIndex === idx,
        };

        if (f.caseDuration)
            return <DurationFilterStatus {...args} />;

        if (f.filters?.length && f.renderType === activityFilterRenderType)
            return <ActivityFilterStatus {...args}/>;

        if (f.caseTime || f.renderType === timeFilterRenderType)
            return <TimeFilterStatus {...args}/>;

        if (f.caseSequence)
            return <SequenceFilterStatus {...args}/>;

        if (f.variant)
            return <VariantFilterStatus {...args}/>;

        if (!f.renderType && (f.caseAttributeText || f.caseAttributeInt || f.caseAttributeFloat || f.caseAttributeDatetime))
            return <AttributeFilterStatus {...args}/>;

        if (f.filters?.length && f.renderType === productFilterRenderType)
            return <ProductFilterStatus {...args}/>;

        if (f.renderType === caseIdFilterRenderType)
            return <CaseIdFilterStatus {...args}/>;

        if (f.renderType === caseYieldFilterRenderType)
            return <CaseYieldFilterStatus {...args}/>;

        return <div key={"unknown-"+ idx}>unknown filter type, please implement: {JSON.stringify(f)}</div>;
    });

    return (
        <div className="filters">
            <div className="gauge">
                <Gauge
                    className="fillParent"
                    padding={0}
                    valueFormatter={() => ""}
                    idleThickness={9}
                    valueThickness={9}
                    deadzoneWidth={0}
                    idleColor={ colors["$gray-3"] }
                    min={0}
                    max={props.numTotalCases}
                    value={props.numPassingCases}
                />
            </div>
            {!!filters.length && <div className="list" data-testid="status-list">
                {filters}
            </div>}

            <button
                className="inlineButton brandHover"
                onClick={() => { if (props.onAddButtonClicked) props.onAddButtonClicked(); }}
                data-testid="addFilterButton">
                <svg className="svg-icon xsmall"><use xlinkHref="#radix-plus" /></svg>
            </button>
        </div>
    );

    function onClickHandler(e: EventFilter, idx: number) {
        if (props.onFilterClicked)
            props.onFilterClicked(e, idx);
    }

    function onRemoveHandler(e: EventFilter, idx: number) {
        if (props.onFilterRemoved)
            props.onFilterRemoved(e, idx);
    }
}
