import { useContext } from "react";
import { LossReasonsParams } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { SettingsContext } from "../contexts/SettingsContext";
import { SessionContext } from "../contexts/SessionContext";
import { LossReasonsSchema } from "../models/generated";

export function useLossReasons(
    request: Partial<LossReasonsParams> = {},
    options?: ApiHookOptions<LossReasonsSchema>
): [LossReasonsSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const eventKeys = request.eventKeys ?? session.project?.eventKeys;
    
    const requestOptions: LossReasonsParams = {
        eventFilters:
      request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: eventKeys ?? ({} as EventKeys),
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: request.uploads ?? session.project?.uploads,
        machines: request.machines ?? undefined,

        // Below this line still needs configuring
        losses: ["failure"],
        limit: 0,
        offset: 0,
        sort: [
            "endTime",
            "timeStatistics.mean",
            "-interruptionFrequencyStatistics.sum",
        ],
    };

    const setDisabled =
    options?.disable || !eventKeys || !requestOptions.uploadId;

    return useApi(
        Datastores.getLossReasons,
        requestOptions,
        [JSON.stringify(requestOptions)],
        { ...options, disable: setDisabled }
    );
}
