import React, { useContext, useEffect, useState } from "react";
import { Column, Row } from "react-table";
import { Eventlog } from "../../models/ApiTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useMountedState } from "../../hooks/UseMounted";
import i18n from "../../i18n";
import { Formatter, getColumnLabel } from "../../utils/Formatter";
import CustomTable, { PaginationModes } from "../custom-table/CustomTable";
import Spinner from "../spinner/Spinner";
import { Api, ignoreCancelledRequest } from "../../api/Api";



export default function EventlogTable() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [table, setTable] = useState<Eventlog>();
    const isMounted = useMountedState();
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    useEffect(() => {
        if (session.project?.uploadId && session.project?.eventKeys) {
            setIsLoading(true);
            Api.getEventlog({ uploadId: session.project.uploadId, eventKeys: session.project.eventKeys, eventFilters: settings.previewFilters ?? settings.filters ?? [], limit: 1 }).then((result) => {
                if (isMounted())
                    setTable(result);
            }).catch(ignoreCancelledRequest).finally(() => {
                if (isMounted())
                    setIsLoading(false);
            });
        }
    }, [session.project, settings.filters, settings.previewFilters]);

    const data: any[] = React.useMemo(() => {
        if (!table?.cases.length)
            return [];

        return table.cases.map((c) => {
            return c.events.map((e) => {
                return { ...e.attributes };
            });
        }).flat();
    }, [table]);

    // Get a list of all columns
    let allColumns: string[] | undefined;
    if (data.length) {
        const columns: { [id: string]: boolean } = {};
        for (const row of data)
            for (const e of Object.keys(row))
                columns[e] = true;

        allColumns = Object.keys(columns);
    }
    // @ts-expect-error: react-table typing only allows strings for the accessor although also functions can be provided
    const tableColumns: Column<any>[] = (allColumns ?? []).map((c) => {
        const attribute = session.eventUpload!.meta.attributes.find(a => a.name == c);
        if (attribute === undefined)
            return undefined;
        const label = getColumnLabel(c, session.project!.eventKeys!);
        const headerJSX = label
            ? <div><div className="labelled">{label}</div><div>{c}</div></div>
            : <div><div className="labelled">{c}</div><div>{c}</div></div>;
        return {
            id: c,
            Header: headerJSX,
            accessor: accessorMap(attribute!.type, c),
            sortType: attribute?.type === "datetime" ? (row1: Row<any>, row2: Row<any>, columnId: string) => {
                const val1 = (row1.original[columnId] ?? "").toString() as string;
                const val2 = (row2.original[columnId] ?? "").toString() as string;
                return val1.localeCompare(val2);
            } : (attribute!.type === "integer" || attribute!.type === "float") ?
                (row1: Row<any>, row2: Row<any>, columnId: string) => {
                    const val1 = (row1.original[columnId] ?? 0) as number;
                    const val2 = (row2.original[columnId] ?? 0) as number;
                    return val1 - val2;
                } : (row1: Row<any>, row2: Row<any>, columnId: string) => {
                    const val1 = (row1.original[columnId] ?? "").toString() as string;
                    const val2 = (row2.original[columnId] ?? "").toString() as string;
                    return val1.localeCompare(val2);
                },
        };
    }).filter((c) => c !== undefined);

    return <div className="eventlogExplorer">
        <Spinner isLoading={isLoading} />
        <CustomTable
            data={data}
            columns={tableColumns}
            paginationMode={PaginationModes.FillContainer}
            hasSortBy={true}
        />
    </div>;

    function accessorMap(attributeType: string, label: string): string | ((d: { [key: string]: string }) => string) {
        switch (attributeType) {
            case "boolean":
                return ((d) => (d[label] !== undefined && d[label] !== null) ? i18n.t(`common.${d[label].toString()}`) : "");
            case "datetime":
                return (d) => Formatter.formatDateTime(d[label], undefined, session.locale, session.timezone);
            default:
                // we are using `(d) => d[label]` instead of just `label` here
                // to also display column labels with dots in them (see https://github.com/tannerlinsley/react-table/issues/3004)
                return (d) => d[label];
        }
    }
}
