import React, { useContext } from "react";
import { StatsRowValue } from "../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useSupplyChainGraphs } from "../../hooks/UseSupplyChainGraphs";
import { getCustomKpiParameters } from "../../models/Kpi";
import { KpiPresets, KpiTypes } from "../../models/KpiTypes";
import { Stats } from "../../models/Stats";
import { quantities } from "../../utils/Quantities";
import { capitalizeFirst } from "../../utils/Utils";

export function SupplyChainEdgeStatistics() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);


    const kpiTypes = [...KpiPresets.supplyChainViewKpis, KpiTypes.GoodQuantity];

    const graphOptions = getCustomKpiParameters(kpiTypes, settings, session);

    // Same request as in BomGraph, hope it's cached
    const [graph, isGraphLoading] = useSupplyChainGraphs(graphOptions, {
        disable: !settings.supplyChain.selectedProduct,
    });

    const edge = graph?.edges?.find(e => e.from === settings.selection.edge?.from && e.to === settings.selection.edge?.to);
    const propName = `component${capitalizeFirst(settings.quantity)}Statistics`;
    const quantity = quantities.find(q => q.id === settings.quantity);

    const title = `${getNodeLabelById(settings.selection.edge?.from)} ➞ ${getNodeLabelById(settings.selection.edge?.to)}`;

    const statistic = (edge as unknown as any)?.[propName] as Stats | undefined;
    const hasVariance = statistic?.min !== statistic?.max;
    return <div>
        {!!statistic?.sum && <StatsSection title={title} isTitleLoading={isGraphLoading}>
            <StatsSubSection title="supplyChain.edgeStatsTitle" disableTable={true} spotlightId={"SupplyChain-Edge-Statistics"}>
                <div className="table">
                    <StatsRowValue label="common.statistics.mean" isLoading={isGraphLoading} unit={quantity?.unit} value={statistic?.mean} isHighlight={true} />
                    {hasVariance && <StatsRowValue label="common.statistics.median" isLoading={isGraphLoading} unit={quantity?.unit} value={statistic?.median} />}
                    {hasVariance && <StatsRowValue label="common.statistics.min" isLoading={isGraphLoading} unit={quantity?.unit} value={statistic?.min} />}
                    {hasVariance && <StatsRowValue label="common.statistics.max" isLoading={isGraphLoading} unit={quantity?.unit} value={statistic?.max} />}
                    <StatsRowValue label="common.statistics.sum" isLoading={isGraphLoading} unit={quantity?.unit} value={statistic?.sum} />
                </div>
            </StatsSubSection>
        </StatsSection>}


    </div>;

    function getNodeLabelById(id: string | undefined) {
        return graph?.nodes?.find(n => n.id === id)?.name ?? "";
    }   }