import { useContext } from "react";
import { SessionContext, SessionType } from "../contexts/SessionContext";
import { ProductIdentifier, SettingsContext, SettingsType } from "../contexts/SettingsContext";
import { PerProductStatisticsParams, disableAllCalcOptions } from "../models/ApiTypes";
import { Datastores } from "../utils/Datastores";
import { buildProductFilter } from "../utils/FilterBuilder";
import { useApi } from "./UseApi";
import { PerProductCaseStatisticsSchema } from "../models/generated";

/**
 * @param disabled If you don't need data from this hook, but have to include it
 * to keep the hook count constant, you can prevent it from hitting the API using
 * this switch
 */
export function useProductCaseAggregations(options?: Partial<PerProductStatisticsParams>, disabled?: boolean):
    [PerProductCaseStatisticsSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const { eventFilters, ...nonFilterOptions } = options ?? {};
    const filters = eventFilters ?? settings.previewFilters ?? settings.filters;

    const eventKeys = (options?.eventKeys ?? session.project?.eventKeys)!;

    const requestOptions: PerProductStatisticsParams = {
        ...disableAllCalcOptions,
        eventFilters: filters,
        eventKeys,
        uploadId: session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        calculateTimeAndFreqStats: true,
        ...nonFilterOptions,
    };

    return useApi(
        Datastores.getProductCaseAggregationStatistics,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: disabled || session.project?.uploadId === undefined,
        });
}


/**
 * If you need a product hook just to retrieve a single product entity, include these parameters to
 * narrow the request down to it. 
 * WARNING: You still need to disable the hook manually in case the product identifier is undefined!
 */
export function filterProduct(product: ProductIdentifier | undefined, session: SessionType, settings: SettingsType) {
    const productFilter = settings.selection.product ? buildProductFilter([settings.selection.product], false, session) : undefined;
    return {
        eventFilters: (settings.previewFilters ?? settings.filters ?? []).concat(productFilter ? [productFilter] : []),
        limit: 1,
    };
}