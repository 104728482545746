import { SimpleEventDispatcher } from "strongly-typed-events";
import { NotificationProps } from "./Notification";

export class NotificationService {
    private static nextId = 1;

    private static notifications: NotificationProps[] = [];

    private static _onChange = new SimpleEventDispatcher<NotificationProps[]>();
    public static get onChange() {
        return NotificationService._onChange.asEvent();
    }

    public static hide(id: string) {
        NotificationService.notifications = NotificationService.notifications.filter(n => n.id !== id);
        NotificationService._onChange.dispatch(NotificationService.notifications);
    }

    public static add(notificationProps: NotificationProps) {
        // Assign unique ID
        if (notificationProps.id === undefined) {
            notificationProps.id = NotificationService.nextId.toString();
            NotificationService.nextId++;
        } else {
            // ID was provided. Do nothing if that ID already exists
            if (NotificationService.notifications.some(n => n.id === notificationProps.id))
                return;
        }

        const cancelFn = () => {
            NotificationService.hide(notificationProps.id!);
            if (notificationProps.onCancel)
                notificationProps.onCancel();
        };
        
        NotificationService.notifications.push({
            ...notificationProps,
            onCancel: cancelFn
        });
        NotificationService._onChange.dispatch(NotificationService.notifications);
    }
}