import { useContext, useEffect, useState } from "react";
import { disableAllCalcOptions, CaseGanttCase, CaseGanttChartRequestType, CaseGanttData, TimeMode } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { GroupingKeys } from "../models/Dfg";
import { getStatType } from "../models/Gantt";
import { SortOrder } from "../models/KpiTypes";
import { Datastores } from "../utils/Datastores";
import { getIsGroupingValid } from "../utils/Initializers";
import { DateTime } from "luxon";
import { noop } from "lodash";

export function useCaseGantt(requestOptions?: Partial<CaseGanttChartRequestType>, overrideGroupingKey?: GroupingKeys, disabled = false): [undefined | CaseGanttData, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gantt, setGantt] = useState<CaseGanttData | undefined>();

    const filters = requestOptions?.eventFilters ?? settings.previewFilters ?? settings.filters;
    const groupingKey = overrideGroupingKey ?? settings.groupingKey;

    const timeMode = requestOptions?.timeMode ?? settings.gantt.timeMode;

    useEffect(() => {
        if (!session.project?.eventKeys ||
            !session.project?.uploadId ||
            !getIsGroupingValid(session.project?.eventKeys, settings.groupingKey) ||
            disabled) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const subscriptionId = Datastores.caseGantt.getSubscriptionId();

        const sort = getSortString();

        const options: CaseGanttChartRequestType = {
            ...disableAllCalcOptions,
            eventFilters: filters,
            eventKeys: {
                ...session.project?.eventKeys,
                activityKeysGroup: groupingKey,
            },
            uploadId: session.project?.uploadId,
            uploads: session.project?.uploads,
            timeMode,
            sort: requestOptions?.sort ?? sort,

            // For performance reasons, we're limiting this to 100.
            // TODO: We could speed this up if we rendered the rows
            // separately rather than in one single canvas.
            limit: 200,
            ...requestOptions
        };

        Datastores.caseGantt.get(options, subscriptionId).then((data) => {
            if (options.timeMode === TimeMode.Absolute) {
                const cases = data.cases.map((entry) => {
                    return {
                        ...entry,
                        events: entry.events.map((e) => [
                            DateTime.fromISO(fixTimezone(String(e[0])!)).toUTC().toSeconds(),
                            DateTime.fromISO(fixTimezone(String(e[1])!)).toUTC().toSeconds(),
                            e[2]
                        ]),
                    } as CaseGanttCase;
                });

                setGantt({
                    ...data,
                    cases,
                });
            }
            else
                setGantt(data);
        }).catch(noop).finally(() => {
            setIsLoading(false);
        });

        return () => {
            Datastores.caseGantt.cancelSubscription(subscriptionId);
        };
    }, [
        session.project?.eventKeys,
        session.project?.uploadId,
        session.project?.uploads,
        session.project,
        JSON.stringify(filters),
        groupingKey,
        settings.gantt.sortOrder,
        settings.quantity,
        settings.gantt.caseGanttSettings,
        settings.apiRetry,
        JSON.stringify(requestOptions),
        timeMode,
    ]);

    return [gantt, isLoading];

    function getSortString(): string {
        const statType = getStatType(settings);
        return settings.gantt.sortOrder === SortOrder.Descending ? "-" + statType : statType;
    }

    function fixTimezone(time: string) {
        if (!time.includes("Z") && !time.includes("+"))
            return time + "Z";

        return time;
    }
}