import * as amplitude from "@amplitude/analytics-browser";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { SessionContext } from "../contexts/SessionContext";
import { getUrlPathWithoutUUID } from "../utils/Utils";
import { isNoneUserRun } from "../Global";

export function useAmplitude() {
    const session = useContext(SessionContext);
    const auth0 = useAuth0();
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    const location = useLocation();

    if (
        apiKey === undefined ||
    isNoneUserRun() ||
    !auth0.user?.sub
    ) {
        return;
    }

    amplitude.init(apiKey ?? "", {
        userId: auth0.user?.sub,
        serverZone: "EU",

        // We are only interested in page views for now
        autocapture: false,
    });

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set(
        "organization",
        session.user?.organization && session.user.organization !== ""
            ? session.user?.organization
            : "Not defined"
    );
    identifyEvent.set(
        "isCustomer",
        session.user?.isCustomer !== undefined
            ? session.user?.isCustomer
            : "Not defined"
    );
    identifyEvent.set(
        "persona",
        session.user?.persona !== undefined ? session.user?.persona : "Not defined"
    );

    amplitude.identify(identifyEvent);

    amplitude.track("Page View", {
        href: getUrlPathWithoutUUID(location),
    });

    return [amplitude.track];
}
