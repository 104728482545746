import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import { SessionContext } from "../../contexts/SessionContext";

export type NumberInputProps = {
    disabled?: boolean;
    onValueChanged?: (e: number | undefined) => void;
    value: number;
    className?: string;
    placeholder?: string;
    allowNegative?: boolean;
    min?: number;
    max?: number;
};

const locales: {[iana: string]: {
    thousandSeparator: string,
    decimalSeparator: string,
}} = {
    de: {
        thousandSeparator: ".",
        decimalSeparator: ",",
    },
    en: {
        thousandSeparator: ",",
        decimalSeparator: ".",
    },
};

/**
 * Wrapper class that feeds react-number-input with sane defaults for our app
 */
export function NumberInput(props: NumberInputProps) {
    const session = useContext(SessionContext);
    const locale = locales[session.numberFormatLocale];

    return <NumericFormat
        disabled={props.disabled}
        value={props.value}
        className={props.className}
        placeholder={props.placeholder}
        allowNegative={props.allowNegative}
        min={props.min}
        max={props.max}
 
        onChange={undefined}
        onValueChange={(e) => {
            if (props.onValueChanged)
                props.onValueChanged(e.floatValue);
        }}
        thousandsGroupStyle="thousand"
        thousandSeparator={locale.thousandSeparator}
        decimalSeparator={locale.decimalSeparator}
        fixedDecimalScale={false}
        allowLeadingZeros={true}
    />;
}