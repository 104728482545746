import React, { useContext } from "react";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { RcaType } from "../../../contexts/ContextTypes";
import { SettingsContext, getRecentRcaByType } from "../../../contexts/SettingsContext";
import { Formatter } from "../../../utils/Formatter";
import { getRcaSelectionType } from "../RcaUtils";
import { useSyncedRcaSelection } from "../UseRca";
import { PlanningDeviationRcaStats } from "./PlanningDeviationRcaStats";
import { ProcessPathRcaStats } from "./ProcessPathRcaStats";
import { QualityRcaStats } from "./QualityRcaStats";

/**
 * Main RCA side statistics. Use this and be happy!
 */
export default function SideStatisticsRcaStats(props: {
    type: RcaType
}) {
    const settings = useContext(SettingsContext);
    const rca = getRecentRcaByType(props.type, settings);

    useSyncedRcaSelection({rca});

    const showDefaultRcaStats = getRcaSelectionType(settings.selection) === undefined &&
        props.type !== RcaType.Bottleneck;
    const showDetailRcaStats = !!getRcaSelectionType(settings.selection);

    const stats = rca?.result?.statistics;
    if (!stats || (!showDefaultRcaStats && !showDetailRcaStats))
        return null;

    return <div data-testid="sidePanel">
        {showDefaultRcaStats &&
            <StatsSection title={"rca.rcaTitle"}>
                <StatsSubSection>
                    <StatsRowValue label={"rca.caseCount"} value={stats?.nCases ?? undefined} unit={Formatter.units.numberShort}/>
                    <StatsRowValue label={"rca.featureCount"} value={stats?.nFeatures ?? undefined} unit={Formatter.units.numberShort}/>
                </StatsSubSection>
            </StatsSection>
        }

        {showDetailRcaStats && <>
            {props.type === RcaType.Time && <PlanningDeviationRcaStats />}

            {props.type === RcaType.Quality && <QualityRcaStats />}

            {props.type === RcaType.Throughput &&  <ProcessPathRcaStats rcaType={props.type} />}

            {props.type === RcaType.ThroughputTime && <ProcessPathRcaStats rcaType={props.type} />}
        </>}
    </div>;
}
