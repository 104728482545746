import React from "react";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsSubSection } from "../../../components/stats-section/StatsSection";
import { UnitMetadata, unitToSigned } from "../../../utils/Formatter";


export function InfluenceStatsSubsection(props: {label: string, influence: number | undefined, unitMeta: UnitMetadata | undefined}) {
    return <StatsSubSection title={"rca.selectedFeatureRelevance"}>
        <StatsRowValue
            label={props.label}
            value={props.influence}
            unit={unitToSigned(props.unitMeta)}
            isHighlight={true}
            isLoading={props.influence === undefined}
        />
    </StatsSubSection>;
}
