import { useContext } from "react";
import { disableAllCalcOptions, PerProductStatisticsParams, SupplyChainGraphSchema } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { SettingsContext } from "../contexts/SettingsContext";
import { SessionContext } from "../contexts/SessionContext";

export function useSupplyChainGraphs(request: Partial<PerProductStatisticsParams> = {}, options?: ApiHookOptions<SupplyChainGraphSchema>): [SupplyChainGraphSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const eventKeys = request.eventKeys ?? session.project?.eventKeys;

    const requestOptions: PerProductStatisticsParams = {
        ...disableAllCalcOptions,
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: eventKeys ?? { } as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        ...request,
    };

    const setDisabled = options?.disable || !eventKeys || !requestOptions.uploadId || !session.project?.uploads?.billOfMaterials?.id;

    return useApi(
        Datastores.getSupplyChainGraphs, 
        requestOptions, 
        [ JSON.stringify(requestOptions) ], 
        { 
            ...options, 
            disable: setDisabled 
        });
}