import React, { useContext, useState } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { isOniqDev, SessionContext } from "../../contexts/SessionContext";
import {
    BreadcrumbsType,
    TabbedView,
    TabbedViewType,
} from "../../components/tabbed-view/TabbedView";
import i18n from "../../i18n";
import { ValueStreamSubtitle } from "../../components/tabbed-view/ValueStreamSubtitle";
import { getKpiDefinition } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { UnitMetadata } from "../../utils/Formatter";
import { Api } from "../../api/Api";
import {
    getFileName,
    TemplateType,
} from "../../components/download-file/DownloadFile";
import FileSaver from "file-saver";
import { AcceptedHeaders, BaseQuantityType } from "../../models/ApiTypes";
import {
    CaseGanttParamsEventFiltersInner,
    UploadCollectionSchema,
} from "../../models/generated";
import { getNodeStatisticName } from "../../utils/MainNodeKpi";
import { GroupingKeys } from "../../models/Dfg";
import { getCustomKpisDfg } from "../../utils/DfgUtils";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import Dropdown from "../../components/dropdown/Dropdown";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { viewSettingsInitialization } from "../../utils/Initializers";

export default function MasterDataDownloadView() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const isOniqDevUser = isOniqDev(session);


    const routingId = session.project?.uploads?.cases?.columnMapping?.routingId;
    const isAllowedMasterDataDownload =
    !!routingId &&
    (isOniqDevUser || !!session.project?.features?.allowMasterDataDownload);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const fileName = getFileName(
        settings,
        window.location.pathname,
        TemplateType.Node
    );
    const fileType = AcceptedHeaders.Excel.toString();

    // Define which Kpis to request
    const requestKpiTypes = [
        KpiTypes.ProductionTimePerPass,
        KpiTypes.SetupTimePerPass,
        KpiTypes.ProductionTime,
        KpiTypes.SetupTime,
        KpiTypes.Frequency,
    ];

    const caseYieldQuantities = getAssignedQuantities(
        session.project?.eventKeys,
        QuantityType.CaseYield,
        false
    );

    const getTranslatedKPI = () => {
        const result = [
            {
                label: i18n.t("common.routingId"),
                id: "routingId",
            },
            {
                label: i18n.t("common.product"),
                id: "activity_values.product.value",
            },
            {
                label: i18n.t("common.passId"),
                id: "activity_values.pass_id.value",
            },
            {
                label: i18n.t("common.description"),
                id: "activity_values.operation.value",
            },
            {
                label: i18n.t("common.machine"),
                id: "activity_values.machine.value",
            },
            {
                label: i18n.t("common.machineType"),
                id: "activity_values.machine_type.value",
            },
            {
                label: i18n.t("common.location"),
                id: "activity_values.location.value",
            },
        ];

        if (isObjectCentricAvailable(session.project?.eventKeys)) {
            result.push({
                label: i18n.t("common.objectType"),
                id: "objectType",
            });
        }

        for (const kpiType of requestKpiTypes) {
            const kpiDef = getKpiDefinition(kpiType, {
                session,
                settings: {
                    ...settings,
                    kpi: {
                        ...settings.kpi,
                        selectedKpi: kpiType,
                    },
                    quantity: settings.quantity,
                },
            });

            if (kpiDef?.nodeStatisticsPath === undefined) continue;

            let unitMeta = kpiDef.unit;
            
            if (
                (unitMeta as any).sum !== undefined ||
        (unitMeta as any).mean !== undefined
            )
                unitMeta = (unitMeta as any)[
                    settings.kpi.statistic === StatisticTypes.Sum
                        ? StatisticTypes.Sum.toString()
                        : StatisticTypes.Mean.toString()
                ];

            const unit = (unitMeta as UnitMetadata)
                .getUnits({
                    baseQuantity: settings.quantity,
                })
                .find((u) => u.size === 1);

            const unitTranslated = i18n.t(unit?.name ?? "");

            const statisticName = getNodeStatisticName(session, settings, {
                kpiType,
                statistic: kpiType === KpiTypes.Frequency ? StatisticTypes.Sum : settings.kpi.statistic,
            });

            if (statisticName !== undefined) {
                result.push({
                    id: statisticName,
                    label: unitTranslated ? `${i18n.t(kpiDef.label)} (${unitTranslated})` : `${i18n.t(kpiDef.label)}`,
                });
            }
        }

        return result;
    };

    function QuantitySelector() {
        const options = caseYieldQuantities.map((q) => {
            return {
                label: i18n.t(q.name),
                value: q.id,
            };
        });
        return (
            <>
                <h2>{i18n.t("masterDataDownload.quantitySelection")}</h2>
                <Dropdown
                    className="dropdownLight sizeConstrainedSelect"
                    options={options}
                    value={
                        options.find((o) => o.value === settings.quantity) ?? options[0]
                    }
                    onChange={(e) => {
                        settings.set({ quantity: e!.value as BaseQuantityType });
                    }}
                />
            </>
        );
    }

    function StatisticsSelector() {
        const options = [StatisticTypes.Mean, StatisticTypes.Median].map((q) => {
            return {
                label: i18n.t(`common.statistics.${q}`),
                value: q,
            };
        });

        return (
            <div className="mt">
                <h2>{i18n.t("masterDataDownload.statisticSelection")}</h2>
                <Dropdown
                    options={options}
                    className="dropdownLight mbl sizeConstrainedSelect"
                    onChange={(p) => {
                        if (p?.value) {
                            settings.mergeSet({
                                kpi: { statistic: p.value as StatisticTypes },
                            });
                        }
                    }}
                    value={
                        options.find((o) => o.value === settings.kpi.statistic) ??
            options[0]
                    }
                />
            </div>
        );
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const eventFilters = settings.previewFilters ?? settings.filters;
        const customKpiDefinitions = getCustomKpisDfg(
            settings,
            session,
            false,
            requestKpiTypes
        );

        const response = await Api.getExportedMasterDataFile({
            ...customKpiDefinitions,
            useActivityPasses: true,
            consolidatePasses: true,
            eventKeys: {
                ...session.project?.eventKeys,
                activityKeysGroup: GroupingKeys.MachineValueStream,
            },
            eventFilters: eventFilters as CaseGanttParamsEventFiltersInner[],
            uploadId: session.project!.uploadId!,
            uploads: session.project?.uploads as UploadCollectionSchema,
            exportColumns: getTranslatedKPI(),
        });

        const blob = new Blob([response.data], { type: fileType });
        FileSaver.saveAs(blob, fileName + ".xlsx");

        setIsSubmitting(false);
    };

    const dataDownloadPages: TabbedViewType[] = [
        {
            isVisible: isAllowedMasterDataDownload,
            disableSharing: true,
            tabTitle: "common.analysesView.masterDataDownload",
            tabSlug: "master-data-download",
            stats: undefined,
            controller: undefined,
            activator: (preferences) => {
                settings.set(viewSettingsInitialization(session, settings, preferences, undefined, {
                    quantities: caseYieldQuantities.map(q => q.baseQuantity),
                }));
            },
            content: (
                <>
                    <div className="masterDataDownload">
                        <p className="infoText">
                            {i18n.t("masterDataDownload.description")}
                        </p>

                        {caseYieldQuantities.length > 1 && <QuantitySelector />}

                        <StatisticsSelector />
                        <button
                            onClick={handleSubmit}
                            className="shortcutButton"
                            disabled={isSubmitting}
                        >
                            <span>{i18n.t("masterDataDownload.buttonLabel")}</span>
                            <svg className="svg-icon xsmall">
                                <use xlinkHref="#radix-download" />
                            </svg>
                        </button>
                    </div>
                </>
            ),
        },
    ];

    const breadcrumbs: BreadcrumbsType[] = [
        {
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        },
        {
            label: "common.analysesView.masterDataDownload",
        },
    ];

    return (
        <TabbedView
            subtitle={<ValueStreamSubtitle />}
            breadcrumbs={breadcrumbs}
            pages={dataDownloadPages}
        />
    );
}
