import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { EventKeys } from "../../models/EventKeys";
import { Project } from "../../models/Project";
import { Upload } from "../../models/Upload";
import { Datastores } from "../../utils/Datastores";
import { MappingStateType } from "./AssignColumns";
import IdentifyColumns from "./IdentifyColumns";
import { Api } from "../../api/Api";


const initialPlanMappingStates = [{
    name: "common.caseId",
    key: "caseId",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.confirmation",
    key: "operation",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.startTime",
    key: "startTime",
    isOptional: false,
    allowedTypes: ["datetime"]
}, {
    name: "common.endTime",
    key: "endTime",
    isOptional: true,
    allowedTypes: ["datetime"]
}, {
    name: "common.machine",
    key: "machine",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.machineType",
    key: "machineType",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.location",
    key: "location",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.role",
    key: "role",
    isOptional: true,
    allowedTypes: ["string"]
}, {
    name: "common.productionTime",
    key: "isProduction",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.setup",
    key: "isSetup",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.link",
    key: "isLink",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.orderSequence",
    key: "passId",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.objectId",
    key: "object",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.objectType",
    key: "objectType",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.eventId",
    key: "eventId",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.yieldMass",
    key: "yieldMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.yield"
}, {
    name: "common.yieldLength",
    key: "yieldLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.yield"
}, {
    name: "common.yieldCount",
    key: "yieldCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.yield"
}, {
    name: "common.scrapMass",
    key: "scrapMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.scrap"
}, {
    name: "common.scrapLength",
    key: "scrapLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.scrap"
}, {
    name: "common.scrapCount",
    key: "scrapCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.scrap"
}, {
    name: "common.caseYieldMass",
    key: "caseYieldMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseYield"
}, {
    name: "common.caseYieldLength",
    key: "caseYieldLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseYield"
}, {
    name: "common.caseYieldCount",
    key: "caseYieldCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseYield"
}, {
    name: "common.caseScrapMass",
    key: "caseScrapMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseScrap"
}, {
    name: "common.caseScrapLength",
    key: "caseScrapLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseScrap"
}, {
    name: "common.caseScrapCount",
    key: "caseScrapCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseScrap"
}
];

const columnKeysToCheck = ["machine", "machineType", "location"];


export default function EditPlan() {
    const navigate = useNavigate();

    const { projectId } = useParams<{
        projectId: string,
    }>();

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    session.setProject(projectId);

    const [project, setProject] = useState<Project | undefined>();

    const [subscriptionId, setSubscriptionId] = useState<number>(0);
    useEffect(() => {
        const subscriptionId = Datastores.getUploadRawEvents.getSubscriptionId();
        setSubscriptionId(subscriptionId);

        return () => {
            Datastores.getUploadRawEvents.cancelSubscription(subscriptionId);
        };
    }, []);

    // Fetch upload including all properties from API
    const [upload, setUpload] = useState<Upload | undefined>();
    const [mappingStates, setMappingStates] = useState<MappingStateType[]>(initialPlanMappingStates);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    // Project ID is only present if we're re-visiting this page from an existing
    // project.
    useEffect(() => {
        if (projectId)
            Api.getProject(projectId).then((p) => {
                setProject(p);

                const allowedMappingStates = mappingStates.map(s => {
                    if (columnKeysToCheck.includes(s.key) && p.eventKeys![s.key] === undefined)
                        return { ...s, isSelectable: false };
                    else
                        return s;
                });

                if (p.uploadIdPlan) {
                    Api.getUpload(p.uploadIdPlan).then((upload) => {
                        setUpload(upload);
                    });

                    Datastores.getUploadRawEvents.get({
                        id: p.uploadIdPlan,
                        limit: 10,
                        offset: 0
                    }, subscriptionId).then((preview) => {
                        const previewColumns = preview.length ? Object.keys((preview as any)[0]) : undefined;

                        if (p.eventKeysPlan) {
                            // Build mapping states from eventKeys
                            const newMappingState = allowedMappingStates.map(s => {
                                if (columnKeysToCheck.includes(s.key))
                                    if (p.eventKeys![s.key] === undefined)
                                        return { ...s, isSelectable: false };
                                const assignedColumn = p.eventKeysPlan![s.key];
                                const columnIdx = previewColumns?.findIndex(c => c === assignedColumn);

                                if (!assignedColumn || columnIdx === undefined || columnIdx < 0)
                                    return s;

                                return {
                                    ...s,
                                    colIdx: columnIdx,
                                };
                            });

                            setMappingStates(newMappingState);
                        } else setMappingStates(allowedMappingStates);
                        setIsInitialized(true);
                    });
                }
                else {
                    navigate("/projects/" + projectId + "/add-plan");
                }
            });
    }, [projectId, settings.apiRetry]);

    return <>
        <Spinner isLoading={!isInitialized} />
        {upload && isInitialized && <IdentifyColumns
            mappingStates={mappingStates}
            onSubmitLabel={"identifyColumns.buttonUpdate"}
            onCancelLabel={"identifyColumns.buttonCancel"}
            upload={upload}
            onCancel={cancelUpdatePlan}
            onSubmit={updatePlan}
        />}
    </>;

    function cancelUpdatePlan() {
        navigate("/projects/" + projectId + "/dashboard");
    }

    async function updatePlan(partialProject: { name: string, eventKeys: EventKeys }) {
        if (project) {
            const result = await Api.updateProject({
                ...project,
                eventKeysPlan: partialProject.eventKeys,
                eventFilters: []
            });

            session.set({
                project: result,
            });

            Datastores.flushAllCaches();

            navigate("/projects/" + projectId + "/dashboard");
        }
    }
}
