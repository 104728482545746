import { get } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { timeAndThroughputCalcOptions } from "../../models/ApiTypes";
import { Bar, Graph } from "../../components/graph/Graph";
import { VarianceLegend } from "../../components/graph/VarianceLegend";
import { useGridResize } from "../../components/kpi-chart/UseGridResize";
import Spinner from "../../components/spinner/Spinner";
import Toast from "../../components/toast/Toast";
import { SessionContext, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { getUnit } from "../../models/Kpi";
import { Stats } from "../../models/Stats";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import { getMessage } from "../process-kpi-chart/ProcessKpiChart";
import { GraphLine, commonSelectionLineProps } from "../../components/graph/GraphCommon";
import DownloadFile, { TemplateType } from "../../components/download-file/DownloadFile";
import { getKpiDefinition } from "../../models/Kpi";
import { UnitMetadata } from "../../utils/Formatter";
import { getAnalysisTitle } from "../../components/product-chart/ProductChart";
import { getProductPathFromDefinition } from "../../utils/SettingsUtils";
import { useStatistics } from "../../hooks/UseStatistics";
import { SortOrder } from "../../models/KpiTypes";
import { ProductStatType, useProducts } from "../../hooks/UseProducts";
import { ProductCaseAggregationStatisticsSchema } from "../../models/generated";

type BarData = {
    product: ProductStatType,
    stats: Stats,
};

export function ProductVariations(props: {
    noDataPlaceholder: string,
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const [isToastClosed, setIsToastClosed] = useState(false);

    const container = useRef<HTMLDivElement>(null);
    const [width, height] = useGridResize(container, 1, undefined, undefined, 0);

    const [stats, ] = useStatistics(undefined);

    // Yes, we're loading all product aggregations here. The reason for this is the
    // custom sorting we're using below. There is no way we can offload that to the API
    // at the moment.
    const [productAggregations, isLoading] = useProducts({
        ...timeAndThroughputCalcOptions,
        ...kpiDefinition?.apiParameters,
        customKpis: kpiDefinition?.productCustomKpis ? kpiDefinition?.productCustomKpis : undefined,
        limit: Number.MAX_SAFE_INTEGER,
    }, {});

    // Draw selection line
    const selectedProduct = productAggregations?.products?.find(x => x.id === settings.selection.product?.id);

    const value = selectedProduct !== undefined ? getStatsFromSettings(selectedProduct) : undefined;

    const selectedLine: GraphLine[] = value?.median !== undefined ?
        [{ ...{ value: value.median, ...commonSelectionLineProps } }] :
        [];

    // Initialize processPathUnit if still unset
    useEffect(() => {
        if (!(getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false) ?? []).some(q => q.id === settings.quantity)) {
            settings.set({
                quantity: (getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false) ?? [undefined])[0]?.baseQuantity || "none",
            });
        }
    }, [
        session.project
    ]);

    const [data, productsWithSingleProduction]: [Bar<BarData>[] | undefined, number] = useMemo(() => {
        if (productAggregations === undefined)
            return [undefined, 0];

        const result: Bar<BarData>[] = [];
        let productsWithSingleProduction = 0;

        for (const product of productAggregations.products) {
            const stats = getStatsFromSettings(product);

            // we leave out products with a count of 1 because there is no advantage in analysing them.
            // However, display a toast to inform the user that there are products that have only
            // been produced once.
            // See https://gitlab.com/oniqofficial/general/oniq/-/issues/1270
            if (product.actual?.count === 1) {
                productsWithSingleProduction++;
                continue;
            }

            if (stats?.median === undefined ||
                stats?.p75 === undefined ||
                stats?.p25 === undefined)
                continue;

            result.push({
                label: product.name,
                data: {
                    product,
                    stats
                },
                value: stats.p75,
                baseValue: stats.p25,
                median: stats.median,
            });
        }

        // Sort!
        const sorted = sortByVariance(result, settings) as Bar<BarData>[];

        return [sorted, productsWithSingleProduction];
    }, [
        productAggregations,
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        settings.quantity,
        settings.kpi.selectedKpi
    ]);

    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic) as UnitMetadata;

    const downloadAllowed = hasDownloadPermission(session);

    const hasData = data !== undefined && data.length > 0;

    const title = i18n.t(getAnalysisTitle(session, settings)).toString();

    return <div className="fillParentMargin" ref={container}>
        <Spinner isLoading={isLoading} showProjectLoadingSpinner={true} />

        {!isLoading && <>
            {productAggregations !== undefined && !hasData && getMessage(productsWithSingleProduction > 0 ? i18n.t("workflows.throughputVariance.noDataVariance") : props.noDataPlaceholder, stats?.numFilteredTraces)}

            {!isLoading && hasData && width !== undefined && height !== undefined &&
                <>
                    <Graph
                        title={title}
                        horizonalLines={selectedLine}
                        data={data ?? []}
                        legend={<VarianceLegend />}
                        padding={{
                            top: 40,
                            left: 80,
                            bottom: 100,
                        }}
                        min={0}
                        selectedIndex={data.findIndex(d => d?.data?.product.id === settings.selection.product?.id)}
                        onSelected={(e) => {
                            settings.setSelection({
                                product: (e?.data) ? {
                                    id: e.data.product.id,
                                    name: e.data.product.name,
                                } : undefined
                            });
                        }}
                        width={width}
                        height={height}
                        yAxisUnit={unit}
                        showYAxisLines={true}
                        showYAxisTicks={true}
                        valueFormatter={(value) => {
                            if (unit)
                                return unit.formatter(value, {
                                    locale: session.numberFormatLocale,
                                    baseQuantity: settings.quantity
                                });
                            return "";
                        }}
                    />
                    <DownloadFile
                        data={data}
                        template={TemplateType.ProductVariations}
                        meta={unit}
                        allowed={downloadAllowed}
                        title={title} />
                </>
            }

            {!isToastClosed && <div className="toastContainer" style={{ width: width }}>
                <Toast visible={productsWithSingleProduction > 0 && !isToastClosed} className="toastCondensed" onClose={() => setIsToastClosed(true)} >
                    {i18n.t("workflows.throughputVariance.singleProductionToast", {
                        count: productsWithSingleProduction,
                        total_number_of_products_in_current_filter: productAggregations?.products?.length,
                    })}
                </Toast>
            </div>}

            <Shortcuts handledSelections={[ShortcutContexts.Product]} />
        </>
        }
    </div>;

    function getStatsFromSettings(product: ProductCaseAggregationStatisticsSchema): Stats | undefined {
        const path = getProductPathFromDefinition(settings, kpiDefinition);

        // Unless mentioned otherwise, we'd like to get the value
        // from actual data.
        return path ? get(product, (!path.startsWith("deviation")? "actual." : "") + path as string) as Stats : undefined;
    }
}

export function getSortingValue(stats: Stats, settings: SettingsType) {
    if (settings.kpi.sortBy === SortByType.Median)
        return stats.median;

    if (settings.kpi.sortBy === SortByType.Percentiles)
        // This sorting mode prevents us from using server-side sorting
        // and paging. Custom calculations are not supported by the API.
        if (stats.p25 !== undefined && stats.p75 !== undefined)
            return stats.p75 - stats.p25;
}


/**
 * Sort function that can be used to sort by percentile difference
 * or median.
 * @param stats
 * @param settings
 * @returns
 */
export function sortByVariance(stats: Bar<{stats: Stats}>[], settings: SettingsType) {
    const sign = settings.kpi.sortOrder === SortOrder.Ascending ? 1 : -1;
    const sorted = stats.filter(a => a.value !== undefined).sort((a, b) => {
        const aVal = getSortingValue(a.data!.stats, settings)!;
        const bVal = getSortingValue(b.data!.stats, settings)!;

        return (aVal - bVal) * sign;
    });
    return sorted;
}

export function exportProductVariationData(data: Bar<BarData>[]) {
    const result = [];

    for (const bar of data) {
        const item = {
            [i18n.t("common.product")]: bar?.label,
            [i18n.t("common.statistics.median")]: bar?.median,
            [i18n.t("common.percentile25")]: bar?.baseValue,
            [i18n.t("common.percentile75")]: bar?.value,
            [i18n.t("common.statistics.shortMin")]: bar.data?.stats?.min,
            [i18n.t("common.statistics.shortMax")]: bar.data?.stats?.max,
        };
        result.push(item);
    }
    return result;
}
