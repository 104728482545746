import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../../../contexts/SessionContext";
import { ProductIdentifier, SettingsContext } from "../../../../contexts/SettingsContext";
import { maxValuesPerColumn, useColumnValues, ValueLookupType } from "../../../../hooks/UseColumnValues";
import i18n from "../../../../i18n";
import { useParetoClustering } from "../../../../hooks/UseParetoClustering";
import { AbcProductFilter, AbcProductStateType, EventFilter, abcFilterRenderType, productFilterRenderType } from "../../../../models/EventFilter";
import { Datastores } from "../../../../utils/Datastores";
import Spinner from "../../../spinner/Spinner";
import Toast, { ToastTypes } from "../../../toast/Toast";
import { buildProductFilter } from "../../../../utils/FilterBuilder";
import { capitalize, flatten, noop } from "lodash";
import { quantities } from "../../../../utils/Quantities";
import Dropdown from "../../../dropdown/Dropdown";

type ProductFilterEditorPropsType = {
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};

type RowElementType = {
    checked: boolean;
    value: string;
    disabled: boolean;
};

type CategoryElement = {
    columnId: string;
    title: string;
    isLoading: boolean,
    data: RowElementType[];
    hasSearchBar?: boolean;
    searchValue?: string;
};

export default function ProductFilterEditor(props: ProductFilterEditorPropsType) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const initialAbcFilter = props.initialValue?.filters?.find(f => f.renderType === abcFilterRenderType) as AbcProductFilter;

    const [categoryState, setCategoryState] = useState<CategoryElement[] | undefined>();
    const [abcSelection, setAbcSelection] = useState<AbcProductStateType>(() => {
        if (!initialAbcFilter)
            return [false, false, false];

        return [...initialAbcFilter.abcState.selection] as AbcProductStateType;
    });

    const selectedQuantityName = initialAbcFilter ? (initialAbcFilter as AbcProductFilter).abcState.quantity : "count";
    const selectedQuantity = quantities.find(q => q.id === selectedQuantityName && !q.isFrequency);

    const [isExcluded, setIsExcluded] = useState<boolean>(false);

    const [includeUpstream, setIncludeUpstream] = useState<boolean>(false);

    const columns = (categoryState ?? []).map(s => s.columnId);

    const columnValueLookup = useColumnValues(columns) ?? {};

    const [subscriptionId] = useState<number>(() => Datastores.distinctAttributeValues.getSubscriptionId());
    useEffect(() => { return () => { Datastores.distinctAttributeValues.cancelSubscription(subscriptionId); }; }, []);

    useEffect(() => {
        if (categoryState !== undefined && subscriptionId)
            updateOptions(categoryState, isExcluded, includeUpstream);
    }, [
        session.project?.id,
        settings.filters,
        subscriptionId,
        settings.apiRetry,
        isExcluded
    ]);

    const kpiOptions = [{
        label: `${i18n.t("common.caseCount")}`,
        value: "count",
    }];

    if (session.project?.eventKeys !== undefined) {
        const caseYieldProp = `caseYield${capitalize(selectedQuantity?.id)}`;
        if (session.project.eventKeys[caseYieldProp] !== undefined) {
            kpiOptions.push({
                label: `${i18n.t("common.yield")} (${i18n.t(selectedQuantity?.name ?? "")})`,
                value: `${caseYieldProp}Statistics.sum`,
            });

            // After discussion with @ckosmehl we agreed that the current definition of "Produktionsmenge"
            // may not always be correct and we need to re-assess whether we want to use it here. For now 
            // we should therefore take it out here.
            // In case we want it back, uncomment the following lines:

            // kpiOptions.push({
            //     label: `${i18n.t("common.outputWithoutUnit")} (${i18n.t(selectedQuantity?.name ?? "")})`,
            //     value: `caseOutput${capitalize(selectedQuantity?.id)}Statistics.sum`,
            // });
        }

        const scrapProp = `scrap${capitalize(selectedQuantity?.id)}`;
        if (session.project.eventKeys[scrapProp] !== undefined)
            kpiOptions.push({
                label: `${i18n.t("common.scrap")} (${i18n.t(selectedQuantity?.name ?? "")})`,
                value: `case${capitalize(scrapProp)}Statistics.sum`,
            });
    }

    const [statistic, setStatistic] = useState<string>(() => {
        return initialAbcFilter?.abcState.statistic ?? kpiOptions[0].value;
    });

    const [clusters, isClustersLoading] = useParetoClustering({
        statistic,
        calculateOutputStats: true,
        eventFilters: [],
    });

    useEffect(() => {
        if (!clusters)
            return;

        // Initialize category state
        const state: CategoryElement[] = [];

        // Product name column
        const hasProductIds = !!session.project?.eventKeys?.product;
        if (hasProductIds)
            state.push({
                isLoading: true,
                columnId: session.project!.eventKeys!.product!,
                title: i18n.t("common.product"),
                hasSearchBar: true,
                data: [],
            });

        // Product category columns
        for (const title of session.project?.eventKeys?.productCategories ?? [])
            state.push({
                title,
                hasSearchBar: true,
                columnId: title,
                isLoading: true,
                data: [],
            });
        initializeState(columnValueLookup, state);
    }, [
        session.project?.id,
        JSON.stringify(columnValueLookup),
        clusters,
        settings.apiRetry,
    ]);

    const isInitializationComplete = categoryState !== undefined && categoryState.every(c => !c.isLoading);
    useEffect(() => {
        // When initialization is complete, emit the current filter state once
        if (isInitializationComplete && props.onUpdate) {
            const filter = stateToFilter(statistic, abcSelection, categoryState, isExcluded, includeUpstream);

            if (filter.filters?.length === 0)
                props.onUpdate(undefined);
            else
                props.onUpdate(filter);
        }
    }, [
        isInitializationComplete,
    ]);

    if (categoryState !== undefined && categoryState.length === 0)
        return <div className="productFilterEditor tabPage">
            <Toast className="center grab" type={ToastTypes.Info} visible={true}>
                {i18n.t("filters.noProductsDefined")}
            </Toast>
        </div>;

    return <div className="productFilterEditor tabPage">
        <div className="panelsContainer">
            <div className="panel">
                <Spinner isLoading={isClustersLoading || !categoryState} />
                {categoryState && <>
                    <h3>{i18n.t("common.abcFilterTitle")}</h3>
                    <Dropdown
                        className="dropdownLight mbs"
                        options={kpiOptions}
                        value={kpiOptions.find(o => o.value === statistic)!}
                        onChange={(e) => {
                            const stat = e!.value as string;
                            setStatistic(stat);
                            if (props.onUpdate && categoryState) {
                                props.onUpdate(stateToFilter(stat, abcSelection, categoryState, isExcluded, includeUpstream));
                                updateOptions(categoryState, isExcluded, includeUpstream);
                            }
                        }}
                        isSearchable={false}
                        placeholder="Quantity"
                    />
                    <div className="optionsContainer">
                        {!isClustersLoading && <div>
                            {["a", "b", "c"].map((id, idx) => <label key={"abc-" + id}>
                                <input type="checkbox" className="smallCheckbox" checked={abcSelection[idx]} onChange={() => {
                                    abcSelection[idx] = !abcSelection[idx];
                                    setAbcSelection([...abcSelection]);
                                    if (props.onUpdate && categoryState) {
                                        props.onUpdate(stateToFilter(statistic, abcSelection, categoryState, isExcluded, includeUpstream));
                                        updateOptions(categoryState, isExcluded, includeUpstream);
                                    }
                                }} />
                                {i18n.t(`common.${id}Products`)}
                            </label>)}
                        </div>}
                    </div>
                </>}
            </div>

            {(categoryState || []).map((c) => {
                return <div className="panel" key={c.title}>
                    <h3>{c.title} ({getSearchResults(c.data, c.searchValue).filter(s => s.disabled === false).length}/{getSearchResults(c.data, c.searchValue).length})</h3>
                    {!!c.hasSearchBar && <input
                        type="text"
                        className="search"
                        onKeyDown={(e) => {
                            if (e.key !== "Enter")
                                return;

                            e.preventDefault();
                            const firstSearchResult = getSearchResults(c.data, c.searchValue)[0];
                            if (firstSearchResult) {
                                const state = getCheckedState(c, firstSearchResult, !firstSearchResult.checked);
                                const newState = state?.map((v) => {
                                    if (v.columnId === c.columnId)
                                        return { ...v, searchValue: "" };
                                    else return v;
                                });

                                setCategoryState(newState);

                                if (props.onUpdate)
                                    props.onUpdate(stateToFilter(statistic, abcSelection, newState, isExcluded, includeUpstream));

                                updateOptions(newState!, isExcluded, includeUpstream);
                                e.currentTarget.value = "";
                            }
                        }}
                        onChange={(e) => {
                            setSearchValue(e.target.value, c.columnId);
                        }}
                        placeholder={i18n.t("filters.searchForCategory", { item: c.title }).toString()} />}
                    <div className="optionsContainer">
                        <Spinner isLoading={c.isLoading} />
                        <div>
                            {getSearchResults(c.data, c.searchValue).map(d => <label key={c.title + "-" + d.value} className={d.disabled ? "disabled" : undefined}>
                                <input type="checkbox" className="smallCheckbox" checked={d.checked} onChange={() => {
                                    set(c, d, !d.checked);
                                }} />
                                {d.value}
                            </label>)}
                        </div>
                    </div>
                </div>;
            })}

        </div>


        <div className="checkboxes">
            <label className="alignCheckboxes">
                <div>
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={isExcluded}
                        id="checkbox-exclude"
                        data-testid="checkbox-exclude"
                        onChange={(e) => {
                            setIsExcluded(e.target.checked);

                            if (props.onUpdate)
                                props.onUpdate(stateToFilter(statistic, abcSelection, categoryState!, e.target.checked, includeUpstream));

                            updateOptions(categoryState!, e.target.checked, includeUpstream);
                        }} />
                    <label htmlFor="checkbox-exclude" />
                </div>
                <div>
                    {i18n.t("filters.excludeProducts")}
                </div>
            </label>

            {session.project?.uploads?.billOfMaterials?.id !== undefined && <label className="alignCheckboxes mt">
                <div>
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={includeUpstream}
                        id="checkbox-include-upstream"
                        onChange={(e) => {
                            setIncludeUpstream(e.target.checked);

                            if (props.onUpdate)
                                props.onUpdate(stateToFilter(statistic, abcSelection, categoryState!, isExcluded, e.target.checked));

                            updateOptions(categoryState!, isExcluded, e.target.checked);
                        }}
                    />
                    <label htmlFor="checkbox-include-upstream" />
                </div>
                <div>
                    {i18n.t("filters.includeUpstream")}
                </div>
            </label>}
        </div>

    </div>;

    function getSearchResults(elements: RowElementType[], query: string | undefined) {
        const customSort = (elements: RowElementType[]) => {
            const checked = elements.filter(e => e.checked);
            const enabled = elements.filter(e => !e.checked && !e.disabled);
            const disabled = elements.filter(e => !e.checked && e.disabled);
            return [...checked, ...enabled, ...disabled];
        };

        if (!query)
            return customSort(elements);

        const parts = query.split(" ").filter(e => e.trim().length > 0).map(e => e.toString().toLowerCase().trim());
        const checkQuery = (d: RowElementType, parts: string[]) => {
            const lc = d.value.toString().toLowerCase();
            for (const part of parts)
                if (lc.indexOf(part) < 0)
                    return false;

            return true;
        };

        return customSort(elements.filter(d => checkQuery(d, parts)));
    }

    function getCheckedState(category: CategoryElement, element: RowElementType, isChecked: boolean) {
        return (categoryState || []).map(s => {
            return (s.columnId !== category.columnId) ? s : {
                ...s,
                data: s.data.map(e => e.value === element.value ? {
                    ...e,
                    checked: isChecked
                } : e),
            } as CategoryElement;
        });
    }

    function set(category: CategoryElement, element: RowElementType, isChecked: boolean) {
        const nextState = getCheckedState(category, element, isChecked);
        setCategoryState(nextState);
        updateOptions(nextState, isExcluded, includeUpstream);

        queueMicrotask(() => {
            props.onUpdate?.(stateToFilter(statistic, abcSelection, nextState, isExcluded, includeUpstream));
        });
    }

    function setSearchValue(value: string, columnId: string) {
        const newState = categoryState?.map((v) => {
            if (v.columnId === columnId)
                return { ...v, searchValue: value };
            else return v;
        });
        setCategoryState(newState);
    }

    function stateToFilter(statistic: string, abcSelection: AbcProductStateType, categories: CategoryElement[], exclude: boolean, includeUpstream: boolean): EventFilter {
        // Build EventFilter instance from state, if anyone is interested
        const result: EventFilter = {
            filters: [],
            mergeOperator: exclude ? "or" : "and",
            caseAggregator: exclude ? "all" : "any",
            renderType: productFilterRenderType,
        };

        const prefix = exclude ? "ne" : "eq";

        const usedCategories = categories.filter(c => (c.data ?? []).some(d => d.checked));
        for (const category of usedCategories) {
            const values = category.data.filter(d => d.checked).map(d => d.value);
            const attribute = session?.eventUpload?.meta.attributes.find(a => a.name === category.columnId)?.type;

            // Build a filter according to the type
            switch (attribute) {
                case "string":
                    result.filters!.push({
                        caseAttributeText: {
                            name: category.columnId,
                            billOfMaterials: includeUpstream ? { upstreamLevels: 1 } : undefined,
                            [prefix]: values,
                        }
                    });
                    break;

                case "integer":
                    result.filters!.push({
                        caseAttributeInt: {
                            name: category.columnId,
                            billOfMaterials: includeUpstream ? { upstreamLevels: 1 } : undefined,
                            [prefix]: values.map(v => +v),
                        }
                    });
                    break;

                default:
                    throw Error("category type not implemented: " + attribute);
            }
        }

        // Add ABC filter in case there is something selected
        const abcFilter = getAbcProductFilter(statistic, abcSelection, exclude, includeUpstream);
        if (abcFilter)
            result.filters?.push(abcFilter);

        return result;
    }

    function getAbcProductFilter(statistic: string, abcSelection: AbcProductStateType, exclude: boolean, includeUpstream: boolean) {
        const productIds = flatten([0, 1, 2].map(idx => !abcSelection[idx] ? [] : clusters?.groups[idx].products)).filter(f => f !== undefined) as ProductIdentifier[];
        if (!productIds.length)
            return undefined;

        const filter = buildProductFilter(productIds, exclude, session, includeUpstream) as AbcProductFilter;
        filter.renderType = abcFilterRenderType;
        filter.abcState = {
            quantity: selectedQuantity!.id,
            statistic,
            selection: abcSelection
        };
        return filter;
    }

    function initializeState(columnData: ValueLookupType, state: CategoryElement[]) {
        const checkedItems = (props.initialValue?.filters ?? []).map((e: EventFilter) => {
            return {
                name: e.caseAttributeInt?.name ?? e.caseAttributeText?.name,
                value: (e.caseAttributeInt?.eq ?? e.caseAttributeInt?.ne)?.map(v => v.toString()) ?? (e.caseAttributeText?.eq ?? e.caseAttributeText?.ne),
            };
        });

        const f = props.initialValue?.filters?.[0]?.caseAttributeText ??
            props.initialValue?.filters?.[0]?.caseAttributeInt ??
            props.initialValue?.filters?.[0]?.caseAttributeFloat ??
            props.initialValue?.filters?.[0]?.caseAttributeDatetime ??
            props.initialValue?.filters?.[0]?.caseAttributeBool;

        const _isExcluded = !!f?.ne;
        setIsExcluded(_isExcluded);

        const _includeUpstream = !!f?.billOfMaterials?.upstreamLevels;
        setIncludeUpstream(_includeUpstream);

        const newState = state.map(s => {
            const element = columnData[s.columnId] === undefined ? s : {
                ...s,
                isLoading: false,
                data: columnData[s.columnId].sort((a, b) => {
                    return a.value.toString().localeCompare(b.value.toString());
                }).map(x => {

                    const checkedColumnValues = checkedItems.find(i => i.name === s.columnId)?.value;
                    return {
                        checked: !!(checkedColumnValues && checkedColumnValues.indexOf(x.value.toString()) >= 0),
                        disabled: false,
                        value: x.value
                    };
                }).filter(x => !!x.value).sort((a, b) => Number(b.checked) - Number(a.checked)),
                // the last sort is used to display checked items at the very top for saved filters
                // this makes a deselection easier
            } as CategoryElement;

            return element;
        });

        updateOptions(newState, _isExcluded, _includeUpstream);
        setCategoryState(newState);
    }

    /**
     * Updates the disabled state of the category options
     */
    function updateOptions(state: CategoryElement[], exclude: boolean, includeUpstream: boolean) {
        if (!session.project?.eventKeys || !subscriptionId)
            return;

        const attributes = (state || []).map(c => c.columnId);
        if (attributes.length === 0)
            return;

        // We need the complete filter chain up this one
        let filters: EventFilter[] = [];
        if (settings.filterEditor.editFilterIndex !== undefined)
            filters = settings.filters.filter((f, idx) => idx !== settings.filterEditor.editFilterIndex!).concat([stateToFilter(statistic, abcSelection, state, exclude, includeUpstream)]);
        else
            filters = settings.filters.concat([stateToFilter(statistic, abcSelection, state, exclude, includeUpstream)]);

        Datastores.distinctAttributeValues.get({
            eventKeys: session.project!.eventKeys!,
            eventFilters: filters,
            attributes: (state || []).map(c => c.columnId),
            uploadId: session.project!.uploadId!,
            uploads: session.project?.uploads,
            limit: maxValuesPerColumn,
        }, subscriptionId).then(result => {
            setCategoryState((_state) => {
                const columnIds = (_state || []).map(s => s.columnId);

                const newState: CategoryElement[] = [];

                for (const columnId of columnIds) {
                    const resultData = result.find(r => r.name === columnId)?.values ?? [];

                    const currentState = (_state || []).find(s => s.columnId === columnId);
                    if (!currentState)
                        throw Error("unknown column " + columnId + ", please fix this");

                    newState.push({
                        ...currentState,
                        data: currentState.data.map(e => {
                            return {
                                ...e,
                                disabled: !resultData.some(rd => rd.value === e.value)
                            };
                        }),
                    } as CategoryElement);
                }

                return newState;
            });
        }).catch(noop);
    }
}
