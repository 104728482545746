import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SelectFile from "../../components/select-file/SelectFile";
import { SessionContext } from "../../contexts/SessionContext";
import { Upload } from "../../models/Upload";
import { Api } from "../../api/Api";

export default function AddPlan() {
    const navigate = useNavigate();
    const { projectId } = useParams<{projectId: string}>();
    const session = useContext(SessionContext);
    session.setProject(projectId);

    return <SelectFile 
        navigateTo={async (upload: Upload) => {
            await updateProject(upload);
            navigate("/projects/" + projectId + "/edit-plan");
        }}
    />;

    async function updateProject(upload: Upload) {
        if (session.project) {
            await Api.updateProject({
                ...session.project,
                uploadIdPlan: upload.id,
            });

            session.set({
                project: {
                    ...session.project,
                    uploadIdPlan: upload.id
                }
            });
        }
    }
}