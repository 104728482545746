import React, { useContext } from "react";
import { SettingsContext, MatrixComparison } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { SessionContext } from "../../contexts/SessionContext";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";

export function MatrixComparisonSelector(props: { enabledComparisons: MatrixComparison[] }) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const hasPlanningData = session.project?.uploadIdPlan !== undefined;
    const location = useLocation();

    const translationKeys = {
        [MatrixComparison.None]: "none",
        [MatrixComparison.Planning]: "planning",
        [MatrixComparison.AbsoluteDeviation]: "absoluteDeviation",
        [MatrixComparison.RelativeDeviation]: "relativeDeviation",
    };

    const options = props.enabledComparisons.filter(o => o === MatrixComparison.None || hasPlanningData).map(v => {
        return { label: i18n.t("kpiComparisons." + translationKeys[v]), value: v };
    });

    return <div className="section comparison">
        <div className="title">
            {i18n.t("common.kpiComparison")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["comparison"])} className="mls" />
        </div>

        <Dropdown
            options={options}
            value={{ value: settings.kpiMatrix.comparison, label: i18n.t("kpiComparisons." + translationKeys[settings.kpiMatrix.comparison]) }}
            onChange={(e) => {
                const comparison = e!.value as MatrixComparison;
                settings.mergeSet({
                    kpiMatrix: { comparison: comparison }
                });
            }}
        />
    </div>;
}