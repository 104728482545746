import { TrackEventParams } from "@jonkoops/matomo-tracker-react/lib/types";
import { SessionType } from "../../contexts/SessionContext";
import { RcaSettingsType, SettingsContext, SettingsContextType, getRecentRcaByType, updateRecentRcaByType } from "../../contexts/SettingsContext";
import { Datastores } from "../../utils/Datastores";
import { LegacyAnalyzedValues, RcaType } from "../../contexts/ContextTypes";
import { GroupingKeys } from "../../models/Dfg";
import { PostRootCauseAnalysisRequest, RcaDrilldownType, RcaRequestType, RcatypeTargets } from "../../models/ApiTypes";
import { NotificationService } from "../../components/notification/NotificationService";
import Global from "../../Global";
import { EventFilter } from "../../models/EventFilter";
import i18n from "../../i18n";
import React, { useContext, useState } from "react";
import { SortOrder } from "../../models/KpiTypes";
import { Api } from "../../api/Api";
import { defaultRcaIndicatorsWeights } from "../settings/ProjectSettingsView";

export const minCasesForAnalysis = Global.isRunningJestTest ? 5 : 25;
export const minCasesForRca = Global.isRunningJestTest ? 5 : 250;
export const maxProductsForRca = 20;

export type FormatterType = (value: number | undefined, locale: string) => string;

export async function submitRcaBySettings(session: SessionType, settings: SettingsContextType, rcaSettings: RcaSettingsType, trackEvent: undefined | ((params: TrackEventParams) => void)) {
    const { rcaType, drilldown, targetType, maximize, analyzedValue, resultsUrl, errorFormatter, sortOrder } = rcaSettings;

    const stats = await Datastores.statistics.get({
        eventFilters: rcaSettings.rcaFilters ?? [],
        eventKeys: session.project!.eventKeys!,
        uploadId: session.project!.uploadId!,
        uploads: session.project?.uploads,
    }, -1);

    return await submitRca(
        session,
        settings,
        rcaType,
        drilldown,
        targetType,
        maximize ?? false,
        analyzedValue ?? settings.kpi.analyzedValue,
        rcaSettings.rcaFilters ?? [],
        resultsUrl ?? "",
        stats.nCases,
        errorFormatter ?? (() => ""),
        sortOrder,
        trackEvent,
        // Should be false when we select the bottleneck analysis and all products
        (rcaType === RcaType.Bottleneck) && (session.project?.settings?.bottleneckType === "product"),
    );
}

export async function submitRca(
    session: SessionType,
    settings: SettingsContextType,
    type: RcaType,
    drilldown: RcaDrilldownType | undefined,
    targetType: RcatypeTargets,
    maximize: boolean,
    analyzedValue: LegacyAnalyzedValues,
    filters: EventFilter[],
    resultsUrl: string,
    numFilteredTraces: number,
    formatter: FormatterType,
    sortOrder: SortOrder | undefined,
    trackEvent: undefined | ((params: TrackEventParams) => void),
    useOrderSequence: boolean
) {
    const isDrilldown = (drilldown?.node !== undefined) || (drilldown?.reason !== undefined);

    trackEvent?.({
        category: "RCA",
        action: isDrilldown ? "drilldown started" : "started",
        name: type.toString(),
    });

    const eventlogs = {
        actual: {
            uploadId: session.project!.uploadId!,
            eventKeys: {
                ...session.project!.eventKeys!,
                activityKeysGroup: useOrderSequence ? GroupingKeys.PassValueStream : GroupingKeys.Machine,  // We always assume machine/workplace level for all RCAs
            },
            eventFilters: filters,
            uploads: session.project!.uploads!
        },
        planned: session.project?.eventKeysPlan !== undefined ? {
            uploadId: session.project!.uploadIdPlan!,
            eventKeys: session.project!.eventKeysPlan!,
        } : undefined,
        consolidatePasses: true,  // always consolidate passes for RCA as we are assuming machine/workplace level
        useActivityPasses: true  // always use activity passes as we do the same for dfg requests
    };

    const bottleneckFactorWeights = session.project?.settings?.bottleneckFactorWeights ?? defaultRcaIndicatorsWeights;

    // Only use the MI score analysis when there are not enough cases to execute an RCA,
    // and when we are analyzing for throughput times
    const shouldRunMIScore = numFilteredTraces < minCasesForRca;

    const isBottleneck = type === RcaType.Bottleneck;
    const isOrgLosses = type === RcaType.OrgLosses;

    let modelType = isOrgLosses ? "orglosses" : (shouldRunMIScore ? "miscore" : "xgbregressor");

    // Extra option for aggregating feature results for a certain RCA type
    if (isBottleneck)
        modelType = "bottleneckscore";

    const requestOptions: PostRootCauseAnalysisRequest = {
        eventsParams: {
            eventlogs
        },
        rcaType: {
            modelType: modelType as RcaRequestType["modelType"],
            targetType,
            drilldown: drilldown,
            options: {
                maximize,
                minCases: isBottleneck ? 2 : minCasesForAnalysis,
                targetPercentile: 0.25,
                shapAggregation: "meanAbs",
                shapWeighting: "positiveOnly",
                ...(isBottleneck ? { bottleneckFactorWeights } : {})
            },
        },
        name: shouldRunMIScore ? "miscore" : "rca",
    };

    const response = await Api.postRootCauseAnalysis(requestOptions);
    const state: Partial<RcaSettingsType> = {
        id: response.resultsId,
        alertUser: true,
        showResults: true,
        status: "started",
        sortOrder,
        maximize,
        quantity: settings.quantity as "mass" | "length" | "count",
        rcaType: type,
        analyzedValue,
        resultsUrl,
        targetType,
        rcaFilters: filters,
        result: undefined,
        errorFormatter: formatter,
    };

    updateRecentRcaByType(type, settings, state, true);

    NotificationService.add({
        id: "rca-started",
        className: "light success-accent",
        icon: "radix-clock",
        autoCloseDelay: 5000,
        summary: "rca.analysisStartedSummary",
        message: "rca.analysisStarted"
    });
}

export function StartRcaButton(props: {
    onClick: () => void | Promise<void>,
    disabled?: boolean,
    isInitializing?: boolean,
    title?: string,
    type: RcaType;
}) {
    const settings = useContext(SettingsContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    return <div className="buttons mtl gapSmall">
        <button disabled={props.disabled || props.isInitializing || isSubmitting} className="shortcutButton" onClick={async () => {
            setIsSubmitting(true);

            try {
                const r = props.onClick();
                if (r instanceof Promise)
                    await r;
            } catch (e) {
                // what to do here
            } finally {
                setIsSubmitting(false);
            }
        }}>
            {props.isInitializing && <div className="buttonSpinner">
                <div className="dot-pulse" />
            </div>}
            {i18n.t(props.title ?? "rca.startRCA")}
        </button>

        {getRecentRcaByType(props.type, settings).id !== undefined &&
            getRecentRcaByType(props.type, settings).status === "finished" && <button
            onClick={() => {
                updateRecentRcaByType(props.type, settings, {
                    showResults: true
                }, true);
            }}
            className="shortcutButton">
            {i18n.t("rca.showLastResults")}
        </button>}
    </div>;
}
