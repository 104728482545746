import { useContext, useMemo } from "react";
import { disableAllCalcOptions, PerTimeperiodStatisticsParams, TimePeriodFrequencies } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { ApiHookOptions, useApi } from "./UseApi";
import { LogTimeperiodStatisticsSchema, PerTimeperiodCaseStatisticsSchema, TimePeriod, TimeperiodCaseAggregationStatisticsSchema, TimeperiodCaseStatisticsSchema } from "../models/generated";

/**
 * Fetches time-aggregated case statistics. Returns a format that is shared between multiple hooks.
 */
export function useTimeAggregatedCaseStatistics(request: Partial<PerTimeperiodStatisticsParams> = {}, options?: ApiHookOptions<PerTimeperiodCaseStatisticsSchema> & {
    addEnergyStats?: boolean;
}): [AggregatedTimeperiodSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: PerTimeperiodStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        frequency: request.frequency ?? TimePeriodFrequencies.Month,
        tz: request.tz ?? session.timezone ?? "UTC",
        consolidatePasses: request.consolidatePasses ?? groupSupportsConsolidatePasses(request.eventKeys?.activityKeysGroup),
    };

    const [data, isLoading, hash] = useApi(Datastores.getTimeAggregatedCaseStatistics, requestOptions, [JSON.stringify(requestOptions)], {
        ...options,
        disable: options?.disable || !requestOptions.uploadId?.length,
    });

    const result = useMemo(() => {
        return data ? toAggregatedTimeperiodSchema(data) : undefined;
    }, [ data ]);

    return [result, isLoading, hash];
}

function toAggregatedTimeperiodSchema(data: PerTimeperiodCaseStatisticsSchema): AggregatedTimeperiodSchema {
    const actualMap = new Map<string, TimeperiodCaseAggregationStatisticsSchema>(data.timeperiods.map((t) => [t.timeperiodStartTime, t]));
    const plannedMap = new Map<string, TimeperiodCaseAggregationStatisticsSchema>(data.planned?.timeperiods.map((t) => [t.timeperiodStartTime, t]) ?? []);
    const deviationMap = new Map<string, TimeperiodCaseAggregationStatisticsSchema>(data.deviation?.timeperiods.map((t) => [t.timeperiodStartTime, t]) ?? []);

    const timestamps = Array.from(actualMap.keys());

    return {
        frequency: data.frequency,
        timeperiods: timestamps.map((timestamp) => {
            return {
                timeperiodStartTime: timestamp,
                actual: actualMap.get(timestamp),
                planned: plannedMap.get(timestamp),
                deviation: deviationMap.get(timestamp),
            } as AggregatedTimeperiodElementSchema;
        }),
        log: {
            actual: data.log,
            planned: data.planned?.log,
            deviation: data.deviation?.log,
        },
    };
}

export type AggregatedTimeperiodElementSchema = {
    timeperiodStartTime: string;
    actual?: TimeperiodCaseAggregationStatisticsSchema | TimeperiodCaseStatisticsSchema;
    planned?: TimeperiodCaseAggregationStatisticsSchema | TimeperiodCaseStatisticsSchema;
    deviation?: TimeperiodCaseAggregationStatisticsSchema | TimeperiodCaseStatisticsSchema;
}

/**
 * Some schema that can be used by multiple hooks
 */
export type AggregatedTimeperiodSchema = {
    frequency: TimePeriod;

    log: {
        actual?: LogTimeperiodStatisticsSchema;
        planned?: LogTimeperiodStatisticsSchema;
        deviation?: LogTimeperiodStatisticsSchema;
    },

    timeperiods: AggregatedTimeperiodElementSchema[];
}
