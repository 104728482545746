import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import colors from "../../colors.json";
import { DfGraphLayout, getLegendProps, IDfGraph, nodeHighlightColorMapDefault, ZoomControlLocations } from "../../components/dfg/DfGraph";
import { getNodeMarkupOutput, getNodeMarkupTimings } from "../../components/dfg/nodes/NodeMarkupFactory";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import { SelectionMode, VariantsList } from "../../components/variants-list/VariantsList";
import { getSortOrder, VariantsSortInput } from "../../components/variants-list/VariantsSortInput";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { defaultVariantsLimit } from "../../Global";
import { AnalysisType, calculateGraphDisplayArguments, useGraph } from "../../hooks/UseGraph";
import { useVariants } from "../../hooks/UseVariants";
import { Node } from "../../models/Dfg";
import { Datastores } from "../../utils/Datastores";
import { DfgUtils, getEdgeLabelText, getEdgeStat } from "../../utils/DfgUtils";
import { buildVariantFilter } from "../../utils/FilterBuilder";
import { getQuantity } from "../../utils/Quantities";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import { getCustomKpisDfg } from "../../utils/DfgUtils";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { noop } from "lodash";
import { disableAllCalcOptions, BaseQuantityType } from "../../models/ApiTypes";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";

type SortState = {
    sort: string;
    ascending: boolean;
    quantity: BaseQuantityType | undefined;
}

type DeltaState = {
    nodeIds: Set<string>;
    edgeIds: { [fromId: string]: Set<string> };
}

export interface IProcessVariants {
    fitGraph: () => void;
}

export const ProcessVariants = React.forwardRef((props: {
    analysisType: AnalysisType;
}, ref: React.Ref<IProcessVariants>) => {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const [sortState, setSortState] = useState<SortState>({
        sort: props.analysisType === AnalysisType.Output ? "rate" : "duration",
        ascending: props.analysisType === AnalysisType.Output ? false : true,
        quantity: settings.quantity,
    });

    if (sortState.quantity === undefined && settings.quantity !== undefined)
        setSortState({
            ...sortState,
            quantity: settings.quantity
        });
    const graphOptions = {
        ...getCustomKpisDfg(settings, session, false),
        ...calculateGraphDisplayArguments,
        calculateTimeAndFreqStats: true,
        calculateUnknownStats: true,
        useActivityPasses: true,
    };
    const graph = useGraph(graphOptions, AnalysisType.Variants, false);

    const graphRef = useRef<IDfGraph>(null);

    const [variants, isVariantsLoading] = useVariants({
        sort: getSortOrder(sortState.sort, sortState.ascending, getQuantity(sortState.quantity)),
        limit: defaultVariantsLimit,
    });

    useEffect(() => {
        // make sure that processPathSelectedIds only contains valid ids
        if (!isVariantsLoading && variants !== undefined) {
            const selectedIds = [];
            for (const selectedId of settings.processPath.processPathSelectedIds) {
                if (variants?.some((v) => v.id === selectedId))
                    selectedIds.push(selectedId);
            }
            settings.setProcessPath({ processPathSelectedIds: selectedIds });
        }
    }, [
        variants,
        settings.filters
    ]);

    const [subscriptionId] = useState(() => { return Datastores.dfg.getSubscriptionId(); });
    useEffect(() => { return () => { Datastores.dfg.cancelSubscription(subscriptionId); }; }, []);

    const [deltaState, setDeltaState] = useState<DeltaState | undefined>(undefined);

    const [isDeltaStateUpdating, setIsDeltaStateUpdating] = useState<boolean>(false);

    useEffect(() => {
        graphRef.current?.invalidate();
    }, [
        settings.quantity,
        settings.quantity,
        settings.kpi.analyzedValue,
    ]);

    // Expose "fitGraph"
    useImperativeHandle(ref, () => ({
        fitGraph() {
            queueMicrotask(() => {
                graphRef.current?.fitGraph();
            });
        },
    }));

    useEffect(() => {
        // Create filter that reduces data to the variants selected
        const variantsFilter = buildVariantFilter(settings.processPath.processPathSelectedIds, settings.groupingKey, false);
        if (!variantsFilter) {
            setDeltaState({
                edgeIds: {},
                nodeIds: new Set(),
            });
            graphRef.current?.invalidate();
            return;
        }

        // Update graph
        setIsDeltaStateUpdating(true);
        Datastores.dfg.get({
            eventFilters: (settings.previewFilters ?? settings.filters).concat([variantsFilter]),
            eventKeys: {
                ...session.project!.eventKeys!,
                activityKeysGroup: settings.groupingKey,
            },
            uploads: session.project?.uploads,
            uploadId: session.project!.uploadId!,
            ...disableAllCalcOptions,
            calculateEdges: true,
            calculateNodes: true,
        }, subscriptionId).then(response => {
            const variantsGraph = DfgUtils.preprocessGraph(response);
            const deltaState: DeltaState = {
                nodeIds: new Set(),
                edgeIds: {},
            };

            // Initialize hashmaps containing node- and edge ids
            for (const node of variantsGraph.nodes)
                deltaState.nodeIds.add(node.id);

            for (const edge of DfgUtils.mergeEdges(variantsGraph.edges)) {
                if (!deltaState.edgeIds[edge.from])
                    deltaState.edgeIds[edge.from] = new Set();
                deltaState.edgeIds[edge.from].add(edge.to);
            }

            setDeltaState(deltaState);
            graphRef.current?.invalidate();
        }).catch(noop).finally(() => {
            setIsDeltaStateUpdating(false);
        });
    }, [
        settings.processPath.processPathSelectedIds,
    ]);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    return <div className="defaultPage workflow contentPivot">
        <div className="processVariants">
            <div className="panel leftPanel">
                <VariantsSortInput
                    ascending={sortState.ascending}
                    quantity={sortState.quantity}
                    sort={sortState.sort}
                    onChanged={(sort, ascending, quantity) => {
                        setSortState({
                            sort,
                            ascending,
                            quantity,
                        });
                    }}
                />

                <VariantsList
                    selectionMode={SelectionMode.Multiple}
                    variants={variants}
                    isLoading={isVariantsLoading}
                    quantity={sortState.quantity}
                    selectedIds={settings.processPath.processPathSelectedIds}
                    onSelectionChanged={(ids) => {
                        settings.setProcessPath({
                            processPathSelectedIds: ids
                        });
                    }}
                />
                <Shortcuts stack={true} handledSelections={[ShortcutContexts.Variant]} />
            </div>

            <div className="panel rightPanel">
                <DfGraphLayout
                    zoomControlLocation={ZoomControlLocations.FarRight}
                    ref={graphRef}
                    highlightEdges={true}
                    highlightNodes={true}
                    isObjectCentric={isObjectCentric}
                    graph={graph}
                    showComplexitySlider={false}
                    legend={getLegendProps(settings.kpi.selectedKpi)}
                    edgeLabelFunc={getEdgeLabelText}
                    isLoading={graph === undefined || isDeltaStateUpdating}
                    edgeHighlightStatFunc={(multiEdge) => {
                        const isVariantEdge = deltaState?.edgeIds[multiEdge.from]?.has(multiEdge.to);
                        if (!isVariantEdge)
                            return undefined;
                        // in case highlighting is deactivated we just use the same stat value of zero for every edge
                        if (!settings.graph.highlight)
                            return 0;

                        // for start and end edges we just assign a value of zero
                        if (graph?.nodes && DfgUtils.isAfterStartOrBeforeEndEdge(multiEdge, graph?.nodes))
                            return 0;


                        return getEdgeStat(multiEdge, settings, session);

                    }}
                    edgeHighlightColorFunc={(multiEdge) => {
                        const isVariantEdge = deltaState?.edgeIds[multiEdge.from]?.has(multiEdge.to);

                        if (isVariantEdge) {
                            // we just always use blue as a color here because it may otherwise sometimes
                            // get hard to distinguish between the grey and light blue edges
                            return colors.$blue;
                        }

                        return colors["$gray-2"];
                    }}
                    nodeHighlightStatFunc={(node) => {
                        return getMainNodeStat(node, settings, session);
                    }}
                    nodeClassFunc={(node) => {
                        const isVariantNode = deltaState?.nodeIds.has(node.id);
                        if (isVariantNode)
                            return "variantNode";
                    }}
                    nodeHighlightColorFunc={(node, stat, minStatistic, maxStatistic, scale) => {
                        const isVariantNode = deltaState?.nodeIds.has(node.id);
                        if (isVariantNode)
                            if (settings.graph.highlight && scale !== undefined)
                                return nodeHighlightColorMapDefault(scale);
                        return colors["$white"];
                    }}
                    markupFunc={(node: Node, settings: SettingsType, session: SessionType) => {
                        if (props.analysisType === AnalysisType.Times)
                            return getNodeMarkupTimings(node, settings, session);
                        else
                            return getNodeMarkupOutput(node, settings, session);
                    }}
                />
                <DownloadDfgTrayElement graph={graphRef.current} filename={"workflows.processPath.processVariants"} />
                <BackButtonTrayElement />
            </div>
        </div>
    </div>;
});
