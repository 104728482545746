import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import Progress from "../progress/Progress";
import { ValueSpinner } from "../value-spinner/ValueSpinner";
import { StatsRowBaseProps } from "./StatsRowString";

export type StatsRowPercentBarProps = StatsRowBaseProps & {
    /**
     * Value
     */
    value?: number;

    /**
     * 0 => empty bar, of: full bar. Defaults to 1.
     */
    of?: number;

    /**
     * If omitted, the percent value will be used
     */
    barLabel?: string;
};

export function StatsRowPercentBar(props: StatsRowPercentBarProps) {
    const session = useContext(SessionContext);
    const of = props.of || 1;
    const percent = props.value !== undefined ? 100 * (props.value / of) : undefined;

    const isLoading = props.isLoading === true;
    const barLabel = props.barLabel ?? Formatter.units.percent.formatter(props.value, {
        locale: session.numberFormatLocale,
        of
    });

    return <div className="row">
        <div>
            {i18n.t(props.label)}
        </div>

        <ValueSpinner isLoading={isLoading} className="valueSpinnerProgressPlaceholder">
            <>
                {percent !== undefined && <Progress
                    label={barLabel}
                    percent={percent!} />}
            </>
        </ValueSpinner>
    </div>;
}