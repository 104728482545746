import React, { useContext, useEffect } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { CaseGanttOutputLabels } from "../../models/Gantt";
import { KpiTypes, SortOrder, StatisticTypes } from "../../models/KpiTypes";
import { getAssignedQuantities, Quantity, QuantityType } from "../../utils/Quantities";
import Dropdown from "../dropdown/Dropdown";
import { GraphDetailedControls } from "./GraphDetailedControls";
import { GroupingKeyControls, VisibilityOptions } from "./GroupingKeyControls";
import { BenchmarkingKpiSortBy, KpiSortOrderSwitch, QuantityDropdown } from "./KpiControlPrimitives";
import { ObjectControls } from "./ObjectControls";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId, classNames } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { KpiControls } from "./KpiControls";
import { TimeMode, CaseGanttSetting } from "../../models/ApiTypes";
import NodeSelectionControls from "./NodeSelectionControls";

export type ControlProps = {
    flavor: ControlFlavor;
    baseQuantities?: Quantity[];
    isValueStream?: boolean;
    orderSequences?: boolean;
}

export enum ControlFlavor {
    DfgControls,
    GanttControls,
    CaseGanttControls,
    CaseKpiControls,
    Hidden,
    OutputControls,
    QualityControls,
    ProductCategoryControls,
    OutputKpiControls,
    QualityKpiControls,
    EnergyControls,
    EnergyKpiControls,
    EnergyProductControls,
    StockControls,
    ValueStreamControls,
    TaktTimeControls
}

export const Controls = (props: ControlProps) => {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const location = useLocation();

    if (props.flavor === ControlFlavor.Hidden)
        return null;

    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);

    const caseGanttSortOptions = settings.gantt.timeMode === TimeMode.Absolute ? [...CaseGanttOutputLabels, { setting: CaseGanttSetting.StartTime, label: "common.startTime" }] : CaseGanttOutputLabels;

    useEffect(() => {
        if (caseGanttSortOptions.findIndex((c) => c.setting === settings.gantt.caseGanttSettings) === -1) {
            settings.setGanttState({
                caseGanttSettings: caseGanttSortOptions[0].setting
            });
        }
    }, [caseGanttSortOptions]);

    return <div className="controls">

        {props.flavor === ControlFlavor.TaktTimeControls && <KpiControls kpis={[KpiTypes.CycleTime]} hasYAxis={true} hideStatistics={[StatisticTypes.Median, StatisticTypes.Sum, StatisticTypes.Variance]} />}

        {[ControlFlavor.DfgControls, ControlFlavor.StockControls, ControlFlavor.OutputControls, ControlFlavor.QualityControls, ControlFlavor.EnergyControls, ControlFlavor.ValueStreamControls].includes(props.flavor) && <>
            <ObjectControls showContextSwitch={true} />
        </>}
        {[ControlFlavor.OutputKpiControls, ControlFlavor.QualityKpiControls, ControlFlavor.TaktTimeControls, ControlFlavor.EnergyKpiControls].includes(props.flavor) && <>
            <ObjectControls showContextSwitch={false} />
        </>}


        {[ControlFlavor.GanttControls, ControlFlavor.CaseGanttControls].includes(props.flavor) && <div className="section ganttTimeMode">
            <div className="title">
                {i18n.t("gantt.timeSequence")}
                <Spotlight id={buildControllerSpotlightId(location.pathname, ["time_sequence"])} className="mls" />
            </div>
            <div className="select">
                <Dropdown
                    value={{
                        value: settings.gantt.timeMode,
                        label: i18n.t("gantt." + settings.gantt.timeMode).toString(),
                    }}
                    data-testid={"selectTimeMode"}
                    onChange={(e) => {
                        settings.setGanttState({
                            timeMode: e?.value as TimeMode
                        });
                    }}
                    options={
                        Object.values(TimeMode).map((option) => {
                            return {
                                value: option,
                                label: i18n.t("gantt." + option).toString(),
                            };
                        })} />
            </div>
            {settings.gantt.timeMode === TimeMode.Absolute && <div className={classNames(["restrictToTimeFilter", "checkboxLabel", "mtLarge"])}>
                <label>
                    <input type="checkbox" id="checkbox-restrictToTimeFilter" className="checkbox" checked={settings.gantt.restrictToTimeFilter} onChange={(e) => { settings.setGanttState({ restrictToTimeFilter: e.target.checked }); }} /><label htmlFor="checkbox-restrictToTimeFilter" />
                    {i18n.t("gantt.restrictToTimeFilter")}
                </label>
            </div>}
        </div>}


        {props.flavor === ControlFlavor.CaseGanttControls && settings.gantt.caseGanttSettings !== CaseGanttSetting.Duration && <QuantityDropdown quantities={quantities} />}

        {props.flavor !== ControlFlavor.CaseKpiControls &&
            props.flavor !== ControlFlavor.EnergyProductControls && <GroupingKeyControls
            options={{
                none: VisibilityOptions.Hidden,
                location: [ControlFlavor.ValueStreamControls, ControlFlavor.TaktTimeControls].includes(props.flavor) ? VisibilityOptions.Hidden : undefined,
                objectType: [ControlFlavor.GanttControls, ControlFlavor.CaseGanttControls, ControlFlavor.TaktTimeControls].includes(props.flavor) ? VisibilityOptions.Hidden : undefined,
                machineType: props.flavor === ControlFlavor.TaktTimeControls ? VisibilityOptions.Hidden : undefined,
            }}
            secondaryGrouping={[ControlFlavor.DfgControls, ControlFlavor.OutputControls, ControlFlavor.StockControls, ControlFlavor.QualityControls, ControlFlavor.EnergyControls, ControlFlavor.ValueStreamControls].includes(props.flavor) ? VisibilityOptions.Visible : VisibilityOptions.Hidden}
            isXAxisLabel={[ControlFlavor.OutputKpiControls, ControlFlavor.QualityKpiControls, ControlFlavor.EnergyKpiControls].includes(props.flavor)}
        />}

        {props.flavor === ControlFlavor.CaseGanttControls && <NodeSelectionControls />}

        {props.flavor === ControlFlavor.CaseGanttControls && <div className="section caseGanttSort">
            <div className="title">
                {i18n.t("explorer.sortOrderBy")}
                <Spotlight id={buildControllerSpotlightId(location.pathname, ["SortBy"])} className="mls" />
            </div>
            {quantities.length > 0 && <div className="select">
                <Dropdown
                    value={{
                        value: settings.gantt.caseGanttSettings,
                        label: i18n.t(caseGanttSortOptions.find((c) => c.setting === settings.gantt.caseGanttSettings)?.label ?? "").toString(),
                    }}
                    onChange={(e) => {
                        settings.setGanttState({
                            caseGanttSettings: e?.value as CaseGanttSetting
                        });
                    }}
                    options={
                        caseGanttSortOptions.map((s) => {
                            return {
                                value: s.setting,
                                label: i18n.t(s.label).toString(),
                            };
                        })} />
            </div>}

            <div className="buttons-2col mtLarge">
                <button id="button-ascending" className={settings.gantt.sortOrder === SortOrder.Ascending ? "active" : undefined} onClick={() => settings.setGanttState({ sortOrder: SortOrder.Ascending })}>{i18n.t("explorer.ascending")}</button>
                <button id="button-descending" className={settings.gantt.sortOrder === SortOrder.Descending ? "active" : undefined} onClick={() => settings.setGanttState({ sortOrder: SortOrder.Descending })}>{i18n.t("explorer.descending")}</button>
            </div>
        </div>}

        {[ControlFlavor.CaseKpiControls, ControlFlavor.OutputKpiControls, ControlFlavor.QualityKpiControls, ControlFlavor.EnergyProductControls].includes(props.flavor) && <KpiSortOrderSwitch />}

        {[ControlFlavor.DfgControls, ControlFlavor.OutputControls, ControlFlavor.StockControls, ControlFlavor.QualityControls].includes(props.flavor) && <GraphDetailedControls />}

        {[ControlFlavor.EnergyKpiControls].includes(props.flavor) && <BenchmarkingKpiSortBy spotlightId={buildControllerSpotlightId(location.pathname, ["SortBy"])} isValueStream={props.isValueStream} enableOrderSequencesSorting={props.orderSequences} />}
    </div>;

};
