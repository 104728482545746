import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Column } from "react-table";
import CustomTable, { PaginationModes } from "../../components/custom-table/CustomTable";
import { GroupButton } from "../../components/group-button/GroupButton";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { Upload } from "../../models/Upload";
import { Datastores } from "../../utils/Datastores";
import { Formatter } from "../../utils/Formatter";

export type MappingStateType = {
    name: string;
    colIdx?: number;
    key: string;
    isOptional: boolean;
    allowedTypes: string[];
    group?: string;
    isSelectable?: boolean;
};

type AssignColumnsPropsType = {
    mappingStates: MappingStateType[],
    upload: Upload,
    onUpdate: (mappingStates: MappingStateType[]) => void,
}

export default function AssignColumns(props: AssignColumnsPropsType) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const tableRef = useRef<HTMLDivElement>(null);
    const [draw, redraw] = useState<number>(0);

    const [preview, setPreview] = useState<any[]>([]);

    const [currentMappingState, setCurrentMappingState] = useState<MappingStateType | undefined>(props.mappingStates.find(c => c.colIdx === undefined && c.isSelectable !== false));

    const container = useRef<HTMLDivElement>(null);

    const [containerSize, setContainerSize] = useState<{width: number, height: number} | undefined>(undefined);

    // Register observer in case the user has not specified width & height
    const observerRef = useRef(new ResizeObserver(e => {
        const { width, height } = e[0].contentRect;
        setContainerSize({ width, height });
    }));

    useEffect(() => {
        if (container.current)
            observerRef.current.observe(container.current);

        return () => {
            if (observerRef.current)
                observerRef.current.disconnect();
        };
    }, [
        container,
        observerRef,
    ]);

    const [subscriptionId, setSubscriptionId] = useState<number>(0);
    useEffect(() => {
        const subscriptionId = Datastores.getUploadRawEvents.getSubscriptionId();
        setSubscriptionId(subscriptionId);

        return () => {
            Datastores.getUploadRawEvents.cancelSubscription(subscriptionId);
        };
    }, []);

    // Fetch preview data
    useEffect(() => {
        Datastores.getUploadRawEvents.get({
            id: props.upload.id,
            limit: 10,
            offset: 0
        }, subscriptionId).then((result) => {
            setPreview(result);
        });
    }, [props.upload, subscriptionId, settings.apiRetry]);

    // Apply formatting
    const previewColumns = preview.length ? Object.keys(preview[0]) : undefined;
    
    const [formattedPreview, setFormattedPreview] = useState<any[]>([]);
    
    useEffect(() => {
        if (!preview || !props.upload.meta.attributes || !previewColumns?.length)
            return;

        const result: any[] = cloneDeep(preview);

        for (const c of previewColumns!) {
            const attribute = !props.upload ? undefined : props.upload.meta.attributes.find(a => a.name == c);

            if (attribute && attribute.type === "float")
                for (const data of result) {
                    data[attribute.name] = Formatter.formatNumber(+data[attribute.name], 2, session.numberFormatLocale);
                }
        }

        setFormattedPreview(result);
    }, [
        preview,
        session.numberFormatLocale,
        props.upload.meta.attributes,
    ]);

    const columns: Column<any>[] = useMemo(() => {
        if (!previewColumns?.length)
            return [];

        return previewColumns.map((c, idx) => {
            const attribute = !props.upload ? undefined : props.upload.meta.attributes.find(a => a.name == c);

            return {
                id: c,
                Header: c + (attribute ? " (" + attribute.type + ")" : ""),
                // we are using `(d) => d[c]` instead of just `c` here
                // to also display column labels with dots in them (see https://github.com/tannerlinsley/react-table/issues/3004)
                accessor: attribute?.type === "boolean" ? ((d) => d[c] !== undefined && d[c] !== null ? i18n.t(`common.${d[c].toString()}`) : d[c]) : (d) => d[c],
                className: props.mappingStates.some(m => m.colIdx === idx) ? "assigned" : "",
                attribute
            };
        });
    }, [
        preview, 
        props.mappingStates, 
        containerSize, 
        props.upload,
    ]);
    const tableRows = tableRef.current?.getElementsByTagName("tr");
    const tableRow = tableRows ? tableRows[0] : undefined;

    const tableContainer = tableRef.current?.getElementsByClassName("customTable")[0];
    const headerRect = tableRef.current ? tableRef.current.getBoundingClientRect() : undefined;
    const coverHeight = tableContainer?.clientHeight ?? headerRect?.height;

    useEffect(() => {
        redraw(draw + 1);
    }, [tableRef.current, preview?.length]);

    // Generate the covers that are overlayed above a table column once it is assigned.
    // Dimensions are messed up, because it's rotated, and rotations are none of strong
    // sides of CSS.
    const covers: JSX.Element[] = tableRow ? props.mappingStates.filter(m => m.colIdx !== undefined).map(m => {
        const clientRect = tableRow.children[m.colIdx!].getBoundingClientRect();
        return (
            <div key={m.key} className="cover" style={{
                left: Math.round(clientRect.left - (headerRect?.left ?? 0)) + 1,
                height: tableRow.children[m.colIdx!].clientWidth - 2,
                width: coverHeight,
                margin: coverHeight + "px 0"
            }}>
                {i18n.t(m.name)}
            </div>
        );
    }) : [];

    return <div className="assignColumns" ref={container}>

        <div className="spacer">
            {(props.mappingStates || []).map((_, idx) => getButtonForMappingState(idx, props.mappingStates || []))}
        </div>

        {
            !!preview?.length && <div
                ref={tableRef}
                className="rawPreview">
                {covers}
                <CustomTable
                    onScroll={() => {
                        redraw(draw + 1);
                    }}
                    onMouseOver={({ column, columnIdx }) => {
                        if (!tableRef.current)
                            return;

                        const type = (column as any).attribute?.type;
                        const allowClick = type && (currentMappingState?.allowedTypes || []).some(t => type === t);

                        if (allowClick) {
                            const tableRows = tableRef.current.getElementsByTagName("tr");
                            for (const row of Array.from(tableRows))
                                row.children[columnIdx].classList.add("selected");
                        }
                    }}
                    onMouseOut={({ columnIdx }) => {
                        if (!tableRef.current)
                            return;

                        const tableRows = tableRef.current.getElementsByTagName("tr");
                        for (const row of Array.from(tableRows))
                            row.children[columnIdx].classList.remove("selected");
                    }}
                    onClick={({ column, columnIdx }) => {
                        const type = (column as any).attribute?.type;
                        const allowClick = type && (currentMappingState?.allowedTypes || []).some(t => type === t);

                        if (!allowClick)
                            return;

                        const newState = props.mappingStates.map(c => {
                            return c.key === currentMappingState!.key ? {
                                ...c,
                                colIdx: columnIdx,
                            } : c;
                        });

                        props.onUpdate(newState);

                        goToNextMappingState();
                    }}
                    data={formattedPreview ?? []}
                    columns={columns}
                    paginationMode={PaginationModes.Fixed}
                    pageSize={10}
                    className="spacer"

                /></div>
        }
    </div>;

    function goToNextMappingState() {
        if (currentMappingState?.group) {
            // Groups are last and usually optional, so we're not proceeding from here
            setCurrentMappingState(undefined);
            return;
        }

        const currentIx = props.mappingStates.findIndex(c => c.key === currentMappingState!.key);
        setCurrentMappingState(props.mappingStates.find((c, ix) => (ix > currentIx) && (c.colIdx === undefined)));
    }

    function getButtonForMappingState(idx: number, states: MappingStateType[], renderGroups = true) {
        const s = states[idx];
        const disabled = s.isSelectable !== undefined ? !s.isSelectable : false;
        const isGroup = s.group !== undefined;

        if (isGroup) {
            if (renderGroups && states.findIndex(x => x.group === s.group) < idx) 
                // Group has already been handled
                return;

            const members = (props.mappingStates || []).map((s, idx) => { return {state: s, idx}; }).filter(x => x.state.group === s.group);

            // This is the first element of the group. Return group button!
            if (renderGroups)
                return <GroupButton 
                    className={(props.mappingStates || []).some(m => m.group === s.group && currentMappingState?.name === m.name) ? "selected" :
                        (props.mappingStates || []).some(m => m.group === s.group && m.colIdx !== undefined) ? "assigned" : undefined}
                    key={s.group} 
                    title={i18n.t(s.group ?? "")}>
                    {members.map(e => getButtonForMappingState(e.idx, props.mappingStates ?? [], false))}
                </GroupButton>;
        }

        const className = currentMappingState?.key === s.key ? "headerButton selected" :
            s.colIdx !== undefined ? "headerButton assigned" : "headerButton";
        return <button
            key={s.key}
            disabled={disabled}
            title={i18n.t(disabled ? "identifyColumns.buttonDisabled" : s.name).toString()}
            className={className}
            onClick={() => {
                // Check if unassigned, and select if so
                if (s.colIdx === undefined)
                    setCurrentMappingState(s);
            }}>
            {i18n.t(s.name)}
            {s.colIdx !== undefined && <svg
                onClick={() => {
                    const newState = props.mappingStates.map(c => {
                        return c.key === s.key ? {
                            ...c,
                            colIdx: undefined
                        } : c;
                    });

                    props.onUpdate(newState);
                    setCurrentMappingState(newState.find(c => c.key === s.key));
                }}
                className="svg-icon tiny close brandHover">
                <use xlinkHref="#radix-cross-1" />
            </svg>}
        </button>;
    }
}
