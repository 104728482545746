import React, { Fragment } from "react";

export type JsxTemplateStringProps = {
    /**
     * Text template with placeholders for the values of the mapping. Use this or set
     * an i18n key in the id property.
     */
    template: string;

    /**
     * {Placeholders} in the template will be replaced with the JSX element of this mapping object
     */
    mapping: {[id: string]: JSX.Element};
}

export function JsxTemplateString(props: JsxTemplateStringProps) {
    const rx = new RegExp("{(\\w+)}", "gi");

    const parts: (JSX.Element | string)[] = [];

    const matches = props.template.matchAll(rx);
    
    let lastEnd = 0;
    for (const match of matches) {
        const id = match[1];
        const idx = match.index ?? 0;
        const length = match[0].length;

        const before = props.template.substring(lastEnd, idx);
        if (before)
            parts.push(<Fragment key={`from-${idx}`}>{before}</Fragment>);

        const jsx = props.mapping[id];
        parts.push(<Fragment key={`fragment-${id}`}>{jsx}</Fragment>);

        lastEnd = idx + length;
    }

    parts.push(<Fragment key={"to-end"}>{props.template.substring(lastEnd)}</Fragment>);

    return <Fragment key="result">{parts}</Fragment>;
}