import React, { useContext } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SideStatisticsCaseDeviation } from "./SideStatisticsCaseDeviation";
import { SideStatisticsProductDeviation } from "./SideStatisticsProductDeviation";

export function SideStatisticsProductCaseDeviation() {
    const settings = useContext(SettingsContext);

    if (settings.selection.product !== undefined)
        return <SideStatisticsProductDeviation />;

    if (settings.selection.case !== undefined)
        return <SideStatisticsCaseDeviation />;

    return null;
}
