export class Day {
    constructor(public year: number, public month: number, public day: number) { }

    static daysInMonth(year: number, month: number) {
        return new Date(year, month, 0).getDate();
    }

    nextDay() {
        this.day++;
        if (this.day > Day.daysInMonth(this.year, this.month)) {
            this.day = 1;
            return this.nextMonth();
        }

        return this;
    }

    nextMonth() {
        this.month++;

        if (this.month > 12) {
            this.month = 1;
            this.year++;
        }

        return this;
    }

    toString() {
        return `${this.year.toString().padStart(4, "0")}-${this.month.toString().padStart(2, "0")}-${this.day.toString().padStart(2, "0")}T00:00:00`;
    }

    toDate() {
        return new Date(this.toString());
    }
}