/**
 * Display the configuration of a single duration filter element.
 * Duration filters check the duration of an event trace, and only
 * let it pass if the duration falls into the interval range specified.
 */
import React, { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";
import { Formatter } from "../../../utils/Formatter";

type DurationFilterStatusPropsType = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function DurationFilterStatus(props: DurationFilterStatusPropsType): JSX.Element | null {
    const session = useContext(SessionContext);
    const e = props.filter?.caseDuration;
    if (e?.ge === undefined && e?.lt === undefined)
        return null;

    let label = "";
    if (e?.ge === undefined)
        label = "< " + Formatter.formatDurationShort(e?.lt, undefined, session.numberFormatLocale);
    else
    if (e?.lt === undefined)
        label = "> " + Formatter.formatDurationShort(e?.ge, undefined, session.numberFormatLocale);
    else
        label = Formatter.formatDurationShort(e?.ge, undefined, session.numberFormatLocale) + " - " + Formatter.formatDurationShort(e?.lt, undefined, session.numberFormatLocale);


    return <div className={"filter durationFilter" + (props.isEdited ? " edit" : "")} onClick={onClick}>
        <svg className="svg-icon small"><use xlinkHref="#radix-clock" /></svg>
        <div>
            {i18n.t("filters.duration")} <br />
            <b>{label}</b>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;

    function onClick() {
        if (props.onClick)
            props.onClick();
    }
}