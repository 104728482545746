import { debounce } from "lodash";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { SessionContext } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import { Card, CardInfo } from "./Card";
import { Sequence } from "./Sequence";
import { classNames } from "../../utils/Utils";

interface ISequence {
    add: (element: CardInfo) => void,
    clear: () => void,
    getState: () => CardInfo[] | undefined;
}

export enum CollectionTypes {
    Sequence,
    Set,
    Single
}

export enum Orientations {
    Horizontal,
    Vertical,
}

export type SearchResults = {
    matches: CardInfo[],
    hasMoreData: boolean,
}

type DropzonePropsType = {
    sequenceHeader?: JSX.Element | JSX.Element[] | undefined;

    /**
     * Optional search title above the element selection
     */
    searchTitle?: string;

    /**
     * Placeholder of the element selection filter input
     */
    searchPlaceholderLabel: string;

    /**
     * Label above the drop zone, optional
     */
    dropzoneLabel?: string;

    /**
     * Search callback
     */
    onSearch: (parts: string[]) => SearchResults;

    /**
     * Drop already filtered search results. 
     * If set to true the filtered results do not show up again in the list on the left.
     */
    dropFilteredResults?: boolean;

    /**
     * Change callback
     */
    onChange: (elements: CardInfo[]) => void;

    /**
     * Initially selected values
     */
    initialValue: CardInfo[];

    /**
     * Total count of elements
     */
    totalCount?: number;

    /**
     * If false, no search bar result counts will be shown
     */
    showResultCount?: boolean;

    /**
     * Maximum number of displayed elements. If you provide more elements, the component
     * will display something like "there are more elements that aren't displayed".
     */
    maxResults?: number;

    /**
     * What kind of collection are we manipulating here? An unordered set, a sequence or
     * a single element?
     */
    collectionType: CollectionTypes;

    /**
     * Orientation of the drop zone. If horizontal, the selection and drop zone are
     * stacked on top of each other, otherwise aligned from left to right
     */
    orientation?: Orientations;

    className?: string;
};

export interface IDropzone {
    reset: () => void,
    dataUpdated: () => void,
    getState: () => CardInfo[] | undefined;
}

export const Dropzone = React.forwardRef((props: DropzonePropsType, ref: React.Ref<IDropzone>) => {
    const sequenceRef = useRef<ISequence>(null);
    const searchQueryInputRef = useRef<HTMLInputElement>(null);
    const session = useContext(SessionContext);

    const [filteredItems, setFilteredItems] = useState<CardInfo[]>([]);
    const initialValue = (props.initialValue ?? []).map(e => {
        return {
            ...e,
            id: !e.id ? e.label + Math.random().toString() : e.id,
        };
    });

    /**
     * We have 3 places where data is stored:
     *  - At the backend: This is the ground truth, it knows all there is, and that might be a lot
     *  - In the model: This is what the client retrieved via API. It might fetch just a fraction
     *    of the entire data and page stuff in as needed.
     *  - The dropzone: As creating DOM elements is very expensive, we just want to do this for
     *    elements in view. The component might decide to display less than what is in the model.
     *
     * So the backend knows more than the model, and the model is bigger than what's displayed.
     */
    const [backendHasMoreData, setBackendHasMoreData] = useState<boolean>(false);
    const [modelHasMoreData, setModelHasMoreData] = useState<boolean>(false);

    const currentSequence = sequenceRef.current?.getState() ?? props.initialValue;

    const debounceSearch = debounce((query: string) => {
        search(query);
    }, 150);

    useEffect(() => {
        // Pre-populate results list
        if (searchQueryInputRef.current?.value !== undefined)
            search(searchQueryInputRef.current?.value);
    }, [JSON.stringify(currentSequence)]);

    // Expose "reset"
    useImperativeHandle(ref, () => ({
        reset() {
            reset();
        },

        dataUpdated() {
            search(searchQueryInputRef.current?.value ?? "");
        },

        getState() {
            return sequenceRef.current?.getState();
        }
    }));

    return <div className={classNames(["dropzone", props.orientation === Orientations.Horizontal ? "horizontal" : "vertical", props.className])}>
        <DndProvider options={HTML5toTouch}>
            <div className="selectionContainer">
                <div className="selection">
                    <div>
                        {props.searchTitle && <h3>
                            {props.searchTitle}
                        </h3>}
                        {props.showResultCount !== false && !backendHasMoreData && <div className="filterCount">({Formatter.formatNumber(filteredItems.length, 2, session.numberFormatLocale)}
                            {props.totalCount !== undefined && <> / {Formatter.formatNumber(props.totalCount, 2, session.numberFormatLocale)}</>}
                            )</div>}
                        <input className="search" data-testid="search" ref={searchQueryInputRef} onChange={(e) => { debounceSearch(e.target.value); }} type="text" placeholder={props.searchPlaceholderLabel} />
                    </div>
                </div>
                <div className="resultsContainer" data-testid="resultsContainer">
                    <div className="results">
                        {filteredItems.map((e) => <Card
                            id={e.id}
                            index={undefined}
                            key={e.id}
                            label={e.label}
                            data={e.data}
                        />)}

                        {(backendHasMoreData || modelHasMoreData) && <div className="hasMoreData">
                            {i18n.t("common.hasMoreData")}
                        </div>}
                    </div>
                </div>
            </div>

            <div className={classNames(["sequenceContainer", props.collectionType === CollectionTypes.Single ? "sequenceContainerSingle" : "sequenceContainerMultiple"])}>
                {props.dropzoneLabel && <h3>{props.dropzoneLabel}</h3>}

                <div className="sequenceHeader">
                    {props.sequenceHeader}
                </div>

                <div className="dropTarget">
                    <div className={classNames([
                        "backdrop",
                        props.collectionType === CollectionTypes.Sequence && "backdropSequence",
                        props.collectionType === CollectionTypes.Set && "backdropSet",
                        props.collectionType === CollectionTypes.Single && " backdropSingle"
                    ])}>

                        <Sequence
                            ref={sequenceRef}
                            collectionType={props.collectionType}
                            onUpdate={props.onChange}
                            initialValue={initialValue} />
                    </div>
                </div>
            </div>

        </DndProvider>
    </div>;

    function reset() {
        if (sequenceRef.current)
            sequenceRef.current.clear();

        if (searchQueryInputRef.current)
            searchQueryInputRef.current.value = "";

        search("");
    }

    function search(query: string) {
        const parts = query.split(" ").map(m => m.trim().toLocaleLowerCase()).filter(m => m.length > 0);
        const result = props.onSearch(parts);

        if (result === undefined)
            return;

        const maxResults = props.maxResults ?? 500;
        const _modelHasMoreData = result.matches.length > maxResults;
        const filteredResults = result.matches
            .filter((m, idx) => idx < maxResults)
            // filter out already filtered results
            .filter(r => !props.dropFilteredResults || !(currentSequence?.map(c => c.id).includes(r.id)));
        setFilteredItems(filteredResults.map(r => {
            return {
                ...r,
                id: !r.id ? r.label + Math.random() : r.id,
            };
        }));
        setBackendHasMoreData(result.hasMoreData);
        setModelHasMoreData(_modelHasMoreData);
    }
});
