import React from "react";
import { TrayIconElement } from "./TrayElement";
import i18n from "../../i18n";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { IDfGraph } from "../dfg/DfGraph";

export function DownloadDfgTrayElement(props: {
    graph: IDfGraph | null;
    filename: string;
    allowed?: boolean;
}) {
    const { trackEvent } = useMatomo();

    return <TrayIconElement
        title="common.downloadDfg"
        icon="radix-download"
        testId="downloadTooltip"
        id="downloadTooltip"
        onClick={() => {
            if (!props.graph || props.allowed === false)
                return;

            const filename = i18n.t(props.filename).toString() + ".html";
            props.graph.save(filename);

            trackEvent({
                category: "Interactions",
                action: "Download Graph",
                name: new URL(window.location.href).pathname,
            });
        }}
    />;
}