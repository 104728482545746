import React from "react";
import i18n from "../../i18n";

export type DfgLegendProps = React.StyleHTMLAttributes<HTMLDivElement> & {
    leftLabel?: string;
    rightLabel?: string;
    colormap?: (idx: number) => string;
    leftColormap?: (idx: number) => string;
    rightColormap?: (idx: number) => string;
    showUnplanned?: boolean;
};

export function DfgLegend(props: DfgLegendProps) {
    return <div className="dfgLegendContainer" style={props.style}>
        {props.showUnplanned === true && <div className="dfgLegendUnplanned">
            <div>{i18n.t("workflows.planningDeviation.unplanned")}</div>
            <div className="dfgLegendUnplannedColorbox" />
        </div>}

        {props.leftLabel && props.rightLabel && <div className="dfgLegend">
            <div className="left">
                <svg className="svg-icon small fonticon"><use xlinkHref="#radix-triangle-left" /></svg>
                {i18n.t(props.leftLabel ?? "")}
            </div>
            <div className="right">
                {i18n.t(props.rightLabel ?? "")}
                <svg className="svg-icon small fonticon"><use xlinkHref="#radix-triangle-right" /></svg>
            </div>

            {props.colormap !== undefined && <>
                <div className="colorboxContainer">
                    <div className="colorbox">
                        <div className="gradient" style={{
                            background: `linear-gradient(90deg, ${props.colormap(0).toString()} 0%, ${props.colormap(0.5).toString()} 100%)`,
                        }}></div>
                    </div>
                    <div className="colorbox">
                        <div className="gradient" style={{
                            background: `linear-gradient(90deg, ${props.colormap(0.5).toString()} 0%, ${props.colormap(1).toString()} 100%)`,
                        }}></div>
                    </div>
                </div>
            </>}

            {props.leftColormap !== undefined && props.rightColormap !== undefined && <>
                <div className="colorboxContainer">
                    <div className="colorbox br">
                        <div className="gradient" style={{
                            background: `linear-gradient(90deg, ${props.leftColormap(1).toString()} 0%, ${props.leftColormap(0).toString()} 100%)`,
                        }}></div>
                    </div>
                    <div className="colorbox">
                        <div className="gradient" style={{
                            background: `linear-gradient(90deg, ${props.rightColormap(0).toString()} 0%, ${props.rightColormap(1).toString()} 100%)`,
                        }}></div>
                    </div>
                </div>
            </>}
        </div>}
    </div>;
}