import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { BaseQuantityType, Variant } from "../../../../models/ApiTypes";
import colors from "../../../../colors.json";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { defaultVariantsLimit } from "../../../../Global";
import { useResizeObserver } from "../../../../hooks/UseResizeObserver";
import { useVariants } from "../../../../hooks/UseVariants";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { Formatter } from "../../../../utils/Formatter";
import { apiToGroupingKey, groupingKeyToApi, groupSupportsConsolidatePasses } from "../../../../utils/GroupingUtils";
import { getAssignedQuantities, QuantityType } from "../../../../utils/Quantities";
import { Gauge } from "../../../gauge/Gauge";
import Toast, { ToastTypes } from "../../../toast/Toast";
import { VariantsList } from "../../../variants-list/VariantsList";
import { getSortOrder, VariantsSortInput } from "../../../variants-list/VariantsSortInput";
import { IVariantTable } from "../../../variants-list/VariantTable";

export type VariantFilterEditorProps = {
    initialValue: EventFilter | undefined;
    onUpdate?: (e: EventFilter | undefined) => void;
};

export function VariantFilterEditor(props: VariantFilterEditorProps): JSX.Element {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);

    const [excludeSelection, setExcludeSelection] = useState<boolean>(!!props.initialValue?.variant?.ne);

    const [sort, setSort] = useState<string>(props.initialValue?.variant?.sortBy ?? "count");
    const [ascending, setAscending] = useState<boolean>(props.initialValue?.variant?.sortAscending ?? false);

    const [quantity, setQuantity] = useState<BaseQuantityType>(props.initialValue?.variant?.quantity ?? (settings.quantity ? settings.quantity : undefined) ?? caseYieldQuantities[0]?.id as BaseQuantityType ?? "count");

    const eventFilters = (settings.filters ?? []).filter((_, idx) => idx !== settings.filterEditor.editFilterIndex!);

    const [variants, isVariantsLoading] = useVariants({
        eventFilters,
        limit: defaultVariantsLimit,
        sort: getSortOrder(sort, ascending, caseYieldQuantities.length === 1 ? caseYieldQuantities[0] : caseYieldQuantities.find(q => q.id === quantity)),
    },
    apiToGroupingKey(props.initialValue?.variant?.activityKeysGroup));

    const [allVariants, isAllVariantsLoading] = useVariants({
        eventFilters: [],
        limit: defaultVariantsLimit,
        sort: getSortOrder("count", false, undefined),
    }, apiToGroupingKey(props.initialValue?.variant?.activityKeysGroup));

    const idToVariantLookup = useMemo(() => {
        const map = new Map<string, Variant>();
        for (const v of variants ?? [])
            map.set(v.id, v);

        return map;
    }, [
        variants
    ]);

    // Total number of cases that are covered with the variants going into this filter
    const numVariantCases = useMemo(() => {
        let result = 0;
        (variants ?? []).forEach(v => {
            result += v.count;
        });

        return result;
    }, [
        variants
    ]);

    // Invalidate table if variants changed
    useEffect(() => {
        queueMicrotask(() => {
            tableRef.current?.invalidate();
        });
    }, [
        variants
    ]);

    const [selectedIds, setSelectedIds] = useState<string[]>(props.initialValue?.variant?.eq ?? props.initialValue?.variant?.ne ?? []);

    // Determine which variants are selected but removed by other filters
    const [hiddenVariants, setHiddenVariants] = useState<Variant[]>([]);
    useEffect(() => {
        if (allVariants !== undefined && variants !== undefined) {
            // find selected ids that are not in variants, but in allVariants
            const hiddenVariantIds = selectedIds.filter(id => variants.find(v => v.id === id) === undefined);
            const hiddenVariants = hiddenVariantIds.map(id => allVariants.find(v => v.id === id)).filter(v => v !== undefined) as Variant[];
            setHiddenVariants(hiddenVariants);
        }
    }, [
        allVariants,
        variants,
        selectedIds
    ]);

    // Delete list of selected variants in case the grouping is changed
    useEffect(() => {
        // If we're editing an existing filter, don't update the grouping
        if (props.initialValue?.variant !== undefined)
            return;

        // Content size is set only after initialization, and we don't want to remove the
        // initialValue, only because the component receives it's initial GroupingKey.
        if (listContainerSize !== undefined)
            setSelectedIds([]);
    }, [
        settings.groupingKey,
    ]);

    // Size handling
    const tableRef = useRef<IVariantTable>(null);

    const listContainerRef = useRef<HTMLDivElement>(null);
    const listContainerSize = useResizeObserver(listContainerRef);

    const buttonsContainerRef = useRef<HTMLDivElement>(null);
    useResizeObserver(buttonsContainerRef);

    // Number of variants that the user clicked on and thus, are marked green.
    // Variants, that are selected, but not displayed, are not considered here.
    const numSelectedVariants = selectedIds.length > 0 ? selectedIds.filter(id => idToVariantLookup.has(id)).length : variants?.length;
    const numSelectedCases = selectedIds.length === 0 ? numVariantCases : [0, ...(selectedIds.map(id => idToVariantLookup.get(id)?.count).filter(c => c !== undefined) as number[])].reduce((total: number, cnt: number) => total + cnt);

    // Number of selected variants that were removed by other filters
    const numRemovedSelection = selectedIds.filter(id => !idToVariantLookup.has(id)).length;

    const numFilteredCases = excludeSelection && selectedIds.length > 0 ? numVariantCases - numSelectedCases : numSelectedCases;
    const numFilteredVariants = excludeSelection && selectedIds.length > 0 ? (variants?.length ?? 0) - (numSelectedVariants ?? 0) : numSelectedVariants;

    useEffect(() => {
        filterChanged(selectedIds, excludeSelection, sort, ascending, quantity);
    }, [
        selectedIds,
        excludeSelection,
        sort,
        ascending,
        quantity
    ]);

    return <div className="variantFilterEditor">
        <div className="left">

            <div className="fold">

                <div className="gauges">
                    <div className="gaugeContainer">
                        {numFilteredVariants !== undefined && numSelectedVariants !== undefined && variants?.length !== undefined && <div className="gauge">
                            <Gauge
                                className="fillParent"
                                padding={0}
                                idleThickness={9}
                                valueThickness={9}
                                deadzoneWidth={0}
                                idleColor={colors["$gray-3"]}
                                minGaugeRadius={40}
                                min={0}
                                max={variants.length}
                                valueFormatter={(v) => Formatter.formatNumber(v, 0, session.numberFormatLocale)}
                                value={numFilteredVariants}
                            /></div>}
                        <div className="label">{variants !== undefined ? i18n.t(variants?.length === 1 ? "filters.variantsGauge_one" : "filters.variantsGauge_other", { numVariants: Formatter.formatNumber(variants?.length, 0, session.numberFormatLocale) }) : ""}</div>
                    </div>
                    <div className="gaugeContainer">
                        <div className="gauge">
                            <Gauge
                                className="fillParent"
                                padding={0}
                                valueFormatter={(value) => Formatter.formatPercent(value, numVariantCases, 0, session.numberFormatLocale)}
                                idleThickness={9}
                                valueThickness={9}
                                deadzoneWidth={0}
                                minGaugeRadius={40}
                                idleColor={colors["$gray-3"]}
                                min={0}
                                max={numVariantCases}
                                value={numFilteredCases}
                            />
                        </div>
                        <div className="label">{i18n.t(numVariantCases === 1 ? "filters.casesGauge_one" : "filters.casesGauge_other", { numCases: Formatter.formatNumber(numVariantCases, 0, session.numberFormatLocale) })}</div>
                    </div>
                </div>
            </div>
        </div>

        {/* right panel */}
        <div className="right">
            <VariantsSortInput
                className="variantsSortInputFixed"
                ascending={ascending}
                quantity={quantity}
                sort={sort}
                onChanged={(_sort, _ascending, _quantity) => {
                    setSort(_sort);
                    setAscending(_ascending);
                    if (_quantity)
                        setQuantity(_quantity);

                    queueMicrotask(() => {
                        tableRef.current?.invalidate();
                    });
                }}
            />

            <div className="fold">
                <VariantsList
                    ref={tableRef}
                    variants={variants}
                    hiddenVariants={hiddenVariants}
                    isLoading={isVariantsLoading || isAllVariantsLoading}
                    quantity={quantity}
                    selectedIds={selectedIds}
                    onSelectionChanged={(newSelectedIds) => {
                        setSelectedIds(newSelectedIds);
                        filterChanged(newSelectedIds, excludeSelection, sort, ascending, quantity);
                    }}
                />
                <Toast visible={selectedIds.length === 0} type={ToastTypes.Info} className="toastCondensed">
                    {i18n.t("filters.variants.noSelectionWarning")}
                </Toast>
                <Toast type={ToastTypes.Info} className="toastCondensed" visible={numRemovedSelection > 0}>
                    {i18n.t("filters.variants.variantRemovalHint", { count: numRemovedSelection })}
                </Toast>
            </div>
        </div>

        <div className="switch">
            <label className="alignCheckboxes">
                <div>
                    <input
                        data-testid="checkbox-exclude"
                        type="checkbox"
                        className="checkbox"
                        checked={excludeSelection}
                        id="checkbox-exclude"
                        onChange={(e) => {
                            setExcludeSelection(e.target.checked);
                            filterChanged(selectedIds, e.target.checked, sort, ascending, quantity);
                        }} />
                    <label htmlFor="checkbox-exclude" />
                </div>
                <div>
                    {i18n.t("filters.excludeProducts")}
                </div>
            </label>
        </div>
    </div>;

    function filterChanged(selectedIds: string[], excludeSelection: boolean, sortBy: string, sortAscending: boolean, quantity: string) {
        if (!props.onUpdate)
            return;

        const prefix = excludeSelection ? "ne" : "eq";

        const filter: EventFilter | undefined = selectedIds.length === 0 ? undefined : {
            variant: {
                consolidatePasses: (props.initialValue?.variant?.consolidatePasses ?? groupSupportsConsolidatePasses(settings.groupingKey)),
                activityKeysGroup: props.initialValue?.variant?.activityKeysGroup ?? groupingKeyToApi(settings.groupingKey),
                [prefix]: selectedIds,
                sortBy,
                quantity,
                sortAscending,
            }
        } as EventFilter;

        props.onUpdate(filter);
    }
}
