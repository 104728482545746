import { debounce, isString, max } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import colors from "../../colors.json";
import Global from "../../Global";
import i18n from "../../i18n";
import useWindowResize from "../../utils/WindowResizeHook";
import { Layouter } from "../dfg/Layouter";
import { ScrollHost, ScrollHostDimensions } from "../scrollhost/ScrollHost";
import { classNames } from "../../utils/Utils";

const scrollbarHeight = 12;

export type GanttElement<T> = {
    rowIndex: number;
    start: number;
    end?: number;
    color?: string;
    data?: T;
}

export type GanttPropsType<T> = {
    rowHeader: string[] | JSX.Element[];
    columnHeader: JSX.Element[];
    data: GanttElement<T>[];

    topLabel?: JSX.Element;

    selectedRow?: number;

    rowHeaderWidth?: number;
    columnHeaderHeight?: number;

    minCellWidth?: number;

    tickToX?: (tick: number) => number,
    tooltipHandler?: (element: GanttElement<T>) => JSX.Element,
    onRowClicked?: (rowIdx: number) => void,
}

export default function GanttChart<T>(props: GanttPropsType<T>) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const previousSelectedRowRef = useRef<number | undefined>(undefined);
    const [cursorInView, setCursorInView] = useState<boolean>(false);

    // Generates a lookup that returns all elements in a given row
    const [barData, numUsedRows] = prepareRowLookup();

    const numRows = Math.max(numUsedRows, props.rowHeader.length);

    const [tooltip, setTooltip] = useState<{
        visible: boolean,
        x?: number,
        y?: number,
        element?: JSX.Element
    }>({
        visible: false,
    });

    // This holds a reference to a per-row tooltip lookup
    const tooltipRef = useRef<{ [rowIdx: number]: GanttElement<T>[] }>({});

    useWindowResize();

    const width = containerRef.current?.clientWidth ? containerRef.current?.clientWidth - scrollbarHeight : 0;
    const height = containerRef.current?.clientHeight ? containerRef.current?.clientHeight - scrollbarHeight : 0;

    // Calculate row header heights
    const rowHeaderHeights = useMemo(() => {
        if (!props.rowHeader?.length)
            return [];

        const result: number[] = [];
        props.rowHeader.forEach((r) => {
            const dimensions = Layouter.measureFontSize("ganttHeaderMeasure", isString(r) ? r as string : r.toString(), true);
            result.push(dimensions.height);
        });

        return result;
    }, [props.rowHeader]);

    const cellSpacing = Math.max(
        props.minCellWidth ?? 70,
        Math.floor((width - (props.rowHeaderWidth ?? 200)) / (Math.max(1, props.columnHeader.length)))
    );

    let totalRowHeaderHeight = 0;
    const rowTops: number[] = [0];
    if (rowHeaderHeights) {
        for (let i = 0; i < rowHeaderHeights?.length ?? 0; i++) {
            totalRowHeaderHeight += rowHeaderHeights[i];
            rowTops.push(totalRowHeaderHeight);
        }
    }

    const dimensions: ScrollHostDimensions = {
        headerSize: {
            width: (props.rowHeaderWidth ?? 200),
            height: (props.columnHeaderHeight ?? 35),
        },
        contentSize: {
            width: cellSpacing * (props.columnHeader?.length ?? 0),
            height: totalRowHeaderHeight
        }
    };

    const headerCellMarkup = props.columnHeader.map((cell, idx) => {
        return <div 
            key={`header${idx}`} 
            data-testid={"dateHeader"} 
            className="columnHeader" 
            style={{ width: cellSpacing, left: idx * cellSpacing }}>
            {cell}
        </div>; });
    headerCellMarkup.push(<div className="xLabel" key="xLabel">{i18n.t("gantt.timeSequence")}</div>);

    const rowMarkup = props.rowHeader.map((row, idx) => <div
        key={`row${idx}`}
        className={classNames(["rowHeader", idx === props.selectedRow && "rowHeaderSelected"])}
        onClick={() => {
            if (props.onRowClicked)
                props.onRowClicked(idx);
        }}
        style={{
            top: rowTops![idx],
            height: rowHeaderHeights![idx]
        }}>
        {row}
    </div>);

    useEffect(() => {
        if (!canvasRef.current)
            return;

        const ctx = canvasRef.current.getContext("2d");
        if (!ctx || !dimensions.contentSize.height)
            return;

        // Deleting tooltips, as they are regenerated as we render
        tooltipRef.current = {};

        for (let i = 0; i < numRows; i++) {
            renderRow(ctx, i);
        }
    }, [props, width]);

    useEffect(() => {
        if (!canvasRef.current)
            return;

        const ctx = canvasRef.current.getContext("2d");
        if (!ctx || !dimensions.contentSize.height)
            return;

        // Render previously and currently selected row
        if (previousSelectedRowRef.current !== undefined)
            renderRow(ctx, previousSelectedRowRef.current);

        if (props.selectedRow !== undefined)
            renderRow(ctx, props.selectedRow);

        previousSelectedRowRef.current = props.selectedRow;
    }, [
        props.selectedRow,
    ]);

    const renderScrollHost = !!height || Global.isRunningJestTest;

    return <div className="ganttChart">
        {tooltip.visible && cursorInView && <div className="tooltip" style={{ top: tooltip.y!, left: tooltip.x! }}>
            {tooltip.element}
        </div>}

        <div ref={containerRef} className="chartContainer">
            {renderScrollHost && <ScrollHost
                dimensions={dimensions}
                content={<canvas
                    ref={canvasRef}
                    onClick={(e) => {
                        if (!props.onRowClicked)
                            return;

                        // Find clicked row
                        const rect = canvasRef.current!.getBoundingClientRect();
                        const viewY = e.pageY - rect.top;

                        let i;
                        for (i = 0; i < rowTops.length && rowTops[i] < viewY; i++) {
                            // Find the first row that has a larger y coordinate than
                            // the click
                        }

                        props.onRowClicked(i - 1);
                    }}
                    onMouseOverCapture={() => { setCursorInView(true); }}
                    onMouseOutCapture={() => { setCursorInView(false); }}
                    width={dimensions.contentSize.width}
                    height={dimensions.contentSize.height}
                    onMouseMove={debounce((e) => {
                        const rect = (e.target as HTMLDivElement).getBoundingClientRect();
                        const x = Math.max(0, e.clientX - rect.left);
                        const y = Math.max(0, e.clientY - rect.top);

                        // Get row number that corresponds to y coordinate
                        const rowIdx = rowTops.map((top, idx) => {
                            return { top, idx };
                        }).filter(r => r.top <= y).pop()?.idx;

                        if (rowIdx === undefined) {
                            setTooltip({ visible: false });
                            return;
                        }

                        const tooltips = getTooltips(rowIdx, x);
                        if (!tooltips.length) {
                            setTooltip({ visible: false });
                            return;
                        }

                        setTooltip({
                            x: e.clientX,
                            y: e.clientY,
                            visible: true,
                            element: props.tooltipHandler ? props.tooltipHandler(tooltips[0]) : undefined,
                        });

                    }, 500)}
                />}
                yHeader={rowMarkup}
                xHeader={headerCellMarkup}
                topLabel={props.topLabel}
            />}
        </div>
    </div>;

    function getTooltips(row: number, x: number) {
        if (!tooltipRef.current || tooltipRef.current[row] === undefined)
            return [];

        return tooltipRef.current[row].filter(t => {
            return t.start <= x && t.end! >= x;
        });
    }

    function prepareRowLookup(): [Map<number, GanttElement<T>[]>, number] {
        return useMemo(() => {
            const result: Map<number, GanttElement<T>[]> = new Map();
    
            let maxIdx = 0;
    
            for (const element of props.data) {
                if (!result.has(element.rowIndex))
                    result.set(element.rowIndex, []);
    
                result.get(element.rowIndex)!.push(element);
    
                if (element.rowIndex > maxIdx)
                    maxIdx = element.rowIndex;
            }
    
            return [result, maxIdx + 1];
        }, [props.data]);
    }

    /**
     * Renders a row into the canvas provided. You can use this to lazy-render the chart as it goes.
     * @param ctx Render context
     * @param rowIdx Row number to render
     */
    function renderRow(ctx: CanvasRenderingContext2D, rowIdx: number) {
        const y = rowTops[rowIdx];
        const height = rowHeaderHeights[rowIdx];

        ctx.beginPath();

        // Fill background with alternating colors
        ctx.fillStyle = props.selectedRow === rowIdx? colors.$selected : 
            [colors["$gray-4"], colors["$white"]][rowIdx % 2];
            
        ctx.fillRect(0, rowTops[rowIdx], dimensions.contentSize.width, height);

        // Draw column separators
        ctx.strokeStyle = colors.$ganttSeparatorColor;
        ctx.lineWidth = 1;

        for (let i = 0; i < props.columnHeader.length; i++) {
            const x = Math.floor(i * cellSpacing) + 0.5;
            ctx.moveTo(x, y);
            ctx.lineTo(x, y + height);
        }

        // Render bars <3
        if (tooltipRef.current[rowIdx] === undefined)
            tooltipRef.current[rowIdx] = [];

        for (const bar of (barData.get(rowIdx) ?? [])) {
            const yc = y + height / 2;
            ctx.fillStyle = bar.color ?? "red";
            const x1 = (props.tickToX ? props.tickToX(bar.start) : bar.start) * cellSpacing;

            if (bar.end !== undefined) {
                // Event has a start and end timestamp

                // Let's make the bar cover 40% of the height of the
                // row, so it does not look too crowded.
                const halfBarHeight = height * 0.4;
                const x2 = (props.tickToX ? props.tickToX(bar.end) : bar.end) * cellSpacing;
                const width = max([x2 - x1, 1]) as number;
                ctx.fillRect(x1, yc - halfBarHeight, width, halfBarHeight * 2);

                tooltipRef.current[bar.rowIndex].push({
                    ...bar,
                    start: Math.floor(x1),
                    end: Math.ceil(x2),
                });
            } else {
                // We just have a start timestamp, so we're rendering the event
                // as a point only.
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "16px sans-serif";
                ctx.fillText("◆", x1, yc, cellSpacing);

                tooltipRef.current[bar.rowIndex].push({
                    ...bar,
                    start: Math.floor(x1 - 8),
                    end: Math.ceil(x1 + 8),
                });
            }
        }

        ctx.stroke();
    }
}