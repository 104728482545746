import { isEqual } from "lodash";
import React, { useContext, useEffect, useImperativeHandle } from "react";
import { GraphSettingsType, SelectionType, SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";

export interface ISettingsSpy {
    set(data: DeepPartial<SettingsContextType>): void;
    setGraph(data: Partial<GraphSettingsType>): void;
    setSelection(selection: SelectionType): void;
}

export const SettingsSpy = React.forwardRef((props: {
    onSettingsChanged?: (settings: SettingsType) => void,
}, ref: React.Ref<ISettingsSpy>) => {
    const settings = useContext(SettingsContext);

    useEffect(() => {
        if (props.onSettingsChanged)
            props.onSettingsChanged(settings);
    }, [
        JSON.stringify(settings),
    ]);

    useImperativeHandle(ref, () => ({
        setSelection(selection: SelectionType) {
            settings.setSelection(selection);
        },
        setGraph(data: Partial<GraphSettingsType>) {
            if (isEqual({...settings, ...data}, settings))
                return;

            settings.setGraph({
                ...settings.graph,
                ...data,
            });
        },
        set(data: DeepPartial<SettingsContextType>) {
            const result = ObjectMerger.mergeObjects([ settings, data ]);

            // This check takes care only in invoke callbacks if something acutally changed.
            if (isEqual(result, settings))
                return;

            settings.mergeSet({
                ...result
            } as SettingsType);
        }
    }));

    return <></>;
});