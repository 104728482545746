import React from "react";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { KpiControls, getValidKpiControlSettings } from "./KpiControls";
import { ObjectControls, getValidObjectControlsSettings } from "./ObjectControls";
import { GroupingKeyControls, GroupingKeyOptions, VisibilityOptions, getValidGroupingKeyControlSettings } from "./GroupingKeyControls";
import { GraphDetailedControls } from "./GraphDetailedControls";
import { SettingsType } from "../../contexts/SettingsContext";
import { SessionType } from "../../contexts/SessionContext";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import { GraphHighlightCheckbox } from "./HighlightControls";

export function getValidDfgControlSettings(session: SessionType, settings: SettingsType, props: DfgControlsProps): DeepPartial<SettingsType> {
    return ObjectMerger.mergeObjects([
        getValidKpiControlSettings(session, settings, {
            kpis: props.kpis,
            hasYAxis: false,
            addQuantityDropdown: true,
            hideStatistics: [StatisticTypes.Variance]
        }),
        getValidObjectControlsSettings(session, settings),
        getValidGroupingKeyControlSettings(session, settings, {
            options: {
                none: VisibilityOptions.Hidden,
                ...props.groupingKeyOptions
            }
        }),
    ]);
}

export type DfgControlsProps = {
    /**
     * Check KpiPresets if there is something that suits your needs
     */
    kpis: KpiTypes[],

    /**
     * Grouping key options for the grouping key controls.
     */
    groupingKeyOptions?: GroupingKeyOptions;

    /**
     * Disable the highlighting of transitions.
     */
    disableTransitionHighlighting?: boolean,

    /**
     * Labels for the kpi options. If omitted, these default to the labels from the KPI definitions
     */
    kpiOptionLabels?: { [key in KpiTypes]?: string },

    /**
     * Show indentation in the dropdown. Is handled as true by default.
     */
    hasIndentation?: boolean,

    /**Disable the detailed controls. */
    disableDetailedControls?: boolean,
};

/**
 * This component is used across all dimensions for the dfg view.
 */
export default function DfgControls(props: DfgControlsProps) {

    return <div className="controls">

        <KpiControls kpis={props.kpis} hasYAxis={false} addQuantityDropdown={true} hideStatistics={[StatisticTypes.Variance]} kpiOptionLabels={props.kpiOptionLabels} hasIndentation={props.hasIndentation} />

        <GraphHighlightCheckbox />

        <ObjectControls showContextSwitch={true} />

        <GroupingKeyControls
            options={{
                none: VisibilityOptions.Hidden,
                ...props.groupingKeyOptions
            }}
            secondaryGrouping={VisibilityOptions.Visible}
            isXAxisLabel={false}
        />

        {!props.disableDetailedControls === true && <GraphDetailedControls />}

    </div>;
}
