import React, { useContext } from "react";
import { BaseQuantityType, timeAndThroughputCalcOptions } from "../../../models/ApiTypes";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { RcaType } from "../../../contexts/ContextTypes";
import { SettingsContext, getRecentRcaByType } from "../../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../../contexts/ContextTypes";
import { CaseAggregationStatistics } from "../../../models/Case";
import { getCaseAggregatedRelativeScrapStatistics, getCaseAggregatedScrapStatistics } from "../../../utils/CaseStats";
import { Formatter, getQuantityUnit } from "../../../utils/Formatter";
import { calculateInfluence, getRcaSelectionType } from "../RcaUtils";
import { InfluenceStatsSubsection } from "./InfluenceStatsSubsection";
import { useCases } from "../../../hooks/UseCases";

/**
 * Used in SideStatisticsRcaStats. Don't use directly!
 */
export function QualityRcaStats() {
    const settings = useContext(SettingsContext);

    const rca = getRecentRcaByType(RcaType.Quality, settings);
    const rcaFilters = rca?.rcaFilters ?? [];

    const calcOptions = {
        ...timeAndThroughputCalcOptions,
        limit: 0
    };

    const [allCaseStatistics, areAllCasesLoading] = useCases({
        ...calcOptions,
        eventFilters: rcaFilters,
    });

    const showSelectionStats = !!getRcaSelectionType(settings.selection);

    const allRelativeStats = allCaseStatistics?.log?.actual !== undefined  ? getCaseAggregatedRelativeScrapStatistics(allCaseStatistics.log.actual, rca.quantity) : undefined;
    const influenceUnit = rca.analyzedValue === LegacyAnalyzedValues.QualityQuota ? Formatter.units.percent : getQuantityUnit(rca.quantity);
    const influence = calculateInfluence(settings.selection.feature?.relevance, rca?.maximize ?? true);
    const influenceLabel = rca.analyzedValue === LegacyAnalyzedValues.QualityQuota ? "quality.relativeScrap" : "quality.values";

    return <>
        {showSelectionStats && <StatsSection title="rca.comparingStats">
            <InfluenceStatsSubsection label={influenceLabel} influence={influence} unitMeta={influenceUnit}/>
            <QualityStatsSubsection title="rca.scrapAllCases" stats={allCaseStatistics?.log?.actual} quantity={rca.quantity} count={allCaseStatistics?.log?.actual?.count} isLoading={areAllCasesLoading}/>

            {/* TODO: make this similar to the variance and deviation stats (i.e. separate blocks for all cases and selected, all)? */}
            <StatsSubSection title="rca.scrapMeanRate">
                <StatsRowValue label="common.allCases" value={allRelativeStats?.mean} unit={Formatter.units.percent} isLoading={areAllCasesLoading} />
            </StatsSubSection>
        </StatsSection>}
    </>;
}


function QualityStatsSubsection(props: {isLoading: boolean, title: string, stats?: CaseAggregationStatistics, quantity?: BaseQuantityType, count?: number}) {
    const quantityStats = props.stats !== undefined ? getCaseAggregatedScrapStatistics(props.stats, props.quantity) : undefined;
    const unitMeta = getQuantityUnit(props.quantity);

    return <StatsSubSection title={props.title}>
        <StatsRowValue label="common.statistics.total" value={quantityStats?.sum} unit={unitMeta} isLoading={props.isLoading}/>
        <StatsRowValue label="common.statistics.mean" value={quantityStats?.mean} unit={unitMeta} isLoading={props.isLoading} isHighlight={true}/>
        <StatsRowValue label="common.statistics.max" value={quantityStats?.max} unit={unitMeta} isLoading={props.isLoading} />
        <StatsRowValue label="common.statistics.min" value={quantityStats?.min} unit={unitMeta} isLoading={props.isLoading} />
        <StatsRowValue label="common.statistics.absCount" value={props.count} unit={Formatter.units.numberShort} isLoading={props.isLoading} />
    </StatsSubSection>;
}
