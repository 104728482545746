import React, { useImperativeHandle, useState } from "react";
import { useDrop } from "react-dnd";
import { Card, CardData, CardInfo, ItemTypes } from "./Card";
import { CollectionTypes } from "./Dropzone";

export const Sequence = React.forwardRef((props: {
    collectionType: CollectionTypes;
    initialValue: CardInfo[],
    onUpdate: (items: CardInfo[]) => void,
}, ref: React.Ref<{
    add: (element: CardInfo) => void,
    clear: () => void,
    getState: () => CardInfo[] | undefined,
}>) => {
    const [state, setState] = useState<CardData[]>(props.initialValue.map((p, idx) => {
        return {
            ...p,
            index: idx,
        } as CardData;
    }));

    const [, drop] = useDrop({
        accept: ItemTypes.Card,
        drop: (item: CardData & { type: string }) => {
            if (item.index !== undefined)
                return;

            if (state.some(s => s.id === item.id))
                return;

            let newState: CardData[] = [];

            switch (props.collectionType) {
                case CollectionTypes.Sequence:
                    newState = ([] as CardData[]).concat(state).concat([{
                    // we add a random number here to make it possible
                    // that two cards with the same id can be added
                        id: item.id  + Math.random(),
                        index: state.length,
                        label: item.label,
                        data: item.data,
                    }]);
                    break;

                case CollectionTypes.Set:
                    newState = state.filter(s => s.id !== item.id).concat([item]);
                    break;

                case CollectionTypes.Single:
                    newState = [item];
                    break;
            }
            
            setState(newState);
            props.onUpdate(newState);
        }
    });

    useImperativeHandle(ref, () => ({
        add(element: CardInfo) {
            const newState: CardData[] = [
                ...state,
                {
                    ...element,
                    index: state.length
                }
            ];
            setState(newState);
            props.onUpdate(newState);
        },
        clear() {
            setState([]);
            props.onUpdate([]);
        },
        getState() {
            return [...state];
        }
    }));

    return (<>
        <div ref={drop} className="sequence">
            {state.map((a, idx) => <Card id={a.id} index={idx} key={a.id} label={a.label} moveCard={(dragIdx, hoverIdx) => {
                if (dragIdx === undefined)
                    return;
                const newSequence = ([] as CardData[]).concat(state);
                const tmp = newSequence[dragIdx];
                newSequence[dragIdx] = newSequence[hoverIdx];
                newSequence[hoverIdx] = tmp;

                // Insert element before idx
                setState(newSequence);
                props.onUpdate(newSequence);
            }}
            onDelete={(item) => {
                const newState = state.filter(s => s.id !== item.id);
                setState(newState);
                props.onUpdate(newState);
            }}
            />)}
        </div>
    </>
    );
});