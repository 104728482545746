import React from "react";

type InputAdderPropsType<T> = {
    inputType: "text" | "number" | "email" | "tel" | "url";
    placeholder?: string;
    buttonLabel?: string;
    onAdd?: (newElement: T) => void;
    parse: (value: string) => T | undefined;
};

type InputAdderState = {
    isValid: boolean;
};


export default class InputAdder<T> extends React.Component<InputAdderPropsType<T>, InputAdderState> {
    
    private inputRef: React.RefObject<HTMLInputElement>;


    constructor(props: InputAdderPropsType<T>) {
        super(props);
        this.state = {
            isValid: true
        };

        this.inputRef = React.createRef();

        this.keyHandler = this.keyHandler.bind(this);
        this.addStringValue = this.addStringValue.bind(this);
    }
    

    render() {
        return <div 
            className={this.state.isValid ? "arrayEditor arrayEditorValid" : "arrayEditor inputError"}>
            <input 
                ref={this.inputRef}
                placeholder={this.props.placeholder}
                type={this.props.inputType} 
                onKeyUp={this.keyHandler} />
            <button className="addButton" disabled={!this.state.isValid} onClick={() => {
                const inputValue = this.inputRef.current?.value;
                this.addStringValue(inputValue);
            }}>
                {this.props.buttonLabel ?? "+"}
            </button>
        </div>;
    }

    private keyHandler(e: React.KeyboardEvent<HTMLInputElement>) {
        const isValueValid = this.props.parse(e.currentTarget.value) !== undefined;
        if (isValueValid !== this.state.isValid)
            this.setState({ isValid: isValueValid});
            
        if (e.key === "Enter") {
            if (isValueValid)    
                this.addStringValue(e.currentTarget.value);

            e.stopPropagation();
            e.preventDefault();
        }
    }

    public addStringValue(stringValue: string | undefined) {
        if (stringValue === undefined)
            return;

        const value = this.props.parse(stringValue.trim());
        this.add(value);
    }

    public add(value: T | undefined) {
        if (value === undefined)
            return;

        if (this.inputRef.current)
            this.inputRef.current.value = "";

        if (this.props.onAdd)
            this.props.onAdd(value);
    }
}