import { isArray, isObject, isString } from "lodash";

type Dict = {[key: string]: string};

/**
 * Replaces UUIDs in an object, array, string, whatever!
 * @param obj Object that contains UUIDs that need replacing
 * @param dict key => value lookup holding the replacements
 * @returns new object where the occurences of the UUIDs are replaced
 */
export function replaceIdsInObject(obj: any | any[] | string | number | boolean | null | undefined, dict: Dict) {
    if (obj === undefined || obj === null)
        return obj;

    if (isArray(obj))
        return replaceIdsInObjectInternal([...obj], dict);

    if (isObject(obj)) 
        return replaceIdsInObjectInternal({ ...obj }, dict);

    return replaceIdsInObjectInternal(obj, dict);
}

function replaceIdsInObjectInternal(obj: unknown, dict: Dict) {
    if (obj === undefined || obj === null)
        return undefined;
        
    if (isObject(obj)) {
        const props = Object.getOwnPropertyNames(obj);
        for (const prop of props) {
            const o = obj as {[key: string]: string | undefined | null | unknown};
            o[prop] = replaceIdsInObjectInternal(o[prop], dict);
        }

        return obj;
    }

    if (isArray(obj)) {
        for (let i = 0; i < (obj as []).length; i++)
            (obj as any[])[i] = replaceIdsInObjectInternal(obj[i], dict);

        return obj;
    }

    if (isString(obj)) {
        let result = obj as string;
        for (const key of Object.keys(dict))
            result = result.replaceAll(key, dict[key]);

        return result;
    }

    // obj is a number, boolean or null... Nothing to do here!
    return obj;
}
