import { Component } from "react";

interface UnmountWatcherProps {
    componentWillUnmount: () => void;
}

class UnmountWatcher extends Component<UnmountWatcherProps> {
    render() {
        return null;
    }

    componentWillUnmount(): void {
        this.props.componentWillUnmount();
    }
}

export default UnmountWatcher;