import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { Notification, NotificationProps } from "./Notification";
import { NotificationService } from "./NotificationService";
import { classNames } from "../../utils/Utils";

export function NotificationHost() {
    const portalNode: HTMLElement | undefined = document.getElementById("notifications-root")!;

    const element = useMemo(() => {
        return document.createElement("div");
    }, []);

    useEffect(() => {
        portalNode?.appendChild(element);
        return () => {
            tryRemoveNode();
        };
    }, []);

    const [notifications, setNotifications] = useState<NotificationProps[]>([]);
    useEffect(() => {
        NotificationService.onChange.subscribe((props) => {
            queueMicrotask(() => {
                setNotifications(props);
            });
        });
    }, []);

    return ReactDOM.createPortal(<>
        {notifications.map((n) => <Notification key={`notification-${n.id}`} {...n} className={classNames([n.className, "mb"])} />)}
    </>, element);

    function tryRemoveNode() {
        try {
            portalNode?.removeChild(element);
        } catch {
            // In rare occasions this element is already gone. Removing it twice would
            // result in an error
        }
    }
}