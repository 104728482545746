import { isArray, range } from "lodash";
import React, { useContext, useMemo, useRef } from "react";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import { Formatter } from "../../utils/Formatter";
import { BaseGraphProps, ContainerModes, generateColumnLabels, generateGraphTicks, getHorizonalLines, Padding, Tick, useDragging } from "./GraphCommon";
import { IScrollbar, Scrollbar } from "../scrollbar/Scrollbar";

export type OverlayBarMetrics = {
    /**
     * Center of the drawing area
     */
    center: number,

    /**
     * width of a bar
     */
    barWidth: number,

    /**
     * Space allocated for a single bar, including the space between bars
     */
    space: number,
};

export type Bar<T> = Tick & {
    /**
     * indicates where the bar should begin. If omitted, this defaults to 0.
     */
    baseValue?: number;

    /**
     * This component can also render a median indicator, so you can use it
     * as a simple box plot.
     */
    median?: number;

    /**
     * The formatted value as used in tooltips, the bar labels, ...
     * Formatters can be quite expensive, especially when dates are involved.
     * So, for large datasets, it's generally better to provide the formatter
     * as `valueFormatter` to the Graph component, and handle the formatting
     * on demand.
     */
    valueLabel?: string;
    tooltip?: string;

    /**
     * when specified, this per-bar setting overrides the one from the props
     */
    barColor?: string;
    data?: T;
}

export type GraphOverlay = {
    color: string;
    lineWidth: number;

    data: number[];
}

export type GraphProps<T> = BaseGraphProps & {
    barColor?: string;

    negativeBarColor?: string;

    minBarPadding?: number;

    barWidth?: number;

    data: Bar<T>[];

    overlayRenderer?: (visibleIndices: number[], idxToX: (value: number) => OverlayBarMetrics, valueToY: (value: number) => number) => (JSX.Element[] | JSX.Element | undefined | null);

    selectedIndex?: number | number[];

    onSelected?: (element: Bar<T> | undefined, idx: number | undefined) => void;

    /**
     * If set, a legend will be displayed with the colors of the respective bars
     */
    legend?: JSX.Element;
}

export function Graph<T>(props: GraphProps<T>) {
    // How much of barSpace will be assigned for drawing the bar
    const barWidth = props.barWidth ?? 20;

    const minBarWidth = (props.minBarPadding ?? 40) + barWidth;

    const selectedIndices = isArray(props.selectedIndex) ? props.selectedIndex : [props.selectedIndex as number];

    const containerMode = props.containerMode ?? ContainerModes.Overflowing;

    const session = useContext(SessionContext);
    const scrollbarRef = useRef<IScrollbar>(null);

    const { min, max } = useMemo(() => {
        const data = (props.data ?? []).filter(d => d !== undefined);
        const allValues = data.map(d => d.value).filter(d => d !== undefined).
            concat((props.yAxisTicks ?? []).filter(d => d !== undefined).map(t => t.value)).
            concat(data.filter(d => d?.median !== undefined).map(d => d.median!)).
            concat(data.map(e => e.baseValue ?? 0));
        // Get a list of all values for horizontal lines. We add 5% to them such that the axis is 5% longer in case they are setting the max value.
        const allLineValues = (props.horizonalLines ?? []).map(v => v.value * 1.05);

        const min = props.min !== undefined ?
            Math.min(props.min, ...allValues, ...allLineValues) :
            Math.min(...allValues, ...allLineValues);
        const max = props.max !== undefined ?
            Math.max(props.max, ...allValues, ...allLineValues) :
            Math.max(...allValues, ...allLineValues);
        return {
            min: min !== undefined && isFinite(min) ? min : 0,
            max: max !== undefined && isFinite(max) ? max : 0,
        };
    }, [
        props.min,
        props.max,
        props.data,
        props.yAxisTicks,
    ]);

    if (props.data === undefined)
        return null;

    const padding: Padding = {
        top: props.padding?.top ?? 50,
        bottom: props.padding?.bottom ?? 50,
        left: props.padding?.left ?? 100,
        right: props.padding?.right ?? 10,
    };

    const positiveColor = props.barColor ?? colors.$graphPositiveValueColor;
    const negativeColor = props.negativeBarColor ?? positiveColor;

    // Enforce minimum width
    const minWidth = padding.left + padding.right + barWidth;
    const width = Math.max(props.width, minWidth);

    // Enforce a minimum height of 30 pixels for the chart, no matter what
    // the properties say
    const height = Math.max(padding.bottom + padding.top + 30, props.height);

    // Calculate bar width. If there is more space than needed to display all bars, we can just
    // as well distribute them evenly over the space available.
    const paneWidth = width - padding.left - padding.right;
    const maxBarsInView = Math.floor(paneWidth / minBarWidth);
    const canDrag = maxBarsInView <= (props.data?.length ?? 0);

    // Space allocated for a single bar
    const barSpace = canDrag ? minBarWidth : paneWidth / (props.data?.length ?? 1);

    const yZero = valueToY(0);

    // Deal with drag gestures
    const contentWidth = (props.data?.length ?? 0) * barSpace;
    const maxOffset = Math.max(0, contentWidth - paneWidth);
    const { offset, dragState, mouseHandlers, setOffset } = useDragging(canDrag, maxOffset, props.initialOffset, (offset) => {
        if (scrollbarRef.current)
            scrollbarRef.current.setOffset(offset);
    });

    // Axes
    const axisColor = colors["$gray-7"];
    const axes: JSX.Element[] = [];
    axes.push(<line key="y-axis" x1={padding.left} x2={padding.left} y1={padding.top} y2={height - padding.bottom} stroke={axisColor} />);

    if (yZero <= (height - padding.bottom))
        axes.push(<line key="x-axis" x1={padding.left} x2={width - padding.right} y1={yZero} y2={yZero} stroke={axisColor} />);

    // y axis ticks
    const tickLabels: JSX.Element[] = [];

    const tickLength = 5;

    const yAxisTicks = (props.yAxisTicks?.length) ? props.yAxisTicks : generateGraphTicks(
        height - padding.top - padding.bottom,
        min,
        max,
        true,
        props.yAxisUnit ?? Formatter.defaultUnit,
        {
            locale: session.locale,
            ...props.formatterParams
        },
        props.valueFormatter ?? props.yAxisUnit?.formatter ?? Formatter.defaultUnit.formatter);

    if (props.showYAxisLines || props.showYAxisTicks)
        for (const tick of yAxisTicks) {
            const y = Math.round(valueToY(tick.value));

            if (props.showYAxisTicks) {
                axes.push(<line key={`y-axis-tick-${tick.value}`} x1={padding.left - tickLength} x2={padding.left} y1={y} y2={y} stroke={axisColor} />);
                tickLabels.push(<div key={`y-axis-label-${tick.value}`} className="yAxisTick" style={{
                    left: 0,
                    width: padding.left,
                    top: y - 20 + 0.5,
                    height: 40,
                    paddingRight: tickLength + 5,
                }}>
                    {tick.label ?? Formatter.formatNumber(tick.value, 2, session.numberFormatLocale)}
                </div>);
            }

            if (props.showYAxisLines)
                axes.push(<line key={`y-axis-line-${tick.value}`} x1={padding.left} x2={width - padding.right} y1={y} y2={y} stroke="rgba(0,0,0,0.075)" />);
        }

    // Render visible bars
    const bars: JSX.Element[] = [];
    const medians: JSX.Element[] = [];
    const barLabels: JSX.Element[] = [];
    const barValues: JSX.Element[] = [];
    const columnTicks: JSX.Element[] = [];
    const overlays: JSX.Element[] = [];

    const visibleIndices = getVisibleIndices();
    const halfBarWidthPixels = barWidth / 2;

    const valueFormatter = props.valueFormatter ?? props.yAxisUnit?.formatter ?? Formatter.defaultUnit.formatter;

    const createRect = (idx: number, x: number, minY: number, maxY: number, zeroY: number, positiveColor: string, negativeColor: string) => {
        const rect = (key: string, x: number, minY: number, maxY: number, color: string) => {
            return <rect
                style={{ cursor: "pointer" }}
                key={key}
                x={x}
                width={barWidth}
                y={minY}
                height={maxY - minY}
                fill={color}
                clipPath="url(#constrain-pane)"
                onClick={(e) => {
                    if (props.onSelected) {
                        // If a drag event ended recently, this is not a selection click but caused by
                        // the user releasing the mouse button.
                        if (!dragState.lastDragGestureEnded ||
                            (new Date().getTime() - dragState.lastDragGestureEnded) > 100)
                            if (selectedIndices.indexOf(idx) >= 0)
                                props.onSelected(undefined, undefined);
                            else
                                props.onSelected(props.data[idx], idx);
                    }

                    e.preventDefault();
                    e.stopPropagation();
                }}
            />;
        };

        const result: JSX.Element[] = [];
        // Draw two distinct halves if the colors differ and we have a zero crossing
        if (positiveColor !== negativeColor &&
            (minY <= zeroY || maxY <= zeroY) && (minY > zeroY || maxY > zeroY)) {
            // positive part
            {
                const upper = Math.min(minY, maxY);
                const lower = Math.min(zeroY, Math.max(minY, maxY));
                result.push(rect(`bar-${idx}-pos`, x, upper, lower, positiveColor));
            }

            // negative part
            {
                const lower = Math.max(zeroY, Math.min(minY, maxY));
                const upper = Math.max(minY, maxY);
                result.push(rect(`bar-${idx}-neg`, x, lower, upper, negativeColor));
            }
        } else {
            const color = minY > zeroY ? negativeColor : positiveColor;
            result.push(rect(`bar-${idx}`, x, Math.min(minY, maxY), Math.max(minY, maxY), color));
        }
        return result;
    };

    for (const idx of visibleIndices) {
        const element = props.data[idx];

        if (element === undefined)
            break;

        const isSelected = selectedIndices.indexOf(idx) >= 0;
        const barPositiveColor = isSelected ? props.selectionColor ?? colors["$selected"] : positiveColor;
        const barNegativeColor = isSelected ? props.selectionColor ?? colors["$selected"] : negativeColor;
        const center = idx * barSpace - offset + barSpace / 2;

        const x1 = padding.left + center - halfBarWidthPixels;

        const y = [valueToY(element.baseValue ?? 0), valueToY(element.value)];
        const y1 = Math.min(...y);
        const y2 = Math.max(...y);
        const zeroY = valueToY(0);

        if (element.median !== undefined) {
            // To prevent sub-pixel sampling of lines, draw those at half pixel locations like 11.5
            const medianY = Math.round(valueToY(element.median)) + 0.5;
            const lineProps = {
                x1,
                x2: x1 + barWidth,
                y1: medianY,
                y2: medianY,
                clipPath: "url(#constrain-pane)",
            };

            medians.push(<line
                {...lineProps}
                key={`median-${idx}`}
                stroke={colors.$white}
                strokeWidth={5}
            />);

            medians.push(<line
                {...lineProps}
                key={`median-${idx}-inner`}
                stroke={colors.$selected}
                strokeWidth={3}
            />);
        }

        for (const bar of createRect(idx, x1, y1, y2, zeroY, isSelected ? colors.$graphSelectionBarColor : element.barColor ?? barPositiveColor, isSelected ? colors.$graphSelectionBarColor : element.barColor ?? barNegativeColor))
            bars.push(bar);

        // Now we break the labels into multiple rows that are short enough for the space we've
        // reserved for it. If there are too many, we'll drop the rest.
        if (element.label) {
            const xCenter = padding.left + idx * barSpace + barSpace / 2 - offset;
            const lines = generateColumnLabels(element.label, element.tooltip, xCenter, barSpace - 20, width, height, padding, () => {
                if (props.onSelected)
                    if (selectedIndices.indexOf(idx) >= 0)
                        props.onSelected(undefined, undefined);
                    else
                        props.onSelected(props.data[idx], idx);
            }, mouseHandlers, isSelected);

            for (const line of lines)
                barLabels.push(line);
        }

        if (props.showBarValues) {
            barValues.push(<div
                key={`value-${idx}`}
                className="barValue"
                {...mouseHandlers}
                style={{
                    left: idx * barSpace - offset,
                    height: 40,
                    width: barSpace,
                    bottom: height - y1,
                }}>
                {element.valueLabel ?? valueFormatter(element.value, {
                    locale: session.numberFormatLocale,
                    numDigits: 1,
                    ...props.formatterParams,
                })}
            </div>);
        }
    }

    if (props.overlayRenderer) {
        const elements = props.overlayRenderer(visibleIndices, (x) => {
            return {
                center: padding.left + x * barSpace - offset + barSpace / 2,
                barWidth,
                space: barSpace,
            } as OverlayBarMetrics;
        }, valueToY);

        if (isArray(elements))
            elements.filter(e => e !== undefined && e !== null).forEach(e => overlays.push(e));
        else
        if (elements !== undefined && elements !== null)
            overlays.push(elements);
    }

    // Line drawing
    const [lines, lineLabels] = getHorizonalLines(props.horizonalLines, width, padding, valueToY);

    return <div
        style={{
            width: width,
            height: height,
            cursor: canDrag ? "grab" : "default",
            overflow: containerMode === ContainerModes.Overflowing ? "visible" : "hidden",
        }}
        onClick={() => {
            if (props.onSelected)
                props.onSelected(undefined, undefined);
        }}
        className="graph">
        {dragState.isDragGesture && <div className="cover" {...mouseHandlers} onMouseOut={mouseHandlers.onMouseUp} />}

        {props.yAxisLabel !== undefined && <div className="yAxisLabel" style={{
            width: height - padding.top - padding.bottom,
            top: padding.top,
            left: -(height - padding.top - padding.bottom) + padding.left - (props.yAxisLabelPadding ?? 0),
        }}>
            {props.yAxisLabel}
        </div>}
        <div
            style={{
                position: "absolute",
                top: 0,
                left: padding.left,
                height: height,
                width: width - padding.left - padding.right,
                overflow: "hidden",
            }}
        >
            {barValues}
        </div>
        <svg
            {...mouseHandlers}
            style={{
                width,
                height,
                position: "relative",
            }}>
            <defs>
                <clipPath id="constrain-pane">
                    <rect x={padding.left} y={padding.top} width={width - padding.left - padding.right} height={height - padding.top - padding.bottom} />
                </clipPath>
                <clipPath id="constrain-pane-labels">
                    <rect x={padding.left} y={0} width={width - padding.left - padding.right} height={height - padding.bottom} />
                </clipPath>
            </defs>

            {axes}
            {lines}
            {bars}
            {medians}
            {columnTicks}
            {overlays}
        </svg>
        {tickLabels}
        {barLabels}
        {lineLabels}

        {containerMode === ContainerModes.Constrained && <div
            className="fader"
            style={{
                height: padding.bottom,
                width: Math.min(15, padding.left / 2),
            }}
        />}

        {props.title !== undefined && <div className="topContainer title">{props.title}</div>}

        {/* Legend  */}
        {props.legend !== undefined && props.legend}

        <Scrollbar
            ref={scrollbarRef}
            padding={padding}
            onChange={(state) => {
                setOffset(state.from);
            }}
            initialState={{
                from: offset,
                to: offset + paneWidth,
                max: contentWidth,
            }} />

    </div>;

    function getVisibleIndices() {
        const fromIdx = Math.max(0, panelXToIndex(0));
        const toIdx = Math.min(panelXToIndex(paneWidth) + 1, props.data?.length ?? 0);

        if (toIdx < fromIdx)
            return [];

        return range(fromIdx, toIdx);
    }

    function valueToY(value: number) {
        const delta = (max - min) || 1;
        const ratio = (value - min) / delta;
        const panelHeight = Math.max(0, height - padding.top - padding.bottom);

        return Math.max(0, height - padding.bottom) - ratio * panelHeight;
    }

    /**
     * Calculates the index of the corresponding bar for a given x coordinate. This coordinate
     * is relative to the left of the chart pane.
     * @param offset
     */
    function panelXToIndex(x: number) {
        return Math.floor((x + offset) / barSpace);
    }
}
