import { capitalize } from "lodash";
import { GroupingKeys } from "../models/Dfg";
import { EventKeys } from "../models/EventKeys";
import { Formatter, UnitMetadata } from "./Formatter";
import { BaseQuantityType } from "../models/ApiTypes";

export const baseQuantities = ["mass", "length", "count"] as const;

export function isBaseQuantityType(s: string | undefined): s is BaseQuantityType  {
    return baseQuantities.includes(s as any);
}

export function isAvailableBaseQuantityType(s: string | undefined, availableBaseQuantityIds: BaseQuantityType[]): s is BaseQuantityType {
    return isBaseQuantityType(s) && (availableBaseQuantityIds || []).includes(s);
}

export type QuantityFormatter = (value?: number, numDigits?: number, locale?: string) => string


export type Quantity = {
    /**
     * This ID should never change, so it may be used to refer to a metric in code
     */
    id: string,

    /**
     * Translation string
     */
    name: string,

    /**
     * Metric formatter
     * @deprecated use .unit instead
     */
    formatter: QuantityFormatter,

    isFrequency: boolean,

    baseQuantity: BaseQuantityType,

    unit: UnitMetadata,
};

export const quantities: Quantity[] = [{
    id: "mass",
    name: "quantities.mass",
    formatter: Formatter.formatWeight,
    isFrequency: false,
    baseQuantity: "mass",
    unit: Formatter.units.weight,
}, {
    id: "count",
    name: "quantities.count",
    formatter: (value, numDigits = 1, locale?: string) => { return Formatter.formatNumberShort(value, numDigits, locale); },
    isFrequency: false,
    baseQuantity: "count",
    unit: Formatter.units.count,
}, {
    id: "length",
    name: "quantities.length",
    formatter: Formatter.formatMetricLength,
    isFrequency: false,
    baseQuantity: "length",
    unit: Formatter.units.metricLength,
}, {
    id: "massFlow",
    name: "quantities.massFlow",
    formatter: Formatter.formatMassFlow,
    isFrequency: true,
    baseQuantity: "mass",
    unit: Formatter.units.massFlow,
}, {
    id: "countFlow",
    name: "quantities.unitFlow",
    formatter: Formatter.formatCountFlow,
    isFrequency: true,
    baseQuantity: "count",
    unit: Formatter.units.countFlow,
}, {
    id: "speed",
    name: "quantities.speed",
    formatter: Formatter.formatSpeed,
    isFrequency: true,
    baseQuantity: "length",
    unit: Formatter.units.speed,
}];

const quantityMap: { [key: string]: Quantity } = {};
for (const q of quantities) {
    quantityMap[q.id] = q;
}

export enum QuantityType {
    Yield = "yield",
    Scrap = "scrap",
    CaseYield = "caseYield",
    CaseScrap = "caseScrap",
}

/**
 * Returns the quantities that are assigned to this project
 * @param eventKeys Event keys
 * @param outputType (case)Scrap or (case)Yield or an array of types. If an array is given, any one of these types will count as a match.
 * @param frequency Indicates if you want frequencies (=true), non-frequencies (=false) or both (=undefined)
 * @returns array of Quantity entities
 */
export function getAssignedQuantities(eventKeys: EventKeys | undefined, outputType: QuantityType | QuantityType[], frequency?: boolean): Quantity[] {
    if (eventKeys === undefined)
        return [];

    const outputTypes = Array.isArray(outputType) ? outputType : [outputType];

    const result = new Map<string, Quantity> ();
    for (const baseQuantity of baseQuantities) {
        for (const quantity of quantities.filter(q => q.baseQuantity === baseQuantity && (frequency === undefined || frequency === q.isFrequency)))
            for (const t of outputTypes) {
                const key = t.toString() + capitalize(baseQuantity);
                if (eventKeys[key] !== undefined)
                    if (!result.has(quantity.id))
                        result.set(quantity.id, quantity);
            }
    }

    // A map returns it's values in insertion order, which is what we want to keep the ordering of the base quantities consistent.
    return [...result.values()];
}

export function getQuantity(key: string | undefined, isFlow?: boolean): Quantity | undefined {
    if (!key)
        return undefined;

    if (isFlow)
        return flowQuantityMap[key];
    return quantityMap[key];
}

const flowQuantityMap: { [key: string]: Quantity } = {};
for (const q of quantities.filter(q => q.isFrequency).reverse()) {
    flowQuantityMap[q.baseQuantity] = q;
}

export function getFlowQuantity(key: BaseQuantityType | undefined): Quantity | undefined {
    if (key === undefined)
        return;
    return flowQuantityMap[key];
}

export function getAssignedGroupingKeys(eventKeys: EventKeys | undefined): GroupingKeys[] {
    if (!eventKeys)
        return [GroupingKeys.None];

    const result: GroupingKeys[] = [];

    // Let's insert the machine first as it is the default value.
    if (eventKeys.machine !== undefined) {
        result.push(GroupingKeys.Machine);
        if (eventKeys.passId)
            result.push(GroupingKeys.MachineValueStream);
    }

    if (eventKeys.machineType !== undefined) {
        result.push(GroupingKeys.MachineType);
        if (eventKeys.passId)
            result.push(GroupingKeys.MachineTypeValueStream);
    }

    if (eventKeys.location !== undefined) {
        result.push(GroupingKeys.Location);
        if (eventKeys.passId)
            result.push(GroupingKeys.LocationValueStream);
    }

    if (eventKeys.objectType !== undefined) {
        result.push(GroupingKeys.ObjectType);
        if (eventKeys.passId)
            result.push(GroupingKeys.ObjectTypeValueStream);
    }

    if (eventKeys.objectType !== undefined && eventKeys.machine !== undefined) {
        result.push(GroupingKeys.MachineObjectType);
        if (eventKeys.passId)
            result.push(GroupingKeys.MachineObjectTypeValueStream);
    }

    if (eventKeys.passId !== undefined)
        result.push(GroupingKeys.PassValueStream);

    result.push(GroupingKeys.None);
    
    if (eventKeys.passId)
        result.push(GroupingKeys.NoneValueStream);

    return result;
}

export function hasCaseYieldAndCaseScrap(eventKeys: EventKeys | undefined, quantity: BaseQuantityType | undefined) {
    if (quantity === undefined)
        return false;
    const caseYieldQuantities = getAssignedQuantities(eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);
    // case scrap is calculated from event scrap if case scrap is not directly
    // assigned, so we can assume that case scrap is available if either case
    // scrap or event scrap are assigned.
    const caseScrapQuantities = getAssignedQuantities(eventKeys, [QuantityType.Scrap, QuantityType.CaseScrap], false).map(q => q.baseQuantity);

    return caseYieldQuantities.includes(quantity) && caseScrapQuantities.includes(quantity);
}
