import { debounce } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { TraceOptions } from "../../../models/ApiTypes";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { EventFilter, activityFilterRenderType, caseIdFilterRenderType, caseYieldFilterRenderType, getFilterFriendlyName, isFilterEqual, productFilterRenderType, timeFilterRenderType } from "../../../models/EventFilter";
import { Spotlight } from "../../spotlight/Spotlight";
import Tabs, { TabStyles } from "../../tabs/Tabs";
import ActivityFilterEditor from "./activity-filter-editor/ActivityFilterEditor";
import AttributeFilterEditor from "./attribute-filter-editor/AttributeFilterEditor";
import CaseYieldFilterEditor from "./case-yield-filter-editor/CaseYieldFilterEditor";
import CaseIdFilterEditor from "./caseid-filter-editor/CaseIdFilterEditor";
import DurationFilterEditor from "./duration-filter-editor/DurationFilterEditor";
import ProductFilterEditor from "./product-filter-editor/ProductFilterEditor";
import SequenceFilterEditor from "./sequence-filter-editor/SequenceFilterEditor";
import TimeFilterEditor from "./time-filter-editor/TimeFilterEditor";
import { VariantFilterEditor } from "./variant-filter-editor/VariantFilterEditor";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { EventKeys } from "../../../models/EventKeys";
import UnmountWatcher from "../../../utils/Unmount";

const debouncedHandler = debounce((filter: EventFilter | undefined, onChange: (filter: EventFilter | undefined) => void) => {
    onChange(filter);
}, 200);

export type TabFilterEditorPropsType = {
    initialValue?: EventFilter;
    onChange?: (filter: EventFilter | undefined) => void;
    children?: React.ReactNode[] | React.ReactNode;
};

const filterTypeToPageMapping: { [id: string]: number } = {
    "product": 0,
    "caseId": 1,
    "caseTime": 2,
    "variant": 3,
    "caseDuration": 4,
    "caseYield": 5,
    "activity": 6,
    "caseSequence": 7,
    "caseAttributeText": 8,
    "caseAttributeFloat": 8,
    "caseAttributeInt": 8,
    "caseAttributeDatetime": 8,

};

export default function TabFilterEditor(props: TabFilterEditorPropsType) {
    let initialPage = 0;
    if (props.initialValue !== undefined) {
        if (props.initialValue?.renderType === activityFilterRenderType)
            initialPage = filterTypeToPageMapping["activity"];
        else if (props.initialValue.renderType === caseIdFilterRenderType)
            initialPage = filterTypeToPageMapping["caseId"];
        else if (props.initialValue?.renderType === productFilterRenderType)
            initialPage = filterTypeToPageMapping["product"];
        else if (props.initialValue.renderType === timeFilterRenderType)
            initialPage = filterTypeToPageMapping["caseTime"];
        else if (props.initialValue?.renderType === caseYieldFilterRenderType)
            initialPage = filterTypeToPageMapping["caseYield"];
        else {
            const key = Object.keys(props.initialValue)[0];
            initialPage = filterTypeToPageMapping[key];
        }
    }
    const disableTabSelection = props.initialValue !== undefined;

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const lastEmittedFilterRef = useRef<EventFilter | undefined | null>(null);

    const [tabPageIdx, setTabPageIdx] = useState<number>(initialPage);

    useEffect(() => {
        if (props.initialValue === undefined) {
            setTabPageIdx(0);
        }
    }, [props.initialValue]);

    useEffect(() => {
        settings.setFilterEditState({ editFilterPage: tabPageIdx });
    }, [tabPageIdx]);

    // Keeps track of the index of the tab the user last interacted with. Used for
    // tracking only.
    const lastTabInteractionIdx = useRef<number | undefined>(undefined);
    const { trackEvent } = useMatomo();

    const options: TraceOptions = {
        eventFilters: (settings.filters || []).filter((f, idx) => {
            // as discussed here https://gitlab.com/oniqofficial/general/oniq/-/merge_requests/647#note_681754923
            // we just drop the filter that is currently being edited regardless of the ordering
            // to show the user what effect its filter has on the results
            return settings.filterEditor.editFilterIndex === undefined ||
                idx !== settings.filterEditor.editFilterIndex;
        }),
        uploadId: session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        eventKeys: {
            ...session.project?.eventKeys,
            activityKeysGroup: settings.groupingKey,
        } as EventKeys
    };

    const hasProductIds = !!session.project?.eventKeys?.product;

    if (session.project?.eventKeys === undefined)
        return null;

    return <div className="tabFilterEditor">
        <UnmountWatcher componentWillUnmount={() => settings.set({
            previewFilters: undefined,
            filterEditor: {
                showFilterEditor: false,
                editFilter: undefined,
                editFilterIndex: undefined,
            }
        })
        } />
        <Tabs
            tabStyle={TabStyles.Classic}
            tabClassName="smallTextTabs textOnly"
            onChanged={(e, idx) => {
                setTabPageIdx(idx);
            }}
            selectedIndex={disableTabSelection ? initialPage : tabPageIdx}
            pages={[{
                label: "filters.productFilter",
                icon: "pen",
                isDisabled: disableTabSelection || !hasProductIds,
            }, {
                label: "filters.caseId.tabTitle",
                icon: "statistics",
                isDisabled: disableTabSelection,
            }, {
                label: "filters.timeFilter",
                icon: "info",
                isDisabled: disableTabSelection
            }, {
                label: "filters.variantFilter",
                icon: "variants2",
                isDisabled: disableTabSelection
            }, {
                label: "filters.durationFilter",
                icon: "hourglass",
                isDisabled: disableTabSelection
            }, {
                label: "filters.caseYield.tabTitle",
                icon: "caseYieldFilter",
                isDisabled: disableTabSelection,
            }, {
                label: "filters.activityFilter",
                icon: "excluded",
                isDisabled: disableTabSelection
            }, {
                label: "filters.sequenceFilter",
                icon: "sequence",
                isDisabled: disableTabSelection
            }, {
                label: "filters.attributeFilter",
                icon: "tag",
                isDisabled: disableTabSelection
            }]}>
            <>
                <div className="filterEditorPage">
                    <ProductFilterEditor
                        key={"product" + (settings.filterEditor.editFilterIndex ?? "")}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-Product" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth550">
                    <CaseIdFilterEditor
                        key={"case" + (settings.filterEditor.editFilterIndex ?? "")}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-Orders" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth550">
                    <TimeFilterEditor
                        key={"time" + (settings.filterEditor.editFilterIndex ?? "")}
                        options={options}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-Time" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth725">
                    <VariantFilterEditor
                        key={"variant" + (settings.filterEditor.editFilterIndex ?? "")}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-ProcessPath" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth550">
                    <DurationFilterEditor
                        key={"duration" + (settings.filterEditor.editFilterIndex ?? "")}
                        options={options}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-ThroughputTime" className="topRight" />
            </>
            <>
                <CaseYieldFilterEditor
                    key={"caseYield" + (settings.filterEditor.editFilterIndex ?? "")}
                    initialValue={props.initialValue}
                    onUpdate={receiveFilter}>
                    {props.children}
                </CaseYieldFilterEditor>
                <Spotlight id="Filter-Open-CaseYield" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth550">
                    <ActivityFilterEditor
                        key={"activity" + (settings.filterEditor.editFilterIndex ?? "")}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-Activity" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth550">
                    <SequenceFilterEditor
                        key={"sequence" + (settings.filterEditor.editFilterIndex ?? "")}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter} />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-Sequence" className="topRight" />
            </>
            <>
                <div className="filterEditorPage filterEditorMinWidth550">
                    <AttributeFilterEditor
                        key={"attribute" + (settings.filterEditor.editFilterIndex ?? "")}
                        initialValue={props.initialValue}
                        onUpdate={receiveFilter}
                    />
                    {props.children}
                </div>
                <Spotlight id="Filter-Open-Attribute" className="topRight" />
            </>

        </Tabs>
        <div></div>
    </div>;

    function receiveFilter(filter: EventFilter | undefined) {
        const lastFilter = lastEmittedFilterRef.current === null ? undefined : lastEmittedFilterRef.current;
        if (isFilterEqual(filter, lastFilter) || !props.onChange)
            return;

        // The user interacted with this tab, this is worth reporting to Matomo
        if (lastTabInteractionIdx.current !== tabPageIdx && filter !== undefined) {
            lastTabInteractionIdx.current = tabPageIdx;
            trackEvent({
                category: "Filters",
                action: "Editor interaction",
                name: getFilterFriendlyName(filter),
            });
        }

        if (lastEmittedFilterRef.current === null) {
            // Immediately emit initial filter change, debounce the rest
            lastEmittedFilterRef.current = filter;

            props.onChange!(filter);
            return;
        }

        lastEmittedFilterRef.current = filter;
        debouncedHandler(filter, props.onChange);
    }
}
