import React, { useRef } from "react";
import { createPortal } from "react-dom";
import i18n from "../../i18n";

export function TrayIconElement(props: {
    icon: string;
    iconClass?: string;
    title: string;
    id?: string;
    testId?: string;
    disabled?: boolean;
    onClick: () => void | Promise<void>;

    // The order of the tray element in the tray. The lower the number, the
    // more to the left the element will be rendered.
    order?: number;
}) {
    return <TrayElement>
        <div
            id={props.id}
            style={{ order: props.order ?? 0 }}
            data-testid={props.testId}
            className={"trayElement trayElementIcon" + (!props.disabled ? " clickable brandHover" : "")}
            title={i18n.t(props.title).toString()}
            onClick={props.onClick}>
            <svg className={"svg-icon xsmall" + (props.iconClass ? " " + props.iconClass : "")}>
                <use xlinkHref={"#" + props.icon} />
            </svg>
        </div>
    </TrayElement>;
}

/**
 * Component that renders a command tray to the closest element with the .tray
 * class attached.
 */
export function TrayElement(props: React.PropsWithChildren) {
    // Get node where this component would be rendered as a child.
    // We need this location in the DOM to start the search for the closest
    // tray element.
    let portal: JSX.Element | null = null;
    const fragRef = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setRenderCount] = React.useState(0);

    React.useEffect(() => {
        setRenderCount(renderCount => renderCount + 1);
    }, [
        fragRef.current,
    ]);

    if (fragRef.current) {
        const tray = findTrayNode(fragRef.current, fragRef.current);
        if (tray)
            portal = createPortal(props.children, tray);
    }

    return <div ref={fragRef}>
        {portal}
    </div>;

    // Searches the children for the node with the .tray class attached.
    // if it doesn't find one, it continues the search with the parent's children, 
    // excluding the ones it already searched.
    function findTrayNode(node: HTMLElement | null, lastChild: HTMLElement | undefined = undefined): HTMLElement | null {
        if (!node)
            return null;

        if (node.classList.contains("tray"))
            return node;

        for (const child of node.children) {
            if (child === lastChild)
                continue;

            if (child.classList.contains("tray"))
                return child as HTMLElement;
        }

        if (lastChild)
            // This is an upward scan, so we continue with the parent's children.
            return findTrayNode(node.parentElement, node);

        // We've scanned the DOM down to the leaf, so stop the recursion here.
        return null;
    }
}