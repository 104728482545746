import { range } from "lodash";
import React from "react";
import { useResizeObserver } from "./UseResizeObserver";
import colors from "../colors.json";

export type GridLayout = {
    width: number;
    height: number;
    numElementsPerRow: number;
    numRows: number;
    isScrollingNeeded: boolean;
}

export type GridLayoutOptions = {
    minWidth: number;
    minHeight: number;
    maxWidth?: number;
    maxHeight?: number;

    /**
     * When scrolling cannot be prevented, this is the height assigned to each row
     */
    desiredHeight: number;
    xGap?: number;
    yGap?: number;
}

/**
 * Layouts elements into a grid (without considering margins, make sure to use box-sizing: border-box!).
 * Given the minimum/maximum size of each element, the space will be assigned accordingly. Tries to prevent
 * scrolling.
 * @param container container
 * @param numElements Number of elements we have
 * @param desiredHeight when there's no way around scrolling use this height
 */
export function useGridLayout(container: React.RefObject<HTMLDivElement>, numElements: number, options: GridLayoutOptions): GridLayout | undefined {
    const size = useResizeObserver(container);

    if (size === undefined || numElements === 0)
        return undefined;

    const xGap = options.xGap ?? 0;
    const yGap = options.yGap ?? 0;

    const preferredNumELementsPerRow = range(1, numElements).filter(e => (numElements % e) === 0).concat(
        range(1, numElements).filter(e => (numElements % e) !== 0));

    let numElementsPerRow = 1;
    let bestScore = Number.MAX_SAFE_INTEGER;
    const isScrollingNeeded = false;

    for (const elementsPerRow of preferredNumELementsPerRow) {
        const contentWidth = size.width - (elementsPerRow - 1) * xGap;
        if ((contentWidth / elementsPerRow) < options.minWidth)
            continue;

        if (options.maxWidth !== undefined && (contentWidth / elementsPerRow) > options.maxWidth)
            continue;

        const numRows = Math.ceil(numElements / elementsPerRow);
        const minContainerHeight = numRows * options.minHeight;

        const needsScrolling = minContainerHeight >= size.height;

        // Calculate a score, penalize scrolling, prefer less rows and more elements per row
        const score = (needsScrolling ? 1000000 : 0) + minContainerHeight;

        if (score < bestScore) {
            bestScore = score;
            numElementsPerRow = elementsPerRow;
            needsScrolling;
        }
    }

    const elementWidth = Math.floor(((size.width - (numElementsPerRow - 1) * xGap) - colors.$scrollbarWidth) / numElementsPerRow);

    let numRows = Math.min(
        Math.ceil(numElements / numElementsPerRow),
        Math.ceil(size.height / options.minHeight)
    ) || 1;

    if (options.maxHeight)
        while (((size.height - (numRows - 1) * yGap) / numRows) > options.maxHeight)
            numRows++;

    const elementHeight = Math.floor(((numRows * options.minHeight + (numRows - 1) * yGap) < size.height) ? size.height / numRows : options.desiredHeight);

    return {
        width: elementWidth,
        height: elementHeight,
        numElementsPerRow,
        numRows,
        isScrollingNeeded,
    };
}

export function toGridLayout(layout: GridLayout | undefined): React.CSSProperties | undefined {
    if (!layout)
        return undefined;

    const rowHeight = layout.isScrollingNeeded ? `${layout.height}px` : "1fr";

    const gridTemplateColumns = range(0, layout.numElementsPerRow).map(() => "1fr").join(" ");
    const gridTemplateRows = range(0, layout.numRows).map(() => rowHeight).join(" ");

    return {
        gridTemplateColumns,
        gridTemplateRows,
        overflowY: layout.isScrollingNeeded ? "auto" : "hidden",
    };
}