import React, { useContext, useEffect } from "react";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext";
import { QuantityType, getAssignedQuantities, getQuantity, quantities } from "../../utils/Quantities";
import i18n from "../../i18n";
import Dropdown from "../../components/dropdown/Dropdown";
import { ProductDropdown } from "../../components/product-dropdown/ProductDropdown";
import { ProductIdentifier, SettingsContext, SettingsContextType } from "../../contexts/SettingsContext";
import { buildProductFilter, isProductFilter } from "../../utils/FilterBuilder";
import { LegacyAnalyzedValues, RcaType } from "../../contexts/ContextTypes";
import { capitalize } from "lodash";
import { BaseQuantityType, RcatypeTargets } from "../../models/ApiTypes";
import { useStatistics } from "../../hooks/UseStatistics";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { StartRcaButton, submitRca } from "./RcaCreationUtils";

export function CreateBottleneckRca() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);

    useEffect(() => {
        const productFilter = settings.filters.find(f => !!isProductFilter(f, false, session.project?.eventKeys)?.length);
        const products = isProductFilter(productFilter, false, session.project?.eventKeys);

        // Currently we support only selections of single products
        if (products?.length === 1 && settings.rca.product !== products[0])
            queueMicrotask(() => {
                settings.mergeSet({ rca: { product: products[0] } });
            });
    }, []);

    const filtersWithoutProductFilters = settings.filters.filter(f => {
        const r = isProductFilter(f, false, session.project?.eventKeys);
        return r === undefined || r.length === 0;
    });

    const isProductBottleneck = session.project?.settings?.bottleneckType === "product";

    return <div className="rcaContent">
        <div className="infoText" dangerouslySetInnerHTML={{ __html: isProductBottleneck ? i18n.t("rca.bottleneck.explainerProduct").toString() : i18n.t("rca.bottleneck.explainerGlobal").toString() }} />

        {/* <p>
            <a onClick={() => {
                openGlossary(session, {
                    de: "Engpass",
                    en: "Bottleneck",
                    jp: "Bottleneck"
                }[session.locale]);
            }}>{i18n.t("rca.bottleneck.hint")}</a>
        </p> */}

        {isProductBottleneck && <ProductDropdown filters={filtersWithoutProductFilters} className="dropdownLight mbl sizeConstrainedSelect" onChange={(p) => {
            settings.mergeSet({
                rca: { product: p }
            });
        }} value={settings.rca.product} />}

        {caseYieldQuantities.length > 1 && <>
            <h2>{i18n.t("rca.bottleneck.quantitySelection")}</h2>
            <QuantitySelector />
        </>}

        <StartButton />
    </div>;

    function StartButton() {
        const [stats, areStatsLoading] = useStatistics();
        const { trackEvent } = useMatomo();

        return <StartRcaButton
            title="rca.startAnalysis"
            type={RcaType.Bottleneck}
            disabled={areStatsLoading || (isProductBottleneck && settings.rca.product === "")}
            onClick={async () => {
                const quantity = quantities.find(q => q.id === settings.quantity);
                const key = "kpis.caseYieldRate" + capitalize(quantity?.baseQuantity);

                const filters = isProductBottleneck ? getProductBottleneckFilters(settings, session) : settings.filters;

                if (filters === undefined)
                    return;

                await submitRca(session,
                    settings,
                    RcaType.Bottleneck,
                    undefined,
                    key as RcatypeTargets,
                    false,
                    LegacyAnalyzedValues.OutputRate,
                    filters,
                    `/projects/${session.projectId}/analyses/bottleneck/bottleneck`,
                    stats.numFilteredTraces ?? 0,
                    (value: number | undefined, locale: string) => {
                        const formatter = getQuantity(settings.quantity, true)?.formatter;
                        if (formatter)
                            return formatter(value, undefined, locale);
                        return "";
                    },
                    undefined,
                    trackEvent,
                    // Group by order sequence for product bottleneck otherwise group by machine 
                    isProductBottleneck,
                );

                if (isProductBottleneck)
                    settings.mergeSet({
                        filters: filters,
                    });
            }} />;
    }

    function QuantitySelector() {
        const [quantity, setQuantity] = React.useState<BaseQuantityType>(() => {
            if (caseYieldQuantities.map(q => q.baseQuantity).includes(settings.quantity as BaseQuantityType))
                return settings.quantity as BaseQuantityType;

            const quantity = caseYieldQuantities[0]?.baseQuantity ?? "count";

            queueMicrotask(() => {
                settings.set({ quantity });
            });

            return quantity;
        });

        const options = caseYieldQuantities.map(q => {
            return {
                label: i18n.t(q.name),
                value: q.id,
            };
        });
        return <Dropdown
            className="dropdownLight sizeConstrainedSelect"
            options={options}
            value={options.find(o => o.value === quantity) ?? options[0]}
            onChange={e => {
                const quantity = e!.value as BaseQuantityType;
                settings.set({ quantity });
                setQuantity(quantity);
            }}
        />;
    }

    function getProductBottleneckFilters (settings: SettingsContextType, session: SessionContextType) {
        const rcaProductFilter = buildProductFilter({
            name: settings.rca.product,
        } as ProductIdentifier, false, session);

        if (!rcaProductFilter)
            return undefined;

        return [...filtersWithoutProductFilters, rcaProductFilter];
    }
}