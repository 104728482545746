import React from "react";

export type ToastPropsType = {
    type?: ToastTypes;
    className?: string;
    children: React.ReactNode;
    visible: boolean;
    onClose?: () => void;
    style?: React.CSSProperties;
};

export enum ToastTypes {
    Warning = 1,
    Info = 2,
}

export default function Toast(props: ToastPropsType) {
    const meta = {
        [ToastTypes.Warning]: {
            icon: "#warning",
            cssClass: "warningToast",
        },
        [ToastTypes.Info]: {
            icon: "#radix-info-circled",
            cssClass: "infoToast",
        }
    };

    const classNames: string[] = ["toast"];
    if (props.className !== undefined)
        classNames.push(props.className);

    return <>{props.visible && <div style={props.style} className={classNames.join(" ")} data-testid="toast">
        <div className={meta[props.type ?? ToastTypes.Warning].cssClass}>
            <div>
                <svg className="svg-icon large"><use xlinkHref={meta[props.type ?? ToastTypes.Warning].icon} /></svg>
            </div>
            <div className="toastContent">
                {props.children}
            </div>
            {props.onClose && <div className="toastCloser" onClick={props.onClose}>
                <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-cross-1" /></svg>
            </div>}
        </div>
    </div>}</>;
}