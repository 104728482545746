import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../i18n";
import { EditFavoritesModal } from "./EditFavoritesModal";
import { SettingsContext, applyViewConfiguration } from "../../contexts/SettingsContext";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import { IModal } from "../../components/modal/Modal";
import { uniq } from "lodash";
import { ValueSpinner } from "../../components/value-spinner/ValueSpinner";
import { Spotlight } from "../../components/spotlight/Spotlight";
import { useNavigate } from "react-router-dom";
import { Api } from "../../api/Api";
import { ViewConfigurations } from "../../models/ApiTypes";

export function FavoritesView() {
    const settings = useContext(SettingsContext);

    const addFavoritesModalRef = useRef<IModal>(null);

    const [favoriteToEdit, setFavoriteToEdit] = useState<ViewConfigurations | undefined>();

    const [favoriteList, setFavoriteList] = useState<ViewConfigurations[]>([]);

    const navigate = useNavigate();

    // Fetch project names from the API and populate a lookup projectId => name
    const projectIds = uniq(favoriteList.map(t => t.projectId).filter(id => id !== undefined));
    const [projectNameLookup, setProjectNameLookup] = useState<{ [id: string]: string }>({});

    useEffect(() => {
        getFavorites();
    }, []);

    useEffect(() => {
        for (const projectId of projectIds) {
            if (projectNameLookup[projectId] !== undefined)
                continue;

            Api.getProject(projectId).then(project => {
                setProjectNameLookup((state) => {
                    return {
                        ...state,
                        [projectId]: project.name,
                    };
                });
            });
        }
    }, [
        JSON.stringify(projectIds),
    ]);

    const tilesMarkup = useMemo(() => {
        return favoriteList.map(favorite => {
            const projectName = projectNameLookup[favorite.projectId ?? ""];

            return <div
                className="tile tileInteractive"
                title={favorite.name} key={favorite.id}
                onClick={() => {
                    applyViewConfiguration(favorite, settings, navigate);
                }}>
                <div className="tileFavoriteContent">
                    <div className="tileTitle" title={favorite.name}>
                        {favorite.name}
                    </div>

                    {favorite.description !== undefined && <div className="description" title={favorite.description}>
                        {favorite.description}
                    </div>}

                    <div className="extra">
                        <ValueSpinner isLoading={projectName === undefined}>
                            {projectName}
                        </ValueSpinner>
                    </div>
                </div>
                <div className="selection" onClick={(e) => {
                    getFavoriteSelected(favorite);
                    addFavoritesModalRef.current?.show();
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <svg className="svg-icon xxsmall brandHover">
                        <use xlinkHref="#radix-pencil-1" />
                    </svg>
                </div>
            </div>;
        });
    }, [
        favoriteList,
        projectNameLookup,
    ]);

    return <>
        {tilesMarkup.length > 0 ?
            <div className="projectContainer">
                <div className="greetingContainer">
                    <div className="greeting">{i18n.t("favorites.headline")}</div>
                </div>
                <div className="tileContainer" data-testid="container">
                    {tilesMarkup}
                </div>
            </div>
            :
            <NoDataAvailable visible={true} title="favorites.noData" message="favorites.noDataDescription" />}
        <EditFavoritesModal
            ref={addFavoritesModalRef}
            {...favoriteToEdit}
            onDone={async () => { await getFavorites(); }} />

        <Spotlight id="Favorites" className="bottomRight mb mrl fixed" />
    </>;

    async function getFavorites() {
        const favoritesResponse = upgradeFavorites(await Api.getViewConfigurations({}));
        setFavoriteList(favoritesResponse);
    }

    async function getFavoriteSelected(favorite: ViewConfigurations) {
        const favoriteSelected = await Api.getViewConfigurationsById(favorite.id ?? "");
        setFavoriteToEdit(favoriteSelected);
    }

    /**
     * This function makes old favorites compatible with the current state of the app.
     */
    function upgradeFavorites(favorites: ViewConfigurations[]): ViewConfigurations[] {
        return favorites.map(input => {
            const favorite = { ...input };

            // #region https://gitlab.com/ONIQofficial/general/oniq/-/issues/2649
            
            // We only need to adapt the paths, because only the /node views have
            // been used in favorites so far!
            if (favorite.viewId === "output/process/node")
                favorite.viewId = "output/process/benchmarking";

            if (favorite.viewId === "timings/process/node")
                favorite.viewId = "timings/process/benchmarking";

            // #endregion

            return favorite;
        });
    }
}