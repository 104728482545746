/**
 * Displays the configuration of a single attribute filter element.
 */

import React, { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";
import { quantities } from "../../../utils/Quantities";

type CaseYieldFilterProps = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function CaseYieldFilterStatus(props: CaseYieldFilterProps): JSX.Element | null {
    const session = useContext(SessionContext);
    const fromFilter = props.filter!.filters![0].caseAttributeFloat ?? props.filter!.filters![0].caseAttributeInt;
    const toFilter = props.filter!.filters![1].caseAttributeFloat ?? props.filter!.filters![1].caseAttributeInt;

    const fromValue = fromFilter!.ge;
    const toValue = toFilter!.le;

    if (!session?.project?.eventKeys)
        return null;

    const kpi = (props.filter as any).kpi as string;
    const quantity = (props.filter as any).quantity as string;

    const unit = quantities.find(q => q.baseQuantity === quantity && !q.isFrequency)!.unit;

    const formatterParams = { 
        locale: session.numberFormatLocale, 
        numDigits: 1, 
    };

    const info = {
        kpiName: i18n.t({
            caseOutput: "common.output",
            caseYield: "common.yield",
            caseScrap: "common.scrap",
        }[kpi] ?? "" as string),
        quantityName: i18n.t("quantities." + quantity),
        from: unit.formatter(fromValue, formatterParams),
        to: unit.formatter(toValue, formatterParams),
    };

    return <div className={"filter caseIdFilter" + (props.isEdited ? " edit" : "")} onClick={() => {
        if (props.onClick)
            props.onClick();
    }}>
        <svg className="svg-icon small"><use xlinkHref="#radix-bar-chart" /></svg>
        <div>
            {i18n.t("filters.caseYield.statusTitle", info)} <br />
            <b>{i18n.t("filters.caseYield.status", info)}</b>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;
}