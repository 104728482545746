import { useContext, useState } from "react";
import { SessionContext, SessionType } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { AggTypes, ApiPaginationOptions, CaseDeviationStatisticsSchema, CaseStatisticsTraceOptions, CustomKpi, disableAllCalcOptions, GetCaseDeviationResult, GetCaseStatisticsResponse, GetDeviationRequest, TraceOptions } from "../models/ApiTypes";
import { CaseAggregationStatistics, CaseStatistics } from "../models/Case";
import { GroupingKeys } from "../models/Dfg";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { uniq } from "lodash";
import { defaultCaseLimit } from "../Global";
import { getPlanningState } from "../utils/SettingsUtils";

// This hook hides the request logic from the consuming views. There's quite some logic involved that
// I believe would belong in the API itself.
// Some projects (=those without routings) require requests to a legacy endpoint with a different response
// format. This hook provides a unified interface for both cases.
//
// It replaces the useCaseDeviation and useCaseStatistics hook that come from another time.
export type UseCasesRequestType = ApiPaginationOptions & TraceOptions & {
    useActivityPasses?: boolean;
    consolidatePasses?: boolean;
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculateUnknownStats?: boolean;
    calculatePureObjectStats?: boolean;
    calculateProductionStats?: boolean;
    calculateFailureStats?: boolean;
    calculateSetupStats?: boolean;
    calculateInterruptionStats?: boolean;
    calculatePassChangeStats?: boolean;
    calculateBusyStats?: boolean;
    calculatePassStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    calculatePlanned?: boolean;
    calculateDeviations?: boolean;
    calculateVariantsScore?: boolean;
    calculateActivityKeysGroupVariants?: GroupingKeys[];
    aggs: AggTypes[];
    customKpis: CustomKpi[];
};

export type UseCasesResponseType = {
    cases: CaseDeviationStatisticsSchema[],
    log: {
        actual?: CaseAggregationStatistics;
        planned?: CaseAggregationStatistics;
        deviation?: CaseAggregationStatistics;
    }
};

export function useCases(request?: Partial<UseCasesRequestType>, options?: ApiHookOptions<UseCasesResponseType>):
    [UseCasesResponseType | undefined, boolean] {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [result, setResult] = useState<UseCasesResponseType | undefined>(undefined);

    // Decide on which endpoint to use.
    const isDeviationRequested = request?.calculateDeviations || request?.calculatePlanned;

    const {hasRoutings, hasPlanningLog} = getPlanningState(session);

    const useDeviationEndpoint = isDeviationRequested &&
        hasPlanningLog && !hasRoutings;

    const useRegularEndpoint = !isDeviationRequested || hasRoutings || (isDeviationRequested && !hasPlanningLog && !hasRoutings);

    const eventFilters = request?.eventFilters ?? settings.previewFilters ?? settings.filters ?? [];
    const eventKeys = session.project?.eventKeys ?? {} as EventKeys;
    const eventKeysPlan = session.project?.eventKeysPlan ?? {} as EventKeys;

    const deviationRequest: GetDeviationRequest = {
        actual: {
            uploadId: session.project?.uploadId ?? "",
            eventKeys,
            eventFilters,
            uploads: session.project?.uploads,
        },
        planned: {
            eventKeys: eventKeysPlan,
            uploadId: session.project?.uploadIdPlan ?? "",
        },
        consolidatePasses: true,
        limit: defaultCaseLimit,
        ...disableAllCalcOptions,
        ...request,
        sort: fixSortOrder(session, request?.sort, isDeviationRequested),
    };

    // State is set using the onData callback, so we don't need to return the result here.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, isDeviationLoading] = useApi(Datastores.getPerCaseDeviationStatistics, deviationRequest, [JSON.stringify(deviationRequest)], {
        disable: !useDeviationEndpoint || !session.project?.uploadId,
        onData: (data) => {
            const mapped = mapFromDeviationResponse(data);
            setResult(mapped);
            options?.onData?.(mapped);
        },
    });

    const regularRequest: CaseStatisticsTraceOptions = {
        eventFilters,
        eventKeys,
        uploadId: session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        limit: defaultCaseLimit,
        ...disableAllCalcOptions,
        ...request,
        calculateDeviations: request?.calculateDeviations && hasRoutings,
        calculatePlanned: request?.calculatePlanned && hasRoutings,
        sort: fixSortOrder(session, request?.sort, isDeviationRequested),
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [__, isRegularLoading] = useApi(Datastores.getCaseStatistics, regularRequest, [JSON.stringify(regularRequest)], {
        disable: !useRegularEndpoint || !session.project?.uploadId,
        onData: (data) => {
            const mapped = mapFromResponse(data);
            setResult(mapped);
            options?.onData?.(mapped);
        }
    });

    return [result, isDeviationLoading || isRegularLoading];
}

/**
 * The legacy API requires "actual." prefix for the actual values when
 * deviations are enabled. This is not required for the new API.
 */
export function fixSortOrder(session: SessionType, sort?: string[], enableDeviations?: boolean) {
    if (!sort) return undefined;

    function removePrefix(s: string) {
        return s.replaceAll("actual.", "").replaceAll("planned.", "").replaceAll("deviation.", "");
    }

    return sort.map(s => {
        // We're deciding on prefixes here.

        // Deviation endpoint: If the sort key isn't prefixed, we need to add "actual.".
        // Regular endpoint: If the sort key is prefixed with "actual.", we need to remove it.
        const sortOrder = s.startsWith("-") ? "-" : "";
        const propName = s.replace("-", "");
        const isPrefixed = propName?.startsWith("actual.") ||
            propName?.startsWith("planned.") ||
            propName?.startsWith("deviation.");

        const hasPlanningData = session.project?.uploadIdPlan !== undefined &&
            session.project?.eventKeysPlan !== undefined;

        const hasRoutings = session.project?.uploads?.routings !== undefined;

        const isDeviationEndpoint = enableDeviations && hasPlanningData && !hasRoutings;

        // Deviation endpoint
        if (isDeviationEndpoint) {
            if (propName === "name")
                // Don't prefix
                return `${sortOrder}${removePrefix(propName)}`;

            if (propName.endsWith("count"))
                return `${sortOrder}caseCount`;

            return `${sortOrder}${!isPrefixed ? "actual." : ""}${propName}`;
        }

        // Regular endpoint
        const result = isPrefixed? s.replace("actual.", "") : s;
        return enableDeviations ? result : result.replaceAll("deviation.", "");
    });
}

function mapFromResponse(data: GetCaseStatisticsResponse) {
    const actualMap = new Map<string, CaseStatistics>((data.cases ?? []).map(c => [c.id, c]));
    const plannedMap = new Map<string, CaseStatistics>((data.planned?.cases ?? []).map(c => [c.id, c]));
    const deviationMap = new Map<string, CaseStatistics>((data.deviation?.cases ?? []).map(c => [c.id, c]));

    const ids = uniq(Array.from(actualMap.keys()).concat(Array.from(plannedMap.keys())).concat(Array.from(deviationMap.keys())));

    const result: UseCasesResponseType = {
        cases: ids.map(id => {
            return {
                id,
                actual: actualMap.get(id),
                planned: plannedMap.get(id),
                deviation: deviationMap.get(id),
            };
        }),
        log: {
            actual: data.log,
            planned: data?.planned?.log,
            deviation: data?.deviation?.log,
        },
    };

    return result;
}

function mapFromDeviationResponse(data: GetCaseDeviationResult) {
    const result: UseCasesResponseType = {
        cases: (data.cases ?? []).map(c => {
            return {
                ...c,
            };
        }),
        log: {
            actual: {
                ...data.log.actual,
                count: data.log.actual?.count ?? data.log.count ?? 0,
            },
            planned: data.log.planned,
            deviation: data.log.deviation,
        }
    };

    return result;
}
