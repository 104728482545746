import { debounce } from "lodash";
import React, { useCallback, useState } from "react";

type RangeInputPropsType = {
    label?: string;
    min: number;
    max: number;
    buttonIncrements?: number;
    initialValue?: number;
    valueFormatter?: (value: number) => string;
    onValueChanged?: (value: number) => void;
    debounceChangeMs?: number;
};

export default function RangeInput(props: RangeInputPropsType) {
    const [value, setValue] = useState<number>(props.initialValue || 0);

    const debouncedHandler = useCallback(debounce((nextValue: number) => props.onValueChanged!(nextValue), props.debounceChangeMs), []);
    const changeHandler = props.debounceChangeMs ?
        debouncedHandler :
        (nextValue: number) => props.onValueChanged!(nextValue);

    const showHeader = !!props.label || !!props.valueFormatter;

    return <div className="element"
        onWheel={(e) => { add((props.buttonIncrements || 10) * Math.sign(e.deltaY)); }}
    >
        {showHeader && <table>
            <tbody>
                <tr>
                    <td>{props.label}</td>
                    <td>{props.valueFormatter ? props.valueFormatter(value) : value}</td>
                </tr>
            </tbody>
        </table>}
        <div className="slider">
            <div className="button" onClick={() => { add(-(props.buttonIncrements || 10)); }}>-</div>
            <input type="range" min={props.min} max={props.max} value={value} onChange={(e) => {
                const value = +e.target.value;
                updateValue(value);
            }} />
            <div className="button" onClick={() => { add(props.buttonIncrements || 10); }}>+</div>
        </div>
    </div>;

    function add(delta: number) {
        const newValue = Math.max(props.min, Math.min(props.max, value + delta));
        updateValue(newValue);
    }

    function updateValue(v: number) {
        setValue(v);
        if (props.onValueChanged !== undefined)
            changeHandler(v);
    }
}