import React, { useContext } from "react";
import i18n from "../../i18n";
import { SettingsContext } from "../../contexts/SettingsContext";

export type KpiMatrixHighlightControlProps = {
    label?: string;
    disabled?: boolean;
};

export function KpiHighlightControls(props: KpiMatrixHighlightControlProps) {
    const settings = useContext(SettingsContext);

    return <div className="highlightCheckbox">
        <div className={"noselect" + (props.disabled ? " disabled" : "")}>
            <label>
                <input type="checkbox" disabled={props.disabled} id="checkbox-events" className="checkbox" checked={settings.kpi.highlightDeviations} onChange={(e) => { settings.mergeSet({ kpi: { highlightDeviations: e.target.checked } }); }} /><label htmlFor="checkbox-events" />
                {i18n.t(props.label ?? "common.highlights")}
            </label>
        </div>
    </div>;
}

export function KpiMatrixHighlightControls(props: KpiMatrixHighlightControlProps) {
    const settings = useContext(SettingsContext);

    return <div className="highlightCheckbox">
        <div className={"noselect" + (props.disabled ? " disabled" : "")}>
            <label>
                <input type="checkbox" disabled={props.disabled} id="checkbox-events" className="checkbox" checked={settings.kpiMatrix.highlightDeviations} onChange={(e) => { settings.mergeSet({ kpiMatrix: { highlightDeviations: e.target.checked } }); }} /><label htmlFor="checkbox-events" />
                {i18n.t(props.label ?? "common.highlights")}
            </label>
        </div>
    </div>;
}

export function GraphHighlightCheckbox(props: KpiMatrixHighlightControlProps) {
    const settings = useContext(SettingsContext);
    return <div className="highlightCheckbox">
        <div className={"noselect" + (props.disabled ? " disabled" : "")}>
            <label>
                <input type="checkbox" id="checkbox-transitions" className="checkbox" checked={settings.graph.highlight} onChange={(e) => { settings.setGraph({ highlight: e.target.checked }); }} /><label htmlFor="checkbox-transitions" />
                {i18n.t("common.highlights")}
            </label>
        </div>
    </div>;
}