import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import EventlogTable from "../../components/eventlog-table/EventlogTable";
import FilterEditor from "../../components/filter-editor/FilterEditor";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";

export default function ProjectLog() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const { projectId } = useParams<{
        projectId?: string
    }>();
    
    session.setProject(projectId);

    return <div className={settings.filterEditor.showFilterEditor ? "filterExpanded projectLog" : "projectLog"}>
        <div className="logContainer">
            <EventlogTable />
        </div>
        <FilterEditor />
    </div>;
}