import { Vector } from "./Vector";

export class Casteljau {
    /**
     * Calculates a spline at time t with respect to the control points provided using
     * Casteljau's Algorithm (https://en.wikipedia.org/wiki/De_Casteljau%27s_algorithm)
     * @param t Value [0..1]
     * @param controlPoints Spline control points. 3 points are needed for quadratic curves, 4 for cubic ones
     */
    static getSplinePoint(t: number, controlPoints: Vector[]) {
        let points = [...controlPoints];

        while (points.length > 1) {
            const nextPoints: Vector[] = [];
            for (let i = 1; i < points.length; i++) {
                nextPoints.push(this.interpolate(t, points[i - 1], points[i]));
            }

            points = nextPoints;
        }
        return points[0];
    }

    private static interpolate(t: number, a: Vector, b: Vector) {
        return b.subtract(a).multiply(t).add(a);
    }
}