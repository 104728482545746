import React, { useContext, useRef } from "react";
import { AutoCenteringModes, IDfGraph, ProcessGraph, ZoomControlLocations, getEdgeColor, getEdgeHighlightColor, getLegendProps } from "../../components/dfg/DfGraph";
import { SettingsContext } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { getCustomKpisEquipmentStats, getEdgeStat, isTerminalNodeId } from "../../utils/DfgUtils";
import { getNodeMarkupValueStream } from "../../components/dfg/nodes/NodeMarkupFactory";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { ALL_OBJECT_INDICATOR, GroupingKeys } from "../../models/Dfg";
import { Formatter } from "../../utils/Formatter";
import { getEquipmentNodeKpiValue, getMainNodeStat } from "../../utils/MainNodeKpi";
import { SessionContext } from "../../contexts/SessionContext";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { useEquipmentStats } from "../../hooks/UseEquipmentStats";
import { EventKeys } from "../../models/EventKeys";
import { getAllowedKpis, getKpiDefinition } from "../../models/Kpi";

export function ValueStreamGraph(props: { kpis: KpiTypes[]}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const dfgRef = useRef<IDfGraph>(null);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const equipmentStatsOptions = {
        ...getCustomKpisEquipmentStats(settings, session, [KpiTypes.OverallEquipmentEffectiveness]),
        calculateNodes: true,
        calculateActivityValues: true,
    };

    const allowedKpis = getAllowedKpis(session, settings, props.kpis, false);

    const requiresEquipmentStats = allowedKpis.some(k => getKpiDefinition(k, {settings, session})?.isEquipmentStatsKpi);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, {session, settings});

    const [equipmentStats, isEquipmentStatsLoading] = useEquipmentStats({
        ...equipmentStatsOptions,
        eventKeys: {
            ...session.project!.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: !requiresEquipmentStats ||
            !session.project?.uploadId ||
            !session.project?.eventKeys?.passId,
    });

    if (!kpiDefinition)
        return null;

    return <>
        <ProcessGraph
            isLoading={requiresEquipmentStats && isEquipmentStatsLoading}
            ref={dfgRef}
            isObjectCentric={isObjectCentric}
            legend={getLegendProps(settings.kpi.selectedKpi)}
            zoomControlLocation={ZoomControlLocations.FarRight}
            analysis={AnalysisType.ValueStream}
            kpiTypes={allowedKpis}
            markupFunc={(node, settings, session) => {
                return getNodeMarkupValueStream(node, equipmentStats?.equipment, kpiDefinition, settings, session);
            }}
            edgeLabelFunc={(edge, settings, session) => {
                const edgeStats = getEdgeStat(edge, settings, session, { kpiType: KpiTypes.QueuingTime, statistic: StatisticTypes.Mean });
                if (isTerminalNodeId(edge.from) || isTerminalNodeId(edge.to))
                    return undefined;
                return Formatter.formatDurationShort(edgeStats, undefined, session.numberFormatLocale);
            }}
            nodeHighlightStatFunc={(node) => {
                if (isObjectCentric && !node.objects?.map(o => o.type).includes(settings.graph.objectType) && settings.graph.objectType !== ALL_OBJECT_INDICATOR)
                    return;

                return kpiDefinition.isEquipmentStatsKpi ? getEquipmentNodeKpiValue(node, equipmentStats?.equipment, session, settings) : getMainNodeStat(node, settings, session) ??
                    getMainNodeStat(node, settings, session, { isObjectCentric: false });
            }}
            edgeColorFunc={(edge) => getEdgeColor(edge, settings, session)}
            edgeHighlightStatFunc={(edge) => {
                return getEdgeStat(edge, settings, session, { kpiType: KpiTypes.QueuingTime, statistic: StatisticTypes.Mean });
            }}
            edgeHighlightColorFunc={(edge, stat, minStatistic, maxStatistic, scale) => {
                if (scale === undefined)
                    return undefined;

                return getEdgeHighlightColor(edge, scale, settings, session);
            }}
            centerMode={AutoCenteringModes.None}
        />
        <BackButtonTrayElement />
        <DownloadDfgTrayElement graph={dfgRef.current} filename="workflows.taktTime.title" />
    </>;
}