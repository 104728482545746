import { uniq } from "lodash";

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

export class ObjectMerger {
    /**
     * Merges multiple partial objects together, the results are also partial.
     * This is an out-of-place operation, the input elements are not modified.
     */
    static mergePartialObjects<T>(arr: DeepPartial<T>[], ignoreUndefined = true) {
        if (!arr?.length)
            throw "Cannot merge empty array";

        let result: any = {};
        for (const item of arr)
            result = ObjectMerger.merge(result, item, ignoreUndefined);
        
        return result as DeepPartial<T>;
    }

    /**
     * Merges multiple objects together. The first element is expected to be a complete 
     * (i.e. non-partial) instance, the rest may be partials. The result is a complete
     * instance. This is an out-of-place operation, the input elements are not modified.
     */
    static mergeObjects<T>(arr: [T, ...DeepPartial<T>[]], ignoreUndefined = true) {
        if (!arr?.length)
            throw "Cannot merge empty array";

        let result: any = {};
        for (const item of arr)
            result = ObjectMerger.merge(result, item, ignoreUndefined);
        
        return result as T;
    }

    static mergeObject<T>(a: T, b: DeepPartial<T> | undefined, ignoreUndefined = true): T {
        return ObjectMerger.merge(a, b, ignoreUndefined) as T;
    }

    private static merge(a: any, b: any, ignoreUndefined: boolean) {
        if (b === undefined)
            return a;
        
        const c: any = { };
        const bProps = Object.getOwnPropertyNames(b);
        const distinctProps = uniq(Object.getOwnPropertyNames(a).concat(bProps));
    
        for (const propName of distinctProps) {
            const aProp = a[propName];
            const bProp = b[propName];
    
            if (Array.isArray(aProp) && Array.isArray(bProp)) {
                c[propName] = bProp ?? aProp;
                continue;
            } 
            
            if (typeof aProp === "object" && aProp !== null && 
                typeof bProp === "object" && bProp !== null) {
                c[propName] = ObjectMerger.merge(aProp, bProp || {}, ignoreUndefined);
                continue;
            } 
            
            if (bProp !== undefined || (!ignoreUndefined && bProps.indexOf(propName) >= 0)) {
                c[propName] = bProp;
                continue;
            }

            c[propName] = bProp ?? aProp;
        }
    
        return c;
    }
}