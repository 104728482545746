import React from "react";

export enum TileSelectionModes {
    Starrable,
    Pinnable,
    None
}

export type TilePropsType = {
    title?: string;
    description?: string;
    className?: string;
    extra?: React.ReactNode | React.ReactNode[] | string | undefined;
    selectionMode?: TileSelectionModes;
    isAnalyses?: boolean;
    isSelected?: boolean;
    analysesIcon?: string;
    onSelectionChanged?: (newState: boolean) => void;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const icons = [
    {
        mode: TileSelectionModes.Pinnable,
        isSelected: false,
        icon: "#radix-drawing-pin"
    }, {
        mode: TileSelectionModes.Pinnable,
        isSelected: true,
        icon: "#radix-drawing-pin-filled"
    }, {
        mode: TileSelectionModes.Starrable,
        isSelected: false,
        icon: "#radix-star"
    }, {
        mode: TileSelectionModes.Starrable,
        isSelected: true,
        icon: "#radix-star-filled"
    }
];

export default function Tile(props: TilePropsType) {
    const selectionMode = props.selectionMode ?? TileSelectionModes.None;
    const icon = icons.find(i => i.mode === selectionMode && i.isSelected === props.isSelected);

    return <div
        onClick={(e) => {
            if (props.onClick !== undefined)
                props.onClick(e);
        }}
        className={(props.onClick !== undefined ? "tile tileInteractive" : "tile") + (props.className !== undefined ? " " + props.className : "")}>
        <div className="tileContent">

            {props.isAnalyses &&
            <div className="analysesIcon">
                <svg className="svg-icon small">
                    <use xlinkHref={props.analysesIcon} />
                </svg>
            </div>
            }

            <div className="tileText">
                {props.title !== undefined && <div className="tileTitle" title={props.title}>
                    {props.title}
                </div>}

                {props.description !== undefined && <div className="description" title={props.description}>
                    {props.description}
                </div>}

                {props.extra !== undefined && <div className="extra">
                    {props.extra}
                </div>}

            </div>

        </div>

        {props.selectionMode !== TileSelectionModes.None && <div className="selection">
            <svg onClick={(e) => {
                if (props.onSelectionChanged !== undefined) {
                    props.onSelectionChanged(!props.isSelected);
                    e.stopPropagation();
                }
            }} className="svg-icon xsmall"><use xlinkHref={icon?.icon} /></svg>
        </div>}
    </div>;
}
