import React, { useContext } from "react";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { NodeMatrixSchema } from "../../../models/ApiTypes";
import { StatisticTypes } from "../../../models/KpiTypes";
import { StatsSection, StatsSubSection } from "../../stats-section/StatsSection";
import { StatsRowValue } from "../../stats-section/StatsRowValue";
import { Formatter } from "../../../utils/Formatter";
import { SetupMatrixCellProps } from "../SetupMatrixCell";
import { UnmarkedSetupStatistics } from "../../../views/setup/UnmarkedSetupStatistics";


export function MatrixSetupStats() {
    const settings = useContext(SettingsContext);

    const element = settings.selection.matrixElement as SetupMatrixCellProps;
    if (!element) 
        return <UnmarkedSetupStatistics />;

    const from = element.from as NodeMatrixSchema;
    const to = element.to as NodeMatrixSchema;
    const title = `${from?.activityValues?.product?.value} ➞ ${to?.activityValues?.product?.value}`;
    return getMatrixStats({
        title,
        isLoading: false,
        count: element.transition?.frequencyStatistics.sum,
        mean: element.transition?.timeStatistics.mean,
        median: element.transition?.timeStatistics.median,
        max: element.transition?.timeStatistics.max,
        min: element.transition?.timeStatistics.min,
        comparisonMean: element.comparisonTransition?.timeStatistics.mean,
    }, settings.kpi.statistic);
}

export function getMatrixStats(data: {
    title: string,
    count: number | undefined,
    isLoading: boolean | undefined,
    max: number | undefined,
    min: number | undefined,
    mean: number | undefined,
    median: number | undefined,
    comparisonMean: number | undefined,
}, selectedStatistic: StatisticTypes) {
    return <StatsSection title={data.title}>
        <StatsSubSection title="common.setup">
            <StatsRowValue label="common.actionFrequency" value={data.count} isLoading={data.isLoading} />
            <StatsRowValue label="common.mean" value={data.mean} unit={Formatter.units.durationShort} isHighlight={selectedStatistic === StatisticTypes.Mean} isLoading={data.isLoading} />
            {data.median !== undefined && <StatsRowValue label="common.statistics.median" value={data.median} unit={Formatter.units.durationShort} isHighlight={selectedStatistic === StatisticTypes.Median} isLoading={data.isLoading} />}
            <StatsRowValue label="common.statistics.max" value={data.max} unit={Formatter.units.durationShort} isLoading={data.isLoading} />
            <StatsRowValue label="common.statistics.min" value={data.min} unit={Formatter.units.durationShort} isLoading={data.isLoading} />
        </StatsSubSection>

        {data.comparisonMean !== undefined && isFinite(data.comparisonMean) && <StatsSubSection title="common.plannedSetupTime">
            <StatsRowValue label="common.mean" value={data.comparisonMean} unit={Formatter.units.durationShort} isHighlight={true} isLoading={data.isLoading} />
        </StatsSubSection>} 
    </StatsSection>;
}
