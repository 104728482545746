import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { EventKeys } from "../../models/EventKeys";
import { Project } from "../../models/Project";
import { Upload } from "../../models/Upload";
import { Datastores } from "../../utils/Datastores";
import { MappingStateType } from "./AssignColumns";
import IdentifyColumns from "./IdentifyColumns";
import { Api } from "../../api/Api";


const initialOrderTrackingMappingStates = [{
    name: "common.orderNetworkId",
    key: "caseId",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.confirmation",
    key: "operation",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.startTime",
    key: "startTime",
    isOptional: false,
    allowedTypes: ["datetime"]
}, {
    name: "common.endTime",
    key: "endTime",
    isOptional: true,
    allowedTypes: ["datetime"]
}, {
    name: "common.machine",
    key: "machine",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.caseId",
    key: "machineType",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.product",
    key: "location",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.orderSequence",
    key: "passId",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.objectId",
    key: "object",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.objectType",
    key: "objectType",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.eventId",
    key: "eventId",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}
];

export default function EditOrderTrackingUpload() {
    const navigate = useNavigate();

    const { projectId } = useParams<{
        projectId: string,
    }>();

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    session.setProject(projectId);

    const [project, setProject] = useState<Project | undefined>();

    const [subscriptionId, setSubscriptionId] = useState<number>(0);
    useEffect(() => {
        const subscriptionId = Datastores.getUploadRawEvents.getSubscriptionId();
        setSubscriptionId(subscriptionId);

        return () => {
            Datastores.getUploadRawEvents.cancelSubscription(subscriptionId);
        };
    }, []);

    // Fetch upload including all properties from API
    const [upload, setUpload] = useState<Upload | undefined>();
    const [mappingStates, setMappingStates] = useState<MappingStateType[]>(initialOrderTrackingMappingStates);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    // Project ID is only present if we're re-visiting this page from an existing
    // project.
    useEffect(() => {
        if (projectId)
            Api.getProject(projectId).then((p) => {
                setProject(p);

                const allowedMappingStates = mappingStates;

                if (p.uploadIdOrderTracking) {
                    Api.getUpload(p.uploadIdOrderTracking).then((upload) => {
                        setUpload(upload);
                    });

                    Datastores.getUploadRawEvents.get({
                        id: p.uploadIdOrderTracking,
                        limit: 10,
                        offset: 0
                    }, subscriptionId).then((preview) => {
                        const previewColumns = preview.length ? Object.keys((preview as any)[0]) : undefined;

                        if (p.eventKeysOrderTracking) {
                            // Build mapping states from eventKeys
                            const newMappingState = allowedMappingStates.map(s => {
                                const assignedColumn = p.eventKeysOrderTracking![s.key];
                                const columnIdx = previewColumns?.findIndex(c => c === assignedColumn);

                                if (!assignedColumn || columnIdx === undefined || columnIdx < 0)
                                    return s;

                                return {
                                    ...s,
                                    colIdx: columnIdx,
                                };
                            });

                            setMappingStates(newMappingState);
                        } else setMappingStates(allowedMappingStates);
                        setIsInitialized(true);
                    });
                }
                else {
                    navigate("/projects/" + projectId + "/add-order-tracking");
                }
            });
    }, [projectId, settings.apiRetry]);

    return <>
        <Spinner isLoading={!isInitialized} />
        {upload && isInitialized && <IdentifyColumns
            mappingStates={mappingStates}
            onSubmitLabel={"identifyColumns.buttonUpdate"}
            onCancelLabel={"identifyColumns.buttonCancel"}
            upload={upload}
            onCancel={cancelUpdateOrderTracking}
            onSubmit={updateOrderTracking}
        />}
    </>;

    function cancelUpdateOrderTracking() {
        navigate("/projects/" + projectId + "/dashboard");
    }

    async function updateOrderTracking(partialProject: { name: string, eventKeys: EventKeys }) {
        if (project) {
            const result = await Api.updateProject({
                ...project,
                eventKeysOrderTracking: partialProject.eventKeys,
                eventFilters: []
            });

            session.set({
                project: result,
            });

            Datastores.flushAllCaches();

            navigate("/projects/" + projectId + "/order-tracking/dfg");
        }
    }
}
