/**
 * Display the configuration of a single time filter element.
 * Time filters use the start- and/or end timestamps to determine whether or not
 * to include a specific event trace.
 */

import React, { useContext } from "react";
import parse from "html-react-parser";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";
import { Formatter } from "../../../utils/Formatter";
import { SessionContext } from "../../../contexts/SessionContext";
import { getTimeFilterProps } from "../../../utils/FilterBuilder";

type TimeFilterStatusPropsType = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function TimeFilterStatus(props: TimeFilterStatusPropsType): JSX.Element | null {
    const session = useContext(SessionContext);

    const filterProps = getTimeFilterProps(props.filter);
    if (!filterProps?.start && !filterProps?.end)
        return null;

    const start = Formatter.formatDateTime(filterProps.start ? new Date(filterProps.start) : undefined, undefined, session.locale, session.timezone);
    const end = Formatter.formatDateTime(filterProps.end ? new Date(filterProps.end) : undefined, undefined, session.locale, session.timezone);

    let translationString = "filters.time.";

    if (!filterProps?.requireStartInRange && !filterProps?.requireEndInRange)
        translationString += "activeOrders";
    else {
        if (filterProps?.requireStartInRange && filterProps?.requireEndInRange)
            translationString += "startEnd";
        else
        if (filterProps?.requireStartInRange)
            translationString += "start";
        else
            translationString += "end";

        if (start && end)
            translationString += "InRange";
        else
        if (end)
            translationString += "BeforeEnd";
        else
            translationString += "AfterStart";
    }

    if (filterProps.exclude)
        translationString += "Exclude";

    return <div className={"filter timeFilter" + (props.isEdited ? " edit" : "")} onClick={onClick}>
        <svg className="svg-icon xsmall"><use xlinkHref="#radix-calendar" /></svg>
        <div>
            {parse(i18n.t(translationString, { start, end }))}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;

    function onClick() {
        if (props.onClick)
            props.onClick();
    }
}