import { useEffect } from "react";
import { createPortal } from "react-dom";

/**
 * Handles creation/destruction of a portal element.
 */
export function Portal(props: {
    children: JSX.Element | JSX.Element[] | undefined,
    rootId?: string,
}) {
    const mount = document.getElementById(props.rootId ?? "portal-root");
    const el = props.rootId ? undefined : document.createElement("div");

    useEffect(() => {
        if (!mount)
            return;

        if (el)
            mount.appendChild(el);

        return () => {
            if (el)
                mount.removeChild(el);
        };
    }, [el, mount]);

    const container = el ?? mount;
    if (!container)
        return null;

    return createPortal(props.children, (el ?? mount)!);
}