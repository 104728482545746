import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { ApiErrorContextType } from "../models/ApiTypes";
import Global from "../Global";
import { useMountedState } from "../hooks/UseMounted";
import { Project } from "../models/Project";
import { Upload } from "../models/Upload";
import { DeepPartial, ObjectMerger } from "../utils/ObjectMerger";
import jwtDecode from "jwt-decode";
import { Api } from "../api/Api";
import { Datastores } from "../utils/Datastores";

export type User = {
    email: string,
    name: string,
    picture: string,
    sub?: string,
    organization?: string,
    isCustomer?: boolean,
    persona?: string,
    permissions: string[],

    /**
     * Unguessable user ID
     */
    hash: string,
};

/**
 * This context holds information about the current session.
 */
export type SessionType = {
    projectId: string | undefined,
    project: Project | undefined,
    eventUpload: Upload | undefined,
    objectValues: string[] | undefined,
    apiToken?: string | undefined,
    user?: User,
    locale: string,
    numberFormatLocale: string,
    timezone: string,
    apiError: ApiErrorContextType,
};

export type SessionContextType = SessionType & {
    setProject(projectId?: string): void;
    set(data: Partial<SessionType>): void;
};

export const defaultSessionValues: SessionType = {
    projectId: undefined,
    project: undefined,
    eventUpload: undefined,
    apiToken: undefined,
    objectValues: undefined,
    user: undefined,
    locale: Global.isRunningJestTest ? "de" : "",
    numberFormatLocale: Global.isRunningJestTest ? "de" : "",
    timezone: "Europe/Berlin",
    apiError: {
        isFaulty: false,
    }
};

export const SessionContext = React.createContext<SessionContextType>({
    ...defaultSessionValues,

    setProject: noop,
    set: noop,
});

export function SessionContextProvider(props: React.PropsWithChildren<{
    initialValues?: DeepPartial<SessionType>,

    /**
     * Callback to modify the project after it has been loaded.
     * Used for testing purposes.
     */
    projectModificationHook?: (project: Project) => Project,
}>) {
    const [state, setState] = useState<SessionContextType>(() => {
        let merged: Partial<SessionContextType> = {
            setProject: noop,
            set: noop,
        };

        merged = ObjectMerger.mergeObject<Partial<SessionContextType>>(merged, defaultSessionValues);

        // Read user settings from local storage
        merged.locale = Global.isRunningJestTest ? "de" : (localStorage.getItem("locale") ?? props.initialValues?.locale ?? "");
        merged.numberFormatLocale = Global.isRunningJestTest ? "de" : localStorage.getItem("numberFormatLocale") ?? props.initialValues?.numberFormatLocale ?? "";
        merged.timezone = localStorage.getItem("timezone") ?? "Europe/Berlin";

        if (props.initialValues)
            merged = ObjectMerger.mergeObject(merged, props.initialValues, false);

        // Set html's lang property, otherwise Firefox's hyphens won't work
        document.documentElement.setAttribute("lang", merged.locale!);

        return merged as SessionContextType;
    });

    const isMounted = useMountedState();

    useEffect(() => {
        (async () => {
            let project = state.project;
            let objectValues = state.objectValues;
            if (state.projectId &&
                state.projectId !== state.project?.id) {
                project = await Api.getProject(state.projectId!);
                objectValues = (!project.eventKeys?.objectType || !project.uploadId) ? [] : (await Datastores.distinctAttributeValues.get({
                    eventKeys: project.eventKeys,
                    eventFilters: [],
                    attributes: [project.eventKeys.objectType],
                    uploadId: project.uploadId!,
                    uploads: project.uploads,
                    limit: 1000,
                }, -1))?.[0]?.values.map(v => v.value).flat();

                if (props.projectModificationHook)
                    project = props.projectModificationHook(project);
            }

            let eventUpload = state.eventUpload;
            if (project?.uploadId && project.uploadId !== state.eventUpload?.id)
                eventUpload = project?.uploadId ? await Api.getUpload(project.uploadId) : state.eventUpload;

            if (isMounted() && (project !== state.project || eventUpload !== state.eventUpload || objectValues !== state.objectValues)) {
                const newState = {
                    project,
                    objectValues,
                    eventUpload,
                };

                setState((s) => {
                    return {
                        ...s,
                        ...newState
                    };
                });
            }
        })();
    }, [state.projectId, state.project]);

    // Periodically update the project. It could be that the upload IDs changed,
    // but the user is working on cached data entirely. In that case, she would
    // never know that there's fresh data waiting for us!

    useEffect(() => {
        const interval = setInterval(() => {
            if (!state.projectId)
                return;

            Api.getProjectRaw(state.projectId).then((project) => {
                if (project.uploadId === state.project?.uploadId &&
                    project.uploadIdPlan === state.project?.uploadIdPlan &&
                    project.uploadIdOrderTracking === state.project?.uploadIdOrderTracking)
                    return;

                Api.getUploadRaw(project.uploadId!).then((upload) => {
                    setState(s => {
                        return { ...s, project, eventUpload: upload };
                    });
                });
            });
        }, 30 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [
        state,
    ]);

    return <SessionContext.Provider value={{
        ...state,
        set(data: Partial<SessionType>) {
            setState((s) => {
                const newState = {
                    ...s,
                    ...data,
                };

                // Set html's lang property, otherwise Firefox's hyphens won't work
                document.documentElement.setAttribute("lang", newState.locale!);

                return newState;
            });
        },
        setProject(id?: string) {
            if (id === undefined)
                return;

            if (id === state?.projectId)
                return;

            queueMicrotask(() => {
                setState((s) => {
                    const newState = {
                        ...s,
                        project: undefined,
                        objectValues: undefined,
                        projectId: id,
                    };

                    return newState;
                });
            });
        }
    }}>
        {props.children}
    </SessionContext.Provider>;
}

export function hasNetTransitionTimes(session: SessionType) {
    return session.project?.features?.allowNetTimes && session.project?.uploads?.shiftCalendar !== undefined;
}

export function isOniqEmployee(session: SessionType) {
    if (Global.isRunningJestTest || Global.isRunningStorybook)
        return true;

    const permissions = jwtDecode<{ permissions?: string[] }>(session.apiToken ?? "")?.permissions;
    return permissions?.includes("oniq");
}

export function isOniqDev(session: SessionType) {
    if (Global.isRunningJestTest || Global.isRunningStorybook)
        return true;

    const permissions = jwtDecode<{ permissions?: string[] }>(session.apiToken ?? "")?.permissions;
    return permissions?.includes("oniqDev");
}

export function isWorkshopAccount(session: SessionType) {
    if (Global.isRunningJestTest || Global.isRunningStorybook)
        return true;

    const permissions = jwtDecode<{ permissions?: string[] }>(session.apiToken ?? "")?.permissions;
    return permissions?.includes("workshopAccount");
}

export function hasDownloadPermission(session: SessionType) {
    if (Global.isRunningJestTest)
        return false;

    if (Global.isRunningStorybook)
        return true;

    const permissions = jwtDecode<{ permissions?: string[] }>(session.apiToken ?? "")?.permissions;
    return permissions?.includes("downloadViewData");
}

export class SessionInstance {
    public static session: SessionContextType;
}
