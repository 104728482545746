import { MultiEdge } from "../../models/Dfg";
import { getHash } from "../../utils/Utils";
import { Vector } from "../../utils/Vector";
import { Leg } from "./Leg";

const defaultCornerLength = 10;


/**
 * This class takes care of rendering and interpolating edges. An edge may consist of a multiple
 * Bézier curves or straignt lines, and you can ask for intermediate positions along the edge using
 * getRelativePosition or getAbsolutePosition respectively.
 */
export class ChartEdge {
    id: string;

    from: string;
    to: string;
    public legs: Leg[];
    length: number;

    private addArrow: boolean;

    constructor(public key: string, public edge: MultiEdge, addArrow: boolean) {
        this.from = edge.from;
        this.to = edge.to;
        this.addArrow = addArrow;

        this.id = `e-${getHash(edge.from)}-${getHash(edge.to)}`;

        const points = edge.renderOptions?.points;

        if (!points || points.length < 2)
            // This should never happen anyway
            throw new Error("this is a point and not an edge!");

        // Remove points that are too close to each other
        const filterLengths = (points: Vector[]) => {
            const result: Vector[] = [points[0]];
            let prevIdx = 0;
            for (let i = 1; i < points.length; i++) {
                const p = points[i];
                const d = p.subtract(points[prevIdx]);
                if (d.length() < 5)
                    continue;
                result.push(p);
                prevIdx = i;
            }
            return result;
        };

        const pointVectors = filterLengths(points.map(p => Vector.fromPoint(p)));

        const controlPointVectors: Vector[] = [];

        // add first point
        controlPointVectors.push(pointVectors[0]);

        // add corners
        for (let i = 1; i < (pointVectors.length - 1); i++) {
            const p = pointVectors[i];

            const dPrev = p.subtract(pointVectors[i - 1]);
            const dNext = pointVectors[i + 1].subtract(p);

            const sPrev = dPrev.length();
            const sNext = dNext.length();
            const prevCornerLength = Math.min(sPrev / 2, defaultCornerLength);
            const nextCornerLength = Math.min(sNext / 2, defaultCornerLength);

            const pPre = p.subtract(dPrev.multiply(prevCornerLength / sPrev));
            const pPost = p.add(dNext.multiply(nextCornerLength / sNext));

            controlPointVectors.push(pPre);
            controlPointVectors.push(p);
            controlPointVectors.push(pPost);
        }
        // add last point
        controlPointVectors.push(pointVectors[pointVectors.length - 1]);

        // Add legs
        this.length = 0;
        this.legs = [];
        for (let i = 2; i < (controlPointVectors.length - 1); i++) {
            // Each corner has 3 points. The middle point is the "real" point
            // and the two surrounding points are spline control points.
            if (((i + 1) % 3) === 0) {
                // First draw a straight line to the start of the corner
                const toCornerLeg = new Leg([
                    controlPointVectors[i-2],
                    controlPointVectors[i-1],
                ]);
                this.legs.push(toCornerLeg);
                this.length += toCornerLeg.length;

                // Draw the actual corner as a cubic spline
                const cornerLeg = new Leg([
                    controlPointVectors[i-1],
                    controlPointVectors[i],
                    controlPointVectors[i+1]
                ]);
                this.legs.push(cornerLeg);
                this.length += cornerLeg.length;
            }
        }
        // Add a straight line to the last point.
        const lastLeg = new Leg([controlPointVectors[controlPointVectors.length - 2], controlPointVectors[controlPointVectors.length - 1]]);
        this.legs.push(lastLeg);
        this.length += lastLeg.length;
    }

    select(container: HTMLElement) {
        const elements = container.getElementsByClassName(this.id);
        for (const element of elements)
            if (!element.classList.contains("selected"))
                element.classList.add("selected");
    }

    static blurSelection(container: HTMLElement) {
        const elements = container.getElementsByClassName("selected");
        while (elements.length)
            elements[0].classList.remove("selected");
    }

    /**
     * Updates stroke and width attributes for this edge. 
     * Does nothing if the edge is not rendered yet.
     */
    updateEdgeStyle(container: HTMLElement, isSelected: boolean, lineStroke: string, width?: number) {
        const elements = container.getElementsByClassName(this.id) ?? [];
        for (const element of elements) {
            element.setAttribute("stroke", lineStroke);
            element.setAttribute("fill", lineStroke);

            if (isSelected) {
                if (!element.classList.contains("selected"))
                    element.classList.add("selected");
            } else {
                if (element.classList.contains("selected"))
                    element.classList.remove("selected");
            }

            if (width && element.getAttribute("stroke-width"))
                element.setAttribute("stroke-width", width.toString());
        }
    }

    /**
     * Appends the edge as sequence of <path> elements to the given SVG container
     * @param container Container to append the edge to
     * @param isSelected Whether the edge is selected
     * @param lineStroke Stroke
     * @param width Line width
     * @param dashed 
     * @param clickHandler Click handler
     */
    append(container: SVGSVGElement, isSelected: boolean, lineStroke: string, width?: number, dashed?: number, clickHandler?: (edge: MultiEdge) => void) {
        width = width || 1;

        const arrowHeadSize = 6;

        // This is how much pixels we need to remove from the edge so the arrow head
        // is not overdrawn by it's own path
        let truncation = arrowHeadSize + width;

        // If truncation is longer than the last leg, remove as many legs as needed
        // to cover the truncation
        let lastIdx = this.legs.length - 1;
        while (truncation > 0 && lastIdx > 0) {
            const legLength = this.legs[lastIdx].length;
            if (legLength > truncation)
                break;

            truncation -= legLength;
            lastIdx--;
        }

        const legs = lastIdx === this.legs.length ? this.legs : this.legs.filter((_, idx) => idx <= lastIdx);

        for (const idx in legs) {
            const leg = legs[idx];

            // Truncate last leg
            const isLastLeg = (+idx) === (legs.length - 1);
            leg.append(container, isSelected, this.id, this.edge, lineStroke, width, dashed, isLastLeg ? truncation : undefined, clickHandler);
        }

        // Only add arrow head if desired
        if (this.addArrow)
            this.appendArrowHead(container, isSelected, this.id, this.length - arrowHeadSize - width, lineStroke, 1 + width, arrowHeadSize + width, clickHandler);
    }

    /**
     * Generates a JSX.Element that renders an arrow head at the given
     * position of the edge
     * @param container SVG container to append the arrow head to
     * @param id ID of the edge this arrow head belongs to
     * @param position Position at which the arrow head should be placed
     * @param stroke Color of the arrow
     * @param width Width of the arrow head generated
     * @param length Length of the arrow head generated
     * @param clickHandler onClick handler
     */
    appendArrowHead(container: SVGSVGElement, isSelected: boolean, id: string, position: number, stroke: string, width: number, length: number, clickHandler?: (edge: MultiEdge) => void) {
        const headStart = this.getAbsolutePosition(position);
        const headEnd = this.getAbsolutePosition(position + length);
        const headDir = headEnd.subtract(headStart).normalize();
        const headPerp = new Vector(headDir.y, -headDir.x).normalize();

        const d = `M ${headStart.x} ${headStart.y} L ${headStart.x + headPerp.x * width} ${headStart.y + headPerp.y * width} ` +
            `L ${headStart.x + headDir.x * length} ${headStart.y + headDir.y * length} L ${headStart.x - headPerp.x * width} ${headStart.y - headPerp.y * width} Z`;

        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path.setAttribute("d", d);
        path.setAttribute("stroke", stroke);
        path.setAttribute("fill", stroke);

        const classes = [id];
        if (isSelected)
            classes.push("selected");

        path.setAttribute("class", classes.join(" "));
         
        path.addEventListener("click", (e) => {
            if (clickHandler)
                clickHandler(this.edge);

            e.preventDefault();
            e.stopPropagation();
        });

        container.appendChild(path);
    }

    /**
     * Returns the position of a specific point along the edge, specified as fraction of
     * the total edge length. E.g. 0 would refer to the beginning of the edge, 0.5 would
     * be the mid point and 1 the end of the edge.
     * @param t fraction of the total length
     */
    getRelativePosition(t: number): Vector {
        return this.getAbsolutePosition(this.length * t);
    }

    /**
     * Returns the position of a specific point along the edge, specified in pixels.
     * Negative values are offsetted from the end of the edge.
     * @param px number of pixels to walk the edge
     */
    getAbsolutePosition(px: number): Vector {
        if (px < 0)
            px += this.length;

        px = Math.max(0, Math.min(this.length, px));

        let legIdx = 0;
        while (legIdx < this.legs.length && px > this.legs[legIdx].length) {
            px -= this.legs[legIdx].length;
            legIdx++;
        }

        if (legIdx === this.legs.length) {
            // Requested point is outside last leg, return last point of last leg instead
            const lastLeg = this.legs[this.legs.length - 1];
            return lastLeg.controlPoints[lastLeg.controlPoints.length - 1];
        }

        return this.legs[legIdx].getAbsolutePositionAt(px);
    }

    /**
     * Returns the worst case distance of a given point to this edge,
     * meaning, the edge is at most this many units away from pt.
     *
     * This function trades accuracy for speed.
     * @param pt The point to check for
     * @returns Maximum distance. The true distance is less than the result.
     */
    worstCaseDistanceTo(pt: Vector) {
        let minDistance = Number.MAX_SAFE_INTEGER;
        for (const leg of this.legs) {
            const distance = leg.getWorstCaseDistance(pt);
            if (distance < minDistance)
                minDistance = distance;
        }

        return minDistance;
    }

    distanceTo(pt: Vector) {
        let minDistance = Number.MAX_SAFE_INTEGER;
        for (const leg of this.legs) {
            const distance = leg.getDistanceSq(pt);
            if (distance < minDistance)
                minDistance = distance;
        }

        return minDistance;
    }
}
