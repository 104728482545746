import React, { useContext, useMemo, useRef } from "react";
import { SessionContext, SessionType, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { analysisGraphMapping, AnalysisType, useGraph } from "../../hooks/UseGraph";
import i18n from "../../i18n";
import { Edge, MultiEdge, Graph as graphType } from "../../models/Dfg";
import { getKpiDefinition, getUnit } from "../../models/Kpi";
import { DfgUtils, getCustomKpisDfg, getEdgeStatisticName } from "../../utils/DfgUtils";
import { Bar, Graph  } from "../graph/Graph";
import { ContainerModes, GraphLine, commonSelectionLineProps } from "../graph/GraphCommon";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import Spinner from "../spinner/Spinner";
import { ensureProperCountFormatting } from "./NodeKpiChart";
import DownloadFile, { TemplateType } from "../download-file/DownloadFile";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { Stats } from "../../models/Stats";
import { useGridResize } from "./UseGridResize";
import { get } from "lodash";
import { sortByVariance } from "../../views/process-path/ProductVariations";
import { getAnalysisTitle } from "../product-chart/ProductChart";
import { VarianceLegend } from "../graph/VarianceLegend";

export type EdgeKpiChartProps = {
    analysisType: AnalysisType;
    title?: string;
}

type BarData = {
    edge: Edge | undefined,
    stats: Stats,
};

export function EdgeVarianceChart(props: EdgeKpiChartProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const container = useRef<HTMLDivElement>(null);
    const [width, height] = useGridResize(container, 1, undefined, undefined, 0);

    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === props.analysisType)?.arguments;

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const graphOptions = { ...analysisArguments, ...getCustomKpisDfg(settings, session, false) };

    const graph = useGraph({
        ...graphOptions,
        calculateNodes: true,
        calculateEdges: true,
        calculateRoles: true,
        calculateTimeAndFreqStats: true,
        calculateActivityValues: true,
    }, props.analysisType);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const graphData: Bar<BarData>[] = useMemo(() => {
        
        const result: Bar<BarData>[] = [];

        // Exclude edges that are connected to start- or end nodes
        const excludedNodeIds = new Set(DfgUtils.filterStartAndEndNodes(graph?.nodes ?? []).map(n => n.id));
        const edges = (graph?.multiEdges ?? []).filter(e => !excludedNodeIds.has(e.from) && !excludedNodeIds.has(e.to));

        for (const edge of edges) {
            const objectEdge = DfgUtils.findObjectEdge(edge, isObjectCentric, settings.graph.objectType);
            if (objectEdge === undefined)
                continue;

            const stats = getEdgeVarianceStat(objectEdge, settings, session);

            if (stats?.median === undefined ||
                stats?.p75 === undefined ||
                stats?.p25 === undefined)
                continue;

            const label = `${DfgUtils.getNodeLabelById(edge.from, graph?.nodes, settings.groupingKey)} ➞ ${DfgUtils.getNodeLabelById(edge.to, graph?.nodes, settings.groupingKey)}`;

            result.push({
                label: label,
                data: {
                    edge : objectEdge,
                    stats
                },
                value: stats.p75,
                baseValue: stats.p25,
                median: stats.median,
            });
        }

        // Sort!
        const sorted = sortByVariance(result, settings) as Bar<BarData>[];

        return sorted;
    }, [
        graph,
        session.numberFormatLocale,
        settings.graph.objectType,
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.quantity,
    ]);

    const chartContainerRef = useRef<HTMLDivElement>(null);

    const downloadAllowed = hasDownloadPermission(session);

    const isInitializing = graph === undefined ||
        width === undefined ||
        height === undefined;

    const onSelected = (data: Edge | undefined) => {  
        const edges = (graph?.edges ?? []).filter(e => e.from === data?.from && e.to === data?.to);

        if (!edges?.length ||
            (settings.selection.edge?.from === edges[0].from && settings.selection.edge?.to === edges[0].to)) {
            settings.setSelection({});
            return;
        }

        settings.setSelection({
            edge: {
                ...edges[0],
                edges,
            }
        });
    };

    const selectedIndex = settings.selection.edge !== undefined ? graphData.findIndex(c => c.data?.edge?.from === settings.selection.edge?.from && c.data?.edge?.to === settings.selection.edge?.to) : undefined;

    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    const title = getAnalysisTitle(session, settings);

    const hasData =  graphData !== undefined && graphData.length > 0;

    return <div className="kpiChartCollection fillParentMargin" ref={container}>
        <Spinner isLoading={isInitializing} showProjectLoadingSpinner={true} />

        {graph !== undefined && !hasData && !isInitializing && <div className="noKpisAvailable">
            {i18n.t("common.noKpisAvailable")}
            <div>
                {i18n.t("common.noEdgesFound")}
            </div>
        </div>}

        <div className={"chartContainer" + (isInitializing ? " hide" : "")} ref={chartContainerRef}>
            {width !== undefined && height !== undefined && hasData && <Graph
                title={i18n.t(title).toString()}
                horizonalLines={drawSelectionLine(graph, settings, session)}
                yAxisUnit={unit}
                showYAxisLines={true}
                showYAxisTicks={true}
                selectedIndex={selectedIndex}
                legend={<VarianceLegend />}
                min={0}
                data={graphData ?? []}
                padding={{
                    top: 40,
                    left: 80,
                    bottom: 100,
                }}
                onSelected={(e) => onSelected(e?.data?.edge)}
                width={width}
                height={height}
                containerMode={ContainerModes.Constrained}
                formatterParams={{
                    numDigits: 1,
                }}
                valueFormatter={(value) => {
                    return ensureProperCountFormatting(value, unit, session, settings.quantity);
                }}
            />}
        </div>
        <DownloadFile
            data={graphData}
            template={TemplateType.EdgeVariance}
            meta={unit}
            allowed={downloadAllowed}
            title={i18n.t(title).toString()} />
        <Shortcuts handledSelections={[ShortcutContexts.Edge]} graph={graph} />
    </div>;
}

function getEdgeVarianceStat(multiEdge: MultiEdge | Edge | undefined, settings: SettingsType, session: SessionType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const edge = get(multiEdge, "edges") !== undefined ?
        DfgUtils.findObjectEdge(multiEdge as MultiEdge, isObjectCentric, settings.graph.objectType) :
        multiEdge;
    const edgePropName = getEdgeStatisticName(session, settings);
    if (edgePropName === undefined || edge === undefined)
        return undefined;
    return get(edge, edgePropName) as Stats | undefined;
}

export function exportEdgeVarianceData(data: Bar<BarData>[]) {
    const formattedData = [];
 
    for (const bar of data) {
        const item = {
            [i18n.t("common.from")]: bar?.data?.edge?.from,
            [i18n.t("common.to")]: bar?.data?.edge?.to,
            [i18n.t("common.statistics.median")]: bar?.median,
            [i18n.t("common.percentile25")]: bar?.baseValue,
            [i18n.t("common.percentile75")]: bar?.value,
            [i18n.t("common.statistics.shortMin")]: bar.data?.stats?.min,
            [i18n.t("common.statistics.shortMax")]: bar.data?.stats?.max,
        };

        formattedData.push(item);
    }
    
    return formattedData;
}

function drawSelectionLine(graph: graphType | undefined, settings: SettingsType, session: SessionType): GraphLine[] {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const selectedEdge = settings.selection.edge ? graph?.multiEdges.find(e => e.from === settings.selection.edge?.from && e.to === settings.selection.edge?.to) : undefined;

    const objectEdge = selectedEdge ? DfgUtils.findObjectEdge(selectedEdge, isObjectCentric, settings.graph.objectType) : undefined;
    const value = objectEdge ? getEdgeVarianceStat(objectEdge, settings, session) : undefined;
    const selectedElementLine: GraphLine[] = value?.median !== undefined ? [{ ...{ value: value?.median, ...commonSelectionLineProps } }] : [];
    return selectedElementLine;
}