import React, { useContext } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SetupMatrixElementSchema, NodeMatrixSchema } from "../../models/ApiTypes";
import { BaseMatrixProps } from "./MatrixCell";


export type SetupMatrixCellProps = BaseMatrixProps & {
    transition?: SetupMatrixElementSchema;
    comparisonTransition?: SetupMatrixElementSchema;
    from?: NodeMatrixSchema | string;
    to?: NodeMatrixSchema | string;
}

export default function SetupMatrixCell(props: SetupMatrixCellProps) {
    const settings = useContext(SettingsContext);
    const cellSelected = settings.selection.matrixElement as SetupMatrixCellProps;

    const isSelected = cellSelected?.transition !== undefined && 
    (cellSelected?.transition?.from === props.transition?.from &&
        cellSelected?.transition?.to === props.transition?.to);


    return (
        <div
            style={{
                top: `calc(var(--matrix-cell-box-height) * ${props.y})`,
                left: `calc(var(--matrix-cell-box-width) * ${props.x})`,
                backgroundColor: props.color,
            }}
            className={`matrixCell matrixCell--variant-${props.variant}${props.transition !== undefined ? " clickable" : ""}${isSelected ? " matrixCell--selected" : ""}`}
            onClick={(e) => {
                e.stopPropagation();

                if (!props.transition) {
                    // Blank cell
                    settings.setSelection({});
                    return;
                }

                if (!props ||
                    (cellSelected?.transition === props.transition) ||
                    (props.from === undefined || props.to === undefined)) {
                    // Blur selection
                    settings.setSelection({});
                    return;
                }

                settings.setSelection({
                    matrixElement: props
                });
            }}>
            {props.title && <div className="matrixCell__title">{props.title}</div>}
            {props.info && <div className="matrixCell__info">{props.info}</div>}
        </div>
    );
}
