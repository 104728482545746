import React, { useContext, useEffect } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useGraph } from "../../hooks/UseGraph";
import Dropdown, { StringOption } from "../dropdown/Dropdown";
import i18n from "../../i18n";
import { getActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { GroupingKeys, NodeRoles } from "../../models/Dfg";
import { isTerminalNode } from "../../utils/DfgUtils";
import { Spotlight } from "../spotlight/Spotlight";

export const ALL_NODES = "__ALL_NODES__";

export default function NodeSelectionControls() {
    const settings = useContext(SettingsContext);

    const graphOptions = {
        calculateNodes: true,
        calculateRoles: true,
        calculateActivityValues: true,
    };

    const graph = useGraph({ ...graphOptions });

    const isInitializing = graph === undefined;

    const dropdownOptions: StringOption[] = isInitializing ? [] : [
        {
            label: i18n.t("common.allActivities"),
            value: ALL_NODES,
        },

        ...graph.nodes.filter(n => n.role !== NodeRoles.Inventory && !isTerminalNode(n)).map(n => {
            return {
                label: n.activityValues !== undefined ? getActivityLabelFromActivityValues(n.activityValues, settings.groupingKey ?? GroupingKeys.None) : "",
                value: n.id,
            } as StringOption;
        }),
    ];

    useEffect(() => {
        if (graph === undefined || settings.gantt.restrictToNode === undefined || settings.gantt.restrictToNode === ALL_NODES)
            return;
        if (graph.nodes.find(n => n.id === settings.gantt.restrictToNode) === undefined)
            settings.mergeSet({
                gantt: {
                    restrictToNode: ALL_NODES,
                },
            });

    }, [graph]);


    return <div className="section nodeSelection">
        <div className="title">{i18n.t("common.focusOn")}<Spotlight id="Controller-FocusOn" className="mls" /></div>
        <Dropdown
            className={"nodeDropdown"}
            placeholder={i18n.t("common.initializing").toString()}
            isSearchable={true}
            value={dropdownOptions.find(o => settings.gantt.restrictToNode === o.value! as string) ?? dropdownOptions[0]}
            data-testid={"selectNode"}
            onChange={(e) => {
                const ot = e!.value as string;
                settings.mergeSet({
                    gantt: {
                        restrictToNode: ot === "" ? undefined : ot,
                    },
                });
            }}
            options={dropdownOptions} />
    </div>;
}