import { useEffect, useState } from "react";
import { DfgRequest, disableAllCalcOptions } from "../models/ApiTypes";
import { Graph, GroupingKeys, NodeRoles } from "../models/Dfg";
import { AnalysisType, useGraph } from "./UseGraph";


/**
 * Custom hook that returns machine names for a given order sequence.
 * @param orderSequences Order sequence or array of order sequences to retrieve machine names for.
 * @param request Options to pass to the DFG request. Calculate... options are ignored.
 * @param options Options to disable the hook or to enable/disable the loading indicator.
 * @returns An object containing an array of machine names and a boolean indicating whether the hook is still loading.
 */
export function useOrderSequenceMachineNames(orderSequences?: string | string[] | undefined, request?: Partial<DfgRequest>, options?: {
    disable?: boolean,
}): {
    machineNames: string[] | undefined,
    isLoading: boolean,
    graph: Graph | undefined,
} {
    const disableHook = orderSequences === undefined || options?.disable;
    const [machineNames, setMachineNames] = useState<string[] | undefined>(undefined);

    const orderSequenceDfg = useGraph({
        ...request,
        ...disableAllCalcOptions,
        groupingKey: GroupingKeys.MachineValueStream,
        calculateNodes: true,
        calculateActivityValues: true,
    }, AnalysisType.Times, false,
    disableHook);

    const isLoading = !orderSequenceDfg;

    useEffect(() => {
        const passIds = Array.isArray(orderSequences) ? orderSequences : [orderSequences ?? ""];

        // Retrieve machine names used in the order sequence.
        const passIdMachineNames = (orderSequenceDfg?.nodes ?? []).filter(n =>
            passIds.includes(n.activityValues?.passId?.value ?? "") &&
            n.role !== NodeRoles.Inventory)?.map(n => n.activityValues?.machine?.value).filter(n => n !== undefined) as string[];

        setMachineNames(passIdMachineNames);
    }, [
        orderSequenceDfg,
        JSON.stringify(orderSequences),
    ]);

    return { machineNames, isLoading, graph: orderSequenceDfg };
}

export type OrderSequenceMachineIdsRequest = {
    machineNames: string[] | undefined,
    machineIds: string[] | undefined,
    isLoading: boolean,
    isMachineNamesLoading: boolean,
    machineNameGraph: Graph | undefined,
    machineIdGraph: Graph | undefined,
};

/**
 * Custom hook that returns machine IDs for a given order sequence.
 * We're assuming a one-to-one mapping from machine names to machine IDs.
 * @param orderSequences Order sequence or array of order sequences to retrieve machine IDs for. When this is an empty array, the 
 *  dfg that maps to machine IDs will be requested. If undefined, that second request won't happen.
 * @param request Options to pass to the DFG request. Calculate... options are ignored.
 * @param options Options to disable the hook or to enable/disable the loading indicator.
 * @returns An object containing an array of machine names, an array of machine IDs, and a boolean indicating whether the hook is still loading.
 */
export function useOrderSequenceMachineIds(orderSequences?: string | string[] | undefined, request?: Partial<DfgRequest>, options?: {
    disable?: boolean,
}): OrderSequenceMachineIdsRequest {
    const [machineIds, setMachineIds] = useState<string[] | undefined>(undefined);

    const machineNamesRequest = useOrderSequenceMachineNames(orderSequences, request, options);

    const machineDfg = useGraph({
        ...request,
        ...disableAllCalcOptions,
        groupingKey: GroupingKeys.Machine,
        // Here the filters are irrelevant as we're only using this to map
        // machine names to machine IDs. Providing an emtpy filters array
        // improves the cachiness
        eventFilters: [],
        calculateNodes: true,
        calculateActivityValues: true,
        calculateRoles: true,
    }, AnalysisType.Times, false, options?.disable || orderSequences === undefined);

    const isLoading = machineNamesRequest.isLoading || !machineDfg;

    useEffect(() => {
        if (!machineNamesRequest.machineNames?.length || !machineDfg?.nodes?.length) {
            setMachineIds(undefined);
            return;
        }

        // We're assuming a one-to-one mapping from machine names to machine IDs. In theory, this
        // isn't necessarily the case. Location or machine type could differ while still sharing the
        // same name.
        const passIdMachineIds = machineNamesRequest.machineNames.map(name => machineDfg?.nodes.find(n => n.activityValues?.machine?.value === name)?.id).filter(n => n !== undefined) as string[];
        setMachineIds(passIdMachineIds);
    }, [
        JSON.stringify(machineNamesRequest),
        JSON.stringify(orderSequences),
        machineDfg?.hash,
    ]);

    return {
        machineNames: machineNamesRequest.machineNames,
        machineIds,
        isLoading,
        isMachineNamesLoading: machineNamesRequest.isLoading,
        machineNameGraph: machineNamesRequest.graph,
        machineIdGraph: machineDfg,
    };
}
