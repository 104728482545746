import React, { useState } from "react";
import i18n from "../../i18n";
import RangeInput from "../../components/range-input/RangeInput";

type TimePickerPropsType = {
    initialHour?: number;
    initialMinute?: number;
    onChange?: (hour: number, minute: number) => void,
};

type TimePickerStateType = {
    hour: number,
    minute: number,
};

export default function TimePicker(props: TimePickerPropsType) {
    const [state, setState] = useState<TimePickerStateType>({
        hour: props.initialHour ?? 0,
        minute: props.initialMinute ?? 0,
    });

    return <div className="timepicker">
        <div className="display">
            <span>{state.hour.toString().padStart(2, "0")}</span>
            :
            <span>{state.minute.toString().padStart(2, "0")}</span>
        </div>

        <RangeInput
            min={0}
            max={23}
            buttonIncrements={1}
            label={i18n.t("common.hour").toString()}
            onValueChanged={(e) => { emitState({ ...state, hour: e }); }}
            initialValue={state.hour}
        />

        <RangeInput
            label={i18n.t("common.minute").toString()}
            min={0}
            max={59}
            buttonIncrements={5}
            onValueChanged={(e) => { emitState({ ...state, minute: e }); }}
            initialValue={state.minute}
        />
    </div>;

    function emitState(state: TimePickerStateType) {
        setState(state);
        if (props.onChange)
            props.onChange(state.hour, state.minute);
    }
}