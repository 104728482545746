import { isNumber, isString } from "lodash";
import React, { useContext, useState } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import DateTimePicker from "../datetime-picker/DateTimePicker";
import Modal from "../modal/Modal";

type DateTimePropsType = {
    value?: string | number | Date | undefined;
    onChange?: (date?: Date) => void,
    allowUndefined?: boolean;
    disabled?: boolean;
    className?: string;
    noValuePlaceholder?: string;
};

export default function DateTimeInput(props: DateTimePropsType) {
    const session = useContext(SessionContext);
    const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false);

    let date: Date | undefined = undefined;
    if (props.value !== undefined) {
        if (isNumber(props.value))
            date = new Date(props.value);

        if (props.value instanceof Date)
            date = props.value;

        if (isString(props.value))
            date = new Date(props.value);
    }

    const [selection, setSelection] = useState<Date | undefined>(undefined);

    return <div className={"datetime" + (props.className ? " " + props.className : "")}>
        {date && <span>
            <span className={props.disabled ? "disabled" : "enabled"} onClick={showPicker}>
                {Formatter.formatDateTime(date, "", session.locale, session.timezone)}
            </span>

            {props.allowUndefined && <svg className="svg-icon xtiny clickable x hoverOpacity" onClick={clear}><use xlinkHref="#radix-cross-1" /></svg>}
        </span>}
        {!date && <span className={props.disabled ? "disabled" : "enabled"} onClick={showPicker}>
            {props.noValuePlaceholder ?? i18n.t("datetimePickerNoValue")}
        </span>}

        <Modal
            width={420}
            isVisible={isPickerVisible}
            onDone={async () => { apply(); }}
            isClosable={true}
            defaultButtonLabel="common.apply"
            showCancelButton={true}
            onCancel={() => setIsPickerVisible(false)}
        >
            <div className="datepickerContainer">
                <DateTimePicker
                    className="widthLimit700"
                    value={selection}
                    onChangeDate={(d) => { setSelection(d); }}
                />

            </div>
        </Modal>
    </div>;

    function showPicker() {
        const now = new Date();
        setSelection(date ?? new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0));
        setIsPickerVisible(true);
    }

    function apply() {
        setIsPickerVisible(false);
        if (props.onChange)
            props.onChange(selection);
    }

    function clear() {
        if (props.onChange)
            props.onChange(undefined);
    }
}