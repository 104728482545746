import React, { useContext } from "react";
import * as FileSaver from "file-saver";
import { TrayIconElement } from "../tray/TrayElement";
import { PostRootCauseAnalysisRequest } from "../../models/ApiTypes";
import { Api } from "../../api/Api";
import { getFileName, TemplateType } from "../download-file/DownloadFile";
import { SettingsContext } from "../../contexts/SettingsContext";
import { NotificationService } from "../notification/NotificationService";

export function DownloadBottleneckDebuggingResults(props: {
    rcaRequest: PostRootCauseAnalysisRequest,
}) {

    const settings = useContext(SettingsContext);
    const fileName = getFileName(settings, window.location.pathname, TemplateType.Node);

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    async function exportBottleneckDebuggingResults() {
        const newRequest: PostRootCauseAnalysisRequest = {
            ...props.rcaRequest,
            rcaType: {
                ...props.rcaRequest.rcaType,
                options: {
                    ...props.rcaRequest.rcaType.options,
                    bottleneckIntermediateResults: true
                },
            }
        };

        NotificationService.add({
            id: "bottleneck-debugging-results",
            className: "light success-accent",
            icon: "radix-clock",
            autoCloseDelay: 5000,
            summary: "rca.bottleneckDebuggingStarted",
            message: "rca.bottleneckDebuggingStartedDescription"
        });

        // Workaround for showing the notification immediately
        settings.set({});

        const response = await Api.bottleneckDebuggingResults(newRequest);

        const blob = new Blob([response.data], { type: fileType });
        FileSaver.saveAs(blob, fileName + ".xlsx");
    }

    return <TrayIconElement
        icon="radix-download"
        testId="download-bottleneck-debugging"
        id="downloadBottleneckDebugging"
        onClick={() => exportBottleneckDebuggingResults()}
        title="common.downloadBottleneckDebugging"
    />;
}
