import React from "react";
import i18n from "../../i18n";

export type TabsNextPageProps = {
    title: string;
    content: JSX.Element;
};

export type TabsNextProps = {
    title?: string;
    selectedIndex: number;
    onTabChanged: (idx: number, page: TabsNextPageProps) => void,
    pages: TabsNextPageProps[];
};

export function TabsNext(props: TabsNextProps) {
    return <div className="tabsNext">
        {props.title && props.pages.length > 1 && <h1>{i18n.t(props.title)}</h1>}
        {props.pages.length > 1 && <div className="tabsHeader">
            {props.pages.map((p, idx) => <div 
                key={p.title} 
                onClick={() => {
                    props.onTabChanged(idx, p);
                }}
                className={"tabsHeaderElement" + (idx === props.selectedIndex ? " active": "")}>
                <span title={i18n.t(p.title).toString()}>
                    {i18n.t(p.title)}
                </span>
            </div>)}
        </div>}
        {props.pages[props.selectedIndex].content}
    </div>;
}