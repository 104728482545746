import React from "react";
import { TabbedViewNext } from "../../components/tabbed-view/TabbedViewNext";
import { ProjectSettingsView } from "./ProjectSettingsView";

export function SettingsView() {
    return <div className="nextPage">
        <TabbedViewNext
            headline="common.settings"
            pages={[{
                title: "settings.projectSettings",
                tabSlug: "project",
                content: <ProjectSettingsView />
            }]}
        />
    </div>;
}