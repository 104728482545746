import React from "react";
import i18n from "../../i18n";

export type LegendItem = {
    label: string;
    color: string;
    isLine?: boolean;
}

export type LegendProps = React.StyleHTMLAttributes<HTMLDivElement> & {
    items: LegendItem[];
};

export function Legend(props: LegendProps) {
    return <div
        style={props.style}
        className={"legend" + (props.className? " " + props.className : "")}
        data-testid="legend">
        {props.items.map((item, idx) => {
            return <div key={`legend-${idx}-${item.label}`}>
                {item.isLine !== true &&<div className="colorbox" style={{
                    backgroundColor: item.color
                }}>&nbsp;</div>}
                {item.isLine === true &&<div className="linebox" ><div style={{
                    backgroundColor: item.color
                }}>&nbsp;</div></div>}
                {i18n.t(item.label)}
            </div>;
        })}
    </div>;
}