import React from "react";
import { useNavigate } from "react-router-dom";
import SelectFile from "../../components/select-file/SelectFile";
import { Upload } from "../../models/Upload";

export default function AddProjectUpload() {

    const navigate = useNavigate();

    return <SelectFile 
        navigateTo={(upload: Upload) => navigate("/projects/create-project/" + upload.id)}
    />;
}