import { useEffect, useState } from "react";

/**
 * This hook returns the scrollFade CSS class to attach to the DIV element that
 * hosts scroll content.
 * 
 * How to use:
 * 
 * const containerRef = useRef<HTMLDivElement>(null);
 * const cssClass = useScrollFade(containerRef);
 * 
 * return <div ref={containerRef} className={cssClass}>
 *    ...Large div that needs scrolling
 * </div>;
 * 
 * @param containerRef 
 * @returns 
 */
export function useScrollFade(containerRef: React.RefObject<HTMLDivElement>) {
    // This triggers a rerender if needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setCssClass] = useState("");
    
    useEffect(() => {
        if (!containerRef.current)
            return;

        setCssClass(getCssClass());

        containerRef.current.onscroll = () => {
            setCssClass(getCssClass);
        };
    }, [
        containerRef.current,
    ]);

    return getCssClass();

    function getCssClass() {
        const top = containerRef.current?.scrollTop ?? 0;

        // clientHeight and scrollHeight are provided by the browser as rounded number, while
        // they are handled as floats internally.So in the worst case,
        // scrollHeight could e.g. be 1000.4999999 (provided as 1000), and clientHeight could be 499.5 (aka 500).
        // In that case, max would be 500 (while 500.9999). To account for that, we're subtracting an extra
        // offset of 1.5 here
        const max = Math.max(0, (containerRef.current?.scrollHeight ?? 0) - (containerRef.current?.clientHeight ?? 0)) - 1.5;

        if (max === 0)
            // There is nothing to scroll, so we don't need to attach
            // any CSS class
            return "";

        const topFade = top > 0;
        const bottomFade = top < max;
        
        return topFade && bottomFade ? " scrollFadeTopBottom" :
            topFade ? " scrollFadeTop" :
                bottomFade ? " scrollFadeBottom" : "";
    }
}
