import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { AnalysisType, calculateOutputStatsArguments, useGraph } from "../../hooks/UseGraph";
import { StatsCalculationRequest } from "../../models/ApiTypes";
import { NodeRoles } from "../../models/Dfg";
import { buildKpiSpotlightId, getKpiDefinition } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { DfgUtils, getCustomKpisDfg, getNodeKpiStatistics } from "../../utils/DfgUtils";
import { Formatter, FormatterParams } from "../../utils/Formatter";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { getQuantity } from "../../utils/Quantities";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { LogStats } from "../../views/process-kpi-chart/stats/LogStats";
import { getRelativeOutputKpiMap } from "../dfg/nodes/NodeMarkupFactory";
import EdgeStatsLineGraph from "../graph/EdgeStatsLineGraph";
import { StatsRowPercentBar } from "../stats-section/StatsRowPercentBar";
import { StatsRowString } from "../stats-section/StatsRowString";
import { StatsRowValue } from "../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../stats-section/StatsSection";
import { EdgeStats, FrequencyAndProductStats, NodeStats } from "../stats-section/Utility";
import { LocalizationInfoStats } from "./LocalizationInfoStats";
import NodeStatsLineGraph from "../graph/NodeStatsLineGraph";

type ShowStatProps = {
    showYield?: boolean;
    showOutput?: boolean;
    showScrap?: boolean;
    showThroughput?: boolean;
    showStock?: boolean;
}

type SideStatisticsOutputProps = ShowStatProps & {
    disableProcessFocus?: boolean;
    disableCaseView?: boolean;
};

export default function SideStatisticsOutput(props: SideStatisticsOutputProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const isTerminalNode = settings.selection.node?.role === NodeRoles.Start || settings.selection.node?.role === NodeRoles.End;
    const showUnmarkedAside = !(settings.selection.node || settings.selection.edge) || isTerminalNode;

    const graphOptions: StatsCalculationRequest = {
        ...calculateOutputStatsArguments,
        calculateActivityValues: true,
        calculateNodes: true,
        calculateEdges: true,
        ...getCustomKpisDfg(settings, session, false, [KpiTypes.GoodQuantity, KpiTypes.ScrapQuantity, KpiTypes.WorkInProcessInventory, KpiTypes.ThroughputRate, KpiTypes.OrderBacklog])
    };
    const graph = useGraph(graphOptions, AnalysisType.Output, undefined, showUnmarkedAside);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    // If nothing is selected, return the unmarked output stats
    if (showUnmarkedAside)
        return <LogStats />;

    const isLoading = graph === undefined;

    const selectedMultiEdge = graph?.multiEdges.find(e => e.from === settings.selection.edge?.from && e.to === settings.selection.edge?.to);
    const selectedEdge = selectedMultiEdge ? DfgUtils.findObjectEdge(selectedMultiEdge, isObjectCentric, settings.graph.objectType) : undefined;

    const node = graph?.nodes.find(n => n.id === settings.selection.node?.id);
    const selectedNode = node ? DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType) : undefined;

    const label = selectedMultiEdge
        ? `${DfgUtils.getNodeLabelById(selectedMultiEdge.from, graph?.nodes, settings.groupingKey)} ➞ ${DfgUtils.getNodeLabelById(selectedMultiEdge.to, graph?.nodes, settings.groupingKey)}`
        : selectedNode
            ? getShortActivityLabelFromActivityValues(selectedNode?.activityValues, settings.groupingKey)
            : undefined;

    const kpi = settings.kpi.selectedKpi;

    const isGoodQuantity = [KpiTypes.GoodQuantity, KpiTypes.GoodQuantityTransport].includes(kpi);
    const isWorkInProcessInventory = [KpiTypes.WorkInProcessInventory, KpiTypes.WorkInProgressBeforeEventInventory].includes(kpi);
    const isThroughputRate = [KpiTypes.ThroughputRate, KpiTypes.ThroughputRateTransport].includes(kpi);
    const isWaitingOrders = [KpiTypes.OrderBacklog, KpiTypes.OrderBacklogBeforeEvent].includes(kpi);

    const nodeStatsEnabled = (props.showYield && isGoodQuantity) || (props.showScrap && kpi === KpiTypes.ScrapQuantity) ||
        (props.showStock && isWorkInProcessInventory) || (props.showThroughput && isThroughputRate) ||
        (props.showOutput && kpi === KpiTypes.ProducedQuantity) || isWaitingOrders;

    const edgeStatsEnabled = (props.showYield && isGoodQuantity) ||
        (props.showStock && isWorkInProcessInventory) ||
        (props.showThroughput && isThroughputRate) || isWaitingOrders;

    // Node stats (based on events/passes)
    const outputNodeKpiStat = selectedNode ? getNodeKpiStatistics(session, settings, selectedNode, settings.kpi.selectedKpi) : undefined;
    const outputNodeRelativeKpi = getRelativeOutputKpiMap(selectedNode, settings.quantity);
    const quantityFormatter = getQuantity(settings.quantity)?.unit.formatter;
    const formatParams: FormatterParams = { numDigits: 1, locale: session.locale };

    const kpiTypeNode = kpi === KpiTypes.OrderBacklog ? KpiTypes.OrderBacklogBeforeEvent :
        kpi === KpiTypes.WorkInProcessInventory ? KpiTypes.WorkInProgressBeforeEventInventory :
            kpi;

    const kpiDefinition = getKpiDefinition(kpi, { settings, session });

    const nodeLineGraphEnabled = (nodeStatsEnabled || [KpiTypes.Frequency, KpiTypes.ProductCount].includes(kpi)) && !!kpiDefinition?.nodeOverTimeStatisticsPath;

    return <>
        <div data-testid="sidePanel">
            {/* Node or edge selected */}
            {(settings.selection.node || settings.selection.edge) && <StatsSection title={label} isTitleLoading={isLoading}>
                <LocalizationInfoStats />
                {/* Node stats */}
                {settings.selection.node && !isTerminalNode && <>

                    {nodeStatsEnabled &&
                        <NodeStats
                            kpiType={kpiTypeNode}
                            node={selectedNode}
                            disableMedian={[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog].includes(kpi)}
                            disableMinMax={[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog].includes(kpi)}
                            disableSum={[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog, KpiTypes.ThroughputRate].includes(kpi)}
                            isLoading={isLoading} />}

                    {props.showOutput && kpi === KpiTypes.ProducedQuantity && quantityFormatter && (isLoading || outputNodeKpiStat !== undefined) && <StatsSubSection title={getKpiDefinition(KpiTypes.ProducedQuantity, { session, settings })?.label} spotlightId={buildKpiSpotlightId(KpiTypes.ProducedQuantity, ["Node"])}>
                        <StatsRowString label="common.statistics.sum" value={quantityFormatter(outputNodeKpiStat?.sum ?? 0, formatParams)} isHighlight={settings.kpi.statistic === StatisticTypes.Sum} isLoading={isLoading} />

                        {(isLoading || outputNodeRelativeKpi?.yield !== undefined) &&
                            <StatsRowPercentBar label="common.yield" value={outputNodeRelativeKpi?.yield} isLoading={isLoading} />}

                        {(isLoading || outputNodeRelativeKpi?.scrap !== undefined) &&
                            <StatsRowPercentBar label="common.scrap" value={outputNodeRelativeKpi?.scrap} isLoading={isLoading} />}

                    </StatsSubSection>}

                    {/* Line chart */}
                    {nodeLineGraphEnabled && <NodeStatsLineGraph />}

                    <FrequencyAndProductStats node={selectedNode} isLoading={isLoading} />
                </>}

                {/* Edge stats */}
                {settings.selection.edge && edgeStatsEnabled &&
                    <EdgeStats
                        kpiType={kpi}
                        disableMedian={[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog].includes(kpi)}
                        disableMinMax={[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog].includes(kpi)}
                        disableSum={[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog, KpiTypes.ThroughputRate].includes(kpi)}
                        edge={selectedEdge}
                        isLoading={isLoading} />}

                {/* Line chart */}
                {settings.selection.edge && edgeStatsEnabled && !!kpiDefinition?.edgeOverTimeStatisticsPath && <EdgeStatsLineGraph />}

                {/* Frequency stats edge */}
                {settings.selection.edge && <>
                    <StatsSubSection title="common.actionFrequency" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge"])}>
                        <StatsRowValue label="common.statistics.absCount" value={selectedEdge?.frequencyStatistics?.sum} unit={Formatter.units.number} isLoading={isLoading} />
                    </StatsSubSection>
                </>}
            </StatsSection>}
        </div>
    </>;
}
