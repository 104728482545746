import React, { useContext, useEffect } from "react";
import i18n from "../../i18n";
import Dropdown, { StringOption } from "../dropdown/Dropdown";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { useGraph } from "../../hooks/UseGraph";
import { GroupingKeys, NodeRoles } from "../../models/Dfg";
import { uniqBy } from "lodash";

export function MachineSelector(props: {
  className?: string;
  bare?: boolean;
  onChange?: (machine: string | undefined) => void;
  onBlur?: () => void;
  focusInitially?: boolean;
  canSelectAll?: boolean;
  hidePlaceHolder?: boolean;
}) {
    const settings = useContext(SettingsContext);
    const location = useLocation();

    const graphOptions = {
        calculateNodes: true,
        calculateRoles: true,
        calculateActivityValues: true,
    };

    const graph = useGraph({
        ...graphOptions,
        groupingKey: GroupingKeys.Machine,
    });

    const allMachines =
    (
        graph?.nodes.filter(
            (n) =>
                n.activityValues?.machine?.value !== undefined &&
          n.role !== NodeRoles.Inventory
        ) ?? []
    )
        .map((n) => ({
            label: n.activityValues?.machine?.value ?? "",
            value: n.activityValues?.machine?.value ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    const machines = uniqBy(allMachines, (o) => o.label);

    const isInitializing = graph === undefined;

    useEffect(() => {
        if (graph === undefined) return;

        const machine = machines.find(
            (m) => m.value === settings.selection.node?.activityValues?.machine?.value
        );
        if (machine)
            queueMicrotask(() => {
                settings.mergeSet({
                    kpiMatrix: {
                        machines: [machine.value.toString()],
                    }
                });
            });
    }, [graph]);

    const dropdownOptions: StringOption[] = isInitializing
        ? []
        : [
            ...machines.map((m) => {
                return {
                    label: m.value,
                    value: m.value,
                };
            }),
        ];

    if (!props.hidePlaceHolder) {
        dropdownOptions.unshift({
            label: i18n.t("common.pleaseSelect"),
            value: "",
        },);
    }    

    if (props.canSelectAll && !isInitializing) {
        const index = props.hidePlaceHolder ? 0 : 1;
        dropdownOptions.splice(index, 0, {
            label: i18n.t("common.allMachines"),
            value: "all",
        });
    }
       

    const dropdown = (
        <Dropdown
            className={props.className}
            focusInitially={props.focusInitially}
            placeholder={i18n.t("common.initializing").toString()}
            isSearchable={true}
            value={
                dropdownOptions.find(
                    (o) => settings.kpiMatrix.machineName === (o.value! as string)
                ) ?? dropdownOptions[0]
            }
            data-testid={"selectMachine"}
            onBlur={props.onBlur}
            onChange={(e) => {
                const ot = e!.value as string;
                settings.mergeSet({
                    kpiMatrix: {
                        machineName: ot === "" ? undefined : ot,
                    },
                });

                if (props.onChange) props.onChange(ot === "" ? undefined : ot);
            }}
            options={dropdownOptions}
        />
    );

    if (props.bare) return dropdown;

    return (
        <div className="section machine">
            <div className="title">
                {i18n.t("common.selectWorkplace")}
                <Spotlight
                    id={buildControllerSpotlightId(location.pathname, ["machine"])}
                    className="mls"
                />
            </div>
            <div className="select mt">{dropdown}</div>
        </div>
    );
}
