import React from "react";
import Modal, { IModal, ModalProps } from "../../components/modal/Modal";
import i18n from "../../i18n";

export const AboutModal = React.forwardRef((props: Partial<ModalProps>, ref: React.Ref<IModal>) => {
    return <Modal
        {...props}
        isClosable={true}
        width={600}
        ref={ref}
    >
        <div className="aboutModal">
            <div className="licenseHeader" dangerouslySetInnerHTML={{
                __html: i18n.t("about.licenseHeaderHtml")
            }}/>

            <div className="licenses">
                <iframe src="/licenses.html" title="ONIQ Open Source Licenses" />
            </div>

        </div>
    </Modal>;
});
