import { isArray } from "lodash";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { TabType } from "../../components/tabs/Tabs";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";

export interface ISidePanel {
    showPage(idx: number): void;
}

type SidePanelPropsType = {
    pages: TabType[];
    //statsProps: SideStatisticsPropsType;
    selectedIndex?: number;
    onChanged?: (tab: TabType, index: number) => void;
    children: React.ReactNode[] | React.ReactNode;
};

export const SidePanel = React.forwardRef((props: SidePanelPropsType, ref: React.Ref<ISidePanel>) => {

    const settings = useContext(SettingsContext);
    const [selectedTabIdx, setTabIdx] = useState(() => {
        return props.selectedIndex ?? (isArray(props.children) ? props.children.findIndex(p => p !== undefined) : 0) ?? 0;
    });

    // Expose interface
    useImperativeHandle(ref, () => ({
        showPage(idx: number) {
            setTabIdx(idx);
        }
    }));

    // If there's only one tab, switch to that immediately
    useEffect(() => {
        const numVisiblePages = (props.pages ?? []).filter(p => !p.isDisabled && !p.isHidden).length;
        if (numVisiblePages === 1)
            setTabIdx((props.pages ?? []).findIndex(p => !p.isDisabled && !p.isHidden));
    }, [
        JSON.stringify(props.pages),
    ]);

    useEffect(() => {
        settings.set({sidePanelPage: selectedTabIdx});
    }, [selectedTabIdx]);

    return <div className="sidePanel">
        <div className="buttons">
            {props.pages.map((p, idx) => {
                return <button
                    data-testid={"sidePanel-" + idx}
                    title={i18n.t(p.label ?? "").toString()}
                    style={{
                        display: p.isHidden === true || (isArray(props.children) && props.children?.[idx] === null) ? "none" : "block"
                    }}
                    onClick={() => {
                        setTabIdx(idx);

                        if (props.onChanged)
                            props.onChanged({...props.pages[idx]}, idx);
                    }}
                    className={(p.className ? p.className : "tabButton" + String(idx)) + (selectedTabIdx === idx ? " selected" : "")}
                    key={"button-" + (p.label ?? p.icon ?? idx.toString())}>
                    {i18n.t(p.label ?? "").toString()}
                </button>;
            })}
        </div>
        <div className="pages">
            {isArray(props.children) ? props.children.map((c, idx) => {
                if (idx !== selectedTabIdx)
                    return null;
                
                return <div key={idx} className="visible">
                    {c}
                </div>;
            }) : props.children}
        </div>
    </div>;
});