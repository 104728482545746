
import React, { useContext, useEffect, useMemo } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { useGraph } from "../../hooks/UseGraph";
import i18n from "../../i18n";
import { ALL_OBJECT_INDICATOR, CASE_TYPE_ID, MultiEdge, Node } from "../../models/Dfg";
import { DfgUtils } from "../../utils/DfgUtils";
import Dropdown, { StringOption } from "../dropdown/Dropdown";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { DeepPartial } from "../../utils/ObjectMerger";
import { set } from "lodash";

export type ObjectControlProps = {
    showContextSwitch: boolean,
    disableObjectType?: boolean,
    disableCaseView?: boolean,
    disableAllObjects?: boolean
}

export function getValidObjectControlsSettings(session: SessionType, settings: SettingsType): DeepPartial<SettingsType> {
    const result: DeepPartial<SettingsType> = { };

    if (!!session.objectValues?.length && (settings.graph.objectType === undefined ||
        (settings.graph.objectType !== ALL_OBJECT_INDICATOR && !(session.objectValues ?? []).includes(settings.graph.objectType))))
        set(result, "graph.objectType", ALL_OBJECT_INDICATOR);

    return result;
}

export function ObjectControls(props: ObjectControlProps) {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const location = useLocation();
    
    const graph = useGraph({
        calculateEdges: true,
        calculateUnknownStats: true,
        useActivityPasses: true,
    }, undefined, false, session.project?.eventKeys?.objectType === undefined);

    const addAllObjects = true;

    // Data retrieval and dropdown initialization
    const { objectTypes, dropdownOptions } = useMemo(() => {
        if (!graph)
            return { objectTypes: [], dropdownOptions: [] };

        const objectTypes: string[] = DfgUtils.getAllObjectTypes(graph, addAllObjects).sort((a, b) => {
            if (a === ALL_OBJECT_INDICATOR)
                return -1;
            if (b === ALL_OBJECT_INDICATOR)
                return 1;
            return a.localeCompare(b);
        });

        const dropdownOptions = objectTypes.filter(o => o !== CASE_TYPE_ID).map(o => {
            if (o === ALL_OBJECT_INDICATOR)
                return {
                    label: i18n.t("common.allObjects"),
                    value: o,
                } as StringOption;
            return {
                label: o,
                value: o,
            } as StringOption;
        });

        return {
            objectTypes: objectTypes.filter(o => o !== CASE_TYPE_ID && o !== undefined),
            dropdownOptions,
        };
    }, [
        graph?.hash,
        session.locale,
    ]);

    useEffect(() => {
        if (objectTypes.length > 0 && (settings.graph.objectType === undefined || !objectTypes.includes(settings.graph.objectType)))
            settings.setGraph({ objectType: objectTypes[0] });
    }, [
        objectTypes,
    ]);

    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);

    if (!hasObjects || props.disableObjectType || dropdownOptions.length <= 1)
        return null;

    return <div className="section material">
        <div className="title">
            {i18n.t("common.material")}
            
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["material"])} className="mls"/>
        </div>
        <div className="select mt">
            <Dropdown
                placeholder={i18n.t("common.initializing").toString()}
                isSearchable={true}
                value={dropdownOptions.find(o => o.value === settings.graph.objectType)!}
                data-testid={"selectObjectType"}
                onChange={(e) => {
                    const ot = e!.value as string;
                    settings.set({
                        ...settings,
                        graph: {
                            ...settings.graph,
                            objectType: ot,
                        },
                        selection: shouldResetSelection(ot) ? {} : settings.selection,
                    });
                }}
                options={dropdownOptions} />
        </div>

        {props.showContextSwitch && <div className="noselect mt">
            <label className="checkboxLabel">
                <input type="checkbox" id="checkbox-context" className="checkbox" checked={settings.graph.showObjectContext} onChange={(e) => { settings.setGraph({ showObjectContext: e.target.checked }); }} /><label htmlFor="checkbox-context" />
                {i18n.t("common.showObjectContext")}
            </label>
        </div>}
    </div>;

    /**
     * This only works when we are working with objects
     */
    function shouldResetSelection(objectType: string | undefined) {
        if (!objectType)
            return false;

        return ((settings.selection.node && !getNodeObjectTypes(settings.selection.node).includes(objectType)) ||
            (settings.selection.edge && !getEdgeObjectTypes(settings.selection.edge).includes(objectType)));
    }
}

export function getEdgeObjectTypes(edge: MultiEdge | undefined) {
    if (!edge)
        return [];

    return (edge.edges.map(e => e.objectType) as string[]).
        filter(o => o !== CASE_TYPE_ID).
        sort();
}

export function getNodeObjectTypes(node: Node | undefined) {
    if (!node)
        return [];

    return (node.objects ?? []).filter(o => o.type !== undefined).map(o => o.type as string).sort();
}
