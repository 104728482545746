import { Matrix } from "./Matrix";

export class Vector {
    constructor(public x: number, public y: number) { }

    static fromPoint(p: {x: number, y: number}) {
        return new Vector(p.x, p.y);
    }

    /**
     * Clones a vector
     */
    clone() {
        return new Vector(this.x, this.y);
    }

    /**
     * Multiplies this vector with the matrix provided, and returns the result
     * @param matrix Matrix to multiply with
     */
    transform(matrix: Matrix) {
        return new Vector(
            matrix.m11 * this.x + matrix.m12 * this.y + matrix.tx,
            matrix.m21 * this.x + matrix.m22 * this.y + matrix.ty
        );
    }

    /**
     * Returns this vector's length
     */
    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    /**
     * Returns this vector's length, squared. Faster than
     * length() and still usable for sorting.
     * @returns length²
     */
    lengthSq() {
        return this.x * this.x + this.y * this.y;
    }

    /**
     * Calculates the dot product of this vector and the other one provided
     * @param other Other vector to multiply with
     */
    dot(other: Vector) {
        return this.x * other.x + this.y * other.y;
    }

    /**
     * Returns the angle between this and the vector provided in radians [0..PI]
     * @param other Other vector
     */
    getAngle(other: Vector) {
        const dot = this.dot(other);
        const mag = this.length() * other.length();

        return Math.acos(dot / mag);
    }

    subtract(v: Vector) {
        return new Vector(this.x - v.x, this.y - v.y);
    }

    add(v: Vector) {
        return new Vector(this.x + v.x, this.y + v.y);
    }

    multiply(scalar: number) {
        return new Vector(this.x * scalar, this.y * scalar);
    }

    normalize() {
        const length = this.length();
        if (length === 0)
            return new Vector(0, 0);

        return this.multiply(1 / this.length());
    }

    toString() {
        return `${this.x}, ${this.y}`;
    }

    static getCenter(vectors: Vector[]) {
        let x = 0, y = 0;
        for (const v of vectors) {
            x += v.x;
            y += v.y;
        }

        return new Vector(x / vectors.length, y / vectors.length);
    }
}
