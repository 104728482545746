import React, { useContext } from "react";
import { ViewSubtitle } from "./ViewSubtitle";
import { useLocation } from "react-router-dom";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useGraph } from "../../hooks/UseGraph";
import { ValueSpinner } from "../value-spinner/ValueSpinner";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { ALL_NODES } from "../controls/NodeSelectionControls";

export function ValueStreamSubtitle() {

    const settings = useContext(SettingsContext);
    const location = useLocation();
    const isCaseGantt = location.pathname.includes("case-gantt");

    const graph = useGraph({
        calculateActivityValues: true,
        calculateNodes: true,
    }, undefined, undefined, !isCaseGantt);

    const restrictedNode = graph?.nodes.find(n => n.id === settings.gantt.restrictToNode);

    if (!isCaseGantt || settings?.gantt?.restrictToNode === ALL_NODES)
        return <ViewSubtitle />;

    const isLoading = graph === undefined;
    const title = getShortActivityLabelFromActivityValues(restrictedNode?.activityValues, settings.groupingKey);

    return <div className="text viewSubtitleSpinner">
        <ValueSpinner isLoading={isLoading}>
            {title}
        </ValueSpinner>
    </div>;
}