import { BaseGraph } from "../models/Dfg";

/**
 * This function removes statistics delivered by the backend after link nodes that do not make any sense
 * @param graph The graph instance to remove the stats from
 * @returns Cleaned graph instance
 */
export function removeStatsAfterLinks(graph: BaseGraph): BaseGraph {

    const edges = graph.edges.map(e => {
        if (e.from.startsWith("__LINK_"))
            return {
                from: e.from,
                to: e.to,
                objectType: e.objectType,
                frequencyStatistics: e.frequencyStatistics
            };
        return e;
    });

    return {...graph, edges};

}