import { useContext, useEffect, useState } from "react";
import { ColumnInfo, GetDistinctAttributeValuesRequest } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { Datastores } from "../utils/Datastores";
import { noop } from "lodash";

/**
 * Maximum number of distinct values we'll ask the API to fetch for us
 */
export const maxValuesPerColumn = 10000;

export type ValueLookupType = {[columnId: string]: ColumnInfo[]};

export function useColumnValues(columns: string[], requestOptions?: Partial<GetDistinctAttributeValuesRequest>, disabled = false) {
    const session = useContext(SessionContext);
    const settingsContext = useContext(SettingsContext);
    const [state, setState] = useState<ValueLookupType | undefined>();

    const [subscriptionId, setSubscriptionId] = useState<number>(0);
    useEffect(() => {
        const subscriptionId = Datastores.distinctAttributeValues.getSubscriptionId();
        setSubscriptionId(subscriptionId);

        return () => {
            Datastores.distinctAttributeValues.cancelSubscription(subscriptionId);
        };
    }, []);

    useEffect(() => {
        if (!session.project || !subscriptionId || disabled)
            return;

        // For each column, request distinct values and store them!
        const result = {...state};
        for (const unavailableColumnValue of columns) {
            const request = {
                eventKeys: session.project.eventKeys!,
                eventFilters: [],
                attributes: [unavailableColumnValue],
                uploadId: requestOptions?.uploadId ?? session.project.uploadId!,
                uploads: session.project?.uploads,
                limit: maxValuesPerColumn,
                ...requestOptions
            };

            Datastores.distinctAttributeValues.get(request, subscriptionId).then((response) => {
                result[unavailableColumnValue] = response[0].values;
                setState({...result});
            }).catch(noop);
        }
    }, [
        JSON.stringify(columns), 
        JSON.stringify(requestOptions),
        session.project,
        subscriptionId,
        settingsContext.apiRetry,
    ]);

    return state;
}
