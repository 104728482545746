import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, applyViewConfiguration } from "../../contexts/SettingsContext";
import { Api } from "../../api/Api";

export function GotoView() {
    const navigate = useNavigate();
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const { favoriteId } = useParams<{
        favoriteId: string;
    }>();
    
    React.useEffect(() => {
        if (!session || !settings || !favoriteId)
            return;

        Api.getViewConfigurationsById(favoriteId).then((viewConfiguration) => {
            // We got a view configuration! Apply
            applyViewConfiguration(viewConfiguration, settings, navigate);
        }).catch((e) => {
            // We didn't get a view configuration. Redirect to projects.
            window.location.href = "/projects";
            console.error(e);
        });
    }, [
        session,
        settings,
        favoriteId,
    ]);

    // Here could be some fancy loading screen, but tbh I don't think anyone
    // would notice it anyway as things hopefully load fast.
    return <></>;
}