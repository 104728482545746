import { sortBy, uniq } from "lodash";
import { useContext, useEffect, useMemo, useState, useRef } from "react";
import { EdgeLabelFunc, MarkupFunc } from "../components/dfg/DfGraph";
import { bestPerformanceLayoutOptions, bestResultLayoutOptions, layoutGraph, LayoutOptions } from "../components/dfg/GraphLayout";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../contexts/SettingsContext";
import { ALL_OBJECT_INDICATOR, BaseGraph, Edge, GroupingKeys, Layout, Node } from "../models/Dfg";
import { DfgUtils, updateHash } from "../utils/DfgUtils";
import { SimpleCache } from "../utils/SimpleCache";
import { useCache } from "./UseCache";
import { useMountedState } from "./UseMounted";
import { getHash } from "../utils/Utils";
import { isObjectCentricAvailable } from "../utils/SettingsUtils";

type GraphComplexityMeasure = {
    edges: number,
    nodes: number
}

const smallGraphComplexityLimit: GraphComplexityMeasure = { edges: 75, nodes: 25 };
const midGraphComplexityLimit: GraphComplexityMeasure = { edges: 150, nodes: 75 };
export const preferredNodeCountLimit = 150;
const maxGraphComplexityLimit: GraphComplexityMeasure = { edges: 5000, nodes: 400 };

const maxComplexitySteps = 50;

export type GraphLayoutProps = {
    graph?: BaseGraph,
    complexityCutoffScore?: number,
    preferredNodeCount?: number,
    preLayoutGraphFilterFunc?: GraphFilterFunc,
    postLayoutGraphFilterFunc?: GraphFilterFunc,
    markupFunc: MarkupFunc,
    edgeLabelFunc?: EdgeLabelFunc,
    isObjectCentric: boolean,
    hasEventId: boolean,
    disabled?: boolean,
    onLayoutFinished?: (layout: GraphLayoutState) => void,
    onScoreLimited?: (percent: number) => void,
}

export type GraphFilterFunc = (graph: BaseGraph | undefined, settings: SettingsType, isObjectCentric: boolean) => BaseGraph | undefined;

export type GraphLayoutState = {
    isGraphTooLarge: boolean,

    cutoffScore: number | undefined;

    /**
     * This is the limit beyond which the graph gets too complicated to be rendered.
     */
    cutoffScoreLimit: number | undefined,

    isLayouting: boolean,

    layout?: Layout,

    /**
     * The graph that has been layouted, with pre-, post- and score filteres applied
     */
    layoutedGraph?: BaseGraph,
    preLayoutGraphFilterFunc?: GraphFilterFunc,
    postLayoutGraphFilterFunc?: GraphFilterFunc,

    complexitySteps: number[] | undefined;
    complexityHistogram: number[] | undefined;
}

/**
  * This hook returns a layouted graph, and it's layout is always kept fresh!
  * This way, one view can display a graph with very detailed and large nodes, while another
  * one has a compact view with a very different graph layout.
  * @param markupFunc node markup function. Required for getting the node's dimensions
  * @returns a graph state object
  */
export function useGraphLayout(props: GraphLayoutProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const isMounted = useMountedState();

    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);
    const [state, setState] = useState<GraphLayoutState>({
        cutoffScoreLimit: 0,
        cutoffScore: undefined,
        isGraphTooLarge: false,
        isLayouting: false,
        postLayoutGraphFilterFunc: undefined,
        preLayoutGraphFilterFunc: undefined,
        layoutedGraph: undefined,
        layout: undefined,
        complexityHistogram: undefined,
        complexitySteps: undefined,
    });

    // The complexity to use for our layout
    const complexity = props.complexityCutoffScore ?? settings.graph.complexityCutoffScore ?? 0;

    // 1. Apply pre-layout filters
    const preFilteredGraph = useMemo(() => {
        // We have a new graph, indicate that we're busy!
        setState((state) => {
            return { ...state, isLayouting: true };
        });

        if (props.preLayoutGraphFilterFunc) {
            const graph = props.preLayoutGraphFilterFunc(props.graph, settings, props.isObjectCentric);
            updateHash(graph);
            return graph;
        }

        return props.graph;
    }, [
        props.graph?.hash,
        props.isObjectCentric,
        props.hasEventId,
        props.disabled,

        hasObjects,

        settings.graph.nodeDetailLevel,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.graph.orientation,
        settings.graph.showObjectContext,
        settings.graph.objectType,
        settings.graph.secondGroupingLevel,

        session.numberFormatLocale,
        session.locale,
    ]);

    // 2. Determine cutoff score limit
    const { cutoffScoreLimit, isPreferredNodeCount } = useMemo(() => {
        // If the layout is pinned to a specific cutoff, there's no point in generating
        // a suggestion. However, having the filtered graph would be nice!
        if (props.complexityCutoffScore !== undefined)
            return { cutoffScoreLimit: props.complexityCutoffScore, isPreferredNodeCount: false };

        if (!preFilteredGraph)
            return { cutoffScoreLimit: undefined, isPreferredNodeCount: false };


        if (isGraphComplexityInRange(preFilteredGraph!, maxGraphComplexityLimit) &&
            (props.preferredNodeCount === undefined || preFilteredGraph.nodes.length <= props.preferredNodeCount))
            return {
                cutoffScoreLimit: 0,
                isPreferredNodeCount: false,
            };

        return {
            cutoffScoreLimit: suggestCutoffScore(preFilteredGraph, maxGraphComplexityLimit, props.preferredNodeCount) ?? 0,
            isPreferredNodeCount: props.preferredNodeCount !== undefined,
        };
    }, [
        props.complexityCutoffScore,
        preFilteredGraph?.hash,
    ]);

    const complexitySteps = useComplexitySteps({
        graph: props.graph,
        filterFunctions: [props.preLayoutGraphFilterFunc, props.postLayoutGraphFilterFunc].filter(f => f !== undefined) as GraphFilterFunc[],
        settings,
        hasObjects,
        disabled: props.complexityCutoffScore !== undefined,
    });

    // We have a new graph here. Check if the current cutoffScore is below the limit, and if so,
    // set it to the limit. Also, invoke a callback!
    useEffect(() => {
        if (cutoffScoreLimit !== undefined &&
            ((settings.graph.complexityCutoffScore !== undefined && settings.graph.complexityCutoffScore < cutoffScoreLimit) ||
                settings.graph.complexityCutoffScore === undefined)) {
            settings.setGraph({
                complexityCutoffScore: cutoffScoreLimit,
            });

            const idx = complexitySteps.complexitySliderSteps.findIndex(s => s >= cutoffScoreLimit);

            // Only show notification if not all paths are visible
            if (idx > 0 && !isPreferredNodeCount)
                props.onScoreLimited?.(complexitySteps.histogram[idx]);
        }
    }, [
        preFilteredGraph?.hash
    ]);

    // The cutoff score we're now working with
    const cutoffScore = props.complexityCutoffScore ?? settings.graph.complexityCutoffScore ?? cutoffScoreLimit;

    // 3. Generate prefiltered graph. Undefined if we don't have a cutoff score
    const scorePreFilteredGraph = useMemo(() => {
        if (cutoffScore === undefined || !preFilteredGraph)
            return undefined;

        if (cutoffScore === 0)
            return preFilteredGraph;

        return DfgUtils.filterScore(preFilteredGraph, cutoffScore);
    }, [
        preFilteredGraph?.hash,
        cutoffScore,
    ]);

    const isGraphTooLarge = scorePreFilteredGraph !== undefined &&
        !isGraphComplexityInRange(scorePreFilteredGraph, maxGraphComplexityLimit);

    const layoutDeps = [
        cutoffScore,
        preFilteredGraph?.hash,
        hasObjects,

        props.isObjectCentric,
        props.hasEventId,
        props.disabled,
        settings.groupingKey,

        settings.graph.nodeDetailLevel,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.graph.orientation,
        settings.graph.showObjectContext,
        settings.graph.objectType,
        settings.graph.secondGroupingLevel,

        settings.graph.complexityCutoffScore,

        session.numberFormatLocale,
        session.locale,
    ];

    const layoutCache = (useRef<SimpleCache<string, Layout>>(new SimpleCache<string, Layout>(50))).current;

    const scorePreFiltereGraphHashRef = useRef<string | undefined>(scorePreFilteredGraph?.hash);
    scorePreFiltereGraphHashRef.current = scorePreFilteredGraph?.hash;

    useEffect(() => {
        if (settings.graph.complexityCutoffScore === undefined)
            // Initialize this first, then we can still consider layouting this thing
            return;

        if (!scorePreFilteredGraph || isGraphTooLarge || props.disabled) {
            const newState = {
                ...state,
                isLayouting: false,
                cutoffScore,
                cutoffScoreLimit,
                layout: undefined,
                layoutedGraph: undefined,
                postLayoutGraphFilterFunc: props.postLayoutGraphFilterFunc,
                preLayoutGraphFilterFunc: props.preLayoutGraphFilterFunc,
                isGraphTooLarge: isGraphTooLarge,
            };
            setState(newState);
            props.onLayoutFinished?.(newState);
            return;
        }

        const depsHash = getHash(layoutDeps);
        if (layoutCache.has(depsHash)) {
            finalizeLayout(layoutCache.get(depsHash)!);
            return;
        }

        if (!state.isLayouting)
            setState((state) => {
                return { ...state, isLayouting: true };
            });

        layoutGraph(
            scorePreFilteredGraph,
            settings,
            session,
            props.markupFunc,
            props.edgeLabelFunc,
            selectLayoutOptions(scorePreFilteredGraph)
        ).then((layout) => {
            layoutCache.set(depsHash, layout);
            // We need to check whether the current hash of scorePreFilteredGraph still matches the hash that was submitted for layouting.
            // There are circumstances where this may have changed due to changes in settings or graphs.
            // This can especially occur if layout was cached previously and can be returned very fast while in the background layouting is done for an old graph state.
            if (scorePreFiltereGraphHashRef.current !== scorePreFilteredGraph?.hash)
                return;
            finalizeLayout(layout);

        });
    }, layoutDeps);
    return state;

    function finalizeLayout(layout: Layout) {
        if (!isMounted())
            return;

        // Apply graph post layout filter
        let filteredGraph: BaseGraph | undefined = scorePreFilteredGraph;
        let filteredLayout: Layout = layout;
        if (props.postLayoutGraphFilterFunc) {
            filteredGraph = props.postLayoutGraphFilterFunc(scorePreFilteredGraph, settings, props.isObjectCentric);
            updateHash(filteredGraph);

            if (filteredGraph) {
                filteredLayout = syncLayoutEdges(layout, filteredGraph);
                filteredLayout.hash = "";
            }
        }

        filteredLayout.hash = getHash({
            // Hashing only the significant bits speeds things up dramatically
            edges: filteredLayout.edges.map(e => e.from + "-" + e.to),
            nodes: filteredLayout.nodes
        });

        const newState = {
            ...state,
            layout: filteredLayout,
            layoutedGraph: filteredGraph,
            isLayouting: false,
            isGraphTooLarge: false,
            cutoffScore: complexity,
            cutoffScoreLimit,
            complexitySteps: complexitySteps.complexitySliderSteps,
            complexityHistogram: complexitySteps.histogram,
        };

        props.onLayoutFinished?.(newState);

        setState(newState);
    }
}

export function defaultGraphFilter(allObjectsGraph: BaseGraph | undefined, settings: SettingsType, isObjectCentric: boolean) {
    if (allObjectsGraph === undefined || !isObjectCentric || settings.graph.objectType === undefined)
        return allObjectsGraph;
    // TODO: remove Gebinde entry once we introduced project settings for overlapping production
    if (!isObjectCentric || (settings.graph.objectType === "Gebinde" || settings.graph.objectType === "Charge"))
        return DfgUtils.dropOnlyCaseEdges(DfgUtils.getGraphWithOnlyCaseStartEnd(allObjectsGraph));
    if (!settings.graph.showObjectContext && settings.graph.objectType !== ALL_OBJECT_INDICATOR)
        return DfgUtils.filterObjectGraph(allObjectsGraph, settings.graph.objectType);
    if (settings.graph.showObjectContext || settings.graph.objectType === ALL_OBJECT_INDICATOR) {
        if ([GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream].includes(settings.groupingKey))
            return DfgUtils.filterPureObjectGraph(allObjectsGraph);
        else
            return DfgUtils.filterPureObjectGraph(allObjectsGraph, true);
    }
    return allObjectsGraph;
}

function isGraphComplexityInRange(graph: BaseGraph, limit: GraphComplexityMeasure) {
    return graph.nodes.length < limit.nodes && (graph.multiEdges ?? graph.edges).length < limit.edges;
}

function selectLayoutOptions(graph: BaseGraph | undefined): Partial<LayoutOptions> | undefined {
    if (graph === undefined)
        return undefined;
    if (graph && isGraphComplexityInRange(graph, smallGraphComplexityLimit)) {
        return bestResultLayoutOptions;
    } else if (graph && isGraphComplexityInRange(graph, midGraphComplexityLimit))
        return undefined; // default options
    return bestPerformanceLayoutOptions;
}

/**
 * Given a graph and a desired number of nodes and edges, this function returns
 * the cutoffScore required to meet that complexity.
 */
function suggestCutoffScore(graph: BaseGraph | undefined, limit: GraphComplexityMeasure, preferredNodeCount?: number) {
    if (graph === undefined)
        return undefined;

    const sortedNodeScores = sortBy(graph.nodes, n => -(n.score ?? Infinity)).map(n => n.score).filter(s => s !== undefined) as number[];
    const multiEdgesWithScores = graph.multiEdges?.map(me => {
        const edgeScores = me.edges.map(e => e.score).filter(s => s !== undefined) as number[];
        const multiEdgeScore = (edgeScores.length > 0) ? Math.max(...edgeScores) : undefined;
        return {
            score: multiEdgeScore,
            multiEdge: me
        };
    }) ?? [];
    const sortedMultiEdgeScores = (sortBy(multiEdgesWithScores, e => -(e.score ?? Infinity))).map(e => e.score).filter(s => s !== undefined) as number[];

    // Nodes and edges may share the same score. If we're allowing e.g. 500 nodes max, and we picked the
    // score if the 500th node, that score may result in 503 nodes being selected, which is too much.
    // So let's go to the one index that contains one node/edge too much, and seek for the index with the
    // next smaller score.
    const nextSmallerScore = (scores: number[], maxIdx: number) => {
        if (scores.length === 0)
            return 0;

        let idx = Math.min(scores.length - 1, maxIdx - 1);
        const v = scores[idx];
        while (idx > 0) {
            if (scores[idx] !== v)
                return scores[idx];

            idx--;
        }

        return scores[0];
    };

    const suggestedNodeScore = nextSmallerScore(sortedNodeScores, Math.min(preferredNodeCount ?? Number.MAX_SAFE_INTEGER, limit.nodes));
    const suggestedEdgeScore = nextSmallerScore(sortedMultiEdgeScores, limit.edges) || 0;

    if (preferredNodeCount !== undefined)
        return suggestedNodeScore;

    return Math.max(suggestedNodeScore, suggestedEdgeScore);
}

function syncLayoutEdges(layout: Layout, graph: BaseGraph) {
    const filteredLayoutEdges = layout.edges.filter(chartEdge => (graph?.multiEdges ?? []).some(e => e.from === chartEdge.from && e.to === chartEdge.to)) ?? [];
    return { ...layout, edges: filteredLayoutEdges };
}

// #region Complexity step calculation
/**
 * Returns visible complexity steps and complexity histograms
 */
function useComplexitySteps(props: {
    graph: BaseGraph | undefined,
    filterFunctions: GraphFilterFunc[],
    settings: SettingsType,
    hasObjects: boolean,
    disabled?: boolean
}) {
    return useCache(() => {
        const defaultResult = { complexitySliderSteps: [0], histogram: [0] };
        if (props.disabled || props.graph === undefined)
            return defaultResult;

        const steps = DfgUtils.sortedScores(props.graph);

        if (steps.length === 0)
            return defaultResult;

        // In case of OC graphs:
        // Limit complexity reduction so that at least one node of that object type is visible still
        const isObjectCentric = props.hasObjects;
        const isObjectContextChecked = props.settings.graph.showObjectContext;

        const constrainLowerLimit = isObjectCentric && !isObjectContextChecked;

        if (!constrainLowerLimit)
            return prepareStepsWithHistogram(
                getSignificantComplexitySteps(props.graph, reduceComplexitySteps(steps), props.settings, props.hasObjects, props.filterFunctions),
                props.graph.nodes ?? [], props.graph.edges ?? []
            );

        // Determine maximum complexity score of the object nodes. We must not go above that score,
        // because then no node of that object would be visible anymore.
        const objectNodes = (props.graph?.nodes ?? []).filter(n => (n.objects ?? []).some(o => o.type === props.settings.graph.objectType));
        const maxObjectScore = Math.max(...(objectNodes.map(o => o.score).filter(s => s !== undefined) as number[]));

        // Only accept reduction scores that are below that limit
        const objectSteps = reduceComplexitySteps(steps.filter(s => s < maxObjectScore));
        const significantSteps = getSignificantComplexitySteps(props.graph, objectSteps, props.settings, props.hasObjects, props.filterFunctions);

        const objectEdges = DfgUtils.filterObjectEdges(props.graph.multiEdges ?? [], props.settings.graph.objectType);
        const flatEdges = objectEdges.map(o => o.edges.filter(e => e.objectType === props.settings.graph.objectType)).flat();
        return prepareStepsWithHistogram(significantSteps, objectNodes, flatEdges);
    }, [
        props.disabled,
        props.graph?.hash,
        props.hasObjects,
        props.settings.graph.showObjectContext,
        props.settings.graph.objectType,
    ]);
}

function reduceComplexitySteps(steps: number[]): number[] {
    // Reduce complexity steps to a sane amount we can choose from with a
    // 150px high slider. This is necessary, because we need to check graphs for
    // identity, and that takes too much time otherwise.
    if (steps.length <= maxComplexitySteps)
        return steps;

    const skipRatio = steps.length / maxComplexitySteps;

    const reduced: number[] = [];
    for (let i = steps.length - 1; i >= 0; i -= skipRatio)
        reduced.push(steps[Math.floor(i)]);

    return uniq(reduced).reverse();
}

/**
 * Generates a histogram with cutoff score vs number of nodes that have a higher score.
 * Returns the response object.
 */
function prepareStepsWithHistogram(complexitySliderSteps: number[], objectNodes: Node[], multiEdges: Edge[]) {
    // Create histogram that gives an indication how many nodes are visible in that
    // step
    const histogram: number[] = Array(complexitySliderSteps.length).fill(0);
    for (let i = 0; i < complexitySliderSteps.length; i++) {
        const step = complexitySliderSteps[i];
        histogram[i] = objectNodes.filter(o => (o.score ?? 0) >= step).length +
            multiEdges.filter(o => (o.score ?? 0) >= step).length;
    }

    const cnt = histogram[0] ?? 1;
    return {
        histogram: histogram.map(h => (h / cnt) * 100),
        complexitySliderSteps,
    };
}


/**
 * Returns the complexity steps that actually visually modify the graph
 */
function getSignificantComplexitySteps(graph: BaseGraph | undefined, steps: number[], settings: SettingsType, hasObjects: boolean, filterFunctions: GraphFilterFunc[]) {
    if (!graph)
        return [0];

    const result: number[] = [];
    let hash: string | undefined = undefined;

    let filtered: BaseGraph | undefined = graph;
    for (const fn of filterFunctions)
        filtered = fn(filtered, settings, hasObjects);

    if (!filtered)
        return [0];

    // Filter scores, going from basic to complex
    for (const step of steps.reverse()) {
        const newHash = getFilteredGraphHash(filtered, step);

        if (newHash === undefined)
            // Graph is completely empty now, let's skip this level
            continue;

        if (newHash === hash)
            // Nothing changed to previous step
            continue;

        hash = newHash;
        result.push(step);
    }

    return result.length > 0 ? result.reverse() : [0];
}

/**
 * For the given graph, cutoffScore and objectType, this function returns a hash.
 * If that hash does not change, the set of nodes and multiEdges passing the test
 * did not change.
 */
function getFilteredGraphHash(graph: BaseGraph, score: number) {
    const scoreFiltered = {
        nodes: graph.nodes.filter(n => (n.score ?? 0) >= score),
        multiEdges: graph.multiEdges?.filter(e => e.edges.some(me => (me.score ?? 0) >= score)),
    };

    return getHash([
        scoreFiltered.nodes.map(n => n.id),
        scoreFiltered.multiEdges?.map(e => e.from + "-" + e.to),
    ]);
}

// #endregion
