import i18next from "i18next";
import React, { useContext, useImperativeHandle, useRef } from "react";
import Dropdown from "../../components/dropdown/Dropdown";
import Modal, { IModal, ModalProps } from "../../components/modal/Modal";
import { SessionContext } from "../../contexts/SessionContext";
import i18n, { numberFormatLocales, supportedLanguages } from "../../i18n";
import { AboutModal } from "./AboutModal";

export const SettingsModal = React.forwardRef((props: Partial<ModalProps>, ref: React.Ref<IModal>) => {
    const session = useContext(SessionContext);

    const aboutModalRef = useRef<IModal>(null);

    const localeOptions = Object.keys(supportedLanguages).map(locale => {
        return {
            label: i18n.t("locales." + locale),
            value: locale,
        };
    });

    const selectedLocale = localeOptions.find(o => o.value === session.locale)!;

    const numberFormatLocale = numberFormatLocales.map(f => {
        return {
            value: f.locale,
            label: f.example,
        };
    });

    const selectedNumberFormatLocale = numberFormatLocale.find(o => o.value === session.numberFormatLocale)!;

    const timezoneOptions = [{
        label: i18n.t("timezones.europe-berlin"),
        value: "Europe/Berlin"
    }];

    const settingsModalRef = useRef<IModal>(null);

    useImperativeHandle(ref, () => ({
        show() {
            settingsModalRef.current?.show();
        },
        hide() {
            settingsModalRef.current?.hide();
        }
    }));

    return <Modal
        {...props}
        isClosable={true}
        ref={settingsModalRef}
        bottom={<div className="about"><a onClick={() => aboutModalRef.current?.show()}>{i18n.t("common.viewOpenSourceLicenses")}</a></div>}
        title="profile.settings">

        <div className="twoColumns">
            <label>{i18n.t("common.language")}</label>
            <div className="flexColumn">
                <Dropdown
                    className="dropdownLight"
                    options={localeOptions}
                    value={selectedLocale}
                    onChange={(e) => {
                        if (e?.value) {
                            localStorage.setItem("locale", e.value.toString());
                            i18next.changeLanguage(e.value.toString());
                            session.set({
                                locale: e.value.toString(),
                            });
                        }
                    }}
                />
            </div>

            <label>{i18n.t("common.numberFormatLocale")}</label>
            <div className="flexColumn">
                <Dropdown
                    className="dropdownLight"
                    options={numberFormatLocale}
                    value={selectedNumberFormatLocale}
                    onChange={(e) => {
                        if (e?.value) {
                            localStorage.setItem("numberFormatLocale", e.value.toString());
                            session.set({
                                numberFormatLocale: e.value.toString(),
                            });
                        }
                    }}
                />
            </div>

            <label>{i18n.t("common.timezone")}</label>
            <div className="flexColumn">
                <Dropdown
                    className="dropdownLight"
                    options={timezoneOptions}
                    value={timezoneOptions.find(o => o.value === session.timezone)!}
                    onChange={(e) => {
                        if (e?.value) {
                            localStorage.setItem("timezone", e.value.toString());
                        }
                    }}
                />
            </div>
        </div>
        <AboutModal ref={aboutModalRef} />
    </Modal>;
});