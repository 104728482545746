import React, { useContext } from "react";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsContextType, SettingsType, SortByType } from "../../contexts/SettingsContext";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { getProductDefaultSettings, ValidValueTypes, viewSettingsInitialization } from "../../utils/Initializers";
import { ProcessKpiChart } from "../process-kpi-chart/ProcessKpiChart";
import i18n from "../../i18n";
import { Project } from "../../models/Project";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { caseKpiControlsGetAllowedStatistics, getAllowedKpis } from "../../models/Kpi";
import BenchmarkingControls, { getValidBenchmarkingControlSettings } from "../../components/controls/BenchmarkingControls";
import ProductSideStatistics from "../process-kpi-chart/stats/ProductSideStatistics";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { KpiTypes, KpiPresets } from "../../models/KpiTypes";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import { LogStats } from "../process-kpi-chart/stats/LogStats";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function QualityKpisView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const q = getQualityCaseQuantities(settings, session.project);

    const isInitialized = session.project !== undefined;
    const errorMessage = isInitialized ? (() => {
        if (!q.isQuotaAvailable && settings.kpi.selectedKpi === KpiTypes.ScrapRatio)
            return "kpi.noQualityQuotaAvailable";

        if (!q.isScrapAvailable)
            return "kpi.noDataScrapRequired";
    })() : undefined;

    const allowedKpis = getAllowedKpis(session, settings, KpiPresets.productQualityKpis, true);

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.products"
        }]}
        pages={[{
            tabTitle: "common.processKpis",
            tabSlug: "process",
            spotlightId: "Quality-KPI-Process",
            controller: <BenchmarkingControls kpis={allowedKpis} />,
            activator: (preferences) => {
                let temp = validateQualityCaseParameters(session, settings, preferences,
                    getProductDefaultSettings(session),
                    {
                        sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.Median, SortByType.Percentiles],
                        quantities: q.quantities.map(a => a.baseQuantity),
                        kpis: KpiPresets.productQualityKpis,
                        aggregationTypes: [AggregationTypes.Product, AggregationTypes.Case, AggregationTypes.Time],
                    });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingControlSettings(session, temp, { kpis: allowedKpis }));

                // The allowed comparisons are depending on settings that might have been corrected above,
                // so we need to run this as a two-step process
                settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                    statistics: caseKpiControlsGetAllowedStatistics(session, settings, temp.kpi.selectedKpi, temp.kpi.aggregation),
                    kpis: getAllowedKpis(session, temp, KpiPresets.productQualityKpis, true)
                }));
            },
            dimensions: getDimensionParameters(session.projectId, "kpis/process"),
            content: <>
                <KpiErrorMessage message={errorMessage} />
                {!errorMessage && isInitialized && <ProcessKpiChart
                    noProductDataPlaceholder="kpi.noDataProductsAndScrapRequired"
                    noCaseDataPlaceholder="kpi.noDataScrapYieldRequired"
                    noTimeDataPlaceholder="kpi.noDataInTimeRange"
                    pageSlug="quality" />}
                <BackButtonTrayElement />
            </>,
            selectionTrigger: (settings) => {
                return settings.selection.product !== undefined ||
                    settings.selection.case !== undefined ||
                    settings.selection.timeperiod !== undefined;
            },
            stats: <ProductSideStatistics
                unmarkedAside={<LogStats />}
                hasError={!!errorMessage}
            />,
        }]}>
        <BackButtonTrayElement />
    </TabbedView>;
}

/**
 * This method encapsulates quality-specific validation logic.
 */
export function getQualityCaseQuantities(settings: SettingsType, project: Project | undefined) {
    const caseScrapQuantities = getAssignedQuantities(project?.eventKeys, [QuantityType.Scrap, QuantityType.CaseScrap], false);
    const caseYieldQuantities = getAssignedQuantities(project?.eventKeys, QuantityType.CaseYield, false);

    return {
        quantities: caseScrapQuantities,
        quotaQuantities: caseYieldQuantities,
        isScrapAvailable: caseScrapQuantities.some(s => s.baseQuantity === settings.quantity),
        isQuotaAvailable: caseScrapQuantities.some(s => s.baseQuantity === settings.quantity) &&
            caseYieldQuantities.some(s => s.baseQuantity === settings.quantity),
    };
}

/**
 * Quality validation helpers. This is needed in a couple of places, so it's public.
 * @param validValues If the callee needs to impose additional validation constraints, these can be provided here.
 */
export function validateQualityCaseParameters(session: SessionType, settings: SettingsContextType, preferences: DeepPartial<SettingsType> | undefined, defaultValues: DeepPartial<SettingsType> | undefined, validValues: Partial<ValidValueTypes>) {
    const q = getQualityCaseQuantities(settings, session.project);

    return viewSettingsInitialization(session, settings, preferences, defaultValues, {
        aggregationTypes: [AggregationTypes.Product, AggregationTypes.Case, AggregationTypes.Time],
        quantities: q.quantities.map(q => q.baseQuantity),
    },
    validValues);
}

/**
 * Convenience function that creates the "No KPIs available" screen
 */
export function KpiErrorMessage(props: { message?: string }) {
    if (!props.message)
        return null;

    return <div className="noKpisAvailable">
        {i18n.t("common.noKpisAvailable")}
        <div>
            {i18n.t(props.message)}
        </div>
    </div>;
}
