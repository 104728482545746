import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// This initializes the i18n system.
// Currently, all translations are linked into the bundle at compile time.
// Once the translations grow massively, we could still load the translations
// dynamically using https://github.com/i18next/i18next-http-backend

import de from "./i18n/de.json";
import en from "./i18n/en.json";
import jp from "./i18n/jp.json";

import spotlightsDe from "./i18n/spotlights-de.json";
import spotlightsEn from "./i18n/spotlights-en.json";
import { SessionType, isOniqEmployee } from "./contexts/SessionContext";

export const supportedLanguages: { [locale: string]: { translation: any } } = {
    en: build(en, spotlightsEn),
    de: build(de, spotlightsDe),
    jp: build(jp, spotlightsEn),
};

export const numberFormatLocales = [
    {
        locale: "en",
        example: "123,456,789.0",
    }, {
        locale: "de",
        example: "123.456.789,0",
    },
];

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: window.localStorage.getItem("locale") ?? "de",
        resources: supportedLanguages,
        supportedLngs: Object.getOwnPropertyNames(supportedLanguages),
        keySeparator: ".",
        nsSeparator: false,
        interpolation: {
            // react already safes from xss
            escapeValue: false
        }
    });

export default i18n;

function build(data: any, spotlights: any) {
    return {
        translation: {
            ...data.translation,
            spotlights,
        }
    };
}

/**
 * Checks one spotlight id after the other and returns the first one that exists, or undefined if there is none.
 * @param locale locale
 * @param ids spotlight IDs in order of preference
 * @returns First existing spotlight ID, or undefined if there is none
 */
export function getFirstExistingSpotlight(session: SessionType, ids: (string | undefined)[]) {
    for (const id of ids)
        if (id && hasSpotlight(session.locale, id))
            return id;

    if (isOniqEmployee(session))
        console.warn(`No text provided for any of ${ids} and locale ${session.locale}`);

    return undefined;
}

function hasSpotlight(locale: string, id: string) {
    const spotlights = ({
        "de": spotlightsDe,
        "en": spotlightsEn,
    }[locale]) as { [key: string]: string } | undefined;

    if (!spotlights)
        return false;

    return spotlights[id.toLowerCase()] !== undefined;
}