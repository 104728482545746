import React, { RefObject, useContext, useEffect, useImperativeHandle, useRef } from "react";
import Modal, { IModal, ModalProps } from "../../components/modal/Modal";
import i18n from "../../i18n";
import { FormRow } from "../../components/forms/FormRow";
import { isOniqDev, isWorkshopAccount, SessionContext } from "../../contexts/SessionContext";
import { NotificationService } from "../../components/notification/NotificationService";
import Global from "../../Global";
import { useForm } from "react-hook-form";
import { FormDeleteButton } from "../../components/forms/FormDeleteButton";
import { formEnterExecute, formEnterFocus } from "../../components/stats-section/Utility";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Api } from "../../api/Api";
import { ViewConfigurations, ViewConfigurationType } from "../../models/ApiTypes";


type FormData = {
    name: string;
    description: string;
    makeDashboard?: boolean;
}

export const EditFavoritesModal = React.forwardRef((props: Partial<ModalProps & ViewConfigurations>, ref: React.Ref<IModal>) => {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const { trackEvent } = useMatomo();
    const url = new URL(window.location.href).toString();
    const isOniqDevUser = isOniqDev(session);
    const isWorkshopAccountUser = isWorkshopAccount(session);

    const { register, handleSubmit, reset, setFocus, formState: { errors, isDirty, isValid } } = useForm<FormData>({
        defaultValues: {
            name: props.name ?? "",
            description: props.description ?? "",
            makeDashboard: props.viewType === ViewConfigurationType.Dashboard,
        }
    });

    useEffect(() => {
        reset({
            description: props.description ?? "",
            name: props.name ?? "",
        });
    }, [
        props.name,
        props.description,
        props.id,
    ]);

    const formRef = useRef<HTMLFormElement>(null);
    const modalRef = useRef<IModal>(null);

    useImperativeHandle(ref, () => ({
        show() {
            modalRef.current?.show();

            // Reset form
            reset();

            // focus element right after reset executed
            queueMicrotask(() => {
                setFocus("name", {
                    shouldSelect: true
                });
            });
        },
        hide() {
            modalRef.current?.hide();
        }
    }));

    return <Modal
        {...props}
        isClosable={true}
        width={600}
        title={props.id !== undefined ? "favorites.editView" : "favorites.saveView"}
        defaultButtonLabel="favorites.save"
        isOkDisabled={!isDirty || !isValid}
        onCancel={() => {
            // This is here so we have a cancel button
        }}
        onDefaultButtonClicked={async () => {
            await handleSubmit(save)();

            // Prevent closing of this modal, because we're doing that programmatically in the
            // success handler
            return true;
        }}
        showCancelButton={true}
        ref={modalRef}
    >
        <div className="next">
            <form ref={formRef} autoComplete="off">
                <FormRow title="favorites.name" description="favorites.nameDescription">
                    <input
                        type="text"
                        className="inputFillRow"
                        autoComplete="off"
                        {...formEnterFocus(setFocus, "description")}
                        {...register("name", { required: true, minLength: 1 })}
                        placeholder={i18n.t("common.label").toString()} />
                    {errors.name?.type !== undefined && <div className="formError">
                        {i18n.t("forms.textMissing")}
                    </div>}
                </FormRow>

                <FormRow title="favorites.description">
                    <textarea {...formEnterExecute(() => { handleSubmit(save)(); })} className="inputFillRow" {...register("description")} placeholder={i18n.t("favorites.description").toString()} />
                </FormRow>

                {session.project?.userId === session.user?.sub && getViewId(url) === "dashboard" && (isOniqDevUser || isWorkshopAccountUser) && <FormRow title="common.dashboard">
                    <label>
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="default-dashboard"
                            checked={undefined}
                            {...register("makeDashboard")}
                        />
                        <label htmlFor="default-dashboard" />
                        {session.project?.isSharedWithOrganization ? i18n.t("favorites.dashboardDefaultForOrganization") : i18n.t("favorites.dashboardDefault")}
                    </label>
                </FormRow>}

                {props.id !== undefined && <FormRow title="common.actions">
                    <FormDeleteButton label="favorites.delete" confirmationLabel="favorites.reallyDelete" onClick={async () => {
                        await deleteFavoriteAsync(props.id!);
                        if (props.onDone)
                            props.onDone();
                    }}/>
                </FormRow>}
            </form>
        </div>

    </Modal>;

    /**
     * Delete a favorite
     */
    async function deleteFavoriteAsync(id: string) {

        await Api.deleteViewConfigurations(id);

        NotificationService.add({
            className: "light success-accent",
            summary: i18n.t("favorites.deleted", { ...props }),
            message: "",
            autoCloseDelay: Global.shortNotificationDelay,
            icon: "radix-bookmark",
        });

        trackEvent({
            category: "Favorites",
            action: "Favorite deleted",
        });

        closeModal();
    }

    /**
     * Add or Update a favorite
     */
    async function save(data: FormData) {

        const favorite: ViewConfigurations = {
            userId: session.user?.hash ?? "",
            name: data.name,
            description: data.description,
            projectId: props.projectId ?? session.projectId!,
            viewId: getViewId(url),
            settings: settings,
            viewType: data.makeDashboard ? ViewConfigurationType.Dashboard : undefined,
            isSharedWithOrganization: (data.makeDashboard && session.project?.isSharedWithOrganization) ?? false,
        };

        const updatedFavorite = props as ViewConfigurations;

        if (props.id !== undefined) {
            await Api.updateViewConfigurations({
                ...updatedFavorite,
                ...data
            });
        } else {
            await Api.createViewConfigurations(favorite);
        }

        // Display notification
        NotificationService.add({
            className: "light success-accent",
            summary: i18n.t(props.id !== undefined ? "favorites.updated" : "favorites.added", { ...favorite }),
            message: "",
            autoCloseDelay: Global.shortNotificationDelay,
            icon: "bookmark",
        });

        trackEvent({
            category: "Favorites",
            action: "Favorite added",
        });

        closeModal();

        if (props.onDone)
            await props.onDone();
    }

    function closeModal() {
        // This is an unfortunately necessary hack to force a re-render.
        // Otherwise, the above notification would just not render,
        // although it should.
        session.set(session);

        // Close modal
        (ref! as RefObject<IModal>)!.current!.hide();
    }
});

export function getViewId(url: string) {
    const urlData = url.indexOf("projects/") + "projects/".length;
    const hashIdx = url.indexOf("#", urlData);
    const urlString = url.substring(urlData, hashIdx >= 0 ? hashIdx : undefined );
    const viewId = urlString.substring(
        urlString.indexOf("/") + 1,
        urlString.lastIndexOf(urlString.charAt(urlString.length))
    );
    return viewId;
}
