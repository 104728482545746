import { MultiEdge, Point } from "../../models/Dfg";
import { Casteljau } from "../../utils/Casteljau";
import { Vector } from "../../utils/Vector";

/**
 * A leg is a part of an edge and may refer to a spline or line segment. An edge is usually composed
 * of multiple legs
 */
export class Leg {
    /**
     * Number of steps with which the length of the spline is sampled
     */
    public static readonly accuracy = 15;

    private readonly accuracyStep = 1 / Leg.accuracy;

    /**
     * Points used for spline interpolation
     */
    controlPoints: Vector[];

    /**
     * Pre-processed edge line points
     */
    points: Vector[];

    /**
     * Array holding cumulative leg length at each accuracy step
     */
    accumulatedLength: number[];

    /**
     * Array holding leg lengths at each accracy step
     */
    lengths: number[];

    /**
     * Total length of this leg
     */
    length: number;

    /**
     * Initializes an edge leg
     * @param points 2 points => line segment, 3 points => Quadratic Bézier curve, (not implemented: 4 points => Cubic Bézier curve)
     */
    constructor(points: Point[]) {
        // Pre-calculate approximate length by summing up 10 line segments
        this.length = 0;

        this.controlPoints = points.map(p => new Vector(p.x, p.y));
        this.accumulatedLength = [];
        this.lengths = [];
        this.points = [];

        let prev = Casteljau.getSplinePoint(0, this.controlPoints);
        this.accumulatedLength.push(0);

        for (let j = 1; j <= Leg.accuracy; j++) {
            const t = j / Leg.accuracy;
            const current = Casteljau.getSplinePoint(t, this.controlPoints);
            this.points.push(current);
            const length = prev.subtract(current).length();
            this.lengths.push(length);
            this.length += length;
            this.accumulatedLength.push(this.length);

            prev = current;
        }
    }

    /**
     * Returns the position that you get when you traverse this edge's leg for the given
     * amount of pixels
     * @param px Number of pixels to traverse the edge along
     */
    getAbsolutePositionAt(px: number) {
        px = Math.max(0, Math.min(this.length, px));

        let idx = 1;
        while (idx < this.accumulatedLength.length && px > this.accumulatedLength[idx])
            idx++;

        idx--;

        const accuracyInterpolationStep = (px - (this.accumulatedLength[idx] || 0)) / this.lengths[idx];

        const t = idx * this.accuracyStep + accuracyInterpolationStep * this.accuracyStep;

        return Casteljau.getSplinePoint(t, this.controlPoints);
    }

    /**
     * 
     * @param container Container to append the leg to
     * @param id Edge ID
     * @param edge Edge that this leg belongs to
     * @param stroke Stroke
     * @param width Line width
     * @param dashed Dash pattern
     * @param truncate How many pixels to truncate at the end
     * @param clickHandler Click handler
     */
    append(container: SVGSVGElement, isSelected: boolean, id: string, edge: MultiEdge, stroke: string, width?: number, dashed?: number, truncate?: number, clickHandler?: (edge: MultiEdge) => void) {
        const [p1, p2, p3] = this.controlPoints;

        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        
        path.setAttribute("stroke", stroke);
        path.setAttribute("stroke-linecap", dashed ? "butt" : "round");
        path.setAttribute("stroke-width", width?.toString() ?? "1");
        const classes = [id];
        if (isSelected)
            classes.push("selected");

        path.setAttribute("class", classes.join(" "));
        
        const dashArray = this.buildDashArray(dashed, truncate);
        if (dashArray)
            path.setAttribute("stroke-dasharray", dashArray);
    
        if (this.controlPoints.length === 3) {
            path.setAttribute("d", `M ${p1.x} ${p1.y} Q ${p2.x} ${p2.y},${p3.x} ${p3.y}`);
            path.setAttribute("fill", "transparent");
        } else {
            path.setAttribute("d", `M ${isNaN(p1.x)? 0 : p1.x} ${isNaN(p1.y) ? 0 : p1.y} L ${isNaN(p2.x) ? 0 : p2.x} ${isNaN(p2.y) ? 0 : p2.y}`);
        }

        path.addEventListener("click", (e) => {
            if (clickHandler)
                clickHandler(edge);

            e.preventDefault();
            e.stopPropagation();
        });

        container.appendChild(path);
    }


    /**
     * Builds a dash pattern, considering how much is cut off at the end
     * @param dash Dash pattern
     * @param truncation Truncation at the end of this leg
     * @returns Dash pattern
     */
    private buildDashArray(dash: number | undefined, truncation: number | undefined): string | undefined {
        const ridiculouslyLargeNumber = 1000000000;
        if (truncation === undefined)
            return dash?.toString();

        // Truncation requested

        // No dash pattern, calculate visible length and skip remaining pixels
        const distanceToDraw = this.length - truncation!;

        if (!dash)
            return Math.max(0, distanceToDraw).toString() + " " + ridiculouslyLargeNumber;

        // Build dash pattern
        const dashArray: number[] = [];
        let covered = 0;
        let drawing = true;

        while ((covered + dash!) < distanceToDraw) {
            dashArray.push(dash!);
            covered += dash!;
            drawing = !drawing;
        }

        if (drawing)
            dashArray.push(Math.max(0, distanceToDraw - covered));

        // Skip rest of the path
        dashArray.push(ridiculouslyLargeNumber);

        return dashArray.join(" ");
    }

    /**
     * Returns a worst-case distance of the leg to the given point
     */
    getWorstCaseDistance(pt: Vector) {
        let minDist = Number.MAX_SAFE_INTEGER;
        for (const current of this.points) {
            const distSq = current.subtract(pt).lengthSq();

            if (distSq < minDist)
                minDist = distSq;
        }

        if (minDist === Number.MAX_SAFE_INTEGER)
            return Number.MAX_SAFE_INTEGER;

        return Math.sqrt(minDist) + Leg.accuracy;
    }

    getDistanceSq(p: Vector) {
        let distance = Number.MAX_SAFE_INTEGER;

        for (let i = 1; i < this.points.length; i++) {
            const previous = this.points[i - 1];
            const current = this.points[i];

            distance = Math.min(distance, distancePointLineSq(p, previous, current));
        }

        return distance;
    }
}

/**
 * Distance from p to the line that goes through p1, p2.
 * http://www.paulbourke.net/geometry/pointlineplane/
 * @returns distance
 */
function distancePointLineSq(p: Vector, p1: Vector, p2: Vector) {
    const p1p2 = p2.subtract(p1);

    // If line-defining points are almost equal
    // just return the distance to one of them.
    if (p1p2.lengthSq() < 0.0001)
        return p1.subtract(p).lengthSq();

    const u = ((p.x - p1.x) * (p2.x - p1.x) + (p.y - p1.y) * (p2.y - p1.y)) / p1p2.lengthSq();


    if (u < 0)
        return p1.subtract(p).lengthSq();

    if (u > 1)
        return p2.subtract(p).lengthSq();

    const i = p1.add(p1p2.multiply(u));

    return i.subtract(p).lengthSq();
}
