/**
 * Displays the configuration of a single activity filter element.
 * Activity filters check if traces contain a specific element, and if
 * so, the trace will be accepted or discarded. Depends on the config!
 */

import parse from "html-react-parser";
import React, { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";
import { getActivityLabelFromActivityItem } from "../../../utils/GroupingUtils";

type ActivityFilterStatusPropsType = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function ActivityFilterStatus(props: ActivityFilterStatusPropsType): JSX.Element | null {
    const session = useContext(SessionContext);
    if (!session.project?.eventKeys)
        return <div></div>;
    const e = props.filter?.filters?.length ? props.filter?.filters[0].activity : undefined;
    if (e?.ne === undefined &&
        e?.eq === undefined)
        return null;

    const title = e?.eq !== undefined ?
        i18n.t("filters.requireActivity", { activity: getActivityLabelFromActivityItem(e?.eq[0], e.groupingKey!) }) :
        i18n.t("filters.excludeActivity", { activity: getActivityLabelFromActivityItem(e?.ne![0], e.groupingKey!) });

    return <div className={"filter activityFilter" + (props.isEdited ? " edit" : "")} title={sanitize(title)} onClick={onClick}>
        <svg className="svg-icon small">
            <use xlinkHref="#radix-activity-log" />
        </svg>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
        <div>
            {parse(title)}
        </div>
    </div>;

    function onClick() {
        if (props.onClick)
            props.onClick();
    }
}

/**
 * Don't use this function for untrusted markup. It's just here for saving me to
 * duplicate a translation string that currently contains markup.
 */
function sanitize(input: string) {
    return input.replace(/<[^>]+>/gi, "");
}