import React, { useState } from "react";
import { DefaultFormatterType, Formatter, FormatterParams, UnitMetadata, UnitScale } from "../../utils/Formatter";
import { Vector } from "../../utils/Vector";
import { breakString } from "../dfg/Layouter";
import { Alignments } from "../spotlight/Spotlight";
import { uniq } from "lodash";
import { classNames, getTouchCenter } from "../../utils/Utils";
import colors from "../../colors.json";

/**
 * If the view is dragged less than this many pixels, the gesture may be considered a click.
 */
export const MIN_DRAG_DISTANCE = 10;

export enum ContainerModes {
    Constrained,
    Overflowing,
}

export type Tick = {
    value: number;
    label?: string;
}

export type Padding = {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export type GraphLine = {
    label?: string;
    value: number;
    color?: string;
    width?: number;
    dash?: number;
}

export type OffsetType = {
    offset: number;
    alignment: Alignments.Left | Alignments.Right;
}

export type BaseGraphProps = {
    width: number;
    height: number;

    containerMode?: ContainerModes;

    title?: string;

    padding?: Partial<Padding>;

    xAxisLabel?: string;

    horizonalLines?: GraphLine[];

    min?: number;

    max?: number;

    showYAxisTicks?: boolean;

    showYAxisLines?: boolean;

    /**
     * If provided, these tick marks will be used. If omitted, the graph will try it's best
     * to provide some. If you don't want any ticks on the y axis, set `showYAxisTicks` to false.
     */
    yAxisTicks?: Tick[];

    yAxisLabel?: string | JSX.Element;

    /**
     * Number of pixels of padding between y axis and it's label
     */
    yAxisLabelPadding?: number;

    /**
     * If true, the values will be shown on top of a bar, using the valueFormatter if provided.
     */
    showBarValues?: boolean;

    /**
     * If provided, an appropriate unit will be picked for the y axis, and the axis ticks
     * will be multiples of this.
     */
    yAxisUnit?: UnitMetadata;

    formatterParams?: Partial<FormatterParams>;

    /**
     * Formatter to use. If provided, this formatter overrides the yAxisUnit formatter. 
     * If omitted, the yAxisUnit formatter will be used. However, try to stick to 
     * providing an yAxisUnit. Your life will be easier!
     */
    valueFormatter?: DefaultFormatterType;

    selectionColor?: string;

    /**
     * This is what the offset will be initialized with. Basically useless,
     * unless you're unit testing this component
     */
    initialOffset?: OffsetType;
}

export type DragState = {
    current: Vector | undefined;
    started: Vector | undefined;

    /**
     * True, if the user dragged for MIN_DRAG_DISTANCE pixels
     */
    isDragGesture: boolean;

    lastDragGestureEnded: number | undefined;
}

/**
 * Provides 1D scroll handlers for our graph components
 * @param canDrag true if dragging should be enabled, false deactivates it (e.g. in case the entire graph is already visible on screen)
 * @param initialOffset initial scroll offset
 * @returns 
 */
export function useDragging(canDrag: boolean, clipMax: number, initialOffset?: OffsetType, onDragging?: (offset: number) => void) {
    const getInitialDragPosition = () => {
        if (!initialOffset || initialOffset.alignment === Alignments.Left)
            return initialOffset?.offset ?? 0;

        return clipMax - initialOffset.offset;
    };

    const [offset, setOffset] = useState<number>(getInitialDragPosition);

    const [dragState, setDragState] = useState<DragState>({
        current: undefined,
        started: undefined,
        isDragGesture: false,
        lastDragGestureEnded: undefined
    });

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement | SVGElement>) => {
        const center = getTouchCenter(e.touches);
        if (!canDrag || !center)
            return;

        setDragState({
            lastDragGestureEnded: undefined,
            current: new Vector(center.clientX, center.clientY),
            started: new Vector(center.clientX, center.clientY),
            isDragGesture: false,
        });
    };

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
        if (!canDrag)
            return;

        setDragState({
            lastDragGestureEnded: undefined,
            current: new Vector(e.clientX, e.clientY),
            started: new Vector(e.clientX, e.clientY),
            isDragGesture: false,
        });
    };

    const onTouchEnd = (e: React.TouchEvent<HTMLDivElement | SVGElement>) => {
        if (e.touches.length > 0)
            return;

        setDragState({
            lastDragGestureEnded: dragState.isDragGesture ? new Date().getTime() : undefined,
            current: undefined,
            started: undefined,
            isDragGesture: false,
        });
    };

    const onMouseUp = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>) => {
        setDragState({
            lastDragGestureEnded: dragState.isDragGesture ? new Date().getTime() : undefined,
            current: undefined,
            started: undefined,
            isDragGesture: false,
        });

        e.preventDefault();
        e.stopPropagation();
    };

    const moveHandler = (e: {
        clientX: number,
        clientY: number,
    }) => {
        if (!dragState.current)
            return;

        const dx = dragState.current.x - e.clientX;

        let isDragGesture = dragState.isDragGesture;
        if (!isDragGesture) {
            const distance = new Vector(e.clientX, e.clientY).subtract(dragState.started!).length();
            if (distance > MIN_DRAG_DISTANCE)
                isDragGesture = true;
        }

        if (isDragGesture) {
            const o = Math.max(0, Math.min(clipMax, offset + dx));
            setOffset(o);

            if (onDragging)
                onDragging(o);

            setDragState({
                ...dragState,
                isDragGesture,
                current: new Vector(e.clientX, e.clientY),
            });
        }
    };

    const onWheel = (e: React.WheelEvent<HTMLDivElement | SVGSVGElement>) => {
        const delta = e.deltaX + e.deltaY;
        const o = Math.max(0, Math.min(clipMax, offset + delta));
        setOffset(o);

        if (onDragging)
            onDragging(o);
    };

    const onTouchMove = (e: React.TouchEvent<HTMLDivElement | SVGSVGElement>) => {
        const centers = getTouchCenter(e.touches);
        if (centers)
            moveHandler(centers);
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>) => {
        moveHandler(e);
    };

    return {
        offset,
        dragState,
        setOffset,
        mouseHandlers: {
            onMouseDown,
            onMouseUp,
            onMouseMove,
            onTouchStart,
            onTouchEnd,
            onTouchMove,
            onWheel,
        },
        resetDragPosition: () => {
            setOffset(getInitialDragPosition());
        },
    };
}

export function generateGraphTicksByScale(paneHeight: number, min: number, max: number, restrictToMinMax: boolean, unitScale: UnitScale | undefined, formatterParams: FormatterParams, valueFormatter: DefaultFormatterType): Tick[] {
    const delta = Math.abs(max - min);

    if (delta < 0.0000001 || paneHeight === 0)
        return [{
            value: min,
            label: valueFormatter(min, { ...formatterParams }),
        } as Tick];

    // Maximum number of ticks is one every 30 pixels
    const maxTicks = Math.max(1, paneHeight) / 30;

    // eslint-disable-next-line no-constant-condition
    const getScaleAndStep = () => {
        const steps = unitScale?.steps ?? [5, 4, 2, 1];
        let scale = 0.0000001 * (unitScale?.size ?? 1);
        while (isFinite(scale)) {
            for (const step of steps) {
                const numTicks = (delta / scale) * step;
                if (numTicks < maxTicks)
                    return {
                        steps: step, scale
                    };
            }
            scale *= 10;
        }

        return { steps: 1, scale: Number.MAX_SAFE_INTEGER };
    };

    const { steps, scale } = getScaleAndStep();

    const stepSize = scale / steps;
    const from = Math.round(min / stepSize) * stepSize;
    const to = Math.round(max / stepSize) * stepSize;

    // go from "from" to "to", with a step size of (scale / steps), and
    // reject everything thats smaller than "min" or larger than "max"
    const numSteps = Math.ceil(Math.abs(to - from) / stepSize);

    // Try assigning labels. If we see a label twice, increase the
    // number of digits allowed
    const p = { ...formatterParams };

    // eslint-disable-next-line no-constant-condition
    for (let attempt = 0; true; attempt++) {
        const result = [];
        for (let i = 0; i <= numSteps; i++) {
            const current = from + i * stepSize;
            if (restrictToMinMax)
                if (current < min || current > max)
                    continue;

            result.push({
                value: current,
                label: valueFormatter(current, p),
            });
        }

        // Break when we found a solution with unique labels
        if (uniq(result.map(r => r.label)).length === result.length ||
            attempt >= 5)
            return result;

        // Otherwise, increase number of digits allowed
        p.numDigits = (p.numDigits ?? 0) + 1;
    }
}

/**
 * Generates y-Axis ticks
 * @param min minimum value
 * @param max maximum value
 * @param restrictToMinMax if true, the ticks will be strictly within [min..max]: Say we have
 * min=0.7, max=2.9, and the scale is 1. Then, the ticks will be [1, 2], not [0, 1, 2, 3]
 * @param valueFormatter Formatter to use for the tick labels
 * @param unit If provided, ticks will preferrably be placed at multiples of this unit
 * @returns Tick marks
 */
export function generateGraphTicks(paneHeight: number, min: number, max: number, restrictToMinMax: boolean, unitMetadata: UnitMetadata | undefined, formatterParams: FormatterParams, valueFormatter: DefaultFormatterType): Tick[] {
    const meta = unitMetadata ?? Formatter.defaultUnit;
    const units = meta.getUnits({});
    const unit = Formatter.getUnit(units, max)!;

    return generateGraphTicksByScale(paneHeight, min, max, restrictToMinMax, unit, formatterParams, valueFormatter);
}

/**
 * Generates column labels by breaking the label into multiple parts and arranging as many as there is space available
 * @param label Label text
 * @param tooltip Tooltip text
 * @param xCenter Center label position on screen
 * @param availableSpace Available width for labels
 * @param width chart width
 * @param height chart height
 * @param padding padding
 * @param onClick click handler
 * @param mouseHandlers mouse handlers
 */
export function generateColumnLabels(label: string, tooltip: string | undefined, xCenter: number, availableSpace: number, width: number, height: number, padding: Padding, onClick: () => void, mouseHandlers: any, isGroupSelected?: boolean) {
    const barLabels: JSX.Element[] = [];
    const approxLineHeight = 15;
    const labelWidth = Math.max(50, Math.ceil(Math.sqrt(padding.bottom * padding.bottom * 2) - approxLineHeight));

    const weHaveSpaceForThisManyLabelRows = availableSpace / approxLineHeight;

    // Turns out, measuring text dimensions is not very precise when used with relative sizes (such as rem).
    const breaks = breakString(label, labelWidth, undefined, "15px OpenSans-Regular");
    const labelRows = truncateBreaks(breaks, weHaveSpaceForThisManyLabelRows);
    let x = xCenter - (labelRows.length * approxLineHeight) / 2;

    for (let i = 0; i < labelRows.length; i++) {
        const part = labelRows[i];
        const isLast = i === (labelRows.length - 1);
        if (x > (padding.left - approxLineHeight / 2) && x < (width - padding.right - approxLineHeight / 2)) {
            barLabels.push(<div
                className={classNames(["label", isLast && "truncate", isGroupSelected && "selected"])}
                title={tooltip ?? label}
                key={`label-${xCenter}-${i}-${label}`}
                {...mouseHandlers}
                onClick={(e) => {
                    onClick();
                    e.preventDefault();
                    e.stopPropagation();
                }}
                style={{
                    top: height - padding.bottom,
                    right: Math.round(width - padding.right - x),
                    width: labelWidth,
                }}>
                {part}
            </div>);
        }

        x += approxLineHeight;
    }

    return barLabels;
}

function truncateBreaks(breaks: string[], count: number) {
    if (breaks.length <= count)
        return breaks;

    // We have more breaks than we can display, so let's truncate
    // the last one. We can just add "...", the text-overflow: ellipsis
    // will truncate earlier if these don't fit
    const result = breaks.filter((_, idx) => idx < (count - 1));
    const last = breaks[result.length];
    result.push(last + "...");

    return result;
}

export function getHorizonalLines(lines: GraphLine[] | undefined, graphWidth: number, padding: Padding, valueToY: (value: number) => number): [JSX.Element[], JSX.Element[]] {
    const svgElements: JSX.Element[] = [];
    const labelElements: JSX.Element[] = [];

    for (const horizonalLine of lines ?? []) {
        const lineWidth = horizonalLine.width ?? 10;
        const y = Math.round(valueToY(horizonalLine.value)) + 0.5;
        svgElements.push(<line key={`horizontal-line-${horizonalLine.value}${horizonalLine.label}`} y1={y} y2={y} x1={padding.left} x2={graphWidth - padding.right} stroke={horizonalLine.color ?? "red"} strokeWidth={lineWidth} strokeDasharray={horizonalLine.dash} />);


        if (horizonalLine.label) {
            const yLabel = y - lineWidth / 2 - 18;
            labelElements.push(<div key={`horizontal-line-label-${horizonalLine.value}${horizonalLine.label}`} className="lineLabel" style={{ top: yLabel, left: padding.left }}>{horizonalLine.label}</div>);
        }
    }

    return [svgElements, labelElements];
}


export const commonSelectionLineProps = {
    color: colors.$graphSelectionLineColor,
    width: 1, 
    dash: 8,
};

export class LabelPlacement {
    private taken: boolean[];
    constructor(private width: number) {
        this.taken = new Array(Math.max(1, width ?? 1)).fill(false);
    }

    public isFree(x: number, width: number) {
        for (let i = Math.max(0, x); i < Math.min(this.taken.length, x + width); i++)
            if (this.taken[i])
                return false;

        return true;
    }

    public take(x: number, width: number) {
        for (let i = Math.max(0, x); i < Math.min(this.taken.length, x + width); i++)
            this.taken[i] = true;
    }
}