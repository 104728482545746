import React from "react";
import colors from "../../colors.json";

export type Marker = {
    position: number;
    label: string;
    offset?: number;
    color?: string;
};

export type SvgGaugeProps = {
    className?: string;

    /**
     * Color of the value label
     */
    valueFontColor?: string;

    /**
     * Value formatter callback. Leave blank for default formatting!
     */
    valueFormatter?: (value: number, gauge: Gauge) => string;

    /**
     * Optional label to be displayed at the minimum position. Recommended to add some
     * dead space when doing so.
     */
    minLabel?: string;

    /**
     * Optional label to be displayed at the maximum position. Recommended to add some
     * dead space when doing so.
     */
    maxLabel?: string;

    /**
     * Markers to display on the gauge
     */
    markers?: Marker[];

    /**
     * Color of the markers
     */
    markerColor?: string;

    /**
     * Width of the markers
     */
    markerWidth?: number;


    /**
     * The current value
     */
    value?: number;

    /**
     * Minimum value this gauge can display
     */
    min: number;

    /**
     * Maximum value this gauge can display
     */
    max: number;

    /**
     * Padding. Add some if you want markers
     */
    padding?: number;

    /**
     * If 0, the entire circle will be used as gauge. If 180, only half of it, etc...
     */
    deadzoneWidth?: number;

    /**
     * Thickness of the gauge, in pixels
     */
    valueThickness?: number;

    /**
     * Thickness of the gauge for the part that's not filled, in pixels
     */
    idleThickness?: number;

    /**
     * Color of the gauge for the unused part
     */
    idleColor?: string;

    /**
     * Color of the gauge
     */
    valueColor?: string;

    /**
     * Gauges with a radius smaller than this will not be drawn
     */
    minGaugeRadius?: number;
};

type GaugeStateType = {
    /**
     * Width of the component after layout. Only valid if isInitialized===true
     */
    width?: number;

    /**
     * Height of the component after layout. Only valid if isInitialized===true
     */
    height?: number;
};

export class Gauge extends React.Component<SvgGaugeProps, GaugeStateType> {
    svgRef: React.RefObject<SVGSVGElement>;

    private thickness!: number;
    private idleThickness!: number;
    private deadZone!: number;
    private padding!: number;
    private radius!: number;
    private yOffset!: number;

    private boundResizeHandler: () => void;

    constructor(props: SvgGaugeProps) {
        super(props);
        this.state = {};

        this.svgRef = React.createRef<SVGSVGElement>();
        this.boundResizeHandler = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    handleResize() {
        if (!this.svgRef?.current)
            return;

        this.setState({
            width: this.svgRef.current.clientWidth,
            height: this.svgRef.current.clientHeight,
        });
    }

    render() {
        const markup = this.renderSvg();

        return <div className={this.props.className}>
            <svg ref={this.svgRef} style={{ overflow: "visible", height: "100%", width: "100%" }}>
                {markup}
            </svg>
        </div>;
    }

    private renderSvg() {
        if (!this.state.width || !this.state.height)
            return;

        const width = this.state.width;
        const height = this.state.height;

        this.thickness = this.props.valueThickness || 6;
        this.idleThickness = this.props.idleThickness || this.props.valueThickness || 5;
        this.deadZone = this.props.deadzoneWidth ?? 90;
        this.padding = this.props.padding === undefined ? 15 : this.props.padding;
        this.radius = (Math.min(width, height) / 2) - Math.max(this.thickness, this.idleThickness) - this.padding;

        const rT = this.radius - (this.thickness + this.idleThickness) / 4; // r minus half of average thickness
        const yC = this.valueToCoords(width, height, 0, 0, 0).y - Math.min(width, height) / 2;
        this.yOffset = (rT - yC) / 2;

        const startDeg = this.deadZone / 2;
        const endDeg = 360 - startDeg;

        // Draw idle gauge
        const resultElements: JSX.Element[] = [];

        if (!this.props.minGaugeRadius || this.radius >= this.props.minGaugeRadius) {
            resultElements.push(<polyline
                key={"idle-" + endDeg}
                points={this.getCirclePoints(width, height, startDeg, endDeg, this.radius)}
                stroke={this.props.idleColor || colors["$gray-1"]}
                fill="none"
                strokeWidth={this.idleThickness || 5} />);

            // Overdraw with value gauge
            const delta = this.props.max - this.props.min;
            if (this.props.value && delta) {
                const ratio = Math.max(0, Math.min(1, (this.props.value - this.props.min) / delta));
                const endDegValue = startDeg + (360 - this.deadZone) * ratio;

                resultElements.push(<polyline
                    key={"value" + endDegValue}
                    points={this.getCirclePoints(width, height, startDeg, endDegValue, this.radius)}
                    stroke={this.props.valueColor || colors.$blue}
                    fill="none"
                    strokeWidth={this.thickness} />);
            }

            // Draw markers
            for (const element of this.props.markers || []) {
                const p1 = this.valueToCoords(width, height, element.position, -this.thickness, this.yOffset);
                const p2 = this.valueToCoords(width, height, element.position, this.thickness, this.yOffset);
                const p3 = this.valueToCoords(width, height, element.position, this.thickness + (element.offset || 9), this.yOffset);

                resultElements.push(<line key={"marker-" + element.position} x1={p1.x} y1={p1.y} x2={p2.x} y2={p2.y} stroke={element.color || this.props.markerColor || colors["$gray-4"]} strokeWidth={this.props.markerWidth || 4} />);
                resultElements.push(<text
                    x={p3.x}
                    y={p3.y}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    fontFamily="sans-serif"
                    fontSize="12px"
                    key={element.label}
                    fill={element.color || this.props.markerColor || colors["$gray-4"]} >
                    {this.svgText(p3.x, element.label)}
                </text>);
            }

            // Add min/max labels
            if (this.props.minLabel) {
                const p = this.valueToCoords(width, height, this.props.min, this.thickness + 18, this.yOffset);

                resultElements.push(<text
                    x={p.x}
                    y={p.y}
                    key={`minLabel ${this.props.minLabel}`}
                    fontFamily="Nunito, sans-serif"
                    fontSize="12px"
                    fill={this.props.markerColor || colors["$gray-4"]}
                    textAnchor="start"
                    alignmentBaseline="central" >
                    {this.svgText(p.x, this.props.minLabel)}
                </text>);
            }

            if (this.props.maxLabel) {
                const p = this.valueToCoords(width, height, this.props.max, this.thickness + 18, this.yOffset);
                resultElements.push(<text
                    x={p.x}
                    y={p.y}
                    key={`maxLabel ${this.props.minLabel}`}
                    fontFamily="Nunito, sans-serif"
                    fontSize="12px"
                    fill={this.props.markerColor || colors["$gray-4"]}
                    textAnchor="end"
                    alignmentBaseline="central" >
                    {this.svgText(p.x, this.props.maxLabel)}
                </text>);
            }
        }

        // Finally, draw the value!
        if (this.props.value !== undefined) {
            const text = this.props.valueFormatter ? this.props.valueFormatter(this.props.value, this) : this.props.value.toString();

            resultElements.push(<text
                x={width / 2}
                y={height / 2 + this.yOffset}
                key={`value ${text}`}
                fontFamily="Nunito, sans-serif"
                fontWeight="bold"
                fill={this.props.valueFontColor || this.props.valueColor || colors.$blue}
                fontSize="22px"
                textAnchor="middle"
                alignmentBaseline="central" >
                {text}
            </text>);
        }

        return resultElements;
    }

    private getCirclePoints(width: number, height: number, startDeg: number, endDeg: number, radius: number) {
        const points: string[] = [];
        for (let i = startDeg; i < endDeg; i++) {
            const rad = i * Math.PI / 180.0;
            const x = -Math.sin(rad) * radius + width / 2;
            const y = Math.cos(rad) * radius + height / 2 + this.yOffset;

            points.push(`${x},${y}`);
        }

        return points.join(" ");
    }

    private valueToCoords(width: number, height: number, value: number, radiusOffset = 0, yOffset: number) {
        const startDeg = this.deadZone / 2;

        const delta = this.props.max - this.props.min;
        const ratio = delta ? Math.max(0, Math.min(1, (value - this.props.min) / delta)) : 0;
        const deg = startDeg + (360 - this.deadZone) * ratio;

        const rad = deg * Math.PI / 180.0;
        const x = -Math.sin(rad) * (this.radius + radiusOffset) + width / 2;
        const y = Math.cos(rad) * (this.radius + radiusOffset) + height / 2 + yOffset;

        return {
            x: x,
            y: y,
        };
    }

    /**
     * Wraps \n-separated lines into tspans
     * @param text Text that we want to process
     */
    private svgText(x: number, text: string, shift = 14): JSX.Element[] {
        const result: JSX.Element[] = [];
        const lines = text.split("\n");
        let y = 0;

        for (const line of lines) {
            result.push(<tspan key={`${x}-${y}-${line}`} x={x} dy={y.toString() + "px"}>{line}</tspan>);
            y += shift;
        }

        return result;
    }
}