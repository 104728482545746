import React from "react";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";

type VariantFilterStatusPropsType = {
    filter: EventFilter,
    onRemove?: (filter: EventFilter) => void,
    onClick?: () => void,
    isEdited?: boolean
};

export default function VariantFilterStatus(props: VariantFilterStatusPropsType) {
    const filter = props.filter.variant;
    const numVariants = (filter?.eq ?? filter?.ne ?? []).length;

    const prefix = filter?.eq ? "Eq" : "Ne";

    return <div className={"filter variantFilter" + (props.isEdited ? " edit" : "")} onClick={() => { if (props.onClick) props.onClick(); }}>
        <svg className="svg-icon small"><use xlinkHref="#radix-tokens" /></svg>
        <div>
            {i18n.t("filters.variants.status" + prefix, {count: numVariants})}<br/>
            {i18n.t("filters.variants.grouping." + (filter?.activityKeysGroup ?? "none"))}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;
}