import React, { DOMAttributes } from "react";
import i18n from "../../i18n";

export type I18nLinks = {
    /**
     * translation key for the template to use. Set this one or provide the template
     * directly in the `template` property.
     */
    id?: string;
    
    /**
     * Text template with placeholders for the values of the mapping. Use this or set
     * an i18n key in the id property.
     */
    template?: string;
    key?: string;
    mapping: {[id: string]: DOMAttributes<HTMLAnchorElement>};
}

export function I18nLinks(props: I18nLinks) {
    const text = props.template ?? i18n.t(props.id ?? "").toString();

    const rx = new RegExp("<a id='(\\w+)'>([^<]+)</a>", "gi");

    const parts: (JSX.Element | string)[] = [];

    const matches = text.matchAll(rx);
    
    let lastEnd = 0;
    for (const match of matches) {
        const id = match[1];
        const child = match[2];

        const idx = match.index ?? 0;
        const length = match[0].length;

        const before = text.substring(lastEnd, idx);
        
        if (before)
            parts.push(before);

        const domAttributes = props.mapping[id] ?? {};

        parts.push(<a key={(props.key ?? "") + id} {...domAttributes} >
            {child}
        </a>);

        lastEnd = idx + length;
    }

    parts.push(text.substring(lastEnd));

    return <div key={props.key}>
        {parts}
    </div>;
}