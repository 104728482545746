import { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useSetupMatrixActualAndPlanned } from "./SetupMatrix";
import { max, min, sum } from "lodash";
import { StatisticTypes } from "../../models/KpiTypes";
import { getMatrixStats } from "../../components/matrix/stats/MatrixSetupStats";

export function UnmarkedSetupStatistics() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const [data, isDataLoading, comparisonData, isComparisonDataLoading] = useSetupMatrixActualAndPlanned(session, settings);

    const count = sum(data?.transitions.map(t => t.frequencyStatistics.sum));
    const comparisonCount = sum(comparisonData?.transitions.map(t => t.frequencyStatistics.sum));

    return getMatrixStats({
        title: "common.allSetups",
        isLoading: isDataLoading || isComparisonDataLoading,
        count,
        max: max(data?.transitions.map(t => t.timeStatistics.max)),
        min: min(data?.transitions.map(t => t.timeStatistics.min)),
        mean: sum(data?.transitions.map(t =>
            t.timeStatistics.mean === undefined || t.frequencyStatistics.sum === undefined ?
                undefined :
                t.timeStatistics.mean * t.frequencyStatistics.sum)) / count,
        median: undefined,
        comparisonMean: sum(comparisonData?.transitions.map(t =>
            t.timeStatistics.mean === undefined || t.frequencyStatistics.sum === undefined ?
                undefined :
                t.timeStatistics.mean * t.frequencyStatistics.sum)) / comparisonCount,
    }, StatisticTypes.Mean);
}