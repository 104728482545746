import React, { useContext, useMemo, useRef } from "react";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useSetupEvents } from "../../hooks/UseSetupEvents";
import i18n from "../../i18n";
import { SetupEventsNode, SetupMatrixElementSchema, SetupTransitionSelectionSchema } from "../../models/ApiTypes";
import { GroupingKeys } from "../../models/Dfg";
import { EventKeys } from "../../models/EventKeys";
import { Formatter } from "../../utils/Formatter";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import Spinner from "../../components/spinner/Spinner";
import { Bar, Graph } from "../../components/graph/Graph";
import { Legend, LegendItem } from "../../components/graph/Legend";
import { GroupGraph } from "../../components/graph/GroupGraph";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { Alignments } from "../../components/spotlight/Spotlight";
import { useSetupMatrix } from "../../hooks/UseSetupMatrix";
import { MachineSelector } from "../../components/controls/MachineSelector";
import { TrayElement } from "../../components/tray/TrayElement";
import { SelectionPage } from "../../components/selection-page/SelectionPage";


export function SetupGraphView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const container = useRef<HTMLDivElement>(null);
    const size = container.current?.getClientRects()[0];

    const machines = settings.kpiMatrix.machineName ? [settings.kpiMatrix.machineName] : [];

    const [setupEventsActual, isActualLoading] = useSetupEvents({
        eventKeys: {
            ...session.project?.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
        eventFilters: settings.previewFilters ?? settings.filters,
        uploadId: session.project?.uploadId,
        machines,
    }, {
        disable: !session.project?.eventKeys ||
            !session.project?.uploadId ||
            !settings.kpiMatrix.machineName,
    });

    const hasPlanningData = session.project?.eventKeysPlan !== undefined && session.project?.uploadIdPlan !== undefined;
    const [setupMatrixPlanned, isPlanLoading] = useSetupMatrix({
        machines,
        // We do not send the filters here because it might lead to strange behavior.
        // The planning data does not have all labels and it may happend that filters that work
        // for the actual data do not work for the planning data.
        eventFilters: [],
        uploadId: session.project?.uploadIdPlan ?? "",
        eventKeys: {
            ...session.project?.eventKeysPlan ?? "",

            // TODO: Fix this hack ¯\_(ツ)_/¯
            product: session.project?.eventKeys?.product,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: !session.project?.eventKeysPlan ||
            !settings.kpiMatrix.machineName ||
            !hasPlanningData ||
            settings.kpi.comparisons === KpiComparisons.None,
    });

    const isLoading = isActualLoading || (hasPlanningData && isPlanLoading);

    const { barData, planned } = useMemo(() => {
        if (isLoading || !setupEventsActual)
            return { barData: [] as Bar<SetupTransitionSelectionSchema>[], planned: [] as Bar<SetupTransitionSelectionSchema>[][] };

        const barData: Bar<SetupTransitionSelectionSchema>[] = [];
        const planData: Bar<SetupTransitionSelectionSchema>[][] = [];

        const nodesMap = new Map<string, SetupEventsNode>(setupEventsActual.nodes.map(node => [node.id, node]));
        const matrixTransitionMap = new Map<string, SetupMatrixElementSchema>(setupMatrixPlanned?.transitions.map(t => [`${t.from}-${t.to}`, t]));

        for (let i = 0; i < setupEventsActual.transitionEvents.length; i++) {
            const actual = setupEventsActual.transitionEvents[i];
            const planned = matrixTransitionMap.get(`${actual.from}-${actual.to}`)?.timeStatistics.mean;

            const fromNode = nodesMap.get(actual.from);
            const toNode = nodesMap.get(actual.to);

            const data = {
                actual,
                planned,
                fromProduct: setupEventsActual.nodes.find(n => n.id === actual.from)?.activityValues?.product?.value,
                toProduct: setupEventsActual.nodes.find(n => n.id === actual.to)?.activityValues?.product?.value,
            };
            const actualBar: Bar<any> = {
                data,
                value: actual.duration,
                label: `${fromNode?.activityValues.product.value} → ${toNode?.activityValues.product.value}`,
                barColor: (!settings.kpiMatrix.highlightDeviations || settings.kpi.comparisons !== KpiComparisons.Planning) ? undefined : planned === undefined ?
                    colors.$unplanned : (planned !== undefined && actual.duration > planned) ?
                        colors.$setupGraphActualSlower :
                        colors.$setupGraphActualFaster,
            };

            barData.push(actualBar);

            if (settings.kpi.comparisons === KpiComparisons.Planning && planned !== undefined)
                planData.push([actualBar, {
                    data,
                    value: planned ?? 0,
                }]);
            else
                planData.push([actualBar]);
        }

        return { barData, planned: hasPlanningData ? planData : undefined };
    }, [
        isLoading,
        settings.kpi.comparisons,
        settings.kpiMatrix.highlightDeviations,
        setupEventsActual,
        setupMatrixPlanned,
        session.locale,
        container.current,
        size?.width,
        size?.height,
    ]);

    const isReady = !isLoading && (!settings.kpiMatrix.machineName || setupEventsActual !== undefined);

    const showNoMachineBanner = isReady && !settings.kpiMatrix.machineName;
    const showNoDataBanner = isReady && !!settings.kpiMatrix.machineName && (!setupEventsActual?.transitionEvents.length);

    const graphOptions = {
        title: i18n.t("setupEvents.title", { machine: settings.kpiMatrix.machineName }).toString(),
        width: size?.width as number,
        height: size?.height as number,
        yAxisTicks: [],
        padding: {
            top: 85,
            bottom: 100,
            left: 60,
        },
        selectedIndex: barData.findIndex(d => {
            return d.data?.fromProduct === settings.selection.setupTransition?.fromProduct &&
                d.data?.toProduct === settings.selection.setupTransition?.toProduct &&
                d.data?.actual.startTime === settings.selection.setupTransition?.actual.startTime &&
                d.data?.actual.endTime === settings.selection.setupTransition?.actual.endTime;
        }),
    };


    const legendItems: LegendItem[] = [];
    const showPlanningData = settings.kpi.comparisons === KpiComparisons.Planning && hasPlanningData;
    const showHighlights = settings.kpiMatrix.highlightDeviations && settings.kpi.comparisons === KpiComparisons.Planning && session.project?.eventKeysPlan !== undefined;

    if (showPlanningData) {
        if (showHighlights) {
            legendItems.push({ color: colors.$setupGraphActualFaster, label: "workflows.planningDeviation.actualFasterThanPlanned" });
            legendItems.push({ color: colors.$setupGraphActualSlower, label: "workflows.planningDeviation.actualSlowerThanPlanned" });
            legendItems.push({ color: colors.$unplanned, label: "workflows.planningDeviation.actualUnplanned" });
            legendItems.push({ color: colors.$plan, label: "common.plan", isLine: !settings.kpiMatrix.useDeviationBars });
        } else {
            legendItems.push({ color: colors.$actual, label: "common.actual" });
            legendItems.push({ color: colors.$plan, label: "common.plan", isLine: !settings.kpiMatrix.useDeviationBars });
        }
    }

    const maxY = Math.max(...barData.map(d => d.value), ...(planned?.map(d => d[1]?.value ?? 0) ?? []));

    if (!isLoading && showNoMachineBanner)
        return <SelectionPage
            title={i18n.t("setupMatrix.title").toString()}
            description={i18n.t("setupMatrix.noMachineDescription").toString()}
            selectorComponent={
                <MachineSelector className="dropdownLight mbl sizeConstrainedSelect" />
            } />;

    return <>
        <Spinner isLoading={isLoading} />
        <div className="setupGraph">
            <div className="setupGraphContainer" ref={container}>
                {showNoDataBanner &&
                    <NoDataAvailable visible={true} title="setupEvents.errorTitle" message="setupMatrix.noData" />}
                <div>
                    {isReady && !!size?.width && !!size?.height && <>
                        {(barData?.length ?? 0) > 0 && <>
                            {!settings.kpiMatrix.useDeviationBars && <Graph
                                {...graphOptions}
                                max={maxY}
                                showYAxisLines={true}
                                yAxisLabel={i18n.t("common.setupEvents").toString()}
                                initialOffset={{
                                    alignment: Alignments.Right,
                                    offset: 0,
                                }}
                                minBarPadding={50}
                                showBarValues={settings.kpi.comparisons === KpiComparisons.None}
                                data={barData!}
                                onSelected={element => {
                                    settings.setSelection({
                                        setupTransition: element?.data,
                                    });
                                }}
                                valueFormatter={value => Formatter.formatDurationShort(value, 2, session.numberFormatLocale)}
                                overlayRenderer={(visibleIndices, idxToX, valueToY) => {
                                    if (settings.kpi.comparisons === KpiComparisons.None)
                                        return;

                                    const bars: JSX.Element[] = [];
                                    const lines: JSX.Element[] = [];
                                    const labels: JSX.Element[] = [];

                                    const rangeFrom = Math.min(...visibleIndices) - 1;
                                    const rangeTo = Math.max(...visibleIndices) + 2;

                                    const drawCircle = (key: string, idx: number, value: number) => {
                                        const fromX = idxToX(idx).center;
                                        const y = valueToY(value);
                                        bars.push(<circle key={key} cx={fromX} cy={y} r={3} fill={colors.$plan} clipPath="url(#constrain-pane-labels)" />);
                                    };

                                    const coveredLineIdx: Set<number> = new Set();
                                    if (planned !== undefined) {
                                        for (let idx = rangeFrom; idx < rangeTo; idx++) {
                                            const fromIdx = Math.max(0, idx - 1);
                                            const toIdx = Math.max(0, Math.min(planned!.length - 1, idx));
                                            if (fromIdx === toIdx)
                                                continue;

                                            const key = `overlay-${fromIdx}-${toIdx}`;
                                            const toX = idxToX(toIdx).center;
                                            const toValue = planned![toIdx]?.[1]?.value;
                                            const fromValue = planned![fromIdx]?.[1]?.value;
                                            const fromX = idxToX(fromIdx).center;
                                            const fromY = valueToY(fromValue);
                                            const toY = valueToY(toValue);

                                            if (toValue !== undefined && fromValue !== undefined) {
                                                // Render line for planned values
                                                bars.push(<line key={key} x1={fromX} x2={toX} y1={fromY} y2={toY} stroke={colors.$plan} strokeWidth={2} strokeLinecap="round" clipPath="url(#constrain-pane)" />);
                                                coveredLineIdx.add(fromIdx);
                                                coveredLineIdx.add(toIdx);
                                            }
                                        }

                                        for (let idx = rangeFrom; idx < rangeTo; idx++) {
                                            const value = planned![idx]?.[1]?.value;
                                            if (coveredLineIdx.has(idx) || value === undefined)
                                                continue;

                                            drawCircle(`overlay-${idx}`, idx, value);
                                        }
                                    }

                                    for (const idx of visibleIndices) {
                                        const key = `overlay-${idx}`;
                                        const x = idxToX(idx).center;
                                        const actual = barData[idx].value;
                                        const plan = planned?.[idx]?.[1]?.value ?? actual;
                                        const yPlan = valueToY(plan);
                                        const yActual = valueToY(actual);
                                        const minY = Math.min(yActual, yPlan);

                                        lines.push(<line key={key + "-lline"} x1={x + 0.5} x2={x + 0.5} y1={yActual} y2={minY - 20} strokeWidth={0.5} stroke={colors["$coolgray-400"]} clipPath="url(#constrain-pane-labels)" />);
                                        labels.push(<text key={key + "-label"} className="setupGraphLabel" dominantBaseline="auto" textAnchor="middle" x={x} y={minY - 25} clipPath="url(#constrain-pane-labels)">
                                            {Formatter.formatDurationShort(actual, 2, session.numberFormatLocale)}
                                        </text>);
                                    }

                                    return lines.concat(bars).concat(labels);
                                }}
                            />}

                            {settings.kpiMatrix.useDeviationBars && <GroupGraph
                                {...graphOptions}
                                initialOffset={{
                                    alignment: Alignments.Right,
                                    offset: 0,
                                }}
                                showYAxisLines={true}
                                barPadding={10}
                                minGroupPadding={50}
                                yAxisLabel={i18n.t("common.setupEvents").toString()}
                                showBarValues={true}
                                data={planned!}
                                valueFormatter={value => Formatter.formatDurationShort(value, 2, session.numberFormatLocale)}
                                selectedGroupIdx={graphOptions.selectedIndex}
                                selectedGroupBarIdx={0}
                                onLabelSelected={(idx) => {
                                    settings.setSelection({
                                        setupTransition: planned![idx][0]!.data,
                                    });
                                }}
                                onSelected={(_group, _bar, element) => {
                                    settings.setSelection({
                                        setupTransition: element,
                                    });
                                }}

                            />}
                        </>}
                    </>}

                    {legendItems.length > 0 && !showNoDataBanner &&
                        isReady && !!size?.width && !!size?.height && <Legend
                        items={legendItems}
                        className="dfgLegendContainer"
                    />}
                </div>
            </div>
        </div>
        <div className="bottomLeft">
            <TrayElement>
                <button
                    className="shortcutButton"
                    onClick={() => {
                        settings.mergeSet({
                            kpiMatrix: {
                                machineName: ""
                            }
                        });
                    }
                    }>
                    {i18n.t("setupMatrix.startNewAnalysis").toString()}
                </button>
            </TrayElement>
        </div>
    </>;
}
