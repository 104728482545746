import { isArray } from "lodash";
import React from "react";
import i18n from "../../i18n";

/**
 * Simple tabbing class. Provide tabs as a list of icon/translation key tuples, and the
 * children will be treated as content pages. Example:
 * 
 * <Tabs pages={[{label:"generic.info", icon: "questionmark"}, {label: "Page 2", icon: "cog"}]} >
 *      <div>Info page content</div>
 *      <div>Page 2 content</div>
 * </Tabs>
 * 
 * Place tabs into a container with `position: relative;` and a fixed size.
 */

export type TabType = {
    label?: string;
    icon?: string;
    className?: string;
    isHidden?: boolean;
    isDisabled?: boolean;
}

export enum TabStyles {
    InlinePage,
    Classic,
}

type TabsPropsType = {
    pages: TabType[];
    tabClassName?: string;
    selectedIndex: number;
    tabStyle: TabStyles;
    onChanged: (tab: TabType, index: number) => void;
    children: React.ReactNode[] | React.ReactNode;

    body?: React.ReactNode;
    header?: React.ReactNode;
};

export default function Tabs(props: TabsPropsType) {
    const childCount = isArray(props.children) ? props.children.length : 1;
    if (props.pages.length !== childCount)
        throw Error("Tabs component definitions for pages and children do not match.");

    return (
        <div className={props.tabStyle === TabStyles.Classic ? "tabsClassic" : "tabsInline"}>
            {props.pages.length > 1 && <div className="header">
                {props.header}
                {props.pages.map((t, idx) => {
                    return <div
                        id={t.label ?? undefined}
                        key={(t.icon ?? "?") + (t.label ?? "?")}
                        title={t.label ? i18n.t(t.label).toString() : undefined}
                        onClick={() => {
                            if (t.isDisabled || idx === props.selectedIndex)
                                return;
                            props.onChanged(t, idx);
                        }}
                        className={(idx === props.selectedIndex ? "tab active" : "tab") +
                            (props.tabClassName ? " " + props.tabClassName : "") +
                            (t.isHidden ? " hidden" : "") + (t.isDisabled ? " disabled" : "")}>
                        {!!t.icon && <svg className="svg-icon small"><use xlinkHref={"#" + t.icon} /></svg>}
                        {!!t.label && <span title={i18n.t(t.label).toString()}>{i18n.t(t.label).toString()}</span>}
                    </div>;
                })}
                {props.tabStyle === TabStyles.InlinePage && <div className="tab tabsFiller"></div>}
            </div>}

            <div className="body">
                {isArray(props.children) ? props.children.filter((c, idx) => {
                    return idx === props.selectedIndex;
                }).map((c, idx) => {
                    return <div key={idx} className="visible">
                        {c}
                    </div>;
                }) : props.children}
                {props.body}
            </div>
        </div>
    );
}