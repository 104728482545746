import React, { useContext } from "react";
import VarianceStatistics from "../../components/variance-statistics/VarianceStatistics";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { buildProductFilter } from "../../utils/FilterBuilder";
import { Formatter } from "../../utils/Formatter";
import { buildKpiSpotlightId, getKpiDefinition } from "../../models/Kpi";
import { StatsRowValue } from "../../components/stats-section/StatsRowValue";
import { KpiTypes } from "../../models/KpiTypes";
import { useProducts } from "../../hooks/UseProducts";

export function SideStatisticsProductDeviation() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const filters = settings.previewFilters ?? settings.filters;

    // Narrow the results down to the product we're interested in here
    const productFilter = settings.selection.product ? buildProductFilter([settings.selection.product], false, session) : undefined;
    const deviationOptions = {
        eventFilters: productFilter ? [...filters, productFilter] : filters,
        limit: 1,
        calculateTimeAndFreqStats: true,
        calculatePlanned: true,
        calculateDeviations: true,
    };

    const [allProducts, allProductsLoading] = useProducts(deviationOptions);

    // allProducts, fastProducts, and slowProducts should all
    // just contain a single element anyway
    const selectedProductAll = allProducts?.products.find(p => p.id === settings.selection.product?.id);

    if (!settings.selection.product)
        return null;

    const relativeToPlanned = (selectedProductAll?.deviation as unknown as any)?.relativeToPlanned.durationStatistics;

    return <div data-testid="sidePanel">
        <StatsSection title={`${i18n.t("common.product")} ${selectedProductAll?.name}`} isTitleLoading={allProductsLoading} >

            {relativeToPlanned !== undefined && <StatsSubSection title="workflows.planningDeviationByProduct.averageRelativeTitle" disableTable={true} spotlightId={buildKpiSpotlightId(KpiTypes.DeviationRelativeThroughputTime)}>
                <VarianceStatistics
                    variance={relativeToPlanned}
                    isLoading={allProductsLoading}
                    unit={Formatter.units.percent}
                    labelLow={i18n.t("workflows.planningDeviation.faster").toString()}
                    labelHigh={i18n.t("workflows.planningDeviation.slower").toString()}
                    count={selectedProductAll?.actual?.count}
                />
            </StatsSubSection>}

            <StatsSubSection title="workflows.planningDeviationByProduct.averageDurationTitle" disableTable={true} spotlightId={buildKpiSpotlightId(KpiTypes.DeviationThroughputTime)}>
                <VarianceStatistics
                    variance={selectedProductAll?.deviation?.durationStatistics}
                    isLoading={allProductsLoading}
                    unit={Formatter.units.durationShort}
                    labelLow={i18n.t("workflows.planningDeviation.faster").toString()}
                    labelHigh={i18n.t("workflows.planningDeviation.slower").toString()}
                    count={selectedProductAll?.actual?.count}
                />
            </StatsSubSection>

            <StatsSubSection title={getKpiDefinition(KpiTypes.OrderCount, { session, settings })?.label} spotlightId={buildKpiSpotlightId(KpiTypes.OrderCount)}>
                <StatsRowValue isLoading={allProductsLoading} label="common.statistics.sum" unit={Formatter.units.numberShort} value={selectedProductAll?.actual?.count} />
            </StatsSubSection>

        </StatsSection>
    </div>;
}
