import { isString } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { isRouteActive, NavItem, NavItemProps } from "./NavItem";
import { classNames } from "../../utils/Utils";

export type NavGroupProps = {
    title?: string;
    isSelected?: boolean;
    childElements: (NavItemProps | NavGroupProps)[];
    level?: number;
    icon?: string;
    className?: string;
    isCollapsible?: boolean;
    id?: string;
    markup?: JSX.Element;
    idSlug?: string;
};

export function NavGroup(props: NavGroupProps) {
    const isCollapsible = props.isCollapsible === undefined || props.isCollapsible;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsible);
    const location = useLocation();
    const level = props.level ?? -1;

    const isGroupWithChildren = hasElements(props);

    const children = (props.childElements ?? []).map((c, idx) => {
        const active = someChild(c, (child) => {
            const url = isString(child.targetUrl) ? child.targetUrl : child.targetUrl?.();
            return isRouteActive(location.pathname, url, child);
        });

        const isGroup = isGroupProps(c);
        if (isGroup) {
            const key = c.title ?? idx.toString();
            return <NavGroup
                {...c as NavGroupProps}
                id={(props.id ? props.id + "." : "") + (c.idSlug ?? c.id ?? "group-" + idx)}
                key={key}
                isSelected={active}
                level={level + 1}
            />;
        }

        const childProps = c as NavItemProps;
        const key = (c.title ?? isString(childProps.targetUrl) ? childProps.targetUrl as string : childProps.targetUrl?.()) ?? `item-${idx}`;
        return <NavItem
            key={key}
            {...c as NavItemProps}
            id={(props.id ? props.id + "." : "") + (c.idSlug ?? "")}
            level={level + 1}
        />;
    });

    useEffect(() => {
        // When the page is loaded expand the nav group that's containing the currently
        // active route
        if (isCollapsed && props.isSelected)
            setIsCollapsed(false);
    }, [
        location.pathname
    ]);

    if (!isGroupWithChildren) 
        return <></>;

    return <div className={classNames(["navGroup", props.isSelected && "selected", isCollapsed && isCollapsible && "collapsed", !isCollapsible && "navGroupNotCollapsible", props.className])}>
        {(props.title !== undefined || props.markup !== undefined) && <div 
            className="title"
            id={props.id}
            onClick={() => {
                setIsCollapsed(!isCollapsed);
            }}    
        >
            <div 
                className={"navGroupLevel" + level.toString()}>
                {props.markup !== undefined && props.markup}
                {props.markup === undefined && props.icon !== undefined && <svg className="svg-icon xsmall"><use xlinkHref={props.icon} /></svg>}
                {props.markup === undefined && i18n.t(props.title ?? "")}
            </div>

            {isCollapsible && <div className="collapser">
                <svg className="svg-icon tiny">
                    <use xlinkHref="#radix-chevron-up" />
                </svg>
            </div>}
        </div>}
        <div className="children">
            {children}
        </div>
    </div>;
}

export function hasElements(props: NavGroupProps | NavItemProps): boolean {
    if (isGroupProps(props))
        return (props as NavGroupProps).childElements?.some(c => hasElements(c));

    const itemProps = props as NavItemProps;
    return itemProps.isVisible === undefined || itemProps.isVisible;
}

export function someChild(props: NavGroupProps | NavItemProps, evaluator: (child: NavItemProps) => boolean): boolean {
    const isGroup = isGroupProps(props);
    if (isGroup) {
        const groupProps = props as NavGroupProps;
        return (groupProps.childElements ?? []).some(c => {
            return someChild(c, evaluator);
        });
    }
    return evaluator(props as NavItemProps);
}

function isGroupProps(props: NavGroupProps | NavItemProps | undefined) {
    return props !== undefined &&
        (props as NavItemProps).targetUrl === undefined &&
        (props as NavItemProps).onClick === undefined;
}