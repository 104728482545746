import React, { useContext, useEffect, useMemo } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { ALL_OBJECT_INDICATOR, CASE_TYPE_ID, GroupingKeys } from "../../models/Dfg";
import { StatsSubSection } from "../stats-section/StatsSection";
import { StatsRowString } from "../stats-section/StatsRowString";
import { SessionContext } from "../../contexts/SessionContext";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";


export function LocalizationInfoStats() {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const node = settings.selection.node;

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const objectType = useMemo(() => {
        // For Gebinde we don't want to show localization info
        if (!isObjectCentric || settings.graph.objectType === "Gebinde")
            return undefined;

        // Initialize objectType setting if it is not part of the selected multiEdge
        let typeToSet: string | undefined = isObjectCentric ? settings.graph.objectType : undefined;

        if (settings.selection.edge && !settings.selection.edge.edges.some(e => e.objectType === settings.graph.objectType)) {
            // Currently selected objectType is not part of the selected multiEdge. Select one that is, and
            // if there's none, select case view.
            typeToSet = settings.selection.edge?.edges?.find(o => o.objectType !== CASE_TYPE_ID)?.objectType;
        }

        if (settings.selection.node && isObjectCentric) {
            // Object selected
            if (!(settings.selection.node.objects ?? []).some(o => o.type === settings.graph.objectType)) {
                // Node does not have info for that object type
                typeToSet = (settings.selection.node.objects ?? []).find(o => o.type !== CASE_TYPE_ID)?.type;
            }
        }

        if (typeToSet)
            return typeToSet;
    }, [
        settings.selection.edge,
        settings.selection.node,
        settings.graph.objectType,
    ]);

    useEffect(() => {
        if (!isObjectCentric || 
            settings.graph.objectType === "Gebinde" || 
            settings.graph.objectType === ALL_OBJECT_INDICATOR ||
            objectType === undefined)
            return;

        settings.setGraph({ objectType });
    }, [
        objectType, 
        isObjectCentric, 
        settings.graph.objectType,
    ]);

    if (node !== undefined) {
        switch (settings.groupingKey) {
            case GroupingKeys.NoneValueStream:
            case GroupingKeys.None:
                if (node?.activityValues?.machine?.value || node?.activityValues?.machineType?.value || node?.activityValues?.location?.value)
                    return <StatsSubSection title="common.localization" spotlightId="Localization">
                        {isObjectCentric && objectType && <StatsRowString label="common.material" value={objectType} />}
                        {node?.activityValues?.machine?.value &&
                            <StatsRowString label="common.machine" value={node?.activityValues?.machine?.value} />}

                        {node?.activityValues?.machineType?.value &&
                            <StatsRowString label="common.machineType" value={node?.activityValues?.machineType?.value} />}

                        {node?.activityValues?.location?.value &&
                            <StatsRowString label="common.location" value={node?.activityValues?.location?.value} />}
                    </StatsSubSection>;

                return null;
            case GroupingKeys.MachineObjectType:
            case GroupingKeys.MachineObjectTypeValueStream:
            case GroupingKeys.MachineValueStream:
            case GroupingKeys.Machine:
                if (node?.activityValues?.machineType?.value || node?.activityValues?.location?.value)
                    return <StatsSubSection title="common.localization" spotlightId="Localization">
                        {isObjectCentric && objectType && <StatsRowString label="common.material" value={objectType} />}
                        {node?.activityValues?.machineType?.value &&
                            <StatsRowString label="common.machineType" value={node?.activityValues?.machineType?.value} />}

                        {node?.activityValues?.location?.value &&
                            <StatsRowString label="common.location" value={node?.activityValues?.location?.value} />}
                    </StatsSubSection>;
                return null;

            default:
                if (isObjectCentric && objectType)
                    return <StatsSubSection title="common.localization" spotlightId="Localization">
                        {objectType && <StatsRowString label="common.material" value={objectType} />}
                    </StatsSubSection>;
                return null;
        }
    }

    if (isObjectCentric && objectType)
        return <StatsSubSection title="common.localization" spotlightId="Localization">
            {<StatsRowString label="common.material" value={objectType} />}
        </StatsSubSection>;
    return null;
}