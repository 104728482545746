import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import { GraphOrientation, GraphSettingsType, KpiSettingsType, SettingsContext, SettingsContextProvider, SettingsType, VisualizationType } from "../../contexts/SettingsContext";
import { calculateGraphDisplayArguments } from "../../hooks/UseGraph";
import { useUnifiedDeviationGraphs } from "../../hooks/UseUnifiedDeviationGraph";
import i18n from "../../i18n";
import { BaseGraph, Edge, MultiEdge, Node } from "../../models/Dfg";
import { KpiTypes } from "../../models/KpiTypes";
import { DfgUtils, getCustomKpisDfg, getEdgeLabelText, getEdgeStat } from "../../utils/DfgUtils";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import { isObjectCentricDeviationAvailable } from "../../utils/SettingsUtils";
import { AutoCenteringModes, DfGraphLayout, GraphPropsType, HighlightFunc, IDfGraph, PanZoomState, ZoomControlLocations, deviationLegendFrequencyProps, deviationLegendProps, deviationLegendStart, edgeHighlightColorMapEarly, edgeHighlightColorMapLate, nodeHighlightColorMapDefault, nodeHighlightColorMapEarly, nodeHighlightColorMapLate, nodeLegendProps } from "../dfg/DfGraph";
import { DfgLegend, DfgLegendProps } from "../dfg/DfgLegend";
import { getNodeMarkupDeviations, getNodeMarkupTimings } from "../dfg/nodes/NodeMarkupFactory";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import { BackButtonTrayElement } from "../tray/BackButtonTrayElement";
import { DownloadDfgTrayElement } from "../tray/DownloadDfgTrayElement";
import { ISettingsSpy, SettingsSpy } from "./SettingsSpy";
import { getKpiDefinition } from "../../models/Kpi";

export type DeviationGraphProps = Omit<Omit<Omit<GraphPropsType, "markupFunc">, "edgeLabelFunc">, "nodeHighlightStatFunc">;

const kpisWithDefaultLegend = [KpiTypes.OnTimeStart, KpiTypes.OnTimeEnd];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DeviationGraph = React.forwardRef((props: DeviationGraphProps, ref: React.Ref<IDfGraph>) => {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const key = `${session.user?.hash}-${session.project?.id}-`;

    const [actual, planned, deviation, isLoading] = useUnifiedDeviationGraphs({
        ...getCustomKpisDfg(settings, session, true),
        ...calculateGraphDisplayArguments,
        calculateUnknownStats: true,
        useActivityPasses: true,
        calculateTimeAndFreqStats: true,
        calculateSetupStats: true,
    });

    const isObjectCentric = session.project !== undefined && isObjectCentricDeviationAvailable(session.project);

    const settingsWriterPlan = useRef<ISettingsSpy>(null);
    const settingsWriterActual = useRef<ISettingsSpy>(null);
    const settingsWriterAggregated = useRef<ISettingsSpy>(null);

    const [isLinked, setIsLinked] = useState((localStorage.getItem(key + "isLinked")?.toLowerCase() === "true") ?? false);

    const refSingle = useRef<IDfGraph>(null);
    const refLeft = useRef<IDfGraph>(null);
    const refRight = useRef<IDfGraph>(null);

    const lastViewState = useRef<PanZoomState | undefined>();

    const combinedOrientation = getOrientation("combined");
    const actualOrientation = getOrientation("actual");
    if (isLinked)
        localStorage.setItem(key + "plan", actualOrientation);
    const planOrientation = getOrientation("plan");

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, {settings, session});

    // #region Get lookups for unplanned nodes/edges
    const { unplannedEdges, unplannedNodes, unexecutedEdges, unexecutedNodes } = useMemo(() => {
        const unplanned = getUnmatchedElements(actual, planned, isObjectCentric, settings.graph.objectType);
        const unexecuted = getUnmatchedElements(planned, actual, isObjectCentric, settings.graph.objectType);
        return {
            unplannedEdges: unplanned.unmatchedEdges,
            unplannedNodes: unplanned.unmatchedNodes,
            unexecutedEdges: unexecuted.unmatchedEdges,
            unexecutedNodes: unexecuted.unmatchedNodes,
        };
    }, [
        actual, planned,
        settings.graph.objectType,
        isObjectCentric
    ]);

    const hasUnexecuted = unexecutedNodes.size > 0 || unexecutedEdges.size > 0;
    // #endregion

    useEffect(() => {
        if (settings.graph.visualization !== VisualizationType.Single)
            return;

        setOrientation(combinedOrientation, ["combined"]);
    }, [
        combinedOrientation,
        settings.graph.visualization,
    ]);

    useEffect(() => {
        // The nodes and edges from the "actual" graph are the ones with deviation data.
        // So if something in the left chart is selected, we need to map the instance to
        // it's counterpart in the actual graph.
        // If we don't find such node/edge, revert to using the original instance.
        if (!actual || !planned)
            return;

        if (settings.selection.node) {
            const node = actual?.nodes.find(n => n.id === settings.selection.node?.id) ?? settings.selection.node;
            settings.setSelection({ node });
            return;
        }

        if (settings.selection.edge) {
            const edge = actual?.multiEdges?.find(e => e.from === settings.selection.edge?.from && e.to === settings.selection.edge?.to) ?? settings.selection.edge;
            settings.setSelection({ edge });
            return;
        }
    }, [
        actual,
        planned,
        deviation,
        settings.selection.node?.id,
        settings.selection.edge?.from,
        settings.selection.edge?.to,
    ]);

    // This useEffect block deals with selections only. This has to be
    // separate from the viewState handling below, because this should
    // also work when the DFG views are not linked.
    useEffect(() => {
        settingsWriterActual.current?.setSelection(settings.selection);
        settingsWriterPlan.current?.setSelection(settings.selection);
        settingsWriterAggregated.current?.setSelection(settings.selection);
    }, [
        settings.selection.node,
        settings.selection.edge,
    ]);

    // This block synchronizes the DFG viewStates when the DFGs are linked.
    useEffect(() => {
        const graph: Partial<GraphSettingsType> = {
            nodeDetailLevel: settings.graph.nodeDetailLevel,
            orientation: settings.graph.orientation,
            highlight: settings.graph.highlight,
            secondGroupingLevel: settings.graph.secondGroupingLevel,
            complexityCutoffScore: settings.graph.complexityCutoffScore,
            objectType: settings.graph.objectType,
            showObjectContext: settings.graph.showObjectContext,
            visualization: settings.graph.visualization,
        };

        const kpi: Partial<KpiSettingsType> = {
            selectedKpi: settings.kpi.selectedKpi,
            statistic: settings.kpi.statistic,
        };

        const data = {
            groupingKey: settings.groupingKey,
            graph: { ...graph },
            kpi: { ...kpi },
            quantity: settings.quantity,
        };

        settingsWriterActual.current?.set({ ...data, graph: { ...data.graph, orientation: actualOrientation } });
        settingsWriterPlan.current?.set({ ...data, graph: { ...data.graph, orientation: planOrientation } });
        settingsWriterAggregated.current?.set(data);
    }, [
        settings.groupingKey,
        settings.graph.visualization,
        settings.graph.highlight,
        settings.graph.orientation,
        settings.graph.secondGroupingLevel,
        settings.graph.nodeDetailLevel,
        settings.graph.complexityCutoffScore,
        settings.graph.objectType,
        settings.graph.showObjectContext,
        settings.quantity,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        planOrientation,
        actualOrientation,
        isLinked,
    ]);
    // Show "unplanned" legend only if relevant. That is when nodes are highlighted and there are in fact unplanned nodes.
    // Same for edges.
    const unplannedLegendProps = {
        showUnplanned: (unplannedEdges.size > 0) ||
            (unplannedNodes.size > 0)
    };
    const legendProps: DfgLegendProps = getLegendProps();

    return <div className="fillParent">
        {settings.graph.visualization === VisualizationType.Single && <>
            {!isLoading && <DfgLegend {...legendProps} />}
            <SettingsContextProvider initialValues={{ ...settings }}>
                <SettingsSpy ref={settingsWriterAggregated} />
                <DfGraphLayout
                    ref={refSingle}
                    zoomControlLocation={ZoomControlLocations.FarRight}
                    graph={kpiDefinition?.isPassDeviation ? actual : deviation}
                    isLoading={isLoading}
                    showComplexitySlider={false}
                    className={props.className}
                    minZoom={props.minZoom}
                    maxZoom={props.maxZoom}
                    centerMode={AutoCenteringModes.None}
                    isObjectCentric={isObjectCentric}
                    markupFunc={getNodeMarkupDeviations}
                    // We always activate the highlighting in order to show unplanned/unexecuted edges.
                    // The highlighting statistics functions need to be adapted accordingly.
                    highlightEdges={true}
                    highlightNodes={true}
                    edgeLabelFunc={(edge, settings, session) => {
                        const statistic = getEdgeStat(edge, settings, session);
                        if (statistic === undefined)
                            return i18n.t("workflows.planningDeviation.unplanned").toString();
                        return getEdgeLabelText(edge, settings, session);
                    }}
                    nodeHighlightStatFunc={(node, settings) => {
                        // we use just the statistic value here because we are already using the deviation graph (graph.deviation)
                        if (settings.graph.highlight)
                            return getMainNodeStat(node, settings, session);
                        return undefined;
                    }}
                    nodeHighlightColorFunc={nodeColoringFunc}

                    edgeHighlightStatFunc={(edge, settings, session) => {
                        // we use just the statistic value here because we are already using the deviation graph (graph.deviation)
                        if (settings.graph.highlight)
                            return getEdgeStat(edge, settings, session);
                        return undefined;
                    }}
                    onOrientationChanged={(orientation) => {
                        setOrientation(orientation, ["combined"]);
                    }}
                    edgeHighlightColorFunc={edgeColoringFunc}
                    edgeThicknessFunc={getDeviationEdgeThickness}
                    onSelected={(selection) => {
                        settings.setSelection({
                            ...selection,
                        });
                    }}
                />
            </SettingsContextProvider>
            <DownloadDfgTrayElement graph={refSingle.current} filename={i18n.t("workflows.planningDeviation.title").toString()} />
            <BackButtonTrayElement />
        </>}

        {settings.graph.visualization === VisualizationType.SideBySide && <div className="sideBySide">
            <div className="plan">
                <h3>{i18n.t("workflows.planningDeviation.plan")}</h3>
                {!isLoading && hasUnexecuted && <div className="dfgLegendContainer">
                    <div className="dfgLegendUnplanned">
                        <div>{i18n.t("workflows.planningDeviation.unexecuted")}</div>
                        <div className="dfgLegendUnplannedColorbox" />
                    </div>
                </div>}
                <div className="tray noSpotlightTray" />
                <SettingsContextProvider initialValues={{ ...settings }}>
                    <SettingsSpy ref={settingsWriterPlan} />
                    <DfGraphLayout
                        id="dfg-left"
                        ref={refLeft}
                        graph={planned}
                        isLoading={isLoading}
                        className={props.className}
                        minZoom={props.minZoom}
                        maxZoom={props.maxZoom}
                        showComplexitySlider={false}
                        showProjectLoadingSpinner={false}
                        isObjectCentric={isObjectCentric}
                        zoomControlLocation={ZoomControlLocations.Right}
                        markupFunc={getNodeMarkupTimings}
                        // We always activate the highlighting in order to show unplanned/unexecuted edges.
                        // The highlighting statistics functions need to be adapted accordingly.
                        highlightEdges={true}
                        highlightNodes={true}
                        edgeLabelFunc={getEdgeLabelText}
                        edgeHighlightColorFunc={(edge: Edge | MultiEdge) => {
                            if (unexecutedEdges.has(getEdgeId(edge)))
                                return colors.$unplanned;
                        }}
                        nodeHighlightColorFunc={(node: Node) => {
                            if (unexecutedNodes.has(node.id))
                                return colors.$unplanned;
                        }}
                        onViewChanged={(viewState) => {
                            lastViewState.current = { ...viewState };
                            if (isLinked) {
                                const animate = refLeft.current?.getViewState().viewState.zoom !== viewState.zoom;
                                refRight.current?.setViewState(lastViewState.current, undefined, animate);
                            }
                        }}
                        onOrientationChanged={(orientation) => {
                            lastViewState.current = refLeft.current?.getViewState().viewState;

                            if (isLinked)
                                setOrientation(orientation, ["actual"]);

                            setOrientation(orientation, ["plan"]);
                        }}
                        centerMode={AutoCenteringModes.None}
                        onSelected={(selection) => {
                            // Translate node or edge to an instance from the right graph.
                            // That's the one with juicy stats!
                            const node = selection.node ? actual?.nodes.find(n => n.id === selection.node?.id) ?? selection.node : undefined;
                            const edge = selection.edge ? actual?.multiEdges?.find(e => e.from === selection.edge?.from && e.to === selection.edge.to) ?? selection.edge : undefined;

                            settings.setSelection({
                                ...selection,
                                node,
                                edge,
                            });
                        }}
                    />
                </SettingsContextProvider>
                <DownloadDfgTrayElement graph={refLeft.current} filename={i18n.t("workflows.planningDeviation.title").toString() + "-" + i18n.t("kpiComparisons.planning").toString()} />
            </div>
            <div>
                <h3>{i18n.t("workflows.planningDeviation.actual")}</h3>
                {!isLoading && <DfgLegend {...legendProps} />}
                <SettingsContextProvider initialValues={{ ...settings }}>
                    <SettingsSpy ref={settingsWriterActual} />
                    <DfGraphLayout
                        id="dfg-right"
                        ref={refRight}
                        graph={actual}
                        isLoading={isLoading}
                        className={props.className}
                        minZoom={props.minZoom}
                        maxZoom={props.maxZoom}
                        showComplexitySlider={false}
                        showProjectLoadingSpinner={false}
                        isObjectCentric={isObjectCentric}
                        markupFunc={getNodeMarkupTimings}
                        // We always activate the highlighting in order to show unplanned/unexecuted edges.
                        // The highlighting statistics functions need to be adapted accordingly.
                        highlightEdges={true}
                        highlightNodes={true}
                        zoomControlLocation={ZoomControlLocations.Left}
                        edgeLabelFunc={getEdgeLabelText}
                        nodeHighlightStatFunc={(node, settings) => {
                            // Note that in the side-by-side view, we want to draw highlighting based on the deviation, not the statistic shown in the node, so we need to fetch the deviation value explicitly.
                            if (settings.graph.highlight) {
                                if (kpiDefinition?.isPassDeviation)
                                    return getMainNodeStat(node, settings, session);
                                return getDeviationNodeStatisticValue(node, settings);
                            }
                            return undefined;
                        }}
                        nodeHighlightColorFunc={nodeColoringFunc}

                        edgeHighlightStatFunc={(edge, settings) => {
                            // Note that in the side-by-side view, we want to draw highlighting based on the deviation, not the statistic shown on the edge, so we need to fetch the deviation value explicitly.
                            if (settings.graph.highlight)
                                return getDeviationEdgeStatisticValue(edge, settings);
                            return undefined;
                        }}
                        edgeHighlightColorFunc={edgeColoringFunc}
                        edgeThicknessFunc={getDeviationEdgeThickness}
                        centerMode={AutoCenteringModes.None}
                        onViewChanged={(viewState) => {
                            lastViewState.current = { ...viewState };
                            if (isLinked) {
                                const animate = refRight.current?.getViewState().viewState.zoom !== viewState.zoom;
                                refLeft.current?.setViewState(lastViewState.current, undefined, animate);
                            }
                        }}
                        onOrientationChanged={(orientation) => {
                            lastViewState.current = refRight.current?.getViewState().viewState;

                            if (isLinked)
                                setOrientation(orientation, ["plan"]);

                            setOrientation(orientation, ["actual"]);
                        }}
                        onSelected={(selection) => {
                            settings.setSelection(selection);
                        }}
                    />
                </SettingsContextProvider>
                <DownloadDfgTrayElement graph={refRight.current} filename={i18n.t("workflows.planningDeviation.title").toString() + "-" + i18n.t("common.actual").toString()} />
                <BackButtonTrayElement />
            </div>

            <div className="linkButton" title={i18n.t("workflows.planningDeviation.linkDfgs").toString()} onClick={() => {
                const newState = !isLinked;
                setIsLinked(newState);
                localStorage.setItem(key + "isLinked", newState.toString());

                if (newState) {
                    const orientation = getOrientation("actual");
                    if (lastViewState.current && settings.graph.visualization === VisualizationType.SideBySide) {
                        refLeft.current?.setViewState(lastViewState.current, orientation, true);
                        refRight.current?.setViewState(lastViewState.current, orientation, true);
                    }
                }
            }}>
                {isLinked && <svg className="svg-icon xsmall"><use xlinkHref="#link" /></svg>}
                {!isLinked && <svg className="svg-icon xsmall"><use xlinkHref="#unlink" /></svg>}
            </div>
        </div>}
        <Shortcuts handledSelections={[ShortcutContexts.Node, ShortcutContexts.Edge]} graph={actual} />
    </div>;

    function getOrientation(pane: "plan" | "actual" | "combined") {
        return (localStorage.getItem(key + pane) ?? GraphOrientation.vertical) as GraphOrientation;
    }

    function setOrientation(orientation: GraphOrientation, pane: ("plan" | "actual" | "combined")[]) {
        if (pane.includes("plan")) {
            localStorage.setItem(key + "plan", orientation);
            settingsWriterPlan.current?.setGraph({ orientation });
        }

        if (pane.includes("actual")) {
            localStorage.setItem(key + "actual", orientation);
            settingsWriterActual.current?.setGraph({ orientation });
        }

        if (pane.includes("combined")) {
            localStorage.setItem(key + "combined", orientation);
            settingsWriterAggregated.current?.setGraph({ orientation });
        }
    }

    /**
     * Looks up the edge from the deviation graph and returns the statistic value
     * @param multiEdge the edge to render labels for
     * @settings current settings context values
     * @returns numeric value
     */
    function getDeviationEdgeStatisticValue(multiEdge: MultiEdge, settings: SettingsType) {
        const deviationEdge = deviation?.multiEdges?.find((e) => e.from === multiEdge.from && e.to === multiEdge.to);
        if (deviationEdge === undefined)
            return undefined;
        return getEdgeStat(deviationEdge, settings, session);
    }

    /**
     * Looks up the node from the deviation graph and returns the statistic value
     * @param node the node to render labels for
     * @settings current settings context values
     * @returns numeric value
     */
    function getDeviationNodeStatisticValue(node: Node, settings: SettingsType) {
        const deviationNode = deviation?.nodes.find((n) => n.id === node.id);
        if (deviationNode === undefined)
            return undefined;
        return getMainNodeStat(deviationNode, settings, session);
    }

    function edgeColoringFunc(edge: Edge | MultiEdge, stat: number | undefined, minStatistic: number, maxStatistic: number) {
        if (unplannedEdges.has(getEdgeId(edge)))
            return colors.$unplanned;

        if (stat !== undefined)
            return coloringFunc(edge, stat, minStatistic, maxStatistic, edgeHighlightColorMapEarly, edgeHighlightColorMapLate);
    }

    function getLegendProps() {
        if (settings.graph.highlight) {
            if (kpisWithDefaultLegend.includes(settings.kpi.selectedKpi))
                return { ...nodeLegendProps, ...unplannedLegendProps };
            if (settings.kpi.selectedKpi === KpiTypes.Frequency)
                return { ...deviationLegendFrequencyProps, ...unplannedLegendProps };
            if ([KpiTypes.StartTimeDeviation, KpiTypes.EndTimeDeviation].includes(settings.kpi.selectedKpi))
                return { ...deviationLegendStart, ...unplannedLegendProps };
            return { ...deviationLegendProps, ...unplannedLegendProps };
        }
        return unplannedLegendProps;
    }


    function nodeColoringFunc(node: Node, stat: number | undefined, minStatistic: number, maxStatistic: number) {
        if (unplannedNodes.has(node.id))
            return colors.$unplanned;

        if (kpisWithDefaultLegend.includes(settings.kpi.selectedKpi)) {
            if (stat === undefined)
                return colors.$white;
            return nodeHighlightColorMapDefault(stat);
        }

        return coloringFunc(node, stat, minStatistic, maxStatistic, nodeHighlightColorMapEarly, nodeHighlightColorMapLate);
    }
});

function getScale(value: number, max: number) {
    return Math.min(1, Math.max(0, Math.abs(value / (max || 1))));
}

/**
 * Function that colors the nodes/edges of the actual graph based on the value returned
 * by the nodeHighlightStatFunc and edgeHighlightStatFunc functions.
 * This is just the coloring logic, no data is retrieved here.
 */
function coloringFunc(graphElement: any, stat: number | undefined, minStatistic: number, maxStatistic: number, earlyColorMap: HighlightFunc, lateColorMap: HighlightFunc) {
    if (stat === undefined)
        return;

    const max = Math.max(Math.abs(minStatistic), Math.abs(maxStatistic));
    if (stat === 0)
        return undefined;

    if (stat < 0) {
        // Too early
        const scale = getScale(stat, max);
        return earlyColorMap(scale);
    } else {
        // Too late
        const scale = getScale(stat, max);
        return lateColorMap(scale);
    }
}
function getDeviationEdgeThickness(edge: MultiEdge, stat: number, minStatistic: number, maxStatistic: number) {
    const max = Math.max(Math.abs(minStatistic), Math.abs(maxStatistic));
    if (stat < 0) {
        // Too early
        const scale = getScale(stat, max);
        return 3 + scale * 7;
    } else {
        // Too late
        const scale = getScale(stat, max);
        return 3 + scale * 7;
    }
}

function getEdgeId(edge: Edge | MultiEdge) {
    return `${edge.from}->${edge.to}`;
}


function getUnmatchedElements(g1: BaseGraph | undefined, g2: BaseGraph | undefined, isObjectCentric: boolean, objectType: string | undefined) {
    const unmatchedEdges = new Set<string>();
    const unmatchedNodes = new Set<string>();
    if (!g1 || !g2)
        return {
            unmatchedEdges,
            unmatchedNodes,
        };

    for (const edge of (g1?.multiEdges ?? [])) {
        const objectEdge = DfgUtils.findObjectEdge(edge, isObjectCentric, objectType);

        if (objectEdge?.objectType && !g2.edges.some(e => matchEdge(e.from, objectEdge.from) && matchEdge(e.to, objectEdge.to) && e.objectType === objectEdge.objectType)) {
            unmatchedEdges.add(getEdgeId(objectEdge));
        }
    }

    for (const node of (g1?.nodes ?? [])) {
        const objectNode = DfgUtils.findObjectNode(node, isObjectCentric, objectType);
        if (!!objectNode?.id && !g2.nodes.some(n => n.id === objectNode.id))
            unmatchedNodes.add(node.id);
    }

    return {
        unmatchedEdges,
        unmatchedNodes,
    };
}

function matchEdge(e1: string, e2: string) {
    // Link node names need to be handled special as they contain a generic number after __LINK_.
    // We therefore just check whether the start of the string matches.
    if (e1.startsWith("__LINK_"))
        return e2.startsWith("__LINK_");
    return e1 === e2;
}
