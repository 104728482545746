import React, { useContext, useEffect, useMemo } from "react";
import i18n from "../../i18n";
import Spinner from "../spinner/Spinner";
import { StatsRowString } from "../stats-section/StatsRowString";
import { StatsSubSection } from "../stats-section/StatsSection";
import { LineGraphProps, LineGraphData, LineGraph } from "./LineGraph";
import { TimePeriodFrequencies } from "../../models/ApiTypes";
import { useStatistics } from "../../hooks/UseStatistics";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SessionContext } from "../../contexts/SessionContext";
import { DataGapFilling, getKpiDefinition, getUnit } from "../../models/Kpi";
import { ValueSpinner } from "../value-spinner/ValueSpinner";
import Menu from "../menu/Menu";
import { Formatter, UnitMetadata } from "../../utils/Formatter";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { lowerCaseFirst } from "../../utils/Utils";

export function StatsLineGraph(props: Omit<Omit<Omit<Omit<LineGraphProps, "unitScale">, "xAxisTickFrequency">, "fill">, "data"> & {
    isLoading?: boolean;

    /**
     * Data to be shown. Check the LineGraph.useLineGraphData hook if that can help you!
     */
    data: LineGraphData[] | undefined;

    /**
     * Maximum frequency that can be selected. If not provided, the maximum frequency is Year.
     */
    maxFrequency?: TimePeriodFrequencies;

    /**
     * Frequency of the data points shown. Mandatory if setFrequency is not provided.
     */
    frequency: TimePeriodFrequencies | undefined;

    /**
     * This component checks the filtered time range and sets the frequency to the best fit using this function.
     * Also, when provided, the frequency selector will be shown.
     * However, if you don't provide a setFrequency function, you have to provide a frequency.
     */
    setFrequency?: (frequency: TimePeriodFrequencies) => void;

    /**
     * Spotlight id for the kpi spotlight.
     */
    spotlightId?: string | string[];

    /**
     * Unit to be used in the graph.
     */
    unit?: UnitMetadata | undefined;

    /**
     * Kpi data displayed in the graph.
     */
    kpi?: KpiTypes | undefined;
    
    /**
     * statistics used when displaying the data in the graph.
     */
    statistics?: StatisticTypes | undefined;
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [stats, isStatsLoading] = useStatistics(undefined, { disable: props.setFrequency === undefined || props.frequency !== undefined }); // Used for chart frequency init

    const kpi = props.kpi ?? settings.kpi.selectedKpi;
    const kpiDefinition = getKpiDefinition(kpi, { session, settings });

    const kpiStatistic = props.statistics ?? settings.kpi.statistic;

    const unit = props.unit ?? getUnit(kpiDefinition?.unit, kpiStatistic) ?? Formatter.units.number as UnitMetadata;
    const scale = Formatter.getUnit(unit.getUnits({
        baseQuantity: settings.quantity,
    }), Math.max(...(props.data?.map(e => Math.abs(e.y ?? 0)) ?? [0])) / 5) ?? Formatter.units.number.getUnits({})[0];

    if (props.frequency === undefined && props.setFrequency === undefined)
        console.error("When frequency is undefined you have to provide a setFrequency function. Then this component can take care of selecting an appropriate frequency.");

    // When change the time range of the graph, the frequency should be changed accordingly.
    useEffect(() => {
        if (isStatsLoading || stats?.maxDate === undefined || stats?.minDate === undefined ||
            props.setFrequency === undefined)
            return;

        if (stats.numFilteredEvents === 0 || stats.numFilteredTraces === 0) {
            props.setFrequency(TimePeriodFrequencies.Day);
            return;
        }

        const durationDays = (stats.maxDate!.getTime() - stats.minDate!.getTime()) / (1000 * 60 * 60 * 24);

        if (durationDays <= 7) {
            props.setFrequency(TimePeriodFrequencies.Hour);
            return;
        } 

        if (durationDays <= 7 * 20) {
            props.setFrequency(TimePeriodFrequencies.Day);
            return;
        }

        if (durationDays <= 30 * 20) {
            props.setFrequency(TimePeriodFrequencies.Week);
            return;
        }

        if (durationDays < (356 * 20)) {
            props.setFrequency(TimePeriodFrequencies.Month);
            return;
        }

        props.setFrequency(TimePeriodFrequencies.Year);
    }, [stats, isStatsLoading]);

    const FrequencyOptions = useMemo(() => {
        if (stats?.maxDate === undefined || stats?.minDate === undefined)
            return [];

        return [
            ...((stats.maxDate!.getTime() - stats.minDate!.getTime()) / (1000 * 60 * 60 * 24) <= 7 ? [{
                title: i18n.t("units.hour").toString(),
                onClick: () => { props.setFrequency!(TimePeriodFrequencies.Hour); }
            }] : []),
            {
                title: i18n.t("units.day").toString(),
                onClick: () => { props.setFrequency!(TimePeriodFrequencies.Day); }
            }, {
                title: i18n.t("units.week").toString(),
                onClick: () => { props.setFrequency!(TimePeriodFrequencies.Week); },
                disabled: props.maxFrequency === TimePeriodFrequencies.Day,
            }, {
                title: i18n.t("units.month").toString(),
                onClick: () => { props.setFrequency!(TimePeriodFrequencies.Month); },
                disabled: props.maxFrequency === TimePeriodFrequencies.Day ||
                    props.maxFrequency === TimePeriodFrequencies.Week,
            }, {
                title: i18n.t("units.year").toString(),
                disabled: props.maxFrequency !== TimePeriodFrequencies.Year,
                onClick: () => { props.setFrequency!(TimePeriodFrequencies.Year); }
            }];
    }, [
        stats, isStatsLoading,
    ]);

    return <StatsSubSection title="common.overTime" spotlightId={props.spotlightId} >
        {props.setFrequency !== undefined && <div className="timeperiodFrequencySelector">
            <Menu className="menuLight" items={FrequencyOptions}>
                <div className="buttonesque" data-testid="frequency-selection">
                    <ValueSpinner isLoading={props.frequency === undefined}>
                        <>
                            {props.frequency !== undefined && i18n.t("units." + props.frequency.toString())}
                        </>
                    </ValueSpinner>
                    <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-caret-down" /></svg>
                </div>
            </Menu>
        </div>}

        <StatsRowString label={i18n.t(`common.statistics.${kpiStatistic}`) + " " + (session.locale === "en" ? lowerCaseFirst(i18n.t(kpiDefinition?.label ?? "") ?? "") : i18n.t(kpiDefinition?.label ?? ""))} />
        <div className="statsLineGraph">
            <Spinner opaque={true} isLoading={props.isLoading || props.frequency === undefined} />
            {props.isLoading !== true && !!props.data?.length && props.frequency !== undefined && <LineGraph
                {...props}
                unitScale={scale}
                dataGapFilling={kpiDefinition?.dataGapFilling ?? DataGapFilling.Skip}
                data={props.data!} // At this point we know that data is not undefined
                xAxisTickFrequency={props.frequency}
            />}
        </div>
    </StatsSubSection>;
}