import React, { useEffect, useRef, useState } from "react";

export type ResizeState = {
    width: number,
    height: number,
};

export function useResizeObserver(componentRef: React.RefObject<HTMLDivElement>) {
    const [size, setSize] = useState<ResizeState | undefined>();
    
    const observerRef = useRef(new ResizeObserver(e => {
        const { width, height } = e[0].contentRect;

        if (size?.width !== width || size?.height !== height)
            setSize({ width, height });
    }));

    // Initialization and cleanup
    useEffect(() => {
        if (componentRef.current)
            observerRef.current.observe(componentRef.current);

        return () => {
            if (observerRef.current)
                observerRef.current.disconnect();
        };
    }, [
        componentRef.current,
        observerRef,
    ]);

    return size;
}