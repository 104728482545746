import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { AnalysisType, calculateOutputStatsArguments, useGraph } from "../../hooks/UseGraph";
import { NodeRoles } from "../../models/Dfg";
import { buildKpiSpotlightId, getKpiDefinition } from "../../models/Kpi";
import { KpiTypes } from "../../models/KpiTypes";
import { DfgUtils, getCustomKpisDfg } from "../../utils/DfgUtils";
import { Formatter } from "../../utils/Formatter";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { LogStats } from "../../views/process-kpi-chart/stats/LogStats";
import { StatsRowValue } from "../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../stats-section/StatsSection";
import { EdgeStats, FrequencyAndProductStats, NodeStats } from "../stats-section/Utility";
import { LocalizationInfoStats } from "./LocalizationInfoStats";
import { getSelectionLabel } from "./SideStatisticsTime";
import NodeStatsLineGraph from "../graph/NodeStatsLineGraph";

export default function SideStatisticsEnergy() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const graph = useGraph({
        ...calculateOutputStatsArguments,
        calculateActivityValues: true,
        calculateNodes: true,
        calculateEdges: true,
        calculateBusyStats: true,
        ...getCustomKpisDfg(settings, session, false, [settings.kpi.selectedKpi])
    }, AnalysisType.Energy);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    if (graph === undefined || settings.quantity === undefined)
        return <div></div>;
    const label = getSelectionLabel(graph, settings);

    const selectedEdge = DfgUtils.getEdgeFromGraph(graph, settings.selection.edge, isObjectCentric, settings.graph.objectType);
    const selectedNode = DfgUtils.getNodeFromGraph(graph, settings.selection.node, isObjectCentric, settings.graph.objectType);

    const isTerminalNodeSelected = Boolean(settings.selection.node?.role === NodeRoles.Start || settings.selection.node?.role === NodeRoles.End);

    const hasSelection = (settings.selection.node || settings.selection.edge);

    const kpi = settings.kpi.selectedKpi;
    const kpiDefinition = getKpiDefinition(kpi, { settings, session });

    return <>
        <div data-testid="sidePanel">
            {!hasSelection && <LogStats addEnergyStats={true} />}

            {hasSelection && !isTerminalNodeSelected && <StatsSection title={label}>
                <LocalizationInfoStats />

                {selectedNode !== undefined &&
                    <>
                        <NodeStats kpiType={kpi} node={selectedNode} />

                        {/* Line chart */}
                        {!!kpiDefinition?.nodeOverTimeStatisticsPath && <NodeStatsLineGraph />}

                        <FrequencyAndProductStats node={selectedNode} />
                    </>}
                {selectedEdge !== undefined &&
                    <>
                        <EdgeStats kpiType={kpi} edge={selectedEdge} />
                        <StatsSubSection title="common.actionFrequency" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge"])}>
                            <StatsRowValue label="common.statistics.absCount" value={selectedEdge?.frequencyStatistics?.sum} unit={Formatter.units.number} />
                        </StatsSubSection>
                    </>
                }

            </StatsSection>}
        </div>
    </>;
}
