import React from "react";
import { ScrollHost } from "../scrollhost/ScrollHost";
import i18n from "../../i18n";
import { Alignments, Spotlight } from "../spotlight/Spotlight";
import { capitalizeFirst } from "../../utils/Utils";

export type MatrixHeaderEntry = {
    title?: string;
    info?: string;
    id?: string;
    
    /**
     * When provided, a spotlight will be added to the header cell
     */
    spotlightId?: string;

    /**
     * When provided, a highlighting css class will be added to the header cell
     */
    highlightLevel?: number;
}

export enum MatrixType {
    Kpi = "kpi",
    Setup = "setup",
}
export interface MatrixPropsType {
    headerX: MatrixHeaderEntry[];
    labelHeaderX?: string;
    labelHeaderY?: string;
    alignmentHeaderX?: Alignments;
    alignmentHeaderY?: Alignments;
    headerY: MatrixHeaderEntry[];
    data: JSX.Element[][];
    cellWidth: number;
    cellHeight: number;
    title?: JSX.Element | JSX.Element[] | undefined;
    onClick?: () => void;
    onRemoveClick?: (title: string | undefined) => void;
}

export default function Matrix({
    headerX,
    headerY,
    data,
    cellWidth,
    cellHeight,
    alignmentHeaderX = Alignments.Center,
    alignmentHeaderY = Alignments.Center,
    labelHeaderX,
    labelHeaderY,
    title = undefined,
    onRemoveClick = undefined,
}: MatrixPropsType) {
    const cols = headerX.length;
    const rows = headerY.length;

    const headerSize = {
        width: cellWidth + 1,
        height: cellHeight + 1,
    };

    const contentSize = {
        width: cols * cellWidth + (cols - 1) * 2,
        height: rows * cellHeight + (rows - 1) * 2,
    };

    const style = {
        "--matrix-cols": cols,
        "--matrix-cell-width": `${cellWidth}px`,
        "--matrix-cell-height": `${cellHeight}px`,
        "--matrix-label-rotation": `${Math.atan(cellHeight / cellWidth)}rad`,
        "--matrix-cell-box-width": `${cellWidth + 2}px`,
        "--matrix-cell-box-height": `${cellHeight + 2}px`,
    } as React.CSSProperties;


    const header = (headerMatrix: MatrixHeaderEntry[], alignment: Alignments, enableRemove?: boolean) => {
        return (
            headerMatrix.map(({ title, info, id, spotlightId, highlightLevel }, index) => (
                <div className={`matrix__headerCell matrix__alignment${capitalizeFirst(alignment.toString())}`} key={index}>
                    {title &&
                        <div className="removeCell">
                            <div className={"matrix__headerCellTitle" + (highlightLevel ? ` highlightLevel${highlightLevel}` : "")} title={title}>{title}</div>
                            {spotlightId && <Spotlight id={spotlightId} className="regular" />}
                            {enableRemove && <svg onClick={() => onRemoveClick!(id)} className="svg-icon xtiny"><use xlinkHref={"#radix-cross-1"} /></svg>}
                        </div>
                    }
                    {info && <div className="matrix__headerCellInfo">{info}</div>}
                </div>
            ))
        );
    };

    return (
        <div className="matrix" style={style}>

            <div className="topContainer" style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                {title !== undefined && title}
            </div>

            <ScrollHost
                contentScrollFade={false}
                content={(top, left, bottom, right) => {
                    // Render elements currently in view
                    const result: JSX.Element[] = [];

                    const cw = cellWidth + 2; // Including gap
                    const ch = cellHeight + 2;
                    const fromX = Math.max(0, Math.floor(left / cw) - 1);
                    const toX = Math.min(headerX.length, Math.ceil(right / cw) + 1);
                    const fromY = Math.max(0, Math.floor(top / ch) - 1);
                    const toY = Math.min(headerY?.length, Math.ceil(bottom / ch) + 1);

                    for (let y = fromY; y < toY; y++)
                        for (let x = fromX; x < toX; x++) {
                            if (data[x] !== undefined && data[x][y] !== undefined) {
                                result.push(data[x][y]);
                                continue;
                            }

                            if (x !== y) {
                                result.push(<div
                                    key={`${x}-${y}`}
                                    style={{
                                        top: `calc(var(--matrix-cell-box-height) * ${y})`,
                                        left: `calc(var(--matrix-cell-box-width) * ${x})`,
                                    }}
                                    className={"matrixCell matrixCell--variant-default"}>
                                    <div className="matrixCell__title">-</div>
                                </div>);
                                continue;
                            }
                        }

                    return <div className="matrix__grid"
                    >{result}</div>;
                }}
                dimensions={{
                    contentSize,
                    headerSize,
                }}
                topLabel={<div className="matrix__topLabel">
                    <div className="matrix__topLabelRow">{i18n.t(labelHeaderY ?? "")}</div>
                    <div className="matrix__topLabelCol">{i18n.t(labelHeaderX ?? "")}</div>
                </div>}
                xHeader={<div className="matrix__xHeader"><div>{header(headerX, alignmentHeaderX, onRemoveClick !== undefined)}</div></div>}
                yHeader={<div className="matrix__yHeader">{header(headerY, alignmentHeaderY)}</div>}
            />
        </div>
    );
}
