import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import { SessionContext } from "../../contexts/SessionContext";
import { defaultValues, SettingsContext } from "../../contexts/SettingsContext";
import Global from "../../Global";
import { EventKeys } from "../../models/EventKeys";
import { Upload } from "../../models/Upload";
import { makeSettingsValid } from "../../utils/Initializers";
import IdentifyColumns from "./IdentifyColumns";
import { Api } from "../../api/Api";

export const productCategoryGroup = "common.productCategories";

export const initialProjectMappingStates = [{
    name: "common.caseId",
    key: "caseId",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.confirmation",
    key: "operation",
    isOptional: false,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.startTime",
    key: "startTime",
    isOptional: false,
    allowedTypes: ["datetime"]
}, {
    name: "common.endTime",
    key: "endTime",
    isOptional: true,
    allowedTypes: ["datetime"]
}, {
    name: "common.machine",
    key: "machine",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.machineType",
    key: "machineType",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.location",
    key: "location",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.role",
    key: "role",
    isOptional: true,
    allowedTypes: ["string"]
}, {
    name: "common.product",
    key: "product",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.productionTime",
    key: "isProduction",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.failure",
    key: "isFailure",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.unplannedPreventiveMaintenance",
    key: "isUnplannedPreventiveMaintenance",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.correctiveMaintenance",
    key: "isCorrectiveMaintenance",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.nonActiveMaintenance",
    key: "isNonActiveMaintenance",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.otherTechnicalLosses",
    key: "isOtherTechnicalLosses",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.materialShortage",
    key: "isMaterialShortage",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.deliveryProblem",
    key: "isDeliveryProblem",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.authorizationShortage",
    key: "isAuthorizationShortage",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.employeeShortage",
    key: "isEmployeeShortage",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.otherOrganizationalLosses",
    key: "isOtherOrganizationalLosses",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.processLosses",
    key: "isProcessLosses",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.qualityLosses",
    key: "isQualityLosses",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
},
{
    name: "common.setup",
    key: "isSetup",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.interruption",
    key: "isInterruption",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.passChange",
    key: "isPassChange",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.link",
    key: "isLink",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.orderSequence",
    key: "passId",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.reason",
    key: "reason",
    isOptional: true,
    allowedTypes: ["integer", "string"]
}, {
    name: "common.objectId",
    key: "object",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.objectType",
    key: "objectType",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.eventId",
    key: "eventId",
    isOptional: true,
    allowedTypes: ["integer", "string"],
}, {
    name: "common.material",
    key: "isPureObject",
    isOptional: true,
    allowedTypes: ["integer", "boolean"]
}, {
    name: "common.yieldMass",
    key: "yieldMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.yield"
}, {
    name: "common.yieldLength",
    key: "yieldLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.yield"
}, {
    name: "common.yieldCount",
    key: "yieldCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.yield"
}, {
    name: "common.scrapMass",
    key: "scrapMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.scrap"
}, {
    name: "common.scrapLength",
    key: "scrapLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.scrap"
}, {
    name: "common.scrapCount",
    key: "scrapCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.scrap"
}, {
    name: "common.caseYieldMass",
    key: "caseYieldMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseYield"
}, {
    name: "common.caseYieldLength",
    key: "caseYieldLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseYield"
}, {
    name: "common.caseYieldCount",
    key: "caseYieldCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseYield"
}, {
    name: "common.caseScrapMass",
    key: "caseScrapMass",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseScrap"
}, {
    name: "common.caseScrapLength",
    key: "caseScrapLength",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseScrap"
}, {
    name: "common.caseScrapCount",
    key: "caseScrapCount",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.caseScrap"
}, {
    name: "common.productCategory1",
    key: "productCategory1",
    isOptional: true,
    allowedTypes: ["integer", "string"],
    group: productCategoryGroup
}, {
    name: "common.productCategory2",
    key: "productCategory2",
    isOptional: true,
    allowedTypes: ["integer", "string"],
    group: productCategoryGroup
}, {
    name: "common.productCategory3",
    key: "productCategory3",
    isOptional: true,
    allowedTypes: ["integer", "string"],
    group: productCategoryGroup
}, {
    name: "common.productCategory4",
    key: "productCategory4",
    isOptional: true,
    allowedTypes: ["integer", "string"],
    group: productCategoryGroup
}, {
    name: "common.productCategory5",
    key: "productCategory5",
    isOptional: true,
    allowedTypes: ["integer", "string"],
    group: productCategoryGroup
}, {
    name: "common.reworkOrder",
    key: "isRework",
    isOptional: true,
    allowedTypes: ["integer", "boolean"],
}, {
    name: "common.orderInProgress",
    key: "isWip",
    isOptional: true,
    allowedTypes: ["integer", "boolean"],
}, {
    name: "common.carbonEmissions",
    key: "carbonMass",
    allowedTypes: ["integer", "float"],
    isOptional: true,
}, {
    name: "common.powerConsumption",
    key: "electricityEnergy",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.energyConsumption",
}, {
    name: "common.gasConsumption",
    key: "gasEnergy",
    isOptional: true,
    allowedTypes: ["integer", "float"],
    group: "common.energyConsumption",
}];


export default function CreateProject() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const navigate = useNavigate();

    const { uploadId } = useParams<{
        uploadId: string,
    }>();

    // Fetch upload including all properties from API
    const [upload, setUpload] = useState<Upload | undefined>();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    useEffect(() => {
        if (uploadId)
            Api.getUpload(uploadId).then((result) => {
                setUpload(result);
                if (name.length === 0) {
                    const name = result.filename.replace(/_/g, " ").substr(0, result.filename.length - result.filenameExtension.length);
                    setName(name);
                }
                setIsInitialized(true);
            });
    }, [uploadId]);

    return <>
        <Spinner isLoading={!isInitialized} />
        {upload && isInitialized && <IdentifyColumns
            name={name}
            mappingStates={cloneDeep(initialProjectMappingStates)}
            onSubmitLabel={"common.continue"}
            onCancelLabel={"common.cancel"}
            upload={upload}
            onCancel={cancelProjectCreation}
            onSubmit={createNewProject}

        />}
    </>;

    async function cancelProjectCreation() {
        if (!uploadId)
            return;

        // we first need to check whether the upload is used by other projects
        const otherProjects = await Api.getProjects({ fields: ["uploadId"] });
        if (otherProjects.some((p) => p.uploadId === uploadId))
            navigate("/projects");
        else {
        // in case it is not used by other projects we delete it
            await Api.deleteUpload(uploadId);
            navigate("/projects");
        }
    }

    async function createNewProject(partialProject: {name: string, eventKeys: EventKeys}) {
        const result = await Api.createProject({
            ...partialProject,
            eventFilters: [],
            uploadId: uploadId!
        });

        session.set({
            projectId: result.id,
            project: undefined,
            eventUpload: undefined,
        });

        const validSettings = makeSettingsValid({
            ...session,
            projectId: result.id,
            project: result,
            eventUpload: upload,
        }, {
            ...defaultValues,
            ...settings,
        });

        settings.set({
            ...(validSettings ?? settings),
            filters: [],
            previewFilters: undefined,
        });

        Global.projectLoadingSpinnerHasBeenShown = false;

        navigate("/projects/" + result.id + "/dashboard");
    }
}
