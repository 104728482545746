import React, { Fragment } from "react";

export type Bar<T> = {
    value: number;
    label?: JSX.Element[] | JSX.Element | string | undefined;
    tooltip?: string;
    data?: T;
}

export function RcaResultsGraph<T>(props: {
    data: Bar<T>[],
    onSelected?: (element: Bar<T> | undefined, idx: number | undefined) => void;
    selectedIndex?: number | number[];
    title?: string;
    minBarWidth?: number;
}) {
    const maxValue = Math.max(...(props.data ?? []).map(b => b.value));
    const minBarWidth = props.minBarWidth ?? 0;

    const additionalStyles = props.onSelected !== undefined ? {
        cursor: "pointer"
    } : undefined;

    const bars = (props.data ?? []).map((b, idx) => {
        return <Fragment key={"row-" + idx}>
            <div className="label" style={additionalStyles} onClick={(e) => {
                if (props.onSelected)
                    if (idx === props.selectedIndex)
                        props.onSelected(undefined, undefined);
                    else
                        props.onSelected(b, idx);

                e.stopPropagation();
            }}>
                {b.label}
            </div>
            <div className="barContainer">
                <div className="bar">
                    <div
                        className={props.selectedIndex === idx ? "selected" : undefined}
                        style={{
                            width: `max(${minBarWidth}px, ${Math.round((b.value / maxValue) * 100)}%)`,
                            ...additionalStyles
                        }}
                        onClick={(e) => {
                            if (props.onSelected)
                                if (idx === props.selectedIndex)
                                    props.onSelected(undefined, undefined);
                                else
                                    props.onSelected(b, idx);

                            e.stopPropagation();
                        }}
                    ></div>
                </div>
            </div>
        </Fragment>;
    });

    if (!bars.length)
        return <></>;

    return <>
        {props.title !== undefined && <h1 className="mbl">{props.title}</h1>}
        <div
            onClick={() => {
                if (props.onSelected)
                    props.onSelected(undefined, undefined);
            }}
            className="rcaResultsGraph">
            {bars}
        </div>
    </>;
}
