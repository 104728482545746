import { useContext, useEffect, useState } from "react";
import { disableAllCalcOptions, ColumnInfoNg, DfgRequest } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { GroupingKeys, NodeActivitySchema } from "../models/Dfg";
import { EventFilter } from "../models/EventFilter";
import { Datastores } from "../utils/Datastores";
import { groupMapping } from "../utils/GroupingUtils";
import { isTerminalNode } from "../utils/DfgUtils";
import { useMountedState } from "./UseMounted";
import { ignoreCancelledRequest } from "../api/Api";

/**
 * This hook provides the possible values that correspond to the group provided.
 * Re-implemented to use the dfg endpoint in lieu of the distinct values endpoint, 
 * because we've started adding non-column-mapped attributes to the node activities.
 */
export function useGroupEvents(groupingKey: GroupingKeys | undefined, filters: EventFilter[] | undefined): [
    ColumnInfoNg[] | undefined,
    (keyof NodeActivitySchema)[] | undefined,
    boolean,
] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const isMounted = useMountedState();

    const [subscriptionId] = useState<number>(() => { return Datastores.dfg.getSubscriptionId(); });
    useEffect(() => { return () => { Datastores.dfg.cancelSubscription(subscriptionId); }; }, []);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<{
        events: ColumnInfoNg[] | undefined,
        attributes: string[] | undefined,
    }>({
        events: undefined,
        attributes: undefined,
    });

    useEffect(() => {
        const key = groupingKey ?? settings.groupingKey;
        if (!session.project?.eventKeys)
            return;

        const requestOptions: DfgRequest = {
            ...disableAllCalcOptions,
            uploadId: session.project.uploadId!,
            uploads: session.project?.uploads,
            eventKeys: {
                ...session.project.eventKeys,
                activityKeysGroup: key,
            },
            eventFilters: filters ?? settings.filters,
            calculateTimeAndFreqStats: true,
            calculateActivityValues: true,
            calculateNodes: true,
            calculateRoles: true,
        };

        if (!Datastores.dfg.isCached(requestOptions))
            setIsLoading(true);

        Datastores.dfg.get(requestOptions, subscriptionId).then((response) => {
            if (!isMounted())
                return;

            // Fetch attributes for selected grouping key
            const attributes = groupMapping.find(g => g.groupKey === key)!.attributes;

            const values: ColumnInfoNg[] = response.nodes.filter(n => !isTerminalNode(n)).map(node => {
                return {
                    id: node.id,
                    value: attributes.map(a => (node.activityValues ?? {})[a]?.value),
                };
            });

            setData({
                events: values,
                attributes
            });
        }).catch(ignoreCancelledRequest).finally(() => {
            if (isMounted())
                setIsLoading(false);
        });
    }, [
        session.project,
        settings.apiRetry,
        groupingKey,
        JSON.stringify(filters),
    ]);

    return [data.events, data.attributes as (keyof NodeActivitySchema)[] | undefined, isLoading];
}
