import React, { useContext } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { NodeDetailLevel } from "../../models/Dfg";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";

export function GraphDetailedControls() {
    const settings = useContext(SettingsContext);
    const location = useLocation();

    return <div className="section dfgDetail">
        <div className="title">
            {i18n.t("explorer.eventTypes")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["ActivitiesView"])} className="mls" />
        </div>
        <div className="buttons-2col">
            <button data-testid="button-nodes-detailled" id="button-nodes-detailled" onClick={() => { settings.setGraph({ nodeDetailLevel: NodeDetailLevel.Detailed }); }} className={settings.graph.nodeDetailLevel === NodeDetailLevel.Detailed ? "active" : ""}>{i18n.t("explorer.eventTypesDetailed")}</button>
            <button data-testid="button-nodes-simple" id="button-nodes-simple" onClick={() => { settings.setGraph({ nodeDetailLevel: NodeDetailLevel.Simple }); }} className={settings.graph.nodeDetailLevel === NodeDetailLevel.Simple ? "active" : ""}>{i18n.t("explorer.eventTypesSimple")}</button>
        </div>
    </div>;
}