import React, { useState } from "react";
import Toast, { ToastTypes } from "../../components/toast/Toast";
import i18n from "../../i18n";
import { GroupingKeys } from "../../models/Dfg";
import { EventKeys } from "../../models/EventKeys";
import { Upload } from "../../models/Upload";
import AssignColumns, { MappingStateType } from "./AssignColumns";
import { productCategoryGroup } from "./CreateProject";

type IdentifyColumnsPropsType = {
    name?: string,
    description?: string,
    hasName?: boolean,
    mappingStates: MappingStateType[],
    onSubmitLabel: string,
    onCancelLabel: string,
    upload: Upload,
    onSubmit: (partialProject: {name: string, description: string, eventKeys: EventKeys}) => void,
    onCancel: () => void
}

export default function IdentifyColumns(props: IdentifyColumnsPropsType) {
    const hasName: boolean = props.hasName ?? !!props.name;

    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const [name, setName] = useState<string>(props.name || "");
    const [description, setDescription] = useState<string>(props.description || "");


    const [mappingStates, setMappingStates] = useState<MappingStateType[]>(props.mappingStates);

    const continueButtonDisabled = !hasChanges || mappingStates.some(m => !m.isOptional && m.colIdx == undefined) || (!name?.length && hasName);
    const [toastVisible, toastType, toastMessage] = getToastMessage();


    return <div className="identifyColumns">
        <h1>{i18n.t("identifyColumns.title")}</h1>

        <Toast visible={toastVisible} type={toastType}>{toastMessage}</Toast>
        <div className={hasName ? "columnForm columnForm100Px spacer" : "spacer"}>
            {hasName && <label>{i18n.t("identifyColumns.projectName")}</label>}
            {hasName && <input value={name} onChange={(e) => { setHasChanges(true); setName(e.target.value); }} className="input light" type="text" placeholder={i18n.t("identifyColumns.projectName").toString()} />}
        </div>
        {hasName && <div className={"columnForm columnForm100Px spacer"}>
            { <label>{i18n.t("identifyColumns.projectDescription")}</label>}
            {<textarea value={description} onChange={(e) => { setHasChanges(true); setDescription(e.target.value); }} rows={4} className="input light" placeholder={i18n.t("identifyColumns.projectDescription").toString()} />}
        </div>}

        <div className={"columnForm spacer"}>
            <div className="proceed">
                <button
                    className="shortcutButton"
                    onClick={() => {
                        props.onCancel();
                    }}
                >
                    {<span>{i18n.t(props.onCancelLabel)}</span>}
                </button>
                <button
                    className="shortcutButton"
                    disabled={continueButtonDisabled}
                    onClick={postProject}
                >
                    {<span>{i18n.t(props.onSubmitLabel)}</span>}
                </button>
            </div>
        </div>

        <AssignColumns
            mappingStates={mappingStates}
            upload={props.upload}
            onUpdate={(newMappingStates) => {
                setMappingStates(newMappingStates);
                setHasChanges(true);
            }}
        />
    </div>;

    async function postProject() {
        if (!props.upload?.meta?.attributes)
            return;

        const keyMapping: any = {};

        for (const c of mappingStates) {
            if (c.colIdx === undefined)
                continue;

            keyMapping[c.key] = props.upload?.meta?.attributes[c.colIdx].name;
        }

        // Set product categories as the API expects it
        keyMapping["productCategories"] = mappingStates.filter(m => m.group === productCategoryGroup && m.colIdx !== undefined).map(m => props.upload?.meta?.attributes[m.colIdx!].name);

        const eventKeys = keyMapping as EventKeys;
        eventKeys.activityKeysGroup = GroupingKeys.None;

        const newProject = {
            eventKeys,
            name,
            description,
            eventFilters: []
        };

        props.onSubmit(newProject);
    }

    function getToastMessage(): [boolean, ToastTypes, string] {
        if (name?.length === 0 && hasName)
            return [true, ToastTypes.Info, i18n.t("identifyColumns.requireName")];
        else {
            const stillRequiredState = mappingStates.find(m => !m.isOptional && m.colIdx == undefined)?.name;
            if (stillRequiredState)
                return [true, ToastTypes.Info, i18n.t("identifyColumns.selectRequiredColumn", { column: i18n.t(stillRequiredState) })];
            const stillOptionalState = mappingStates.find(m => m.isOptional && m.colIdx == undefined)?.name;
            if (stillOptionalState)
                return [false, ToastTypes.Info, i18n.t("identifyColumns.selectOptionalColumn", { column: i18n.t(stillOptionalState) })];
            else
                return [false, ToastTypes.Info, ""];
        }
    }
}
