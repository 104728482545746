import { capitalize } from "lodash";
import React, { useRef, useContext, useEffect, useState } from "react";
import { TimedeltaHistogramSchema } from "../../../../models/ApiTypes";
import { SessionContext, SessionType } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { numDefaultBinCount } from "../../../../Global";
import { useNumericCaseHistogram } from "../../../../hooks/UseNumericCaseHistogram";
import i18n from "../../../../i18n";
import { EventFilter, caseYieldFilterRenderType } from "../../../../models/EventFilter";
import { buildNumericAttributeRangeFilter } from "../../../../utils/FilterBuilder";
import { Formatter } from "../../../../utils/Formatter";
import { baseQuantities, quantities, Quantity } from "../../../../utils/Quantities";
import BarChartSelector from "../../../bar-chart-selector/BarChartSelector";
import Dropdown from "../../../dropdown/Dropdown";
import Spinner from "../../../spinner/Spinner";
import { IUnitInput, UnitInput } from "../../../unit-input/UnitInput";

export type CaseYieldFilterEditorProps = React.PropsWithChildren<{
    initialValue: EventFilter | undefined;
    onUpdate?: (e: EventFilter | undefined) => void;
}>;

export default function CaseYieldFilterEditor(props: CaseYieldFilterEditorProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const inputFrom = useRef<IUnitInput>(null);
    const inputTo = useRef<IUnitInput>(null);

    const [isDomainInitialized, setIsDomainInitialized] = useState(false);

    const fromFilter = props.initialValue?.filters ? (props.initialValue.filters[0]?.caseAttributeFloat ?? props.initialValue.filters![0]?.caseAttributeInt) : undefined;
    const toFilter = props.initialValue?.filters ? (props.initialValue.filters[1]?.caseAttributeFloat ?? props.initialValue.filters![1]?.caseAttributeInt) : undefined;

    const kpiOptions = buildKpiOptions(session);
    const isFilterAvailable = kpiOptions.length > 0;

    const [selectedKpi, setSelectedKpi] = useState(() => {
        if (props.initialValue !== undefined)
            return (props.initialValue as any).kpi;

        return isFilterAvailable ? kpiOptions[0].value : undefined;
    });


    // Initialize Quantity
    const [selectedQuantity, setSelectedQuantity] = useState<string>(() => {
        // Determine initial quantity. If this is a new filter, go with caseBaseQuantity.
        // Otherwise determine it based on the attribute the filter considers.
        if (!props.initialValue)
            return kpiOptions.find(o => o.value === selectedKpi)?.quantities[0].baseQuantity ?? settings.quantity;

        // We're editing an existing filter
        return (props.initialValue as any).quantity;
    });

    const quantity = kpiOptions.find(o => o.value === selectedKpi)?.quantities?.find(q => q.baseQuantity === selectedQuantity) ?? quantities[0];

    // Initialize histograms...
    const statistic = `${selectedKpi}${capitalize(quantity?.baseQuantity)}`;

    // Histogram covering all cases there are. We need to do this to get the bin limits.
    const [fullHistogram, isFullHistogramLoading] = useNumericCaseHistogram({
        eventFilters: [],
        statistic,
        nBins: numDefaultBinCount,
    }, {
        disable: !isFilterAvailable
    });

    const minRange = fullHistogram?.bins ? fullHistogram?.bins[0] : undefined;
    const maxRange = fullHistogram?.bins ? fullHistogram?.bins[fullHistogram?.bins.length - 1] : undefined;

    // This histogram applies all filters (expect itself)
    const eventFilters = settings.filters.filter((_, idx) => !settings.filterEditor.editFilter || settings.filterEditor.editFilterIndex !== idx);
    const [filteredHistogram, isFilteredHistogramLoading] = useNumericCaseHistogram({
        eventFilters,
        statistic,
        binsToUse: fullHistogram?.bins
    }, {
        disable: isFullHistogramLoading || fullHistogram === undefined || !isFilterAvailable,
    });

    // Initialize domain (i.e. selection in the graph)
    const [domain, setDomain] = useState<[number, number] | undefined>(() => {
        if (props.initialValue === undefined)
            return undefined;

        const min = (props.initialValue.filters![0].caseAttributeFloat ?? props.initialValue.filters![0].caseAttributeInt)!.ge!;
        const max = (props.initialValue.filters![1].caseAttributeFloat ?? props.initialValue.filters![1].caseAttributeInt)!.le!;

        return [min, max];
    });

    useEffect(initializeDomain, [fullHistogram]);

    const formatterParams = {
        locale: session.numberFormatLocale,
        digits: 1,
        followUnits: 1,
    };

    const bars = getBarData(isFilteredHistogramLoading ? undefined : filteredHistogram);

    const quantityOptions = kpiOptions.find(o => o.value === selectedKpi)?.quantities.map(o => {
        return {
            label: i18n.t(o.name),
            value: o.id,
        };
    }) ?? [];

    const selectedQuantityUnit = quantities.find(q => q.baseQuantity === selectedQuantity && !q.isFrequency)?.unit ?? Formatter.units.weight;

    const containerRef = useRef<HTMLDivElement>(null);
    const numTicks = (containerRef.current?.clientWidth ?? 1000) / 64;
    const xTicks = isFullHistogramLoading || !fullHistogram?.bins ? undefined :
        getTickValues(minRange!, maxRange!, numTicks);

    const fromValue = domain ? domain[0] : fromFilter ? fromFilter!.ge! : undefined;
    const toValue = domain ? domain[1] : toFilter ? toFilter!.le! : undefined;

    const isInitializing = isFullHistogramLoading || isFilteredHistogramLoading || !isDomainInitialized || bars === undefined || xTicks === undefined;

    if (isFilterAvailable)
        return <div className="filterEditorPage">
            <div className="caseYieldFilterEditor" ref={containerRef}>
                <Spinner isLoading={isInitializing} />
                {!isInitializing && <div className="tabPage">
                    <div className="controlHeader">
                        <h3>
                            {i18n.t("common.kpi")}
                        </h3>
                        <Dropdown
                            className="dropdownLight"
                            options={kpiOptions}
                            isSearchable={false}
                            value={kpiOptions.find(o => o.value === selectedKpi)!}
                            onChange={(e) => {
                                const kpi = e!.value as string;
                                if (kpi !== selectedKpi) {
                                    setSelectedKpi(kpi);
                                    const kpiQuantities = kpiOptions.find(o => o.value === kpi)!.quantities;
                                    if (!kpiQuantities.some(q => q.baseQuantity === selectedQuantity))
                                        setSelectedQuantity(kpiQuantities[0].baseQuantity);

                                    setIsDomainInitialized(false);
                                    setDomain(undefined);
                                }
                            }}
                        />

                        <h3>
                            {i18n.t("common.quantityUnit")}
                        </h3>
                        <Dropdown
                            className="dropdownLight"
                            options={quantityOptions}
                            isSearchable={false}
                            isDisabled={quantityOptions.length <= 1}
                            value={quantityOptions.find(o => o.value === quantity.baseQuantity)!}
                            onChange={(e) => {
                                const quantity = e?.value as string;
                                if (selectedQuantity !== quantity) {
                                    setSelectedQuantity(quantity);
                                    setIsDomainInitialized(false);
                                    setDomain(undefined);
                                }
                            }}
                        />

                        <label>
                            {i18n.t("common.from")}
                        </label>
                        <UnitInput
                            ref={inputFrom}
                            initialValue={fromValue ? { value: fromValue } : undefined}
                            numDigits={3}
                            unit={selectedQuantityUnit}
                            min={fullHistogram?.bins?.length ? fullHistogram.bins[0] : undefined}
                            max={fullHistogram?.bins?.length ? fullHistogram.bins[fullHistogram.bins.length - 1] : undefined}
                            onChange={(v) => {
                                if (domain === undefined)
                                    return;

                                const newDomain: [number, number] = [v, domain[1]];
                                setDomain(newDomain);
                                onUpdate(selectedKpi, selectedQuantity, newDomain);
                            }}
                        />

                        <label>
                            {i18n.t("common.to")}
                        </label>
                        <UnitInput
                            ref={inputTo}
                            initialValue={toValue ? { value: toValue } : undefined}
                            unit={selectedQuantityUnit}
                            min={fullHistogram?.bins?.length ? fullHistogram.bins[0] : undefined}
                            max={fullHistogram?.bins?.length ? fullHistogram.bins[fullHistogram.bins.length - 1] : undefined}
                            numDigits={3}
                            onChange={(v) => {
                                if (domain === undefined)
                                    return;

                                const newDomain: [number, number] = [domain[0], v];
                                setDomain(newDomain);
                                onUpdate(selectedKpi, selectedQuantity, newDomain);
                            }}
                        />
                    </div>

                    <BarChartSelector
                        data={bars!}
                        xTickValues={xTicks}
                        brushDomain={domain ?? [0, 0]}
                        xDomain={[minRange!, maxRange!]}
                        yLabel={i18n.t("common.caseCount").toString()}
                        xTickFormatter={(value) => quantity.unit.formatter(value, formatterParams)}
                        yTickFormatter={(value) => Formatter.formatNumber(value, 2, session.numberFormatLocale)}
                        barLabels={filteredHistogram?.counts.map(v => v === 0 ? "" : Formatter.formatNumber(v, 2, session.numberFormatLocale))}
                        onBrushDomainChange={(newDomain) => {
                            inputFrom.current?.set({ value: newDomain[0] });
                            inputTo.current?.set({ value: newDomain[1] });
                            onUpdate(selectedKpi, selectedQuantity, newDomain);
                        }}
                    />
                </div>}
            </div>
            {props.children}
        </div>;

    return <div className="noKpisAvailable">
        {i18n.t("common.noKpisAvailable")}
        <div>
            {i18n.t("common.noCaseStatsAvailable")}
        </div>
    </div>;

    function initializeDomain() {
        if (isDomainInitialized || fullHistogram === undefined || !fullHistogram.bins?.length)
            return;

        if (domain !== undefined) {
            setIsDomainInitialized(true);
            return;
        }

        const minBin = Math.min(...(fullHistogram?.bins ?? [0]));
        const maxBin = Math.max(...(fullHistogram?.bins ?? [0]));

        setDomain([minBin, maxBin]);
        inputFrom.current?.set({ value: minBin });
        inputTo.current?.set({ value: maxBin });
        setIsDomainInitialized(true);

        if (props.onUpdate)
            props.onUpdate(undefined);
    }

    function onUpdate(kpi: string, quantity: string, values: [number, number]) {
        const columnPrefixes: { [id: string]: string } = {
            caseYield: "caseYield",
            caseScrap: "scrap",
            caseOutput: "caseYield",
        };

        const columnName = columnPrefixes[kpi] + capitalize(quantity);

        const filter = buildNumericAttributeRangeFilter(
            session.project?.eventKeys ? (session.project?.eventKeys[columnName] as string) : undefined,
            Math.min(...values),
            Math.max(...values),
            session);
        if (filter) {
            filter.renderType = caseYieldFilterRenderType;
            (filter as any).kpi = selectedKpi;
            (filter as any).quantity = selectedQuantity;
        }

        if (props.onUpdate)
            props.onUpdate(filter);
    }
}

function getBarData(filteredHistogram: TimedeltaHistogramSchema | undefined) {
    if (!filteredHistogram?.bins?.length)
        return undefined;

    // Blue bars
    const bars = Array.from({ length: filteredHistogram.counts.length }).map((_, i) => {
        return {
            x: (filteredHistogram.bins[i] + filteredHistogram.bins[i + 1]) / 2,
            y: filteredHistogram.counts[i]
        };
    });

    return bars;
}

export function getTickValues(min: number, max: number, maxNumTicks: number) {
    const delta = Math.abs(max - min);

    const stepSize = (() => {
        const niceStepSizes = [
            100000000, 50000000, 25000000, 20000000, 10000000, 5000000, 2500000, 2000000,
            1000000, 500000, 250000, 200000, 100000, 50000, 25000, 20000,
            10000, 5000, 2500, 2000, 1000, 500, 250, 200, 100, 50, 25, 20, 10, 5, 2, 1];
        for (let i = 1; i < niceStepSizes.length; i++) {
            const stepSize = niceStepSizes[i];

            const numTicks = delta / stepSize;
            if (numTicks > maxNumTicks)
                return niceStepSizes[i - 1];
        }

        return niceStepSizes[niceStepSizes.length - 1];
    })();

    // Clip range to step size multiples
    const low = Math.floor(min / stepSize) * stepSize;
    const high = Math.ceil(max / stepSize) * stepSize;

    // Generate ticks
    const result: number[] = [];
    for (let tick = low; tick <= high; tick += stepSize)
        result.push(tick);

    return result;
}


function buildKpiOptions(session: SessionType) {
    const kpiOptions: {
        label: string,
        value: string,
        quantities: Quantity[],
    }[] = [];

    const yieldQuantities = baseQuantities.filter(q => session.project?.eventKeys!["caseYield" + capitalize(q)] !== undefined).map(q => quantities.find(x => x.baseQuantity === q && !x.isFrequency)).filter(q => q !== undefined) as Quantity[];
    if (yieldQuantities.length > 0) {
        kpiOptions.push({
            label: i18n.t("common.yield"),
            value: "caseYield",
            quantities: yieldQuantities,
        });

        // Uncomment once issue 993 is closed!
        // https://gitlab.com/oniqofficial/general/oniq/-/issues/993
        // Also, update common.noCaseStatsAvailable (and add "scrap data")

        // kpiOptions.push({
        //     label: i18n.t("common.output"),
        //     value: "caseOutput",
        //     quantities: yieldQuantities,
        // });
    }

    // const scrapOption ={
    //     label: i18n.t("common.scrap"),
    //     value: "caseScrap",
    //     quantities: baseQuantities.filter(q => session.project?.eventKeys!["scrap" + capitalize(q)] !== undefined).map(q => quantities.find(x => x.baseQuantity === q && !x.isFrequency)).filter(q => q !== undefined) as Quantity[],
    // };
    // if (scrapOption.quantities.length > 0)
    //     kpiOptions.push(scrapOption);

    return kpiOptions;
}
