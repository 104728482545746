import React, { useContext } from "react";
import { MatrixComparisonSelector } from "../../components/controls/MatrixComparisonSelector";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import i18n, { getFirstExistingSpotlight } from "../../i18n";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext";
import { KpiSortOrderSwitch } from "../../components/controls/KpiControlPrimitives";
import {
    MatrixComparison,
    SettingsContext,
    SortByType,
} from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { MatrixSetupStats } from "../../components/matrix/stats/MatrixSetupStats";


import { KpiComparisons } from "../../contexts/ContextTypes";
import {
    KpiControls,
    getValidKpiControlSettings,
} from "../../components/controls/KpiControls";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { useUpload } from "../../hooks/UseUpload";
import { KpiMatrixHighlightControls } from "../../components/controls/HighlightControls";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { SetupMatrixCellProps } from "../../components/matrix/SetupMatrixCell";
import { MachineSelector } from "../../components/controls/MachineSelector";

import { LossReasonsGraph } from "../LossReasons/LossReasonsGraph";

export function WorkplaceLossAnalysis() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const breadcrumbs = [
        {
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        },
        {
            label: "common.analysesView.lossOverview",
        },
    ];

    return (
        <TabbedView
            enableOnlyTimespan={false}
            subtitle={<MachineSubtitleDropdown />}
            breadcrumbs={breadcrumbs}
            pages={[
                {
                    tabTitle: "",
                    tabSlug: "analysis",
                    spotlightId: getFirstExistingSpotlight(session, [
                        `Setup-Matrix-${
                            session.project?.settings?.setupMatrixType ?? "goodToGood"
                        }`,
                        "Setup-Matrix",
                    ]),
                    activator: (preferences) => {
                        const temp = viewSettingsInitialization(
                            session,
                            settings,
                            preferences,
                            { kpi: { comparisons: KpiComparisons.None } },
                            {
                                sortBy: [
                                    SortByType.Kpi,
                                    SortByType.Alphabetical,
                                    SortByType.Frequency,
                                ],
                                kpis: [KpiTypes.TechnicalLosses],
                                statistics: [StatisticTypes.Mean],
                            }
                        );

                        if (!temp) return;

                        settings.mergeSet(
                            ObjectMerger.mergeObjects([
                                temp,
                                getValidKpiControlSettings(session, settings, {
                                    kpis: [KpiTypes.TechnicalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.OrganizationalLosses],
                                    hideStatistics: [StatisticTypes.Sum, StatisticTypes.Variance],
                                }),
                            ])
                        );
                    },
                    controller: pageController(session),
                    stats: settings.kpiMatrix.machineName ? <MatrixSetupStats /> : undefined,
                    isVisible: true,
                    content: (
                        <LossReasonsGraph />
                    ),
                    selectionTrigger: (settings) => {
                        return (
                            (settings.selection.matrixElement as SetupMatrixCellProps)
                                ?.transition !== undefined
                        );
                    },
                },
            ]}
        />
    );
}

function pageController(session: SessionContextType) {
  
    const [uploadPlan] = useUpload(
        session.project?.uploadIdPlan ?? "",
        { disable: session.project?.uploadIdPlan === undefined }
    );

    const allowPlanningComparison =
    uploadPlan?.meta?.attributes === undefined
        ? undefined
        : uploadPlan.meta.attributes.some(
            (a) => a.name === session.project?.eventKeys?.product
        );
    const enabledComparisons = allowPlanningComparison
        ? [
            MatrixComparison.None,
            MatrixComparison.Planning,
            MatrixComparison.AbsoluteDeviation,
            MatrixComparison.RelativeDeviation,
        ]
        : [MatrixComparison.None];

    return (
        <div className="controls">
            <KpiControls
                kpis={[KpiTypes.TechnicalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.OrganizationalLosses]}
                hideStatistics={[StatisticTypes.Variance, StatisticTypes.Mean, StatisticTypes.Median]}
        
            />

            <KpiMatrixHighlightControls label="common.highlights" />

            <MatrixComparisonSelector enabledComparisons={enabledComparisons} />

            <KpiSortOrderSwitch
        
                dropdownOptions={[
                    {
                        label: i18n.t("common.setupTime"),
                        value: SortByType.Kpi,
                    },
                    {
                        label: i18n.t("common.actionFrequency"),
                        value: SortByType.Frequency,
                    },
                    {
                        label: i18n.t("common.alphabetical"),
                        value: SortByType.Alphabetical,
                    },
                ]}
            />
        </div>
    );
}

function MachineSubtitleDropdown() {
    const settings = useContext(SettingsContext);

    const [showTitle, setShowTitle] = React.useState(true);

    return !settings.kpiMatrix.machineName ? (
        <div className="fixedHeight text">
            {i18n.t("common.workplaceSelection").toString()}
        </div>
    ) : (
        <>
            {showTitle && (
                <div
                    className="fixedHeight text"
                    onClick={() => setShowTitle(false)}
                    style={{ cursor: "pointer" }}
                >
                    {settings.kpiMatrix.machineName === "all" ? i18n.t("common.allMachines") : settings.kpiMatrix.machineName}
                    <svg className="svg-icon xxsmall rotate180 ml">
                        <use xlinkHref="#radix-chevron-up"></use>
                    </svg>
                </div>
            )}
            {!showTitle && (
                <div className="fixedHeight">
                    <MachineSelector
                        focusInitially={true}
                        bare={true}
                        onChange={() => setShowTitle(true)}
                        onBlur={() => setShowTitle(true)}
                        canSelectAll={true}
                        hidePlaceHolder={true}
                    />
                </div>
            )}
        </>
    );
}
