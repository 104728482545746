import { BaseGraph, NodeRoles } from "../models/Dfg";

const fakeEmissionsPerTime = 0.002;
const fakeEmissionsPerTimeEdge = fakeEmissionsPerTime * 0.02;
const fakeEnergyPerEmission = 2.3;

export function addEnergyStatsToGraph(dfg: BaseGraph) {
    const startNodes = dfg.nodes.filter(n => n.role === NodeRoles.Start).map((n) => n.id);
    const endNodes = dfg.nodes.filter(n => n.role === NodeRoles.End).map((n) => n.id);
    dfg.edges = dfg.edges.map((e) => {
        if (e.timeStatistics === undefined || startNodes.includes(e.from) || endNodes.includes(e.to))
            return e;
        const totalEmissions = fakeEmissionsPerTimeEdge * ((e.timeStatistics?.sum ?? 0) + 60);
        const totalEnergyConsumption = totalEmissions * fakeEnergyPerEmission;
        const emissionsMass = (e.yieldMassStatistics?.sum !== undefined && e.yieldMassStatistics?.sum !== 0) ? totalEmissions / e.yieldMassStatistics?.sum : undefined;
        const emissionsLength = (e.yieldLengthStatistics?.sum !== undefined && e.yieldLengthStatistics?.sum !== 0) ? totalEmissions / e.yieldLengthStatistics?.sum : undefined;
        const emissionsCount = (e.yieldCountStatistics?.sum !== undefined && e.yieldCountStatistics?.sum !== 0) ? totalEmissions / e.yieldCountStatistics?.sum : undefined;
        return {
            ...e,
            kpis: {
                ...e.kpis,
                carbonEmissions: totalEmissions,
                carbonPerYieldMass: emissionsMass,
                carbonPerYieldLength: emissionsLength,
                carbonPerYieldCount: emissionsCount,
                energyConsumption: totalEnergyConsumption,
                energyPerYieldMass: emissionsMass !== undefined ? emissionsMass * fakeEnergyPerEmission : undefined,
                energyPerYieldLength: (emissionsLength !== undefined) ? emissionsLength * fakeEnergyPerEmission : undefined,
                energyPerYieldCount: (emissionsCount !== undefined) ? emissionsCount * fakeEnergyPerEmission : undefined,
            }
        };
    });

    // we leave those out for now and may switch them on again in the future
    // const materialNodes = dfg.nodes.filter(n => n.role === NodeRoles.Start && n.objects?.length === 0).map((n) => {
    //     return {
    //         id: n.id + " material",
    //         role: NodeRoles.CarbonScope3,
    //         name: "Material",
    //     } as Node;
    // });

    // const materialEdges = dfg.edges.filter(e => (startNodes.includes(e.from) && (e.objectType === CASE_OBJECT_TYPE || e.objectType === undefined))).map((e) => {
    //     return {
    //         ...e,
    //         from: e.from + " material",
    //         to: e.from,
    //         objectType: CASE_OBJECT_TYPE
    //     };
    // });

    // dfg.nodes.push(...materialNodes);
    // dfg.edges.push(...materialEdges);

    return dfg;
}
