import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import { SessionContext } from "../../contexts/SessionContext";
import { defaultValues, SettingsContext } from "../../contexts/SettingsContext";
import { EventKeys } from "../../models/EventKeys";
import { Project } from "../../models/Project";
import { Upload } from "../../models/Upload";
import { Datastores } from "../../utils/Datastores";
import { makeSettingsValid } from "../../utils/Initializers";
import { MappingStateType } from "./AssignColumns";
import { initialProjectMappingStates } from "./CreateProject";
import IdentifyColumns from "./IdentifyColumns";
import { Api } from "../../api/Api";

export default function EditProject() {
    const navigate = useNavigate();

    const { projectId } = useParams<{
        projectId: string,
    }>();

    const session = useContext(SessionContext);
    session.setProject(projectId);

    const settings = useContext(SettingsContext);

    const [project, setProject] = useState<Project | undefined>();

    const [subscriptionId, setSubscriptionId] = useState<number>(0);
    useEffect(() => {
        const subscriptionId = Datastores.getUploadRawEvents.getSubscriptionId();
        setSubscriptionId(subscriptionId);

        return () => {
            Datastores.getUploadRawEvents.cancelSubscription(subscriptionId);
        };
    }, []);

    // Fetch upload including all properties from API
    const [upload, setUpload] = useState<Upload | undefined>();
    const [mappingStates, setMappingStates] = useState<MappingStateType[]>(cloneDeep(initialProjectMappingStates));
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    // Project ID is only present if we're re-visiting this page from an existing
    // project.
    useEffect(() => {
        if (projectId)
            Api.getProject(projectId).then((p) => {
                setProject(p);
                setName(p.name);
                setDescription(p?.description ?? "");

                if (p.uploadId) {
                    Api.getUpload(p.uploadId).then((upload) => {
                        setUpload(upload);
                    });


                    Datastores.getUploadRawEvents.get({
                        id: p.uploadId,
                        offset: 0,
                        limit: 10
                    }, subscriptionId).then((preview) => {

                        const previewColumns = preview.length ? Object.keys((preview as any)[0]) : undefined;

                        // Build mapping states from eventKeys
                        const newMappingState = mappingStates.map(s => {
                            const assignedColumn = p.eventKeys![s.key];
                            const columnIdx = previewColumns?.findIndex(c => c === assignedColumn);

                            if (!assignedColumn || columnIdx === undefined || columnIdx < 0)
                                return s;

                            return {
                                ...s,
                                colIdx: columnIdx,
                            };
                        });

                        (p.eventKeys?.productCategories ?? []).forEach((category, idx) => {
                            const categoryName = "common.productCategory" + (idx + 1).toString();
                            const columnIdx = previewColumns?.findIndex(c => c === category);

                            const ms = newMappingState.find(m => m.name === categoryName);
                            if (ms)
                                ms.colIdx = columnIdx;
                        });

                        setMappingStates(newMappingState);
                        setIsInitialized(true);
                    });
                }
            });
    }, [projectId]);

    return <>
        <Spinner isLoading={!isInitialized} />
        {upload && isInitialized && <IdentifyColumns
            name={name}
            description={description}
            mappingStates={mappingStates}
            onSubmitLabel={"identifyColumns.buttonUpdate"}
            onCancelLabel={"identifyColumns.buttonCancel"}
            upload={upload}
            onCancel={cancelUpdateProject}
            onSubmit={updateProject}
        />}
    </>;

    function cancelUpdateProject() {
        navigate("/projects/" + projectId + "/dashboard");
    }

    async function updateProject(partialProject: {name: string, description: string, eventKeys: EventKeys}) {
        if (project) {
            const result = await Api.updateProject({
                ...project,
                name: partialProject.name,
                description: partialProject.description,
                eventKeys: partialProject.eventKeys,
                eventFilters: []
            });

            const newSession = {
                ...session,
                projectId: result.id,
                project: undefined,
                objectValues: undefined,
            };
            session.set(newSession);

            Datastores.flushAllCaches();

            const newSettings = makeSettingsValid(newSession, settings) ?? settings;
            newSettings.rcaStates = { ...defaultValues.rcaStates };
            newSettings.selection = { };
            settings.set(newSettings);

            navigate("/projects/" + projectId + "/dashboard");
        }
    }
}
