import { useEffect, useRef, useState } from "react";
import colors from "../../colors.json";

// The root element defaults to 16px, so this converts from rem to px
export const defaultChartMargin = colors.$defaultMargin * 16;

export function useGridResize(elementRef: React.RefObject<HTMLDivElement>, numElements: number, defaultWidth?: number, defaultHeight?: number, margin?: number) {
    const [size, setSize] = useState<{
        width: number,
        height: number
    } | undefined>(elementRef.current?.getBoundingClientRect());

    const observerRef = useRef(new ResizeObserver(elementRef => {
        const { width, height } = elementRef[0].contentRect;
        setSize({ width, height });
    }));

    useEffect(() => {
        if (observerRef.current && elementRef.current)
            observerRef.current.observe(elementRef.current);

        return () => {
            if (observerRef.current)
                observerRef.current.disconnect();
        };
    }, [
        elementRef,
        observerRef
    ]);

    if (!size)
        return [undefined, undefined];

    // Lookup: Number of elements => Number of elements per row
    const layouts = [1, 1, 2, 2, 2, 3, 3, 3, 3, 3];
    const elementsPerRow = layouts[numElements] ?? 3;
    const numRows = Math.ceil(numElements / elementsPerRow);

    const chartWidth = size.width / elementsPerRow;
    const chartHeight = size.height / numRows;

    const newSize = [
        defaultWidth ?? Math.max(0, chartWidth - (margin ?? defaultChartMargin)),
        defaultHeight ?? Math.max(0, chartHeight - (margin ?? defaultChartMargin)),
    ];

    return newSize;
}
