import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Global from "../Global";
import { lowerCardinality } from "./Sanitizers";

export function initSentry() {
    Sentry.init({
        dsn: Global.sentry.dsn,
        integrations: [
            new Integrations.BrowserTracing({
                beforeNavigate: context => {
                    return {
                        ...context,
                        name: lowerCardinality(location.pathname)
                    };
                },
                tracingOrigins: [/^.*\.oniq\.dev/, "iqa.oniq.com"]
            })
        ],
        tracesSampleRate: Global.sentry.tracesSampleRate,
        environment: Global.sentry.environment,
        release: Global.sentry.release,
    });
}

export const SENTRY_DEFAULT_GROUP = "{{ default }}";
