import React, { useContext, useImperativeHandle, useMemo, useRef } from "react";
import { Variant } from "../../models/ApiTypes";
import i18n from "../../i18n";
import { ScrollHost } from "../scrollhost/ScrollHost";
import Spinner from "../spinner/Spinner";
import { IVariantTable, VariantTable } from "./VariantTable";
import colors from "../../colors.json";
import { useResizeObserver } from "../../hooks/UseResizeObserver";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { SessionContext } from "../../contexts/SessionContext";

export enum SelectionMode {
    Multiple,
    Single,
}

export type VariantsListProps = {
    /** The quantity to display in the variants list */
    quantity?: string;

    selectionMode?: SelectionMode;
    
    /** If true, a spinner will be displayed */
    isLoading?: boolean;
    
    /** Variants to display */
    variants?: Variant[];

    /** Variants to display, but at the bottom of the list and greyed-out */
    hiddenVariants?: Variant[];

    /** List of selected IDs */
    selectedIds?: string[];

    /** Handler that's executed when the selection changed */
    onSelectionChanged: (selectedIds: string[]) => void;
};

export interface IVariantsList {
    invalidate: () => void;
}

export const VariantsList = React.forwardRef((props: VariantsListProps, ref: React.Ref<IVariantsList>) => {
    const session = useContext(SessionContext);
    const tableRef = useRef<IVariantTable>(null);

    const listContainerRef = useRef<HTMLDivElement>(null);
    const listContainerSize = useResizeObserver(listContainerRef);

    const showYieldColumns = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).length > 0;
    
    const numVariantCases = useMemo(() => {
        let result = 0;
        (props.variants ?? []).forEach(v => {
            result += v.count;
        });

        return result;
    }, [
        props.variants
    ]);

    useImperativeHandle(ref, () => ({
        invalidate: () => {
            tableRef?.current?.invalidate();
        }
    }));

    return <div className="variantsListContainer" ref={listContainerRef}>
        <Spinner isLoading={props.isLoading === true} />
        {listContainerSize !== undefined && <ScrollHost
            contentScrollFade={true}
            content={<>{props.variants !== undefined && <VariantTable
                numVariantCases={numVariantCases}
                ref={tableRef}
                width={listContainerSize.width! - colors.$scrollbarWidth}
                height={listContainerSize.height!}
                data={props.variants}
                hidden={props.hiddenVariants ?? []}
                selectedIds={props.selectedIds ?? []}
                quantity={props.quantity ?? ""}
                onVariantClicked={(variant) => {
                    if (!props.onSelectionChanged)
                        return;

                    const ids = props.selectionMode !== SelectionMode.Single && props.selectedIds ? props.selectedIds : [];
                    const newSelectedIds = ids.some(id => id === variant.id) ?
                        ids.filter(id => id !== variant.id) :
                        [...ids, variant.id];
                        
                    props.onSelectionChanged(newSelectedIds);
                }}
            />}</>}
            xHeader={<div className={showYieldColumns ? "variantGridHeader" : "variantGridHeader variantsGridShort"}>
                <div>{i18n.t("common.variant")}</div>
                <div>{i18n.t("filters.variants.caseCount")}</div>
                <div>{i18n.t("filters.variants.casePercent")}</div>
                <div>{i18n.t("filters.variants.averageDuration")}</div>
                {showYieldColumns && <>
                    <div>{i18n.t("filters.variants.cumulatedYieldSum")}</div>
                    <div>{i18n.t("filters.variants.meanRateAverage")}</div>
                </>}
            </div>}
            yHeader={<></>}
            onScrolled={(top: number, left: number) => {
                tableRef.current?.updateScrollPosition(top, left);
            }}
            dimensions={{
                headerSize: {
                    height: colors.$variantTableHeaderHeight,
                    width: 0,
                },
                contentSize: {
                    width: listContainerSize.width! - colors.$scrollbarWidth,
                    height: ((props.variants?.length ?? 0) + (props.hiddenVariants?.length ?? 0)) * colors.$tableRowHeight,
                },
            }}
        />}
    </div>;
});