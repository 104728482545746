import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { GraphOrientation, SettingsContext } from "../../contexts/SettingsContext";
import { Formatter } from "../../utils/Formatter";
import { ZoomControlLocations } from "./DfGraph";
import { classNames } from "../../utils/Utils";

export type DfGraphControlsProps = {
    onFitGraph?: () => void;
    onZoomIn?: () => void;
    onZoomOut?: () => void;
    anchor?: ZoomControlLocations;

    hasBeenPanned?: boolean;

    zoom: number;

    /**
     * Triggered when the graph orientation changes
     */
    onOrientationChanged?: (orientation: GraphOrientation) => void;
}

export function DfGraphControls(props: DfGraphControlsProps) {
    const zoomControlAlignment = props.anchor === ZoomControlLocations.Right ||
        props.anchor === undefined ? "anchorRight" :
        props.anchor === ZoomControlLocations.Left ? "anchorLeft" : "anchorFarRight";

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    return <div className={"dfGraphControls " + zoomControlAlignment}>
        <div className="dfGraphControlsContainer expander">
            <svg className="svg-icon xsmall clickable brandHover" data-testid="orientationButton" onClick={() => {
                const orientation = settings.graph.orientation === GraphOrientation.horizontal ? GraphOrientation.vertical : GraphOrientation.horizontal;
                settings.setGraph({ orientation });
                if (props.onOrientationChanged)
                    props.onOrientationChanged(orientation);
            }}>
                <use xlinkHref="#radix-reload" />
            </svg>

            <svg 
                className={classNames(["svg-icon xsmall clickable brandHover", !props.hasBeenPanned && "disabled"])}
                data-testid="fitButton" 
                onClick={() => {
                    if (props.onFitGraph)
                        props.onFitGraph();
                }}>
                <use xlinkHref="#radix-size" />
            </svg>
 
        </div>

        <div className="dfGraphControlsContainer">
            <svg className="svg-icon xsmall clickable brandHover" data-testid="zoomOutButton" onClick={() => { if (props.onZoomOut) props.onZoomOut(); }}>
                <use xlinkHref="#radix-minus" />
            </svg>

            <div className="zoomPercent clickable brandHover" data-testid="fitGraphButton" onClick={() => { 
                if (props.onFitGraph) 
                    props.onFitGraph(); 
            }}>
                {Formatter.formatPercent(Math.min(9.99, props.zoom), 1, 0, session.locale)}
            </div>

            <svg className="svg-icon xsmall clickable brandHover" data-testid="zoomInButton" onClick={() => { if (props.onZoomIn) props.onZoomIn(); }}>
                <use xlinkHref="#radix-plus" />
            </svg>
        </div>
    </div>;
}