import React, { useState, useImperativeHandle } from "react";
import i18n from "../../i18n";
import { Formatter, UnitMetadata, UnitScale } from "../../utils/Formatter";
import Dropdown from "../dropdown/Dropdown";
import { NumberInput } from "../number-input/NumberInput";
import { BaseQuantityType } from "../../models/ApiTypes";

export type UnitInputValue = {
    unitScale?: UnitScale;
    value: number;
};

export interface IUnitInput {
    /**
     * Set's the values of this control. The value is expressed in the default unit (size = 1).
     * The control displays that value using the optionally provided scale.
     */
    set: (value: UnitInputValue) => UnitInputValue;
}

export type UnitInputProps = {
    className?: string;
    unit: UnitMetadata;
    baseQuantity?: BaseQuantityType;
    initialValue?: UnitInputValue;
    disabled?: boolean;
    min?: number;
    max?: number;
    numDigits?: number;
    onChange?: (value: number, unitScale: UnitScale, unitNumber: number, unitMetadata: UnitMetadata) => void;
};


/**
 * This component bundles a numeric input and a unit selection.
 */
export const UnitInput = React.forwardRef((props: UnitInputProps, ref: React.Ref<IUnitInput>) => {
    const units = props.unit.getUnits({
        baseQuantity: props.baseQuantity
    });

    const [selectedUnitScale, setSelectedUnitScale] = useState<UnitScale>(() => {
        return getUnitFromInputValue(props.initialValue, units);
    });

    const [value, setValue] = useState<number | undefined>(() => {
        if (props.initialValue === undefined)
            return;

        const targetValue = props.initialValue.value / selectedUnitScale.size;
        const fixed = props.numDigits ? +targetValue.toFixed(props.numDigits) : targetValue;
        return fixed;
    });

    const unitDropdownOptions = units.map((u) => {
        return {
            label: i18n.t(u.name),
            value: u.name,
        };
    });

    useImperativeHandle(ref, () => ({
        set(value: {
            unitScale?: UnitScale;
            value: number;
        }) {
            const scale = getUnitFromInputValue(value, units);
            setSelectedUnitScale(scale);

            const targetValue = value.value / scale.size;
            const fixed = props.numDigits !== undefined ? +targetValue.toFixed(props.numDigits) : targetValue;

            setValue(fixed);

            return {
                value: fixed,
                unitScale: scale,
            } as UnitInputValue;
        }
    }));

    return <div className={"unitInput" + (props.className ? " " + props.className : "")}>
        <NumberInput
            min={props.min !== undefined ? (props.min / selectedUnitScale.size) : undefined}
            max={props.max !== undefined ? (props.max / selectedUnitScale.size) : undefined}
            disabled={props.disabled}
            onValueChanged={input => {
                setValue(input);
                handleChange(input, selectedUnitScale);
            }}
            value={value ?? 0}
            className="input" />
        <Dropdown 
            isDisabled={props.disabled}
            options={unitDropdownOptions} 
            value={unitDropdownOptions.find(u => u.value === selectedUnitScale.name)!}
            className="dropdownLight"
            onChange={e => {
                const unit = units.find(u => u.name === e?.value)!;
                setSelectedUnitScale(unit);
                handleChange(value, unit);
            }}/>
    </div>;

    function handleChange(input: number | undefined, unit: UnitScale) {
        if (input === undefined)
            return;

        const value = input * unit.size;
        if (props.max !== undefined && value > props.max)
            input = props.max / unit.size;

        if (props.min !== undefined && value < props.min)
            input = props.min / unit.size;

        emitState(input, unit, props);
    }
});

function emitState(value: number | undefined, scale: UnitScale, props: UnitInputProps) {
    if (value === undefined || !props.onChange)
        return;

    const baseUnitValue = scale.size * value;
    props.onChange(baseUnitValue, scale, value, props.unit);
}

function getUnitFromInputValue(input: UnitInputValue | undefined, units: UnitScale[]) {
    if (input === undefined)
        // No input provided, so let's pick the default (or the first one, of there is one)
        return units.find(u => u.size === 1) ?? units[0];

    if (input.unitScale)
        return input.unitScale;

    return Formatter.getUnit(units, input.value) ?? units[0];

}