import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import Spinner from "../../components/spinner/Spinner";
import { TrayElement } from "../../components/tray/TrayElement";
import i18n from "../../i18n";
import { SelectionPage } from "../../components/selection-page/SelectionPage";
import { MachineSelector } from "../../components/controls/MachineSelector";
import { KpiTypes } from "../../models/KpiTypes";
import { useLossReasons } from "../../hooks/UseLossReasons";
import { GroupingKeys } from "../../models/Dfg";
import { EventKeys } from "../../models/EventKeys";

export function LossReasonsGraph() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    //const isDurationColoringEnabled = settings.kpiMatrix.comparison === MatrixComparison.None && settings.kpiMatrix.highlightDeviations;

    const machineName = settings.kpiMatrix.machineName;

    const [data] = useLossReasons({
        machines: machineName && machineName !== "all" ? [machineName] : undefined,
        losses: [KpiTypes.TechnicalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.OrganizationalLosses],
        eventKeys: {
            ...session.project!.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: !settings.kpiMatrix.machineName ||
            !session.project?.uploadId ||
            !session.project?.eventKeys?.passId,
    });

    const isLoading = false;
    const isEmpty  = false;

    if (!isLoading && !settings.kpiMatrix.machineName)
        return <SelectionPage
            title={i18n.t("lossAnalysis.title").toString()}
            description={i18n.t("lossAnalysis.noMachineDescription").toString()}
            selectorComponent={
                <MachineSelector className="dropdownLight mbl sizeConstrainedSelect" canSelectAll={true} />
            } />;

    const startNewAnalysisButton = <div className="bottomLeft">
        <TrayElement>
            <button
                className="shortcutButton"
                onClick={() => {
                    settings.mergeSet({
                        kpiMatrix: {
                            machineName: ""
                        }
                    });
                }
                }>
                {i18n.t("setupMatrix.startNewAnalysis").toString()}
            </button>
        </TrayElement>
    </div>;

    if (!isLoading && isEmpty)
        return <>
            <NoDataAvailable visible={true} title="setupMatrix.errorTitle" message="setupMatrix.noData" />
            {startNewAnalysisButton}
        </>;

    return <div className="fillParentMatrix">
        <Spinner isLoading={isLoading} />
        {!isLoading && !isEmpty && <>
            {JSON.stringify(data)}
            {/* Here goes the graph! */}
        </>}
        {startNewAnalysisButton}
    </div>;
}