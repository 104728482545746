import { isEqual, noop } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Histogram, TraceOptions } from "../../../../models/ApiTypes";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { numDefaultBinCount } from "../../../../Global";
import { useMountedState } from "../../../../hooks/UseMounted";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { Datastores } from "../../../../utils/Datastores";
import { Formatter, getTickValues } from "../../../../utils/Formatter";
import useWindowResize from "../../../../utils/WindowResizeHook";
import BarChartSelector from "../../../bar-chart-selector/BarChartSelector";
import Spinner from "../../../spinner/Spinner";
import { IUnitInput, UnitInput } from "../../../unit-input/UnitInput";

type DurationFilterEditorPropsType = {
    options: TraceOptions;
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};
type DurationFilterEditorStateType = {
    min: number;
    max: number;
    minUnitId?: string;
    maxUnitId?: string;
};

export default function DurationFilterEditor(props: DurationFilterEditorPropsType) {
    const [histogram, setHistogram] = useState<Histogram>({ bins: [], counts: [] });

    const isMounted = useMountedState();
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const [isInitializing, setIsInitializing] = useState(true);

    const inputFrom = useRef<IUnitInput>(null);
    const inputTo = useRef<IUnitInput>(null);

    // Take care to re-render component once we're resizing
    useWindowResize();

    const [range, setRange] = useState<[number, number]>([
        histogram.bins[0] || 0,
        histogram.bins[histogram.bins.length - 1] || 0
    ]);

    const [state, setState] = useState<DurationFilterEditorStateType>({
        min: props?.initialValue?.caseDuration?.ge ?? range[0],
        max: props?.initialValue?.caseDuration?.lt ?? range[1],
        minUnitId: props?.initialValue?.caseDuration?.minUnitId,
        maxUnitId: props?.initialValue?.caseDuration?.maxUnitId,
    });

    const xTicks = getTickValues(range[0], range[1], 5, session.locale);

    const [subscriptionId] = useState<number>(() => { return Datastores.durationHistograms.getSubscriptionId(); });
    useEffect(() => {
        return () => { return Datastores.durationHistograms.cancelSubscription(subscriptionId); };
    }, []);

    useEffect(() => {
        setIsInitializing(true);
        Datastores.durationHistograms.get({ ...props.options, nBins: numDefaultBinCount, eventFilters: [] }, subscriptionId).then((fullHistogram) => {
            Datastores.durationHistograms.get({ ...props.options, nBins: numDefaultBinCount, binsToUse: fullHistogram.bins }, subscriptionId).then((filteredHistogram) => {
                if (!isMounted())
                    return;
                const range: [number, number] = [fullHistogram.bins[0], fullHistogram.bins[fullHistogram.bins.length - 1]];
                setRange(range);

                const min = props?.initialValue?.caseDuration?.ge ?? range[0];
                const max = props?.initialValue?.caseDuration?.lt ?? range[1];

                const newState = { ...state, min, max };

                setHistogram(filteredHistogram);

                if (!isEqual(newState, state)) {
                    setState(newState);
                    emitFilter(newState, range);
                }
            }).catch(noop).finally(() => {
                if (isMounted())
                    setIsInitializing(false);
            });
        }).catch(() => {
            if (isMounted())
                setIsInitializing(false);
        });
    }, [
        settings.filters,
        settings.apiRetry,
    ]);

    useEffect(() => {
        // in case the initial value is undefined
        // we hit onUpdate to get rid of whatever has been submitted before
        if (props.initialValue === undefined && props.onUpdate)
            props.onUpdate(undefined);
    }, []);

    const { barData, barDataNoFilter } = getBarData(histogram);

    // Im adding some small random number here to prevent
    // victory charts from caching the brush domain. This
    // has caused trouble before. If you don't think this
    // is necessary any more, just go ahead and remove it,
    // but be sure the following still works:
    // - click "select longest case" button
    // - modify selected range
    // - click that button again
    //
    // If everything's fine then: nice! Otherwise you might
    // want to add that random number again.
    //
    // Wasted 2h on this, grr.
    //
    // UPDATE: Okay, wasted some more hours on this.
    // We need this to be deterministic rather than random,
    // because we're running snapshot tests on this guy...
    //
    // Another update: Moved this code to onUpdate, because
    // the amount of render calls may vary between different
    // environments.
    const numRendersRef = useRef<number>(0);
    const brushDomain: [number, number] = [state.min + numRendersRef.current * 0.00000001, state.max];

    return <>
        <Spinner isLoading={isInitializing} className="filterSpinner" />
        {!isInitializing && <div className="durationFilterEditor tabPage">
            <div className="durationForm light">
                <label>
                    {i18n.t("filters.minDuration")}
                </label>
                <UnitInput
                    ref={inputFrom}
                    className="durationFixedSize"
                    unit={Formatter.units.durationLong}
                    initialValue={{
                        unitScale: Formatter.units.durationLong.getUnits({}).find(u => u.name === state.minUnitId),
                        value: state.min,
                    }}
                    numDigits={3}
                    onChange={(value, scale) => {
                        minChanged(value, scale.name);
                    }} />
                <button
                    className="editorButton"
                    title={i18n.t("filters.shortestCase").toString()}
                    onClick={() => {
                        let lastElement = 0;
                        while (lastElement < histogram.bins.length && !histogram.counts[lastElement])
                            lastElement++;

                        if (lastElement < (histogram?.bins.length - 1))
                            onUpdate([histogram.bins[lastElement], histogram.bins[lastElement + 1]]);
                    }}>
                    {i18n.t("filters.shortestCase")}
                </button>
                <label className="ml">
                    {i18n.t("filters.maxDuration")}
                </label>
                <UnitInput
                    ref={inputTo}
                    className="durationFixedSize"
                    unit={Formatter.units.durationLong}
                    initialValue={{
                        unitScale: Formatter.units.durationLong.getUnits({}).find(u => u.name === state.maxUnitId),
                        value: state.max,
                    }}
                    numDigits={3}
                    onChange={(value, scale) => {
                        maxChanged(value, scale.name);
                    }} />
                <button
                    className="editorButton"
                    title={i18n.t("filters.longestCase").toString()}
                    onClick={() => {
                        let lastElement = (histogram?.bins?.length ?? 0) - 1;
                        while (lastElement > 0 && !histogram.counts[lastElement])
                            lastElement--;

                        if (lastElement >= 0 && histogram?.bins.length > 2)
                            onUpdate([histogram.bins[lastElement], histogram.bins[lastElement + 1]]);
                    }}>
                    {i18n.t("filters.longestCase")}
                </button>
            </div>
            <BarChartSelector
                data={barData}
                dataNoFilter={barDataNoFilter}
                yLabel={i18n.t("common.caseCount").toString()}
                brushDomain={brushDomain}
                xDomain={range}
                xTickValues={xTicks.ticks}
                xTickFormatter={(value) => Formatter.formatDurationShort(value, undefined, session.numberFormatLocale)}
                yTickFormatter={(value) => Formatter.formatNumber(value, 2, session.numberFormatLocale)}
                barLabels={histogram.counts.map(v => v === 0 ? "" : Formatter.formatNumber(v, 2, session.numberFormatLocale))}
                onBrushDomainChange={onUpdate}
            />
        </div>}
    </>;

    function onUpdate(values: [number, number], minUnit?: string, maxUnit?: string) {
        
        const minDuration = Math.max(values[0], range[0]);
        const maxDuration = Math.min(values[1], range[1]);

        let validatedMinUnit = minUnit;
        let validatedMaxUnit = maxUnit;

        if (minUnit === undefined && maxUnit === undefined) {
            validatedMinUnit = inputFrom.current?.set({ value: values[0] }).unitScale?.name;
            validatedMaxUnit = inputTo.current?.set({ value: values[1] }).unitScale?.name;
        }

        setState((state) => {
            const minUnitId = validatedMinUnit ?? state.minUnitId;
            const maxUnitId = validatedMaxUnit ?? state.maxUnitId;

            const newMax = Math.round(maxDuration);
            const newMin = Math.round(minDuration);

            if (newMax !== state.max || newMin !== state.min)
                numRendersRef.current++;

            const newState = {
                ...state,

                minUnitId,
                maxUnitId,

                // we do some rounding to seconds here because
                // otherwise strange things start happening
                max: newMax,
                min: newMin,
            };

            emitFilter(newState, range);

            return newState;
        });
    }

    function minChanged(duration: number, unitId: string) {
        onUpdate([duration, Math.max(state.max, duration)], unitId);
    }

    function maxChanged(duration: number, unitId: string) {
        onUpdate([Math.min(state.min, duration), duration], undefined, unitId);
    }

    function emitFilter(state: DurationFilterEditorStateType, range: [number, number]) {
        // The maximum range may have fractions of a second, while the range selector
        // tends to report just round numbers. That's why I'm neglecting the fractional
        // part of a second here.
        const filterHasRestrictions = Math.abs(state.min - range[0]) > 1 || Math.abs(state.max - range[1]) > 1;

        if (props.onUpdate)
            if (filterHasRestrictions)
                props.onUpdate({
                    caseDuration: {
                        lt: state.max,
                        ge: state.min,
                        maxUnitId: state.maxUnitId,
                        minUnitId: state.minUnitId,
                    }
                } as EventFilter);
            else
                // If the filter does not restrict the results in any meaningful way,
                // pretend it's not there.
                props.onUpdate(undefined);
    }
}

/**
 * Generates histogram bar data. If histogramNoFilter is provided, it will display filtered-
 * out buckets in gray. But it's optional.
 * @param histogram Histogram to display in primary color
 * @param histogramNoFilter Histogram that contains histogram
 * @returns bar data
 */
function getBarData(histogram: Histogram, histogramNoFilter?: Histogram) {
    const barData = Array.from({ length: histogram.counts.length }).map((_, i) => {
        return {
            x: (histogram.bins[i] + histogram.bins[i + 1]) / 2,
            y: histogram.counts[i]
        };
    });
    const barDataNoFilter = histogramNoFilter === undefined ? undefined : Array.from({ length: histogram.counts.length }).map((_, i) => {
        return {
            x: (histogram.bins[i] + histogram.bins[i + 1]) / 2,
            y: (histogramNoFilter.counts[i] - histogram.counts[i])
        };
    });
    return { barData, barDataNoFilter };
}
