import { Point } from "../models/Dfg";
import { Vector } from "./Vector";

export class BoundingBox {
    public min: Vector | undefined = undefined;
    public max: Vector | undefined = undefined;

    public get center() {
        if (!this.min || !this.max)
            return undefined;

        return new Vector(
            (this.min.x + this.max.x) / 2,
            (this.min.y + this.max.y) / 2
        );
    }

    public addVector(vec: Vector) {
        return this.add(vec.x, vec.y);
    }

    public addPoint(pt: Point) {
        return this.add(pt.x, pt.y);
    }

    public add(x: number, y: number) {
        if (this.min === undefined)
            this.min = new Vector(x, y);
        else {
            this.min.x = Math.min(this.min.x, x);
            this.min.y = Math.min(this.min.y, y);
        }

        if (this.max === undefined)
            this.max = new Vector(x, y);
        else {
            this.max.x = Math.max(this.max.x, x);
            this.max.y = Math.max(this.max.y, y);
        }
    }

    public static fromPoints(points: Point[]) {
        const result = new BoundingBox();

        for (const pt of points)
            result.addPoint(pt);

        return result;
    }
}