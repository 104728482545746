import React from "react";
import i18n from "../../i18n";

export function FormRow(props: React.PropsWithChildren<{
    title: string;
    description?: string;
    isMandatory?: boolean;
}>) {
    return <div className="formRow next">
        <div className="left">
            <h3>
                {i18n.t(props.title)}
                {props.isMandatory && <span className="mandatory">*</span>}
            </h3>
            {props.description && <div className="description">
                {i18n.t(props.description)}
            </div>}
        </div>

        <div className="right">
            {props.children}
        </div>
    </div>;
}