import { isArray, isNumber, noop } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { Datastores } from "../../../../utils/Datastores";
import { getColumnLabel } from "../../../../utils/Formatter";
import DateTimeInput from "../../../datetime-input/DateTimeInput";
import { CardInfo } from "../../../dropzone/Card";
import { CollectionTypes, Dropzone, IDropzone, Orientations, SearchResults } from "../../../dropzone/Dropzone";
import InputAdder from "../../../input-adder/InputAdder";
import { attributeOperators, AttributeOperatorType, attributeTypes, getAttributeFilterMetadata, getAttributeFilterValue } from "./AttributeHelpers";

const maxStringResults = 2000;

type AttributeFilterEditorPropsType = {
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};

type StateType = {
    datetimeValue: Date;
    numericValue: number;
    numericValues: number[];
    textValues?: string[];
    selectedTextValue?: string;
    selectedColumnName?: string;
    selectedOperatorName: string;
};

export default function AttributeFilterEditor(props: AttributeFilterEditorPropsType) {
    const meta = props.initialValue ? getAttributeFilterMetadata(props.initialValue) : undefined;
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const dropzoneRef = useRef<IDropzone>(null);

    const [subscriptionId] = useState<number>(() => {
        return Datastores.getDistinctUploadAttributeValues.getSubscriptionId();
    });

    useEffect(() => {
        return () => {
            Datastores.getDistinctUploadAttributeValues.cancelSubscription(subscriptionId);
        };
    }, []);

    const uploadColumns = session.eventUpload?.meta?.attributes.sort((a, b) => {
        return a.name.localeCompare(b.name);
    }) ?? [];

    // List of attribute types that are used in this upload
    const usedAttributeTypes = attributeTypes.filter(a => uploadColumns.some(c => a.type.id.indexOf(c.type) >= 0));

    // Pick a column name that we're initializing with in case no initial value is
    // present in the props
    const defaultColumn = uploadColumns.find(c => usedAttributeTypes[0].type.id.indexOf(c.type) >= 0);
    const defaultColumnType = attributeTypes.find(a => a.type.id.indexOf(defaultColumn?.type ?? "") >= 0);

    // Get initial values
    const initialDate = (() => {
        const dateFilter = props.initialValue?.caseAttributeDatetime;
        const initialDate = dateFilter?.gt ?? dateFilter?.ge ?? dateFilter?.lt ?? dateFilter?.le;
        return initialDate ? new Date(initialDate) : new Date(Math.floor(new Date().getTime() / 60000) * 60000);
    })();

    const initialNumberValue = meta && isNumber(meta.value) ? meta.value as number : undefined;
    const initialNumberValues = meta && isArray(meta.value) && (meta.value as unknown[]).every(i => isNumber(i)) ?
        (meta.value as number[] ?? []) : [];
    const initialTextValues = meta && isArray(meta.value) ? meta.value as string[] ?? [] : [];

    // Initialize states
    const [state, setState] = useState<StateType>({
        datetimeValue: initialDate,
        numericValue: initialNumberValue ?? 0,
        numericValues: initialNumberValues,
        textValues: initialTextValues,
        selectedOperatorName: meta?.operator?.name ?? defaultColumnType?.availableOperators[0].name ?? "eq",
        selectedColumnName: meta?.attributeName ?? defaultColumn?.name,
    });

    const selectedColumnType = (() =>
    {
        const selectedUploadColumn = uploadColumns.find(c => c.name === state.selectedColumnName);
        return attributeTypes.find(a => (a.type.id.indexOf(selectedUploadColumn?.type ?? "") >= 0));
    })();


    // stringValues holds a lookup from columnName to it's distinct values as returned from the API.
    // This needs to initialize only if the upload changes.
    const [stringValues, setStringValues] = useState<{[columnName: string]: {
            value: string;
            count: number;
        }[]} | undefined>();

    const [stringValuesUpdated, setStringValuesUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (!session.eventUpload || !uploadColumns)
            return;

        updateStringValues(session.eventUpload.id, uploadColumns);
    }, [session.eventUpload, subscriptionId, settings.apiRetry]);

    const initialValuesText = meta === undefined || !isArray(meta.value)? [] : (meta.value as string[]).map(v => {
        return {
            id: v.toString(),
            label: v.toString()
        } as CardInfo;
    });

    const operator = attributeOperators.find(o => o.name === state.selectedOperatorName);

    const selectedStringValues = (stringValues && stringValues[state.selectedColumnName ?? ""]) ?
        stringValues[state.selectedColumnName ?? ""]: undefined;

    emitFilter(state);

    return <div className="tabPage light attributeFilterEditor">
        {/* Attribute selection */}
        <div className="select sizeConstrainedSelect">
            <select
                data-testid="attribute-select"
                defaultValue={state.selectedColumnName}
                onChange={(e) => {
                    selectedColumnChanged(e.target.value);
                }}>
                {usedAttributeTypes.map(a => <optgroup key={a.type.id[0]} label={a.type.label}>
                    {uploadColumns.filter(c => a.type.id.indexOf(c.type) >= 0).map(c => <option key={c.name} value={c.name}>
                        {getColumnLabel(c.name, session.project?.eventKeys) ? `${c.name} (▶ ${getColumnLabel(c.name, session.project?.eventKeys)})` : c.name}
                    </option>)}
                </optgroup>)}
            </select>
        </div>

        {/* Operator selection */}
        <div className="operators spacer">
            {(selectedColumnType?.availableOperators ?? []).map((o: AttributeOperatorType) => <button
                className={o.name === state.selectedOperatorName ? "editorButton selected" : "editorButton"}
                onClick={() => { operatorChanged(o); }}
                key={o.name}>
                {o.label}
            </button>)}
        </div>

        {/* Value editors --------------------------------------------------------------------- */}

        {/* Datetime */}
        {selectedColumnType?.type?.id &&
         selectedColumnType.type.id.indexOf("datetime") >= 0 && <div className="columnFormCenter">
            <label>{i18n.t("filters.attribute.dateOperators." + state.selectedOperatorName)}</label>
            <DateTimeInput
                allowUndefined={false}
                onChange={(e) => {
                    if (e !== undefined)
                        updateState({...state, datetimeValue: e });
                }}
                value={state.datetimeValue}
            />
        </div>}

        {/* Numeric, single value */}
        {selectedColumnType?.type?.id &&
         (
             selectedColumnType.type.id.indexOf("float") >= 0 ||
             selectedColumnType.type.id.indexOf("integer") >= 0
         ) &&
         !operator?.isArray &&
        <div className="columnFormCenter">
            <label>{i18n.t("filters.attribute.numericOperators." + state.selectedOperatorName)}</label>
            <input type="number" value={state.numericValue} onChange={(e) => {
                updateState({...state, numericValue: +e.target.value });
            }} placeholder={i18n.t("filters.attribute.value").toString()} />
        </div>}

        {/* Numeric, multiple values */}
        {selectedColumnType?.type?.id &&
         selectedColumnType.type.id.indexOf("integer") >= 0 &&
         !!operator?.isArray &&
         <div className="light">
             <InputAdder<number>
                 placeholder={i18n.t("filters.attribute.addValuesPlaceholder").toString()}
                 inputType="number"
                 buttonLabel={i18n.t("filters.attribute.addValuesPlaceholder").toString()}
                 parse={(e) => { return +e; }}
                 onAdd={(e) => {
                     updateState({...state, numericValues: [e, ...state.numericValues.filter(v => v !== e)] });
                 }}
             />
             <div className="pillContainer">
                 {state.numericValues.map(e => <div key={e} className="pill">
                     {e}
                     <svg onClick={() => { updateState({...state, numericValues: state.numericValues.filter(n => n !== e)}); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>
                 </div>)}
             </div>
         </div>}


        {/* Text */}
        {selectedColumnType?.type?.id &&
         stringValuesUpdated &&
         selectedColumnType.type.id.indexOf("string") >= 0 &&
         (!selectedStringValues ||
         !Object.keys(selectedStringValues).length) && <div>
            <InputAdder<string>
                placeholder={i18n.t("filters.attribute.addValuesPlaceholder").toString()}
                inputType="text"
                parse={(e) => { return e; }}
                onAdd={(e) => {
                    updateState({...state, textValues: [e, ...(state.textValues ?? []).filter(v => v !== e)] });
                }}
            />
            <div className="pillContainer">
                {(state.textValues ?? []).map(e => <div key={e} className="pill">
                    {e}
                    <svg onClick={() => { updateState({...state, textValues: (state.textValues ?? []).filter(n => n !== e)}); }} className="svg-icon tiny closer"><use xlinkHref="#radix-cross-1" /></svg>
                </div>)}
            </div>
        </div>}

        {/* Text with limited cardinality */}
        {selectedColumnType?.type?.id &&
         selectedColumnType.type.id.indexOf("string") >= 0 &&
         selectedStringValues &&
         !!Object.keys(selectedStringValues).length && <div className="growContainer">
            <Dropzone
                orientation={Orientations.Horizontal}
                ref={dropzoneRef}
                searchPlaceholderLabel={i18n.t("filters.attribute.searchValues")}
                collectionType={CollectionTypes.Set}
                onSearch={onSearchTextAttribute}
                onChange={(e) => {
                    updateState({...state, textValues: e.map(l => l.label) });
                }}
                maxResults={maxStringResults}
                showResultCount={false}
                totalCount={selectedStringValues.length}
                initialValue={initialValuesText}
                dropFilteredResults={true}
            />
        </div>}

    </div>;

    function onSearchTextAttribute(parts: string[]): SearchResults {
        if (!state.selectedColumnName ||
            !stringValues)
            return {
                matches: [],
                hasMoreData: false,
            };

        const matches = (stringValues[state.selectedColumnName] || []).filter(sv => {
            return parts.every(p => sv.value.toLocaleLowerCase().indexOf(p) >= 0);
        });

        return {
            matches: matches.filter((_, idx) => idx <= maxStringResults).map(e => {
                return {
                    id: e.value,
                    label: e.value
                } as CardInfo;
            }),
            hasMoreData: matches.length > maxStringResults,
        };
    }

    /**
    * Fetch distinct string values for all string attributes.
    * This would not make sense for numeric or date values.
    * Updates the stringValues state when done
    * @param uploadId Upload ID
    * @param columns Column information
    */
    function updateStringValues(uploadId: string, columns: {name: string, type: string}[]) {
        if (!subscriptionId)
            return;
        setStringValuesUpdated(false);
        Datastores.getDistinctUploadAttributeValues.get({
            uploadId,
            maxValues: maxStringResults,
            attributes: columns.filter(f => {
                return f.type === "string";
            }).map(f => {
                return f.name;
            })
        }, subscriptionId).then((result) => {
            const filtered = result.filter(r => {
                return r.values.length < maxStringResults;
            });

            const lookup: {[columnName: string]: {
                value: string;
                count: number;
            }[]} = {};

            for (const element of filtered)
                lookup[element.name] = element.values.sort((a, b) => {
                    return a.value.localeCompare(b.value);
                });

            setStringValues(lookup);
            setStringValuesUpdated(true);
        }).catch(noop);
    }

    function operatorChanged(operator: AttributeOperatorType) {
        updateState({
            ...state,
            textValues: dropzoneRef.current ? (dropzoneRef.current.getState() ?? []).map(l => l.label) : undefined,
            selectedOperatorName: operator.name
        });
    }

    function selectedColumnChanged(columnName: string) {
        const newState: StateType = {...state, selectedColumnName: columnName };

        const column = uploadColumns.find(c => c.name === columnName);
        const attributeType = attributeTypes.find(a => a.type.id.indexOf(column?.type ?? "") >= 0);

        if (!attributeType?.availableOperators.some(o => o.name === state.selectedOperatorName)) {
            // New column type does not support this operator. Select anything that's legal!
            newState.selectedOperatorName = attributeType?.availableOperators[0].name ?? "";
        }

        updateState(newState);

        setTimeout(() => {
            if (dropzoneRef.current)
                dropzoneRef.current.reset();
        });
    }

    function updateState(newState: StateType) {
        setState(newState);
        emitFilter(newState);
    }

    function emitFilter(newState: StateType) {
        let filter = getEventFilter(newState);
        if (getAttributeFilterValue(filter!) === undefined) {
            // Empty filter value!
            filter = undefined;
        }

        if (props.onUpdate)
            props.onUpdate(filter);
    }

    function getEventFilter(state: StateType) {
        const selectedUploadColumn = uploadColumns.find(c => c.name === state.selectedColumnName);
        const selectedColumnType = attributeTypes.find(a => (a.type.id.indexOf(selectedUploadColumn?.type ?? "") >= 0));

        switch (selectedColumnType?.type.id[0]) {
            case "string":
                return {
                    caseAttributeText: {
                        name: state.selectedColumnName,
                        eq: state.selectedOperatorName === "eq" && state.textValues?.length ? state.textValues : undefined,
                        ne: state.selectedOperatorName === "ne" && state.textValues?.length ? state.textValues : undefined,
                    }
                } as EventFilter;

            case "integer":
                return {
                    caseAttributeInt: {
                        name: state.selectedColumnName,
                        ge: state.selectedOperatorName === "ge" ? state.numericValue : undefined,
                        gt: state.selectedOperatorName === "gt" ? state.numericValue : undefined,
                        le: state.selectedOperatorName === "le" ? state.numericValue : undefined,
                        lt: state.selectedOperatorName === "lt" ? state.numericValue : undefined,
                        eq: state.selectedOperatorName === "eq" ? state.numericValues : undefined,
                        ne: state.selectedOperatorName === "ne" ? state.numericValues : undefined,
                    }
                } as EventFilter;
            case "float":
                return {
                    caseAttributeFloat: {
                        name: state.selectedColumnName,
                        ge: state.selectedOperatorName === "ge" ? state.numericValue : undefined,
                        gt: state.selectedOperatorName === "gt" ? state.numericValue : undefined,
                        le: state.selectedOperatorName === "le" ? state.numericValue : undefined,
                        lt: state.selectedOperatorName === "lt" ? state.numericValue : undefined,
                    }
                } as EventFilter;
            case "datetime":
                return {
                    caseAttributeDatetime: {
                        name: state.selectedColumnName,
                        ge: state.selectedOperatorName === "ge" ? state.datetimeValue.toISOString() : undefined,
                        gt: state.selectedOperatorName === "gt" ? state.datetimeValue.toISOString() : undefined,
                        le: state.selectedOperatorName === "le" ? state.datetimeValue.toISOString() : undefined,
                        lt: state.selectedOperatorName === "lt" ? state.datetimeValue.toISOString() : undefined,
                    }
                } as EventFilter;        }

        return undefined;
    }
}
