import React, { useContext, useRef } from "react";
import { useGridResize } from "../../components/kpi-chart/UseGridResize";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { SettingsContext } from "../../contexts/SettingsContext";
import { CaseProcessKpiChart } from "../process-kpi-chart/CaseProcessKpiChart";
import { ProductProcessKpiChart } from "../process-kpi-chart/ProductProcessKpiChart";
import { TimeperiodChart } from "../../components/timeperiods-chart/TimeperiodsChart";
import { getAnalysisTitle } from "../../components/product-chart/ProductChart";
import { SessionContext } from "../../contexts/SessionContext";

export function StockKpiChart(props: {
    pageSlug: string;
    noProductDataPlaceholder: string;
    noCaseDataPlaceholder: string;
    noTimeDataPlaceholder: string;
    validComparisons: KpiComparisons[];
    addEnergyStats?: boolean;
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const container = useRef<HTMLDivElement>(null);
    const [width, height] = useGridResize(container, 1, undefined, undefined, 0);

    const titleKey = getAnalysisTitle(session, settings);

    return <>
        <div className="fillParentMargin" ref={container}>
            {settings.kpi.aggregation === AggregationTypes.Product && !!width && !!height && <ProductProcessKpiChart singleObjectStatsValid={false} noDataPlaceholder={props.noProductDataPlaceholder} pageSlug={props.pageSlug} width={width} height={height} titleKey={titleKey} />}
            {settings.kpi.aggregation === AggregationTypes.Case && !!width && !!height && <CaseProcessKpiChart singleObjectStatsValid={false} noDataPlaceholder={props.noCaseDataPlaceholder} pageSlug={props.pageSlug} width={width} height={height} titleKey={titleKey} />}
            {settings.kpi.aggregation === AggregationTypes.Time && !!width && !!height && <TimeperiodChart
                width={width} height={height} title={titleKey}
                noDataPlaceholder={props.noTimeDataPlaceholder}
                requestOptions={{
                    calculateTimeAndFreqStats: true,
                    calculateBusyStats: true,
                    calculateProductionStats: true,
                    calculateUnknownStats: true,
                    calculateFailureStats: true,
                    calculateSetupStats: true,
                    calculateInterruptionStats: true,
                    calculatePassChangeStats: true,
                    calculatePassStats: true,
                    calculateOutputStats: true,
                }} />}
        </div>
        <Shortcuts handledSelections={[ShortcutContexts.Product, ShortcutContexts.Case, ShortcutContexts.Timeperiod]} />
    </>;
}
