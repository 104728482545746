import React, { useEffect, useRef } from "react";
import Select, { ActionMeta, GroupBase, StylesConfig } from "react-select";
import colors from "../../colors.json";
import i18n from "../../i18n";

const groupPadding = 0.65;

export interface StringOption {
    label: string,
    value?: string | boolean | number,
    options?: StringOption[],
    indentationLevel?: number | undefined;
}

export type DropdownPropsType = {
    className?: string;
    value: StringOption,
    options: StringOption[];
    maxMenuHeight?: number;
    height?: number;
    isSearchable?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    testId?: string;
    focusInitially?: boolean;
    isOptionDisabled?: (option: StringOption) => boolean;
    onChange: (value: StringOption | null, action: ActionMeta<StringOption>) => void;
    onBlur?: () => void;
};

export default function Dropdown(props: DropdownPropsType) {
    // I need to subtract the border width here. There's one above and below, so 2px
    const height = props.height ? (props.height - 2) + "px" : "25px";

    const indentationPx = [30, 45, 60];

    const customStyles: StylesConfig<StringOption, false, GroupBase<StringOption>> = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? colors.$anthracite : colors.$white,
            paddingLeft: `${indentationPx[state.data.indentationLevel]}px` ?? "0px",
        }),
        control: (provided: any) => ({
            ...provided,
            minHeight: height,
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: height,
        }),
        input: (provided: any) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: height,
            padding: "0px",
        }),
        group: (provided: any) => {
            return {
                ...provided,
                marginBottom: 0,
                paddingBottom: 0,
                paddingTop: 0,
            };
        },
        //We are hiding the group heading label and only showing the group options
        groupHeading: (provided: any) => ({
            ...provided,
            color: colors.$white,
            height: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: `-${groupPadding}rem`,
            textTransform: "none",
            fontSize: colors.$fontSizeDefault + "rem",
        }),
    };

    const myRef = useRef(null);
    useEffect(() => {
        if (props.focusInitially && myRef.current)
            (myRef.current as any).onMenuOpen();
    }, [
        props.focusInitially,
        myRef,
        props.options,
    ]);

    return <div data-testid={props.testId ?? "dropdown"} className={props.className}>
        <Select
            styles={customStyles}
            ref={myRef}
            isDisabled={props.isDisabled}
            isOptionDisabled={props.isOptionDisabled}
            className="dropdownLight"
            classNamePrefix="dropdown"
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            onBlur={props.onBlur}
            isSearchable={props.isSearchable === true}
            maxMenuHeight={props.maxMenuHeight}
            placeholder={props.placeholder}
            noOptionsMessage={() => i18n.t("common.noResults")}
        />
    </div>;
}
