
import { BaseQuantityType } from "../models/ApiTypes";
import { CaseAggregationStatistics, CaseStatistics } from "../models/Case";
import { isBaseQuantityType } from "./Quantities";

export const accessors = {
    case: {
        scrap: {
            mass: (e: CaseStatistics) => e.caseScrapMass,
            length: (e: CaseStatistics) => e.caseScrapLength,
            count: (e: CaseStatistics) => e.caseScrapCount,
        },
        relativeScrap: {
            mass: (e: CaseStatistics) => e.kpis?.relativeCaseScrapMass,
            length: (e: CaseStatistics) => e.kpis?.relativeCaseScrapLength,
            count: (e: CaseStatistics) => e.kpis?.relativeCaseScrapCount,
        },
        yield: {
            mass: (e: CaseStatistics) => e.caseYieldMass,
            length: (e: CaseStatistics) => e.caseYieldLength,
            count: (e: CaseStatistics) => e.caseYieldCount,
        },
        relativeYield: {
            mass: (e: CaseStatistics) => e.kpis?.relativeCaseYieldMass,
            length: (e: CaseStatistics) => e.kpis?.relativeCaseYieldLength,
            count: (e: CaseStatistics) => e.kpis?.relativeCaseYieldCount,
        },
        output: {
            mass: (e: CaseStatistics) => e.caseOutputMass,
            length: (e: CaseStatistics) => e.caseOutputLength,
            count: (e: CaseStatistics) => e.caseOutputCount,
        },
    },
    aggregatedCases: {
        scrap: {
            mass: (e: CaseAggregationStatistics) => e.caseScrapMassStatistics,
            length: (e: CaseAggregationStatistics) => e.caseScrapLengthStatistics,
            count: (e: CaseAggregationStatistics) => e.caseScrapCountStatistics,
        },
        relativeScrap: {
            mass: (e: CaseAggregationStatistics) => e.relativeCaseScrapMassStatistics,
            length: (e: CaseAggregationStatistics) => e.relativeCaseScrapLengthStatistics,
            count: (e: CaseAggregationStatistics) => e.relativeCaseScrapCountStatistics,
        },
        yield: {
            mass: (e: CaseAggregationStatistics) => e.caseYieldMassStatistics,
            length: (e: CaseAggregationStatistics) => e.caseYieldLengthStatistics,
            count: (e: CaseAggregationStatistics) => e.caseYieldCountStatistics,
        },
        relativeYield: {
            mass: (e: CaseAggregationStatistics) => e.relativeCaseYieldMassStatistics,
            length: (e: CaseAggregationStatistics) => e.relativeCaseYieldLengthStatistics,
            count: (e: CaseAggregationStatistics) => e.relativeCaseYieldCountStatistics,
        },
        yieldRate: {
            mass: (e: CaseAggregationStatistics) => e.caseYieldRateMassStatistics,
            length: (e: CaseAggregationStatistics) => e.caseYieldRateLengthStatistics,
            count: (e: CaseAggregationStatistics) => e.caseYieldRateCountStatistics,
        },
        output: {
            mass: (e: CaseAggregationStatistics) => e.caseOutputMassStatistics,
            length: (e: CaseAggregationStatistics) => e.caseOutputLengthStatistics,
            count: (e: CaseAggregationStatistics) => e.caseOutputCountStatistics,
        },
        stock: {
            mass: (e: CaseAggregationStatistics) => e.kpis?.productAverageYieldStockMass,
            length: (e: CaseAggregationStatistics) => e.kpis?.productAverageYieldStockLength,
            count: (e: CaseAggregationStatistics) => e.kpis?.productAverageYieldStockCount,
        },
    }
};


type RelaxedBaseQuantityType = BaseQuantityType | "none" | undefined;

export const getCaseScrap = (stats: CaseStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.case.scrap[quantity](stats) : undefined;
export const getCaseRelativeScrap = (stats: CaseStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? clip0to1(accessors.case.relativeScrap[quantity](stats)) : undefined;
export const getCaseAggregatedScrapStatistics = (stats: CaseAggregationStatistics, quantity: string | undefined) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.scrap[quantity](stats) : undefined;
export const getCaseAggregatedRelativeScrapStatistics = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.relativeScrap[quantity](stats) : undefined;
export const getCaseAggregatedScrap = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => getCaseAggregatedScrapStatistics(stats, quantity)?.sum;
export const getCaseAggregatedRelativeScrap = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => clip0to1(getCaseAggregatedRelativeScrapStatistics(stats, quantity)?.mean);
export const getCaseAggregatedStockStatistics = (stats: CaseAggregationStatistics, quantity: string | undefined) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.stock[quantity](stats) : undefined;

export const getCaseYield = (stats: CaseStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.case.yield[quantity](stats) : undefined;
export const getCaseRelativeYield = (stats: CaseStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? clip0to1(accessors.case.relativeYield[quantity](stats)) : undefined;
export const getCaseAggregatedYieldStatistics = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.yield[quantity](stats) : undefined;
export const getCaseAggregatedRelativeYieldStatistics = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.relativeYield[quantity](stats) : undefined;
export const getCaseAggregatedYield = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => getCaseAggregatedYieldStatistics(stats, quantity)?.sum;
export const getCaseAggregatedYieldRateStatistics = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.yieldRate[quantity](stats) : undefined;

export const getCaseOutput = (stats: CaseStatistics, quantity: RelaxedBaseQuantityType) => isBaseQuantityType(quantity) ? accessors.case.output[quantity](stats) : undefined;
export const getCaseAggregatedOutputStatistics = (stats: CaseAggregationStatistics, quantity: string | undefined) => isBaseQuantityType(quantity) ? accessors.aggregatedCases.output[quantity](stats) : undefined;
export const getCaseAggregatedOutput = (stats: CaseAggregationStatistics, quantity: RelaxedBaseQuantityType) => getCaseAggregatedScrapStatistics(stats, quantity)?.sum;

/**
 * Constrain numerical values between 0 and 1 which can be useful for relative
 * or percent values that should guaranteed to be in that range
 * @param value The value to clip
 * @returns The clipped value
 */
function clip0to1(v: number | undefined) {
    if (v !== undefined)
        return Math.min(Math.max(v, 0), 1);
}
