import { useContext } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { SetupEventsParams, SetupEventsSchema, SetupMatrixParams } from "../models/ApiTypes";

export function useSetupEvents(request: Partial<SetupEventsParams> = {}, options?: ApiHookOptions<SetupEventsSchema>): [SetupEventsSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: SetupMatrixParams = {
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request.eventKeys ?? session.project?.eventKeys ?? { } as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        machines: request.machines ?? [],
        setupMatrixType: session.project?.settings?.setupMatrixType ?? "goodToGood",
    };

    const setDisabled = options?.disable || !requestOptions.eventKeys || !requestOptions.uploadId;

    return useApi(Datastores.getSetupEvents, requestOptions, [ JSON.stringify(requestOptions) ], { ...options, disable: setDisabled });
}