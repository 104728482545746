import { isNumber } from "lodash";
import React, { useState } from "react";
import { Calendar } from "../calendar/Calendar";
import TimePicker from "../time-picker/TimePicker";

export type Timestamp = {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
}

type DateTimePickerPropsType = {
    className?: string;
    value?: number | Date | Timestamp | undefined;
    onChangeTimestamp?: (timestamp: Timestamp) => void;
    onChangeDate?: (date: Date) => void;
};

export default function DateTimePicker(props: DateTimePickerPropsType) {
    const [timestamp, setTimestamp] = useState<Timestamp>(toTimestamp(props.value) ?? dateToTimestamp(new Date()));

    return <div className={props.className ?? ""}>
        <Calendar
            year={timestamp.year}
            month={timestamp.month}
            selectedDay={timestamp.day}
            onClick={(e) => { updateState({ ...timestamp, year: e.year, month: e.month, day: e.day }); }}
        />
        <TimePicker
            initialHour={timestamp.hour}
            initialMinute={timestamp.minute}
            onChange={(hour, minute) => { updateState({ ...timestamp, hour, minute }); }}
        />
    </div>;

    function updateState(state: Timestamp) {
        setTimestamp(state);
        if (props.onChangeDate)
            props.onChangeDate(new Date(state.year, state.month - 1, state.day, state.hour, state.minute, 0, 0));

        if (props.onChangeTimestamp)
            props.onChangeTimestamp({ ...state });
    }

    function toTimestamp(date: Date | number | Timestamp | undefined) {
        if (date === undefined)
            return undefined;

        if (props.value instanceof Date)
            return dateToTimestamp(props.value as Date);

        if (isNumber(props.value))
            return dateToTimestamp(new Date(props.value));

        return props.value as Timestamp;
    }

    function dateToTimestamp(date: Date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes()
        };
    }
}