import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Userpilot } from "userpilot";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import Global from "../Global";

export default function updateUserpilotUrl(isTabbedView?: boolean) {
    const location = Global.isRunningJestTest ? undefined : useLocation();
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const auth0 = useAuth0();
    const isInitialized = useRef(false);

    const lastUrl = useRef<string | undefined>();

    // We don't have japanese translations of our UP spotlights, so we're
    // reverting to the second best option: english.
    const locale = session.locale === "jp" ? "en" : session.locale;

    useEffect(() => {
        if (auth0.user?.sub) {
            Userpilot.identify(auth0.user?.sub, {
                locale_code: locale,
                projectId: session.project?.id,
            });

            if (isInitialized.current) {
                // Userpilot does not update by itself when just the locale_code
                // changed, so we're triggering it by first navigating somewhere else.
                //
                // Also, the interface does not provide a promise that could tell us
                // when the update finished, so we have to estimate that it won't take
                // longer than one second.
                Userpilot.reload({ url: "trigger_locale_change" });

                setTimeout(() => {
                    Userpilot.reload({ url: lastUrl.current });
                }, 1000);
            }

            isInitialized.current = true;
        }
    }, [
        auth0.user?.sub,
        locale,
        session.project?.id
    ]);

    useEffect(() => {
        if (isTabbedView) {
            const baseUrl = `${Global.apiEndpoint}${location?.pathname}?sidePanelPage=${settings.sidePanelPage}&filterOpen=${settings.filterEditor.showFilterEditor}`;
            const userpilotUrl = settings.filterEditor.showFilterEditor ? baseUrl + `&filterPage=${settings.filterEditor.editFilterPage}` : baseUrl;
            if (lastUrl.current === userpilotUrl)
                // Not changed, don't call API
                return;

            lastUrl.current = userpilotUrl;
            Userpilot.reload({ url: lastUrl.current });
        }
        else {
            const userpilotUrl = `${Global.apiEndpoint}${location?.pathname}`;
            if (lastUrl.current === userpilotUrl)
                // Not changed, don't call API
                return;

            lastUrl.current = userpilotUrl;
            Userpilot.reload({
                url: lastUrl.current,
            });
        }
    },
    [
        isTabbedView,
        settings.filterEditor.editFilter,
        settings.filterEditor.editFilterPage,
        settings.sidePanelPage, session.projectId,
        location,
    ]);

}