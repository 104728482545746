import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../contexts/ContextTypes";
import { useColumnValues } from "../../hooks/UseColumnValues";
import i18n from "../../i18n";
import { GroupingKeys } from "../../models/Dfg";
import Dropdown, { StringOption } from "../dropdown/Dropdown";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { KpiHighlightControls } from "./HighlightControls";

export function OrderTrackingControls() {

    return <div className="controls">
        <div className="relative">
            <div className="controlsContent">
                <OrderNumberControls/>
                <OrderTrackingAnalyzedValues/>
                <KpiHighlightControls />
                <OrderTrackingGroupingControls/>
            </div>
        </div>
    </div>;
}

export function OrderNumberControls() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const location = useLocation();

    const columnLabel = session.project?.eventKeysOrderTracking?.caseId;

    const requestOptions = {
        uploadId: session.project?.uploadIdOrderTracking, 
        eventKeys: session.project?.eventKeysOrderTracking
    };

    const columnInfos = useColumnValues(columnLabel ? [columnLabel] : [], requestOptions) ?? {};

    const ordersInitialized = columnLabel === undefined ? false : columnInfos[columnLabel] !== undefined;

    const dropdownOptions: StringOption[] = ordersInitialized ? columnInfos[columnLabel!].map(o => {
        return { label: o.value as string, value: o.value as string };
    }) : [];
    
    return <div className="section orderNumber">
        <div className="title">
            {i18n.t("common.shippingOrder")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["Order-Number"])} className="mls"/>
        </div>
        <Dropdown
            placeholder={i18n.t("common.initializing").toString()}
            isSearchable={true}
            value={dropdownOptions.find(o => o.value === settings.orderTracking.orderFilter)!}
            data-testid={"selectObjectType"}
            onChange={(o) => {
                const order = o!.value as string;
                settings.set({
                    ...settings,
                    orderTracking: {
                        ...settings.orderTracking,
                        orderFilter: order,
                    }
                });
            }}
            options={dropdownOptions} />
    </div>;
}

export function OrderTrackingGroupingControls() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const location = useLocation();

    const secondGroupingOptions = [ {
        label: i18n.t("common.case"),
        value: GroupingKeys.MachineType,
    },
    ];

    return <div className="section grouping">
        <div className="title">
            {i18n.t("common.grouping")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["grouping"])} className="mls"/>
        </div>
        <div className="buttons-2col">
            <button onClick={() => { settings.set({ groupingKey: GroupingKeys.Machine}); }} className={settings.groupingKey === GroupingKeys.Machine ? "active" : ""}>{i18n.t("common.machine")}</button>
            <button disabled={session.project?.eventKeysOrderTracking?.machineType === undefined} onClick={() => { settings.set({ groupingKey: GroupingKeys.MachineType}); }} className={settings.groupingKey === GroupingKeys.MachineType ? "active" : ""}>{i18n.t("common.case")}</button>
        </div>
        {settings.groupingKey === GroupingKeys.Machine && <div className="buttons-1col mtLarge">
            <label>{i18n.t("common.secondGrouping")}</label>
            <Dropdown
                className="secondaryGrouping"
                value={secondGroupingOptions.find((v) => {
                    return v.value === settings.graph.secondGroupingLevel.toString();
                }) ?? secondGroupingOptions[0]}
                options={secondGroupingOptions}
                onChange={(option, action) => {
                    if (option && action.action === "select-option" && option.value !== undefined) {
                        settings.setGraph({secondGroupingLevel: option.value as GroupingKeys});
                    }
                }}
            />
        </div>}
    </div>;
}

export function OrderTrackingAnalyzedValues() {
    const settings = useContext(SettingsContext);
    const location = useLocation();

    return <div className="section orderTrackingAnalyzeValues">
        <div className="title">
            {i18n.t("common.kpi")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["Kpi"])} className="mls"/>
        </div>
        <div className={"buttons-2col mt"} data-testid="side-controls">
            <button
                onClick={() => { settings.setKpiState({ analyzedValue: LegacyAnalyzedValues.TimeValuesMean }); }}
                className={settings.kpi.analyzedValue === LegacyAnalyzedValues.TimeValuesMean ? "active" : ""}>
                {i18n.t("time")}
            </button>
            <button
                onClick={() => { settings.setKpiState({ analyzedValue: LegacyAnalyzedValues.QualityQuota }); }}
                className={settings.kpi.analyzedValue === LegacyAnalyzedValues.QualityQuota ? "active" : ""}>
                {i18n.t("quality.relativeScrap")}
            </button>
        </div>
    </div>;
}