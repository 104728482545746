import { capitalize } from "lodash";
import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Variant } from "../../models/ApiTypes";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import { Stats } from "../../models/Stats";
import { Formatter } from "../../utils/Formatter";
import { getAssignedQuantities, getQuantity, QuantityType } from "../../utils/Quantities";
import { classNames } from "../../utils/Utils";

type State = {
    top: number;
    left: number;
}

export interface IVariantTable {
    updateScrollPosition(top: number, left: number): void;

    /**
     * Invalidate cached rows and re-render
     */
    invalidate(): void;
}

export type VariantTableProps = {
    width: number;
    height: number;
    data: Variant[];

    hidden: Variant[];

    selectedIds: string[];

    quantity: string;

    /**
     * Number of cases covered in the variants provided
     */
    numVariantCases: number;

    onVariantClicked?: (variant: Variant, index: number) => void;
};

export const VariantTable = React.forwardRef((props: VariantTableProps, ref: React.Ref<IVariantTable>) => {
    const session = useContext(SessionContext);
    const [view, setView] = useState<State>({
        top: 0,
        left: 0,
    });

    const showYieldColumns = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).length > 0;

    const selectedIdHash = useMemo(() => {
        const result = new Set<string>();
        for (const id of props.selectedIds)
            result.add(id);

        return result;
    }, [
        props.selectedIds
    ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, triggerRedraw] = useState<number>(0);

    const renderedElementIds = useRef<Set<string>>(new Set<string>());
    const renderedElements = useRef<JSX.Element[]>([]);

    useImperativeHandle(ref, () => ({
        invalidate: () => {
            renderedElementIds.current = new Set<string>();
            renderedElements.current = [];
            renderViewport(view.top);
            triggerRedraw(Math.random());
        },
        updateScrollPosition: (top: number, left: number) => {
            setView({ top, left });
            triggerRedraw(Math.random());
            renderViewport(top);
        }
    }));

    useEffect(() => {
        renderedElementIds.current = new Set<string>();
        renderedElements.current = [];
        renderViewport(view.top);
        triggerRedraw(Math.random());
    }, [
        props.data,
        session.numberFormatLocale,
        session.locale,
        props.hidden,
    ]);

    return <>{renderedElements.current}</>;

    function renderViewport(top: number) {
        const quantity = getQuantity(props.quantity, false);
        const quantityStats = "caseYield" + capitalize(quantity?.id) + "Statistics";

        const flowQuantity = getQuantity(props.quantity, true);
        const flowQuantityStats = "caseYieldRate" + capitalize(quantity?.id) + "Statistics";

        // Determine row IDs in view
        const firstRow = Math.floor(top / colors.$tableRowHeight);
        const lastRow = Math.ceil((top + props.height) / colors.$tableRowHeight);

        // Render rows that haven't been rendered already
        const numElements = props.data.length + props.hidden.length;

        for (let i = firstRow; i <= lastRow; i++) {
            if (i < 0 && i >= numElements)
                continue;

            const data = props.data[i] ?? props.hidden[i - props.data.length];
            if (!data || renderedElementIds.current.has(data.id))
                continue;


            renderedElementIds.current.add(data.id);

            const quantityValue = (data[quantityStats] as Stats | undefined)?.sum;
            const flowQuantityValue = (data[flowQuantityStats] as Stats | undefined)?.mean;

            const isDisabled = i >= props.data.length;
            const isSelected = selectedIdHash.has(data.id);

            renderedElements.current.push(<div
                className={classNames(["variantGrid", isSelected && "selected", isDisabled && "disabled", !showYieldColumns && "variantsGridShort"])}
                data-testid={isSelected ? "selectedVariantTableRow" : "variantTableRow"}
                aria-selected="true"
                onClick={isDisabled ? undefined : () => {
                    if (!props.onVariantClicked)
                        return;

                    props.onVariantClicked(data, i);
                }}
                key={data.id}
                style={{
                    top: i * colors.$tableRowHeight,
                    position: "absolute",
                }}>
                <div>{Formatter.formatNumber(i + 1, 0, session.numberFormatLocale)}</div>
                <div>{Formatter.formatNumber(data.count, 0, session.numberFormatLocale)}</div>
                {props.numVariantCases > 0 && <div>{Formatter.formatPercent(data.count, props.numVariantCases, undefined, session.numberFormatLocale)}</div>}
                {!props.numVariantCases && <div></div>}
                <div>{Formatter.formatDurationShort(data.caseDurationStatistics?.mean, undefined, session.numberFormatLocale)}</div>

                {showYieldColumns && <>
                    <div>
                        {quantity?.unit.formatter(quantityValue, {
                            locale: session.numberFormatLocale
                        })}
                    </div>
                    <div>
                        {flowQuantity?.unit.formatter(flowQuantityValue, {
                            locale: session.numberFormatLocale
                        })}
                    </div>
                </>}
            </div>);
        }
    }
});
