/**
 * Displays the configuration of a single attribute filter element.
 */

import { isArray } from "lodash";
import React from "react";
import i18n from "../../../i18n";
import { EventFilter } from "../../../models/EventFilter";
import { getAttributeFilterMetadata } from "../editors/attribute-filter-editor/AttributeHelpers";
 
 type AttributeFilterStatusPropsType = {
     filter: EventFilter,
     onRemove?: (filter: EventFilter) => void,
     onClick?: () => void,
     isEdited?: boolean
 };


export default function AttributeFilterStatus(props: AttributeFilterStatusPropsType): JSX.Element | null {
    const meta = getAttributeFilterMetadata(props.filter);
    const numValues = meta.value !== undefined ? (isArray(meta.value) ? meta.value.length : 1) : 0;

    return <div className={"filter attributeFilter" + (props.isEdited ? " edit" : "")} onClick={onClick}>
        <svg className="svg-icon small"><use xlinkHref="#radix-list-bullet" /></svg>

        <div>
            {i18n.t("filters.attribute.title")} <br />
            <b>{meta.attributeName} {meta.operator?.label} {numValues === 1 ? meta.valueFormatted : i18n.t("filters.attribute.multipleElements", {numValues})}</b>
        </div>

        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {props.onRemove && <svg onClick={(e) => { props.onRemove!(props.filter); e.stopPropagation(); }} className="svg-icon xtiny closer"><use xlinkHref="#radix-cross-1" /></svg>}
    </div>;
 
    function onClick() {
        if (props.onClick)
            props.onClick();
    }
}