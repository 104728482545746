import React, { useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import CustomTable, { PaginationModes } from "../custom-table/CustomTable";
import FileUpload from "../file-upload/FileUpload";
import Toast, { ToastTypes } from "../toast/Toast";
import Global from "../../Global";
import i18n from "../../i18n";
import { Upload } from "../../models/Upload";
import { Formatter } from "../../utils/Formatter";
import { SessionContext } from "../../contexts/SessionContext";
import { Datastores } from "../../utils/Datastores";
import { SettingsContext } from "../../contexts/SettingsContext";
import updateUserpilotUrl from "../../utils/Userpilot";
import Spinner from "../spinner/Spinner";
import { Spotlight } from "../spotlight/Spotlight";

type SelectFileStateType = {
    error?: any;
};

type SelectFilePropsType = {
    navigateTo: (upload: Upload) => void
}

export default function SelectFile(props: SelectFilePropsType) {
    const session = useContext(SessionContext);

    updateUserpilotUrl();

    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);

    const [uploads, setUploads] = useState<Upload[]>([]);

    const [state, setState] = useState<SelectFileStateType>({
    });

    const columns: Column<Upload>[] = [
        {
            Header: i18n.t("browser.filename").toString(),
            accessor: "filename"
        },
        {
            Header: i18n.t("browser.filesize").toString(),
            accessor: "size",
            // eslint-disable-next-line react/prop-types
            Cell: props => <span>{Formatter.formatFileSize(props.value, undefined, session.numberFormatLocale)}</span>
        },
        {
            Header: i18n.t("browser.filenameExtension").toString(),
            accessor: "filenameExtension",
            // eslint-disable-next-line react/prop-types
            Cell: props => <span>{props.value}</span>
        },
        {
            Header: i18n.t("browser.created").toString(),
            accessor: "created",
            // eslint-disable-next-line react/prop-types
            Cell: props => <span>{Formatter.formatDateTime(props.value, "", session.locale, session.timezone)}</span>
        }
    ];

    const settingsContext = useContext(SettingsContext);

    const [subscriptionId, setSubscriptionId] = useState<number>(0);
    useEffect(() => {
        const subscriptionId = Datastores.getUploads.getSubscriptionId();
        setSubscriptionId(subscriptionId);

        return () => {
            Datastores.getUploads.cancelSubscription(subscriptionId);
        };
    }, []);

    useEffect(() => {
        if (!subscriptionId)
            return;
            
        Datastores.getUploads.get({
            sort: "-created",
        }, subscriptionId).then((result) => {
            setUploads(result);
        });
    }, [session.user, settingsContext.apiRetry, subscriptionId]);

    return <div className="createProject defaultPage">
        <Spinner isLoading={isUploadComplete} />
        {!!uploads?.length && <CustomTable
            data={uploads}
            columns={columns}
            paginationMode={PaginationModes.Fixed}
            onRowClick={(row) => {
                props.navigateTo(row);
            }}
            pageSize={10} />}

        <FileUpload
            targetUrl={Global.apiEndpoint + "/api/processmining/uploads"}
            multiple={false}
            fileExtensions={["csv", "xes"]}
            headers={Global.defaultRequestOptions.headers}
            onFileUploaded={() => {
                setIsUploadComplete(true);
            }}
            onFileUploadComplete={async (e) => {
                setState({ error: undefined });
                props.navigateTo(e.response as Upload);
            }}
            onUploadFailed={(f) => {
                setState({
                    error: i18n.t("browser.uploadError", {
                        filename: f.name
                    })
                });
            }}
            autoUpload={true}
        />

        <Toast visible={state.error} type={ToastTypes.Info}>{state.error}</Toast>
        <Spotlight id="Data-Sources" className="bottomRight" />
    </div>;
}
