export function useLocalStorage<T>(containerId: string): [ {[key: string]: T}, (key: string, value: T) => void] {
    let result: {[key: string]: T} = {};
    const elem = localStorage.getItem(containerId);
    if (elem !== undefined && elem !== null)
        result = JSON.parse(elem);

    return [result, (key: string, value: T) => {
        result[key] = value;
        localStorage.setItem(containerId, JSON.stringify(result));
    }];
}