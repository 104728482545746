import interpolate from "color-interpolate";
import { get } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContextType } from "../../contexts/SettingsContext";
import { useColumnValues } from "../../hooks/UseColumnValues";
import { useCaseGantt } from "../../hooks/UseCaseGantt";
import i18n from "../../i18n";
import { GroupingKeys } from "../../models/Dfg";
import { isStandardNode } from "../../utils/DfgUtils";
import { buildCaseFilter } from "../../utils/FilterBuilder";
import { RenderProcessGantt } from "../../views/gantt/ProcessGantt";
import Toast from "../toast/Toast";
import { OrderTrackingShortcuts, useOrderTrackingArguments, useOrderTrackingGraph } from "./OrderTrackingGraph";
import { ActivitySortMode, TimeMode, CaseGanttData, GanttEntry } from "../../models/ApiTypes";

export type OrderTrackingGanttProps = {
    globalSettings: SettingsContextType;
}

export default function OrderTrackingGantt(props: OrderTrackingGanttProps) {
    const session = useContext(SessionContext);
    const orderTrackingArguments = useOrderTrackingArguments();
    const graph = useOrderTrackingGraph({
        groupingKey: GroupingKeys.MachineType,
    });
    const [isToastClosed, setIsToastClosed] = useState(false);

    // note that the machineType for the order tracking is always containing the order ids
    const orderIdColumn = session.project?.eventKeysOrderTracking?.machineType;
    const orderIdResponse = useColumnValues([orderIdColumn!], orderTrackingArguments, !orderTrackingArguments?.eventFilters || !orderIdColumn);
    const orderIdColumnValues = (orderIdResponse && orderIdColumn)? get(orderIdResponse, orderIdColumn) : undefined;
    const orderIds = orderIdColumnValues ? orderIdColumnValues.map(o => o.value) : [];
    const caseFilter = orderIds ? buildCaseFilter(orderIds as string[], false, session) : undefined;

    const [gantt, isLoading] = useCaseGantt({
        eventFilters: caseFilter ? [caseFilter] : [],
        sortMode: ActivitySortMode.Occurence,
        timeMode: TimeMode.Absolute,
        sort: "startTime"
    }, GroupingKeys.Machine, !caseFilter);

    const processGantt = useMemo(() => {
        setIsToastClosed(false); // new gantt means new toast since other order might not be available
        return gantt ? convertCaseGanttToProcessGantt(gantt) : undefined;
    }, [
        gantt
    ]);

    const colormap = interpolate([colors.$blue]);

    // The total number of nodes in the graph for machine type grouping key is
    // the number of cases in the shipping order.
    const shippingOrderCaseCount = (graph?.nodes.filter(n => isStandardNode(n)) || []).length;
    const missingCasesCount = shippingOrderCaseCount - (gantt?.cases.length || 0);

    return <RenderProcessGantt
        gantt={processGantt}
        isLoading={isLoading}
        bottom={<>
            <Toast className="mt toastCondensed" visible={!isToastClosed && missingCasesCount > 0} onClose={() => setIsToastClosed(true)}>
                {i18n.t("orderTracking.missingCasesToast", {
                    case_count: missingCasesCount,
                    total_case_count: shippingOrderCaseCount
                })}
            </Toast>
            <OrderTrackingShortcuts className="shortcutsStackable" globalSettings={props.globalSettings} />
        </>}
        ganttGroupLabel={"common.case"}
        colormap={colormap}
        noDataPlaceholder={<div className="noKpisAvailable">
            {i18n.t("orderTracking.noShippingOrderInfo")}
        </div>}
        graph={graph}
    />;
}


function convertCaseGanttToProcessGantt(caseGantt: CaseGanttData): GanttEntry[] | undefined{
    const processGantt = [...caseGantt.cases];
    if (processGantt === undefined)
        return;
    return processGantt.map(c => {
        return {
            // For the process gantt we replace the information on what machine is running by the frequency of 1.
            // This isn't really the case as we might have overlapping events going on, but for the purpose of this chart
            // the user is expecting to see the "number of active cases" in which case the "1" is correct.
            events: c.events.map(e => [e[0], e[1], 1]),
            activity: c.caseId,
            activityValues: {
                operation: {value: c.caseId, nUnique: 1},
                machine: {value: c.caseId, nUnique: 1},
                machineType: {value: c.caseId, nUnique: 1}
            }
        };
    });
}
