import React from "react";
import { timeAndThroughputCalcOptions } from "../../models/ApiTypes";
import { SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { KpiChartParams } from "./ProcessKpiChart";
import { ProductChart } from "../../components/product-chart/ProductChart";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { Legend } from "../../components/graph/Legend";
import colors from "../../colors.json";

export function ProductProcessKpiChart(props: KpiChartParams & { singleObjectStatsValid?: boolean, noDataPlaceholder: string, addEnergyStats?: boolean }) {

    return <ProductChart
        requestOptions={timeAndThroughputCalcOptions}
        title={props.titleKey}
        height={props.height}
        width={props.width}
        noDataPlaceholder={props.noDataPlaceholder}
        addEnergyStats={props.addEnergyStats}
        singleObjectStatsValid={props.singleObjectStatsValid}
    />;
}

export function getLegend(settings: SettingsType, isHighlightEnabled = false): JSX.Element {
    if (settings.kpi.comparisons === KpiComparisons.None)
        return <></>;

    const isHighlighted = isHighlightEnabled && settings.kpi.comparisons === KpiComparisons.Planning;

    if (!isHighlighted) {
        if (settings.kpi.comparisons === KpiComparisons.Planning)
            return <Legend
                items={[{
                    color: colors.$graphPositiveValueColor,
                    label: i18n.t("workflows.planningDeviation.actual").toString(),
                }, {
                    color: colors.$plan,
                    label: i18n.t("workflows.planningDeviation.plan").toString(),
                }]}
            />;

        // Best process
        return <Legend
            items={[{
                color: colors.$graphPositiveValueColor,
                label: i18n.t("workflows.planningDeviation.actual").toString(),
            }, {
                color: colors.$bestCase,
                label: i18n.t("kpiComparisons.bestProcesses").toString(),
            }]}
        />;
    }

    // Highlight enabled: value bars with a worse value than planned will be red,
    // and green otherwise.
    if (settings.kpi.comparisons === KpiComparisons.Planning)
        return <Legend
            items={[{
                color: colors.$setupGraphActualFaster,
                label: i18n.t("workflows.planningDeviation.betterThanPlan").toString(),
            }, {
                color: colors.$setupGraphActualSlower,
                label: i18n.t("workflows.planningDeviation.worseThanPlan").toString(),
            }, {
                color: colors.$unplanned,
                label: i18n.t("workflows.planningDeviation.actualUnplanned").toString(),
            }, {
                color: colors.$plan,
                label: i18n.t("workflows.planningDeviation.plan").toString(),
            }]
            } />;

    return <></>;
}
