import { capitalize, isEmpty, isEqual, toLower } from "lodash";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SessionContext, SessionContextType, isOniqEmployee } from "../../contexts/SessionContext";
import { ProductIdentifier, SelectionType, SettingsContext, SettingsContextType, getPushedHistory, getRecentRcaByType, modifyRcaByType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { BaseGraph, GroupingKeys, Node, NodeRoles } from "../../models/Dfg";
import { EventFilter, isFilterEqual } from "../../models/EventFilter";
import { DfgUtils, isTerminalNode } from "../../utils/DfgUtils";
import { buildCaseFilter, buildEdgeFilter, buildNodeFilter, buildProductCategoryFilter, buildProductFilter, buildTimeFilter, buildVariantFilter, isProductFilter } from "../../utils/FilterBuilder";
import { getPlanningState, isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { Timestamp, addStep } from "../../utils/TimezoneUtils";
import { Dimensions, getDimensionFromPathname } from "../dimension/Dimension";
import Menu, { MenuItem, MenuPlacementsVertical } from "../menu/Menu";
import { navigateWith } from "../tabbed-view/TabbedView";
import { AggregationTypes, KpiComparisons, LegacyAnalyzedValues, RcaType } from "../../contexts/ContextTypes";
import { QuantityType, getAssignedGroupingKeys, getAssignedQuantities, getQuantity, quantities } from "../../utils/Quantities";
import { isRcaVisible } from "../../views/rca/RootCauseView";
import { CaseGanttSetting, disableAllCalcOptions, RcatypeTargets, timePeriodFrequenciesArray } from "../../models/ApiTypes";
import { useGraph } from "../../hooks/UseGraph";
import { getRouteFromLocation } from "../../Routing";
import { useProductCaseAggregations } from "../../hooks/UseProductCaseAggregations";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useOrderSequenceMachineIds } from "../../hooks/UseOrderSequenceMachines";
import { getTargetDimensionAndKpi, KpiTypes, SortOrder } from "../../models/KpiTypes";
import { submitRca } from "../../views/rca/RcaCreationUtils";

export enum ShortcutContexts {
    ProductNodes,
    Node,
    Edge,
    Case,
    Product,
    Category,
    Variant,
    Timeperiod,
}

export enum ActionType {
    Filter = 1,
    Other = 2,
}

export type ActionItem = MenuItem & {
    type: ActionType,
}

/**
 * This class is used to display our action buttons. By providing a couple of ShortcutContexts you
 * can make it react to selections in the app. However, you can also add whatever action you want using
 * the options prop.
 * @returns
 */
export type ShortcutsProps = {
    /**
     * Actions to display
     */
    actions?: ActionItem[];

    /**
     * Default actions to display when nothing is selected
     */
    defaultActions?: ActionItem[];

    disabledActionTypes?: ActionType[];

    additionalButtons?: JSX.Element;

    /**
     * The selections that this component should listen to.
     *  - Edge: provide a graph instance
     */
    handledSelections?: ShortcutContexts[];

    /**
     * If the shortcuts are handling edge selections, be sure to provide a graph instance.
     * If you fail to do so, the menu item will be spinning for all eternity.
     */
    graph?: BaseGraph;

    stack?: boolean;
};


export default function Shortcuts(props: ShortcutsProps) {
    function has(context: ShortcutContexts) {
        return (props.handledSelections ?? []).indexOf(context) >= 0;
    }

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const navigate = useNavigate();
    const location = useLocation();
    const currentDimension = getDimensionFromPathname(location.pathname) ?? Dimensions.Timing;
    const isOniqUser = isOniqEmployee(session);
    const { trackEvent } = useMatomo();

    const { hasPlanning } = getPlanningState(session);

    const hasEquipmentComparison = session.project?.features?.allowEquipmentComparison || isOniqUser;
    const hasComparison = session.project?.features?.allowProcessWorkplaceComparison !== false || hasEquipmentComparison;
    const comparisonUrl = hasEquipmentComparison ? `/projects/${session.projectId}/analyses/equipments/comparison` : `/projects/${session.projectId}/analyses/workplaces/comparison`;

    const hasYieldData = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).length > 0;
    const assignedGroupingKeys = getAssignedGroupingKeys(session.project?.eventKeys);

    const actions = [...(props.actions ?? [])];

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const isEdgeSelected = has(ShortcutContexts.Edge) && settings.selection.edge && props.graph?.nodes && !DfgUtils.isAfterStartOrBeforeEndEdge(settings.selection.edge, props.graph.nodes) && !isObjectCentric;
    const isProductSelected = has(ShortcutContexts.Product) && settings.selection.product;

    const isSomethingSelected = (has(ShortcutContexts.Node) && settings.selection.node && settings.selection.node.role !== NodeRoles.Start && settings.selection.node.role !== NodeRoles.End) ||
        (has(ShortcutContexts.ProductNodes) && settings.selection.node && settings.selection.node.role === NodeRoles.Machine) ||
        isEdgeSelected ||
        (has(ShortcutContexts.Case) && settings.selection.case) ||
        (has(ShortcutContexts.Category) && settings.selection.category && settings.selection.categoryValue) ||
        isProductSelected ||
        (has(ShortcutContexts.Timeperiod) && settings.selection.timeperiod) ||
        (has(ShortcutContexts.Variant) && settings.processPath.processPathSelectedIds.length > 0);

    if (isSomethingSelected) {
        actions.push({
            id: "restrictToSelectionButton",
            title: "shortcuts.restrictToSelection",
            tooltip: "shortcuts.restrictToSelectionHelper",
            onClick: () => { addFilter(true); },
            isLoading: isEdgeSelected && !props.graph,
            type: ActionType.Filter,
        });

        actions.push({
            id: "excludeSelectionButton",
            title: "shortcuts.excludeSelection",
            tooltip: "shortcuts.excludeSelectionHelper",
            onClick: () => { addFilter(false); },
            isLoading: isEdgeSelected && !props.graph,
            type: ActionType.Filter,
        });
    }

    const route = getRouteFromLocation(location.pathname);
    const isInProcessKpisView = route.route?.includes(`${route.dimension}/kpis/:tabSlug`);
    const isValueStreamView = route.route?.includes(`${route.dimension}/process/:tabSlug`);
    const isValueStreamDiagram = route.route?.includes("analyses/value-stream/:tabSlug");
    const isCycleTimeView = route.route?.includes("analyses/cycle-time/:tabSlug");

    const isComparisonView = route.route?.includes("analyses/equipments/:tabSlug") || route.route?.includes("analyses/workplaces/:tabSlug");

    const isNothingSelected = !settings.selection.node &&
        !settings.selection.edge &&
        !settings.selection.case &&
        !settings.selection.product &&
        !settings.selection.category &&
        !settings.selection.timeperiod;

    const [productCount, isProductCountLoading] = useProductCaseAggregations({ limit: 1, }, !isInProcessKpisView && !isValueStreamView && !isValueStreamDiagram && !isComparisonView);
    const isSingleProduct = productCount?.log.productCount === 1 && !isProductCountLoading;

    // #region Machine Grouping
    const showMachinesOfPassId = settings.groupingKey === GroupingKeys.MachineValueStream && isSingleProduct &&
        settings.selection.node !== undefined && settings.selection.node.role !== NodeRoles.Inventory;

    const selectedPassId = settings.selection.node?.activityValues?.passId?.value;
    const machineIdsRequest = useOrderSequenceMachineIds([selectedPassId ?? "0"], {}, { disable: selectedPassId === undefined || !showMachinesOfPassId });

    if (showMachinesOfPassId) {
        // Match to nodes from nonSeparatedPassIdGraph
        actions.push({
            title: "shortcuts.comparePassIdMachines",
            id: "compare-pass-id-machines",
            isHidden: selectedPassId === undefined || !hasComparison,
            isLoading: machineIdsRequest.isLoading,
            type: ActionType.Other,
            onClick: () => {
                // Add a filter that filters for machines with the given pass id
                navigateWith(settings, navigate, {
                    kpi: settings.kpi,
                    kpiMatrix: {
                        ...settings.kpiMatrix,
                        machines: machineIdsRequest.machineIds,
                    },
                    history: getPushedHistory(location.pathname, settings),
                }, comparisonUrl);
            },
        });
    }


    // #region Timeperiod
    if (has(ShortcutContexts.Timeperiod) && settings.selection.timeperiod) {
        const timeFilter = getTimeperiodFiltersFromSelection(settings, session)?.additive;
        const filtersWithTimeFilter = ([] as EventFilter[]).concat(settings.filters ?? []).filter(f => !isFilterEqual(f, timeFilter)).concat(timeFilter ? [timeFilter] : []);

        const ganttTargetDimension = currentDimension === Dimensions.Output ?
            currentDimension :
            Dimensions.Timing;

        const currentFrequencyIndex = timePeriodFrequenciesArray.indexOf(settings.kpi.timeScale);
        // We only add the time details button if the current frequency is not the smallest one.
        // Therefore we check whether the index is greater than zero.
        if (currentFrequencyIndex > 0) {
            const newFrequency = timePeriodFrequenciesArray[currentFrequencyIndex - 1];
            actions.push({
                title: `shortcuts.showTimeDetails.${newFrequency}`,
                id: `time-details-${toLower(newFrequency)}`,
                type: ActionType.Other,
                isHidden: !timeFilter,
                onClick: () => {
                    navigateWith(settings, navigate, {
                        filters: filtersWithTimeFilter,
                        kpi: { ...settings.kpi, timeScale: newFrequency },
                        history: getPushedHistory(location.pathname, settings),
                    }, `/projects/${session.projectId}/${currentDimension}/kpis/process`);
                },
            });
        }

        actions.push({
            title: "shortcuts.showOrderGantt",
            id: "order-gantt-timeperiod",
            type: ActionType.Other,
            isHidden: !timeFilter,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: filtersWithTimeFilter,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/${ganttTargetDimension}/process/case-gantt`);
            },
        });
    }
    // #endregion

    // #region Order selection
    if (has(ShortcutContexts.Case) && settings.selection.case) {
        const caseFilter = buildCaseFilter([settings.selection.case], false, session);
        const filtersWithCaseFilter = ([] as EventFilter[]).concat(settings.filters ?? []).filter(f => !isFilterEqual(f, caseFilter)).concat(caseFilter ? [caseFilter] : []);

        actions.push({
            title: "shortcuts.showValueStream",
            id: "dfg-orders",
            isHidden: !caseFilter,
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: filtersWithCaseFilter,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/${currentDimension}/process/dfg`);
            }
        });

        actions.push({
            title: "shortcuts.showDeviationValueStream",
            id: "deviation-orders",
            isHidden: currentDimension !== Dimensions.Timing || !caseFilter || !hasPlanning,
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: filtersWithCaseFilter,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/${currentDimension}/process/deviation`);
            },
        });

        actions.push({
            title: "shortcuts.showProcessGantt",
            id: "process-gantt-orders",
            isHidden: !caseFilter,
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: filtersWithCaseFilter,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/timings/process/process-gantt`);
            }
        });
    }
    // #endregion

    if (isValueStreamDiagram) {
        const target = getTargetDimensionAndKpi(settings.kpi.selectedKpi);
        if (target) {
            actions.push({
                title: "shortcuts.analyzeValueStream",
                id: "value-stream",
                type: ActionType.Other,
                onClick: () => {
                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                        kpi: { selectedKpi: target.kpi },
                    }, `/projects/${session.projectId}/${target.dimension}/process/dfg`);
                },
            });
        }
    }

    // #region Node
    const [machineIds, isMachineIdLoading] = useMachineIds(
        settings.selection.node,
        (selected: Node | undefined, node: Node) => selected?.activityValues?.machine?.value === node?.activityValues?.machine?.value &&
            selected?.activityValues?.machineType?.value === node?.activityValues?.machineType?.value &&
            selected?.activityValues?.location?.value === node?.activityValues?.location?.value,
        { disable: ![GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey) || !has(ShortcutContexts.Node) || !settings.selection.node, filter: settings.filters });

    const [allMachineIds, isAllMachineIdsLoading] = useMachineIds(
        undefined,
        (selected: Node | undefined, node: Node) => node.role !== NodeRoles.Inventory && !isTerminalNode(node),
        { disable: !isSingleProduct, filter: settings.filters });

    const [machineTypeIds, areMachineTypeIdsLoading] = useMachineIds(
        settings.selection.node,
        (selected: Node | undefined, node: Node) => node.activityValues?.machineType?.value === selected?.activityValues?.machineType?.value,
        { disable: ![GroupingKeys.MachineType, GroupingKeys.MachineTypeValueStream].includes(settings.groupingKey) || !has(ShortcutContexts.Node) || !settings.selection.node, filter: settings.filters });

    const [orderSequenceIds, areOrderSequenceIdsLoading] = useMachineIds(
        settings.selection.node,
        (selected: Node | undefined, node: Node) => node.activityValues?.passId?.value === selected?.activityValues?.passId?.value,
        { disable: settings.groupingKey !== GroupingKeys.PassValueStream || !has(ShortcutContexts.Node) || !settings.selection.node, filter: settings.filters });

    const machineIdToNames = useGraph({
        ...disableAllCalcOptions,
        groupingKey: GroupingKeys.Machine,
        eventFilters: [],
        calculateActivityValues: true,
        calculateNodes: true,
    }, undefined, false, !isComparisonView);

    if (has(ShortcutContexts.Node) && settings.selection.node && settings.selection.node.role !== NodeRoles.Inventory && !isTerminalNode(settings.selection.node)) {
        actions.push({
            title: "shortcuts.showSetupMatrix",
            id: "setup-matrix",
            isHidden: !(session.project?.features?.allowSetupMatrix || isOniqUser) ||
                ![GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey),
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    kpiMatrix: {
                        machineName: settings.selection.node?.activityValues?.machine?.value,
                        machines: [settings.selection.node?.activityValues?.machine?.value].filter(m => m !== undefined),
                    },
                }, `/projects/${session.projectId}/analyses/setup/matrix`);
            }
        });

        const nodeFilter = buildNodeFilter(settings.selection.node, false, settings.groupingKey);
        const filtersWithNodeFilter = ([] as EventFilter[]).concat(settings.filters ?? []).filter(f => !isFilterEqual(f, nodeFilter)).concat(nodeFilter ? [nodeFilter] : []);

        actions.push({
            title: "shortcuts.showProductsOfActivity",
            id: "products-for-machine",
            type: ActionType.Other,
            isHidden: settings.selection.node.activityValues?.product?.nUnique === 1,
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    filters: filtersWithNodeFilter,
                    kpi: { selectedKpi: KpiTypes.OrderCount, aggregation: AggregationTypes.Product },
                }, `/projects/${session.projectId}/output/kpis/process`);
            }
        });

        // Workplace comparison: Machine grouping
        actions.push({
            title: "shortcuts.showWorkplaceComparison",
            id: "workplace-comparison",
            disabled: !isProductCountLoading && productCount && productCount?.log?.productCount === 0,
            isHidden: ![GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey) || showMachinesOfPassId || !hasComparison,
            type: ActionType.Other,
            isLoading: isMachineIdLoading || isProductCountLoading,
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    kpiMatrix: {
                        machines: (machineIds ?? []).filter(m => m !== undefined),
                    },
                }, comparisonUrl);
            },
        });

        // Workplace comparison: Machine type grouping
        actions.push({
            title: "shortcuts.showWorkplaceComparison",
            id: "workplace-comparison-machine-type",
            isHidden: ![GroupingKeys.MachineType, GroupingKeys.MachineTypeValueStream].includes(settings.groupingKey) || !hasComparison,
            type: ActionType.Other,
            isLoading: areMachineTypeIdsLoading,
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    kpiMatrix: {
                        machines: (machineTypeIds ?? []).filter(m => m !== undefined),
                    },
                }, comparisonUrl);
            },
        });

        // Workplace comparison: Order sequence grouping
        actions.push({
            title: "shortcuts.showWorkplaceComparison",
            id: "workplace-comparison-order-sequence-machines",
            isHidden: settings.groupingKey !== GroupingKeys.PassValueStream || !hasComparison,
            disabled: !orderSequenceIds?.length,
            type: ActionType.Other,
            isLoading: areOrderSequenceIdsLoading,
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    kpiMatrix: {
                        machines: (orderSequenceIds ?? []).filter(m => m !== undefined),
                    },
                }, comparisonUrl);
            },
        });

        // Jump from a selected node to the case gantt filtered to that node
        actions.push({
            title: "shortcuts.showOrderGantt",
            id: "order-gantt-for-machine",
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    gantt: {
                        restrictToNode: settings.selection.node?.id,
                        caseGanttSettings: CaseGanttSetting.StartTime,
                        sortOrder: SortOrder.Ascending,
                    },
                }, `/projects/${session.projectId}/timings/process/case-gantt`);
            },
        });

    }
    // #endregion

    // #region Products
    if ((has(ShortcutContexts.Product) && settings.selection.product) ||
        (has(ShortcutContexts.ProductNodes) && settings.selection.node?.role === NodeRoles.Machine)) {
        const productId = (has(ShortcutContexts.Product) && settings.selection.product) ?
            settings.selection.product :
            { name: settings.selection.node?.name ?? "" } as ProductIdentifier;

        const productFilter = buildProductFilter([productId], false, session);
        const filtersWithProductFilter = ([] as EventFilter[]).concat(settings.filters ?? []).filter(f => !isFilterEqual(f, productFilter)).concat(productFilter ? [productFilter] : []);

        actions.push({
            title: "shortcuts.showValueStream",
            id: "valuestream",
            isHidden: !productFilter,
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: filtersWithProductFilter,
                    kpi: settings.kpi,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/${currentDimension}/process/dfg`);
            }
        });

        // Deviations are available
        actions.push({
            title: "shortcuts.showDeviationValueStream",
            id: "deviation-valuestream",
            isHidden: currentDimension !== Dimensions.Timing ||
                settings.kpi.comparisons !== KpiComparisons.Planning ||
                !productFilter ||
                !hasPlanning,
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: settings.kpi.comparisons === KpiComparisons.Planning ? filtersWithProductFilter : settings.filters,
                    kpi: settings.kpi,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/timings/process/deviation`);
            }
        });

        actions.push({
            title: "shortcuts.showOrders",
            id: "order-aggregation",
            type: ActionType.Other,
            isHidden: !productFilter,
            onClick: () => {
                navigateWith(settings, navigate, {
                    filters: filtersWithProductFilter,
                    kpi: { ...settings.kpi, aggregation: AggregationTypes.Case },
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/${currentDimension}/kpis/process`);
            },
        });
    }
    // #endregion

    const addCycleTimeButton = (grouping?: GroupingKeys) => {
        actions.push({
            title: "shortcuts.showCycleTimes",
            id: "value-stream-diagram-cycletimes",
            type: ActionType.Other,
            isHidden: !hasYieldData || isValueStreamView || assignedGroupingKeys.length <= 1 || session.project?.features?.allowTaktTime === false,
            onClick: () => {
                navigateWith(settings, navigate, {
                    groupingKey: grouping ?? settings.groupingKey,
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/analyses/cycle-time/summary`);
            },
        });
    };

    const isProductBottleneck = session.project?.settings?.bottleneckType === "product";
    // #region Value Stream Views
    if ((isValueStreamDiagram || isValueStreamView || isCycleTimeView) && !settings.selection.node && !settings.selection.edge && !settings.selection.case) {
        // #2933: action to jump from the value stream view to the bottleneck analysis
        // only makes sense if a single product passes the filter or if the user is an oniq user
        // (because he can demo a global bottleneck analysis)
        actions.push({
            title: "shortcuts.identifyBottleneck",
            id: "bottlenecks",
            isHidden: (isProductBottleneck && !isSingleProduct) ||
                !isRcaVisible(RcaType.Bottleneck, session, isOniqUser),
            type: ActionType.Other,
            onClick: async () => {
                const rca = getRecentRcaByType(RcaType.Bottleneck, settings);

                // If it's not product bottleneck then start immediately the bottleneck analysis without the need to make an additional click on Start analysis
                if (!isProductBottleneck && (rca.id === undefined || !rca.showResults)) {
                    const quantity = quantities.find(q => q.id === settings.quantity);
                    const key = "kpis.caseYieldRate" + capitalize(quantity?.baseQuantity);
                    await submitRca(session,
                        settings,
                        RcaType.Bottleneck,
                        undefined,
                        key as RcatypeTargets,
                        false,
                        LegacyAnalyzedValues.OutputRate,
                        settings.filters,
                        `/projects/${session.projectId}/analyses/bottleneck/bottleneck`,
                        0,
                        (value: number | undefined, locale: string) => {
                            const formatter = getQuantity(settings.quantity, true)?.formatter;
                            if (formatter)
                                return formatter(value, undefined, locale);
                            return "";
                        },
                        undefined,
                        trackEvent,
                        false,
                    );
                    queueMicrotask(() => {
                        const showResults = getRecentRcaByType(RcaType.Bottleneck, settings).id !== undefined;
                        const rcaUpdate = modifyRcaByType(RcaType.Bottleneck, settings, {
                            showResults,
                        }, true);
                        navigateWith(settings, navigate, {
                            history: getPushedHistory(location.pathname, settings),
                            ...rcaUpdate,
                        }, `/projects/${session.projectId}/analyses/bottleneck/bottleneck`);
                    });
                }

                else {
                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                    }, `/projects/${session.projectId}/analyses/bottleneck/bottleneck`);
                }
            },
        });

        if (!isValueStreamDiagram)
            actions.push({
                title: "shortcuts.showValueStreamDiagram",
                id: "value-stream-diagram-valuestream",
                type: ActionType.Other,
                onClick: () => {
                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                    }, `/projects/${session.projectId}/analyses/value-stream/summary`);
                },

            });

        if (!isCycleTimeView && !(isValueStreamDiagram && settings.kpi.selectedKpi !== KpiTypes.CycleTime))
            addCycleTimeButton();

        actions.push({
            title: "shortcuts.showDeviationValueStream",
            type: ActionType.Other,
            id: "schedule-deviation",
            isHidden: !hasPlanning || !(route.route?.includes("timings/process/:tabSlug") || isValueStreamDiagram),
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/timings/process/deviation`);
            },
        });

        actions.push({
            title: "shortcuts.showWorkplaceComparison",
            id: "workplace-comparison-product",
            type: ActionType.Other,
            isLoading: isAllMachineIdsLoading || isProductCountLoading,
            isHidden: !isSingleProduct || !hasComparison,
            onClick: () => {
                navigateWith(settings, navigate, {
                    kpiMatrix: {
                        machines: (allMachineIds ?? []).filter(m => m !== undefined),
                    },
                    history: getPushedHistory(location.pathname, settings),
                }, comparisonUrl);
            },

        });
    }

    // #region Comparison Views
    if (isComparisonView) {

        if (isSingleProduct)
            addCycleTimeButton(GroupingKeys.MachineValueStream);

        // Show the setup matrix if only a single machine is selected
        if (settings.kpiMatrix.machines.length === 1)
            actions.push({
                title: "shortcuts.showSetupMatrix",
                id: "setup-matrix",
                type: ActionType.Other,
                isHidden: !(session.project?.features?.allowSetupMatrix || isOniqUser),
                onClick: () => {
                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                        kpiMatrix: {
                            machineName: machineIdToNames?.nodes.find(n => n.id === settings.kpiMatrix.machines[0])?.activityValues?.machine?.value,
                        },
                    }, `/projects/${session.projectId}/analyses/setup/matrix`);
                }
            });

        // Show the other comparison view
        const isEquipmentComparison = route.route?.includes("analyses/equipments/:tabSlug");
        const otherComparisonUrl = (isEquipmentComparison && session.project?.features?.allowProcessWorkplaceComparison !== false) ? `/projects/${session.projectId}/analyses/workplaces/comparison` : `/projects/${session.projectId}/analyses/equipments/comparison`;

        actions.push({
            title: isEquipmentComparison ? "shortcuts.showProcessComparison" : "shortcuts.showEquipmentComparison",
            id: "switch-workplace-comparison",
            type: ActionType.Other,
            isHidden: (!isEquipmentComparison && !hasEquipmentComparison) || (isEquipmentComparison && session.project?.features?.allowProcessWorkplaceComparison === false),
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                }, otherComparisonUrl);
            },
        });

        // Show the organizational losses in case they are available
        actions.push({
            title: "shortcuts.showOrganizationalLosses",
            id: "organizational-losses",
            type: ActionType.Other,
            isHidden: !isRcaVisible(RcaType.OrgLosses, session, isOniqUser),
            onClick: () => {
                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/analyses/losses/organizational`);
            }

        });

    }


    // #endregion

    // #endregion
    const addSupplyChainAction = (product: ProductIdentifier) => {
        const productFilter = buildProductFilter(product, false, session);
        const filtersWithoutProductFilter = settings.filters.filter(f => !isProductFilter(f, undefined, session.project?.eventKeys)?.includes(product.name));
        actions.push({
            title: "shortcuts.showProductSupplyChain",
            id: "show-product-supply-chain",
            type: ActionType.Other,
            onClick: () => {
                navigateWith(settings, navigate, {
                    supplyChain: {
                        selectedProduct: product.name,
                    },
                    filters: [...filtersWithoutProductFilter, productFilter],
                    history: getPushedHistory(location.pathname, settings),
                }, `/projects/${session.projectId}/supply-chain/bom`);
            },
        });
    };

    if (isInProcessKpisView && isProductSelected && !isEmpty(session.project?.uploads?.billOfMaterials))
        addSupplyChainAction(settings.selection.product!);

    if ((isValueStreamDiagram || isValueStreamView || isInProcessKpisView) &&
        isNothingSelected &&
        productCount?.log.productCount === 1 &&
        !isEmpty(session.project?.uploads?.billOfMaterials))
        addSupplyChainAction(productCount.products[0]);

    const categories: { [key: string]: ActionItem[] } = {};
    actions?.filter(a => !a.isHidden).forEach(action => {
        const key = action.type.toString();
        if (!categories[key])
            categories[key] = [];

        categories[key].push(action);
    });

    if (actions.length === 0)
        return null;

    const labels: { [key: string]: string } = {
        1: "common.filterSelection",
        2: "common.actions",
    };

    return <div className={`shortcutsHost ${props.stack ? "shortcutsStackable" : "shortcutsAbsolute"}`}>
        <div className="list">
            {Object.keys(categories).map(key => {
                if (categories[key].length === 0 || props.disabledActionTypes?.includes(+key as ActionType))
                    return undefined;

                return <Menu key={key} verticalPlacement={MenuPlacementsVertical.Above} className="menuLight" items={categories[key].map(action => {
                    return {
                        ...action,
                        onClick: () => {
                            const id = action.id ?? action.title;
                            if (id)
                                trackEvent({
                                    category: "shortcuts",
                                    action: "clicked",
                                    name: id,
                                });

                            if (action.onClick)
                                action.onClick();
                        },
                        tooltip: action.tooltip !== undefined ? i18n.t(action.tooltip ?? "") : undefined,
                    } as MenuItem;
                })}>
                    <button className="shortcutButton" data-testid={`actions-category-${key}`}>
                        {i18n.t(labels[key] ?? "")}
                        <svg className="svg-icon tiny rotate180"><use xlinkHref="#collapser" /></svg>
                    </button>
                </Menu>;
            }).filter(k => k !== undefined)}
            {props.additionalButtons !== undefined && props.additionalButtons}
        </div>
    </div>;

    function addFilter(addAdditiveFilter: boolean) {
        // We're building additive and subtractive versions of the requested filter here,
        // and replace any of these if present in the current filter setup.
        // That's convenient, because now you can first click on "exclude selection" and then
        // "restrict to selection" if you changed your mind. Without this filtering,
        // this would always result in 0 cases.
        let additive: EventFilter | undefined;
        let subtractive: EventFilter | undefined;

        if (has(ShortcutContexts.Category) && settings.selection.category && settings.selection.categoryValue) {
            additive = buildProductCategoryFilter(settings.selection.category!, [settings.selection.categoryValue!], false, session);
            subtractive = buildProductCategoryFilter(settings.selection.category!, [settings.selection.categoryValue!], true, session);
        }

        if (has(ShortcutContexts.Case) && settings.selection.case) {
            additive = buildCaseFilter([settings.selection.case!], false, session);
            subtractive = buildCaseFilter([settings.selection.case!], true, session);
        }

        if (has(ShortcutContexts.Product) && settings.selection.product) {
            additive = buildProductFilter([settings.selection.product], false, session);
            subtractive = buildProductFilter([settings.selection.product], true, session);
        }

        if (has(ShortcutContexts.Node) && settings.selection.node) {
            additive = buildNodeFilter(settings.selection.node!, false, settings.groupingKey);
            subtractive = buildNodeFilter(settings.selection.node!, true, settings.groupingKey);
        }

        if (has(ShortcutContexts.Edge) && settings.selection.edge) {
            const fromNode = props.graph?.nodes.find(n => n.id === settings.selection.edge!.from);
            const toNode = props.graph?.nodes.find(n => n.id === settings.selection.edge!.to);

            if (!fromNode || !toNode)
                return;

            additive = buildEdgeFilter(fromNode, toNode, false, settings.groupingKey);
            subtractive = buildEdgeFilter(fromNode, toNode, true, settings.groupingKey);
        }

        if (has(ShortcutContexts.Variant) && settings.processPath.processPathSelectedIds.length > 0) {
            additive = buildVariantFilter(settings.processPath.processPathSelectedIds, settings.groupingKey, false);
            subtractive = buildVariantFilter(settings.processPath.processPathSelectedIds, settings.groupingKey, true);
        }

        if (has(ShortcutContexts.Timeperiod) && settings.selection.timeperiod) {
            const temp = getTimeperiodFiltersFromSelection(settings, session);
            additive = temp?.additive;
            subtractive = temp?.subtractive;
        }

        if (additive && subtractive)
            if (addAdditiveFilter)
                appendOrReplaceFilter(additive, subtractive, settings.selection);
            else
                // When removing the selected element from the results it might be
                // wise to reset the selection.
                appendOrReplaceFilter(subtractive, additive, {});
    }


    function appendOrReplaceFilter(filter: EventFilter | undefined, twin: EventFilter | undefined = undefined, selection: SelectionType) {
        if (!filter)
            return;

        const filters = ([] as EventFilter[]).concat(settings.filters ?? []).filter(f => !isEqual(f, twin) && !isEqual(f, filter)).concat([filter]);
        const previewFilters = settings.previewFilters?.length ? ([] as EventFilter[]).concat(settings.previewFilters ?? []).filter(f => !isEqual(f, twin) && !isEqual(f, filter)).concat([filter]) : settings.previewFilters;

        settings.set({
            filters,
            previewFilters,
            selection,
            graph: {
                ...settings.graph,
                complexityCutoffScore: undefined,
            },
        });
    }
}

function getTimeperiodFiltersFromSelection(settings: SettingsContextType, session: SessionContextType) {
    if (!settings.selection.timeperiod?.timeperiodStartTime)
        return undefined;

    const fromDateTime = DateTime.fromISO(settings.selection.timeperiod?.timeperiodStartTime, {
        zone: session.timezone,
    });

    // Calculate end timestamp of the timeperiod
    const toTimestamp = addStep(new Timestamp(fromDateTime.year, fromDateTime.month, fromDateTime.day, fromDateTime.hour, fromDateTime.minute, fromDateTime.second, fromDateTime.millisecond),
        session.timezone,
        settings.kpi.timeScale);

    // Attach that timestamp to a time zone
    const toDateTime = DateTime.fromObject({
        ...toTimestamp,
    }, {
        zone: session.timezone,
    });

    return {
        additive: buildTimeFilter(fromDateTime.toJSDate(), toDateTime.toJSDate(), false, false, false),
        subtractive: buildTimeFilter(fromDateTime.toJSDate(), toDateTime.toJSDate(), false, false, true),
    };
}

function useMachineIds(node: Node | undefined, matcher: (selected: Node | undefined, node: Node) => boolean, options?: { disable?: boolean, filter?: EventFilter[] }): [string[] | undefined, boolean] {
    const graph = useGraph({
        ...disableAllCalcOptions,
        groupingKey: GroupingKeys.Machine,
        eventFilters: options?.filter ?? [],
        calculateActivityValues: true,
        calculateNodes: true,
        calculateRoles: true,
    }, undefined, false, options?.disable);

    if (options?.disable)
        return [undefined, false];

    if (graph === undefined)
        return [undefined, true];

    const matches = graph.nodes.filter(n => matcher(node, n)).map(n => n.id);
    return [matches, false];
}
