import React, { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { StatsRowValue } from "../../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../stats-section/StatsSection";
import { KpiTypes } from "../../../models/KpiTypes";
import EquipmentStatsLineGraph from "../../graph/EquipmentStatsLineGraph";
import { getKpiDefinition, getKpiSpotlightIdNode, getUnit } from "../../../models/Kpi";
import { WorkplaceMatrixCellProps } from "../WorkplaceMatrixCell";
import { getKpiList } from "../../../views/kpi-matrix/EquipmentMatrixView";
import i18n from "../../../i18n";
import { defaultKpiList } from "../../../views/kpi-matrix/KpiMatrixView";
import NodeStatsLineGraph from "../../graph/NodeStatsLineGraph";
import { GroupingKeys } from "../../../models/Dfg";

export function MatrixEquipmentStats() {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const kpiList = getKpiList(session, settings);
    const kpiTypes = kpiList.map(kpiType => kpiType.kpiType);

    const element = settings.selection.matrixElement as WorkplaceMatrixCellProps;

    if (!element) {
        return null;
    }

    const selectedKpiDefinition = getKpiDefinition(element.kpi as KpiTypes, {settings, session});
    //get the statistic of the selected kpi that is used in the matrix 
    const kpiStatistic = kpiList.find(kpi => kpi.kpiType === element.kpi)?.statistic;

    if (!selectedKpiDefinition || !kpiStatistic) {
        return null;
    }
    
    return <StatsSection title={element.workplace?.name}>
        <StatsSubSection title={selectedKpiDefinition?.label} spotlightId={getKpiSpotlightIdNode(session, selectedKpiDefinition?.id, ["Node"])}>
            <StatsRowValue label={i18n.t(`common.statistics.${kpiStatistic}`) ?? "common.value"}  value={element.value} unit={getUnit(selectedKpiDefinition?.unit, kpiStatistic)} isHighlight={true} />
            {/* Line chart */}
        </StatsSubSection>
        <EquipmentStatsLineGraph kpis={kpiTypes} node={element.workplace?.name} statistics={kpiStatistic} kpiSelected={element.kpi}/>
    </StatsSection>;
}

export function MatrixProcessStats() {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const element = settings.selection.matrixElement as WorkplaceMatrixCellProps;

    if (!element) {
        return null;
    }

    const selectedKpiDefinition = getKpiDefinition(element.kpi as KpiTypes, {settings, session});
    const kpiStatistic = defaultKpiList.find(kpi => kpi.kpiType === element.kpi)?.statistic;

    if (!selectedKpiDefinition || !kpiStatistic) {
        return null;
    }

    return <StatsSection title={element.workplace?.name}>
        <StatsSubSection title={selectedKpiDefinition?.label} spotlightId={getKpiSpotlightIdNode(session, selectedKpiDefinition?.id, ["Node"])}>
            <StatsRowValue label={i18n.t(`common.statistics.${kpiStatistic}`) ?? "common.value"}  value={element.value} unit={getUnit(selectedKpiDefinition?.unit, kpiStatistic)} isHighlight={true} />
            {/* Line chart */}
        </StatsSubSection>
        {selectedKpiDefinition?.id !== KpiTypes.CycleTime && <NodeStatsLineGraph kpi={selectedKpiDefinition?.id} nodeId={element.workplace?.id} statistic={kpiStatistic} groupingKey={GroupingKeys.Machine} />}
    </StatsSection>;
}
