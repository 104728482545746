import { matchPath, useLocation } from "react-router-dom";

const paths = [
    "/projects",
    "/projects/add-project-upload",
    "/data-sources",
    "/projects/create-project/:uploadId",
    "/projects/:projectId/settings/:tabSlug",
    "/projects/:projectId/timings/kpis/:tabSlug",
    "/projects/:projectId/timings/process/:tabSlug",
    "/projects/:projectId/output/kpis/:tabSlug",
    "/projects/:projectId/output/process/:tabSlug",
    "/projects/:projectId/output/analyses/process-path/:tabSlug",
    "/projects/:projectId/stock/process/:tabSlug",
    "/projects/:projectId/stock/kpis/:tabSlug",
    "/projects/:projectId/quality/kpis/:tabSlug",
    "/projects/:projectId/quality/process/:tabSlug",
    "/projects/:projectId/carbon/kpis/:tabSlug",
    "/projects/:projectId/carbon/process/:tabSlug",
    "/projects/:projectId/order-tracking/:tabSlug",
    "/projects/:projectId/edit",
    "/projects/:projectId/add-plan",
    "/projects/:projectId/edit-plan",
    "/projects/:projectId/add-order-tracking",
    "/projects/:projectId/edit-order-tracking",
    "/projects/:projectId/dashboard",
    "/projects/:projectId/log",
    "/projects/:projectId/analyses/value-stream/:tabSlug",
    "/projects/:projectId/analyses/cycle-time/:tabSlug",
    "/projects/:projectId/analyses/workplaces/:tabSlug",
    "/projects/:projectId/analyses/equipments/:tabSlug",
    "/projects/:projectId/analyses/rca/:tabSlug",
    "/projects/:projectId/analyses/setup/:tabSlug",
    "/projects/:projectId/analyses/bottleneck/:tabSlug",
    "/projects/:projectId/analyses/losses/:tabSlug",
    "/projects/:projectId/analyses/master-data-download:tabSlug",
    "/favorites",
    "/goto/:favoriteId",
    "/projects/:projectId/supply-chain/:tabSlug",
];

export function useMatchedPath() {
    const { pathname } = useLocation();
    return getRouteFromLocation(pathname);
}

export function getRouteFromLocation(pathname: string): {
    route: string | undefined,
    dimension: string | undefined,
} {
    for (const path of paths)
        if (matchPath({ path }, pathname)) {
            const dimensions = ["timings", "output", "stock", "quality", "carbon"];
            const dimension = dimensions.find((dimension) => pathname.includes(`/${dimension}/`));

            return {
                route: path,
                dimension,
            };
        }

    return {
        route: undefined,
        dimension: undefined,
    };
}
