import React, { useContext, useState } from "react";
import { StatsLineGraph } from "./StatsLineGraph";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SessionContext } from "../../contexts/SessionContext";
import { PerTimeperiodNodeSchema, PerTimeperiodNodeStatisticsSchema, TimePeriodFrequencies } from "../../models/ApiTypes";
import { useNodeAggregationTimeperiods } from "../../hooks/UseNodeAggregationTimeperiods";
import { EventKeys } from "../../models/EventKeys";
import { get, isObject } from "lodash";
import { useLineGraphData } from "./LineGraph";
import { KpiDefinition, buildKpiSpotlightId, getKpiDefinition, getUnit } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { DfgUtils } from "../../utils/DfgUtils";
import { CASE_TYPE_ID, GroupingKeys } from "../../models/Dfg";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";

type NodeStatsLineGraphProps = {
    nodeId?: string | undefined,
    kpi?: KpiTypes | undefined,
    statistic?: StatisticTypes,
    groupingKey?: GroupingKeys,
    disableProcessFocus?: boolean,
}

export default function NodeStatsLineGraph(props: NodeStatsLineGraphProps) {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const [chartFrequency, setChartFrequency] = useState<TimePeriodFrequencies | undefined>(undefined);

    const selectedNodeId = props.nodeId ?? settings.selection.node?.id;
    const selectedKpi = props.kpi ?? settings.kpi.selectedKpi;
    const statsKpi = props.statistic ?? settings.kpi.statistic;

    const objectType = props.disableProcessFocus ? CASE_TYPE_ID : settings.graph.objectType;
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const kpiDefinition = getKpiDefinition(selectedKpi, { settings, session });
    const unit = getUnit(kpiDefinition?.unit, statsKpi);

    const [nodeData, isNodeDataLoading] = useNodeAggregationTimeperiods({
        ...kpiDefinition?.apiParameters,
        customKpis: kpiDefinition?.nodeOverTimeCustomKpis,
        frequency: chartFrequency,
        consolidatePasses: true,
        useActivityPasses: true,
        eventKeys: {
            ...session.project?.eventKeys ?? {},
            activityKeysGroup: props.groupingKey ?? settings.groupingKey,
        } as EventKeys,
        nodes: [selectedNodeId ?? ""],
        sort: ["-timeperiodStartTime"]
    }, {
        disable: chartFrequency === undefined || selectedNodeId === undefined,
    });

    const propName = getNodeOverTimeProp(kpiDefinition, statsKpi);
    const nodeChartData = useLineGraphData(
        getObjectNodeOverTimeData(nodeData, isObjectCentric, objectType),
        propName,
        {
            scale: unit?.name === "percent" ? 100 : undefined,
            frequency: chartFrequency,
        });

    if (kpiDefinition === undefined)
        return null;

    return <StatsLineGraph
        spotlightId={[buildKpiSpotlightId(kpiDefinition.id), "OverTime-Explanation-Workplace"]}
        frequency={chartFrequency}
        setFrequency={setChartFrequency}
        isLoading={isNodeDataLoading}
        data={nodeChartData}
        unit={unit}
        kpi={selectedKpi}
        statistics={statsKpi} />;
}

function getNodeOverTimeProp(kpiDefinition: KpiDefinition | undefined, statistic: StatisticTypes) {
    if (kpiDefinition === undefined)
        return "";
    if (isObject(kpiDefinition.nodeOverTimeStatisticsPath))
        return get(kpiDefinition.nodeOverTimeStatisticsPath, statistic) ?? "";
    return kpiDefinition.nodeOverTimeStatisticsPath ?? "";
}

function getObjectNodeOverTimeData(nodeData: PerTimeperiodNodeStatisticsSchema | undefined, isObjectCentric: boolean, objectType?: string): PerTimeperiodNodeStatisticsSchema | undefined {
    if (nodeData === undefined)
        return undefined;
    return {
        ...nodeData, nodes: nodeData.nodes.map((perTimeperiodNode) => {
            return {
                ...perTimeperiodNode,
                ...getObjectNodeTimeperiods(perTimeperiodNode, isObjectCentric, objectType)
            };
        })
    };
}

function getObjectNodeTimeperiods(perTimeperiodNode: PerTimeperiodNodeSchema, isObjectCentric: boolean, objectType?: string): PerTimeperiodNodeSchema {
    return {
        ...perTimeperiodNode, timeperiods: perTimeperiodNode.timeperiods.map((timeperiod) => {
            return {
                ...(DfgUtils.findObjectNodeStats(timeperiod, isObjectCentric, objectType) ?? {}),
                timeperiodStartTime: timeperiod.timeperiodStartTime,
                timeperiodEndTime: timeperiod.timeperiodStartTime,
            };
        })
    };
}
