import React, { useEffect, useRef, useState } from "react";
import { Column, ColumnInstance, usePagination, useSortBy, useTable } from "react-table";
import { classNames } from "../../utils/Utils";

export type TableEventHandler<T extends Record<string, unknown>> = (params: { element: T | undefined, rowIdx: number | undefined, column: ColumnInstance<T>, columnIdx: number }) => void;

export enum PaginationModes {
    /**
     * Display all data in a large table, no pagination
     */
    None = 0,

    /**
     * Display as many rows as are fitting into the viewport
     */
    FillContainer = 1,

    /**
     * Stick to the number of visible rows specified in the container props
     */
    Fixed = 2,

    ScrollContainer = 3,
}

type CustomTablePropsType<T extends Record<string, unknown>> = {
    data: T[];
    columns: Column<T>[];

    /**
     * Number of pagination elements displayed between previous/next buttons
     */
    maxPaginationElements?: number;

    /**
     * Pagination mode
     */
    paginationMode?: PaginationModes;

    /**
     * Number of elements per page. Only considered if paginationMode is Fixed.
     */
    pageSize?: number;

    /**
     * Allow sorting
     */
    hasSortBy?: boolean;

    /**
     * Handler that's executed when the user clicks on a row
     */
    onRowClick?: (data: T, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;

    style?: React.CSSProperties | undefined;

    className?: string;

    onMouseOver?: TableEventHandler<T>;
    onMouseOut?: TableEventHandler<T>;
    onClick?: TableEventHandler<T>;
    onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

type CustomTableStateType = {
    isReadyForRendering: boolean;
    numVisibleRows: number | undefined;
};

export default function CustomTable<T extends Record<string, unknown>>(props: CustomTablePropsType<T>) {
    const rootContainer = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLTableSectionElement>(null);
    const bodyRef = useRef<HTMLTableSectionElement>(null);
    const paginationRef = useRef<HTMLDivElement>(null);
    const paginationMode = props.paginationMode !== undefined ? props.paginationMode : PaginationModes.Fixed;

    const className = classNames([
        "customTable",
        (paginationMode === PaginationModes.ScrollContainer || paginationMode === PaginationModes.FillContainer) && "tableStickyHeaders",
        props.className
    ]);

    const [state, setState] = useState<CustomTableStateType>({
        isReadyForRendering: paginationMode !== PaginationModes.FillContainer,
        numVisibleRows: undefined,
    });

    useEffect(() => {
        if (paginationMode !== PaginationModes.FillContainer)
            return;

        const clientHeight = rootContainer.current?.clientHeight;
        const headerHeight = headerRef.current?.clientHeight;
        const rowHeight = bodyRef.current?.children[0]?.clientHeight;
        const paginationHeight = paginationRef.current?.clientHeight || 0;

        if (!clientHeight || !headerHeight || !rowHeight)
            return;

        const availableSpace = clientHeight - headerHeight - paginationHeight;
        const numRows = Math.floor(availableSpace / (1 + rowHeight));

        setState((s) => {
            return {
                ...s,
                numVisibleRows: numRows,
                isReadyForRendering: true,
            };
        });
    }, [paginationMode, headerRef.current?.clientHeight]);
    const elementsPerPage = state.numVisibleRows || props.pageSize || 10;
    const plugins = props.hasSortBy ? [useSortBy, usePagination] : [usePagination];
    const tableInstance = useTable({
        columns: props.columns,
        data: props.data,
        pageSize: elementsPerPage,
        initialState: {
            pageIndex: 0,
        }
    }, ...plugins);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount
    } = tableInstance;

    if (pageSize !== elementsPerPage)
        tableInstance.setPageSize(elementsPerPage);

    const maxPaginationDots = props.maxPaginationElements || 11;

    let minPageIdx = Math.max(0, Math.round(pageIndex - maxPaginationDots / 2));
    let maxPageIdx = Math.min(pageCount, minPageIdx + maxPaginationDots);
    minPageIdx = Math.max(0, maxPageIdx - maxPaginationDots);

    const skippedFirstPages = minPageIdx > 0;
    const skippedLastPages = maxPageIdx < pageCount;

    if (skippedLastPages)
        maxPageIdx--;

    if (skippedFirstPages)
        minPageIdx++;

    const dashMarkup: JSX.Element[] = [];
    for (let pageIdx = minPageIdx; pageIdx < maxPageIdx; pageIdx++) {
        const isCurrentPage = pageIdx === pageIndex;
        const cssClasses = "paginatorPage " + (isCurrentPage ? "selected" : "default");

        dashMarkup.push(<div
            key={`paginator-${pageIdx}`}
            className={cssClasses}
            onClick={() => { gotoPage(pageIdx); }}
        >
            <div>{pageIdx + 1}</div>
        </div>);
    }

    return <div
        className="customTableContainer"
        onScroll={(e) => {
            if (props.onScroll !== undefined)
                props.onScroll(e);
        }}
    >
        <div
            ref={rootContainer}
            style={{
                ...(props.style || {}),
                visibility: state.isReadyForRendering ? "visible" : "hidden"
            }}
            className={className}>



            <table {...getTableProps()}>
                <thead ref={headerRef}>
                    {// Loop over the header rows
                        headerGroups.map((headerGroup) => (
                            // Apply the header row props
                            // ESLint doesn't get that headerGroup.getHeaderGroupProps returns
                            // a key property that is expanded into the tr element.
                            // Thus, we have to silence that (otherwise useful) rule here.
                            // eslint-disable-next-line react/jsx-key
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map((column, idx) => (
                                        // Apply the header cell props
                                        // eslint-disable-next-line react/jsx-key
                                        <th
                                            className={(column as any)["className"]}
                                            onMouseOver={() => { if (props.onMouseOver) props.onMouseOver({ element: undefined, rowIdx: undefined, column: column, columnIdx: idx }); }}
                                            onMouseOut={() => { if (props.onMouseOut) props.onMouseOut({ element: undefined, rowIdx: undefined, column: column, columnIdx: idx }); }}
                                            onClick={() => { if (props.onClick) props.onClick({ element: undefined, rowIdx: undefined, column: column, columnIdx: idx }); }}

                                            {...column.getHeaderProps(props.hasSortBy ? column.getSortByToggleProps() : undefined)}>
                                            <div className="headerWrap">
                                                <div className="label">
                                                    {// Render the header
                                                        column.render("Header")}
                                                </div>
                                                {props.hasSortBy && <span className="sortBy">
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <svg className="svg-icon xsmall sorted"><use xlinkHref="#radix-caret-down" /></svg>
                                                            : <svg className="svg-icon xsmall sorted"><use xlinkHref="#up" /></svg>
                                                        : <svg className="svg-icon xsmall"><use xlinkHref="#updown" /></svg>}
                                                </span>}
                                            </div>
                                        </th>
                                    ))}
                            </tr>
                        ))}
                </thead>
                <tbody {...getTableBodyProps()} ref={bodyRef}>
                    {// Loop over the table rows
                        ((paginationMode === PaginationModes.None || paginationMode === PaginationModes.ScrollContainer) ? rows : page).map((row) => {
                            // Prepare the row for display
                            prepareRow(row);
                            return (
                                // Apply the row props
                                // See above... this tr DOES have a key.
                                // eslint-disable-next-line react/jsx-key
                                <tr
                                    style={{
                                        cursor: props.onRowClick ? "pointer" : ""
                                    }}
                                    onClick={(e) => { if (props.onRowClick !== undefined) props.onRowClick(row.original, e); }}
                                    {...row.getRowProps()}>
                                    {// Loop over the rows cells
                                        row.cells.map((cell, idx) => {
                                            // Apply the cell props
                                            const p = cell.getCellProps();
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <td
                                                    className={(cell.column as any)["className"]}
                                                    onMouseOver={() => { if (props.onMouseOver) props.onMouseOver({ element: row.original, rowIdx: row.index, column: cell.column, columnIdx: idx }); }}
                                                    onMouseOut={() => { if (props.onMouseOut) props.onMouseOut({ element: row.original, rowIdx: row.index, column: cell.column, columnIdx: idx }); }}
                                                    onClick={() => { if (props.onClick) props.onClick({ element: row.original, rowIdx: row.index, column: cell.column, columnIdx: idx }); }}
                                                    {...p}
                                                >
                                                    {// Render the cell contents
                                                        cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {paginationMode !== PaginationModes.None && paginationMode !== PaginationModes.ScrollContainer && pageCount > 1 && <div className="paginatorTabs" ref={paginationRef}>
                <button disabled={!canPreviousPage} className="paginatorPage default" onClick={() => previousPage()}>
                    <svg className="svg-icon xsmall"><use xlinkHref="#radix-chevron-left" /></svg>
                </button>

                {skippedFirstPages && <div className="paginatorPage default"><div>...</div></div>}

                {dashMarkup}

                {skippedLastPages && <div className="paginatorPage default"><div>...</div></div>}

                <button disabled={!canNextPage} className="paginatorPage default" onClick={() => nextPage()}>
                    <svg className="svg-icon xsmall"><use xlinkHref="#radix-chevron-right" /></svg>
                </button>
            </div>}
        </div>
    </div>;
}
