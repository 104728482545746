import React, { useContext } from "react";
import { MatrixComparisonSelector } from "../../components/controls/MatrixComparisonSelector";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import { MatrixComparison, SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import i18n from "../../i18n";
import { getKpiDefinition } from "../../models/Kpi";
import Dropdown from "../../components/dropdown/Dropdown";
import { ObjectControls, getValidObjectControlsSettings } from "../../components/controls/ObjectControls";
import { KpiTypes } from "../../models/KpiTypes";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { QuantityDropdown, SortOrderSwitch } from "../../components/controls/KpiControlPrimitives";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { KpiMatrixHighlightControls } from "../../components/controls/HighlightControls";
import { Spotlight } from "../../components/spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { EquipmentMatrixView, getKpiList } from "../kpi-matrix/EquipmentMatrixView";
import { MatrixEquipmentStats } from "../../components/matrix/stats/MatrixWorkplaceStats";
import { WorkplaceMatrixCellProps } from "../../components/matrix/WorkplaceMatrixCell";
import { KpiComparisons } from "../../contexts/ContextTypes";

export function EquipmentComparisonView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const yieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = yieldQuantities.map(q => q.baseQuantity);
    const kpiList = getKpiList(session, settings);

    const enabledComparisons = session.project?.uploads?.routings?.columnMapping?.machine ?
        [MatrixComparison.None, MatrixComparison.Planning, MatrixComparison.AbsoluteDeviation, MatrixComparison.RelativeDeviation]
        : [MatrixComparison.None];


    return <TabbedView
        enableOnlyTimespan={true}
        subtitle={<div className="text viewSubtitleSpinner">
            {settings.kpiMatrix.machines.length === 0 && i18n.t("common.workplaceSelection")}
            {settings.kpiMatrix.machines.length === 1 && settings.kpiMatrix.machines[0]}
            {settings.kpiMatrix.machines.length > 1 && i18n.t("common.numWorkplaces", { count: settings.kpiMatrix.machines.length })}
        </div>}
        breadcrumbs={[{
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        }, {
            label: "common.analysesView.equipmentWorkplace"
        }]}
        pages={[
            {
                tabTitle: "common.equipmentBenchmarking",
                tabSlug: "comparison",
                spotlightId: "Kpi-Matrix",
                isVisible: session.project?.features?.allowEquipmentComparison || isOniqEmployee(session),
                activator: (preferences) => {
                    const temp = viewSettingsInitialization(session, settings, preferences, {
                        kpi: { comparisons: KpiComparisons.None, },
                    }, {
                        sortBy: [SortByType.Kpi, SortByType.Alphabetical, SortByType.Kpi],
                        kpis: kpiList.map(k => k.kpiType),
                        quantities: baseQuantities,
                    });

                    if (!temp)
                        return;

                    settings.mergeSet(ObjectMerger.mergeObjects([
                        temp,
                        getValidObjectControlsSettings(session, settings),
                    ]));
                },
                controller: <div className="controls">
                    <ObjectControls showContextSwitch={false} />
                    {enabledComparisons.length > 1 && <MatrixComparisonSelector enabledComparisons={enabledComparisons} />}
                    {enabledComparisons.length > 1 && <KpiMatrixHighlightControls disabled={settings.kpiMatrix.machines.length <= 1 && settings.kpiMatrix.comparison === MatrixComparison.None} label="common.highlights" />}
                    <div className="section sortOrder">
                        <div className="title">
                            {i18n.t("kpiMatrix.sortByKpi")}
                            <Spotlight id={buildControllerSpotlightId(location.pathname, ["SortBy"])} className="mls" />
                        </div>
                        <Dropdown
                            options={kpiList.map(t => {
                                return {
                                    label: i18n.t(getKpiDefinition(t.kpiType, { session, settings })?.label ?? ""), value: t.kpiType
                                };
                            })}
                            value={{ value: settings.kpi.selectedKpi, label: i18n.t(getKpiDefinition(settings.kpi.selectedKpi, { settings, session })?.label ?? "") }}
                            onChange={(e) => {
                                const kpi = e!.value as KpiTypes;
                                settings.setKpiState({ selectedKpi: kpi });
                            }}
                        />
                        <SortOrderSwitch />
                        <QuantityDropdown quantities={yieldQuantities} />
                    </div>
                </div>,
                stats: <MatrixEquipmentStats />,
                content: <>
                    <EquipmentMatrixView />
                    <BackButtonTrayElement />
                </>,
                selectionTrigger: (settings) => {
                    return (settings.selection.matrixElement as WorkplaceMatrixCellProps)?.value !== undefined;
                },
            }
        ]}
    />;
}