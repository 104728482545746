import Axios, { AxiosProgressEvent } from "axios";
import React, { useContext, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SessionContext } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";

/**
 * Some things I've learnt while building this:
 *  - Centering a massively padded file upload element may be possible, but not for humble
 *    human beings as myself.
 *  - You can drag and drop files on an input type="file" element, but you cannot drop it
 *    on a label. However, you can still click it and get a file upload dialog from the browser.
 *    So hiding the input field itself and styling the label instead does not work.
 */

export enum FileUploadTypes {
    Image = 1,
    Video = 2,
    Audio = 3
}

export type FileUploadedCallbackType = {
    file: File;
};

export type FileUploadCompleteCallbackType = FileUploadedCallbackType & {
    response: any;
};

export type UploadsCompleteCallbackType = {
    files: File[];
};

type FileUploadPropsType = {
    /**
     * Set to true if you want to allow multiple files to be uploaded
     */
    multiple?: boolean;

    /**
     * Array of file extensions that are allowed, e.g. ["jpg", "png", "xes"]
     */
    fileExtensions?: string[];

    /**
     * Restrict upload to one of the media types listed here
     * https://www.iana.org/assignments/media-types/media-types.xhtml
     */
    mediaTypes?: string[];

    /**
     * Use this for allowing all image types, all video types or all audio types
     */
    uploadTypes?: FileUploadTypes[];

    className?: string;

    /**
     * Invoked once the final byte was transferred to the server
     */
    onFileUploaded?: (e: FileUploadedCallbackType) => void;

    /**
     * Invoked when the server responds with a HTTP status to our upload
     */
    onFileUploadComplete?: (e: FileUploadCompleteCallbackType) => void;

    onUploadsComplete?: (e: UploadsCompleteCallbackType) => void;

    onCancel?: () => void;

    onUploadFailed?: (file: File) => void;

    onUploadStarted?: () => void;

    /**
     * Uploads will be POSTed to this URL
     */
    targetUrl: string;

    headers?: { [name: string]: string };

    /**
     * If true, uploads are automatically started once a file is dragged into the upload field
     */
    autoUpload?: boolean;
};

type FileUploadStateType = {
    files: File[];
    isUploading: boolean;
    fileUploadState: { [id: string]: number | undefined };
    isFileComplete: { [id: string]: boolean | undefined };
};

export default function FileUpload(props: FileUploadPropsType) {
    const [state, setState] = useState<FileUploadStateType>({
        files: [],
        isUploading: false,
        fileUploadState: {},
        isFileComplete: {},
    });

    const session = useContext(SessionContext);

    const inputRef = React.createRef<HTMLInputElement>();

    const typeMapping = {
        [FileUploadTypes.Audio]: "audio/*",
        [FileUploadTypes.Video]: "video/*",
        [FileUploadTypes.Image]: "image/*",
    };

    // Generate list of accepted media types
    const accepts: string[] = [];
    if (props.fileExtensions)
        for (const i of props.fileExtensions)
            accepts.push("." + i);

    if (props.mediaTypes)
        for (const i of props.mediaTypes)
            accepts.push(i);

    if (props.uploadTypes)
        for (const i of props.uploadTypes)
            accepts.push(typeMapping[i]);

    const accept = accepts.length ? accepts.join(", ") : undefined;

    const fileMarkup = state.files.map(f => {
        const isComplete = state.fileUploadState[f.name] !== undefined && state.fileUploadState[f.name]! >= 100;

        if (isComplete && !state.isFileComplete[f.name]) {
            setState({
                ...state,
                isFileComplete: {
                    ...state.isFileComplete,
                    [f.name]: true
                }
            });

            if (props.onFileUploaded)
                props.onFileUploaded({
                    file: f,
                });
        }

        return <CSSTransition timeout={300} classNames="file" key={"file-" + f.name}>
            <div className="file">
                <div className="filePreview">
                    <img id={"file-" + f.name} src="/assets/file.svg" alt="preview" />
                </div>
                <div className="fileInfo">
                    <div className="filename">
                        {f.name}
                    </div>
                    <div className="size">
                        {Formatter.formatFileSize(f.size, undefined, session.numberFormatLocale)}
                    </div>
                    {!isComplete && <progress value={state.fileUploadState[f.name]} max="100">{state.fileUploadState[f.name]} %</progress>}
                    {isComplete && <span>{i18n.t("fileUpload.complete")}</span>}
                </div>
                <div className="closer" onClick={() => {
                    removeFile(f);
                }}>
                    <svg className="svg-icon xsmall clickable hoverOpacity"><use xlinkHref="#radix-cross-1" /></svg>
                </div>
            </div>
        </CSSTransition>;
    });

    return <form>
        <div className={`fileUpload ${props.className || ""}`}>
            <div className="background">
                <div>
                    {i18n.t("fileUpload.caption")}
                </div>
            </div>
            <div className="foreground">
                <input disabled={state.isUploading} ref={inputRef} accept={accept} multiple={!!props.multiple} type="file" name="file" id="file-upload" onChange={fileListChanged} />
            </div>
        </div>

        {!props.autoUpload && <div className="fileButtonGroup">
            <button disabled={state.isUploading || !state.files?.length} onClick={startUpload}>{i18n.t("fileUpload.upload")}</button>
            <button onClick={cancel}>{i18n.t("fileUpload.cancel")}</button>
        </div>}

        <TransitionGroup className="fileContainer">
            {fileMarkup}
        </TransitionGroup>
    </form>;

    function cancel(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        window.setTimeout(() => {
            if (props.onCancel)
                props.onCancel();
        });
    }

    /**
     * Starts uploading files to the API endpoint provided in props.targetUrl. Each file
     * will result in a request of it's own.
     * @param e Click event
     */
    function startUpload(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined, s?: FileUploadStateType | undefined) {
        if (props.onUploadStarted)
            props.onUploadStarted();
        
        const currentState = s || state;
        setState({
            ...currentState,
            isUploading: true,
        });

        for (const idx in currentState.files) {
            const file = currentState.files[idx];

            const formData = new FormData();
            formData.append("file", file);

            Axios.post(props.targetUrl, formData, {
                headers: {
                    ...(props.headers || {}),
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progress: AxiosProgressEvent) => {
                    setState((state) => {
                        const uploadState = { ...state.fileUploadState };
                        uploadState[file.name] = Math.round((progress.loaded * 100) / (progress.total ?? 1));

                        return {
                            ...state,
                            fileUploadState: uploadState
                        };
                    });
                }
            }).then((response) => {
                // If file upload is complete now, notify parent
                if (props.onFileUploadComplete !== undefined)
                    queueMicrotask(() => {
                        props.onFileUploadComplete!({ file, response: response.data });
                    });

                // Check if there are any uploads are ongoing still. Notify parent if this
                // was the last one.
                const hasMoreWork = Object.values(currentState.fileUploadState).some(s => { return s === undefined || s < 100; });
                if (!hasMoreWork) {
                    // All transfers are complete
                    if (props.onUploadsComplete !== undefined)
                        queueMicrotask(() => {
                            props.onUploadsComplete!({
                                files: currentState.files
                            });
                        });

                    setState((s) => {
                        return {
                            ...s,
                            isUploading: false,
                            files: [],
                            fileUploadState: {},
                        };
                    });
                }
            }).catch(() => {
                setState((state) => {
                    const uploadState = { ...state.fileUploadState };
                    uploadState[file.name] = undefined;

                    return {
                        ...state,
                        fileUploadState: uploadState,
                        isUploading: false,
                    };
                });

                if (props.onUploadFailed)
                    props.onUploadFailed(currentState.files[idx]);
            });
        }

        e?.preventDefault();
    }

    function fileListChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files?.length)
            return;

        const files: File[] = props.multiple ? state.files : [];

        if (!props.multiple && files.length > 1) {
            alert("Please upload just one file at a time");
            return;
        }

        for (let i = 0; i < e.target.files.length; i++)
            files.push(e.target.files.item(i)!);

        const newState = {
            ...state,
            files: files,
        };

        // When you add, remove and add the same file again, the onChange event would not be
        // triggered. To prevent that, I'm resetting the form here.
        e.target.form?.reset();

        queueMicrotask(() => {
            for (const file of files.filter(f => {
                return f.type.startsWith("image/");
            })) {
                loadFileToImgId(file, "file-" + file.name);
            }
        });

        if (props.autoUpload)
            startUpload(undefined, newState);
        else
            setState(newState);
    }

    function removeFile(file: File) {
        setState((s) => {
            return {
                ...s,
                files: s.files.filter(f => f.name !== file.name),
            };
        });
    }

    /**
     * Loads an image preview into a <img> tag
     * @param file The file to preview
     * @param id ID of the img tag
     */
    function loadFileToImgId(file: File, id: string) {
        const reader = new FileReader();

        const imgtag = document.getElementById(id) as HTMLImageElement;
        if (!imgtag)
            return;

        imgtag!.title = file.name;

        reader.onload = function (event) {
            if (event.target && event.target.result)
                (imgtag as any).src = event.target?.result;
        };

        reader.readAsDataURL(file);
    }
}